import React, { Component } from 'react'
import Moment from 'react-moment'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import Settings from '@material-ui/icons/Settings'
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'

import * as AdminUsersActions from './AdminUsersActions'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import {
	adminProjectsRoute,
	adminUserInfoRoute,
	adminUsersRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_USERS_ADMIN_USER,
	ADMIN_USERS_CHARGED,
	ADMIN_USERS_CUSTOMIZE_USER,
	ADMIN_USERS_CUSTOMIZE_USER_ADDED,
	ADMIN_USERS_ERROR,
	ADMIN_USERS_IS_TRIAL,
	ADMIN_USERS_NO_USERS_FOUND,
	ADMIN_USERS_PAGE_HEADER,
	ADMIN_USERS_SCANNED,
	ADMIN_USERS_SORT_SUBSCRIPTION,
	ADMIN_USERS_SUBSCRIPTION_ENDS,
	ADMIN_USERS_TRIAL_ENDS,
	ADMIN_USERS_VIEW_PROJECTS,
	ADMIN_USERS_VIEW_USER_INFO,
	NO,
	YES
} from '../../../../Services/Strings'
import CastorDatePickers from '../../../Components/CastorDatePickers/index.tsx'
import CastorExcelExport from '../../../Components/CastorExcelExport/CastorExcelExport'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorPagination from '../../../Components/CastorPagination/CastorPagination'
import CastorPopperMenu from '../../../Components/CastorPopperMenu/CastorPopperMenu'
import CastorServerSearchBar from '../../../Components/CastorServerSearchBar/CastorServerSearchBar'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import {
	Button,
	Danger,
	Table
} from '../../../Components/thirdParty/CreativeTim/components'
import CustomizeMenuItems from './AdminUserCustomizeMenu/CustomizeMenuItems'
import CustomizeMenuTarget from './AdminUserCustomizeMenu/CustomizeMenuTarget'
import { getString } from 'Services/Strings/StringService'

import '../adminHome.scss'
import './AdminUsers.scss'

class AdminUsers extends Component {
	componentDidMount() {
		this.setupAdminUsersPage()
	}

	componentWillUnmount() {
		const { onAdminUsersUnmounted } = this.props
		onAdminUsersUnmounted()
	}

	componentDidUpdate = prevProps => {
		this.setupAdminUsersPage(prevProps)
	}

	setupAdminUsersPage = prevProps => {
		const {
				match,
				pageNumber,
				setupAdminUsersPage,
				limitUsers,
				searchPhrase,
				sortBy,
				sortASC
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1
		if (
			urlPage !== pageNumber ||
			(prevProps && searchPhrase !== prevProps.searchPhrase) ||
			(prevProps && sortASC !== prevProps.sortASC) ||
			!prevProps
		) {
			setupAdminUsersPage(searchPhrase, urlPage, limitUsers, sortBy, sortASC)
		}
	}

	renderDatepickerAlert() {
		const { showDatepicker, datePickersToggled, downloadReport } = this.props
		return (
			<CastorDatePickers
				show={showDatepicker}
				onCancel={() => datePickersToggled(false)}
				downloadReport={(startDate, endDate) =>
					downloadReport(startDate, endDate)
				}
			/>
		)
	}

	renderUserProjectsButton = user => {
		const { onUserInfoClick } = this.props
		const customizeUser = Feature.isFeatureOn(FeatureComponentId.CUSTOMIZE_USER)

		return (
			<div className="admin-user-multiple-line-field">
				<Link to={adminProjectsRoute(user.id)}>
					<Button
						className="link-button"
						size="sm"
						color="primary"
						data-qa={`data-qa-user-${user.NAME}-view-projects-btn`}
					>
						<IconFactory iconName="search" />
						{ADMIN_USERS_VIEW_PROJECTS}
					</Button>
				</Link>
				<Link
					to={adminUserInfoRoute(user.id)}
					onClick={() => onUserInfoClick(user)}
				>
					<Button
						className="link-button"
						size="sm"
						color="primary"
						data-qa={`data-qa-user-${user.NAME}-view-info-btn`}
					>
						<IconFactory iconName="info" className="info-button" />
						{ADMIN_USERS_VIEW_USER_INFO}
					</Button>
				</Link>
				{customizeUser && (
					<CastorPopperMenu
						menuName={`adminCustomize_${user.id}`}
						targetItem={
							<CustomizeMenuTarget name={ADMIN_USERS_CUSTOMIZE_USER} />
						}
						menuItems={
							<CustomizeMenuItems
								userId={user.id}
								onDesktopPrinterToggle={e =>
									this.props.onToggleDesktopPrinter(
										e,
										!!user.desktopPrinter,
										user.id
									)
								}
								isDesktopPrinter={!!user.desktopPrinter}
							/>
						}
						qaDataElementName={`data-qa-user-${user.NAME}-customize`}
					/>
				)}
			</div>
		)
	}

	renderAdminIcon = user => {
		if (!user.admin) {
			return <div />
		}
		return (
			<div className="admin-users-icon" title={ADMIN_USERS_ADMIN_USER}>
				{<SupervisorAccount />}
			</div>
		)
	}

	renderCustomizationIcon = user => {
		if (!user['added customization']) {
			return <div />
		}
		return (
			<div
				className="admin-users-icon"
				title={ADMIN_USERS_CUSTOMIZE_USER_ADDED}
			>
				{<Settings />}
			</div>
		)
	}

	renderLightUserIcon = user => {
		if (!user?.light) {
			return <div />
		}
		return (
			<div
				className="admin-users-icon"
				title={getString('ADMIN_USER_LIGHT_USER')}
			>
				<IconFactory iconName="lightUser" width="30px" height="30px" />
			</div>
		)
	}

	renderUserIcons = user => {
		return (
			<div className="admin-users-icons">
				{this.renderAdminIcon(user)}
				{this.renderCustomizationIcon(user)}
				{this.renderLightUserIcon(user)}
			</div>
		)
	}

	renderCreatedField = createdUser => {
		const userCreatedTime = new Date(createdUser)
		return (
			<div className="admin-user-multiple-line-field">
				{userCreatedTime.toLocaleDateString()}
				<Moment fromNow>{userCreatedTime}</Moment>
			</div>
		)
	}

	renderSubscriptionField = user => {
		const userExpireDate = user['expire Date']
		const userSubscriptionTime = new Date(userExpireDate)
		return (
			<div className="admin-user-multiple-line-field">
				<div>
					{ADMIN_USERS_IS_TRIAL}:{' '}
					<span className="admin-user-multiple-line-field-bold">
						{user.trial ? YES : NO}
					</span>
				</div>
				{userExpireDate && (
					<div>
						{user.trial
							? ADMIN_USERS_TRIAL_ENDS
							: ADMIN_USERS_SUBSCRIPTION_ENDS}{' '}
						<Moment fromNow className="admin-user-multiple-line-field-bold">
							{userSubscriptionTime}
						</Moment>
					</div>
				)}
			</div>
		)
	}

	renderTotalPartsField = user => {
		return (
			<div>
				<div>
					{ADMIN_USERS_SCANNED}: {user['total parts scanned']}
				</div>
				<div>
					{ADMIN_USERS_CHARGED}: {user['total parts charged']}
				</div>
			</div>
		)
	}

	renderSubscriptionHead = () => {
		const { sortBy, onSubscriptionHeadClick } = this.props
		return (
			<div
				className={`admin-table-linkable-header ${
					sortBy === 'subscription' ? 'admin-table-linkable-header--sorted' : ''
				}`}
				title={ADMIN_USERS_SORT_SUBSCRIPTION}
				onClick={onSubscriptionHeadClick}
			>
				<IconFactory
					iconName="sortArrows"
					className="admin-table-linkable-header--icon"
				/>
				{getString('SUBSCRIPTION')}
			</div>
		)
	}

	renderCreateHead = () => {
		const { onCreatedHeadClick } = this.props
		return (
			<div
				className={
					'admin-table-linkable-header admin-table-linkable-header--sorted'
				}
				title={getString('ADMIN_USERS_SORT_CREATED')}
				onClick={onCreatedHeadClick}
			>
				<IconFactory
					iconName="sortArrows"
					className="admin-table-linkable-header--icon"
				/>
				{getString('CREATED')}
			</div>
		)
	}

	renderUsersTableData = () => {
		const { users } = this.props
		return users.map(user => {
			return [
				user.NAME,
				user.email,
				user.company,
				this.renderTotalPartsField(user),
				this.renderCreatedField(user.created),
				this.renderSubscriptionField(user),
				this.renderUserIcons(user),
				this.renderUserProjectsButton(user)
			]
		})
	}

	renderUsersTableHead = () => [
		'name',
		'email',
		'company',
		'total parts',
		this.renderCreateHead(),
		this.renderSubscriptionHead(),
		'Other',
		'Actions'
	]
	renderSearchBar = () => (
		<div className="admin-data-table-search-field">
			<CastorServerSearchBar
				placeholder="Search by name/ ID"
				searchPhrase={this.props.searchPhrase}
				setSearchPhrase={this.setSearchPhrase}
			/>
		</div>
	)

	exportData = () => {
		return (
			<div className="admin-data-table-export-data">
				<CastorExcelExport
					isLoading={this.props.isAdminUserReportLoading}
					onClick={() => this.props.datePickersToggled(true)}
				/>
			</div>
		)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	renderUsersTable = () => {
		const {
			showNoUsersAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminUsersAlert,
			totalUsers,
			limitUsers,
			loading,
			showExportExcel
		} = this.props

		const renderTable = () => {
			if (showNoUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_NO_USERS_FOUND}</Danger>{' '}
					</div>
				)
			}
			if (showAdminUsersAlert) {
				return (
					<div className="align-start">
						<Danger>{ADMIN_USERS_ERROR}</Danger>
					</div>
				)
			}
			return (
				<>
					<Table
						tableHead={usersTableHead}
						tableData={this.renderUsersTableData()}
						loading={loading}
						addMinHeight
					/>
					<CastorPagination
						showPagination={showPagination}
						pageNumber={pageNumber}
						isLastPage={isLastPage}
						linkTo={pageNumber => {
							return adminUsersRoute(pageNumber)
						}}
						total={totalUsers}
						limit={limitUsers}
					/>
				</>
			)
		}
		const usersTableHead = this.renderUsersTableHead()
		return (
			<div className="admin-home-page">
				<div className="admin-data-table-add-item-button">
					{this.renderSearchBar()}
					{showExportExcel ? this.exportData() : <div />}
				</div>
				{renderTable()}
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={ADMIN_USERS_PAGE_HEADER}>
				{this.renderDatepickerAlert()}
				<CastorForm
					formTitle={ADMIN_USERS_PAGE_HEADER}
					formSubTitle="List of all users"
					content={this.renderUsersTable()}
					style={{ maxWidth: 'unset' }}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ AdminUsersReducer }) => {
	return {
		...AdminUsersReducer
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminUsersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers)
