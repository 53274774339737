import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as AdminPrintersActions from './AdminPrintersActions'
import {
	adminPrintersEditFormRoute,
	adminPrintersNewFormRoute,
	adminPrintersRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PRINTERS_PAGE_HEADER,
	ADMIN_PRINTERS_PARAMS,
	ADMIN_PRINTERS_SUB_TITLE,
	ADMIN_PRINTERS_TABLE_TEXTS
} from '../../../../Services/Strings'
import AdminDataTable from '../AdminDataTable/AdminDataTable'

import '../adminHome.scss'

const printerParams = ADMIN_PRINTERS_PARAMS

class AdminPrinters extends Component {
	componentWillMount() {
		this.setupAdminPrintersPage()
	}

	componentDidUpdate = prevProps => {
		this.setupAdminPrintersPage(prevProps)
	}

	componentWillUnmount() {
		const { onAdminPrintersUnmounted } = this.props
		onAdminPrintersUnmounted()
	}

	setupAdminPrintersPage = prevProps => {
		const {
				match,
				pageNumber,
				setupAdminPrintersPage,
				printers,
				limitPrinters,
				searchPhrase
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1
		if (
			urlPage !== pageNumber ||
			(prevProps && searchPhrase !== prevProps.searchPhrase) ||
			!prevProps
		) {
			setupAdminPrintersPage(searchPhrase, urlPage, limitPrinters)
		}
	}

	renderTechnologyField = (printer, printingTechnologies) => {
		const technologyName = printingTechnologies?.find(
			tech => tech?.name === printer?.technology
		)

		return (
			<div className="admin-multiple-line-elypsis" title={printer.description}>
				{printer.technology
					? `${technologyName?.userReadableName || printer?.technology} - `
					: ''}
				{printer.description}
			</div>
		)
	}

	renderTraySizeField = printer => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_X}:{printer.trayX}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_Y}:{printer.trayY}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.TRAY_Z}:{printer.trayZ}
				</div>
			</div>
		)
	}

	renderCostsField = printer => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.BUILT_RATE}:{printer.buildRate}
				</div>
				<div>
					{ADMIN_PRINTERS_TABLE_TEXTS.MACHINE_COST}:{printer.machineCost}
				</div>
			</div>
		)
	}

	renderPrintersTableData = () => {
		const { printers, printingTechnologies } = this.props
		return printers.map(printer => {
			return [
				printer.name,
				printer.company,
				this.renderTechnologyField(printer, printingTechnologies),
				this.renderTraySizeField(printer),
				this.renderCostsField(printer),
				printer.id
			]
		})
	}

	deleteRow = id => {
		this.props.deletePrinter(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoPrintersAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminPrintersAlert,
			totalPrinters,
			limitPrinters,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_PRINTERS_PAGE_HEADER}
				formSubTitle={ADMIN_PRINTERS_SUB_TITLE}
				tableHeadParams={printerParams}
				tableData={this.renderPrintersTableData()}
				linkToRouteFunction={adminPrintersRoute}
				showNoDataAlert={showNoPrintersAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminPrintersAlert}
				totalResults={totalPrinters}
				limitResults={limitPrinters}
				linkToEdit={adminPrintersEditFormRoute}
				linkToNew={adminPrintersNewFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				searchPhrase={searchPhrase}
			/>
		)
	}
}

const mapStateToProps = ({ AdminPrintersReducer, user }) => {
	const { printingTechnologies } = user

	return {
		...AdminPrintersReducer,
		printingTechnologies
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrintersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPrinters)
