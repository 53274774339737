import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AccountCircle from '@material-ui/icons/AccountCircle'

import headerLinksStyle from '../thirdParty/CreativeTim/variables/styles/headerLinksStyle'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'

import * as styles from './UserMenu.css'

const UserMenuTarget = props => {
	const { classes, name, onClick, addMargin } = props
	let userMenuTargetStyle = { ...styles.userMenuTarget }
	if (addMargin) {
		userMenuTargetStyle.margin = styles.margin
	}
	const showUserMenu = Feature.isFeatureOn(FeatureComponentId.SHOW_USER_MENU)
	return (
		<div>
			<Button
				data-qa={`data-qa-user-menu-profile`}
				className={classes.button}
				variant="text"
				size="small"
				style={userMenuTargetStyle}
				onClick={onClick}
				disabled={!showUserMenu}
			>
				<AccountCircle
					style={{ height: 30, width: 30, marginRight: '0.1em' }}
				/>
				<span className="user-menu__name">{name}</span>
			</Button>
		</div>
	)
}

export default WithFeatureToggleHOC(
	withStyles(headerLinksStyle)(UserMenuTarget),
	FeatureComponentId.USER_NAME
)
