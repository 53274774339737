import React, { FC, useState, useEffect } from 'react'
import { ReactComponent as CostSaving } from '../../../assets/img/svg/cost saving.svg'
import { ReactComponent as CostSavingSmall } from '../../../assets/img/svg/$ icon.svg'
import { IOtherProps } from '../../../Services/models/IOtherProps'

interface IProps extends IOtherProps {
  small?: boolean
  className?: string
  smallClassName?: string
  expandIconOnHover?: boolean
}

export const IconCostSaving: FC<IProps> = ({
  small,
  className = '',
  smallClassName = '',
  expandIconOnHover,
  ...props
}) => {
  const [isBigIcon, setIsBigIcon] = useState(false)
  useEffect(() => {
    setIsBigIcon(!small)
  }, [small])

  if (!isBigIcon) {
    return (
      <CostSavingSmall
        className={smallClassName}
        onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
        {...props}
      />
    )
  }
  return (
    <CostSaving
      className={className}
      onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
    />
  )
}
