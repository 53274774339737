import { Action, ActionWithPayload } from '../../../global actions/ActionModels'
import {
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	USER_PANEL_DATA_FETCHED,
	USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED,
	USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED,
	USER_PANEL_PRINTING_SAVING_DATA_FETCHED,
	USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR,
	USER_PANEL_PRINTING_SAVING_SELECT_CHANGED,
	USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED,
	USER_PANEL_ROI_PRINTER_CHANGED,
	USER_PANEL_ROI_PRINTER_COMPANY_CHANGED,
	USER_PANEL_ROI_TYPE_ALERT_CANCELLED,
	USER_PANEL_ROI_TYPE_ALERT_CONFIRMED,
	USER_PANEL_ROI_TYPE_SELECT_CHANGED,
	USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED,
	USER_PANEL_UNMOUNTED
} from '../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import { PRINTING_SAVING_TYPES } from '../../../Services/models/ResultsSelectionOptions'
import { sendReNewSubscriptionRequest } from '../../../Services/Network'
import {
	getUserActivityPanelData,
	getUserPrintingSaving,
	getUserROI,
	updateUserPrintingSaving
} from '../../../Services/Network/UserNetwork'
import {
	CONFIGURATION_CALCULATION_ERROR,
	OK,
	SHOW_NOTIFICATION
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import {
	ISimpleConfigurationCompany,
	ISimpleConfigurationPrinter,
	SimpleConfigurationSelectorService
} from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { setROIData, updateUserROI } from './UserPanelService'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'

let simpleConfigurationSelectorService: SimpleConfigurationSelectorService

export const getUserPanelData = (
	userId: string,
	userCurrencySign: string,
	printersFullData: ISimpleConfigurationCompany[]
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			const userActivityPanelDataResponse = await getUserActivityPanelData()
			const userPrintingSavingResponse = await getUserPrintingSaving()
			const userROI = await getUserROI()

			simpleConfigurationSelectorService =
				new SimpleConfigurationSelectorService(printersFullData, false, true)
			dispatch({
				type: USER_PANEL_DATA_FETCHED,
				payload: {
					...(userActivityPanelDataResponse &&
						userActivityPanelDataResponse.data),
					...(userPrintingSavingResponse && userPrintingSavingResponse.data),
					userCurrencySign,
					...setROIData(
						userROI,
						simpleConfigurationSelectorService,
						printersFullData
					)
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error: any) {
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const unmountUserPanel = (): Action<any> => {
	return { type: USER_PANEL_UNMOUNTED }
}

export const onUpgradeClick = () => {
	return (dispatch: any) => {
		sendReNewSubscriptionRequest()
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('UPGRADE_REQUEST_CONFIRMED_TEXT'),
				headerTitle: getString('UPGRADE_REQUEST_HEADER_TEXT'),
				alertType: AlertType.SUCCESS,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				},
				showCancel: false,
				confirmText: OK
			}
		})
	}
}

export const onPrintingSavingSelectChange = (
	value: PRINTING_SAVING_TYPES
): ActionWithPayload<any> => {
	return { type: USER_PANEL_PRINTING_SAVING_SELECT_CHANGED, payload: { value } }
}

export const onPrintingSavingThresholdChanged = (
	threshold: number
): ActionWithPayload<any> => {
	return {
		type: USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED,
		payload: { threshold }
	}
}

export const onPrintingSavingAlertCancel = (): Action<any> => {
	return {
		type: USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED
	}
}

export const onPrintingSavingAlertConfirm = (
	type: PRINTING_SAVING_TYPES,
	threshold: number,
	userCurrencySign: string
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED
			})

			const response = await updateUserPrintingSaving(type, threshold)

			dispatch({
				type: USER_PANEL_PRINTING_SAVING_DATA_FETCHED,
				payload: {
					...response?.data,
					userCurrencySign
				}
			})
		} catch (error: any) {
			dispatch({
				type: USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			})
		}
	}
}

export const onUserROITypeSelectChange = (
	value: PRINTING_SAVING_TYPES
): ActionWithPayload<any> => {
	return { type: USER_PANEL_ROI_TYPE_SELECT_CHANGED, payload: { value } }
}

export const onROIThresholdChanged = (
	threshold: number
): ActionWithPayload<any> => {
	return {
		type: USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED,
		payload: { threshold }
	}
}

export const onROITypeAlertCancel = (): Action<any> => {
	return {
		type: USER_PANEL_ROI_TYPE_ALERT_CANCELLED
	}
}

export const onROITypeAlertConfirm = (
	type: PRINTING_SAVING_TYPES,
	threshold: number,
	printerId: string,
	printersFullData: ISimpleConfigurationCompany[],
	userROIAlertCanCalaulate: boolean
): any => {
	return async (dispatch: any) => {
		dispatch({
			type: USER_PANEL_ROI_TYPE_ALERT_CONFIRMED
		})

		if (userROIAlertCanCalaulate) {
			await updateUserROI(
				type,
				threshold,
				printerId,
				simpleConfigurationSelectorService,
				printersFullData,
				dispatch
			)
		}
	}
}

export const onUserROICompanyChange = (
	value: string
): ActionWithPayload<any> => {
	const userROIPrinters =
		simpleConfigurationSelectorService.getPrintersNamesList(value)
	return {
		type: USER_PANEL_ROI_PRINTER_COMPANY_CHANGED,
		payload: { userROIPrinters, value }
	}
}

export const onUserROIPrinterChange = (
	value: ISimpleConfigurationPrinter | string,
	type: PRINTING_SAVING_TYPES,
	threshold: number,
	printersFullData: ISimpleConfigurationCompany[]
): any => {
	return async (dispatch: any) => {
		dispatch({
			type: USER_PANEL_ROI_PRINTER_CHANGED,
			payload: { value }
		})

		await updateUserROI(
			type,
			threshold,
			(value as ISimpleConfigurationPrinter).printerId,
			simpleConfigurationSelectorService,
			printersFullData,
			dispatch
		)
	}
}
