import {
  PART_ADMIN_SERVER_CALL_FAILED,
  PART_ADMIN_DELETE_PARTS,
  PART_ADMIN_DELETE_PARTS_SUCCESS,
  PART_ADMIN_MERGE_PARTS,
  PART_ADMIN_MERGE_PARTS_SUCCESS,
  PART_ADMIN_SELECTED_ACTION,
  PART_ADMIN_GET_PARTS,
  PART_ADMIN_GET_PARTS_SUCCESS,
  PART_ADMIN_CHECKBOX_SELECTED,
  PART_ADMIN_DOWNLOAD_STL,
  PART_ADMIN_DOWNLOAD_STL_COMPLETE,
  PART_ADMIN_DOWNLOAD_STL_SUCCESS,
  PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED,
  PART_ADMIN_ALL_CLUSTER_PUBLISHED,
  PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
  PART_ADMIN_REJECT_CLUSTER_TOGGLED,
  PART_ADMIN_CLUSTER_PUBLISHED,
  PART_ADMIN_CLUSTER_REJECTED,
  PART_ADMIN_UPDATE_CLUSTER_TOGGLED,
  PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
  PART_ADMIN_CLUSTER_REMOVED,
  PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED
} from '../../../../global actions/types'
import { getString } from '../../../../Services/Strings/StringService'
import { ProjectClusterStatus } from '../../../../Services/models/IProject'
const INITIAL_STATE = {
  loading: false,
  error: null,
  parts: [],
  clusters: [],
  showingSimpleAlertTitle: null,
  showingSimpleAlertText: null,
  selectedAction: '',
  selectedPartsIds: [],
  stlDownloadURL: null,
  publishAllClustersLoading: false,
  updateClusterLoading: false,
  publishClusterLoaders: {},
  rejectClusterLoaders: {},
  removeClusterLoaders: {},
  clusterStructure: '',
  projectClusterHeaderText: ''
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PART_ADMIN_MERGE_PARTS:
    case PART_ADMIN_DELETE_PARTS:
    case PART_ADMIN_GET_PARTS:
      return { ...state, loading: true, error: null }
    case PART_ADMIN_SERVER_CALL_FAILED:
      return { ...state, loading: false, error: action.payload }
    case PART_ADMIN_GET_PARTS_SUCCESS: {
      const { parts, clusters, clusterStructure, project, displayComplex } = action.payload

      parts.forEach(part => {
        part.selected = false
        part.displayComplex = displayComplex
      })

      let projectClusterHeaderText = getProjectClusterHeaderText(
        project.clusterStatus
      )
      return {
        ...state,
        loading: false,
        parts,
        clusters,
        showingSimpleAlertTitle: null,
        showingSimpleAlertText: null,
        selectedPartsIds: [],
        clusterStructure,
        projectClusterHeaderText
      }
    }
    case PART_ADMIN_DELETE_PARTS_SUCCESS:
    case PART_ADMIN_MERGE_PARTS_SUCCESS:
      const parts = action.payload
      parts.forEach(part => (part.selected = false))
      return {
        ...state,
        loading: false,
        parts,
        showingSimpleAlertTitle: null,
        showingSimpleAlertText: null,
        selectedPartsIds: []
      }
    case PART_ADMIN_SELECTED_ACTION:
      return { ...state, selectedAction: action.payload }
    case PART_ADMIN_CHECKBOX_SELECTED:
      const part = action.payload
      const selectedPartsIds = state.selectedPartsIds.slice()
      const indexIfSelected = selectedPartsIds.indexOf(part.id)
      const selected = indexIfSelected > -1
      part.selected = selected
      if (selected) {
        selectedPartsIds.splice(indexIfSelected, 1)
      } else {
        selectedPartsIds.push(part.id)
      }
      return { ...state, selectedPartsIds }
    case PART_ADMIN_DOWNLOAD_STL:
      return { ...state, loading: false, error: null }
    case PART_ADMIN_DOWNLOAD_STL_COMPLETE:
      return { ...state, stlDownloadURL: null }
    case PART_ADMIN_DOWNLOAD_STL_SUCCESS:
      return { ...state, stlDownloadURL: action.payload, load: false }
    case PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED:
      return {
        ...state,
        publishAllClustersLoading: !state.publishAllClustersLoading
      }
    case PART_ADMIN_ALL_CLUSTER_PUBLISHED:
      const { clusters } = action.payload
      return { ...state, publishAllClustersLoading: false, clusters }
    case PART_ADMIN_PUBLISH_CLUSTER_TOGGLED:
      const { clusterId } = action.payload
      return {
        ...state,
        publishClusterLoaders: {
          ...state.publishClusterLoaders,
          [clusterId]: !state.publishClusterLoaders[clusterId]
        }
      }
    case PART_ADMIN_CLUSTER_PUBLISHED: {
      const { clusters, clusterId } = action.payload
      return {
        ...state,
        publishClusterLoaders: {
          ...state.publishClusterLoaders,
          [clusterId]: false
        },
        clusters
      }
    }

    case PART_ADMIN_REJECT_CLUSTER_TOGGLED: {
      const { clusterId } = action.payload
      return {
        ...state,
        rejectClusterLoaders: {
          ...state.rejectClusterLoaders,
          [clusterId]: !state.rejectClusterLoaders[clusterId]
        }
      }
    }
    case PART_ADMIN_CLUSTER_REJECTED: {
      const { clusters, clusterId } = action.payload
      return {
        ...state,
        rejectClusterLoaders: {
          ...state.rejectClusterLoaders,
          [clusterId]: false
        },
        clusters
      }
    }
    case PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED:
      const { projectClusterStatus } = action.payload
      let projectClusterHeaderText = state.projectClusterHeaderText
      if (projectClusterStatus) {
        projectClusterHeaderText = getProjectClusterHeaderText(
          projectClusterStatus
        )
      }
      return {
        ...state,
        projectClusterHeaderText
      }
    case PART_ADMIN_REMOVE_CLUSTER_TOGGLED: {
      const { clusterId } = action.payload
      return {
        ...state,
        removeClusterLoaders: {
          ...state.removeClusterLoaders,
          [clusterId]: !state.removeClusterLoaders[clusterId]
        }
      }
    }
    case PART_ADMIN_CLUSTER_REMOVED: {
      const { clusters, clusterId } = action.payload
      return {
        ...state,
        removeClusterLoaders: {
          ...state.removeClusterLoaders,
          [clusterId]: false
        },
        clusters
      }
    }

    case PART_ADMIN_UPDATE_CLUSTER_TOGGLED:
      return {
        ...state,
        updateClusterLoading: !state.updateClusterLoading
      }
    default:
      return state
  }
}

const getProjectClusterHeaderText = clusterStatus => {
  let projectClusterHeaderText = getString('ADMIN_PARTS_CLUSTER_HEADER_TEXT_1')
  switch (clusterStatus) {
    case ProjectClusterStatus.awaitingAnalysis:
      projectClusterHeaderText += getString('AWAITING_ANALYSIS')
      break
    case ProjectClusterStatus.complete:
      projectClusterHeaderText += getString('COMPLETE')
      break
    case ProjectClusterStatus.failed:
      projectClusterHeaderText += getString('FAILED')
      break
    case ProjectClusterStatus.noClustersFound:
      projectClusterHeaderText += getString('NO_UNIFICATIONS_FOUND')
      break
    default:
      projectClusterHeaderText = getString(
        'ADMIN_UNIFICATIONS_NO_UNIFICATIONS_FOUND'
      )
      break
  }
  return projectClusterHeaderText
}
