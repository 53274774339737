import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SelectField from "@material-ui/core/Select";
import { FormHelperText } from "@material-ui/core";
import React, { InputHTMLAttributes } from "react";

const FormControlTSX: any = FormControl
const InputLabelTSX: any = InputLabel
const SelectFieldTSX: any = SelectField
const FormHelperTextTSX: any = FormHelperText

interface ISelect {
  input: InputHTMLAttributes<HTMLInputElement>,
  label?: string,
  meta: {
    error?: boolean
  }
  initialized: boolean,
  children?: any
  custom?: any
  disabled?: boolean
  errorMessage?: string
}

export const renderSelectField = ({
 input,
 label,
 meta: { error },
 children,
 initialized,
 ...custom
 }: ISelect) => (
  <FormControlTSX className="admin-form-field">
    <InputLabelTSX error={initialized && error}>{label}</InputLabelTSX>
    <SelectFieldTSX
      floatingLabelText={label}
      error={initialized && error}
      helperText={error}
      {...input}
      children={children}
      {...custom}
    />
    <FormHelperTextTSX error={initialized && error}>{error}</FormHelperTextTSX>
  </FormControlTSX>
)



export const renderSelectFieldTouched = ({
 label,
 meta: { error },
 children,
 initialized,
 custom,
 disabled,
 errorMessage
}: ISelect) => (
  <FormControlTSX error={initialized && error} className="admin-form-field">
    <InputLabelTSX>{label}</InputLabelTSX>
    <SelectFieldTSX 
      disabled={disabled}
      floatingLabelText={label}
      children={children}
      {...custom}
    />
    <FormHelperTextTSX error={initialized && error}>{errorMessage || error}</FormHelperTextTSX>
  </FormControlTSX>
)
