import Numeral from 'numeral'
import { Action, ActionWithPayload } from '../../../global actions/ActionModels'
import {
	USER_PANEL_DATA_FETCHED,
	USER_PANEL_UNMOUNTED,
	USER_PANEL_PRINTING_SAVING_SELECT_CHANGED,
	USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED,
	USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED,
	USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED,
	USER_PANEL_PRINTING_SAVING_DATA_FETCHED,
	USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR,
	USER_PANEL_ROI_TYPE_SELECT_CHANGED,
	USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED,
	USER_PANEL_ROI_TYPE_ALERT_CANCELLED,
	USER_PANEL_ROI_TYPE_ALERT_CONFIRMED,
	USER_PANEL_ROI_CALCULATION_STARTED,
	USER_PANEL_ROI_CALCULATION_FINISHED,
	USER_PANEL_ROI_PRINTER_COMPANY_CHANGED,
	USER_PANEL_ROI_PRINTER_CHANGED,
	USER_PANEL_ROI_CALCULATION_GOT_ERROR
} from '../../../global actions/types'
import { toFixedOnlyIfNeeded } from '../../../Services/global/toFixedOnlyIfNeeded'
import {
	IResultsSelectionOption,
	PRINTING_SAVING_TYPES,
	resultsSelectionOptions
} from '../../../Services/models/ResultsSelectionOptions'
import { getString } from '../../../Services/Strings/StringService'
import { IChartSeriesData } from '../../Components/PieChart'
import { ISimpleConfigurationPrinter } from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import {
	createUploadedPartsChartData,
	createUploadedPartsPieIndexes,
	createPrintingSavingChartData,
	createPrintingSavingIndexes
} from './UserPanelService'

export class UserPanelInitialState {
	readonly partsSavesMoney: number = 0
	readonly partsSavesTime: number = 0
	readonly userProductName: string = ''
	readonly userExpireDate: string = ''
	readonly showUpgrade: boolean = false
	readonly totalUploadedParts: number = 0
	readonly totalPrintableParts: number = 0
	readonly totalNotPrintableParts: number = 0
	readonly totalNotCostEffectiveParts: number = 0
	readonly totalNotSuitableParts: number = 0
	readonly uploadedPartsChartData: IChartSeriesData[] = []
	readonly uploadedPartsChartIndexes: any[] = []
	readonly printingSavingValue: IResultsSelectionOption = {
		name: getString('RESULTS_SELECTION_PRINTABLE'),
		type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
	}
	readonly priorPrintingSavingValue: IResultsSelectionOption = {
		name: getString('RESULTS_SELECTION_PRINTABLE'),
		type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
	}
	readonly printingSavingThreshold: number = 10
	readonly printingSavingThresholdError: boolean = false
	readonly showPrintingSavingAlert: boolean = false
	readonly printingSavingLoading: boolean = false
	readonly printingSavingTotalCostBenefit: number = 0
	readonly printingSavingManufacturing: number = 0
	readonly printingSavingInventory: number = 0
	readonly printingSavingShipments: number = 0
	readonly printingSavingChartData: IChartSeriesData[] = []
	readonly printingSavingIndexes: any[] = []
	readonly userRoiLoading: boolean = false
	readonly userROITypeValue: IResultsSelectionOption = {
		name: getString('RESULTS_SELECTION_PRINTABLE'),
		type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
	}
	readonly priorUserROITypeValue: IResultsSelectionOption = {
		name: getString('RESULTS_SELECTION_PRINTABLE'),
		type: PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
	}
	readonly showROITypeAlert: boolean = false
	readonly userROITypeThreshold: number = 10
	readonly userROITypeThresholdError: boolean = false
	readonly printersCompaniesList: Array<string> = []
	readonly userROIPrinters: Array<ISimpleConfigurationPrinter> = []
	readonly userROISelectedPrinterValue: ISimpleConfigurationPrinter | string =
		''
	readonly userROISelectedCompanyValue: string = ''
	readonly userROIAlertCanCalaulate: boolean = false
	readonly userROIMachineCost: number = 0
	readonly userROITraditionalSavingYears: number = 0
	readonly userROIDataText: string = ''
	readonly hideRoiData: boolean = true
	readonly userSubscriptionData: any = null
}

const initialState = new UserPanelInitialState()

const userPanelReducer = (
	state = initialState,
	{ type, payload }: ActionWithPayload<any>
) => {
	switch (type) {
		case USER_PANEL_DATA_FETCHED: {
			const {
				userActivity: { expire_date, myProduct, userSubscriptionData },
				userPartsCount,
				userProject: {
					notCostEffective,
					notPrintSuitable,
					notPrintable,
					printable,
					totalPartCount
				},
				type,
				threshold,
				data,
				userCurrencySign,
				printersCompaniesList,
				userROIPrinters,
				userROISelectedCompanyValue,
				userROISelectedPrinterValue,
				userROIMachineCost,
				userROITraditionalSavingYears,
				userROITypeValue,
				userROITypeThreshold,
				hideRoiData
			} = payload
			const userProductName = myProduct
			return {
				...state,
				partsSavesMoney: userPartsCount['cost-saving'] || state.partsSavesMoney,
				partsSavesTime: userPartsCount['time-saving'] || state.partsSavesTime,
				userProductName,
				userExpireDate: expire_date,
				userSubscriptionData,
				showUpgrade: userProductName !== 'enterprise',
				totalUploadedParts: totalPartCount,
				totalPrintableParts: printable,
				totalNotPrintableParts: notPrintable,
				totalNotCostEffectiveParts: notCostEffective,
				totalNotSuitableParts: notPrintSuitable,
				uploadedPartsChartData: createUploadedPartsChartData(
					printable,
					notPrintable,
					notCostEffective,
					notPrintSuitable
				),
				uploadedPartsChartIndexes: createUploadedPartsPieIndexes(
					printable,
					notPrintable,
					notCostEffective,
					notPrintSuitable
				),
				printingSavingThreshold: threshold || state.printingSavingThreshold,
				printingSavingValue: resultsSelectionOptions.find(
					resultsSelectionOption => resultsSelectionOption.type === type
				),
				...setPrintingSavingData(data, userCurrencySign),
				printersCompaniesList,
				userROIPrinters: userROIPrinters || [],
				userROISelectedCompanyValue: userROISelectedCompanyValue || '',
				userROISelectedPrinterValue: userROISelectedPrinterValue || '',
				userROIAlertCanCalaulate: !!userROISelectedPrinterValue,
				userROIMachineCost,
				userROITraditionalSavingYears,
				userROIDataText: getRoiDataText(userROITraditionalSavingYears),
				userROITypeValue: userROITypeValue || state.userROITypeValue,
				userROITypeThreshold:
					userROITypeThreshold || state.userROITypeThreshold,
				hideRoiData
			}
		}
		case USER_PANEL_PRINTING_SAVING_SELECT_CHANGED: {
			const { value } = payload
			const printingSavingValue = resultsSelectionOptions.find(
				resultsSelectionOption => resultsSelectionOption.type === value
			)
			return {
				...state,
				printingSavingValue,
				priorPrintingSavingValue: state.printingSavingValue,
				showPrintingSavingAlert: true,
				printingSavingThresholdError:
					printingSavingValue?.type ===
					PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
						? checkValidThreshold(state.printingSavingThreshold)
						: false
			}
		}
		case USER_PANEL_PRINTING_SAVING_THRESHOLD_CHANGED: {
			const { threshold } = payload
			return {
				...state,
				printingSavingThreshold: threshold,
				printingSavingThresholdError: checkValidThreshold(threshold)
			}
		}
		case USER_PANEL_UNMOUNTED:
			return { ...initialState }
		case USER_PANEL_PRINTING_SAVING_ALERT_CANCELLED:
			return {
				...state,
				showPrintingSavingAlert: false,
				printingSavingValue: state.priorPrintingSavingValue
			}
		case USER_PANEL_PRINTING_SAVING_ALERT_CONFIRMED:
			return {
				...state,
				printingSavingLoading: true,
				showPrintingSavingAlert: false
			}
		case USER_PANEL_PRINTING_SAVING_DATA_FETCHED: {
			const { data, userCurrencySign } = payload
			return {
				...state,
				printingSavingLoading: false,
				...setPrintingSavingData(data, userCurrencySign)
			}
		}
		case USER_PANEL_PRINTING_SAVING_DATA_GOT_ERROR:
			return { ...state, printingSavingLoading: false }
		case USER_PANEL_ROI_TYPE_SELECT_CHANGED: {
			const { value } = payload
			const userROITypeValue = resultsSelectionOptions.find(
				resultsSelectionOption => resultsSelectionOption.type === value
			)
			return {
				...state,
				userROITypeValue,
				priorUserROITypeValue: state.userROITypeValue,
				showROITypeAlert: true,
				userROITypeThresholdError:
					userROITypeValue?.type ===
					PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD
						? checkValidThreshold(state.userROITypeThreshold)
						: false
			}
		}
		case USER_PANEL_ROI_TYPE_THRESHOLD_CHANGED: {
			const { threshold } = payload
			return {
				...state,
				userROITypeThreshold: threshold,
				userROITypeThresholdError: checkValidThreshold(threshold)
			}
		}
		case USER_PANEL_ROI_TYPE_ALERT_CANCELLED:
			return {
				...state,
				showROITypeAlert: false,
				userROITypeValue: state.priorUserROITypeValue
			}
		case USER_PANEL_ROI_TYPE_ALERT_CONFIRMED:
			return {
				...state,
				showROITypeAlert: false
			}
		case USER_PANEL_ROI_CALCULATION_STARTED:
			return {
				...state,
				userRoiLoading: true
			}
		case USER_PANEL_ROI_CALCULATION_FINISHED: {
			const {
				printersCompaniesList,
				userROIPrinters,
				userROISelectedCompanyValue,
				userROISelectedPrinterValue,
				userROITraditionalSavingYears,
				userROITypeValue,
				userROITypeThreshold,
				userROIMachineCost,
				hideRoiData
			} = payload
			return {
				...state,
				userRoiLoading: false,
				printersCompaniesList,
				userROIPrinters: userROIPrinters || [],
				userROISelectedCompanyValue: userROISelectedCompanyValue || '',
				userROISelectedPrinterValue: userROISelectedPrinterValue || '',
				userROIMachineCost,
				userROITraditionalSavingYears,
				userROIDataText: getRoiDataText(userROITraditionalSavingYears),
				userROITypeValue: userROITypeValue || state.userROITypeValue,
				userROITypeThreshold:
					userROITypeThreshold || state.userROITypeThreshold,
				hideRoiData
			}
		}
		case USER_PANEL_ROI_CALCULATION_GOT_ERROR:
			return {
				...state,
				userRoiLoading: false
			}
		case USER_PANEL_ROI_PRINTER_COMPANY_CHANGED: {
			const { userROIPrinters, value } = payload
			return {
				...state,
				userROIPrinters,
				userROISelectedCompanyValue: value,
				userROISelectedPrinterValue: '',
				userROIAlertCanCalaulate: false
			}
		}
		case USER_PANEL_ROI_PRINTER_CHANGED: {
			const { value } = payload
			return {
				...state,
				userROISelectedPrinterValue: value,
				userROIAlertCanCalaulate: true
			}
		}
		default:
			return state
	}
}

const setPrintingSavingData = (
	{ chainBenefitsData: { upfront = 0, manufacturing = 0, inventory = 0 } }: any,
	userCurrencySign: string
) => {
	return {
		printingSavingTotalCostBenefit: upfront + manufacturing + inventory,
		printingSavingUpfront: upfront,
		printingSavingManufacturing: manufacturing,
		printingSavingInventory: inventory,
		printingSavingChartData: createPrintingSavingChartData(
			upfront,
			manufacturing,
			inventory,
			userCurrencySign
		),
		printingSavingIndexes: createPrintingSavingIndexes(
			upfront,
			manufacturing,
			inventory,
			userCurrencySign
		)
	}
}

export default userPanelReducer

const getRoiDataText = (userROITraditionalSavingYears: number): string => {
	if (userROITraditionalSavingYears > 10) {
		return getString('USER_PANEL_ROI_MORE_THAN')
	}
	if (userROITraditionalSavingYears > 0) {
		return `${toFixedOnlyIfNeeded(userROITraditionalSavingYears)} ${getString(
			'YEARS'
		)}`
	}
	return ''
}

const checkValidThreshold = (threshold: number) => {
	return threshold < 10 || threshold > 90
}
