import React, { FC, memo } from 'react'

import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import StatusViewerPage from './index'
import { UNPRINTABLE } from './ProjectPendingConstants'
import Flexbox from 'Scenes/Components/FlexBox'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components/index.js'
import {
	PROJECT_FAILED_SUBTITLE,
	PROJECT_FAILED_TEXT,
	PROJECT_FAILED_VALUE,
	PROJECT_PENDING_SUBTITLE,
	PROJECT_PENDING_TEXT,
	PROJECT_PENDING_TITLE
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import inPogressImg from 'assets/img/svg/inProgress.svg'

import './ProjectDenied.scss'

interface ProjectFailProps {
	projectId: string
	projectFail: boolean
	quickProjectConnectionError: boolean
	userHasNoPermissions?: boolean
}

const ProjectFail: FC<ProjectFailProps> = ({
	projectId,
	projectFail,
	quickProjectConnectionError,
	userHasNoPermissions
}) => {
	if (quickProjectConnectionError) {
		return (
			<div>
				<NavBarAndMaterial title={PROJECT_PENDING_TITLE}>
					<Flexbox
						style={{ margin: 20 }}
						flexDirection="column"
						alignItems="center"
						justifyContent="center"
						className="custom-error"
					>
						<StatusViewerPage
							header={''}
							subheader={getString('POLLER_CANNOT_CONNECT_TO_THE_SERVER')}
							buttonText={''}
							iconName={''}
							imageSrc={''}
							hide={quickProjectConnectionError}
							onSendProjectFailedToAdmins={() => {}}
							projectId={projectId}
						></StatusViewerPage>
						<Button onClick={() => window.location.reload()}>
							{getString('RELOAD_THE_PAGE')}
						</Button>
					</Flexbox>
				</NavBarAndMaterial>
			</div>
		)
	}

	if (userHasNoPermissions) {
		return (
			<div>
				<NavBarAndMaterial title={PROJECT_PENDING_TITLE}>
					<StatusViewerPage
						header={getString('PROJECT_IS_NOT_AVAILABLE')}
						subheader={''}
						buttonText={''}
						iconName={UNPRINTABLE}
						imageSrc={''}
						hide={projectFail}
						onSendProjectFailedToAdmins={() => {}}
						projectId={projectId}
					></StatusViewerPage>
				</NavBarAndMaterial>
			</div>
		)
	}

	return (
		<div>
			<NavBarAndMaterial title={PROJECT_PENDING_TITLE}>
				<StatusViewerPage
					header={
						projectFail ? PROJECT_FAILED_SUBTITLE : PROJECT_PENDING_SUBTITLE
					}
					subheader={projectFail ? PROJECT_FAILED_TEXT : PROJECT_PENDING_TEXT}
					buttonText={projectFail ? PROJECT_FAILED_VALUE : ''}
					iconName={projectFail ? UNPRINTABLE : ''}
					imageSrc={projectFail ? '' : inPogressImg}
					hide={projectFail}
					onSendProjectFailedToAdmins={() => {}}
					projectId={projectId}
				></StatusViewerPage>
			</NavBarAndMaterial>
		</div>
	)
}

export default memo(ProjectFail)
