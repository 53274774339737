import { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import isString from 'lodash/isString'
import TextField from '@material-ui/core/TextField'

import {
	EMPTY_FIELD_VALUE,
	MAX_NUMBER_FIELD_VALUE,
	MIN_NUMBER_FIELD_VALUE
} from 'Services/Constants'
import {
	isCommaKeyPressed,
	isDisabledKeyPressed,
	isDotKeyPressed,
	isDownKeyPressed,
	isEnterKeyPressed,
	isUpKeyPressed
} from 'Services/getKeyCodesService'

import './NumberField.scss'

const InputField: any = TextField

interface IProps {
	placeholder?: string
	value: number | null
	onChangeValue: Function
	onKeyUp?: Function
	inputClass?: string
	minValue?: number | string
	maxValue?: number
	idInput?: string
	variant?: string
	label?: string
	shrink?: boolean
	helperText?: string
	showArrows?: boolean
	disabled?: boolean
	allowEmpty?: boolean
	allowZero?: boolean
	changeOnBlur?: boolean
	changeOnMouseOut?: boolean
	changeOnScroll?: boolean
	isCommaDisabled?: boolean
	isDotDisabled?: boolean
	step?: number
	error?: boolean
	disableCheckMaxValue?: boolean
	disableCheckMinValue?: boolean
	disableCopyPaste?: boolean
	onKeyPress?: Function
	qaDataElementName?: string
}

const NumberField: FC<IProps> = ({
	placeholder,
	value,
	onChangeValue,
	inputClass,
	minValue = MIN_NUMBER_FIELD_VALUE,
	maxValue = MAX_NUMBER_FIELD_VALUE,
	idInput,
	onKeyUp,
	variant,
	label,
	helperText,
	shrink,
	showArrows = true,
	disabled,
	allowEmpty = true,
	allowZero,
	changeOnBlur,
	changeOnMouseOut,
	changeOnScroll,
	isCommaDisabled = true,
	isDotDisabled = true,
	step = 1,
	error = false,
	onKeyPress,
	disableCheckMaxValue = false,
	disableCheckMinValue = false,
	disableCopyPaste = false,
	qaDataElementName
}) => {
	const initValue = value || minValue || ''
	const [number, setNumber] = useState<string | number | null>(initValue)

	useEffect(() => {
		setNumber(value)
	}, [value])

	const checkIfValueExist = (value: number | string) => value || value === 0

	const checkOnMinValue = (numberValue: number) =>
		!disableCheckMinValue &&
		checkIfValueExist(minValue) &&
		numberValue < minValue

	const checkOnMaxValue = (numberValue: number) =>
		!disableCheckMaxValue && maxValue && numberValue > maxValue

	const getValue = ({
		target: { value: eventValue }
	}: ChangeEvent<HTMLInputElement>): number | string => {
		const numberValue = +eventValue
		if (checkIfValueExist(numberValue)) {
			if (checkOnMinValue(numberValue)) return minValue
			if (checkOnMaxValue(numberValue)) return maxValue
			return eventValue
		} else {
			return allowEmpty ? EMPTY_FIELD_VALUE : minValue
		}
	}

	const emptyFunc = () => {}

	const setNewValue = (e: ChangeEvent<HTMLInputElement>) => {
		let newValue = getValue(e)
		setNumber(newValue)
		onChangeValue(newValue)
	}

	const onKeyUpChange = (e: KeyboardEvent | ChangeEvent<HTMLInputElement>) => {
		if (onKeyUp && isEnterKeyPressed(e as KeyboardEvent)) {
			const newValue = getValue(e as ChangeEvent<HTMLInputElement>)
			setNewValue(e as ChangeEvent<HTMLInputElement>)
			onKeyUp(newValue)
		}
	}

	const onBlurWheel = (e: ChangeEvent<HTMLInputElement>) => {
		e.target.blur()
	}

	const mutateEventValue = (e: any, keyDownPressed?: boolean) => {
		const value = e.target.value
		const numberValue = isString(value) ? +value : value
		const updatedValue = keyDownPressed
			? numberValue - step
			: numberValue + step
		const roundedValue = parseFloat(
			(Math.round(updatedValue * 100) / 100).toFixed(2)
		)

		return {
			...e,
			target: {
				...e.target,
				value: roundedValue
			}
		}
	}

	const checkKeyUpDownValue = (e: any) => {
		if (step) {
			if (isDownKeyPressed(e)) {
				e.preventDefault()
				const newEvent = mutateEventValue(e, true)
				setNewValue(newEvent)
			}

			if (isUpKeyPressed(e)) {
				e.preventDefault()
				const newEvent = mutateEventValue(e, false)
				setNewValue(newEvent)
			}
		}
	}

	const onKeyDownFunc = (e: KeyboardEvent) => {
		isCommaDisabled && isCommaKeyPressed(e) && e.preventDefault()
		isDotDisabled && isDotKeyPressed(e) && e.preventDefault()
		isDisabledKeyPressed(e) && e.preventDefault()

		checkKeyUpDownValue(e)
	}

	const handleCopyPasteChange = (e: KeyboardEvent) => {
		if (disableCopyPaste) {
			e.preventDefault()
		}
	}

	let inputProps = {
		inputProps: {
			min: minValue,
			...(disableCheckMaxValue ? {} : { max: maxValue }),
			step: step,
			'data-qa': qaDataElementName
		}
	}

	return (
		<InputField
			label={label}
			onKeyUp={onKeyUpChange}
			helperText={helperText}
			placeholder={placeholder || ''}
			value={number}
			error={error}
			onCopy={handleCopyPasteChange}
			onPaste={handleCopyPasteChange}
			onChange={setNewValue}
			onBlur={changeOnBlur ? setNewValue: emptyFunc}
			onMouseOut={changeOnMouseOut ? setNewValue: emptyFunc}
			onScroll={changeOnScroll ? setNewValue : emptyFunc}
			onWheel={onBlurWheel}
			onKeyDown={onKeyDownFunc}
			className={inputClass + `${showArrows ? '' : ' hide-arrows'}`}
			InputProps={inputProps}
			InputLabelProps={{
				shrink: shrink
			}}
			variant={variant || 'standard'}
			type="number"
			id={idInput || ''}
			autoFocus={false}
			disabled={!!disabled}
			onKeyPress={onKeyPress}
		/>
	)
}

export default memo(NumberField)