import React, { FC, memo, ReactNode } from 'react'

import { INAPPLICABLE, LOADING } from '../../../../Services/Constants'
import { CastorPartBenefits } from '../../../Components/CastorPartBenefits/CastorPartBenefits'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import { partToResultLabel } from '../ProjectAnalysisService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { PartStatus } from 'Services/models/IPart'
import { ISolutionBenefit } from 'Services/models/ISolutionBenefit'

interface Props {
	result: CADAnalysisResult
	isPartUpdating?: boolean
	benefits?: ISolutionBenefit[]
	customBenefitSection?: ReactNode
	orientationInfoButtonClicked?: Function
	labelTextClassName?: string
	benefitIconClassName?: string
	showHoverData?: boolean
	isMissingInfo?: boolean
	isInapplicable?: boolean
	unprintableReason?: string
	extraUnprintableReasonsCount?: number
}

const renderBenefitSection = (
	benefits?: ISolutionBenefit[],
	customBenefitSection?: ReactNode,
	benefitIconClassName?: string,
	showHoverData?: boolean
) => {
	if (!benefits && !customBenefitSection) {
		return <div></div>
	}
	return (
		customBenefitSection || (
			<CastorPartBenefits
				showHoverData={showHoverData}
				benefits={benefits}
				small
				iconClassName={benefitIconClassName}
				className="grid-list-benefits"
			/>
		)
	)
}

const renderIcon = (iconName: string, isPartUpdating?: boolean) => {
	if (isPartUpdating) {
		return <div></div>
	}
	return <IconFactory iconName={iconName} className="card--result-label-icon" />
}

const PartCardFooter: FC<Props> = ({
	result,
	benefits,
	customBenefitSection,
	isPartUpdating,
	labelTextClassName = '',
	benefitIconClassName = '',
	showHoverData = true,
	isMissingInfo = false,
	isInapplicable = false,
	unprintableReason,
	extraUnprintableReasonsCount
}) => {
	const MissingAndNotCostEffective =
		isMissingInfo && result !== CADAnalysisResult.notCostEffective
	const partResultStatus: any = isInapplicable
		? INAPPLICABLE
		: MissingAndNotCostEffective
		? PartStatus.missingInformation
		: result
	const iconName = MissingAndNotCostEffective
		? PartStatus.missingInformation
		: result || CADAnalysisResult.notPrintable
	const resultLabel = partToResultLabel(
		isPartUpdating ? LOADING : partResultStatus
	)
	const isPartUnprintable =
		result === CADAnalysisResult.notPrintable && !isMissingInfo

	return (
		<>
			{renderIcon(iconName, isPartUpdating)}
			<div
				title={!isPartUnprintable && resultLabel}
				className={`card--result-label-text ${labelTextClassName}`}
			>
				<DetailsPopup
					popperDirection="bottom-start"
					isHover={!isPartUpdating && isPartUnprintable && !!unprintableReason}
					data={
						<div>
							{unprintableReason}
							{!!extraUnprintableReasonsCount && (
								<span className="card--result-label-text--extra-reason">
									+{extraUnprintableReasonsCount}
								</span>
							)}
						</div>
					}
					popperClassName="unprintable-popup"
					popperContactClassName="unprintable-popup--data"
					inPortal
				>
					{resultLabel}
				</DetailsPopup>
			</div>
			{renderBenefitSection(
				benefits,
				customBenefitSection,
				benefitIconClassName,
				showHoverData
			)}
		</>
	)
}

export default memo(PartCardFooter)
