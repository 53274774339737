import React, { memo, FC } from 'react'
import { GridListTile, GridList } from '@material-ui/core'

import ProjectPartItem from '../../../../../../ProjectAnalysis/ProjectPartItem'

import '../SolutionAnalysisTabs.scss'

const GridListTSX: any = GridList
const GridListTileTSX: any = GridListTile

interface IProps {
  parts: any[]
  onPartClick: Function
}

const ClusterPartsTab: FC<IProps> = ({ parts, onPartClick }) => {
  return (
    <GridListTSX className="solution-tab-cluster" spacing={15} cols={3}>
      {parts.map(part => (
        <GridListTileTSX
          className="solution-tab-cluster-part-tile"
          classes={{ tile: 'solution-tab-cluster-part-tile--element' }}
          key={part.id}
          sm={4}
        >
          <ProjectPartItem
            part={part}
            onPartClick={() => onPartClick(part)}
            cardClassName="solution-tab-cluster-part-tile--card"
            cardSizeWrapperClassName="solution-tab-cluster-part-tile--card--image-wrapper"
            cardContentClassName="solution-tab-cluster-part-tile--card--content"
          />
        </GridListTileTSX>
      ))}
    </GridListTSX>
  )
}

export default memo(ClusterPartsTab)
