import React, { memo, FC } from 'react'
import EmptyComponent from './customComponents/EmptyComponent'

interface IProps {
  name: string
}

const CustomComponentsFactory: FC<IProps> = ({ name, ...props }) => {
  const renderComponent = () => {
    switch (name) {
      case 'empty':
        return <EmptyComponent {...props} />
      default:
        return <div />
    }
  }
  return renderComponent()
}

export default memo(CustomComponentsFactory)
