import React, { ChangeEvent, FC, memo, ReactNode } from 'react'
import { MenuItem } from '@material-ui/core'
import SelectMenu from '../SimpleConfigurationSelector/SelectMenu'
import {
  IResultsSelectionOption,
  PRINTING_SAVING_TYPES,
  resultsSelectionOptions
} from '../../../Services/models/ResultsSelectionOptions'

import './CalculationAccordingSelect.scss'

const MenuItemTsx: any = MenuItem

interface IProps {
  explanationText: string
  selectValue: string
  onSelectChange: Function
}

const CalculationAccordingSelect: FC<IProps> = ({
  explanationText,
  selectValue,
  onSelectChange
}) => {
  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    onSelectChange(e.target.value)
  }
  return (
    <div className="stats-clac-according">
      <div className="stats-clac-according--explanation">{explanationText}</div>
      <SelectMenu
        hideTitle
        value={selectValue}
        rootClassName="stats-clac-according--explanation--select"
        onChange={onChange}
        classes={{ select: 'stats-clac-according--explanation--select--item' }}
      >
        {resultsSelectionOptions.map(
          (resultsSelectionOption: IResultsSelectionOption) => (
            <MenuItemTsx
              key={resultsSelectionOption.type}
              value={resultsSelectionOption.type}
            >
              {resultsSelectionOption.name}
            </MenuItemTsx>
          )
        )}
      </SelectMenu>
    </div>
  )
}

export default memo(CalculationAccordingSelect)
