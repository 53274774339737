import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { AnyAction, bindActionCreators } from 'redux'

import * as AdminUsersActions from '../AdminUsersActions'
import AdminUserForm from '../AdminUserForm'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import {
	ADMIN_USER_INFO_EDIT_HEADER,
	ADMIN_USER_INFO_EDIT_SUB_HEADER
} from 'Services/Strings'

import '../../adminHome.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

interface IProps extends RouteComponentProps<MatchParams> {
	onUserUpdateSubmitClick: Function
	onRemoveUserClick: Function
	userSelected: any
	userUpdateLoading: boolean
	loading: boolean
	fetchUserInfo: Function
}

interface IReduxStore {
	AdminUsersReducer: any
}

interface MatchParams {
	user: string
}

const AdminUserSubscriptionEdit: FC<IProps> = ({
	onUserUpdateSubmitClick,
	onRemoveUserClick,
	userSelected,
	userUpdateLoading,
	match: { params },
	fetchUserInfo,
	loading
}) => {
	useEffect(() => {
		if (!userSelected && !loading) {
			const { user } = params
			fetchUserInfo(user)
		}
	}, [userSelected, loading])

	return (
		<NavBarAndMaterialTSX title={ADMIN_USER_INFO_EDIT_HEADER}>
			<div>
				<CastorForm
					formTitle={ADMIN_USER_INFO_EDIT_SUB_HEADER}
					content={
						<AdminUserForm
							initialValues={userSelected}
							onSubmitClick={(data: any) =>
								onUserUpdateSubmitClick(userSelected, data)
							}
							userUpdateLoading={userUpdateLoading}
							onRemoveUser={(data: any) => onRemoveUserClick(userSelected)}
						/>
					}
					style={{ maxWidth: 'unset' }}
				/>
			</div>
		</NavBarAndMaterialTSX>
	)
}

const mapStateToProps = ({ AdminUsersReducer }: IReduxStore) => {
	return {
		...AdminUsersReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminUsersActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(AdminUserSubscriptionEdit)
)
