import {
  ADMIN_HOME_DRAW_TOGGLE,
  SETUP_ADMIN_HOME
} from '../../../global actions/types'
import AdminRoutes from './routes/app'

const INITIAL_STATE = {
  loading: false,
  projects: [],
  routes: [],
  error: null,
  routesNotAllowed: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SETUP_ADMIN_HOME: {
      const { routes, routesNotAllowed } = action.payload
      return { ...state, routes, routesNotAllowed }
    }
    case ADMIN_HOME_DRAW_TOGGLE:
      return { ...state, mobileOpen: !state.mobileOpen }

    default:
      return state
  }
}
