import React, { FC } from 'react'

import { isString } from 'lodash'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Flexbox from 'Scenes/Components/FlexBox'
import NumberField from 'Scenes/Components/NumberField'
import { Muted } from 'Scenes/Components/thirdParty/CreativeTim/components'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { OrientationData } from 'Services/models/IOrinetationData'
import { IStandardCostData } from 'Services/models/IStandardCostData'
import {
	CHANGE_QUANTITY,
	FINANCIAL_COUNT_LABEL_1,
	FINANCIAL_COUNT_LABEL_2
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const makeNumber = (value: string | number) =>
	isString(value) ? parseInt(value) : value

const onKeyUp = (
	value: number,
	onCostQuantityUpdated: Function,
	configuration: any,
	part: IStandardCostData,
	manufacturingMethod: string,
	tempChainBenefits: any,
	expectedYearsOfDemand: number,
	chosenOrientation: OrientationData | null,
	isLeadTime?: boolean
) => {
	onCostQuantityUpdated(
		configuration.id,
		makeNumber(value),
		configuration.postProcessesOptional || {},
		part,
		manufacturingMethod,
		tempChainBenefits,
		expectedYearsOfDemand,
		chosenOrientation,
		isLeadTime
	)
}

interface ProductionNumberProps {
	onCostQuantityUpdated: Function
	configuration: any
	part: IStandardCostData
	manufacturingMethod: string
	tempChainBenefits: any
	expectedYearsOfDemand: number
	chosenOrientation: OrientationData | null
	batchSize: number
	onCostQuantityChanged: Function
	lowBatchSize?: boolean
	isLeadTime?: boolean
	disabled?: boolean
	shouldShowStandardCost?: boolean
	costLoading: boolean
}

const ProductionNumber: FC<ProductionNumberProps> = ({
	onCostQuantityUpdated,
	configuration,
	part,
	manufacturingMethod,
	tempChainBenefits,
	expectedYearsOfDemand,
	chosenOrientation,
	batchSize,
	onCostQuantityChanged,
	lowBatchSize,
	isLeadTime,
	disabled,
	shouldShowStandardCost,
	costLoading
}) => {
	if (
		!Feature.isFeatureOn(FeatureComponentId.COST_TAB_INFORMATION) ||
		part?.isDrawing ||
		!configuration.isSpecifiedQuantity
	) {
		return <></>
	}
	let disableParameters = !Feature.isFeatureActive(
		FeatureComponentId.COST_TAB_INFORMATION
	)
	let changeQuantity = Feature.isFeatureOn(
		FeatureComponentId.CHANGE_QUANTITY_CONFIGURATION
	)

	return (
		<Flexbox flexDirection="row" alignItems="center">
			<Muted className="nowrap">{FINANCIAL_COUNT_LABEL_1}</Muted>
			<NumberField
				disabled={
					disabled ||
					lowBatchSize ||
					disableParameters ||
					shouldShowStandardCost
				}
				onKeyUp={(value: number) =>
					onKeyUp(
						value,
						onCostQuantityUpdated,
						configuration,
						part,
						manufacturingMethod,
						tempChainBenefits,
						expectedYearsOfDemand,
						chosenOrientation,
						isLeadTime
					)
				}
				value={batchSize}
				onChangeValue={(value: number) =>
					onCostQuantityChanged(configuration.id, makeNumber(value))
				}
				changeOnScroll={true}
				inputClass="financial-form-quantity"
				idInput="plastic-form-quantity"
			/>
			<Muted>{FINANCIAL_COUNT_LABEL_2}</Muted>
			{changeQuantity && (
				<DetailsPopup
					isHover={disableParameters}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
					targetClassName="regular-style-target"
				>
					<TransparentButton
						onClick={() =>
							onCostQuantityUpdated(
								configuration.id,
								batchSize,
								configuration.postProcessesOptional || {},
								part,
								manufacturingMethod,
								tempChainBenefits,
								expectedYearsOfDemand,
								chosenOrientation,
								isLeadTime
							)
						}
						disabled={
							lowBatchSize ||
							disableParameters ||
							shouldShowStandardCost ||
							costLoading
						}
						className="financial-form-button"
						data-qa="data-qa-change-quantity-button"
					>
						{CHANGE_QUANTITY}
					</TransparentButton>
				</DetailsPopup>
			)}
		</Flexbox>
	)
}

export default ProductionNumber
