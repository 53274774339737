import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import typographyStyle from '../../variables/styles/typographyStyle.jsx'

function Muted({ ...props }) {
  const { classes, children, style, title, className } = props
  return (
    <div
      title={title}
      style={style}
      className={
        classes.defaultFontStyle + ' ' + classes.mutedText + ' ' + className
      }
    >
      {children}
    </div>
  )
}

Muted.propTypes = {
  classes: PropTypes.object.isRequired
}

export default memo(withStyles(typographyStyle)(Muted))
