import {
  GET_ADMIN_PRINTER_MATERIALS_SUCCESS,
  GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR,
  GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED,
  ADMIN_PRINTER_MATERIAL_DELETED,
  ADMIN_PRINTER_MATERIAL_UPDATED,
  ADMIN_PRINTER_MATERIAL_ADDED,
  ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED,
  NEW_ITEM_ADD_CALLED,
  NEW_ITEM_ADD_FAILED,
  GET_ADMIN_PRINTER_MATERIALS,
  GET_ALL_PRINTERS_ERROR,
  GET_ALL_PRINTERS_SUCCESS,
  GET_ALL_PRINTERS_LOADING
} from '../../../../global actions/types'
import {
  addedDeviationExtToProperty,
  checkMachining,
  getPrinterCompanyName
} from './AdminPrinterMaterialService'
import { initialPrinterMaterialState } from './constants'

const INITIAL_STATE = {
  allPrintersCompanies: {},
  allCategories: [],
  printerMaterials: [],
  totalPrinterMaterials: 0,
  printerMaterialsTableData: [],
  showNoPrinterMaterialsAlert: false,
  pageNumber: 1,
  isLastPage: false,
  NumOfPrinterMaterialsSeen: 0,
  showPagination: false,
  limitPrinterMaterials: 50,
  showAdminPrinterMaterialsAlert: false,
  printerMaterialAdded: false,
  searchPhrase: '',
  addingNewItem: false,
  loading: false,
  selectedEditPrinterMaterial: null,
  selectedNewPrinterMaterial: initialPrinterMaterialState
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ALL_PRINTERS_ERROR: {
      return { ...state, loading: false, showAdminPrinterMaterialsAlert: true }
    }
    case GET_ALL_PRINTERS_SUCCESS: {
      const { allPrintersCompanies, allCategories } = action.payload
      return {
        ...state,
        allPrintersCompanies,
        loading: false,
        allCategories: allCategories
      }
    }
    case GET_ALL_PRINTERS_LOADING: {
      return { ...state, loading: true }
    }
    case GET_ADMIN_PRINTER_MATERIALS:
      return { ...state, loading: true }
    case GET_ADMIN_PRINTER_MATERIALS_SUCCESS:
      const { data, pageNumber } = action.payload,
        { printerMaterials, totalPrinterMaterials } = data,
        NumOfPrinterMaterialsSeen =
          (pageNumber - 1) * state.limitPrinterMaterials +
          printerMaterials.length,
        isLastPage = NumOfPrinterMaterialsSeen >= totalPrinterMaterials
      return {
        ...state,
        printerMaterials,
        totalPrinterMaterials,
        printerMaterialsTableData: printerMaterials.map(printerMaterial =>
          Object.values(printerMaterial)
        ),
        showNoPrinterMaterialsAlert: !printerMaterials.length,
        pageNumber,
        NumOfPrinterMaterialsSeen,
        isLastPage,
        showPagination: !(isLastPage && pageNumber === 1),
        showAdminPrinterMaterialsAlert: false,
        printerMaterialAdded: false,
        addingNewItem: false,
        loading: false
      }
    case ADMIN_PRINTER_MATERIAL_UPDATED:
      const updatedPrinterMaterial = action.payload
      return {
        ...state,
        printerMaterials: state.printerMaterials.map(printerMaterial =>
          printerMaterial.id === updatedPrinterMaterial.id
            ? updatedPrinterMaterial
            : printerMaterial
        )
      }
    case GET_ADMIN_PRINTER_MATERIALS_GOT_ERROR:
      return {
        ...state,
        showAdminPrinterMaterialsAlert: true,
        loading: false
      }
    case GET_ADMIN_PRINTER_MATERIALS_EDIT_PRINTER_MATERIAL_SELECTED:
      const { allPrintersCompanies } = state
      const preparedDataMachining = checkMachining(action.payload)
      const getPrinterMaterial = addedDeviationExtToProperty(
        preparedDataMachining
      )
      const printerName = getPrinterMaterial?.printers?.trim() || ''
      const printerCompany = getPrinterCompanyName(
        allPrintersCompanies,
        printerName
      )

      return {
        ...state,
        selectedEditPrinterMaterial: {
          ...getPrinterMaterial,
          printers: printerName,
          printerCompany: printerCompany
        },
        printerMaterialAdded: false
      }
    case ADMIN_PRINTER_MATERIAL_DELETED:
      const id = action.payload
      return {
        ...state,
        printerMaterials: state.printerMaterials.filter(
          printerMaterial => printerMaterial.id !== id
        )
      }
    case ADMIN_PRINTER_MATERIAL_ADDED:
      const newPrinterMaterial = action.payload

      return {
        ...state,
        printerMaterials: [...state.printerMaterials, newPrinterMaterial],
        printerMaterialAdded: true,
        addingNewItem: false
      }
    case ADMIN_PRINTER_MATERIAL_SEARCH_PHRASE_CHANGED:
      const searchPhrase = action.payload
      return {
        ...state,
        searchPhrase
      }
    case NEW_ITEM_ADD_CALLED:
      return {
        ...state,
        addingNewItem: true
      }
    case NEW_ITEM_ADD_FAILED:
      return {
        ...state,
        addingNewItem: false
      }
    default:
      return state
  }
}
