import parse from 'html-react-parser'
import { RootStateOrAny, useSelector } from 'react-redux'

import { toNumber } from 'lodash'

import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { drawingFiles } from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { uploadProjectComponents } = getTheme()

export const useUploadErrorMessage = (
	selectedType: string,
	materialNotSelected: boolean,
	BOMFileNotSelected: boolean,
	errorUnit: boolean,
	quantity: number,
	manufactureMethodError: boolean,
	projectStandardCostError: boolean,
	projectName: string,
	isRemainingParts: boolean,
	error?: string,
	userDateExpired?: boolean,
	isAdmin?: boolean,
	fileLimit?: number,
	enableUploadWithErrorMessage?: boolean,
	agreement?: boolean,
	isStandardCostBoxChecked?: boolean
): {
	errorMessage: string | null | React.ReactNode
	disableByErrorMessage: boolean
} => {
	const addProjectName = Feature.isFeatureOn(
		FeatureComponentId.ADD_PROJECT_NAME
	)
	const isUsageAgreement = Feature.isFeatureOn(
		FeatureComponentId.SHOW_AGREEMENT
	)
	const user = useSelector((state: RootStateOrAny) => state.user)
	let errorMessage: React.ReactNode | string | null = null
	let disableByErrorMessage = true

	if (!agreement && isUsageAgreement) {
		errorMessage = getString('MUST_AGREE_TO_TURMS')
	}
	if (uploadProjectComponents?.manufacturingType && projectStandardCostError) {
		errorMessage = getString('NEW_UPLOAD_COST_VALIDATION')
	}
	if (projectStandardCostError) {
		if (!isStandardCostBoxChecked) {
			errorMessage = getString('NO_STANDARD_COST_ERROR')
		}
		if (uploadProjectComponents?.manufacturingType) {
			errorMessage = getString('NEW_UPLOAD_COST_VALIDATION')
		}
	}
	if (uploadProjectComponents?.estimatedPart && manufactureMethodError) {
		errorMessage = getString('NEW_UPLOAD_MANUFACTURING_VALIDATION')
	}
	if (addProjectName && (!projectName?.trim())) {
		errorMessage = getString('MISSING_PROJECT_NAME')
	}
	if (!selectedType) {
		errorMessage = getString('TYPE_SELECTION_WARNING')
	}
	if (BOMFileNotSelected) {
		errorMessage = getString('MISSING_BOM_FILE')
	}
	if (materialNotSelected && selectedType !== METADATA) {
		errorMessage = getString('NEW_UPLOAD_DROP_WARN')
	}
	if (errorUnit) {
		errorMessage = getString('UPLOAD_UNIT_TYPE_EXPLENATION')
	}
	if (
		!toNumber(quantity) &&
		Feature.isFeatureOn(FeatureComponentId.FORCE_SPECIFY_QUANTITY)
	) {
		errorMessage = getString('NEW_UPLOAD_QUANTITY_VALIDATION')
	}
	if (!!fileLimit) {
		errorMessage = getString('MAX_ALLOWED_PARTS_ERROR').format(fileLimit)
	}
	if (isRemainingParts && !isAdmin) {
		const userName = user?.userDetails?.name
		const userEmail = user?.userDetails?.email
		const emailSubject = getString(
			'LIGHT_USER_CONTACT_US_EMAIL_SUBJECT'
		).format(userName ? `- ${userName}` : '')
		const emailFrom = userName && userEmail ? userEmail : ''
		const emailBody = encodeURIComponent(
			getString('LIGHT_USER_CONTACT_US_EMAIL_BODY').format(userName || '')
		)
		errorMessage = parse(
			getString('LIGHT_USER_LIMIT_REACHED_INFO').format(
				user?.contactUsEmail,
				emailSubject,
				emailBody,
				emailFrom
			)
		)
	}
	if (!errorMessage || (error && enableUploadWithErrorMessage)) {
		disableByErrorMessage = false
	}
	if (error) {
		errorMessage = error
	}

	return { errorMessage, disableByErrorMessage }
}

export const uploadInfoMessage = (
	is2dUpload: boolean,
	isDrawingFeatureOn?: boolean,
	radioButtonSelected?: string,
	selectedType?: string
): string => {
	let infoMessage = getString('GENERAL_DROP_FILE_EXPLANATION_SINGLE_AND_BOM')

	if (!isDrawingFeatureOn) {
		infoMessage = getString('GENERAL_DROP_FILE_EXPLANATION_ONLY_TWO_D')
	}
	if (radioButtonSelected !== drawingFiles) {
		infoMessage = getString('NEW_UPLOAD_ENTER_CAD')
	}
	if (selectedType === METADATA) {
		infoMessage = getString('GENERAL_DROP_FILE_EXPLANATION_ONLY_METADATA')
	}
	if (is2dUpload) {
		infoMessage = getString('NEW_UPLOAD_ENTER_DRAWING_PDF')
	}

	return infoMessage
}
