import { cloneDeep, sortBy } from 'lodash'
import isNull from 'lodash/isNull'

import {
	ADMIN_MATERIAL_ADDED,
	ADMIN_MATERIAL_DELETED,
	ADMIN_SETINGS_PRIORITIES_TOGGLED,
	CHANGE_USER_MATERIAL_NAME_MAPPING,
	CO2_USER_SETTINGS_CARBON_CHANGED,
	CO2_USER_SETTINGS_DATA_LOADING,
	CO2_USER_SETTINGS_DISPOSE,
	CO2_USER_SETTINGS_PU_AMOUNT,
	CO2_USER_SETTINGS_PU_YEARS,
	CREATE_USER_MATERIAL_NAMES_MAPPING,
	DEFAULT_SCENARIO_CHANGED,
	FATCH_USER_MATERIAL_NAMES_MAPPING,
	FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
	GOT_LOGIN_DATA,
	GOT_MATERIAL_TECHNOLOGIES_DATA,
	LOGIN_PENDING,
	LOGIN_STATE_CHANGED,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	NEW_PAGE_VISITED,
	PRINTER_ADDED_TO_USER,
	PRINTER_REMOVED_FROM_USER,
	REMOVE_USER_MATERIAL_NAMES_MAPPING,
	RESET_AUTH_STATE,
	START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_SHOW_TOUR,
	UPDATE_TOUR_FAILED,
	UPDATE_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_USER_ONBOARDING_STATE,
	USER_CUSTOM_SETTINGS_UPDATED,
	USER_DETAIL_CHANGED,
	USER_FILTERS_CHANGE,
	USER_FILTERS_TOGGLES_UPDATED,
	USER_LANGUAGE_CHANGED
} from '../global actions/types'
import { checkIfDisabled } from '../Scenes/Home/Customize/CustomizeMaterialNamesMapping/CastorMaterialNamesMappingService'
import {
	defaultAllowedFileTypes,
	defaultMaxAllowedUploadParts,
	defaultMaxAllowedUploadProjects,
	defaultNotSupportedUnitTypeFormats,
	defaultPartsFileSizeLimit,
	defaultSettingScenario,
	defaultUploadFileName,
	defaultUploadUnitType,
	materialTypes
} from '../Services/Constants'
import { FeatureComponentId } from '../Services/models/Features'
import { getUserLanguage } from 'Services/Utils/startupTools'

const addressFeature = [
	{
		componentId: FeatureComponentId.ADDRESS,
		description: '',
		id: 27,
		name: 'address',
		on: process.env.REACT_APP_ONPREM !== 'true'
	},
	{
		componentId: FeatureComponentId.OFFLINE_ADDRESS,
		description: '',
		id: 28,
		name: 'offline_address',
		on: process.env.REACT_APP_ONPREM === 'true'
	}
]

const INITIAL_STATE = {
	loggedIn: false,
	loginPending: false,
	stateUnknown: true,
	user: null,
	roles: [],
	features: [...addressFeature],
	userOnTrial: null,
	materials: [],
	materialTypes: [],
	userSettings: {},
	userDetails: {},
	printerTechnologies: {},
	userLanguage: getUserLanguage(),
	userCurrencySign: '$',
	printersFullData: [],
	printerTechnologiesIds: [],
	printingTechnologies: [],
	printers: [],
	userMaterials: [],
	materialCategories: [],
	filters: [],
	userFilters: [],
	priorities: [],
	allOptionalPostProcessesData: [],
	optionalPostProcessesBreakDown: [],
	applications: [],
	acceptedFileTypes: [],
	partsFileSizeLimit: 0,
	notSupportedUnitTypeFormats: [],
	defaultConfigurations: null,
	uploadProjectSelectedRadioButton: null,
	defaultUploadProjectSelectedRadioButton: null,
	defaultUploadProjectMaterialType: materialTypes.plastic,
	filterPartsDefaultSelection: null,
	printerMaterialUniqueNames: [],
	defaultBomFilePath: '',
	defaultMetadataFilePath: '',
	threeDViewerURL:
		process.env.REACT_APP_ONPREM === 'true'
			? '../../../../heatmapViewer/index.html'
			: 'https://new-heatmap.s3.amazonaws.com/index.html',
	currentPageName: '',
	pagesVisited: null,
	pages: [],
	userCustomizationSettings: {},
	printIssues: [],
	contactUsEmail: '',
	userProviders: [],
	userMaterialNamesMapping: [],
	doMaterialNameMappingRefresh: false,
	disableMaterialNameMappingSaveAll: true,
	isLoadingMaterial: false,
	defaultProjectScenario: defaultSettingScenario.lowVolume,
	co2PerKW: '',
	puAnnualKgCO2: '',
	puYearsCO2: '',
	disposeFactor: '',
	maxAllowedUploadParts: defaultMaxAllowedUploadParts,
	maxAllowedLightUploadParts: defaultMaxAllowedUploadParts,
	allowedCountries: [],
	maxAllowedUploadProjects: defaultMaxAllowedUploadProjects,
	uploadUnitType: defaultUploadUnitType,
	defaultFileName: defaultUploadFileName,
	isLightUser: false,
	defaultProjectScenarios: [],
	defaultProjectScenarioParameters: [],
	partsBundle: '',
	editableFunctionStrings: [],
	carbonLoading: false,
	yearlyAmountLoading: false,
	partLifeLoading: false,
	disposeFactorLoading: false,
	onboardingCompleted: true,
	isEditingMaterial: false,
	initialUserDefaultMaterial: {}
}

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case LOGIN_STATE_CHANGED:
			const loggedIn = action.payload
			const resetUserState = loggedIn ? {} : INITIAL_STATE
			return {
				...state,
				...resetUserState,
				stateUnknown: false,
				loggedIn
			}
		case LOGIN_PENDING:
			return { ...state, loginPending: action.payload }
		case NEW_PAGE_VISITED:
			return { ...state, currentPageName: action.payload }
		case GOT_MATERIAL_TECHNOLOGIES_DATA:
			const {
				printerTechnologies,
				printerTechnologiesIds = [],
				printingTechnologies,
				printers = [],
				userMaterials,
				printerMaterialUniqueNames
			} = action.payload
			return {
				...state,
				printerTechnologies,
				printerTechnologiesIds,
				printingTechnologies,
				printers,
				userMaterials,
				printerMaterialUniqueNames
			}
		case USER_LANGUAGE_CHANGED:
			const { userLanguage } = action.payload
			return { ...state, userLanguage }
		case USER_DETAIL_CHANGED: {
			return { ...state, userDetails: action.payload }
		}
		case GOT_LOGIN_DATA: {
			const payload = action.payload.generalData
			const isLightUser = action.payload.isLightUser
			const materials = payload ? payload.materials : null
			const userSettings = payload ? payload.userSettings : {}
			const applications = payload ? payload.applications : []
			const userDetails = payload ? payload.userDetails : {}
			const materialCategories = payload ? payload.materialCategories : []
			const filters = payload ? payload.filters : {}

			const printersFullData = payload ? payload.printersFullData : []
			const priorities = payload ? payload.priorities : {}
			const pages = payload && payload.pages ? payload.pages : {}
			const printIssues =
				payload && payload.printIssues ? payload.printIssues : []
			const acceptedFileTypes =
				payload?.allowedFileTypes || defaultAllowedFileTypes
			const partsFileSizeLimit =
				payload?.partsFileSizeLimit || defaultPartsFileSizeLimit
			const defaultFileName = payload?.defaultFileName || defaultUploadFileName

			const notSupportedUnitTypeFormats =
				payload?.notSupportedUnitTypeFormats ||
				defaultNotSupportedUnitTypeFormats

			const maxAllowedUploadParts =
				payload?.maxAllowedUploadParts || defaultMaxAllowedUploadParts

			const maxAllowedLightUploadParts =
				payload?.maxAllowedLightUploadParts || defaultMaxAllowedUploadParts

			const allowedCountries = payload?.allowedCountries || []

			const maxAllowedUploadProjects =
				payload?.maxAllowedUploadProjects || defaultMaxAllowedUploadProjects

			const uploadUnitType =
				payload?.defaultUploadUnitType || defaultUploadUnitType

			const uploadProjectSelectedRadioButton =
				payload?.uploadProjectSelectedRadioButton || null
			const defaultUploadProjectMaterialType =
				payload?.defaultUploadProjectMaterialType || materialTypes.plastic
			const filterPartsDefaultSelection =
				payload?.filterPartsDefaultSelection || null

			const threeDViewerURL = payload
				? payload.threeDViewerURL || state.threeDViewerURL
				: state.threeDViewerURL
			const userSubscriptionDetails = payload?.userSubscriptionDetails
			const roles = payload
				? payload.roles
				: []
				? payload.userSubscriptionDetails
				: {}
			const allOptionalPostProcessesData = payload
				? payload.optionalPostProcesses
				: []

			const optionalPostProcessesBreakDown = payload
				? payload.optionalPostProcessesBreakDown
				: []
			const optionalPostProcessAvailability = payload
				? payload.optionalPostProcessesPermissions
				: {}
			const defaultConfigurations = payload ? payload.defaultConfigurations : []
			const userCurrencySign = payload?.userCurrencySign || '$'
			const contactUsEmail = payload?.contactUsEmail || 'support@3dcastor.com'
			const defaultBomFilePath = payload?.defaultBomFilePath || ''
			const defaultMetadataFilePath = payload?.defaultMetadataFilePath || ''

			const features = payload ? payload.features : [...addressFeature]

			const userOnTrial = userSubscriptionDetails.trial
			const currentMaterialTypes = []
			if (materials) {
				materials.forEach(material => {
					material.category = material.category.toLowerCase()
					if (!currentMaterialTypes.includes(material.type)) {
						currentMaterialTypes.push(material.type)
					}
				})
			}

			const userCustomizationSettings = payload.userCustomizationSettings
			const userProviders = payload.userProviders
			const userMaterialNamesMapping = payload.userMaterialNamesMapping
			const initialUserDefaultMaterial = [...userMaterialNamesMapping].find(
				materialName => materialName.defaultFormatType && materialName.active
			)
			const defaultProjectScenario =
				payload?.userCustomizationSettings.defaultProjectScenario ||
				state.defaultProjectScenario
			const co2PerKW = payload?.userCustomizationSettings.co2PerKW
			const puAnnualKgCO2 = payload?.userCustomizationSettings.puAnnualKgCO2
			const puYearsCO2 = payload?.userCustomizationSettings.puYearsCO2
			const disposeFactor = payload?.userCustomizationSettings.disposeFactor

			const defaultUploadProjectSelectedRadioButton =
				payload.defaultUploadProjectSelectedRadioButton
			const defaultProjectScenarios = payload.defaultProjectScenarios
			const defaultProjectScenarioParameters =
				payload.defaultProjectScenarioParameters

			const partsBundle = payload?.userSubscriptionDetails.partsBundle
			const editableFunctionStrings = payload?.editableFunctionStrings || []

			return {
				...state,
				materials,
				materialTypes: currentMaterialTypes,
				userSettings,
				userDetails,
				materialCategories,
				userOnTrial,
				filters,
				userFilters: action.payload.userFilters,
				priorities,
				allOptionalPostProcessesData,
				optionalPostProcessesBreakDown,
				optionalPostProcessAvailability,
				applications,
				roles,
				threeDViewerURL,
				features,
				acceptedFileTypes,
				partsFileSizeLimit,
				notSupportedUnitTypeFormats,
				uploadProjectSelectedRadioButton,
				defaultUploadProjectSelectedRadioButton,
				defaultUploadProjectMaterialType,
				filterPartsDefaultSelection,
				userCurrencySign,
				printersFullData,
				pages,
				pagesVisited: userDetails.pagesVisited,
				userCustomizationSettings,
				printIssues,
				contactUsEmail,
				defaultConfigurations,
				userProviders,
				userMaterialNamesMapping,
				defaultProjectScenario,
				co2PerKW,
				puAnnualKgCO2,
				puYearsCO2,
				disposeFactor,
				isLightUser,
				defaultProjectScenarios,
				defaultProjectScenarioParameters,
				maxAllowedUploadParts,
				maxAllowedLightUploadParts,
				allowedCountries,
				maxAllowedUploadProjects,
				uploadUnitType,
				defaultFileName,
				partsBundle,
				editableFunctionStrings,
				defaultBomFilePath,
				defaultMetadataFilePath,
				onboardingCompleted: userDetails.onboardingCompleted,
				initialUserDefaultMaterial
			}
		}
		case PRINTER_ADDED_TO_USER: {
			return {
				...state,
				printers: action.payload
			}
		}
		case PRINTER_REMOVED_FROM_USER:
			return {
				...state,
				printers: action.payload
			}
		case MATERIAL_REMOVED_FROM_USER: {
			const { material, userMaterials } = action.payload
			const filteredMaterials = state.materials.filter(
				({ id: existId }) => !material.some(({ id }) => existId === id)
			)

			return {
				...state,
				materials: filteredMaterials,
				userMaterials
			}
		}
		case ADMIN_MATERIAL_ADDED: {
			const material = action.payload
			const materials = sortBy([...state.materials, material], ['name'])

			return {
				...state,
				materials
			}
		}
		case ADMIN_MATERIAL_DELETED: {
			const materialId = action.payload
			const filteredMaterials = state.materials.filter(
				({ id }) => id !== materialId
			)

			return {
				...state,
				materials: filteredMaterials
			}
		}
		case MATERIAL_ADDED_TO_USER: {
			const { material: addedMaterials, userMaterials } = action.payload
			const materials = sortBy(
				[...state.materials, ...addedMaterials],
				['name']
			)

			return {
				...state,
				materials,
				userMaterials
			}
		}
		case ADMIN_SETINGS_PRIORITIES_TOGGLED: {
			const { priorities } = action.payload
			return { ...state, priorities }
		}
		case RESET_AUTH_STATE:
			return { ...state, features: [...addressFeature] }
		case UPDATE_SHOW_TOUR: {
			return { ...state, pagesVisited: action.payload.pagesVisited }
		}
		case UPDATE_TOUR_FAILED:
			return { ...state, tourActive: false, tourError: action.error }

		case USER_CUSTOM_SETTINGS_UPDATED: {
			const { userCustomizationSettings } = action.payload
			const fullTrayAssumption =
				userCustomizationSettings.fullTrayAssumption == null
					? state.userCustomizationSettings.fullTrayAssumption
					: userCustomizationSettings.fullTrayAssumption

			return {
				...state,
				userCustomizationSettings: {
					...state.userCustomizationSettings,
					...userCustomizationSettings,
					fullTrayAssumption: !!fullTrayAssumption
				}
			}
		}

		case FULL_TRAY_ASSUMPTION_DATA_ARRANGED: {
			const { settingsData } = action.payload
			const fullTrayAssumption = isNull(
				settingsData?.userSettings?.fullTrayAssumption
			)
				? settingsData?.defaultSettings?.fullTrayAssumption
				: settingsData?.userSettings?.fullTrayAssumption

			return {
				...state,
				userCustomizationSettings: {
					...state.userCustomizationSettings,
					fullTrayAssumption: !!fullTrayAssumption
				}
			}
		}

		case USER_FILTERS_TOGGLES_UPDATED: {
			const { userFilters } = action.payload
			return {
				...state,
				userFilters
			}
		}

		case USER_FILTERS_CHANGE: {
			const { newUserFilters } = action.payload
			return {
				...state,
				userFilters: newUserFilters
			}
		}

		case UPDATE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNamesMapping, userMaterialNameMappingUpdate } =
				action.payload
			let changedUserMaterialNamesMapping = cloneDeep(
				state.userMaterialNamesMapping
			)
			const changedIndex = changedUserMaterialNamesMapping.findIndex(
				material =>
					material.expression === userMaterialNameMappingUpdate.expression
			)

			if (changedIndex > -1) {
				changedUserMaterialNamesMapping[changedIndex] =
					userMaterialNameMappingUpdate
			} else {
				changedUserMaterialNamesMapping = userMaterialNamesMapping
			}

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				),
				isEditingMaterial: false,
				initialUserDefaultMaterial: changedUserMaterialNamesMapping.find(
					materialName => materialName.defaultFormatType && materialName.active
				)
			}
		}

		case CHANGE_USER_MATERIAL_NAME_MAPPING: {
			const { userMaterialNameMapping } = action.payload

			const userMaterialNamesMappingCopy = cloneDeep(
				state.userMaterialNamesMapping
			)
			const changedUserMaterialNamesMapping = userMaterialNamesMappingCopy.map(
				userMaterialName => {
					if (userMaterialName.id === userMaterialNameMapping.id) {
						userMaterialName.material = userMaterialNameMapping.material
					}
					return userMaterialName
				}
			)

			return {
				...state,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				),
				isEditingMaterial: true
			}
		}

		case REMOVE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNameMappingId } = action.payload

			const changedUserMaterialNamesMapping =
				state.userMaterialNamesMapping.filter(
					userMaterialNameMapping =>
						userMaterialNameMapping.id !== userMaterialNameMappingId
				)

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				)
			}
		}

		case CREATE_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNameMapping } = action.payload
			let changedUserMaterialNamesMapping = state.userMaterialNamesMapping
			const changedIndex = changedUserMaterialNamesMapping.findIndex(
				material => material.expression === userMaterialNameMapping.expression
			)

			if (changedIndex > -1) {
				changedUserMaterialNamesMapping[changedIndex] = userMaterialNameMapping
			} else {
				changedUserMaterialNamesMapping.push(userMaterialNameMapping)
			}

			return {
				...state,
				isLoadingMaterial: false,
				userMaterialNamesMapping: changedUserMaterialNamesMapping,
				disableMaterialNameMappingSaveAll: checkIfDisabled(
					changedUserMaterialNamesMapping
				)
			}
		}

		case START_UPDATING_USER_MATERIAL_NAMES_MAPPING: {
			return {
				...state,
				isLoadingMaterial: action.payload.isLoading
			}
		}

		case FATCH_USER_MATERIAL_NAMES_MAPPING: {
			const { userMaterialNamesMapping } = action.payload
			return {
				...state,
				userMaterialNamesMapping,
				doMaterialNameMappingRefresh: false
			}
		}

		case DEFAULT_SCENARIO_CHANGED: {
			const defaultScenario = action.payload
			return {
				...state,
				defaultProjectScenario: defaultScenario
			}
		}
		case CO2_USER_SETTINGS_CARBON_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				co2PerKW: value
			}
		}
		case CO2_USER_SETTINGS_DATA_LOADING: {
			return {
				...state,
				...action.payload
			}
		}
		case CO2_USER_SETTINGS_PU_AMOUNT: {
			const { value } = action.payload
			return {
				...state,
				puAnnualKgCO2: value
			}
		}
		case CO2_USER_SETTINGS_PU_YEARS: {
			const { value } = action.payload
			return {
				...state,
				puYearsCO2: value
			}
		}
		case CO2_USER_SETTINGS_DISPOSE: {
			const { value } = action.payload
			return {
				...state,
				disposeFactor: value
			}
		}
		case UPDATE_USER_ONBOARDING_STATE: {
			const { onboardingCompleted } = action.payload
			return {
				...state,
				onboardingCompleted
			}
		}

		default:
			return state
	}
}

export default userReducer
