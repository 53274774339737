import React, { Component, cloneElement } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import PopperMenu from '../Popper/PopperMenu'
import * as CastorPopperMenuActions from './CastorPopperMenuActions'

class CastorPopperMenu extends Component {
  onPopperMenuClicked = () => {
    const { menuName, openMenu, popperMenuClicked } = this.props
    popperMenuClicked(menuName, !openMenu[menuName])
  }

  onHandleCloseClicked = () => {
    const { openMenu, popperMenuClosed } = this.props
    if (Object.keys(openMenu).length) {
      popperMenuClosed()
    }
  }

  render() {
    const { menuName, openMenu, menuItems, targetItem, className, qaDataElementName } = this.props
    return (
      <PopperMenu
        open={openMenu[menuName]}
        targetItem={cloneElement(targetItem, {
          onClick:  (e) => {
            e.stopPropagation()
            this.onPopperMenuClicked()
          }
        })}
        menuItems={cloneElement(menuItems, {
          onClick: this.onHandleCloseClicked
        })}
        handleClose={this.onHandleCloseClicked}
        className={className}
        qaDataElementName={qaDataElementName}
      />
    )
  }
}

const mapStateToProps = ({ CastorPopperMenuReducer }) => {
  const { openMenu } = CastorPopperMenuReducer
  return { openMenu }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...CastorPopperMenuActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(CastorPopperMenu)
