import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import SolutionPartImagesTabs from '../../../SolutionAnalysisContent/SolutionPartDetails/SolutionPartImagesTabs'
import Flexbox from 'Scenes/Components/FlexBox'
import { LineBreak } from 'Scenes/Components/PdfComponents/LineBreak'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { toFixedOnlyIfNeeded } from 'Services/global/toFixedOnlyIfNeeded'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	part: Part
	partName: string
	show: boolean
	configurationId: number
	configuration: any
}

export const PartInformationPdf: FC<IProps> = ({
	part,
	partName,
	show,
	configurationId,
	configuration
}) => {
	const { project, partMaterial } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)

	if (!show) return <div />

	const { width, height, depth, volume, isDrawing } = part
	const configurationImageUrl: string = configuration.heatMapImageURL || ''
	const showHeatMap: boolean = false
	const imageUrl: string = part.imageURL
	const x = <>&nbsp;x&nbsp;</>

	return (
		<div className="inline">
			<div className="export-pdf__part-info">
				<div className="part-info">
					<h2>{getString('PART_INFORMATION')}</h2>
					<br />
					<div className="part-info__section">
						<p>
							<span>{getString('PROJECT_NAME')}:</span>
							{project.name}
						</p>
						<p>
							<span>{getString('PART_NAME')}:</span>
							{partName}
						</p>
						<p>
							<span>{getString('ORIGINAL_MATERIALS_BUTTON')}:</span>
							{partMaterial.name}
						</p>
						<p>
							<span>{getString('DIMENSIONS').format('mm')}:</span>
							{toFixedOnlyIfNeeded(width)}
							{x}
							{toFixedOnlyIfNeeded(depth)}
							{x}
							{toFixedOnlyIfNeeded(height)}
						</p>
						<p>
							<span>{getString('VOLUME').format('mm3')}:</span>
							{volume?.toFixed()}
						</p>
					</div>
				</div>
				{part?.formatType === METADATA ? (
					<></>
				) : (
					<div className="part-image">
						<h2>
							{!isDrawing && getString('IMAGES_TAB_HEADER_TRAY_ORIENTATION')}
						</h2>
						{isDrawing ? (
							<img src={part.imageURL} alt="imageURL" />
						) : (
							<Flexbox
								flexDirection="column"
								alignItems="flex-start"
								minWidth="39%"
								width="48%"
								marginRight="25px"
							>
								<SolutionPartImagesTabs
									configurationResult={configuration?.result}
									onChangeViewer={() => {}}
									part={part}
									cluster={null}
									freezeConfiguration={false}
									disableConfiguration={true}
									tourStepIndex={1}
									configurationId={configurationId}
									onPartImageTabClicked={() => {}}
									solutionPartImageIndex={1}
									isCluster={false}
									chosenOrientation={configuration.chosenOrientation}
									rotatedOrientation={
										configuration.solution?.rotatedOrientation || false
									}
									customConfiguration={configuration}
									onChooseOrientationClick={() => {}}
									showImageLoader={false}
									showHeatMap={false}
									partImageSrc={
										configurationImageUrl
											? showHeatMap
												? configurationImageUrl
												: imageUrl
											: imageUrl
									}
									onShowHeatToggle={() => {}}
									hide3dModalIframe={() => {}}
									show3dModalIframe={() => {}}
									show3dButton={false}
									showHeatMapButton={false}
									configurationVolume={configuration.volume}
								/>
							</Flexbox>
						)}
					</div>
				)}
			</div>
			<LineBreak />
		</div>
	)
}

export default memo(PartInformationPdf)
