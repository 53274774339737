import appStyle from '../../../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'
import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { connect } from 'react-redux'
import { Route, Switch, withRouter } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { withStyles } from '@material-ui/core'
import cx from 'classnames'
import PropTypes from 'prop-types'

import {
	checkAdminAuthentication,
	drawToggled,
	resetCrumbs,
	setupAdminRoutes,
	setupUserHome
} from '../../../global actions/index'
import { Feature, FeatureComponentId } from '../../../Services/models/Features'
import { uploadProjectRoute } from '../../../Services/routeFuncs'
import { getTheme } from '../../../themes/getTheme'
import MyMuiTheme from '../../Components/MyMuiTheme'
import {
	Header,
	Sidebar
} from '../../Components/thirdParty/CreativeTim/components'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'

const theme = getTheme()

class AdminHome extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}
	handleDrawerToggle = () => {
		this.props.drawToggled()
	}

	componentWillMount() {
		const { setupUserHome, setupAdminRoutes, roles, features } = this.props
		setupUserHome(features)
		setupAdminRoutes(roles, features)
	}

	componentDidMount() {
		const { checkAdminAuthentication, resetCrumbs, history } = this.props
		checkAdminAuthentication(history)
		resetCrumbs()
	}

	componentDidUpdate() {
		const {
			setupUserHome,
			setupAdminRoutes,
			roles,
			features,
			routesNotAllowed
		} = this.props
		if (routesNotAllowed && roles && roles.length) {
			setupUserHome(features)
			setupAdminRoutes(roles, features)
		}
	}

	render() {
		const sideBarAndMenu = Feature.isFeatureOn(
			FeatureComponentId.SIDE_BAR_AND_MENU
		)
		const { classes, routes, onboardingCompleted, ...rest } = this.props

		const mainPanel =
			classes.mainPanel +
			' ' +
			cx({
				[classes.mainPanelSidebarMini]: this.props.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf('Win') > -1
			})
		const uploadProjectPageRedirection = Feature.isFeatureOn(
			FeatureComponentId.UPLOAD_PROJECT_PAGE_REDIRECTION
		)

		return (
			<MyMuiTheme>
				<div>
					<Helmet title={theme.tabTitle} />
					<ErrorBoundary errorClass="sidebar" extra="AdminSidebar">
						<Sidebar
							routes={routes}
							href={uploadProjectRoute()}
							logo={theme.logo}
							handleDrawerToggle={this.handleDrawerToggle.bind(this)}
							color="blue"
							{...rest}
							location={this.props.parentProps.location}
							open={this.props.mobileOpen}
							clickableLogo={uploadProjectPageRedirection}
							onboardingCompleted={onboardingCompleted}
						/>
					</ErrorBoundary>
					<div ref="mainPanel" className={mainPanel}>
						{sideBarAndMenu && (
							<Header
								routes={routes}
								handleDrawerToggle={this.handleDrawerToggle.bind(this)}
								{...rest}
								location={this.props.parentProps.location}
							/>
						)}
						<div className={cx(styles.container, 'routes')}>
							<TransitionGroup>
								<CSSTransition
									key={this.props.parentProps.location.pathname}
									classNames="fade"
									timeout={300}
								>
									<Switch location={this.props.parentProps.location}>
										{routes &&
											routes.map((route, index) => {
												if (route.isInHome) {
													return (
														<Route
															key={index}
															path={route.path}
															component={route.component}
															exact={route.exact}
														/>
													)
												}
											})}
									</Switch>
								</CSSTransition>
							</TransitionGroup>
						</div>
					</div>
				</div>
			</MyMuiTheme>
		)
	}
}

const styles = {
	mainPanel: {
		paddingLeft: '300px',
		marginLeft: 'auto',
		marginRight: 'auto',
		'@media (min-width: 768px)': {
			paddingLeft: '15px'
		}
	}
}

AdminHome.propTypes = {
	classes: PropTypes.object.isRequired
}
const mapStateToProps = ({ classes, location, AdminHomeReducer, user }) => {
	const { routes, loading, error, routesNotAllowed } = AdminHomeReducer
	const {
		roles,
		features,
		userDetails: { onboardingCompleted }
	} = user
	return {
		classes,
		location,
		routes,
		loading,
		error,
		roles,
		features,
		routesNotAllowed,
		onboardingCompleted
	}
}
export default withRouter(
	connect(mapStateToProps, {
		setupUserHome,
		setupAdminRoutes,
		drawToggled,
		checkAdminAuthentication,
		resetCrumbs
	})(withStyles(appStyle)(AdminHome))
)
