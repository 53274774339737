import React from 'react'
import { Field, reduxForm } from 'redux-form'

import { FormHelperText, MenuItem } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import SelectField from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { capitalize, toLower } from 'lodash'

import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import {
	MATERIAL_TABLE_TEXTS,
	REQUIRED,
	SUBMIT,
	UNDO_CHANGES
} from '../../../../Services/Strings'
import InfoBox from '../../../Components/InfoBox'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import { renderSelectFieldTouched } from '../AdminFields/AdminSelectField'
import { specialMaterial } from '../AdminPrinterMaterials/constants'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

import '../adminHome.scss'

const materialTypes = ['Plastic', 'Metal']
const surfaceFinishMethods = ['rz', 'ra']

const validate = values => {
	const errors = {}
	if (!values.name || !values.name.trim()) {
		errors.name = REQUIRED
	}
	if (!values.category || !values.category.trim()) {
		errors.category = REQUIRED
	}
	if (!values.type) {
		errors.type = REQUIRED
	}
	if (!values.wallThickness) {
		errors.wallThickness = REQUIRED
	}
	if (!values.percentElongationAtBreak) {
		errors.percentElongationAtBreak = REQUIRED
	}
	if (!values.youngsModulus) {
		errors.youngsModulus = REQUIRED
	}
	return errors
}

const TRADITIONAL_COST_OBJECT_EXAMPLE_INFO_STRING =
	"{'Cast':0.01122, 'Mold':0,'CNC':0}"

const renderSelectField = ({
	input,
	label,
	meta: { touched, error },
	children,
	custom
}) => (
	<FormControl className="admin-form-field">
		<InputLabel error={touched && error}>{label}</InputLabel>
		<SelectField
			floatingLabelText={label}
			error={touched && error}
			{...input}
			children={children}
			{...custom}
		/>
		<FormHelperText error={touched && error}>{error}</FormHelperText>
	</FormControl>
)

const materialTypeChange = (
	e,
	change,
	onMaterialTypeChange,
	allMaterialCategories
) => {
	change('type', toLower(e.target.value))
	onMaterialTypeChange(e.target.value, allMaterialCategories)
	change('category', '')
}

const renderTypeSelectedItems = (
	change,
	onMaterialTypeChange,
	allMaterialCategories,
	initialized,
	selectedMaterialType
) => {
	return (
		<Field
			name={'type'}
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.TYPE}
			custom={{
				value: capitalize(selectedMaterialType) || '',
				['data-qa']: 'admin-user-material-type',
				onChange: e =>
					materialTypeChange(
						e,
						change,
						onMaterialTypeChange,
						allMaterialCategories
					)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TYPE}</MenuItem>
			{materialTypes.map(materialType => {
				return (
					<MenuItem
						data-qa={`admin-user-material-type-${toLower(materialType)}`}
						key={materialType}
						value={materialType}
					>
						{materialType}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderCategorySelectedItems = (
	change,
	materialCategories,
	initialized,
	selectedMaterialCategory
) => {
	if (!materialCategories || !materialCategories.length) {
		return <div />
	}
	return (
		<Field
			name={'category'}
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.CATEGORY}
			custom={{
				value: capitalize(selectedMaterialCategory) || '',
				['data-qa']: 'admin-user-material-category',
				onChange: e => change('category', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.CATEGORY}</MenuItem>
			{materialCategories.map(materialCategory => {
				return (
					<MenuItem
						data-qa={`admin-user-material-category-${toLower(
							materialCategory.name
						)}`}
						key={materialCategory.id}
						value={capitalize(materialCategory.name)}
					>
						{materialCategory.name}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderSurfaceFinishMethodSelectedItems = (
	selectedSurfaceFinishMethod,
	change,
	initialized
) => {
	return (
		<Field
			name="surfaceFinishMethod"
			data-qa="admin-user-material-surfaceFinishMethod"
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.METHOD}
			custom={{
				value: selectedSurfaceFinishMethod || '',
				onChange: e => change('surfaceFinishMethod', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.METHOD}</MenuItem>
			{surfaceFinishMethods.map(surfaceFinishMethod => {
				return (
					<MenuItem key={surfaceFinishMethod} value={surfaceFinishMethod}>
						{surfaceFinishMethod}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderTextField = ({
	input,
	label,
	meta: { touched, error },
	...custom
}) => (
	<TextField
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
	/>
)

const resetForm = (
	reset,
	allMaterialCategories,
	onMaterialTypeChange,
	initialValues
) => {
	reset()
	onMaterialTypeChange(
		initialValues ? initialValues.type : '',
		allMaterialCategories
	)
}

const onChangeCertificatedValue = (change, filters, name, value) => {
	const updatedFilters = {
		...filters,
		[name]: value
	}
	change('defaultFilters', updatedFilters)
}

const renderSpecialMaterial = (defaultFilters, change) => {
	const filters = defaultFilters ? defaultFilters : {}
	return (
		<div className="checkboxes-block">
			<p>{specialMaterial.label}</p>
			<div className="special-materials">
				{specialMaterial?.checkBoxes.map(checkBox => {
					return (
						<FormControlLabel
							control={
								<Checkbox
									data-qa={`admin-user-material-checkbox-${checkBox.name}`}
									disabled={checkBox.disabled}
									checked={filters[checkBox.name] || false}
									onChange={e =>
										onChangeCertificatedValue(
											change,
											filters,
											checkBox.name,
											e.target.checked
										)
									}
								/>
							}
							label={checkBox?.label}
						/>
					)
				})}
			</div>
		</div>
	)
}
const AdminMaterialForm = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		valid,
		materialCategories,
		change,
		onMaterialTypeChange,
		allMaterialCategories,
		initialValues,
		defaultFilters,
		addingNewItem,
		userCurrencySign,
		initialized,
		selectedMaterialType,
		selectedMaterialCategory,
		selectedSurfaceFinishMethod
	} = props
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			<div>
				<Field
					data-qa="admin-user-material-name"
					className="admin-form-field"
					name="name"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.NAME}
				/>
			</div>

			<div>
				{renderTypeSelectedItems(
					change,
					onMaterialTypeChange,
					allMaterialCategories,
					initialized,
					selectedMaterialType
				)}
			</div>

			<div>
				{renderCategorySelectedItems(
					change,
					materialCategories,
					initialized,
					selectedMaterialCategory
				)}
			</div>

			<div>
				<Field
					data-qa="admin-user-material-wallThickness"
					className="admin-form-field"
					name="wallThickness"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.WALL_THICKNESS}
					type="number"
				/>
			</div>
			<div>
				<Field
					data-qa="admin-user-material-yieldStrengthMPa"
					className="admin-form-field"
					name="yieldStrengthMPa"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}
					type="number"
				/>
			</div>
			<div>
				<Field
					data-qa="admin-user-material-percentElongationAtBreak"
					className="admin-form-field"
					name="percentElongationAtBreak"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.ELONGATION}
					type="number"
				/>
			</div>

			<div>
				<Field
					data-qa="admin-user-material-youngsModulus"
					className="admin-form-field"
					name="youngsModulus"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}
					type="number"
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-density"
					name="density"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.DENCITY}
					type="number"
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-ultimateTensileStrength"
					name="ultimateTensileStrength"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH}
					type="number"
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-maximumServiceTemperature"
					name="maximumServiceTemperature"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE}
					type="number"
				/>
			</div>

			<div>
				<Field
					className="admin-form-field"
					data-qa="admin-user-material-thermalConductivity"
					name="thermalConductivity"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY}
					type="number"
				/>
			</div>

			<div className="form-multiple-fields">
				<div className="form-field-in-multiple first">
					<Field
						className="admin-form-field"
						name="surfaceFinish"
						data-qa="admin-user-material-surfaceFinish"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.SURFACE_FINISH}
						type="number"
					/>
				</div>
				<div className="form-field-in-multiple last">
					{renderSurfaceFinishMethodSelectedItems(
						selectedSurfaceFinishMethod,
						change,
						initialized
					)}
				</div>
			</div>

			<div>
				<Field
					className="admin-form-field"
					name="cost"
					data-qa="admin-user-material-cost"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.COST.format(userCurrencySign)}
					type="number"
				/>
			</div>

			<div>
				<Flexbox alignItems="flex-end">
					<Field
						className="admin-form-field"
						name="co2perKgMaterial"
						data-qa="admin-user-material-co2perKgMaterial"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.CO2_PER_KG}
						type="number"
					/>
					<InfoBox
						boxContact={getString('CO2_PER_KG_MATERIAL_INFO_STRING')}
						iconClassName="admin-form-field__info_icon"
					/>
				</Flexbox>
			</div>
			<div>
				<Flexbox alignItems="flex-end">
					<Field
						className="admin-form-field"
						data-qa="admin-user-material-traditionalCost"
						name="traditionalCost"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.TRADITIONAL_COST.format(
							userCurrencySign
						)}
						type="number"
					/>
					<InfoBox
						boxContact={TRADITIONAL_COST_OBJECT_EXAMPLE_INFO_STRING}
						iconClassName="admin-form-field__info_icon"
					/>
				</Flexbox>
			</div>

			{showDefaultMaterialFilters ? (
				renderSpecialMaterial(defaultFilters, change)
			) : (
				<></>
			)}

			<div>
				<Button
					data-qa="admin-user-material-submit"
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem || pristine || submitting}
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={resetForm.bind(
						this,
						reset,
						allMaterialCategories,
						onMaterialTypeChange,
						initialValues
					)}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'adminMaterialFormState',
	validate,
	enableReinitialize: true
})(AdminMaterialForm)
