import { IUserMaterialNamesMapping } from '../../../../Services/models/IMaterialNamesMapping'
import { defaultMetal } from 'Services/Constants'
import { Material } from 'Services/models/IMaterial'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

export const getSelectedMaterial = (
	materials: Material[],
	materialCategories: any,
	property: string,
	value: string
) => {
	let selectedMaterial = null
	switch (property) {
		case 'type':
			{
				selectedMaterial = materials.find(
					(material: any) =>
						material.id ===
						(value === 'plastic' ? defaultMaterial.id : defaultMetal.id)
				)
			}
			break
		case 'category':
			{
				let category = materialCategories.find(
					(materialCategory: any) => materialCategory.name === value
				)
				selectedMaterial = materials.find(
					(material: any) =>
						material.id === category?.defaultMaterialSelection?.id
				)
			}
			break
		default:
			selectedMaterial = materials.find(
				(material: any) =>
					material[property].toLowerCase() === value.toLowerCase()
			)
			break
	}

	if (selectedMaterial) {
		selectedMaterial.category = selectedMaterial.category.replace(
			/(^\w{1})|(\s+\w{1})/g,
			letter => letter.toUpperCase()
		)
	}

	return selectedMaterial
}

export const filterUserMaterialMappingExpression = (
	userMaterialNamesMapping: IUserMaterialNamesMapping[],
	isActive?: boolean
) => {
	return userMaterialNamesMapping
		?.sort((a, b) => a.expression?.localeCompare(b?.expression))
		?.filter(x => x.active === isActive)
}

export const checkIfDisabled = (
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
) => {
	return (
		userMaterialNamesMapping.filter(
			userMaterialNameMapping =>
				!userMaterialNameMapping.active && userMaterialNameMapping.material
		).length < 2
	)
}

export const filterUserMaterialMapping = (
	userMaterialNamesMapping: IUserMaterialNamesMapping[],
	isActive?: boolean
) => {
	return userMaterialNamesMapping
		.filter(
			userMaterialNameMapping =>
				userMaterialNameMapping.active === isActive &&
				userMaterialNameMapping.material
		)
		.map(userMaterialNameMapping => {
			userMaterialNameMapping.active = true
			return userMaterialNameMapping
		})
}

export const checkIfEmpty = (
	userMaterialNamesMapping: IUserMaterialNamesMapping[],
	isActive?: boolean
) => {
	return (
		userMaterialNamesMapping?.filter(
			userMaterialNameMapping => userMaterialNameMapping?.active === isActive
		).length <= 0
	)
}
