export const gerUrlParams = (paramNames: string[]): any => {
    const url = new URL(window.location.href)
    const params = new URLSearchParams(url.search)

    const paramsObj: any = {}
    paramNames.forEach(paramName => {
        paramsObj[paramName] = params.get(paramName)
    })

    return paramsObj
}