import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import { materialSelected } from '../../UploadProjectActions'
import Flexbox from 'Scenes/Components/FlexBox'
import MaterialSelector from 'Scenes/Components/MaterialSelector/MaterialSelector'
import { useMaterial } from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadBlocksHooks'
import { defaultMetal } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

interface UploadMaterialProps {
	disabled: boolean
}

const UploadMaterial: FC<UploadMaterialProps> = ({ disabled }) => {
	const user = useSelector((state: RootStateOrAny) => state.user)
	const [defaultChosenMaterial] = useMaterial()

	const dispatch = useDispatch()

	const materialChangedUpload = (material: any) => {
		const isMachining = user?.userCustomizationSettings?.machining
		dispatch(materialSelected(material, isMachining))
	}

	return (
		<div>
			<Flexbox flexDirection="column" alignItems="flex-start">
				<div
					className={cx('hide-input', {
						disabledText: disabled,
						bomUploadInput: !disabled
					})}
				>
					{getString('UNIFORM_MATERIAL_RADIO_TEXT_ON_UPLOAD')}
				</div>
				{!disabled && (
					<MaterialSelector
						hideMaterialNameSelector={false}
						hiddenMaterialNameSelector={true}
						isButtonSelector={true}
						onChange={(e: any) => materialChangedUpload(e)}
						disabled={disabled}
						style={{ maxWidth: 50 }}
						defaultMaterialForTypeArr={[defaultMetal, defaultMaterial]}
						defaultMaterial={defaultChosenMaterial}
						selectClassName="upload-project-material-selector-field"
						qaDataElementNameType={'data-qa-original-material-type-'}
						qaDataElementNameCategory={'data-qa-original-category-'}
						qaDataElementNameMaterial={'data-qa-original-material-'}
					/>
				)}
			</Flexbox>
		</div>
	)
}

export default UploadMaterial
