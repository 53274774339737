import { Action } from '../../../../global actions/ActionModels'
import {
  ADMIN_3D_TECH_PARAMETERS_UPDATED,
  GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
  GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
  GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS
} from '../../../../global actions/types'

export class AdminPrintingTechnologyInitialState {
  readonly printers: Array<any> = []
  readonly showNoParametersAlert: boolean = false
  readonly pageNumber: number = 1
  readonly isLastPage: boolean = false
  readonly showPagination: boolean = false
  readonly showAdminPrintersAlert: boolean = false
  readonly selectedEditPrinterTechnology: any = null
  readonly loading: boolean = false
  readonly printersTechParametersData: any = null
}

const initialState: AdminPrintingTechnologyInitialState =
  new AdminPrintingTechnologyInitialState()

const adminPrintingTechnologyReducer = (
  state = initialState,
  { type, payload }: Action<any>
) => {
  switch (type) {
    case GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS:
      const {
        data: { techParametersData }
      } = payload
      return {
        ...state,
        printersTechParametersData: techParametersData,
        showNoParametersAlert: !techParametersData.length
      }
    case GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR:
      return { ...state, showAdminPrintersAlert: true, loading: false }

    case GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED:
      return {
        ...state,
        selectedEditPrinterTechnology: payload
      }

    case ADMIN_3D_TECH_PARAMETERS_UPDATED:
      const updatedPrinterTechnology = payload
      return {
        ...state,
        printersTechParametersData: state.printersTechParametersData.map(
          (printersTech: any) =>
            printersTech.id === updatedPrinterTechnology.id
              ? updatedPrinterTechnology
              : printersTech
        )
      }
    default:
      return state
  }
}

export default adminPrintingTechnologyReducer
