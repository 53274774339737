import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { useCostAnalysisTableHeaders } from './CostAnalysisTableService'
import DataTable from 'Scenes/Components/DataTable'
import { useCostAnalysisTableRows } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableHooks'
import { IConfiguration } from 'Scenes/Home/NewPartAnalysis/NewPartConfiguration/NewPartConfigurationTypes'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { PRINTING_TECHNOLOGY_VALUE_TO_READABLE } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	traditionalMethod: string
	solution: ISolution
	configuration: IConfiguration | any
	chainBenefits: IChainBenefits | any
	shouldShowDetailed?: boolean
	shouldShowNested?: boolean
	part: Part
}

const CostAnalysisTable: FC<IProps> = ({
	traditionalMethod,
	solution,
	configuration,
	chainBenefits,
	shouldShowNested,
	part,
	shouldShowDetailed
}) => {
	const { userCustomizationSettings } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const drawingCostPercentage = useSelector((state: RootStateOrAny) => {
		return (
			state?.user?.userCustomizationSettings?.drawingCostPercentage ||
			state?.MainPartAnalysisReducer?.drawingCostPercentage
		)
	})

	const drawingPrinter = `${
		PRINTING_TECHNOLOGY_VALUE_TO_READABLE[solution?.printer?.technology] ||
		solution?.printerTechnology?.userReadableName ||
		solution?.printer?.technology
	} ${getString('PRINTER')}`
	const printerName = part.isDrawing
		? drawingPrinter
		: solution?.printer?.name || getString('3D_PRINTING')

	const costAnalysisTableHeaders = useCostAnalysisTableHeaders(
		traditionalMethod,
		printerName
	)
	const costAnalysisTableRows = useCostAnalysisTableRows(
		solution,
		configuration,
		chainBenefits,
		userCustomizationSettings,
		part,
		drawingCostPercentage
	)

	return (
		<div className="cost-comparison-tab--table--wrapper">
			<DataTable
				shouldShowDetailed={shouldShowDetailed}
				shouldShowNested={shouldShowNested}
				showChildren={true}
				tableClassName="solution-tab--material-table solution-tab--material-three-column solution-tab--new-table"
				tableHead={costAnalysisTableHeaders}
				tableDataRows={costAnalysisTableRows}
				showBreakLines={true}
			/>
		</div>
	)
}

export default memo(CostAnalysisTable)
