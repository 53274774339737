import {
	findEffectivePoints,
	SolutionAnalysisService
} from '../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisService'
import { CONFIGURATION_CALCULATED } from 'global actions/types'
import {
	ALERT_CALCULATION_FINISHED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import {
	ALTERNATIVE_SOLUTION_HOVER_ELEMENT,
	GET_ALTERNATIVE_SOLUTION_CALCULATED,
	GET_ALTERNATIVE_SOLUTION_CALCULATION,
	MAIN_PART_PART_PRINT_ISSUES_UPDATED,
	NEW_ALTERNATIVE_SOLUTION_CALCULATED,
	NEW_ALTERNATIVE_SOLUTION_CALCULATION,
	SOLUTION_CONFIGURATION_SOLUTION_UPDATED
} from 'global actions/types/partAnalysisTypes'
import { store } from 'index'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { showCustomFunctionWarningPopup } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import { CostComparisonService } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostComparisonService'
import { LeadTimeService } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/LeadTimeTab/LeadTimeService'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { manufacturingMethodTypes } from 'Services/Constants'
import { CostData, LeadData } from 'Services/models/CostComparisonModels'
import { PartPrintIssue } from 'Services/models/PartPrintIssue'
import {
	createOrUpdateSolution,
	getConfigurationSolutions
} from 'Services/Network/PartAnalysisNetwork'
import { OK } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { tabOrder } = getTheme()
let solutionAnalysisService: SolutionAnalysisService
const costComparisonService = new CostComparisonService()
const leadTimeService = new LeadTimeService()

export const getAllAlternativeSolutions = (configurationId: number) => {
	return async (dispatch: any) => {
		dispatch({
			type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
			payload: true
		})

		try {
			const response: any = await getConfigurationSolutions(configurationId)

			if (response?.data) {
				const state = store.getState()

				dispatch({
					type: GET_ALTERNATIVE_SOLUTION_CALCULATED,
					payload: {
						solutions: response.data.solutions || [],
						userState: state.user
					}
				})
				dispatch({
					type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
					payload: false
				})
			}
		} catch (e) {
			console.error(e)

			dispatch({
				type: GET_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: false
			})
		}
	}
}

export const onChangeHoverAlternativeSolution = (
	hoverElement: number | null
) => {
	return (dispatch: any) => {
		dispatch({
			type: ALTERNATIVE_SOLUTION_HOVER_ELEMENT,
			payload: {
				hoverElement
			}
		})
	}
}

export const calculateAlternativeSolutions = (
	solution: any,
	part: any,
	configuration: any
) => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: true
				}
			})

			solutionAnalysisService = new SolutionAnalysisService(
				configuration,
				undefined,
				[],
				undefined
			)

			const user = store.getState().user

			const response: any = await createOrUpdateSolution(
				solution.id,
				solution.part,
				part.projectId,
				configuration.priorities || {},
				{
					printerMaterialID: solution.printerMaterial.id
				},
				-1,
				part.isSpecifiedQuantity,
				solution.printer.name,
				configuration.material?.id || configuration.material,
				part.initialBatchSize,
				{},
				solution.partCluster,
				undefined,
				undefined,
				true,
				solution.printer,
				solution.printerMaterial,
				false,
				undefined,
				undefined,
				undefined,
				undefined
			)

			let partPrintIssues = []

			if (response.data) {
				configuration = response.data.configuration
			}

			let configurations =
				store.getState().MainPartAnalysisReducer.configurations

			const resPoller = await solutionAnalysisService.startConditionPoller(
				configuration,
				configuration.id,
				configurations
			)

			if (resPoller) {
				dispatch({ type: ALERT_CALCULATION_FINISHED })

				if (resPoller.data) {
					configuration = resPoller.data.configuration
					solution = resPoller.data.solution
					partPrintIssues = resPoller.data.partPrintIssues
				}
			} else {
				configuration = response.data.configuration
				solution = response.data.solution
				partPrintIssues = response.data.partPrintIssues
			}

			if (
				solution?.isAbnormalPrice ||
				configuration?.traditionalCostIsAbnormal
			) {
				dispatch(showCustomFunctionWarningPopup())
			}

			let costData: CostData = new CostData()
			let leadTimeData: LeadData = new LeadData()
			let costBenefit: any = null
			let timeBenefit: any = null
			let costDetails: any = null
			let PostProcessOnCounter: number = 0

			const manufacturingMethod: string =
				manufacturingMethodTypes[
					solutionAnalysisService.getManufacturingMethod(configuration)
				]
			if (configuration.costResults) {
				costData = costComparisonService.getCostData(
					configuration.costResults,
					manufacturingMethod,
					user.userCurrencySign,
					solution?.costDetails?.totalCost || 0,
					configuration.drawingCostPercentage,
					configuration.part?.isDrawing ||
						configuration.part?.formatType === METADATA,
					configuration.part?.blockManufacturingMethodOperation,
					configuration?.isSpecifiedQuantity || false
				)
			}

			if (configuration.leadTimeResults) {
				leadTimeData = leadTimeService.getLeadData(
					configuration.leadTimeResults,
					manufacturingMethod,
					configuration.unlockLeadTimeGraph,
					configuration.quantity,
					!!part.standardCost && !configuration.standardCost
				)
			}

			const postProcessToggles: any =
				solutionAnalysisService.setupSolutionPostPostProcessToggles(
					configuration.id,
					false,
					configuration && configuration.postProcessesOptional,
					solution
				)

			const showWeightReductionButton =
				solutionAnalysisService.toShowWeightReductionButton(
					configuration?.type,
					configuration?.result,
					part
				)

			if (solution) {
				costBenefit = solutionAnalysisService.getCostBenefit(solution)
				timeBenefit = solutionAnalysisService.getTimeBenefit(solution)
				costDetails =
					solutionAnalysisService.createCostDetailsPostProcessesLabels(
						configuration && configuration.postProcessesOptional,
						solution && solution.costDetails
					)

				PostProcessOnCounter =
					solutionAnalysisService.howManyPostProcessesOn(postProcessToggles)
			}

			const configurationPrintIssues: PartPrintIssue[] = partPrintIssues.filter(
				(partPrintIssue: PartPrintIssue) =>
					partPrintIssue.configuration === configuration.id ||
					!partPrintIssue.configuration
			)

			const trayOrientationVector: number[] =
				configuration?.trayOrientation?.trayNormalVector ||
				solution?.trayOrientationVector ||
				[]

			const analysisResultsRows =
				solutionAnalysisService.getAnalysisResultsRows(
					solution,
					configuration,
					configurationPrintIssues,
					trayOrientationVector,
					part?.isDrawing,
					part?.formatType === METADATA
				)
			const failedPrintIssuesIds =
				solutionAnalysisService.getFailedPrintIssues(analysisResultsRows)
			const { effectiveQuantity } = findEffectivePoints(configuration)

			let trayOrientationCustom = solutionAnalysisService.isOrientationCustom(
				configuration,
				solution
			)
			const alternativeSolutions =
				configuration && configuration.alternativeSolutions
			let chosenOrientation = solutionAnalysisService.getChosenOrientation(
				part?.trayOrientations?.data || part?.trayOrientations || [],
				trayOrientationVector
			)
			dispatch({
				type: SOLUTION_CONFIGURATION_SOLUTION_UPDATED,
				payload: {
					id: solution.id,
					configuration,
					costBenefit,
					costDetails,
					timeBenefit,
					solution,
					optionalPostProcessAvailability: {},
					allOptionalPostProcessData: {},
					optionalPostProcessesBreakDown: {},
					postProcessToggles,
					PostProcessOnCounter,
					chosenMaterial: solution.material,
					trayOrientationCustom,
					initialSelectedTab:
						solutionAnalysisService.getInitialSelectedTab(solution),
					...costData,
					analysisResultsRows,
					failedPrintIssuesIds,
					manufacturingMethod,
					printingOrientationCalc: false,
					alternativeSolutions,
					chosenOrientation,
					chosenOrientationVector: trayOrientationVector,
					showWeightReductionButton,
					effectiveQuantity,
					disableTabUpdate: true,
					filterInitialValues: configuration.filters,
					chartLeadData: leadTimeData.chartData,
					tabIndex: tabOrder.costAnalysis,
					filterToggles: {
						printerMaterialID: solution.printerMaterial.id
					}
				}
			})

			dispatch({
				type: MAIN_PART_PART_PRINT_ISSUES_UPDATED,
				payload: {
					partPrintIssues
				}
			})

			dispatch({
				type: CONFIGURATION_CALCULATED
			})

			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: false
				}
			})

			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATED,
				payload: {
					newConfiguration: configuration,
					solutionId: solution.id
				}
			})
		} catch (e) {
			console.trace(e)
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('SOMETHING_WENT_WRONG'),
					headerTitle: getString('ERROR'),
					alertType: AlertType.WARNING,
					showCancel: false,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
			dispatch({
				type: NEW_ALTERNATIVE_SOLUTION_CALCULATION,
				payload: {
					solutionId: solution.id,
					isLoading: false
				}
			})
		}
	}
}
