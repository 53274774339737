import {
	IN_HOUSE_OTHER_PLUS_BUTTON_CLICK,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED,
	IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED,
	IN_HOUSE_PRINTERS_ALERT_CLOSE,
	IN_HOUSE_PRINTERS_ALERT_OPEN,
	IN_HOUSE_PRINTERS_AMOUNT_CHANGED,
	IN_HOUSE_PRINTERS_COMPANY_SELECTED,
	IN_HOUSE_PRINTERS_DATA_RECEIVED,
	IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED,
	IN_HOUSE_PRINTERS_NAME_SELECTED,
	IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED,
	IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED,
	IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED,
	IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED,
	IN_HOUSE_PRINTERS_PRINTER_ADDED,
	IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER,
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED,
	IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_SELECTED,
	IN_HOUSE_PRINTERS_PRINTER_REMOVED,
	IN_HOUSE_PRINTERS_PRINTER_SUBMITTED,
	IN_HOUSE_PRINTERS_UNMOUNT,
	IN_HOUSE_PRINTERS_USER_ID_SELECTED,
	IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED,
	IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED
} from '../../../../global actions/types'
import { MAX_MATERIAL_ADDED, OTHER } from '../../../../Services/Constants'
import { MATERIAL_COST_INPUTS_WEIGHT_UNIT } from '../../../../Services/Strings'

const resetSelectedPrinter = {
	inHousePrintersLoading: false,
	selectedPrinterCompany: '0',
	selectedPrinterName: '0',
	disableButton: true,
	printerAmount: '',
	selectedOtherPrinterCompany: '',
	selectedOtherPrinterName: '',
	showOtherPrinterCompanySelector: false,
	selectedMaterials: [],
	otherMaterialsSelected: [],
	selectedMaterialIds: [],
	selectedPrinter: {},
	printerMaterialsList: []
}

const INITIAL_STATE = {
	...resetSelectedPrinter,
	printers: [],
	printersCompanies: {},
	userPrinters: [],
	userId: 0,
	printerNotExist: false,
	showPrinterNotExistAlert: false,
	showInHousePrintersAlert: false,
	inHousePrintersOnly: false
}

const customizeInHousePrintersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case IN_HOUSE_PRINTERS_DATA_RECEIVED: {
			const { printersCompanies, printers } = action.payload
			return {
				...state,
				printersCompanies,
				printers
			}
		}
		case IN_HOUSE_PRINTERS_COMPANY_SELECTED:
			const { selectedPrinterCompany, printerMaterialUniqueNames } =
				action.payload
			return {
				...state,
				selectedPrinterCompany,
				showOtherPrinterCompanySelector: selectedPrinterCompany === OTHER,
				showOtherPrinterNameSelector: false,
				selectedPrinterName: '0',
				disableButton: true,
				printerNotExist: selectedPrinterCompany === OTHER,
				printerMaterialsList:
					selectedPrinterCompany === OTHER ? printerMaterialUniqueNames : [],
				selectedMaterials: [],
				selectedMaterialIds: [],
				otherMaterialsSelected: []
			}
		case IN_HOUSE_PRINTERS_NAME_SELECTED: {
			const { printerNotExist, selectedPrinter, printerMaterialsList } =
				action.payload
			const otherSelected = selectedPrinter.name === OTHER
			const selectedMaterials =
				selectedPrinter.inPrinterNamesList || otherSelected
					? []
					: printerMaterialsList.map(m => m.name)
			return {
				...state,
				printerNotExist,
				selectedPrinter,
				selectedPrinterName: selectedPrinter.name,
				showOtherPrinterNameSelector: otherSelected,
				disableButton:
					otherSelected ||
					isMaterialNotSelected(
						state.otherMaterialsSelected,
						selectedMaterials
					),
				printerMaterialsList,
				selectedMaterials,
				selectedMaterialIds:
					selectedPrinter.inPrinterNamesList || otherSelected
						? []
						: printerMaterialsList.map(m => m.id)
			}
		}
		case IN_HOUSE_PRINTERS_AMOUNT_CHANGED:
			return {
				...state,
				printerAmount: action.payload
			}
		case IN_HOUSE_PRINTERS_PRINTER_SUBMITTED:
			return {
				...state,
				inHousePrintersLoading: true
			}
		case IN_HOUSE_PRINTERS_PRINTER_ADDED_TO_USER:
			return {
				...state,
				userPrinters: action.payload
			}
		case IN_HOUSE_PRINTERS_PRINTER_ADDED:
			return {
				...state,
				...resetSelectedPrinter
			}
		case IN_HOUSE_PRINTERS_PRINTER_REMOVED:
			return {
				...state,
				userPrinters: action.payload
			}
		case IN_HOUSE_PRINTERS_USER_PRINTERS_RECEIVED:
			return {
				...state,
				userPrinters: action.payload
			}
		case IN_HOUSE_PRINTERS_USER_ID_SELECTED:
			return {
				...state,
				userId: action.payload
			}
		case IN_HOUSE_PRINTERS_USER_PRINTERS_FORMATTED:
			return {
				...state,
				userPrinters: [],
				userId: 0
			}
		case IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_SELECTED:
			return {
				...state,
				showPrinterNotExistAlert: true
			}
		case IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_ALERT_REMOVED:
			return {
				...state,
				...resetSelectedPrinter,
				showPrinterNotExistAlert: false
			}
		case IN_HOUSE_PRINTERS_OTHER_COMPANY_SELECTED: {
			const { selectedOtherPrinterCompany, selectedPrinter } = action.payload
			return {
				...state,
				selectedOtherPrinterCompany,
				selectedPrinter
			}
		}
		case IN_HOUSE_PRINTERS_OTHER_NAME_SELECTED: {
			const selectedPrinter = action.payload
			return {
				...state,
				selectedPrinter,
				selectedOtherPrinterName: selectedPrinter.name,
				disableButton:
					selectedPrinter.name.length === 0 ||
					isMaterialNotSelected(
						state.otherMaterialsSelected,
						state.selectedMaterials
					)
			}
		}
		case IN_HOUSE_PRINTERS_MATERIAL_NAME_ADDED:
			const { selectedMaterials } = action.payload
			return {
				...state,
				selectedMaterials,
				selectedMaterialIds: state.printerMaterialsList
					.filter(m => selectedMaterials.indexOf(m.name) > -1)
					.map(m => m.id),
				disableButton: shouldDisableButton(
					state.otherMaterialsSelected,
					selectedMaterials,
					state.selectedPrinterName,
					state.selectedOtherPrinterName
				)
			}
		case IN_HOUSE_PRINTERS_OTHER_MATERIAL_CLICKED:
			let disablePlusButton = false
			const payloadPrinter = action.payload

			const otherMaterialsSelected = state.otherMaterialsSelected.length
				? state.otherMaterialsSelected
				: [
						{
							id: state.otherMaterialsSelected.length + 1,
							name: '',
							price: '',
							unit: MATERIAL_COST_INPUTS_WEIGHT_UNIT[0]
						}
				  ]

			return {
				...state,
				printerAmount: 1,
				disablePlusButton,
				selectedPrinter:
					payloadPrinter?.selectedPrinter || state.selectedPrinter,
				selectedPrinterName:
					payloadPrinter?.selectedPrinter?.name || state.selectedPrinterName,
				otherMaterialsSelected,
				disableButton: shouldDisableButton(
					otherMaterialsSelected,
					state.selectedMaterials,
					payloadPrinter?.selectedPrinter?.name || state.selectedPrinterName,
					state.selectedOtherPrinterName
				)
			}

		case IN_HOUSE_OTHER_PRINTER_MATERIAL_NAME_CHANGED: {
			let disablePlusButton = true

			const { id, name } = action.payload
			let otherMaterialsSelected = state.otherMaterialsSelected.map(
				material => {
					if (material.id === id) {
						material.name = name
						return material
					}
					return material
				}
			)
			if (otherMaterialsSelected.length < MAX_MATERIAL_ADDED) {
				disablePlusButton = false
			}
			return {
				...state,
				otherMaterialsSelected,
				disablePlusButton: disablePlusButton,
				disableButton: shouldDisableButton(
					otherMaterialsSelected,
					state.selectedMaterials,
					state.selectedPrinterName,
					state.selectedOtherPrinterName
				)
			}
		}

		case IN_HOUSE_OTHER_PLUS_BUTTON_CLICK: {
			const { id, name } = action.payload
			let disablePlusButton = true
			let otherMaterialsSelected = state.otherMaterialsSelected.map(
				material => {
					if (material.id === id) {
						material.name = name
					}
					return material
				}
			)
			if (otherMaterialsSelected.length < MAX_MATERIAL_ADDED) {
				otherMaterialsSelected = otherMaterialsSelected.concat([
					{
						id: otherMaterialsSelected.length + 1,
						name: '',
						price: '',
						unit: MATERIAL_COST_INPUTS_WEIGHT_UNIT[0]
					}
				])
				disablePlusButton = false
			}
			return {
				...state,
				disableButton: false,
				disablePlusButton: disablePlusButton,
				otherMaterialsSelected
			}
		}

		case IN_HOUSE_OTHER_PRINTER_MATERIAL_PRICE_CHANGED: {
			const { id, price } = action.payload
			return {
				...state,
				otherMaterialsSelected: state.otherMaterialsSelected.map(material => {
					if (material.id === id) {
						material.price = price
						return material
					}
					return material
				})
			}
		}
		case IN_HOUSE_OTHER_PRINTER_MATERIAL_UNIT_CHANGED: {
			const { id, unit } = action.payload
			return {
				...state,
				otherMaterialsSelected: state.otherMaterialsSelected.map(material => {
					if (material.id === id) {
						material.unit = unit
						return material
					}
					return material
				})
			}
		}
		case IN_HOUSE_PRINTERS_ALERT_OPEN: {
			return {
				...state,
				showInHousePrintersAlert: true
			}
		}
		case IN_HOUSE_PRINTERS_ALERT_CLOSE: {
			return {
				...state,
				...resetSelectedPrinter,
				showInHousePrintersAlert: false
			}
		}
		case IN_HOUSE_PRINTERS_ONLY_CHECKBOX_CHANGED: {
			const inHousePrintersOnly = action.payload
			return {
				...state,
				inHousePrintersOnly
			}
		}
		case IN_HOUSE_PRINTERS_UNMOUNT: {
			return {
				...state,
				...action.payload
			}
		}

		default:
			return state
	}
}

const isMaterialNotSelected = (otherMaterialsSelected, selectedMaterials) => {
	const otherMaterials = otherMaterialsSelected.filter(m => m.name)

	return !otherMaterials.length && !selectedMaterials.length
}

const shouldDisableButton = (
	otherMaterialsSelected,
	selectedMaterials,
	selectedPrinterName,
	selectedOtherPrinterName
) => {
	return (
		isMaterialNotSelected(otherMaterialsSelected, selectedMaterials) ||
		!selectedPrinterName ||
		(selectedOtherPrinterName === OTHER && !selectedOtherPrinterName)
	)
}

export default customizeInHousePrintersReducer
