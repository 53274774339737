import {
	KEY_2d,
	KEY_3d,
	METADATA
} from 'Scenes/Home/NewUploadProject/constants'
import { getString } from 'Services/Strings/StringService'

const MAX_ITEM_COUNT = 3
const START_ITEM_COUNT = 0

export const makeFilterItemsList = (filterItemsList: any) => {
  let filterItemsListLength = filterItemsList

  if (
    filterItemsList?.length > MAX_ITEM_COUNT
  ) {
    filterItemsListLength = filterItemsList.slice(
      START_ITEM_COUNT, MAX_ITEM_COUNT)
    filterItemsListLength.push({
      allowRemove: false,
      key: '',
      value: `+${filterItemsList?.length - MAX_ITEM_COUNT}`
    })
  }

  return filterItemsListLength
}


export const getUploadExplanation = (key: string) => {
  let explanationText = ''
  let explanationSubText = ''

  switch (key) {
    case KEY_2d: {
      explanationText = getString('CHOOSE_MATERIAL_ON_UPLOAD_EXPLINATION');
    } break;
    case KEY_3d: {
      explanationText = getString('CHOOSE_MATERIAL_ON_UPLOAD_EXPLANATION_ASSEMBLY');
    } break;
    case METADATA: {
      explanationText = getString('CHOOSE_MATERIAL_METADATA_EXPLANATION');
      explanationSubText = getString('CHOOSE_MATERIAL_METADATA_EXPLANATION_SUB');
    } break;
  }

  return { explanationText, explanationSubText }
}