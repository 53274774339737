import parse from 'html-react-parser'
import { FC, memo, useMemo } from 'react'

import {
	convertCoDataWithUnits,
	getCoDataValue,
	showCO2Banner
} from './CostComparisonService'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Devider from 'Scenes/Components/Devider/Devider'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { EMISSIONS_COMPARISON_CO2, POTENTIAL_SAVING } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface IProps {
	configuration: any
	part: any
	shouldShowStandardCost: boolean
}
const DeviderTSX: any = Devider

const Co2Comparison: FC<IProps> = ({
	configuration,
	part,
	shouldShowStandardCost
}) => {
	const gainCO2 = configuration?.costResults?.gainCO2

	const shouldShowCO2Banner = useMemo(
		() => showCO2Banner(shouldShowStandardCost, part?.isDrawing),
		[shouldShowStandardCost, part?.isDrawing]
	)
	const { coData, CO2HasPotential } = useMemo(
		() => getCoDataValue(gainCO2),
		[gainCO2]
	)

	const convertedCoData = useMemo(
		() => convertCoDataWithUnits(coData),
		[coData]
	)

	if (!shouldShowCO2Banner) {
		return <div></div>
	}

	if (!CO2HasPotential) {
		return (
			<div className="co-tooltip co-tooltip-information">
				<div className="co-tooltip-information__text grayed">
					<IconFactory iconName="co2" className="admin-form-field__info_icon" />
					<div className="text">{getString('NO_POTENTIAL_SAVINGS')}</div>
				</div>
			</div>
		)
	}

	const tooltipExplanation = getString('EMISSIONS_COMPARISON_TEXT').format(
		`<b>${convertedCoData.total?.weight}</b> ${
			convertedCoData.total?.unit || ''
		}`
	)
	const tooltipExplanationMaterial = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.materialRaw?.weight || 0}</b> ${
			convertedCoData.materialRaw?.unit || ''
		}`
	)
	const tooltipExplanationElectricity = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.electricity?.weight || 0}</b> ${
			convertedCoData.electricity?.unit || ''
		}`
	)
	const tooltipExplanationPu = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.pu?.weight || 0}</b> ${
			convertedCoData.pu?.unit || ''
		}`
	)
	const tooltipExplanationTransportation = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.transportation?.weight || 0}</b> ${
			convertedCoData.transportation?.unit || ''
		}`
	)
	const tooltipExplanationEndOfLife = getString('CO2_RAW_TEXT').format(
		`<b>${convertedCoData.eol?.weight || 0}</b> ${
			convertedCoData.eol?.unit || ''
		}`
	)

	return (
		<div className="co-tooltip co-tooltip-information">
			<div className="co-tooltip-information__text">
				{convertedCoData.electricity && convertedCoData.eol ? (
					<DetailsPopup
						popperClassName={'co2-details-wrapper'}
						isHover={true}
						data={
							<div>
								<div className="cost-details-column">
									<div>{getString('EMISSIONS_COMPARISON_TEXT_HEADER')}</div>
									<div>{parse(tooltipExplanation)}</div>
								</div>
								<DeviderTSX
									size={'200px'}
									className="cost-details-row-divider"
								/>
								<br />
								<div className="cost-details-row">
									<div>{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}</div>
									<div>
										{gainCO2 && !Number(gainCO2)
											? parse(`${tooltipExplanationMaterial}<br />`)
											: ''}
									</div>
								</div>
								<div className="cost-details-row">
									<div>{getString('PRODUCT_MANUFACTURING')}</div>
									<div>
										{gainCO2 && !Number(gainCO2)
											? parse(`${tooltipExplanationElectricity}<br />`)
											: ''}
									</div>
								</div>
								<div className="cost-details-row">
									<div>{getString('PRODUCT_USE')}</div>
									<div>
										{gainCO2 && !Number(gainCO2)
											? parse(`${tooltipExplanationPu}<br />`)
											: ''}
									</div>
								</div>
								<div className="cost-details-row">
									<div>{getString('TRANSPORTATION')}</div>
									<div>
										{gainCO2 && !Number(gainCO2)
											? parse(`${tooltipExplanationTransportation}<br />`)
											: ''}
									</div>
								</div>
								<div className="cost-details-row">
									<div>
										{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}
									</div>
									<div>
										{gainCO2 && !Number(gainCO2)
											? parse(`${tooltipExplanationEndOfLife}<br />`)
											: ''}
									</div>
								</div>
								<DeviderTSX
									size={'200px'}
									className="cost-details-row-divider"
								/>
								{getString('EMISSIONS_COMPARISON_BOTTOM_TEXT')}
							</div>
						}
						popperDirection="top-start"
						popperContactClassName="info-box-data"
					>
						<IconFactory
							iconName="co2"
							className="admin-form-field__info_icon"
						/>

						<div className="text">
							{convertedCoData.total.weight} {convertedCoData.total.unit}
							{EMISSIONS_COMPARISON_CO2} {POTENTIAL_SAVING}
						</div>
					</DetailsPopup>
				) : (
					<div />
				)}
			</div>
		</div>
	)
}

export default memo(Co2Comparison)
