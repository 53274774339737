import { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'

import * as WeightReductionProgressActions from './WeightReductionProgressActions'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'
import { ISolutionFea } from '../../../Services/models/ISolutionFea'
import { partConfigurationsRoute } from '../../../Services/routeFuncs'
import {
	BACK,
	CANCEL,
	DONE,
	NEXT,
	WEIGHT_REDUCTION_PROGRESS_CUSTOMIZE_CONFIGURATIONS_BUTTONS,
	WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS,
	WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES,
	WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK,
	WEIGHT_REDUCTION_PROGRESS_STEP2_HEADERS,
	WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK,
	WIEGHT_REDUCTION_PROGRESS_POPUP_HEADER
} from '../../../Services/Strings'
import CastorRadio from '../../Components/CastorRadio'
import DetailWithTitle from '../../Components/DetailWithTitle/DetailWithTitle'
import IconFactory from '../../Components/StarIcon/IconFactory'
import { MainPartAnalysisInitialState } from '../../Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisReducer'
import CastorAlert from '../alerts/CastorAlert'
import { WeightReductionProgressInitialState } from './WeightReductionProgressReducer'
import Flexbox from 'Scenes/Components/FlexBox'
import { IBestMatchData } from 'Services/models/IBestMatch'

import './WeightReductionProgress.scss'

const FormControlTSX: any = FormControl
const FormControlLabelTSX: any = FormControlLabel
const RadioGroupTSX: any = RadioGroup

interface IReduxStore {
	WeightReductionProgressReducer: WeightReductionProgressInitialState
	MainPartAnalysisReducer: MainPartAnalysisInitialState
	ProjectAnalysisReducer: any
}

interface IProps {
	loading: boolean
	show: boolean
	onConfirm: Function
	onCancel: Function
	radioButtonSelected: any
	printersFullData: any
	weightReductionPartsLeadingData: IBestMatchData[]
	partId: number
	projectId: number
	isSTLFile: boolean
	hasBrepData: boolean
	configuration?: any
	configurationData: any
	feaExistAndPassed?: boolean
	initialStep?: number
	customInitialStep?: number
	feaId?: string | null
	partFeas?: Array<ISolutionFea>
	fromPart?: boolean
	onCancelClick: () => any
	onBackClick: () => any
	setup: (
		configuration: any,
		partId: number,
		feaExistAndPassed: boolean | undefined,
		weightReductionPartsLeadingData?: any,
		customInitialStep?: PROGRESS_STEPS
	) => any
	updateProjectFlag: () => any
	onConfirmButtonClicked: (
		fromPart: boolean,
		stepValue: number,
		configurationData: any,
		isSTLFile: boolean,
		hasBrepData: boolean,
		customInitialStep: number,
		onCancel: Function,
		radioStep2Value?: string,
		feaId?: string | null,
		partFeas?: Array<ISolutionFea>
	) => any
}

export enum PROGRESS_STEPS {
	STEP_1 = 1,
	STEP_2 = 2
}

export enum RADIO_BUTTONS_VALUE {
	RECOMMENDATION_MATERIAL_RADIO_VALUE = '1',
	PRINTERS_AND_MATERIALS_RADIO_VALUE = '2',
	FAST_TRACK_RADIO_BUTTON_VALUE = '3',
	OPTIMIZED_TRACK_RADIO_BUTTON_VALUE = '4'
}

const WeightReductionProgress: FC<
	IProps & WeightReductionProgressInitialState
> = ({
	loading,
	show,
	innerShow,
	onConfirm,
	onCancel,
	radioButtonSelected,
	weightReductionPartsLeadingData,
	partId,
	stepValue,
	isSTLFile,
	hasBrepData,
	configuration,
	feaExistAndPassed,
	projectId,
	initialStep,
	customInitialStep,
	configurationData,
	feaId,
	partFeas,
	fromPart = false,
	onCancelClick,
	onBackClick,
	setup,
	updateProjectFlag,
	onConfirmButtonClicked
}) => {
	useEffect(() => {
		if (radioButtonSelected) {
			setRadioStep1Value(radioButtonSelected)
		}
		setRadioStep2Value(undefined)
	}, [radioButtonSelected])

	useEffect(() => {
		setup(
			configuration,
			partId,
			feaExistAndPassed,
			weightReductionPartsLeadingData,
			initialStep
		)
	}, [weightReductionPartsLeadingData, partId, initialStep, feaExistAndPassed])
	const history = useHistory()
	const [radioStep1Value, setRadioStep1Value] = useState<string>()
	const [radioStep2Value, setRadioStep2Value] = useState<string>()

	const renderFastTrackOption = () => {
		return (
			<div>
				<IconFactory iconName="fastTrackIcon" className="icons" />
				<div className="step2-radio-button--radio--label--header">
					{WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK[0]}
				</div>
				<div className="step2-radio-button--radio--label--firstSection">
					{WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK[1]}
				</div>
				<div className="step2-radio-button--radio--label--secondSection">
					<div>{WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK[2]}</div>
					<div className="step2-radio-button--radio--label--secondSection-explanation">
						{WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK[3]}
					</div>
					<div className="step2-radio-button--radio--label--secondSection-subExplanation">
						{WEIGHT_REDUCTION_PROGRESS_STEP2_FAST_TRACK[4]}
					</div>
				</div>
			</div>
		)
	}
	const renderVerifiedAndOptimizedOption = () => {
		return (
			<div>
				<IconFactory iconName="optimizedTrackIcon" className="icons" />
				<div className="step2-radio-button--radio--label--header">
					{WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK[0]}
				</div>
				<div className="step2-radio-button--radio--label--firstSection">
					{WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK[1]}
				</div>
				<div className="step2-radio-button--radio--label--secondSection">
					<div>{WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK[2]}</div>
					<div className="step2-radio-button--radio--label--secondSection-explanation">
						{WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK[3]}
					</div>
					<div className="step2-radio-button--radio--label--secondSection-subExplanation">
						{WEIGHT_REDUCTION_PROGRESS_STEP2_OPTIMIZED_TRACK[4]}
					</div>
				</div>
			</div>
		)
	}

	const renderOrientationImage = () => {
		if (!configurationData?.trayOrientation) {
			return <div />
		}
		return (
			<DetailWithTitle
				title="Tray Orientation"
				className="step1--recommendation-wrapper--image-wrapper"
				titleClassName="step1--recommendation-wrapper--image-wrapper-title"
				body={
					<img
						className="step1--image"
						src={configurationData?.trayOrientation?.imageURL}
					/>
				}
			/>
		)
	}
	const renderStep1 = () => {
		return (
			<Flexbox flexDirection="column" alignItems="flex-start">
				<div className="material-recommendation--header">
					{WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS}
				</div>
				<div className="step1--recommendation-wrapper">
					<div className="step1--recommendation-wrapper--text-wrapper">
						<DetailWithTitle
							title={WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES[0]}
							className="step1--recommendation-wrapper--text-wrapper--title"
							bodyClassName="step1--recommendation-wrapper--text-wrapper--body"
							body={configurationData?.printerName || ''}
						/>
						<DetailWithTitle
							title={WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES[1]}
							className="step1--recommendation-wrapper--text-wrapper--title"
							bodyClassName="step1--recommendation-wrapper--text-wrapper--body"
							body={configurationData?.printerMaterialName || ''}
						/>
						<DetailWithTitle
							title={WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES[2]}
							className="step1--recommendation-wrapper--text-wrapper--title"
							bodyClassName="step1--recommendation-wrapper--text-wrapper--body"
							body={`{0}${configurationData?.cost || 0}`.format('$')}
						/>
						<DetailWithTitle
							title={WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES[3]}
							className="step1--recommendation-wrapper--text-wrapper--title"
							bodyClassName="step1--recommendation-wrapper--text-wrapper--body"
							body={configurationData?.leadTime || 0}
						/>
						<DetailWithTitle
							title={WEIGHT_REDUCTION_PROGRESS_STEP1_TITLES[4]}
							className="step1--recommendation-wrapper--text-wrapper--title"
							bodyClassName="step1--recommendation-wrapper--text-wrapper--body"
							body={configurationData?.configurationResult}
						/>
					</div>
					{renderOrientationImage()}
				</div>
			</Flexbox>
		)
	}
	const renderStep2 = () => {
		return (
			<div>
				<FormControlTSX component="fieldset">
					<div>{WEIGHT_REDUCTION_PROGRESS_STEP2_HEADERS[0]}</div>
					<div className="step2-radio-button--headers-subHeader">
						{WEIGHT_REDUCTION_PROGRESS_STEP2_HEADERS[1]}
					</div>
					<RadioGroupTSX
						row={true}
						aria-label="select materials"
						name="select materials"
						value={radioStep2Value}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							setRadioStep2Value(e.target.value)
						}}
						classes={{
							root: `step2-wrapper`
						}}
						color="rose"
					>
						<FormControlLabelTSX
							value={RADIO_BUTTONS_VALUE.FAST_TRACK_RADIO_BUTTON_VALUE}
							control={<CastorRadio className="step2-radio-button--radio" />}
							label={renderFastTrackOption()}
							classes={{
								root: `step2-radio-button--radio--label`
							}}
						/>
						<FormControlLabelTSX
							value={RADIO_BUTTONS_VALUE.OPTIMIZED_TRACK_RADIO_BUTTON_VALUE}
							control={<CastorRadio className="step2-radio-button--radio" />}
							label={renderVerifiedAndOptimizedOption()}
							classes={{
								root: `step2-radio-button--radio--label`
							}}
						/>
					</RadioGroupTSX>
				</FormControlTSX>
			</div>
		)
	}
	const getCurrentStateBody = () => {
		switch (stepValue) {
			case PROGRESS_STEPS.STEP_1:
				return renderStep1()
			case PROGRESS_STEPS.STEP_2:
				return renderStep2()
			default:
				return renderStep1()
		}
	}

	const getConfirmText = () => {
		switch (stepValue) {
			case PROGRESS_STEPS.STEP_2:
				return DONE
			case PROGRESS_STEPS.STEP_1:
				return NEXT
			default:
				return ''
		}
	}
	const getCancelText = () => {
		if (customInitialStep === PROGRESS_STEPS.STEP_2) {
			return CANCEL
		}
		switch (stepValue) {
			case PROGRESS_STEPS.STEP_2:
				return BACK
			case PROGRESS_STEPS.STEP_1:
				return WEIGHT_REDUCTION_PROGRESS_CUSTOMIZE_CONFIGURATIONS_BUTTONS
			default:
				return ''
		}
	}

	const onCancelOrBackClicked = () => {
		if (!customInitialStep && stepValue >= PROGRESS_STEPS.STEP_2) {
			onBackClick()
		} else if (stepValue === PROGRESS_STEPS.STEP_1) {
			updateProjectFlag()
			onCancelClick()
			onCancel()
			history.push(partConfigurationsRoute(projectId, partId))
		} else {
			onCancelClick()
			onCancel()
		}
	}

	const onTopCancel = () => {
		onCancelClick()
		onCancel()
	}
	const needToDisableButton = () => {
		if (stepValue === PROGRESS_STEPS.STEP_2) {
			return !radioStep2Value
		}
		return false
	}

	return (
		<CastorAlert
			alertClass="weight-progress-alert"
			alertBodyClass="weight-progress-alert--body"
			loadingCalculation={loading}
			headerTitle={WIEGHT_REDUCTION_PROGRESS_POPUP_HEADER}
			show={innerShow && show}
			onConfirm={() =>
				onConfirmButtonClicked(
					fromPart,
					stepValue,
					configurationData,
					isSTLFile,
					hasBrepData,
					customInitialStep,
					onCancel,
					radioStep2Value,
					feaId,
					partFeas
				)
			}
			onCancel={() => onTopCancel()}
			onFooterCancel={() => onCancelOrBackClicked()}
			confirmOptionalText={getConfirmText()}
			cancelOptionalText={getCancelText()}
			disabled={needToDisableButton()}
		>
			<div>{getCurrentStateBody()}</div>
		</CastorAlert>
	)
}

const mapStateToProps = ({
	WeightReductionProgressReducer,
	MainPartAnalysisReducer: { stepURLExist, feaId, partFeas },
	ProjectAnalysisReducer: { weightReductionPartsLeadingData }
}: IReduxStore) => {
	return {
		...WeightReductionProgressReducer,
		stepURLExist,
		weightReductionPartsLeadingData,
		feaId,
		partFeas
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...WeightReductionProgressActions }, dispatch)

export default WithFeatureToggleHOC(
	memo(connect(mapStateToProps, mapDispatchToProps)(WeightReductionProgress)),
	FeatureComponentId.WEIGHT_REDUCTION
)

//for future use , need to add to IProps and function args
// const companyChanged = (
//   value: ChangeEvent<HTMLSelectElement>,
//   printersFullData: any
// ) => {
//   onCompanySelectorChange(value, printersFullData)
// }

// const printerChanged = (value: ChangeEvent<HTMLSelectElement>) => {
//   onPrinterSelectorChange(value)
// }

// const materialChanged = (value: ChangeEvent<HTMLSelectElement>) => {
//   onMaterialSelectorChange(value)
// }

// const onConfirmButtonClicked = async () => {
//   if (stepValue === PROGRESS_STEPS.STEP_2) {
//     switch (radioStep2Value) {
//       case RADIO_BUTTONS_VALUE.FAST_TRACK_RADIO_BUTTON_VALUE:
//         await onFastTrackChoose(configurationData.id, configurationData.part)
//         onCancel()
//         return
//       case RADIO_BUTTONS_VALUE.OPTIMIZED_TRACK_RADIO_BUTTON_VALUE:
//         await onOptimizedTrackChoose(
//           configurationData.id,
//           configurationData.part,
//           isSTLFile,
//           configurationData.feaExistAndPassed
//         )
//         onCancel()
//         return
//       default:
//         return
//     }
//   }
//   nextStep()
// }
// const nextStep = () => {
//   const bestMatchData = configurationData
//   const fdm = bestMatchData && bestMatchData.printerTechnology === 'fdm'
//   onNextStepClick(customInitialStep, fdm)
// }

// WE MAY USING IT IN THE FEATURE
//
// const renderStep1 = () => {
//   return (
//     <div>
//       <FormControlTSX component="fieldset">
//         <div className="step1--headers">
//           {WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS[0]}
//         </div>
//         <div className="step1--headers-subHeader">
//           {WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS[1]}
//         </div>
//         <RadioGroupTSX
//           aria-label="select materials"
//           name="select materials"
//           value={radioStep1Value}
//           onChange={(e: ChangeEvent<HTMLInputElement>) => {
//             setRadioStep1Value(e.target.value)
//           }}
//           color="rose"
//         >
//           <FormControlLabelTSX
//             value={RADIO_BUTTONS_VALUE.RECOMMENDATION_MATERIAL_RADIO_VALUE}
//             control={<CastorRadio className="step1--radio-button" />}
//             label={renderMaterialRecommendation()}
//             classes={{ root: 'step1--form-label--root' }}
//           />
//           <FormControlLabelTSX
//             value={RADIO_BUTTONS_VALUE.PRINTERS_AND_MATERIALS_RADIO_VALUE}
//             control={<CastorRadio className="step1--radio-button" />}
//             label={renderMaterialPicker()}
//             classes={{ root: 'step1--form-label--root' }}
//           />
//         </RadioGroupTSX>
//       </FormControlTSX>
//     </div>
//   )
// }

// const renderMaterialRecommendation = () => {
//   return (
//     <Flexbox flexDirection="column" alignItems="flex-start">
//       <div className="material-recommendation--header">
//         {WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS[2]}
//       </div>
//       <div className="material-recommendation--textFields-wrapper">
//         <TextField
//           type="text"
//           disabled={true}
//           labelClassName="picker--headers"
//           inputClassName="picker--inputs"
//           label="Printer"
//           input={{
//             value: configurationData?.printerName || ''
//           }}
//         ></TextField>
//         <TextField
//           type="text"
//           disabled={true}
//           labelClassName="picker--headers"
//           inputClassName="picker--inputs"
//           label="Material"
//           input={{
//             value: configurationData?.printerMaterialName || ''
//           }}
//         ></TextField>
//         <TextField
//           type="text"
//           disabled={true}
//           labelClassName="picker--headers"
//           inputClassName="picker--inputs"
//           label="Cost Estimates"
//           input={{
//             value: `{0}${configurationData?.cost || 0}`.format('$')
//           }}
//         ></TextField>
//       </div>
//     </Flexbox>
//   )
// }

// const renderMaterialPicker = () => {
//   return (
//     <div>
//       <div className="material-recommendation--header">
//         {WEIGHT_REDUCTION_PROGRESS_STEP1_HEADERS[3]}
//       </div>
//       <SimpleConfigurationSelector
//         companiesList={
//           printersCompaniesList.length
//             ? printersCompaniesList
//             : WeightReductionMaterialsData.printersCompaniesList
//         }
//         printersList={
//           materialsPickerPrinters.length
//             ? materialsPickerPrinters
//             : WeightReductionMaterialsData.simpleConfigurationPrinters
//         }
//         materialsList={
//           materialsPickerMaterialsList.length
//             ? materialsPickerMaterialsList
//             : WeightReductionMaterialsData.simpleConfigurationMaterialsList
//         }
//         onMaterialsChange={(e: any) => materialChanged(e)}
//         onCompanyChange={(e: any) => companyChanged(e, printersFullData)}
//         onPrinterChange={(e: any) => printerChanged(e)}
//         printerCompanyValue={materialsPickerCompanyValue}
//         printerValue={materialsPickerPrinterValue}
//         materialValue={materialsPickerMaterialValue}
//         disabled={
//           radioStep1Value ===
//           RADIO_BUTTONS_VALUE.RECOMMENDATION_MATERIAL_RADIO_VALUE
//         }
//         dropDownsHeaderClass="picker--headers"
//         selectMenuClassName="step1--selector-input"
//         displayAsRow
//       />
//     </div>
//   )
// }
