import React, { memo, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import cx from 'classnames'

import ResultDetail from './ResultDetail'
import { getConfigurationFailReason } from './SolutionAnalysisTopService'
import TopDetails from './TopDetailsBlocks/index'
import TopNewConfigurationBody from './TopDetailsBlocks/TopNewConfigurationBody'
import ExpandButton from 'Scenes/Components/ExpandButton'
import Flexbox from 'Scenes/Components/FlexBox'
import { IAlternativePrinters } from 'Services/models/AlternativePrinters'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { IConfiguration } from 'Services/models/IConfiguration'
import { CONFIGURATION_RESULT_NOT_PRINTABLE } from 'Services/Strings'

import '../SolutionAnalysis.scss'
import './ResultDetail.scss'

interface Props {
	showSolutionDetails: boolean
	configuration: IConfiguration
	result: string
	resultTitle: string
	failedPrintIssuesIds: number[]
	resultBody: string
	userCurrencySign: string
	newConfiguration: boolean
	solution: any
	alternativeSolutionsPrintersList: Array<IAlternativePrinters>
	onPrinterChange: Function
	onConfigureClick: Function
	onRetrieveResultClick: Function
	showAnalysis: boolean
	timeBenefit: any
	costDetails: any
	customConfiguration: boolean
	loading: boolean
	disablePart: boolean
	isSpecifiedQuantity: boolean
	chainBenefits: IChainBenefits
	isCluster?: boolean
	firstParts?: number
	showCostInRange?: boolean
	classNameTopDetails?: string
	disableConfigurationHeader?: boolean
	disableAlternativeSolution?: boolean
	isSmallPart: boolean
}

const SolutionAnalysisTopDetails: React.FC<Props> = ({
	showSolutionDetails,
	result,
	configuration,
	resultTitle,
	failedPrintIssuesIds,
	resultBody,
	showCostInRange,
	solution,
	onConfigureClick,
	showAnalysis,
	onRetrieveResultClick,
	timeBenefit,
	costDetails,
	userCurrencySign,
	alternativeSolutionsPrintersList,
	onPrinterChange,
	customConfiguration,
	isCluster,
	disablePart,
	chainBenefits,
	loading,
	firstParts,
	classNameTopDetails,
	disableConfigurationHeader,
	disableAlternativeSolution,
	isSpecifiedQuantity,
	isSmallPart
}: Props) => {
	const includeSupplyChainCosts = useSelector(
		(state: RootStateOrAny) =>
			state?.user?.userCustomizationSettings?.includeSupplyChainCosts
	)
	const drawingCostPercentage = useSelector((state: RootStateOrAny) => {
		return (
			state?.user?.userCustomizationSettings?.drawingCostPercentage ||
			state?.MainPartAnalysisReducer?.drawingCostPercentage
		)
	})
	const isAdditiveOn = Feature.isFeatureOn(
		FeatureComponentId.SHOW_CONTACT_ADDITIVE_MINDS_BUTTON
	)

	const configurationIsUnprintable =
		resultBody === CONFIGURATION_RESULT_NOT_PRINTABLE

	const { configurationFailReason, extraFailReasonsCount } = useMemo(
		() =>
			getConfigurationFailReason(
				configurationIsUnprintable,
				failedPrintIssuesIds,
				isSmallPart
			),
		[configurationIsUnprintable, failedPrintIssuesIds, isSmallPart]
	)

	const renderData = () => {
		if (configuration.id <= 0) {
			return (
				<TopNewConfigurationBody
					disablePart={disablePart}
					result={result}
					resultTitle={resultTitle}
					resultBody={resultBody}
					disableConfigurationHeader={disableConfigurationHeader}
				/>
			)
		}

		const showAdditiveButton = isAdditiveOn && configurationIsUnprintable
		const showNoConfigurationContent = Feature.isFeatureOn(
			FeatureComponentId.SHOW_NO_CONFIGURATION_CONTENT
		)
		const showRetrieveResults =
			!showAnalysis &&
			Feature.isFeatureOn(FeatureComponentId.RETRIEVE_RESULTE_UNPRINTABLE)

		return (
			<>
				<ResultDetail
					detailClass={cx({
						'show-retrieve-result': showRetrieveResults || !solution,
						'with-solution': !!solution,
						'without-configuration': !showNoConfigurationContent,
						'no-printable': configurationIsUnprintable
					})}
					showAdditiveButton={showAdditiveButton}
					result={result}
					title={resultTitle}
					body={resultBody}
					extraBodyClassText={
						configurationIsUnprintable ? (
							<>
								<span
									className="no-printable-reason"
									title={configurationFailReason}
								>
									{configurationFailReason}
								</span>
								{extraFailReasonsCount > 0 && (
									<span className="no-printable-extra-reasons">
										+{extraFailReasonsCount}
									</span>
								)}
							</>
						) : (
							''
						)
					}
					extraBodyClassName="no-printable-explanation"
				/>
				<TopDetails
					solution={solution}
					onConfigureClick={onConfigureClick}
					onRetrieveResultClick={onRetrieveResultClick}
					configurationId={configuration.id}
					showAnalysis={showAnalysis}
					showSolutionDetails={showSolutionDetails}
					userCurrencySign={userCurrencySign}
					timeBenefit={timeBenefit}
					costDetails={costDetails}
					printersList={alternativeSolutionsPrintersList}
					customConfiguration={customConfiguration}
					loading={loading}
					onPrinterChange={onPrinterChange}
					chainBenefits={chainBenefits}
					isCluster={isCluster}
					includeSupplyChainCosts={includeSupplyChainCosts}
					firstParts={firstParts}
					showCostInRange={showCostInRange}
					drawingCostPercentage={drawingCostPercentage}
					disableAlternativeSolution={disableAlternativeSolution}
					productLifeCycle={configuration.productLifeCycle}
					isSpecifiedQuantity={isSpecifiedQuantity}
					traditionalCostCalculatedCustom={
						configuration.traditionalCostCalculatedCustom
					}
				/>
			</>
		)
	}

	return (
		<Flexbox
			className={classNameTopDetails}
			alignItems="center"
			justifyContent="space-between"
			height={'114px'}
		>
			<ExpandButton
				className={cx('solution-header--see-more', {
					hide: showSolutionDetails
				})}
			/>
			{renderData()}
		</Flexbox>
	)
}

export default memo(SolutionAnalysisTopDetails)
