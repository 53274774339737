import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isBoolean } from 'lodash'

import {
	ADMIN_GET_SMTP_MAIL,
	ADMIN_GET_SMTP_MAIL_ERROR,
	ADMIN_GET_SMTP_MAIL_LOADING,
	ADMIN_SMTP_MAIL_DATA_CHANGE,
	ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
	ADMIN_VERIFY_SMTP_MAIL
} from './AdminSettingsTypes'
import { ActionWithPayload } from 'global actions/ActionModels'
import {
	ADMIN_SETINGS_PRIORITIES_TOGGLED,
	ADMIN_SETINGS_SETUPED,
	ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import { store } from 'index'
import { prepareEmailDataToSend } from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsService'
import { IPriority } from 'Services/models/IPriority'
import {
	getMailSettings,
	setRelativeOriginalMaterial,
	updateMailSettings,
	updateSingletons,
	verifyMailSettings
} from 'Services/Network'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const setupAdminSettings = (
	priorities: IPriority[]
): ActionWithPayload<any> => {
	const isRelativeToOriginalMaterial = !!priorities.find(
		(priority: IPriority) => priority.relativeToOriginalMaterial
	)

	return {
		type: ADMIN_SETINGS_SETUPED,
		payload: { isRelativeToOriginalMaterial }
	}
}

export const onPriorityToggleChange = (
	isRelativeToOriginalMaterial: boolean
): any => {
	return async (dispatch: any) => {
		try {
			const {
				data: { priorities }
			}: any = await setRelativeOriginalMaterial(isRelativeToOriginalMaterial)
			dispatch({
				type: ADMIN_SETINGS_PRIORITIES_TOGGLED,
				payload: { isRelativeToOriginalMaterial, priorities }
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || 'error switching setting'
				}
			})
		}
	}
}

export const onUpdateSingletonsClick = (): any => {
	return async (dispatch: any) => {
		try {
			dispatch({
				type: ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED
			})
			await updateSingletons()
			dispatch({
				type: ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('DATA_WAS_UPDATED')
				}
			})
		} catch (error: any) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.message || 'error update singletons'
				}
			})
		}
	}
}

export const updateEmailManager = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: true
		})

		try {
			const { mailConfigurations, mailData } =
				store.getState().AdminSettingsReducer

			const prepareDataToSend = prepareEmailDataToSend(
				mailData,
				mailConfigurations
			)

			const response = await updateMailSettings(prepareDataToSend)

			if (response) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.SUCCESS,
						notificationMessage: getString('DATA_WAS_UPDATED')
					}
				})
				dispatch({
					type: ADMIN_GET_SMTP_MAIL,
					payload: {
						mailConfigurations: response.data.emailSettings
					}
				})

			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: getString('DATA_WAS_NOT_UPDATED')
					}
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_GET_SMTP_MAIL_ERROR,
				payload: true
			})
			console.error(err)
		}

		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: false
		})
	}
}

export const changeEmailManager = (name: string, value: string) => {
	return (dispatch: Dispatch<any>) => {
		const { mailVerified } = store.getState().AdminSettingsReducer
		if (isBoolean(mailVerified)) {
			dispatch({
				type: ADMIN_VERIFY_SMTP_MAIL,
				payload: null
			})
		}
		dispatch({
			type: ADMIN_SMTP_MAIL_DATA_CHANGE,
			payload: { name, value }
		})
	}
}

export const verifyEmailManager = () => {
	return async (dispatch: Dispatch<any>) => {
		dispatch({
			type: ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
			payload: true
		})
		try {
			const response = await verifyMailSettings()
			if (response) {
				dispatch({
					type: ADMIN_VERIFY_SMTP_MAIL,
					payload: true
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_VERIFY_SMTP_MAIL,
				payload: false
			})
		}
		dispatch({
			type: ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
			payload: false
		})
	}
}

export const getEmailManager = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: true
		})
		try {
			const response = await getMailSettings()
			if (response) {
				dispatch({
					type: ADMIN_GET_SMTP_MAIL,
					payload: {
						mailConfigurations: response.data.emailSettings
					}
				})
				dispatch({
					type: ADMIN_GET_SMTP_MAIL_LOADING,
					payload: false
				})
			} else {
				dispatch({
					type: ADMIN_GET_SMTP_MAIL_ERROR,
					payload: true
				})
			}
		} catch (err) {
			dispatch({
				type: ADMIN_GET_SMTP_MAIL_ERROR,
				payload: true
			})

			console.error(err)
		}
		dispatch({
			type: ADMIN_GET_SMTP_MAIL_LOADING,
			payload: false
		})
	}
}