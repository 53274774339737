import { ChangeEvent, FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import { ListItemText, MenuItem, Select } from '@material-ui/core'
import isArray from 'lodash/isArray'

import {
	getDataOptions,
	getSelectedOptions
} from '../../AdvancedSettingsService'
import { changeAdvancedFilterTechnology } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

interface MultiCheckboxFilterProps {
	id: string | number
	filter: IFilterData
	printingTechnologies: IPrintingTechnology[]
}

const renderMaterialNamesMenuItems = (
	dataOptions: any = [],
	selectedOptions: any = [],
	onChangeFilterValue: any
) => {
	const lastChecked = selectedOptions.length === 1
	return dataOptions.map((data: any) => {
		const checked = isArray(selectedOptions)
			? selectedOptions?.indexOf(data.key) > -1
			: selectedOptions

		return (
			<MenuItem
				className="select-toggle-custom"
				key={data.key}
				value={data.key}
			>
				<CastorCheckbox checked={checked} disabled={checked && lastChecked} />
				<ListItemText
					className="select-toggle-custom-label"
					primary={data.value}
				/>
				<div
					className="select-toggle-only"
					onClick={(e: any) => {
						e.stopPropagation()
						onChangeFilterValue([data.key])
					}}
				>
					{getString('SELECT_ONLY_TECHNOLOGY')}
				</div>
			</MenuItem>
		)
	})
}

const MultiCheckboxFilter: FC<MultiCheckboxFilterProps> = ({
	id,
	filter,
	printingTechnologies
}) => {
	const dispatch = useDispatch()
	const value = filter?.value as Array<any> | null
	const onChangeFilterValue = (value: string[] | null) => {
		dispatch(changeAdvancedFilterTechnology(id, value, filter.name))
	}
	let selectedOptions =
		value && value.length > 0
			? filter.value
			: getSelectedOptions(filter, printingTechnologies)

	let dataOptions: any = getDataOptions(filter, printingTechnologies)
	return (
		<Select
			className="select filter-fields--widget--text-field-box"
			MenuProps={{
				variant: 'menu',
				getContentAnchorEl: null
			}}
			multiple
			disableUnderline={true}
			value={selectedOptions}
			onChange={(e: ChangeEvent<any>) => {
				const valueLength = e.target.value?.length

				if (valueLength > 0) {
					onChangeFilterValue(e.target.value)
					if (valueLength === dataOptions?.length) {
						onChangeFilterValue(null)
					}
				}
			}}
			renderValue={(selected: any) =>
				selected.length === dataOptions?.length
					? getString('ALL')
					: getString('CUSTOM')
			}
		>
			<MenuItem value="0" className="select-toggle-all">
				<Button
					onClick={(e: any) => {
						e.stopPropagation()
						onChangeFilterValue(null)
					}}
					title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
					style={{ margin: '0 auto', padding: 'unset', width: 'fit-content' }}
				>
					{getString('SELECT_ALL_TECHNOLOGIES_HEADER')}
				</Button>
			</MenuItem>
			{dataOptions?.length ? (
				renderMaterialNamesMenuItems(
					dataOptions,
					selectedOptions,
					onChangeFilterValue
				)
			) : (
				<div />
			)}
		</Select>
	)
}

export default memo(MultiCheckboxFilter)
