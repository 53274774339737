import React, {
	ChangeEvent,
	FC,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useReducer
} from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, TextField } from '@material-ui/core'
import cx from 'classnames'
import { isEmpty, lowerCase } from 'lodash'

import CastorAlert from '../../../Components/alerts/CastorAlert'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import {
	fullCountry,
	fullState,
	updatedStates,
	USA
} from 'Scenes/Components/CastorLocationSearchInput/CastorLocationDropdown/addressInfo/countryStateCity'
import {
	ICountry,
	IState
} from 'Scenes/Components/CastorLocationSearchInput/CastorLocationDropdown/addressInfo/ICountryStateCity'
import {
	onAddressDropdownSelected,
	onGetAllAddresses
} from 'Scenes/Components/CastorLocationSearchInput/CastorLocationDropdown/CastorLocationDropdownActions'
import CastorLocationSearchInput from 'Scenes/Components/CastorLocationSearchInput/CastorLocationSearchInput'
import Flexbox from 'Scenes/Components/FlexBox'
import { PREV_PATH } from 'Scenes/Home/NewUploadProject/constants'
import { setStringItemToLocalStorage } from 'Services/LocalStorageService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { USER_PROFILE_ADDRESS_PLACEHOLDER } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { emailValidation } from 'Services/Utils/emailTools'
import { getTheme } from 'themes/getTheme'

import logoImage from '../../../../assets/img/castorLogoBlack.png'

import './LightUserUploadProjectAlert.scss'

const NULL_VAL = null
const EMPTY_VAL = ''

const { lightUserAlert, logo } = getTheme()

const initialState = {
	inCorrectEmail: false,
	emailMessage: '',
	nameErrorMessage: '',
	companyErrorMessage: '',
	email: '',
	company: '',
	selectedState: NULL_VAL as IState | null,
	name: '',
	zipCode: '',
	country: NULL_VAL as ICountry | null,
	usageAgreement: false,
	errorValidation: '',
	loading: false
}

const reducer = (
	prevState: typeof initialState,
	nextState: typeof initialState
) => ({
	...prevState,
	...nextState
})

const TextFieldTSX: any = TextField

interface IProps {
	show: boolean
	errorMessage: string
	onConfirm: Function
	onCancel: Function
}

const LightUserUploadProjectAlert: FC<IProps> = ({
	show,
	errorMessage,
	onConfirm,
	onCancel
}) => {
	const address = useSelector(
		(state: RootStateOrAny) => state.CastorLocationSearchReducer
	)

	const {
		showHeaderText = true,
		headerWithLogo = false,
		withLogo = true,
		showTerms = false,
		showCancel = true,
		showUpload = true
	} = lightUserAlert || {}

	const initializerArg = {
		...initialState,
		usageAgreement: !showTerms
	}

	const [state, dispatch] = useReducer(reducer, initializerArg)

	const {
		email,
		name,
		usageAgreement,
		inCorrectEmail,
		emailMessage,
		nameErrorMessage,
		errorValidation,
		companyErrorMessage,
		company,
		loading,
		zipCode,
		country,
		selectedState
	} = state

	const freeProvidersRegistrationIsOn = Feature.isFeatureOn(
		FeatureComponentId.FREE_PROVIDERS_REGISTRATION
	)
	const isAddressFieldOn = Feature.isFeatureOn(
		FeatureComponentId.UPLOAD_USER_COUNTRY_FIELD
	)
	const dispatchToStore = useDispatch()

	const headerTitle = useMemo(
		() =>
			headerWithLogo || !showHeaderText
				? ' '
				: getString('LIGHT_USER_UPLOAD_PROJECT_POPUP_HEADER'),
		[headerWithLogo, showHeaderText]
	)

	const isAddressError = useMemo(
		() => isAddressFieldOn && isEmpty(address.country),
		[address]
	)

	const showUsaStates = USA.includes(lowerCase(address.country))

	const isSubmitDisabled = useMemo(
		() =>
			!!errorValidation ||
			isEmpty(email) ||
			inCorrectEmail ||
			name?.length < 2 ||
			company?.length < 2 ||
			isAddressError ||
			!usageAgreement ||
			(showUsaStates && !selectedState),
		[
			errorValidation,
			email,
			inCorrectEmail,
			name?.length,
			company?.length,
			usageAgreement,
			isAddressError,
			showUsaStates,
			selectedState
		]
	)

	const setLoading = (loading: boolean) =>
		dispatch({
			...state,
			loading
		})

	const submitConfirm = () => {
		setLoading(true)
		onConfirm(email, name, company, zipCode)
	}

	useEffect(() => {
		dispatch({
			...state,
			errorValidation: errorMessage,
			...(errorMessage
				? {
						loading: false
				  }
				: {})
		})
	}, [errorMessage])

	useEffect(() => {
		if (isAddressFieldOn) {
			dispatchToStore(onGetAllAddresses())
		}
	}, [dispatchToStore])

	const handleAlertCancel = useCallback(() => {
		onCancel()
		dispatch(initializerArg)
		dispatchToStore(onAddressDropdownSelected(NULL_VAL, NULL_VAL, EMPTY_VAL))
	}, [onCancel])

	const onChangeCountryDropdownValue = (country: ICountry) => {
		dispatch({ ...state, selectedState: null, country })
		dispatchToStore(onAddressDropdownSelected(country, NULL_VAL, EMPTY_VAL))
	}
	const onChangeStateDropdownValue = (selectedState: IState) => {
		dispatch({ ...state, selectedState })
		dispatchToStore(
			onAddressDropdownSelected(state.country, selectedState, EMPTY_VAL)
		)
	}
	const listLength = (elem?: IState[]) => elem && elem.length > 0
	const getValue = (e: ChangeEvent<HTMLSelectElement>) => e.target.value

	const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch({
			...state,
			name: e.target.value,
			nameErrorMessage:
				e.target.value?.length < 2 ? getString('NAME_TO_SHORT') : ''
		})
	}

	const onZipChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch({
			...state,
			zipCode: e.target.value
		})
	}

	const onCompanyChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch({
			...state,
			company: e.target.value,
			companyErrorMessage:
				e.target.value?.length < 2 ? getString('COMPANY_NAME_TO_SHORT') : ''
		})
	}

	const onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const validation = emailValidation(
			e.target.value,
			freeProvidersRegistrationIsOn
		)
		dispatch({
			...state,
			errorValidation: '',
			email: e.target.value,
			inCorrectEmail: !validation.valid,
			emailMessage: validation.errorMessage
		})
	}

	const onAgreementChange = (e: ChangeEvent<HTMLInputElement>) => {
		dispatch({ ...state, usageAgreement: e.target.checked })
	}

	const renderAlertBody = () => {
		const onUsageAgreementClick = () => {
			setStringItemToLocalStorage(PREV_PATH, window.location?.href)
			window.open(getString('USAGE_AGREEMENT_LINK'), '_blank')?.focus()
		}
		const statesToCountry = updatedStates(state.country?.iC, address.allStates)

		return (
			<Flexbox className="light-user-upload-project-alert">
				{headerWithLogo && (
					<img className="header-logo" src={logo} alt="logo" />
				)}
				{withLogo && (
					<img
						className="light-user-upload-project-alert-logo"
						src={logoImage}
						alt="logo"
					/>
				)}
				<div className="popup-message">
					{getString('LIGHT_USER_UPLOAD_PROJECT_POPUP_MESSAGE')}
					<span className="light-user-upload-project-bold-text">
						{getString('LIGHT_USER_UPLOAD_PROJECT_LIMIT_TRIAL_POPUP_MESSAGE')}
					</span>
				</div>
				<TextFieldTSX
					className="light-user-upload-project-alert-name"
					variant="standard"
					placeholder={getString('FULL_NAME_PLACEHOLDER')}
					onChange={onNameChange}
					helperText={nameErrorMessage}
					error={!!nameErrorMessage}
					data-qa="data-qa-light-user-name"
				/>
				<TextFieldTSX
					type="email"
					className="light-user-upload-project-alert-email"
					variant="standard"
					placeholder={getString('EMAIL_PLACEHOLDER')}
					onChange={onEmailChange}
					helperText={emailMessage}
					error={inCorrectEmail}
					data-qa="data-qa-light-user-email"
				/>
				<TextFieldTSX
					className="light-user-upload-project-alert-company"
					variant="standard"
					placeholder={getString('COMPANY_PLACEHOLDER')}
					onChange={onCompanyChange}
					helperText={companyErrorMessage}
					error={!!companyErrorMessage}
					data-qa="data-qa-light-user-email"
				/>
				{isAddressFieldOn && (
					<div className="light-user-upload-project-alert-address">
						<TextFieldTSX
							disabled={!listLength(address.allCountries)}
							InputLabelProps={{
								shrink: !!address.country
							}}
							select
							SelectProps={{
								MenuProps: {
									PopoverClasses: {
										root: 'dropdown'
									}
								},
								classes: {
									root: 'country-select-root'
								}
							}}
							classes={{ root: 'address-input-root' }}
							label={getString('SELECT_YOUR_COUNTRY')}
							value={country?.iC || EMPTY_VAL}
							onChange={(e: ChangeEvent<HTMLSelectElement>) => {
								const countryCode = getValue(e)
								const selectedCountry =
									fullCountry(countryCode, address.allCountries) || NULL_VAL
								onChangeCountryDropdownValue(selectedCountry)
							}}
						>
							<MenuItem disabled>{USER_PROFILE_ADDRESS_PLACEHOLDER}</MenuItem>
							{address.allCountries?.map(
								({ n: countryName, iC: countryCode }: ICountry) => (
									<MenuItem value={countryCode} key={countryCode}>
										{countryName}
									</MenuItem>
								)
							)}
						</TextFieldTSX>
						{showUsaStates && (
							<TextFieldTSX
								disabled={!listLength(address.allStates)}
								InputLabelProps={{
									shrink: !!address.state
								}}
								select
								SelectProps={{
									MenuProps: {
										PopoverClasses: {
											root: 'dropdown'
										}
									},
									classes: {
										root: 'country-select-root state'
									}
								}}
								classes={{ root: 'address-input-root state' }}
								label={getString('SELECT_YOUR_STATE')}
								value={selectedState?.iC || EMPTY_VAL}
								onChange={(e: ChangeEvent<HTMLSelectElement>) => {
									const stateCode = getValue(e)
									const selectedState =
										fullState(stateCode, address.allStates, country?.iC) ||
										NULL_VAL
									onChangeStateDropdownValue(selectedState)
								}}
							>
								<MenuItem disabled>{USER_PROFILE_ADDRESS_PLACEHOLDER}</MenuItem>
								{statesToCountry?.map(
									({ n: stateName, iC: stateCode }: IState) => (
										<MenuItem value={stateCode} key={stateCode}>
											{stateName}
										</MenuItem>
									)
								)}
							</TextFieldTSX>
						)}
						<TextFieldTSX
							className="light-user-upload-project-alert-email zip-code"
							variant="standard"
							placeholder={getString('SEND_TO_SERVICE_BUREAU_ZIP')}
							onChange={onZipChange}
							data-qa="data-qa-light-user-zip"
						/>
					</div>
				)}

				{errorValidation && (
					<span className="light-user-upload-project-alert-error">
						{errorValidation}
					</span>
				)}

				{showTerms && (
					<Flexbox className="usage-agreement-check-box" alignItems="center">
						<CastorCheckbox
							checked={usageAgreement}
							onChange={onAgreementChange}
							inputProps={{
								'aria-label': 'secondary checkbox'
							}}
						/>
						<div>
							<span>{getString('TURMS_CHECKBOX_TEXT')} </span>

							<a onClick={onUsageAgreementClick}>
								{getString('TURMS_CHECKBOX_LINK_TEXT')}
							</a>
						</div>
					</Flexbox>
				)}
			</Flexbox>
		)
	}

	return (
		<CastorAlert
			alertClass={cx('light-alert', { 'modal-black': headerWithLogo })}
			loadingCalculation={loading}
			headerTitle={headerTitle}
			show={show}
			onConfirm={submitConfirm}
			showCancel={showCancel}
			onCancel={handleAlertCancel}
			cancelOptionalText={getString('CANCEL')}
			confirmOptionalText={
				showUpload ? getString('UPLOAD') : getString('SUBMIT')
			}
			onFooterCancel={handleAlertCancel}
			disabled={isSubmitDisabled}
		>
			{renderAlertBody()}
		</CastorAlert>
	)
}

export default memo(LightUserUploadProjectAlert)
