import { FC, memo } from 'react'

import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import DetailsPopup from '../DetailsPopup'
import IconFactory from '../StarIcon/IconFactory'

import './DataTable.scss'

interface Props {
	text: string | number
	data: any
	className: string
}

const renderPopUp = (
	iconClassName: string,
	iconName: string,
	isHover: boolean,
	description: string
) => {
	if (isEmpty(description)) return <></>

	const renderDescription = () => {
		if (description) {
			return (
				<div
					className="info-box-wrapper__content"
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			)
		}

		return <div />
	}

	return (
		<DetailsPopup
			inPortal
			isHover={isHover}
			data={renderDescription()}
			popperDirection={'bottom-start'}
			popperClassName={'info-box-wrapper'}
			popperContactClassName={'info-box-data'}
			targetClassName={'data-table-row-icon-part'}
		>
			<IconFactory
				iconName={iconName}
				className={`data-table-row-${iconClassName}`}
			/>
		</DetailsPopup>
	)
}

const renderTitle = (text: string | number) => {
	return (
		<div
			className="data-table-row-text"
			title={isNil(text) ? '' : text.toString()}
		>
			{text}
		</div>
	)
}

const renderContent = (
	iconClassName: string,
	iconName: string,
	text: string | number,
	isHover: boolean,
	description: string
) => {
	return (
		<>
			{renderTitle(text)}
			{renderPopUp(iconClassName, iconName, isHover, description)}
		</>
	)
}

const DataTableTextWithIconButton: FC<Props> = ({
	text,
	data: {
		onClick,
		iconName,
		iconClassName,
		iconOnTheRight,
		isHover,
		description,
		...restData
	},
	className
}) => (
	<div
		onClick={onClick}
		className={cx(
			'data-table-row data-table-opacity-field',
			className,
			restData.className,
			iconOnTheRight ? 'icon-right' : 'icon-left'
		)}
	>
		{renderContent(iconClassName, iconName, text, isHover, description)}
	</div>
)

export default memo(DataTableTextWithIconButton)
