import fileSaver from 'file-saver'

import {
	ADMIN_USER_DATEPICKER_TOGGLED,
	ADMIN_USER_FETCHED,
	ADMIN_USER_GENERATE_REPORT_LOADING,
	ADMIN_USER_INFO_UPDATE_SUCCESS,
	ADMIN_USER_INFO_UPDATE_TOGGLED,
	ADMIN_USER_SELECTED,
	ADMIN_USERS_DESKTOP_PRINTER_TOGLLED,
	ADMIN_USERS_SEARCH_PHRASE_CHANGED,
	GET_ADMIN_SORT_BY_CREATED_CLICKED,
	GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED,
	GET_ADMIN_USER_INFO,
	GET_ADMIN_USERS_CALLED,
	GET_ADMIN_USERS_GOT_ERROR,
	GET_ADMIN_USERS_SUCCESS,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	POPPER_MENU_CLICKED,
	SERVER_CALL_FAILED_ADMIN,
	USER_INFO_EDIT_SELECT
} from '../../../../global actions/types'
import {
	exportDataToXLSX,
	getAllUserMaterials,
	getAllUserPrinters,
	getUserAdmin,
	getUsersAdmin,
	removeUserFromSystem,
	toggleUserDesktopPrinters,
	updateUserInfoAdmin
} from '../../../../Services/Network'
import {
	PROJECT_ANALYSYS_ERROR_DOWNLOAD,
	SHOW_NOTIFICATION
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import {
	checkForUserInfoChanges,
	prepareInfoForRequest
} from './AdminUsersService'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import history from 'Services/history'
import { getString } from 'Services/Strings/StringService'

export const setupAdminUsersPage = (
	searchPhrase,
	pageNumber,
	limitUsers,
	sortBy,
	sortASC
) => {
	return async dispatch => {
		dispatch({
			type: GET_ADMIN_USERS_CALLED
		})
		try {
			const response = await getUsersAdmin(
				searchPhrase,
				pageNumber,
				limitUsers,
				sortBy,
				sortASC
			)
			dispatch({
				type: GET_ADMIN_USERS_SUCCESS,
				payload: { data: response.data, pageNumber }
			})
		} catch (error) {
			console.log(error)
			dispatch({ type: GET_ADMIN_USERS_GOT_ERROR })
		}
	}
}

export const onToggleDesktopPrinter = (e, desktopPrinter, userId) => {
	e.stopPropagation()
	return async dispatch => {
		try {
			dispatch({
				type: POPPER_MENU_CLICKED,
				payload: { menuName: `adminCustomize_${userId}`, menuStatus: true }
			})
			await toggleUserDesktopPrinters(!desktopPrinter, userId)
			dispatch({
				type: ADMIN_USERS_DESKTOP_PRINTER_TOGLLED,
				payload: { userId, desktopPrinter }
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const onSubscriptionHeadClick = () => {
	return {
		type: GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED
	}
}

export const onCreatedHeadClick = () => {
	return {
		type: GET_ADMIN_SORT_BY_CREATED_CLICKED
	}
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_USERS_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const onAdminUsersUnmounted = () => {
	return {
		type: ADMIN_USERS_SEARCH_PHRASE_CHANGED,
		payload: ''
	}
}

export const onUserInfoClick = user => {
	return async dispatch => {
		try {
			dispatch({ type: GET_ADMIN_USER_INFO, payload: true })
			const { userPrinters, userMaterials } = await getPrintersAndMaterials(
				user.id
			)
			dispatch({ type: GET_ADMIN_USER_INFO, payload: false })
			dispatch({
				type: ADMIN_USER_SELECTED,
				payload: { user, userPrinters, userMaterials }
			})
		} catch (error) {
			dispatch({ type: GET_ADMIN_USER_INFO, payload: false })
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || getString('ERROR_UPDATING_TAGS')
				}
			})
		}
	}
}
export const onUserInfoEditClick = userSelected => {
	return { type: USER_INFO_EDIT_SELECT, payload: { userSelected } }
}

export const onRemoveUserClick = userSelected => {
	return async dispatch => {
		try {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					alertType: AlertType.WARNING,
					text: getString('ARE_YOU_SURE_DELETE_USER'),
					headerTitle: getString('DELETE_USER'),
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
						onRemoveUserFromSystem(userSelected, dispatch)
					},
					confirmText: getString('OK')
				}
			})
		} catch (error) {
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || getString('USER_INFO_REMOVED_FAILED')
				}
			})
		}
	}
}

export const onUserUpdateSubmitClick = (userSelected, userInfo) => {
	const newUpdatedInfo = checkForUserInfoChanges(userSelected, userInfo)
	const preparedInfoForRequest = prepareInfoForRequest(newUpdatedInfo)

	return async dispatch => {
		try {
			dispatch({
				type: ADMIN_USER_INFO_UPDATE_TOGGLED,
				payload: true
			})
			await updateUserInfoAdmin(preparedInfoForRequest)
			dispatch({
				type: ADMIN_USER_INFO_UPDATE_SUCCESS
			})
			dispatch({
				type: ADMIN_USER_INFO_UPDATE_TOGGLED,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString('USER_INFO_UPDATED_SUCCESS')
				}
			})
		} catch (error) {
			console.error(error)
			dispatch({
				type: ADMIN_USER_INFO_UPDATE_TOGGLED,
				payload: false
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage || getString('ERROR_UPDATING_TAGS')
				}
			})
		}
	}
}
export const fetchUserInfo = id => {
	return async dispatch => {
		try {
			dispatch({ type: HANDLE_LOADER, payload: 1 })
			dispatch({ type: GET_ADMIN_USER_INFO, payload: true })
			const { userPrinters, userMaterials } = await getPrintersAndMaterials(id)
			const usersByUser = await getUserAdmin(id)
			const response = await getUserAdmin(id, usersByUser.data.totalUsers)

			const { users, roles } = response.data

			let user = {}
			if (users && users.length > 0) {
				user = users.find(u => u.id === +id)
			}
			dispatch({
				type: ADMIN_USER_FETCHED,
				payload: { user, userPrinters, userMaterials, roles }
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({ type: GET_ADMIN_USER_INFO, payload: false })
			return
		} catch (error) {
			console.error(error)
			dispatch({ type: GET_ADMIN_USER_INFO, payload: false })
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({ type: SERVER_CALL_FAILED_ADMIN })
		}
	}
}

const getPrintersAndMaterials = async id => {
	try {
		const userPrintersData = await getAllUserPrinters(id)
		const userMaterialsData = await getAllUserMaterials(id)
		const userPrinters = userPrintersData.data.printers
		const userMaterials = userMaterialsData.data.userMaterials
		return { userPrinters, userMaterials }
	} catch (err) {
		throw err
	}
}

export const datePickersToggled = show => {
	return {
		type: ADMIN_USER_DATEPICKER_TOGGLED,
		payload: show
	}
}

export const onReportLoading = isLoading => {
	return dispatch => {
		dispatch({
			type: ADMIN_USER_GENERATE_REPORT_LOADING,
			payload: isLoading
		})
	}
}

export const downloadReport = (startDate, endDate) => {
	return dispatch => {
		dispatch(onReportLoading(true))
		dispatch(exportUsersDataToExcel(startDate, endDate))

		dispatch({ type: ADMIN_USER_DATEPICKER_TOGGLED, payload: false })
	}
}

export const exportUsersDataToExcel = (startDate, endDate) => {
	return async dispatch => {
		try {
			const blob = await exportDataToXLSX(
				'exportUsersDataToExcel',
				startDate,
				endDate
			)

			if (blob) {
				fileSaver.saveAs(blob, getString('EXPORT_FILE_NAME'))
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.WARN,
						notificationMessage: getString('ADMIN_TABLE_NO_DATA_FOUND')
					}
				})
			}
			dispatch(onReportLoading(false))
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: PROJECT_ANALYSYS_ERROR_DOWNLOAD
				}
			})
		}
	}
}

const onRemoveUserFromSystem = async (userSelected, dispatch) => {
	await removeUserFromSystem(userSelected.id)
	dispatch({
		type: HANDLE_NOTIFICATION,
		payload: {
			notificationType: SHOW_NOTIFICATION.SUCCESS,
			notificationMessage: getString('USER_INFO_REMOVED_SUCCESS')
		}
	})
	history.push('/admin/users')
}
