import { cloneDeep, isEmpty } from 'lodash'

import { objectives } from './constants'
import { NewPartConfigurationState } from './NewPartConfigurationTypes'
import { Action } from 'global actions/ActionModels'
import {
	GOT_LOGIN_DATA,
	MATERIAL_CATEGORY_CHANGED,
	MATERIAL_CHANGED,
	MATERIAL_TYPE_CHANGED
} from 'global actions/types'
import {
	GET_NEW_CONFIGURATION_FIELDS,
	NEW_CONFIGURATION_CHANGE_MANUFACTURING,
	NEW_CONFIGURATION_CHANGE_OBJECTIVE,
	NEW_CONFIGURATION_CHANGE_PRINTER,
	NEW_CONFIGURATION_CHANGE_PROPERTY_NAME,
	NEW_CONFIGURATION_LOADED,
	NEW_CONFIGURATION_LOADING,
	NEW_CONFIGURATION_SAVE_MATERIAL,
	NEW_CONFIGURATION_SAVE_PROPERTIES
} from 'global actions/types/partAnalysisTypes'
import {
	configurationProperties,
	defaultConfigurationProperties,
	defaultPrinterConfiguration,
	defaultPropertiesOptions
} from 'Services/Constants'
import { Material } from 'Services/models/IMaterial'
import 'Services/Protos.d'
import { getTheme } from 'themes/getTheme'

const { defaultObjectiveValue } = getTheme()

const initialState: NewPartConfigurationState = {
	newConfigurationPage: true,
	loading: false,
	initialMaterial: {},
	partMaterial: {},
	materialTypesList: [],
	categoriesList: [],
	materialsList: [],
	printerList: [],
	printersFullData: [],
	configurationMethod: '',
	chosenMaterialType: '',
	chosenMaterialCategory: '',
	chosenPrinterConfigurationType: '',
	chosenPrinterConfiguration: '',
	chosenMaterial: {},
	chosenDefaultMaterial: {},
	chosenDefaultMaterialType: '',
	chosenDefaultMaterialCategory: '',
	allowedManufacturingMethods: [],
	manufacturingMethod: '',
	allPrinterList: [],
	inHouseList: [],
	sortedPrinters: [],
	printersConfiguration: {},
	defaultConfig: '',
	configurationProperties: configurationProperties,
	priorities: defaultConfigurationProperties,
	propertyName: defaultPropertiesOptions.basic,
	objectivesValue:
		objectives.find(objective => objective.value === defaultObjectiveValue)
			?.value || objectives[0].value
}

const filterPrintersByMaterialType = (printers: any, type: string) => {
	let printersData = cloneDeep(printers)
	printersData = printersData
		.map((printerData: any) => {
			printerData.printers = printerData.printers.filter((printer: any) => {
				return printer.materials.some(
					(material: Material) => material.type === type
				)
			})
			return printerData
		})
		.filter((printerData: any) => printerData.printers?.length)

	return printersData
}

const newPartConfigurationReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case GOT_LOGIN_DATA: {
			const {
				generalData: { printersFullData }
			} = payload

			return {
				...state,
				printersFullData
			}
		}

		case GET_NEW_CONFIGURATION_FIELDS: {
			const {
				materialTypesList,
				categoriesList,
				materialsList,
				manufacturingMethod,
				allowedManufacturingMethods,
				initialMaterial,
				chosenMaterialType,
				chosenMaterial,
				chosenMaterialCategory,
				inHouseList,
				allPrinterList,
				defaultConfigurations,
				partMaterial,
				configuration
			} = payload

			const defaultConfig = defaultConfigurations
				? defaultConfigurations[0]
				: {}

			let printersConfiguration: any = {
				bestMatch: {
					printers: defaultConfigurations || []
				}
			}

			const eligiblePrinters = filterPrintersByMaterialType(
				allPrinterList,
				chosenMaterial?.type
			)

			if (!isEmpty(allPrinterList)) {
				printersConfiguration = {
					...printersConfiguration,
					eligible: {
						printers: eligiblePrinters.length > 1 ? eligiblePrinters : []
					}
				}
			}

			if (!isEmpty(inHouseList)) {
				printersConfiguration = {
					...printersConfiguration,
					inHouse: {
						printers: [...inHouseList]
					}
				}
			}

			return {
				...state,
				configuration,
				initialMaterial,
				chosenMaterial: chosenMaterial,
				chosenMaterialType: chosenMaterialType,
				chosenMaterialCategory: chosenMaterialCategory,
				chosenDefaultMaterialType: chosenMaterialType,
				chosenDefaultMaterialCategory: chosenMaterialCategory,
				chosenDefaultMaterial: chosenMaterial,
				allowedManufacturingMethods: allowedManufacturingMethods,
				manufacturingMethod: manufacturingMethod,
				materialTypesList,
				categoriesList,
				materialsList,
				chosenPrinterConfigurationType: defaultPrinterConfiguration.type,
				chosenPrinterConfiguration: defaultConfig,
				defaultConfig: defaultConfig,
				partMaterial: partMaterial,
				allPrinterList,
				inHouseList,
				printersConfiguration
			}
		}

		case MATERIAL_CHANGED:
		case MATERIAL_CATEGORY_CHANGED:
		case MATERIAL_TYPE_CHANGED: {
			const {
				materialTypesList,
				categoriesList,
				materialsList,
				chosenMaterialCategory,
				chosenMaterialType,
				chosenMaterial,
				allowedManufacturingMethods,
				manufacturingMethod
			} = payload

			const {
				printersConfiguration,
				allPrinterList,
				chosenDefaultMaterialType,
				chosenPrinterConfigurationType,
				defaultConfig
			} = state

			let changedSelectedPrinter = {}

			if (
				chosenDefaultMaterialType !== chosenMaterialType &&
				chosenPrinterConfigurationType === defaultPrinterConfiguration.eligible
			) {
				changedSelectedPrinter = {
					chosenPrinterConfigurationType: defaultPrinterConfiguration.type,
					chosenPrinterConfiguration: defaultConfig
				}
			}

      const eligiblePrinters = filterPrintersByMaterialType(
        allPrinterList,
        chosenMaterial?.type
      )

      return Object.assign(
        {
          ...state,
          chosenDefaultMaterial: chosenMaterial,
          chosenDefaultMaterialType: chosenMaterialType,
          chosenDefaultMaterialCategory: chosenMaterialCategory,
          allowedManufacturingMethods: allowedManufacturingMethods,
          manufacturingMethod: manufacturingMethod,
          printersConfiguration: {
            ...printersConfiguration,
            eligible: {
              printers: eligiblePrinters.length > 1 ? eligiblePrinters : []
            }
          },
          materialTypesList,
          categoriesList,
          materialsList
        },
        changedSelectedPrinter
      )
    }

		case NEW_CONFIGURATION_CHANGE_PRINTER: {
			const { printerConfiguration, key } = payload

			return {
				...state,
				chosenPrinterConfigurationType: key,
				chosenPrinterConfiguration: printerConfiguration
			}
		}

		case NEW_CONFIGURATION_SAVE_MATERIAL: {
			const {
				chosenDefaultMaterialType,
				chosenDefaultMaterialCategory,
				chosenDefaultMaterial,
				allowedManufacturingMethods,
				manufacturingMethod
			} = state

			return {
				...state,
				chosenMaterial: chosenDefaultMaterial,
				chosenMaterialType: chosenDefaultMaterialType,
				chosenMaterialCategory: chosenDefaultMaterialCategory,
				allowedManufacturingMethods: allowedManufacturingMethods,
				manufacturingMethod: manufacturingMethod
			}
		}

		case NEW_CONFIGURATION_CHANGE_MANUFACTURING: {
			const { manufacturingMethod } = payload

			return {
				...state,
				manufacturingMethod: manufacturingMethod
			}
		}

		case NEW_CONFIGURATION_SAVE_PROPERTIES: {
			const { configurationProperties } = payload

			let newPriorities = {}

			configurationProperties.forEach((conf: any) => {
				if (!conf.active && conf.withCheckbox) {
					return
				} else {
					newPriorities = {
						...newPriorities,
						[conf.id]: conf.value
					}
				}
			})

			return {
				...state,
				configurationMethod: configurationProperties,
				priorities: newPriorities,
				chosenPrinterConfigurationType: defaultPrinterConfiguration.custom,
				chosenPrinterConfiguration: defaultPrinterConfiguration.configuration
			}
		}

		case NEW_CONFIGURATION_LOADING:
			return {
				...state,
				loading: true
			}

		case NEW_CONFIGURATION_LOADED:
			return {
				...state,
				loading: false
			}

		case NEW_CONFIGURATION_CHANGE_PROPERTY_NAME: {
			const { propertyName } = state

			return {
				...state,
				propertyName: propertyName
			}
		}

		case NEW_CONFIGURATION_CHANGE_OBJECTIVE: {
			return {
				...state,
				objectivesValue: payload.objectivesValue
			}
		}

		default:
			return {
				...state
			}
	}
}

export default newPartConfigurationReducer
