import React, { FC, memo } from 'react'

import cx from 'classnames'

import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './PartProperties.scss'
import 'animate.css'

interface IProps {
	onClick: Function
	isPrimary?: boolean
	isTransparent?: boolean
}

const PartPropertiesButton: FC<IProps> = ({
	onClick,
	isPrimary,
	isTransparent
}) => {
	const disableDrawingAnalysis = !Feature.isFeatureActive(
		FeatureComponentId.DRAWING_ANALYSIS
	)

	return (
		<DetailsPopup
			isHover={disableDrawingAnalysis}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="bottom"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
		>
			<div className={'parts-properties-button middle'}>
				<Button
					disabled={disableDrawingAnalysis}
					className={cx({
						'border-button': !isPrimary,
						transparent: isTransparent
					})}
					color={isPrimary ? 'primary' : 'transparent'}
					onClick={() => onClick()}
				>
					{getString('PARTS_PROPERTIES')}
				</Button>
			</div>
		</DetailsPopup>
	)
}

export default WithFeatureToggleHOC(
	memo(PartPropertiesButton),
	FeatureComponentId.DRAWING_ANALYSIS
)
