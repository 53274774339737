import React, { Component, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { Link, RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as AdminPrintingTechnologyActions from './AdminPrintingTechnologyActions'
import { getString } from '../../../../Services/Strings/StringService'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import Loader from '../../../Loader/Loader'
import AdminPrinterTechFrom from './AdminPrinterTechFrom'
import { AdminPrintingTechnologyInitialState } from './AdminPrintingTechnologyReducer'

interface IProps
	extends AdminPrintingTechnologyInitialState,
		RouteComponentProps<MatchParams> {
	setupAdminPrintingTechnologyPage: Function
	selectPrinterTechnologyToEdit: Function
	updatePrinterTechnologyParameters: Function
	setSearchPhrase: Function
}

interface IStoreProps {
	AdminPrintingTechnologyReducer: AdminPrintingTechnologyInitialState
}
interface MatchParams {
	printerTechName: string
}

const EditTechParameters: FC<IProps> = ({
	loading,
	printersTechParametersData,
	selectedEditPrinterTechnology,
	setupAdminPrintingTechnologyPage,
	selectPrinterTechnologyToEdit,
	updatePrinterTechnologyParameters,
	match: {
		params: { printerTechName }
	}
}) => {
	useEffect(() => {
		if (!printersTechParametersData || !printersTechParametersData.length) {
			setupAdminPrintingTechnologyPage()
		} else {
			selectPrinterTechnologyToEdit(printerTechName, printersTechParametersData)
		}
	}, [printersTechParametersData])
	if (loading) {
		return <Loader load={loading} message="" />
	}

	const renderFormContent = (selectedEditPrinterTechnology: any) => {
		return (
			<AdminPrinterTechFrom
				onSubmitClick={(values: any) => {
					updatePrinterTechnologyParameters(values)
				}}
				initialValues={selectedEditPrinterTechnology}
			/>
		)
	}

	return (
		<NavBarAndMaterial title={''}>
			<CastorForm
				formTitle={getString('ADMIN_PRINTER_TECHNOLOGY_EDIT_HEADER')}
				content={renderFormContent(selectedEditPrinterTechnology)}
			/>
		</NavBarAndMaterial>
	)
}

function mapStateToProps({ AdminPrintingTechnologyReducer }: IStoreProps) {
	return {
		...AdminPrintingTechnologyReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminPrintingTechnologyActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(EditTechParameters))
