import React from 'react'

import { Feature } from '../../../Services/models/Features'
import LedWithTitle from '../LedWithTitle'

const CastorPartResultIndex = props => {
	const {
		indexes,
		materialType,
		className,
		onlyColors,
		currentTitle,
		circleSize
	} = props
	return (
		<div className={className}>
			{getLedWithTitleArr(
				indexes,
				materialType,
				onlyColors,
				currentTitle,
				circleSize
			)}
		</div>
	)
}

const shouldShowIndex = (index, materialType) => {
	if (index?.featureId && !Feature.isFeatureOn(index?.featureId)) {
		return false
	}
	if (index?.materialType) {
		if (!materialType) {
			return false
		} else if (index?.materialType !== materialType) {
			return false
		}
	}
	return true
}

const getLedWithTitleArr = (
	indexes,
	materialType,
	onlyColors,
	currentTitle,
	circleSize
) => {
	return indexes.map(index => {
		if (!shouldShowIndex(index, materialType)) {
			return <div key={index.result} />
		}
		return (
			<LedWithTitle
				key={index.result + index.title}
				result={index.result}
				title={index.title}
				color={index.color}
				gradientStartColor={index.gradientStartColor}
				onlyColors={onlyColors}
				bolded={currentTitle === index.result}
				circleSize={circleSize}
			/>
		)
	})
}
export default CastorPartResultIndex
