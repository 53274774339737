import IconFactory from '../StarIcon/IconFactory'
import { benefitType } from '../../../Services/models/ISolutionBenefit'
import DetailsPopup from '../DetailsPopup'
import { sortBenefits } from './CastorPartBenefitService'
import { generateKey } from '../../../Services/Utils/uniqKeyGenerator'

import './CastorPartBenefits.scss'

export const CastorPartBenefits = props => {
  const {
    benefits = [],
    small,
    style,
    className,
    expandIconOnHover,
    iconClassName = '',
    showWeightBenefit = true,
    showHoverData = true,
    showFullIcon = false,
  } = props
  return (
    <div style={style} className={className}>
      {
        sortBenefits(benefits).map((benefit, index) => {
        const shouldShowFull = showFullIcon && benefit.type === benefitType.buyToFly
        
        if (
          !showWeightBenefit &&
          benefit.type === benefitType.weightReduction
        ) {
          return <div></div>
        }
        if (!showHoverData) {
          return (
            <span key={generateKey(index)} className="benefit-icon-wrapper">
              <IconFactory
                expandIconOnHover={expandIconOnHover}
                iconName={benefit.type}
                small={small && !shouldShowFull}
                smallClassName={`castor-icon-small ${iconClassName}`}
                className={`castor-icon ${iconClassName}`}
                score={benefit.traditionalCost}
              />
            </span>
          )
        }
        return (
          <span key={generateKey(index)} className="benefit-icon-wrapper">
            <DetailsPopup
              isHover={true}
              data={benefit.name}
              popperDirection="top-end"
              popperClassName="details-popup-data-benefits-message"
              popperContactClassName="details-popup-data-benefits"
            >
              <IconFactory
                expandIconOnHover={expandIconOnHover}
                iconName={benefit.type}
                small={small}
                smallClassName={`castor-icon-small ${iconClassName}`}
                className={`castor-icon ${iconClassName}`}
              />
            </DetailsPopup>
          </span>
        )
      })}
    </div>
  )
}
