import {
	ADMIN_USER_DATEPICKER_TOGGLED,
	ADMIN_USER_FETCHED,
	ADMIN_USER_GENERATE_REPORT_LOADING,
	ADMIN_USER_INFO_UPDATE_SUCCESS,
	ADMIN_USER_INFO_UPDATE_TOGGLED,
	ADMIN_USER_SELECTED,
	ADMIN_USERS_DESKTOP_PRINTER_TOGLLED,
	ADMIN_USERS_SEARCH_PHRASE_CHANGED,
	GET_ADMIN_SORT_BY_CREATED_CLICKED,
	GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED,
	GET_ADMIN_USER_INFO,
	GET_ADMIN_USERS_CALLED,
	GET_ADMIN_USERS_GOT_ERROR,
	GET_ADMIN_USERS_SUCCESS,
	USER_INFO_EDIT_SELECT
} from '../../../../global actions/types'
import { DataTableService } from '../../../Components/DataTable/DataTableService'
import { DataTableFieldType } from '../../../Components/DataTable/IDataTableField'
import { makeRolesAsArray } from './AdminUsersService'

import '../adminHome.scss'

const dataTableService = new DataTableService()

const INITIAL_STATE = {
	users: [],
	totalUsers: 0,
	usersTableHead: [],
	usersTableData: [],
	showNoUsersAlert: false,
	pageNumber: 1,
	isLastPage: false,
	NumOfUsersSeen: 0,
	showPagination: false,
	limitUsers: 50,
	showAdminUsersAlert: false,
	sortBy: '',
	sortASC: false,
	searchPhrase: '',
	userUpdateLoading: false,
	userSelected: null,
	loading: false,
	userSubscriptionInfoData: [],
	userPersonalInfoData: [],
	userProjectAndPartInfoData: [],
	userPrintersData: [],
	userMaterialsData: [],
	userPrintersHeaders: [],
	userMaterialsHeaders: [],
	usersRoles: [],
	showExportExcel: true,
	isAdminUserReportLoading: false
}

const AdminUsersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_ADMIN_USERS_CALLED:
			return { ...state, loading: true }
		case GET_ADMIN_USERS_SUCCESS:
			const { data, pageNumber } = action.payload,
				{ users, totalUsers, roles } = data,
				NumOfUsersSeen = (pageNumber - 1) * state.limitUsers + users.length,
				isLastPage = NumOfUsersSeen >= totalUsers
			return {
				...state,
				users,
				totalUsers,
				usersTableData: users.map(user => Object.values(user)),
				showNoUsersAlert: !users.length,
				pageNumber,
				NumOfUsersSeen,
				isLastPage,
				showPagination: !(isLastPage && pageNumber === 1),
				showAdminUsersAlert: false,
				loading: false,
				usersRoles: roles
			}
		case GET_ADMIN_USERS_GOT_ERROR:
			return {
				...state,
				showAdminUsersAlert: true,
				loading: false
			}
		case GET_ADMIN_SORT_BY_SUBSCRIPTION_CLICKED:
			return { ...state, sortBy: 'subscription', sortASC: !state.sortASC }
		case ADMIN_USER_DATEPICKER_TOGGLED:
			return {
				...state,
				showDatepicker: action.payload
			}
		case GET_ADMIN_SORT_BY_CREATED_CLICKED:
			return { ...state, sortBy: 'created', sortASC: !state.sortASC }
		case ADMIN_USERS_DESKTOP_PRINTER_TOGLLED: {
			const { userId, desktopPrinter } = action.payload
			const users = state.users.map(user => {
				if (user.id === userId) {
					user.desktopPrinter = !desktopPrinter
				}
				return user
			})
			return {
				...state,
				users
			}
		}
		case ADMIN_USERS_SEARCH_PHRASE_CHANGED:
			const searchPhrase = action.payload
			return {
				...state,
				searchPhrase,
				showExportExcel: !searchPhrase
			}
		case USER_INFO_EDIT_SELECT:
			const { userSelected } = action.payload
			return {
				...state,
				userSelected
			}
		case ADMIN_USER_SELECTED:
			const { user, userPrinters, userMaterials } = action.payload
			const userRoles = makeRolesAsArray(user.roles)
			const [headersPrinters, dataPrinters] =
				createUserDataPrinters(userPrinters)
			return {
				...state,
				loading: false,
				userSelected: {
					id: user.id,
					expireDate: user['expire Date'],
					partsCredit: user['parts credit'],
					trial: user.trial,
					verified: user.verified,
					roles: userRoles
				},
				userSubscriptionInfoData: createUserSubscriptionInfo(user),
				userPersonalInfoData: createUserDataPersonalInfo(user),
				userProjectAndPartInfoData: createUserDataProjectAndParts(user),
				userPrintersData: createUserDataPrinters(userPrinters),
				userPrintersHeaders: generateTableHeaders(['Id', 'Name']),
				userMaterialsHeaders: generateTableHeaders(['Id', 'Name']),
				userMaterialsData: createUserDataMaterials(userMaterials)
			}
		case ADMIN_USER_INFO_UPDATE_TOGGLED:
			return { ...state, userUpdateLoading: action.payload }

		case ADMIN_USER_INFO_UPDATE_SUCCESS:
			return {
				...state,
				userUpdateLoading: false,
				userSelected: null
			}
		case GET_ADMIN_USER_INFO:
			return { ...state, loading: action.payload }
		case ADMIN_USER_FETCHED: {
			const { user, userPrinters, userMaterials, roles } = action.payload
			const userRoles = makeRolesAsArray(user.roles)
			const [headersPrinters, dataPrinters] =
				createUserDataPrinters(userPrinters)
			return {
				...state,
				userSelected: {
					id: user.id,
					expireDate: user['expire Date'],
					partsCredit: user['parts credit'],
					trial: user.trial,
					verified: user.verified,
					roles: userRoles
				},
				userSubscriptionInfoData: createUserSubscriptionInfo(user),
				userPersonalInfoData: createUserDataPersonalInfo(user),
				userProjectAndPartInfoData: createUserDataProjectAndParts(user),
				userPrintersData: dataPrinters,
				userMaterialsData: createUserDataMaterials(userMaterials),
				userPrintersHeaders: generateTableHeaders(['Id', 'Name']),
				userMaterialsHeaders: generateTableHeaders(['Id', 'Name']),
				loading: false,
				usersRoles: roles
			}
		}

		case ADMIN_USER_GENERATE_REPORT_LOADING: {
			return {
				...state,
				isAdminUserReportLoading: action.payload
			}
		}
		default:
			return state
	}
}
const updateUserInfo = (user, userInfoUpdated) => {
	const updateTrial = {
		trial:
			userInfoUpdated.trial !== null && userInfoUpdated.trial !== undefined
				? userInfoUpdated.trial
				: null
	}
	const updateVerifiedStatus = {
		verified: userInfoUpdated.verified || false
	}
	const updateAmountOfRemainParts = {
		'remaining parts credit': userInfoUpdated.amount_of_remain_parts || null
	}
	const updatePartsBundle = {
		'parts credit': userInfoUpdated.parts_bundle || null
	}
	const updateSubscriptionExpire_date = {
		'expire Date': userInfoUpdated.subscription_expire_date || null
	}
	const updatedFieldsValues = [
		updateTrial,
		updateAmountOfRemainParts,
		updatePartsBundle,
		updateSubscriptionExpire_date,
		updateVerifiedStatus
	]
	for (let fieldObj of updatedFieldsValues) {
		const [key, value] = Object.entries(fieldObj)[0]
		user[key] = value
	}
	return user
}

const createUserDataProjectAndParts = userData => {
	return generateTableFieldsRows(tableRowData('projectAndParts', userData))
}
const createUserDataPersonalInfo = userData => {
	return generateTableFieldsRows(tableRowData('personalInfo', userData))
}
const createUserSubscriptionInfo = user => {
	return generateTableFieldsRows(tableRowData('subscriptionInfo', user))
}
const createUserDataPrinters = user => {
	const header = true
	const headersNames = ['Printer Name', 'Printer Id']
	const headers =
		headersNames.length &&
		headersNames.map(name => {
			return dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: name
			})
		})
	return [
		headers,
		generateTableFieldsRows(tableRowData('printersInfo', user), header)
	]
}
const createUserDataMaterials = user => {
	return generateTableFieldsRows(tableRowData('materialsInfo', user))
}
const generateTableHeaders = headersText => {
	return (
		headersText.length &&
		headersText.map(text => {
			return dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: text
			})
		})
	)
}
const generateTableFieldsRows = (rowsValues, header = false) => {
	if (!rowsValues || !rowsValues.length) {
		return []
	}

	return rowsValues.map(texts => {
		return [
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Text,
				text: texts.headerFieldText,
				className: 'admin--projects--project-info--field--title'
			}),
			dataTableService.RenderFieldObject({
				type: DataTableFieldType.Header,
				text: texts.valueFieldText,
				className: `admin--projects--project-info--field1`
			})
		]
	})
}

const getFieldData = (dataObj, fieldName, typeText = false) => {
	if (!dataObj[fieldName] && typeof dataObj[fieldName] !== 'number') {
		return typeText ? '' : '0'
	}
	if (
		dataObj[fieldName] !== undefined &&
		dataObj[fieldName] !== null &&
		typeof dataObj[fieldName] === 'number'
	) {
		return dataObj[fieldName].toString()
	}
	if (dataObj[fieldName] && typeof dataObj[fieldName] === 'string') {
		return dataObj[fieldName]
	}
}
const tableRowData = (type, userData) => {
	switch (type) {
		case 'projectAndParts':
			return [
				{
					headerFieldText: 'added customization',
					valueFieldText: getFieldData(userData, 'added customization')
				},
				{
					headerFieldText: 'total parts scanned',
					valueFieldText: getFieldData(userData, 'total parts scanned')
				},
				{
					headerFieldText: 'total parts charged',
					valueFieldText: getFieldData(userData, 'total parts charged')
				},
				{
					headerFieldText: 'Total deleted parts',
					valueFieldText: getFieldData(userData, 'amount of deleted parts')
				},
				{
					headerFieldText: 'Total project uploaded',
					valueFieldText: getFieldData(userData, 'total project uploaded')
				}
			]
		case 'personalInfo':
			return [
				{ headerFieldText: 'User Name', valueFieldText: userData.NAME },
				{
					headerFieldText: 'Company Name',
					valueFieldText: getFieldData(userData, 'company', true)
				},
				{
					headerFieldText: 'User Date Created',
					valueFieldText: getFieldData(userData, 'created', true)
				},
				{
					headerFieldText: 'User Email',
					valueFieldText: getFieldData(userData, 'email', true)
				},
				{
					headerFieldText: 'City',
					valueFieldText: getFieldData(userData, 'city', true)
				},
				{
					headerFieldText: 'State',
					valueFieldText: getFieldData(userData, 'state', true)
				},
				{
					headerFieldText: 'Country',
					valueFieldText: getFieldData(userData, 'country', true)
				},
				{
					headerFieldText: 'Zip code',
					valueFieldText: getFieldData(userData, 'zip_code', true)
				},
				{
					headerFieldText: 'Lat',
					valueFieldText: getFieldData(userData, 'lat')
				},
				{
					headerFieldText: 'Long',
					valueFieldText: getFieldData(userData, 'long')
				},
				{
					headerFieldText: 'Currency',
					valueFieldText: getFieldData(userData, 'currency', true)
				},
				{
					headerFieldText: 'Locale (language)',
					valueFieldText: getFieldData(userData, 'locale', true)
				}
			]
		case 'subscriptionInfo':
			return [
				{
					headerFieldText: 'User On Trial',
					valueFieldText: userData['trial'] ? 'yes' : 'no'
				},
				{
					headerFieldText: 'User Is Verified',
					valueFieldText: userData['verified'] ? 'yes' : 'no'
				},
				// {
				//   headerFieldText: 'Days Credit ',
				//   valueFieldText: getFieldData(userData, 'amount of
				// subscription_days') },
				{
					headerFieldText: 'Subscription Date',
					valueFieldText: getFieldData(userData, 'subscription Date')
				},
				{
					headerFieldText: 'Expire Date',
					valueFieldText: new Date(userData['expire Date']).toDateString()
				},
				{
					headerFieldText: 'Parts Credit',
					valueFieldText: getFieldData(userData, 'parts credit')
				},
				{
					headerFieldText: 'Uploaded Files (All Time)',
					valueFieldText: getFieldData(userData, 'all time uploaded files')
				},
				{
					headerFieldText: 'Mesh healed + Downloaded',
					valueFieldText: getFieldData(userData, 'partDownloadCount')
				},
				{
					headerFieldText: 'Remaining Parts Credit',
					valueFieldText: getFieldData(userData, 'remaining parts credit')
				},
				{
					headerFieldText: 'Locked Parts ',
					valueFieldText: getFieldData(userData, 'amount of locked parts')
				}
			]
		case 'materialsInfo':
			return userData.map(data => {
				return {
					headerFieldText: getFieldData(data, 'id'),
					valueFieldText: getFieldData(data, 'name')
				}
			})

		case 'printersInfo':
			return userData.map(data => {
				return {
					headerFieldText: getFieldData(data, 'id'),
					valueFieldText: getFieldData(data, 'name')
				}
			})

		default:
			return []
	}
}

export default AdminUsersReducer
