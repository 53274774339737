import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import TransparentButton from '../TransparentButton'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'

interface IProps {
	isOnPrem: boolean
	showImage: boolean
}

const BomTemplate: FC<IProps> = ({ isOnPrem, showImage }) => {
	const { defaultBomFilePath } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const link = isOnPrem
		? `${process.env.PUBLIC_URL}/materials/CASTOR_BOM_Template.xlsx`
		: defaultBomFilePath

	return (
		<TransparentButton
			isAnchor={true}
			href={link}
			data-qa="data-qa-download-bom-file"
			download
		>
			{showImage ? <DownloadBom /> : <></>}
			<span>{getString('DOWNLOAD_BOM_TEMPLATE')}</span>
		</TransparentButton>
	)
}

export default WithFeatureToggleHOC(
	memo(BomTemplate),
	FeatureComponentId.BOM_TEMPLATE
)
