import {
	USER_DESKTOP_MATERIAL_TOGGLED,
	COST_AND_LEAD_TBL_DATA_ARRANGED
} from '../../../global actions/types'

const INITIAL_STATE = {
	desktopPrinter: false,
	isShowSemiProfessionalPrintersToggle: false
}

const customizeReducer = (state = INITIAL_STATE, { type, payload }) => {
	switch (type) {
		case USER_DESKTOP_MATERIAL_TOGGLED:
			return {
				...state,
				desktopPrinter: !state.desktopPrinter
			}
		case COST_AND_LEAD_TBL_DATA_ARRANGED:
			const {
				originalUserSettingsValues: { desktopPrinter },
				isShowSemiProfessionalPrintersToggle
			} = payload
			return {
				...state,
				desktopPrinter,
				isShowSemiProfessionalPrintersToggle
			}
		default:
			return state
	}
}

export default customizeReducer
