import {
  PART_ADMIN_SERVER_CALL_FAILED,
  PART_ADMIN_DELETE_PARTS,
  PART_ADMIN_DELETE_PARTS_SUCCESS,
  PART_ADMIN_MERGE_PARTS,
  PART_ADMIN_MERGE_PARTS_SUCCESS,
  PART_ADMIN_SELECTED_ACTION,
  PART_ADMIN_GET_PARTS,
  PART_ADMIN_GET_PARTS_SUCCESS,
  PART_ADMIN_CHECKBOX_SELECTED,
  PART_ADMIN_DOWNLOAD_STL_SUCCESS,
  PART_ADMIN_DOWNLOAD_STL_COMPLETE,
  ADMIN_PROJECT_FETCHED,
  PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED,
  PART_ADMIN_ALL_CLUSTER_PUBLISHED,
  PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
  PART_ADMIN_CLUSTER_PUBLISHED,
  PART_ADMIN_CLUSTER_REJECTED,
  PART_ADMIN_CLUSTER_REMOVED,
  PART_ADMIN_REJECT_CLUSTER_TOGGLED,
  PART_ADMIN_UPDATE_CLUSTER_TOGGLED,
  HANDLE_NOTIFICATION,
  PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
  PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED
} from '../../../../global actions/types'
import {
  adminCombineParts,
  adminDeleteParts,
  machinePartsForProjectAdmin
} from '../../../../Services/Network'
import {
  publishClusters,
  publishCluster,
  rejectCluster,
  removeClusterParts,
  removeCluster
} from '../../../../Services/Network/Admin/ProjectNetwork'
import {
  SHOW_NOTIFICATION,
  CONFIGURATION_CALCULATION_ERROR,
  COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
  COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
  CLUSTER_STRUCTURE,
  OK
} from '../../../../Services/Strings'
import { actionsENUM } from './actionsEnum'
import {
  ALERT_POPPED,
  ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { AlertType } from '../../../Components/alerts/AlertTypes'
import { getString } from '../../../../Services/Strings/StringService'
import { Feature, FeatureComponentId } from "../../../../Services/models/Features";

export const setupAdminPartsPage = projectId => {
  return async dispatch => {
    try {
      dispatch({ type: PART_ADMIN_GET_PARTS })
      const response = await machinePartsForProjectAdmin(projectId)
      const { parts, clusters, clusterStructure, project } = response.data
      const displayComplex = Feature.isFeatureOn(FeatureComponentId.PART_COMPLEXITY_SCORE)

      dispatch({
        type: PART_ADMIN_GET_PARTS_SUCCESS,
        payload: { parts, clusters, clusterStructure, project, displayComplex }
      })
      dispatch({
        type: ADMIN_PROJECT_FETCHED,
        payload: response.data
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: PART_ADMIN_SERVER_CALL_FAILED,
        payload: error.message
      })
    }
  }
}
export const selectAction = actionId => {
  return { type: PART_ADMIN_SELECTED_ACTION, payload: actionId }
}
export const submittedAction = (actionId, projectId, parts) => {
  return dispatch => {
    let serverCallFunc
    let action
    let completeAction
    switch (actionId) {
      case actionsENUM.DELETE_PARTS:
        serverCallFunc = adminDeleteParts
        action = PART_ADMIN_DELETE_PARTS
        completeAction = PART_ADMIN_DELETE_PARTS_SUCCESS
        break
      case actionsENUM.COMBINE_PARTS:
        serverCallFunc = adminCombineParts
        action = PART_ADMIN_MERGE_PARTS
        completeAction = PART_ADMIN_MERGE_PARTS_SUCCESS
        break
      default:
        return
    }

    serverCallFunc(projectId, parts)
      .then(() => {
        return machinePartsForProjectAdmin(projectId)
      })
      .then(response => {
        dispatch({ type: completeAction, payload: response.data.parts })
      })
      .catch(error => {
        console.log(error)
        dispatch({
          type: PART_ADMIN_SERVER_CALL_FAILED,
          payload: parts.length ? error.message
            : getString('SELECT_PARTS_ERROR_MESSAGE')
        })
      })
    dispatch({ type: action })
  }
}
export const downloadSTL = part => ({
  type: PART_ADMIN_DOWNLOAD_STL_SUCCESS,
  payload: part.fileURL
})

export const downloadedSTLComplete = () => {
  return { type: PART_ADMIN_DOWNLOAD_STL_COMPLETE }
}
export const checkboxSelected = part => {
  return { type: PART_ADMIN_CHECKBOX_SELECTED, payload: part }
}

export const publishAllClusters = projectId => {
  return async dispatch => {
    try {
      dispatch({ type: PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED })
      const response = await publishClusters(projectId)
      const { clusters, projectClusterStatus } = response.data
      dispatch({
        type: PART_ADMIN_ALL_CLUSTER_PUBLISHED,
        payload: { clusters }
      })
      dispatch({
        type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
        payload: { projectClusterStatus }
      })
    } catch (error) {
      console.error(error.message)
      dispatch({ type: PART_ADMIN_PUBLISH_ALL_CLUSTER_TOGGLED })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: CONFIGURATION_CALCULATION_ERROR
        }
      })
    }
  }
}

export const onPublishClusterClick = clusterId => {
  return async dispatch => {
    try {
      dispatch({
        type: PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
        payload: { clusterId }
      })
      const response = await publishCluster(clusterId)
      const { clusters, projectClusterStatus } = response.data
      dispatch({
        type: PART_ADMIN_CLUSTER_PUBLISHED,
        payload: { clusters, clusterId }
      })
      dispatch({
        type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
        payload: { projectClusterStatus }
      })
    } catch (error) {
      console.error(error.message)
      dispatch({
        type: PART_ADMIN_PUBLISH_CLUSTER_TOGGLED,
        payload: { clusterId }
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: CONFIGURATION_CALCULATION_ERROR
        }
      })
    }
  }
}

export const onRejectClusterClick = clusterId => {
  return async dispatch => {
    try {
      dispatch({
        type: PART_ADMIN_REJECT_CLUSTER_TOGGLED,
        payload: { clusterId }
      })
      const response = await rejectCluster(clusterId)
      const { clusters, projectClusterStatus } = response.data
      dispatch({
        type: PART_ADMIN_CLUSTER_REJECTED,
        payload: { clusters, clusterId }
      })
      dispatch({
        type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
        payload: { projectClusterStatus }
      })
    } catch (error) {
      console.error(error.message)
      dispatch({
        type: PART_ADMIN_REJECT_CLUSTER_TOGGLED,
        payload: { clusterId }
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: CONFIGURATION_CALCULATION_ERROR
        }
      })
    }
  }
}

export const onRemoveClusterClickApprove = async (clusterId, dispatch) => {
  try {
    dispatch({
      type: PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
      payload: { clusterId }
    })
    const response = await removeCluster(clusterId)
    const { clusters, projectClusterStatus } = response.data
    dispatch({
      type: PART_ADMIN_CLUSTER_REMOVED,
      payload: { clusters, clusterId }
    })
    dispatch({
      type: PART_ADMIN_PROJECT_CLUSTER_STATUS_CHANGED,
      payload: { projectClusterStatus }
    })
  } catch (error) {
    console.error(error.message)
    dispatch({
      type: PART_ADMIN_REMOVE_CLUSTER_TOGGLED,
      payload: { clusterId }
    })
    dispatch({
      type: HANDLE_NOTIFICATION,
      payload: {
        notificationType: SHOW_NOTIFICATION.ERROR,
        notificationMessage: CONFIGURATION_CALCULATION_ERROR
      }
    })
  }
}

export const onRemoveClusterClick = clusterId => {
  return async dispatch => {
    dispatch({
      type: ALERT_POPPED,
      payload: {
        text: getString('ARE_YOU_SURE_DELETE'),
        headerTitle: getString('DELETE_ITEM'),
        // showCancel: false,
        onConfirm: () => {
          dispatch({
            type: ALERT_POPUP_CANCELED
          })
          onRemoveClusterClickApprove(clusterId, dispatch)
        },
        confirmText: OK
      }
    })
  }
}

export const onUpdateClusterClick = (clusterId, transformationMatrixHashs) => {
  return async dispatch => {
    try {
      dispatch({
        type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
      })
      await removeClusterParts(clusterId, transformationMatrixHashs)
      dispatch({
        type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
      })
      dispatch({
        type: ALERT_POPPED,
        payload: {
          text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
          headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
          showCancel: false,
          alertType: AlertType.SUCCESS,
          onConfirm: () => {
            dispatch({
              type: ALERT_POPUP_CANCELED
            })
          },
          confirmText: OK
        }
      })
    } catch (error) {
      console.error(error.message)
      dispatch({
        type: PART_ADMIN_UPDATE_CLUSTER_TOGGLED
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: CONFIGURATION_CALCULATION_ERROR
        }
      })
    }
  }
}

export const onClusterStructureClick = clusterStructure => {
  return dispatch => {
    dispatch({
      type: ALERT_POPPED,
      payload: {
        text: clusterStructure,
        headerTitle: CLUSTER_STRUCTURE,
        showCancel: false,
        alertType: AlertType.SUCCESS,
        onConfirm: () => {
          dispatch({
            type: ALERT_POPUP_CANCELED
          })
        },
        confirmText: OK
      }
    })
  }
}
