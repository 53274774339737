import {
	ALL,
	BUY_TO_FLY,
	COMPLEX_GEOMETRY,
	CONFIGURATION_RESULT_NOT_PRINTABLE,
	CONFIGURATION_RESULT_PRINTABLE,
	COST_SAVING,
	STANDALONE_PARTS,
	SAVED,
	TIME_SAVING
} from '../../../Services/Strings'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { benefitType } from 'Services/models/ISolutionBenefit'

export const filters = {
	SAVED,
	PRINTABLE: CONFIGURATION_RESULT_PRINTABLE,
	ALL,
	COST_SAVING,
	TIME_SAVING,
	BUY_TO_FLY,
	COMPLEX_GEOMETRY,
	NOT_PRINTABLE: CONFIGURATION_RESULT_NOT_PRINTABLE,
	STANDALONE_PARTS
}

export const benefitsFiltersValues = {
	[COST_SAVING]: benefitType.costSaving,
	[TIME_SAVING]: benefitType.timeSaving,
	[BUY_TO_FLY]: benefitType.buyToFly,
	[COMPLEX_GEOMETRY]: benefitType.complexityScore
}

export const statusFiltersValues = {
	[CONFIGURATION_RESULT_PRINTABLE]: CADAnalysisResult.printable,
	[CONFIGURATION_RESULT_NOT_PRINTABLE]: CADAnalysisResult.notPrintable
}
