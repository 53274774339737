import React, { FC, memo } from 'react'
import { getString } from '../../../Services/Strings/StringService'
import DetailWithTitle from '../DetailWithTitle/DetailWithTitle'
import UpgradeUser from './UpgradeUser'

import './UserActivityData.scss'

interface IProps {
  productName: string
  expireDate: string
  expireDateOriginal: string
  showUpgrade: boolean
  amountOfRemainParts: number
  partsCredit: number
  onUpgradeClick: Function
}

const getUpgradeButtonText = (
  amountOfRemainParts: number,
  expireDate: string
) => {
  const daysLeft = calcHowManyDaysLeftToExpire(expireDate)
  const notEnoughDays = daysLeft <= 14
  const notEnoughParts = amountOfRemainParts <= 10

  if (notEnoughDays && notEnoughParts) {
    return getString('RENEW_NOW')
  }

  if (notEnoughDays) {
    return getString('RENEW_NOW')
  }

  if (notEnoughParts) {
    return getString('ADD_CREDIT_NOW')
  }

  return getString('UPGRADE_NOW')
}

const calcHowManyDaysLeftToExpire = (expireDate: string) => {
  const dateNow = new Date() as any
  const subscriptionExpireDate = new Date(expireDate) as any

  const diffTime = Math.abs(subscriptionExpireDate - dateNow)
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
  return diffDays
}

const UserActivityData: FC<IProps> = ({
  productName,
  expireDate,
  expireDateOriginal,
  showUpgrade,
  amountOfRemainParts,
  partsCredit,
  onUpgradeClick
}) => {
  return (
    <div className="user-activity-data">
      <div className="user-activity-data--general">
        <DetailWithTitle
          title={getString('MY_PRODUCT')}
          className="user-activity-data--general-detail"
          body={
            <span>
              {`${getString('CASTOR')} `}
              <span className="user-activity-data--general--product">
                {productName}
              </span>
            </span>
          }
          qaDataElementName="data-qa-my-product-value"
        />
        <DetailWithTitle title={getString('EXPIRE_DATE')} body={expireDate} className="user-activity-data--general-detail" qaDataElementName="data-qa-expire-date-value" />
        <DetailWithTitle title={getString('PARTS_CREDIT')} body={partsCredit} className="user-activity-data--general-detail" qaDataElementName="data-qa-parts-credit-value" />
        <DetailWithTitle
          title={getString('REMAINING_PARTS_CREDIT')}
          className="user-activity-data--general-detail"
          body={amountOfRemainParts}
          qaDataElementName="data-qa-remaining-parts-credit-value"
        />
      </div>
      <div>
        <UpgradeUser
          showEnterprise={showUpgrade}
          buttonCustomText={getUpgradeButtonText(
            amountOfRemainParts,
            expireDateOriginal
          )}
          onUpgradeClick={onUpgradeClick}
        />
      </div>
    </div>
  )
}

export default memo(UserActivityData)
