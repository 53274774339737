import { isEmpty } from 'lodash'

import {
  UPLOAD_SCRIPT_FILE_START,
  UPLOAD_SCRIPT_FILE_END,
  UPLOAD_SCRIPT_ADD_FILE,
  UPLOAD_SCRIPT_ERROR,
  HANDLE_NOTIFICATION
} from '../../../global actions/types'
import { uploadScript } from '../../../Services/Network'
import {
  SHOW_NOTIFICATION,
  UPDATE_DATABASE_FAILED,
  UPDATE_DATABASE_SUCCESS,
  UPDATE_DATABASE_ERROR_FILE_LENGTH,
  UPDATE_DATABASE_ERROR_FILE_FORMAT
} from '../../../Services/Strings'
import { zipFileTypes } from '../NewUploadProject/fileTypes'

export const onUploadFile = (sendFile: any): any => {
  return async (dispatch: any) => {
    dispatch({ type: UPLOAD_SCRIPT_FILE_START })

    try {
      if (sendFile) {
        const response = await uploadScript(sendFile)
        if (response) {
          dispatch({
            type: HANDLE_NOTIFICATION,
            payload: {
              notificationType: SHOW_NOTIFICATION.SUCCESS,
              notificationMessage: UPDATE_DATABASE_SUCCESS
            }
          })
        } else {
          dispatch({
            type: HANDLE_NOTIFICATION,
            payload: {
              notificationType: SHOW_NOTIFICATION.ERROR,
              notificationMessage: UPDATE_DATABASE_FAILED
            }
          })
        }
      }
    } catch (e) {
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage: UPDATE_DATABASE_FAILED
        }
      })
    }

    dispatch({ type: UPLOAD_SCRIPT_FILE_END })
  }
}

export const setFile = (newFile: any, readerResult: any): any => {
  return (dispatch: any) => {
    const file = newFile[0]
    const multipleFiles = newFile.length > 1
    const wrongType = !zipFileTypes.includes(file.type)
    const error = multipleFiles
      ? UPDATE_DATABASE_ERROR_FILE_LENGTH
      : wrongType
      ? UPDATE_DATABASE_ERROR_FILE_FORMAT
      : ''

    if (!isEmpty(error)) {
      dispatch({ type: UPLOAD_SCRIPT_ERROR, payload: { error } })
    } else {
      const preparedToSend = {
        data: readerResult?.split(',').pop(),
        fileName: file.name
      }
      dispatch({
        type: UPLOAD_SCRIPT_ADD_FILE,
        payload: { file, preparedToSend }
      })
    }
  }
}
