import TextField from "@material-ui/core/TextField";
import InfoBox from "../../../Components/InfoBox";
import React, { InputHTMLAttributes } from "react";

const TextFieldTSX: any = TextField

interface ITextField {
  input: InputHTMLAttributes<HTMLInputElement>,
  label?: string,
  meta: {
    error?: boolean,
    touched?: boolean
  }
  initialized: boolean,
  disabled?: boolean,
  iIcon?: string
}

export const renderTextField = ({
  input,
  label,
  meta: { error },
  iIcon,
  initialized,
  disabled,
 ...custom
}: ITextField) => (
  <div className="flex-block">
    <TextFieldTSX
      disabled={disabled}
      label={label}
      hintText={label}
      floatingLabelText={label}
      error={initialized && error}
      helperText={error}
      {...input}
      {...custom}
    />
    {
      iIcon && <InfoBox
        boxContact={iIcon}
        iconClassName="admin-form-field__info_icon"
        boxDirection="right-start"
      />
    }
  </div>
)
