import { RootStateOrAny, useSelector } from 'react-redux'

import { isEmpty, orderBy, some } from 'lodash'
import isArray from 'lodash/isArray'

import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { PRINTER_TECHNOLOGY } from 'Scenes/Home/NewPartAnalysis/NewPartConfiguration/constants'
import { useSolutionReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IFilter } from 'Services/models/IFilter'
import { Material } from 'Services/models/IMaterial'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { IUserFilter } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'

export const validateFilter = (value: any, filters: any, fieldName: string) => {
	if (value) {
		const filter: IFilter = filters.find(
			(filter: IFilter) => filter.name === fieldName
		)
		if (filter.maxValue && value > filter.maxValue) {
			return `${getString('MUST_BE_MAXIMUM')} ${filter.maxValue}`
		}
		if ((filter.minValue || filter.minValue === 0) && value < filter.minValue) {
			return `${getString('MUST_BE_MINIMUM')} ${filter.minValue}`
		}

		return ''
	}
}

export const getSelectedOptions = (
	filter: IFilterData,
	printingTechnologies?: IPrintingTechnology[]
): string[] => {
	let selectedOptions: string[] | undefined = []

	switch (filter.name) {
		case 'printerTechnology':
			if (selectedOptions.length === 0) {
				selectedOptions = printingTechnologies?.map(
					(printingTechnology: IPrintingTechnology) => printingTechnology.name
				)
			}
			break
		default:
			break
	}

	return selectedOptions || []
}

export const getDataOptions = (
	filter: IFilterData,
	printingTechnologies?: IPrintingTechnology[]
): [] => {
	let dataOptions: any = []
	switch (filter.name) {
		case 'printerTechnology':
			printingTechnologies?.map((printingTechnology: IPrintingTechnology) =>
				dataOptions.push({
					key: printingTechnology.name,
					value: printingTechnology.userReadableName
				})
			)
			break

		default:
			break
	}
	return dataOptions || []
}

export const getDisplayFilterItemsList = (updatedFilters: any) => {
	const itemList: any = []
	updatedFilters
		.filter((filter: any) => {
			if (filter.checked) {
				const printingTechnologyValue =
					filter.name == PRINTER_TECHNOLOGY && filter.value !== null

				// return false if:
				// - it's mandatory,showAsDefault and checked
				// - if printingTechnology checked and value is not custom
				return !(
					!printingTechnologyValue &&
					filter.mandatory &&
					filter.showAsDefault
				)
			}
		})
		.forEach((filterValueKey: any) => {
			if (filterValueKey.type !== 'boolean' && filterValueKey.value == null) {
				return
			}
			itemList.push({
				viewOnly: filterValueKey.viewOnly,
				key: filterValueKey.name,
				value: getString(filterValueKey?.actualName) || filterValueKey.name,
				allowRemove: !filterValueKey?.mandatory
			})
		})
	return itemList
}

export const getAdvancedSettings = (
	filters: IFilter[],
	userFilters: IUserFilter[]
) => {
	const advancedFilters = userFilters
		.sort((filterA: any, filterB: any) =>
			filterA.name?.localeCompare(filterB.name)
		)
		.map(userFilter => {
			const filter = filters.find(f => f.name === userFilter.name)
			let value
			switch (filter?.type) {
				case 'boolean':
					value =
						userFilter?.on &&
						(userFilter?.value == '1' || userFilter?.value === 'true')
					break
				case 'array':
					const filterValue = userFilter?.value
					if (filterValue === null) {
						value = filterValue
					} else {
						value = filterValue?.includes(',')
							? filterValue?.split(',')
							: isArray(filterValue)
							? filterValue
							: [filterValue]
					}

					break
				default:
					value = userFilter?.value
					break
			}

			const checked = userFilter?.on || filter?.mandatory

			return {
				name: userFilter.name,
				value: value,
				viewOnly: filter?.viewOnly,
				disabled: !checked,
				showAsDefault: filter?.showAsDefault,
				checked: checked,
				prevChecked: checked,
				defaultValue: filter?.defaultValue,
				order: filter?.order,
				mandatory: filter?.mandatory,
				actualName: filter?.actualName,
				units: filter?.units,
				maxValue: filter?.maxValue,
				minValue: filter?.minValue,
				type: filter?.type,
				error: null,
				manualCheck: false
			}
		})

	return advancedFilters
}

export const initializeFilters = (filters: IFilter[]) => {
	const userFilters: IUserFilter[] = []
	filters &&
		filters
			.filter(filter => !filter.viewOnly)
			.map(filter => {
				const userFilter: IUserFilter = {
					name: filter.name,
					on: false,
					value: null
				}
				if (filter.showAsDefault) {
					userFilter.on = true
					userFilter.value =
						filter.type === 'boolean'
							? !!filter.defaultValue
							: filter.defaultValue
				}
				userFilters.push(userFilter)
			})
	return userFilters
}

export const initializeConfigurationFilters = (
	userFilters: any,
	initialValues: any
) => {
	const filters: IUserFilter[] = []
	userFilters &&
		userFilters
			.filter((filter: any) => !filter.viewOnly)
			.map((filter: any) => {
				const userFilter: IUserFilter = {
					name: filter.name,
					on: false,
					value: null
				}
				if (filter.showAsDefault) {
					userFilter.on = true
					userFilter.value =
						filter.type === 'boolean'
							? !!filter.defaultValue
							: filter.defaultValue
				}

				if (initialValues[filter.name]) {
					userFilter.value = initialValues[filter.name]
					userFilter.on = true

					if (typeof initialValues[filter.name] === 'boolean') {
						userFilter.on = initialValues[filter.name]
					}
				}
				filters.push(userFilter)
			})

	return filters
}

export const prepareUserFiltersToSend = (
	filters: any,
	userFilters: Array<any>,
	toDefault?: boolean
) => {
	if (toDefault) return userFilters
	return userFilters.map((userFilter: any) => {
		const changedFilter = filters.find(
			(filter: any) => filter.name === userFilter.name
		)
		if (changedFilter) {
			return {
				...userFilter,
				on: changedFilter.checked,
				value: changedFilter.value
			}
		}

		return userFilter
	})
}

export const prepareFiltersToSend = (
	filters: Array<any>
): Record<string, unknown> => {
	return filters
		.map(filter => {
			const isCheckedChange = filter.prevChecked !== filter.checked

			// check if filter is array then put null or value
			if (filter?.type === 'array') {
				return {
					[filter.name]: filter.value
				}
			}

			// check if filter is not boolean and value is provided
			if (filter?.type !== 'boolean') {
				if (filter.checked && filter.value !== null) {
					return {
						[filter.name]: filter.value
					}
				}
			}

			// check if filter is boolean and checked or changed
			if (filter?.type === 'boolean') {
				if (isCheckedChange || filter.checked) {
					return {
						[filter.name]: filter.checked
					}
				}
			}

			// if mandatory field and not null
			if (
				filter?.mandatory &&
				filter.value != null &&
				filter.value != filter?.defaultValue
			) {
				return {
					[filter.name]: filter.value
				}
			}

			return {}
		})
		.filter(el => !isEmpty(el))
		.reduce((prev, curr) => ({ ...prev, ...curr }), {})
}

export const useSolutionFiltersRecalculateWarn = (
	configurationId: number,
	originalMaterial: Material,
	configurationFilters: any = {}
) => {
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)

	const { filters } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[
				configurationId as number
			] || new AdvancedSettingsInitialState()
	)
	const { chosenMaterial } = useSolutionReducer(configurationId as number)
	const changedMaterialFilters = originalMaterial !== chosenMaterial

	if (!showDefaultMaterialFilters) {
		return false
	}

	return (
		!changedMaterialFilters &&
		some(
			filters,
			filter =>
				filter.type === 'boolean' &&
				!!filter.value &&
				filter.value !== configurationFilters[filter.name]
		)
	)
}

export const keepConfigurationUniqueFilters = (
	solutionFilters: Record<string, unknown>,
	filters: Record<string, unknown>[],
	configurationFilters: Record<string, unknown>
) => {
	Object.keys(configurationFilters).forEach(key => {
		if (filters.findIndex((filter: any) => filter.name === key) < 0) {
			solutionFilters[key] =
				configurationFilters[key as keyof typeof configurationFilters]
		}
	})
	return solutionFilters
}
