import { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Flexbox from 'Scenes/Components/FlexBox'

import { withStyles } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import InputAdornment from '@material-ui/core/InputAdornment'
import LockOutline from '@material-ui/icons/LockOutlined'

import CustomInput from '../../Components/thirdParty/CreativeTim/components/CustomInput/CustomInput.jsx'
import registerPageStyle from '../../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import ButtonWithLoader from '../../Components/ButtonWithLoader'
import MySimpleAlert from '../../Components/alerts/MySimpleAlert'
import * as AuthActions from './../AuthActions'

import { LOGIN_ROUTE } from '../../../Services/Constants/RoutesConstants'
import {
  PASSWORD,
  RE_ENTER_PASSWORD,
  RESET_PASSWORD,
  RESET_PASSWORD_EMAIL_POPUP_HEADER,
  RESET_PASSWORD_ERROR_ALERT,
  RESET_PASSWORD_SUCCESS_POPUP_HEADER,
  RESET_PASSWORD_SUCCESS_POPUP_BODY,
  OK
} from '../../../Services/Strings'

import './ResetPassword.css'
import { getTheme } from '../../../themes/getTheme'
import CastorAlert from '../../Components/alerts/CastorAlert'
import { AlertType } from '../../Components/alerts/AlertTypes'

const theme = getTheme()

class ResetPassword extends Component {
  componentDidMount() {
    const { token } = this.props.match.params
    const { saveResetToken } = this.props
    saveResetToken(token)
  }
  onPasswordChange(event) {
    const { forgotPasswordChanged } = this.props
    forgotPasswordChanged(event.target.value)
  }
  onReenterPasswordChange(event) {
    const { forgotReenterPasswordChanged, strengthScore, forgotPassword } =
      this.props
    forgotReenterPasswordChanged(
      strengthScore,
      forgotPassword,
      event.target.value
    )
  }
  onSubmitClicked() {
    const {
      sendResetPasswordToServer,
      forgotPassword,
      forgotReenterPassword,
      strengthScore,
      resetToken
    } = this.props
    sendResetPasswordToServer({
      forgotPassword,
      forgotReenterPassword,
      strengthScore,
      resetToken
    })
  }
  renderAlert() {
    const {
      showingSimpleAlertText,
      showingSimpleAlertTitle,
      showResetPasswordErrorAlert,
      resetPasswordSucceeded
    } = this.props
    if (resetPasswordSucceeded && !showResetPasswordErrorAlert) {
      return (
        <CastorAlert
          show={resetPasswordSucceeded && !showResetPasswordErrorAlert}
          alertType={AlertType.SUCCESS}
          headerTitle={RESET_PASSWORD_SUCCESS_POPUP_HEADER}
          onConfirm={() => {
            window.location.href = LOGIN_ROUTE
          }}
          onCancel={() => {
            window.location.href = LOGIN_ROUTE
          }}
          showCancel={false}
          confirmOptionalText={OK}
        >
          {RESET_PASSWORD_SUCCESS_POPUP_BODY}
        </CastorAlert>
      )
    }
    if (!showingSimpleAlertText && showResetPasswordErrorAlert) {
      return (
        <CastorAlert
          show={!showingSimpleAlertText && showResetPasswordErrorAlert}
          alertType={AlertType.WARNING}
          headerTitle={'reset failed'}
          onConfirm={this.props.hideResetPasswordErrorAlert.bind(this)}
          onCancel={this.props.hideResetPasswordErrorAlert.bind(this)}
          showCancel={false}
          confirmOptionalText={OK}
        >
          {RESET_PASSWORD_ERROR_ALERT}
        </CastorAlert>
      )
    }
    if (!showingSimpleAlertText) {
      return <div />
    }
    return (
      <MySimpleAlert
        style={{ display: 'block' }}
        title={showingSimpleAlertTitle}
        onConfirm={this.props.hideForgotPasswordStrengthAlert.bind(this)}
      >
        {showingSimpleAlertText}
      </MySimpleAlert>
    )
  }
  render() {
    const {
      classes,
      match,
      forgotPassword,
      strengthScore,
      showForgotPasswordStrengthAlert,
      forgotReenterPasswordValid,
      forgotReenterPasswordInValid,
      reenterForgotPassword,
      loading,
      error
    } = this.props
    const { userEmail } = match.params
    return (
      <div className="reset-password-form">
        {this.renderAlert()}
        <div className="reset-password-form-headers-div">
          <h3 className="reset-password-form-headers-div-title">
            {RESET_PASSWORD_EMAIL_POPUP_HEADER}
          </h3>
          <h5>{userEmail}</h5>
          <img
            style={styles.logoImage}
            src={theme.whiteBackgroundLogo || theme.logo}
          />
        </div>
        <div className="reset-password-form-inputs">
          <Flexbox flexDirection="row">
            <CustomInput
              success={strengthScore > 1}
              error={
                forgotPassword &&
                forgotPassword.length > 0 &&
                strengthScore <= 1
              }
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses
              }}
              inputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    className={classes.inputAdornment}
                  >
                    <LockOutline className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: PASSWORD,
                value: forgotPassword,
                onChange: this.onPasswordChange.bind(this),
                type: 'password',
                autoComplete: 'new-password'
              }}
            />
            <IconButton
              onClick={showForgotPasswordStrengthAlert}
              style={
                forgotPassword &&
                forgotPassword.length > 0 &&
                strengthScore <= 1
                  ? styles.infoBut
                  : styles.infoButHidden
              }
            >
              <InfoOutline />
            </IconButton>
          </Flexbox>
          <CustomInput
            success={forgotReenterPasswordValid}
            error={forgotReenterPasswordInValid}
            formControlProps={{
              fullWidth: true,
              className: classes.customFormControlClasses
            }}
            inputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                  className={classes.inputAdornment}
                >
                  <LockOutline className={classes.inputAdornmentIcon} />
                </InputAdornment>
              ),
              placeholder: RE_ENTER_PASSWORD,
              value: reenterForgotPassword,
              onChange: this.onReenterPasswordChange.bind(this),
              type: 'password',
              autoComplete: 'new-password'
            }}
          />
          <ButtonWithLoader
            rounded
            loading={loading}
            primary={true}
            style={styles.submitButtonStyle}
            onClick={this.onSubmitClicked.bind(this)}
          >
            {RESET_PASSWORD}
          </ButtonWithLoader>
          <br />
          <p style={styles.errorTextStyle}>{error}</p>
        </div>
      </div>
    )
  }
}
const styles = {
  submitButtonStyle: {
    margin: 15,
    width: 400
  },
  errorTextStyle: {
    fontSize: 15,
    alignSelf: 'center',
    color: 'red'
  },
  logoImage: {
    margin: 10,
    width: 150,
    alighSelf: 'center'
  },
  infoBut: { width: 40, marginLeft: 3, marginRight: -80, marginTop: 6 },
  infoButHidden: {
    width: 40,
    marginLeft: 3,
    marginRight: -80,
    visibility: 'hidden'
  }
}
const mapStateToProps = ({ auth }) => {
  const {
    forgotPassword,
    forgotReenterPassword,
    forgotReenterPasswordValid,
    forgotReenterPasswordInValid,
    showingSimpleAlertTitle,
    strengthScore,
    showingSimpleAlertText,
    showResetPasswordErrorAlert,
    resetPasswordSucceeded,
    resetToken
  } = auth
  return {
    forgotPassword,
    forgotReenterPassword,
    strengthScore,
    forgotReenterPasswordValid,
    forgotReenterPasswordInValid,
    showingSimpleAlertTitle,
    showingSimpleAlertText,
    showResetPasswordErrorAlert,
    resetPasswordSucceeded,
    resetToken
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...AuthActions }, dispatch)
}
export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(registerPageStyle)(ResetPassword))
)
