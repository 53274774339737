import React, { ChangeEvent, FC, memo, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as CustomizeActions from '../../CustomizeActions'
import { getSelectedMaterial } from '../../CustomizeMaterialNamesMapping/CastorMaterialNamesMappingService'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorMaterialSelector from 'Scenes/Components/CastorMaterialSelector/CastorMaterialSelector'
import Flexbox from 'Scenes/Components/FlexBox'
import { Material } from 'Services/models/IMaterial'
import {
	CO2_PER_KG_DATA_VALIDATION,
	CO2_PER_KG_PLACE_HOLDER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	defaultMaterial: any
	onCo2PerKgMaterialChange: Function
	setNewMaterial: Function
	co2PerKg: any
}

interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2EmissionsRawMaterial: FC<IProps> = ({
	materialCategories,
	materialTypes,
	materials,
	defaultMaterial,
	onCo2PerKgMaterialChange,
	setNewMaterial,
	co2PerKg
}) => {
	const [selectedMaterial, setSelectedMaterial] = useState<
		Material | undefined
	>(defaultMaterial)
	const [isError, setIsError] = useState(false)

	const onChangeSelector = (property: string, value: any) => {
		const _selectedMaterial = getSelectedMaterial(
			materials,
			materialCategories,
			property,
			value
		)
		setSelectedMaterial(_selectedMaterial)
	}
	const onCo2PerKgChange = (value: string) => {
		if (Number(value) <= 0) {
			setIsError(true)
		} else {
			setIsError(false)
			onCo2PerKgMaterialChange(value)
		}
	}

	const onSetClick = () => {
		setNewMaterial({
			material: selectedMaterial,
			co2PerKg: co2PerKg
		})
		setSelectedMaterial(defaultMaterial)
	}

	return (
		<>
			<div className="custom-calculation-co2--explanation">
				{getString('CUSTOMIZE_CO2_EMISSIONS_TITLE')}
			</div>
			<Flexbox
				alignItems="center"
				className="custom-calculation-co2--container"
			>
				<CastorMaterialSelector
					onChangeMaterial={onChangeSelector}
					materialCategories={materialCategories}
					materials={materials}
					disabled={false}
					materialTypes={materialTypes}
					selectedMaterial={selectedMaterial}
				/>
				<div className="material-picker">
					<TextFieldTSX
						className="custom-calculation-co2--material-picker"
						type="number"
						placeholder={CO2_PER_KG_PLACE_HOLDER}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							onCo2PerKgChange(e.target.value)
						}}
						value={co2PerKg}
						error={isError}
					/>
				</div>
				<ButtonWithLoader
					size={20}
					style={{
						padding: '5px 10px',
						width: 'unset',
						maxWidth: '70px',
						minWidth: '70px',
						right: '40px'
					}}
					top={20}
					className="custom-material-names--AddButton"
					onClick={onSetClick}
					disabled={isEmpty(co2PerKg) || isError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isError && (
				<div className="custom-calculation-co2--error">
					{CO2_PER_KG_DATA_VALIDATION}
				</div>
			)}
		</>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EmissionsRawMaterial)
)
