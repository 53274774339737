import React, { memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Numeral from 'numeral'

import { store } from '../../../index'
import InfoBox from '../../Components/InfoBox'
import Flexbox from 'Scenes/Components/FlexBox'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { IAlternativePrinters } from 'Services/models/AlternativePrinters'
import { getString } from 'Services/Strings/StringService'

import '../../Components/DetailWithTitle/DetailWithTitle.scss'
import './PrintersSelector.scss'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	printersList: Array<IAlternativePrinters>
	selectedPrinter: IAlternativePrinters
	onChange: Function
	disable?: boolean
	isCluster?: boolean
	showCostInRange?: boolean
}

interface IPrinter {
	company?: string
	technology?: string
	description?: string
}

const PrinterDetail: React.FC<IPrinter> = ({
	company = '',
	technology = '',
	description = ''
}) => {
	return (
		<div>
			{company && (
				<p>
					<span>{getString('PRINTER_TITLE_COMPANY')}</span> {company}
				</p>
			)}
			{technology && (
				<p>
					<span>{getString('PRINTER_TITLE_TECHNOLOGY')}</span> {technology}
				</p>
			)}
			{description && (
				<p>
					<span>{getString('PRINTER_TITLE_DESCRIPTION')}</span> {description}
				</p>
			)}
		</div>
	)
}

const PrintersSelector: React.FC<IProps> = ({
	printersList,
	selectedPrinter,
	onChange,
	disable,
	isCluster,
	showCostInRange
}: IProps) => {
	const { cluster } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const allowPrintersList =
		printersList && printersList.length > 1 && !disable && !isCluster
	const { company, technology, description, name } = selectedPrinter
	const onPrinterChanged = (event: any) => {
		onChange(event.target.value)
	}
	const onOpenDropDown = (event: any) => {
		event.stopPropagation()
	}
	const showInfo = company || technology || description

	const generateDropDownValue = (solution: any, index: number): any => {
		if (index == 0) {
			return solution.name
		}
		const { userCurrencySign } = store.getState().user
		const PrinterName = `${solution.name}`
		const materialNameString = `${solution.materialName} `
		const solutionCostString = ` ${Numeral(Math.abs(solution.cost)).format(
			'0,0'
		)}${userCurrencySign}`

		return (
			<div className="drop-down-value">
				<Flexbox flexDirection="column" width="80%">
					<div className="drop-down-value-printer-name">{PrinterName}</div>
					<div
						title={materialNameString}
						className="drop-down-value--material-name"
					>
						{materialNameString}
					</div>
				</Flexbox>
			</div>
		)
	}

	if (showCostInRange || cluster?.formatType === METADATA) {
		return (
			<div className="castor-detail with-frame">
				<div className="title-wrapper">
					<div></div>
					<div className="castor-detail-title">
						{getString('RECOMMENDED_PRINTER_LABEL')}
					</div>
				</div>
				<div className={`castor-detail-body detail-body`}>
					{technology} {getString('PRINTER')}
				</div>
			</div>
		)
	}

	return (
		<div className="castor-detail with-frame">
			<div className="title-wrapper">
				{showInfo ? (
					<InfoBox
						boxContactElement={
							<PrinterDetail
								company={company}
								technology={technology}
								description={description}
							/>
						}
						iconClassName="cost-details-icon"
					/>
				) : (
					<div />
				)}
				<div className="castor-detail-title">
					{getString('RECOMMENDED_PRINTER_LABEL')}
				</div>
			</div>
			<div className={`castor-detail-body detail-body`}>
				{allowPrintersList && (
					<SelectTSX
						disableUnderline
						classes={{
							select: 'select-drop-down--select',
							icon: 'select-drop-down--icon'
						}}
						className="select-drop-down"
						value={selectedPrinter?.id}
						onClick={onOpenDropDown}
						onChange={onPrinterChanged}
						title={selectedPrinter?.name}
					>
						<MenuItemTSX className="select-drop-down--menu" disabled>
							{getString('PRINTERS_DROPDOWN_HEADER')}
						</MenuItemTSX>
						{printersList.map((item: IAlternativePrinters, index: number) => {
							return (
								<MenuItemTSX
									className={`select-drop-down--menu ${
										index === printersList.length - 1
											? 'select-drop-down--menu--last'
											: ''
									}`}
									value={item.id}
									key={item.id}
								>
									{generateDropDownValue(item, index)}
								</MenuItemTSX>
							)
						})}
					</SelectTSX>
				)}
				{!allowPrintersList && name}
			</div>
		</div>
	)
}
export default memo(PrintersSelector)
