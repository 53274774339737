import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'
import { isEmpty } from 'lodash'

import * as CustomizeActions from '../../CustomizeActions'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { getString } from 'Services/Strings/StringService'

export const DEFAULT_CO2_PER_KW = 0.187
const DEFAULT_VALUE = ' Kg (Default)'
const TextFieldTSX: any = TextField

interface IProps {
	setUserCustomzationSettings: Function
	onCo2PerKwChange: Function
	co2PerKwValue: string
	co2PerKW: number
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2CarbonIntensity: FC<IProps> = ({
	setUserCustomzationSettings,
	onCo2PerKwChange,
	co2PerKwValue,
	co2PerKW
}) => {
	const [isCarbonError, setIsCarbonError] = useState(false)
	const onSaveCarbonIntensity = () => {
		setUserCustomzationSettings(co2PerKwValue, ParameterTypes.carbon)
	}
	const { carbonLoading } = useSelector((state: RootStateOrAny) => state.user)

	useEffect(() => {
		if (!carbonLoading) {
			onCo2PerKwChange('')
		}
	}, [carbonLoading])

	const carbonIntensityCheck = (value: string) => {
		if (Number(value) < 0.01 || Number(value) > 5) {
			setIsCarbonError(true)
		} else {
			setIsCarbonError(false)
		}
		onCo2PerKwChange(value)
	}
	return (
		<>
			<Flexbox
				flexDirection="row"
				className="custom-calculation-co2--carbon-wrapper"
			>
				<div className="custom-calculation-co2--explanation">
					{getString('CARBON_CO2_CALCULATION')}
				</div>
				<TextFieldTSX
					className="custom-calculation-co2--carbon-picker"
					type="number"
					placeholder={
						co2PerKW === DEFAULT_CO2_PER_KW
							? co2PerKW + DEFAULT_VALUE
							: `${co2PerKW} ${getString('KG_UNITS')}`
					}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						carbonIntensityCheck(e.target.value)
					}
					value={co2PerKwValue}
				/>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_PER_KWH')}
				</div>
				<InfoBox
					boxContact={getString('CARBON_CO2_CALCULATION_DATA')}
					iconClassName="custom-calculation-co2--icon-info"
					boxClassName="custom-calculation-co2--icon-info-content"
					boxDirection="right-end"
				/>
				<ButtonWithLoader
					size={20}
					style={{
						padding: '5px 20px',
						width: 'unset',
						maxWidth: '70px',
						minWidth: '70px',
						left: '255px',
						height: '33px'
					}}
					top={20}
					loading={carbonLoading}
					onClick={onSaveCarbonIntensity}
					disabled={isEmpty(co2PerKwValue) || isCarbonError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</Flexbox>
			{isCarbonError && (
				<div className="custom-calculation-co2--error">
					{getString('CARBON_CO2_CALCULATION_VALIDATION')}
				</div>
			)}
		</>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2CarbonIntensity)
)
