import React, { ChangeEvent, FC, memo, ReactNode } from 'react'
import {
  isEnterKeyPressed,
  isEscapeKeyPressed
} from '../../../Services/getKeyCodesService'
import {
  IResultsSelectionOption,
  PRINTING_SAVING_TYPES
} from '../../../Services/models/ResultsSelectionOptions'
import { getString } from '../../../Services/Strings/StringService'
import CastorAlert from '../alerts/CastorAlert'
import IconFactory from '../StarIcon/IconFactory'
import TexFieldInsideString from '../TexFieldInsideString'

import './PrintingSavingStats.scss'

interface IProps {
  selectValue: IResultsSelectionOption
  threshold: number
  printingSavingThresholdError: boolean
  show: boolean
  loadingCalculation: boolean
  onPrintingSavingThresholdChanged: Function
  onPrintingSavingAlertCancel: Function
  onPrintingSavingAlertConfirm: Function
  confirmOptionalText?: string
}

const onKeyUp = (
  e: KeyboardEvent,
  printingSavingThresholdError: boolean,
  onPrintingSavingAlertConfirm: Function,
  onPrintingSavingAlertCancel: Function
) => {
  if (!printingSavingThresholdError && isEnterKeyPressed(e)) {
    onPrintingSavingAlertConfirm()
  } else if (isEscapeKeyPressed(e)) {
    onPrintingSavingAlertCancel()
  }
}

const renderExplanation = (
  selectedType: PRINTING_SAVING_TYPES,
  threshold: number,
  printingSavingThresholdError: boolean,
  onPrintingSavingThresholdChanged: Function,
  onPrintingSavingAlertConfirm: Function,
  onPrintingSavingAlertCancel: Function
): ReactNode => {
  switch (selectedType) {
    case PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_THRESHOLD:
      return (
        <>
          {getString('RESULTS_SELECTION_BENEFITS_PRINTABLE_EXPLANATION_1')}
          <TexFieldInsideString
            value={threshold}
            type="number"
            min={1}
            max={90}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onPrintingSavingThresholdChanged(e.target.value)
            }
            error={printingSavingThresholdError}
            onKeyUp={(e: KeyboardEvent) =>
              onKeyUp(
                e,
                printingSavingThresholdError,
                onPrintingSavingAlertConfirm,
                onPrintingSavingAlertCancel
              )
            }
          />
          %{getString('RESULTS_SELECTION_BENEFITS_PRINTABLE_EXPLANATION_2')}
        </>
      )
    case PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_STARRED:
      return (
        <>
          {getString('RESULTS_SELECTION_BENEFITS_STARRED_EXPLANATION_1')}
          <IconFactory
            iconName="savedDisc"
            className="printing-saving--alert--icon"
          />
          {getString('RESULTS_SELECTION_BENEFITS_STARRED_EXPLANATION_2')}
        </>
      )
    case PRINTING_SAVING_TYPES.COST_EFFECTIVE_PARTS_WITH_STANDARD_COST:
      return <>{getString('RESULTS_SELECTION_BENEFITS_STANDARD_EXPLANATION')}</>
    default:
      return <div></div>
  }
}

const PrintingSavingAlert: FC<IProps> = ({
  selectValue,
  threshold,
  printingSavingThresholdError,
  show,
  loadingCalculation,
  onPrintingSavingThresholdChanged,
  onPrintingSavingAlertCancel,
  onPrintingSavingAlertConfirm,
  confirmOptionalText = getString('PART_ANALYSIS_CONFIGURE_CALCULATE')
}) => {
  return (
    <div>
      <CastorAlert
        headerTitle={'calculate 3D printing saving'}
        onCancel={() => onPrintingSavingAlertCancel()}
        show={show}
        loadingCalculation={loadingCalculation}
        onConfirm={onPrintingSavingAlertConfirm}
        confirmOptionalText={confirmOptionalText}
        disabled={printingSavingThresholdError}
      >
        <div>
          {renderExplanation(
            selectValue.type,
            threshold,
            printingSavingThresholdError,
            onPrintingSavingThresholdChanged,
            onPrintingSavingAlertConfirm,
            onPrintingSavingAlertCancel
          )}
        </div>
      </CastorAlert>
    </div>
  )
}

export default memo(PrintingSavingAlert)
