import Flexbox from 'Scenes/Components/FlexBox'
import { FC, useCallback, useMemo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { findRoleName } from '../AdminUsersService'
import { EDITABLE_ROLES } from 'Services/Constants'
import { getString } from 'Services/Strings/StringService'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const CheckboxTSX: any = Checkbox
const FormControlTsx: any = FormControl

type IProps = {
	roles: number[]
	onChange: (results: number[]) => void
	label: string
	error: boolean
}

const EditRoles: FC<IProps> = ({ roles, onChange, label, error }) => {
	const usersRoles = useSelector(
		(state: RootStateOrAny) => state.AdminUsersReducer.usersRoles
	)

	const nonEditableRoles = useMemo(
		() => roles?.filter(role => EDITABLE_ROLES.indexOf(role) === -1),
		[roles]
	)

	const handleChange = useCallback(
		({ target: { value } }: { target: { value: number[] } }) => {
			onChange(value)
		},
		[onChange]
	)

	return (
		<div className="select-role-wrapper">
			<label htmlFor="roles">{label}</label>
			<FormControlTsx className="admin-user-form--select" error={error}>
				<SelectTsx
					id="roles"
					className="admin-user-form--select"
					data-qa="data-qa-user-roles-select"
					multiple
					MenuProps={{
						variant: 'menu',
						getContentAnchorEl: null,
						PopoverClasses: {
							root: 'dropdown'
						}
					}}
					value={roles || []}
					onChange={handleChange}
					renderValue={() => {
						return (
							<div className="admin-user-form--select--selected-values">
								{roles.length !== 1
									? getString('ROLES_SELECTED').format(roles.length)
									: findRoleName(usersRoles, roles[0])}
							</div>
						)
					}}
				>
					{EDITABLE_ROLES.map((role: number) => {
						const roleName = findRoleName(usersRoles, role)
						return (
							<MenuItemTsx
								key={role}
								value={role}
								className="menu-item"
								data-qa={`data-qa-role-${role}`}
							>
								<Flexbox
									alignItems="center"
									justifyContent="flex-start"
									width="100%"
								>
									<CheckboxTSX
										color="primary"
										checked={roles.indexOf(role) > -1}
									/>
									<div>
										{roleName} ({role})
									</div>
								</Flexbox>
							</MenuItemTsx>
						)
					})}
					{nonEditableRoles.map((role: number) => {
						const roleName = findRoleName(usersRoles, role)
						return (
							<MenuItemTsx
								key={role}
								value={role}
								className="menu-item"
								disabled
							>
								<Flexbox
									alignItems="center"
									justifyContent="flex-start"
									width="100%"
								>
									<CheckboxTSX color="primary" disabled checked />
									<div>
										{roleName} ({role})
									</div>
								</Flexbox>
							</MenuItemTsx>
						)
					})}
				</SelectTsx>
				{error && (
					<span className="admin-user-form--select--error">
						{getString('ROLES_SELECTED').format(roles.length)}
					</span>
				)}
			</FormControlTsx>
		</div>
	)
}

export default EditRoles
