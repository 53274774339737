import { Action } from 'global actions/ActionModels'
import { SHOW_BOUNDARY_ERROR } from 'global actions/types'

interface IAction {
	type: string
	payload: {
		errorMessage?: string
		errorClass?: string
	}
}

const INITIAL_STATE = {
	errorMessage: '',
	errorClass: ''
}

export default (state = INITIAL_STATE, { type, payload }: Action<IAction>) => {
	switch (type) {
		case SHOW_BOUNDARY_ERROR: {
			return {
				...state,
				...payload
			}
		}
		default:
			return state
	}
}
