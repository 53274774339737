import React, { FC, useMemo, memo } from 'react'

import cx from 'classnames'

import CastorSelectBox from 'Scenes/Components/CastorSelectBox'

import { ReactComponent as File } from 'assets/img/svg/icons/file.svg'
import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import FileMaterialAndQuantitySelector from './FileMaterialAndQuantitySelector'

type IProps = {
	uploadedFile: File
	fileChipDeleteClicked: (file: File) => void
	errorFiles: string[]
	onBlur: () => void
}

const FileRow: FC<IProps> = ({
	uploadedFile,
	fileChipDeleteClicked,
	errorFiles,
	onBlur
}) => {
	const fileError = useMemo(
		() => !!errorFiles.find(errorFile => errorFile === uploadedFile.name),
		[errorFiles, uploadedFile.name]
	)
	return (
		<div className={cx('file-row', { withError: fileError })} onBlur={onBlur}>
			<CastorSelectBox
				key={uploadedFile.name}
				selected={true}
				withIcon={false}
				boxClassName="file-row--box"
			>
				<div>
					<File />
					<div className="text">{uploadedFile.name}</div>
				</div>
				<CircleClose
					onClick={(e: any) => {
						e.stopPropagation()
						fileChipDeleteClicked(uploadedFile)
					}}
					className="icon-close"
				/>
			</CastorSelectBox>
			<FileMaterialAndQuantitySelector fileName={uploadedFile.name} />
		</div>
	)
}

export default memo(FileRow)
