import { ChangeEvent, FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import moment from 'moment'

import * as UserPanelActions from './UserPanelActions'
import { Action, ActionWithPayload } from '../../../global actions/ActionModels'
import { getString } from '../../../Services/Strings/StringService'
import CastorGridElement, {
	ElementCardType
} from '../../Components/CastorGridElement'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import PrintingSavingStats from '../../Components/PrintingSavingStats'
import PrintingSavingAlert from '../../Components/PrintingSavingStats/PrintingSavingAlert'
import {
	ISimpleConfigurationCompany,
	ISimpleConfigurationPrinter
} from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import StatsSimpleDataCard from '../../Components/StatsSimpleDataCard'
import StatsUploadedParts from '../../Components/StatsUploadedParts'
import UserActivityData from '../../Components/UserActivityData'
import UserPanelROI from '../../Components/UserPanelROI'
import { UserRole } from '../UserRole.enum'
import { UserPanelInitialState } from './UserPanelReducer'
import HideForRoleHOC from 'Services/HOC/HideForRoleHOC'

import './UsePanel.scss'

interface MatchParams {
	userId: string
}

interface IProps
	extends UserPanelInitialState,
		RouteComponentProps<MatchParams> {
	userCurrencySign: string
	printersFullData: ISimpleConfigurationCompany[]
	getUserPanelData: (
		...getUserPanelDataParams: Parameters<
			typeof UserPanelActions.getUserPanelData
		>
	) => any
	unmountUserPanel: () => Action<any>
	onUpgradeClick: () => any
	onPrintingSavingSelectChange: (
		...onPrintingSavingSelectChangeParams: Parameters<
			typeof UserPanelActions.onPrintingSavingSelectChange
		>
	) => ActionWithPayload<any>
	onPrintingSavingThresholdChanged: (
		...onPrintingSavingThresholdChangedParams: Parameters<
			typeof UserPanelActions.onPrintingSavingThresholdChanged
		>
	) => ActionWithPayload<any>
	onPrintingSavingAlertCancel: (
		...onPrintingSavingAlertCancelParams: Parameters<
			typeof UserPanelActions.onPrintingSavingAlertCancel
		>
	) => Action<any>
	onPrintingSavingAlertConfirm: (
		...onPrintingSavingAlertConfirmParams: Parameters<
			typeof UserPanelActions.onPrintingSavingAlertConfirm
		>
	) => any
	onUserROITypeSelectChange: (
		...onUserROITypeSelectChangeParams: Parameters<
			typeof UserPanelActions.onUserROITypeSelectChange
		>
	) => ActionWithPayload<any>
	onROIThresholdChanged: (
		...onROIThresholdChangedParams: Parameters<
			typeof UserPanelActions.onROIThresholdChanged
		>
	) => ActionWithPayload<any>
	onROITypeAlertCancel: (
		...onROITypeAlertCancelParams: Parameters<
			typeof UserPanelActions.onROITypeAlertCancel
		>
	) => Action<any>
	onROITypeAlertConfirm: (
		...onROITypeAlertConfirmParams: Parameters<
			typeof UserPanelActions.onROITypeAlertConfirm
		>
	) => any
	onUserROICompanyChange: (
		...onUserROICompanyChangeParams: Parameters<
			typeof UserPanelActions.onUserROICompanyChange
		>
	) => ActionWithPayload<any>
	onUserROIPrinterChange: (
		...onUserROIPrinterChangeParams: Parameters<
			typeof UserPanelActions.onUserROIPrinterChange
		>
	) => ActionWithPayload<any>
}

interface IReduxStore {
	UserPanelReducer: UserPanelInitialState
	user: any
}

const UserPanel: FC<IProps> = ({
	match: {
		params: { userId }
	},
	showUpgrade,
	userExpireDate,
	userProductName,
	partsSavesMoney,
	partsSavesTime,
	totalUploadedParts,
	uploadedPartsChartData,
	uploadedPartsChartIndexes,
	printingSavingValue,
	printingSavingThreshold,
	printingSavingThresholdError,
	showPrintingSavingAlert,
	printingSavingLoading,
	printingSavingChartData,
	userCurrencySign,
	printingSavingIndexes,
	printingSavingTotalCostBenefit,
	userRoiLoading,
	userROITypeValue,
	showROITypeAlert,
	userROITypeThreshold,
	userROITypeThresholdError,
	printersFullData,
	printersCompaniesList,
	userROIPrinters,
	userROISelectedCompanyValue,
	userROISelectedPrinterValue,
	userROIAlertCanCalaulate,
	userROIMachineCost,
	userROITraditionalSavingYears,
	hideRoiData,
	userROIDataText,
	userSubscriptionData,
	onUpgradeClick,
	getUserPanelData,
	unmountUserPanel,
	onPrintingSavingSelectChange,
	onPrintingSavingThresholdChanged,
	onPrintingSavingAlertCancel,
	onPrintingSavingAlertConfirm,
	onUserROITypeSelectChange,
	onROIThresholdChanged,
	onROITypeAlertCancel,
	onROITypeAlertConfirm,
	onUserROICompanyChange,
	onUserROIPrinterChange
}) => {
	useEffect(() => {
		getUserPanelData(userId, userCurrencySign, printersFullData)

		return () => {
			unmountUserPanel()
		}
	}, [])

	return (
		<NavBarAndMaterial title={getString('MY_PANEL')}>
			<PrintingSavingAlert
				show={showPrintingSavingAlert}
				threshold={printingSavingThreshold}
				selectValue={printingSavingValue}
				printingSavingThresholdError={printingSavingThresholdError}
				onPrintingSavingThresholdChanged={onPrintingSavingThresholdChanged}
				loadingCalculation={printingSavingLoading}
				onPrintingSavingAlertCancel={onPrintingSavingAlertCancel}
				onPrintingSavingAlertConfirm={() =>
					onPrintingSavingAlertConfirm(
						printingSavingValue.type,
						printingSavingThreshold,
						userCurrencySign
					)
				}
			/>
			<PrintingSavingAlert
				show={showROITypeAlert}
				threshold={userROITypeThreshold}
				selectValue={userROITypeValue}
				printingSavingThresholdError={userROITypeThresholdError}
				onPrintingSavingThresholdChanged={onROIThresholdChanged}
				loadingCalculation={userRoiLoading}
				onPrintingSavingAlertCancel={onROITypeAlertCancel}
				onPrintingSavingAlertConfirm={() =>
					onROITypeAlertConfirm(
						userROITypeValue.type,
						userROITypeThreshold,
						(userROISelectedPrinterValue as ISimpleConfigurationPrinter)
							.printerId,
						printersFullData,
						userROIAlertCanCalaulate
					)
				}
				confirmOptionalText={
					userROIAlertCanCalaulate
						? getString('PART_ANALYSIS_CONFIGURE_CALCULATE')
						: getString('OK')
				}
			/>
			<div className="user-panel" data-qa="data-qa-user-panel">
				<div className="user-panel--grid">
					<CastorGridElement
						gridRow={2}
						gridColumn={4}
						title={getString('MY_ACTIVITY')}
					>
						<UserActivityData
							expireDate={moment(userExpireDate).format('MMM, Do YYYY')}
							expireDateOriginal={userExpireDate}
							productName={userProductName}
							showUpgrade={showUpgrade}
							amountOfRemainParts={
								userSubscriptionData &&
								userSubscriptionData.amount_of_remain_parts
							}
							partsCredit={
								userSubscriptionData && userSubscriptionData.parts_bundle
							}
							onUpgradeClick={onUpgradeClick}
						/>
					</CastorGridElement>

					<CastorGridElement
						gridRow={3}
						gridColumn={6}
						type={ElementCardType.BENEFIT}
						title={getString('3D_PRINT_SAVING')}
					>
						<PrintingSavingStats
							selectValue={printingSavingValue}
							onSelectChange={onPrintingSavingSelectChange}
							printingSavingChartData={printingSavingChartData}
							load={printingSavingLoading}
							printingSavingIndexes={printingSavingIndexes}
							printingSavingTotalCostBenefit={printingSavingTotalCostBenefit}
							userCurrencySign={userCurrencySign}
						/>
					</CastorGridElement>
					<CastorGridElement gridColumn={2} type={ElementCardType.BENEFIT}>
						<StatsSimpleDataCard
							mainData={partsSavesMoney}
							explenation={getString('PARTS_SAVES_MONEY')}
							iconName="dollar-light"
							qaDataElementName="data-qa-parts-save-money-value"
						/>
					</CastorGridElement>
					<CastorGridElement gridColumn={2} type={ElementCardType.BENEFIT}>
						<StatsSimpleDataCard
							mainData={partsSavesTime}
							explenation={getString('PARTS_SAVES_TIME')}
							iconName={'time-light'}
							qaDataElementName="data-qa-parts-save-time-value"
						/>
					</CastorGridElement>

					<CastorGridElement
						gridRow={3}
						gridColumn={4}
						title={getString('MY_PROJECTS')}
					>
						<StatsUploadedParts
							totalUploadedParts={totalUploadedParts}
							uploadedPartsChartData={uploadedPartsChartData}
							uploadedPartsChartIndexes={uploadedPartsChartIndexes}
						/>
					</CastorGridElement>
					<CastorGridElement
						gridRow={3}
						gridColumn={6}
						title={getString('ROI')}
						subtitle={getString('ROI_EXPLANATION')}
					>
						<UserPanelROI
							load={userRoiLoading}
							onTypeSelectChange={onUserROITypeSelectChange}
							selectTypeValue={userROITypeValue}
							companiesList={printersCompaniesList}
							printersList={userROIPrinters}
							printerValue={userROISelectedPrinterValue}
							printerCompanyValue={userROISelectedCompanyValue}
							userCurrencySign={userCurrencySign}
							userROIMachineCost={userROIMachineCost}
							userROITraditionalSavingYears={userROITraditionalSavingYears}
							hideRoiData={hideRoiData}
							userROIDataText={userROIDataText}
							onCompanyChange={(e: ChangeEvent<HTMLSelectElement>) =>
								onUserROICompanyChange(e.target.value)
							}
							onPrinterChange={(e: ChangeEvent<HTMLSelectElement>) => {
								const value = e.target.value

								value &&
									onUserROIPrinterChange(
										value,
										userROITypeValue.type,
										userROITypeThreshold,
										printersFullData
									)
							}}
						/>
					</CastorGridElement>
				</div>
			</div>
		</NavBarAndMaterial>
	)
}

const mapStateToProps = ({
	UserPanelReducer,
	user: { userCurrencySign, printersFullData, ...props }
}: IReduxStore) => {
	return {
		...UserPanelReducer,
		userCurrencySign,
		printersFullData
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...UserPanelActions }, dispatch)

export default HideForRoleHOC(
	connect(mapStateToProps, mapDispatchToProps)(memo(UserPanel)),
	[UserRole.LIGHT]
)
