import React from 'react'
import { Field, reduxForm } from 'redux-form'
import { forEach, map, isString } from 'lodash'
import { FormControlLabel, MenuItem } from '@material-ui/core'

import {
	checkErrorValues,
	standardPrinterProperties,
	maintenanceAsPercent,
	maintenanceAsFixed,
	printerCheckboxes,
	maintenanceMachineSelector
} from './constants'
import { NUMBER } from '../AdminPrinterMaterials/constants'
import { renderNumberField } from '../AdminFields/AdminNumberField'
import { renderTextField } from '../AdminFields/AdminTextField'

import { renderSelectFieldTouched } from '../AdminFields/AdminSelectField'
import { renderCheckbox } from '../AdminFields/AdminCheckbox'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import { getString } from '../../../../Services/Strings/StringService'
import { isLink } from '../../../../Services/validationFuncs'
import {
	REQUIRED,
	UNDO_CHANGES,
	SUBMIT,
	MATERIAL_TABLE_TEXTS
} from '../../../../Services/Strings'
import CastorSelector from '../../../Components/CastorSelector'

import '../adminHome.scss'

const validate = values => {
	const errors = {}

	forEach(checkErrorValues, item => {
		const selectedValue = values[item.name]

		if (
			item.checkIfEmpty &&
			isString(selectedValue) &&
			!selectedValue?.trim()
		) {
			errors[item.name] = REQUIRED
		}

		if (item.checkIfNull && values[item.name] == null) {
			errors[item.name] = REQUIRED
		}

		if (selectedValue && item.checkIfMinMax) {
			const checkOnlyMin = item?.min && selectedValue < item?.min
			const checkAndEqualMin = !item?.min && selectedValue <= item?.min

			if (selectedValue > item?.max || checkAndEqualMin || checkOnlyMin) {
				const label = item?.isRequired
					? getString('NUMBER_VALIDATION_REQUIRED')
					: getString('NUMBER_VALIDATION_NOT_REQUIRED')

				errors[item.name] = label.format(item?.min, item?.max)
			}
		}

		if (selectedValue && item?.checkIfLink && !isLink(selectedValue)) {
			errors[item.name] = getString('INVALID_LINK')
		}
	})

	return errors
}

const onMaintenanceMachineCostSelectorChanged = (
	event,
	changeFunc,
	actionFunc,
	printerId
) => {
	let fieldToRestName
	switch (event.target.value) {
		case 2:
			fieldToRestName = 'maintenanceAsPercentOfMachineCostPerYear'
			break
		case 1:
			fieldToRestName = 'maintenanceFixedMachineCostPerYear'
			break
		default:
			break
	}

	changeFunc(fieldToRestName, null)
	if (printerId) {
		actionFunc(event, printerId)
	} else {
		actionFunc(event)
	}
}

const renderSelectedItems = (
	selectedPrinterTechnology,
	printingTechnologies,
	standard,
	change,
	initialized
) => {
	return (
		<Field
			name={standard?.name}
			component={renderSelectFieldTouched}
			label={standard?.label}
			custom={{
				value: selectedPrinterTechnology || '',
				onChange: e => change('technology', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TECHNOLOGY}</MenuItem>
			{printingTechnologies.map(printingTechnology => {
				return (
					<MenuItem
						key={printingTechnology.name}
						value={printingTechnology.name}
					>
						{printingTechnology.userReadableName}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderMaintenanceMachineCostFields = (key, initialized) => {
	switch (key) {
		case 1:
			return (
				<Field
					initialized={initialized}
					className="admin-form-field"
					name={maintenanceAsPercent.name}
					component={renderTextField}
					label={maintenanceAsPercent.label}
					type={maintenanceAsPercent.type}
					iIcon={maintenanceAsPercent.iIcon}
				/>
			)
		case 2:
			return (
				<Field
					initialized={initialized}
					className="admin-form-field"
					name={maintenanceAsFixed.name}
					component={renderTextField}
					label={maintenanceAsFixed.label}
					type={maintenanceAsFixed.type}
					iIcon={maintenanceAsFixed.iIcon}
				/>
			)

		default:
			return <div />
	}
}

const AdminPrinterForm = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		printingTechnologies,
		valid,
		addingNewItem,
		onMaintenanceMachineCostFieldsSelectorChange,
		maintenanceMachineCostFieldsSelectorValue,
		change,
		printerId,
		initialized,
		selectedPrinterTechnology
	} = props

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			{map(standardPrinterProperties, standard => {
				if (standard?.isSelectedItems) {
					return (
						<div key={standard.name}>
							{renderSelectedItems(
								selectedPrinterTechnology,
								printingTechnologies,
								standard,
								change,
								initialized
							)}
						</div>
					)
				}

				return (
					<div key={standard.name}>
						<Field
							className="admin-form-field"
							name={standard?.name}
							component={
								standard?.type === NUMBER ? renderNumberField : renderTextField
							}
							label={standard?.label}
							initialized={initialized}
							multiline={standard?.multiline}
							{...standard}
						/>
					</div>
				)
			})}

			<div className="admin-form-field--maintenance-div">
				<div>{maintenanceMachineSelector?.name}</div>
				<CastorSelector
					className="admin-form-field--maintenance-div-selector"
					classes={{
						root: 'admin-form-field--maintenance-div-selector--root'
					}}
					value={maintenanceMachineCostFieldsSelectorValue}
					onChange={e => {
						onMaintenanceMachineCostSelectorChanged(
							e,
							change,
							onMaintenanceMachineCostFieldsSelectorChange,
							printerId
						)
					}}
					items={maintenanceMachineSelector?.items}
				/>
			</div>

			{renderMaintenanceMachineCostFields(
				maintenanceMachineCostFieldsSelectorValue,
				initialized
			)}

			{map(printerCheckboxes, checkbox => {
				return (
					<div key={checkbox?.name}>
						<FormControlLabel
							control={
								<Field name={checkbox?.name} component={renderCheckbox} />
							}
							label={checkbox?.label}
						/>
					</div>
				)
			})}

			<div>
				<Button
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={reset}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default reduxForm({
	form: 'adminPrinterFormState',
	validate,
	enableReinitialize: true
})(AdminPrinterForm)
