import { useEffect, useState } from 'react'

import {
	generateInventoryRows,
	generateMaterialRow,
	generateTotalCostRow,
	generateUpfrontRows,
	getProductionRows
} from './CostAnalysisTableService'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IChainBenefits } from 'Services/models/IChainBenefits'
import { IConfiguration } from 'Services/models/IConfiguration'
import { CustomFunctionStringCategory } from 'Services/models/IFunctionString'
import { ImanufacturingTypes } from 'Services/models/IManufacturingTypes'
import { Part } from 'Services/models/IPart'
import { ISolution } from 'Services/models/ISolution'
import { getTheme } from 'themes/getTheme'

const { showEffectiveIcon } = getTheme()

const getCostAnalysisTableRows = (
	solution: ISolution,
	configuration: IConfiguration,
	chainBenefits: IChainBenefits | any,
	userCustomizationSettings: Record<string, any>,
	part: Part,
	isShowValuesInRanges?: boolean,
	drawingCostPercentage?: number
) => {
	const { maxCostDetails, minCostDetails } = solution?.costDetails
	const { fullTrayAssumption } = userCustomizationSettings

	const supplyChainCostsBreakDown =
		solution.costDetails.threeDPrintingCostsBreakDown.supplyChainCostsBreakDown

	const costsBreakDown = supplyChainCostsBreakDown.costsBreakDown
	const minCostsBreakDown =
		minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.costsBreakDown
	const maxCostsBreakDown =
		maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.costsBreakDown

	// part costs
	const perPartCosts = supplyChainCostsBreakDown.perPartCosts
	const minPartCosts =
		minCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.perPartCosts
	const maxPartCosts =
		maxCostDetails?.threeDPrintingCostsBreakDown?.supplyChainCostsBreakDown
			?.perPartCosts

	const supplyChainTraditionalDetails =
		configuration.costResults.supplyChainTraditionalDetails || {}
	const costsBreakDownTraditional =
		supplyChainTraditionalDetails.costsBreakDown || {}
	const perPartCostsTraditional =
		supplyChainTraditionalDetails.perPartCosts || {}

	const { upfrontBreakDown = {}, inventoryBreakDown = {} } =
		costsBreakDownTraditional

	const totalPartEffective =
		solution.costDetails.totalCost < configuration.costResults.actualResult
	const upfrontEffective =
		perPartCosts.totalUpfrontCostsPerUnit <
		perPartCostsTraditional.totalUpfrontCostsPerUnit
	const toolingEffective =
		costsBreakDown.upfrontBreakDown.tooling < upfrontBreakDown.tooling
	const designEffective =
		costsBreakDown.upfrontBreakDown.design < upfrontBreakDown.design
	const verificationEffective =
		costsBreakDown.upfrontBreakDown.verification < upfrontBreakDown.verification
	const productionEffective =
		perPartCosts.totalProductionCostsPerUnit <
		perPartCostsTraditional.totalProductionCostsPerUnit
	const inventoryEffective =
		perPartCosts?.totalInventoryCostsPerUnit <
		perPartCostsTraditional?.totalInventoryCostsPerUnit
	const holdingEffective =
		costsBreakDown.inventoryBreakDown.holding < inventoryBreakDown.holding
	const orderingEffective =
		costsBreakDown.inventoryBreakDown.ordering < inventoryBreakDown.ordering
	const obsolescenceEffective =
		costsBreakDown.inventoryBreakDown.obsolescence <
		inventoryBreakDown.obsolescence
	const annualEffective =
		costsBreakDown.inventoryBreakDown.annualMaintenance <
		inventoryBreakDown.annualMaintenance
	const otherEffective =
		costsBreakDown.inventoryBreakDown.other < inventoryBreakDown.other
	const supplyChainOn = chainBenefits?.Global?.on

	const isStandardCost =
		configuration.manufactureMethod === ImanufacturingTypes.standardCost ||
		part.blockManufacturingMethodOperation

	const productionCostIsCustomized = solution?.costCalculatedWithCustomFunction
		? solution.costCalculatedWithCustomFunction[
				CustomFunctionStringCategory.productionCost
		  ]
		: false

	const traditionalCostCalculatedCustom =
		configuration?.traditionalCostCalculatedCustom

	let rows = [
		generateMaterialRow(solution, configuration),
		generateTotalCostRow(
			showEffectiveIcon,
			totalPartEffective,
			solution,
			configuration,
			supplyChainOn,
			userCustomizationSettings,
			part,
			chainBenefits,
			isStandardCost,
			isShowValuesInRanges,
			drawingCostPercentage
		)
	]
	if (traditionalCostCalculatedCustom && productionCostIsCustomized) {
		return rows
	}

	const allRows = [
		generateUpfrontRows(
			supplyChainOn,
			perPartCosts,
			showEffectiveIcon,
			upfrontEffective,
			perPartCostsTraditional,
			costsBreakDown,
			toolingEffective,
			supplyChainTraditionalDetails,
			designEffective,
			verificationEffective,
			isStandardCost,
			isShowValuesInRanges,
			minPartCosts,
			maxPartCosts,
			minCostsBreakDown,
			maxCostsBreakDown,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom
		),
		getProductionRows(
			perPartCosts,
			showEffectiveIcon,
			productionEffective,
			perPartCostsTraditional,
			solution,
			configuration,
			isShowValuesInRanges,
			minPartCosts,
			maxPartCosts,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom,
			fullTrayAssumption
		),
		generateInventoryRows(
			supplyChainOn,
			perPartCosts,
			showEffectiveIcon,
			inventoryEffective,
			perPartCostsTraditional,
			costsBreakDown,
			holdingEffective,
			supplyChainTraditionalDetails,
			orderingEffective,
			obsolescenceEffective,
			annualEffective,
			otherEffective,
			isShowValuesInRanges,
			minPartCosts,
			maxPartCosts,
			minCostsBreakDown,
			maxCostsBreakDown,
			productionCostIsCustomized,
			traditionalCostCalculatedCustom
		)
	]
	return [...rows, ...allRows]
}

export function useCostAnalysisTableRows(
	solution: any,
	configuration: any,
	chainBenefits: any,
	userCustomizationSettings: any,
	part: Part,
	drawingCostPercentage: number
) {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)

	const [costAnalysisTableRows, setCostAnalysisTableRows] = useState(
		getCostAnalysisTableRows(
			solution,
			configuration,
			chainBenefits,
			userCustomizationSettings,
			part,
			isShowValuesInRanges,
			drawingCostPercentage
		)
	)

	useEffect(() => {
		setCostAnalysisTableRows(
			getCostAnalysisTableRows(
				solution,
				configuration,
				chainBenefits,
				userCustomizationSettings,
				part,
				isShowValuesInRanges,
				drawingCostPercentage
			)
		)
	}, [solution, configuration, chainBenefits])

	return costAnalysisTableRows
}
