import React, { FC, useCallback } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onRemoveUserMaterialNameMapping,
	onUpdateMaterialNameMapping
} from 'Scenes/Home/Customize/CustomizeActions'
import MaterialNamesMappingList from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/MaterialNamesMappingList'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'

const EditMaterialNames: FC = () => {
	const {
		disableMaterialNameMappingSaveAll,
		userMaterialNamesMapping,
		materials,
		materialTypes,
		materialCategories,
		isLoadingMaterial
	} = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()

	const userMaterialNames = userMaterialNamesMapping?.filter(
		(materialName: IUserMaterialNamesMapping) => !materialName.defaultFormatType
	)

	const updateMaterialNameMapping = useCallback(
		(materialNameMapping: IUserMaterialNamesMapping[]) => {
			dispatch(onUpdateMaterialNameMapping(materialNameMapping))
		},
		[dispatch]
	)

	const removeUserMaterialNameMapping = useCallback(
		(id: string) => {
			dispatch(onRemoveUserMaterialNameMapping(id))
		},
		[dispatch]
	)

	return (
		<MaterialNamesMappingList
			title={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_TITLE')}
			explanation={getString(
				'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_EXPLANATION'
			)}
			disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
			userMaterialNamesMapping={userMaterialNames}
			isLoadingMaterial={isLoadingMaterial}
			materialCategories={materialCategories}
			materialTypes={materialTypes}
			materials={materials}
			isActive
			updateMaterialNameMapping={updateMaterialNameMapping}
			removeUserMaterialNameMapping={removeUserMaterialNameMapping}
		/>
	)
}

export default EditMaterialNames
