import React, { ChangeEvent, FC, memo, useMemo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Accordion } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ClearIcon from '@material-ui/icons/Clear'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined'
import cx from 'classnames'

import { FUNCTION_NUMBERS, FUNCTION_OPERATORS } from './constants'
import { useDragAndDrop } from './CostFunctionEditorHooks'
import {
	clearCustomFunction,
	deleteCustomVariable,
	deleteVariableFromFunction,
	openParameterModal,
	saveCustomFunction,
	toggleCostingFunctionAccordion
} from './CostingFunctionEditorActions'
import { getUserReadableFunctionCategoryName } from './CostingFunctionEditorService'
import {
	CostingFunctionSteps,
	IFunctionVariableExtended,
	VariableType
} from './CustomizeCostingFunctionTypes'
import SetNewParameterAlert from './SetNewParameterAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { IUser } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

const Step2: FC = () => {
	const dispatch = useDispatch()
	const {
		stepIsOpen,
		isLoadingStep2,
		isStep2ToggleAllowed,
		variablesList,
		customFunction,
		selectedFunctionCategory,
		selectedPrintingTechnologies,
		customFunctionsList
	} = useSelector((state: RootStateOrAny) => state.CostingFunctionEditorReducer)
	const { printingTechnologies }: IUser = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const {
		handleDragStart,
		handleDrop,
		handleDragEnd,
		handleDragOver,
		handleClick
	} = useDragAndDrop()

	const functionCategoryReadableName: string = useMemo(
		() => getUserReadableFunctionCategoryName(selectedFunctionCategory),
		[selectedFunctionCategory]
	)

	const step2 = CostingFunctionSteps.CreateFunction

	return (
		<>
			<Accordion
				disabled={!isStep2ToggleAllowed}
				className="costing-function-editor--accordion"
				expanded={stepIsOpen[step2]}
				onChange={(e: ChangeEvent<{}>, expanded: boolean) => {
					dispatch(toggleCostingFunctionAccordion(step2))
				}}
			>
				<AccordionSummary
					aria-controls="panel1bh-content"
					id="panel1bh-header"
					className="costing-function-editor--step-heading--wrapper"
					classes={{ content: 'costing-function-editor--step-heading' }}
				>
					<div className="costing-function-editor--step-heading--text">
						<span className="costing-function-editor--step-heading--text--main">
							{getString('COSTING_FUNCTION_EDITOR_STEP_2_HEADER')}
						</span>
						<span>
							{getString('COSTING_FUNCTION_EDITOR_STEP_2_DESCRIPTION')}
						</span>
					</div>
					<ExpandMoreIcon
						className={cx('expand', {
							open: stepIsOpen[step2]
						})}
					/>
				</AccordionSummary>
				<AccordionDetails className="costing-function-editor--step-body">
					<div className="costing-function-editor--create-function--upper-blocks">
						<div>
							<div className="costing-function-editor--create-function--label-wrapper">
								<div className="costing-function-editor--create-function--label with-border">
									{getString('COSTING_FUNCTION_PARAMETERS_LABEL')}
								</div>
								<Button
									color="transparent"
									className="costing-function-editor--create-function--transparent-button"
									onClick={() => dispatch(openParameterModal())}
									data-qa="data-qa-set-constant"
								>
									{getString('COSTING_FUNCTION_SET_PARAMETER')}
								</Button>
							</div>
							<div className="costing-function-editor--create-function--variables">
								{variablesList.map((variable: IFunctionVariableExtended) => {
									const isUsedInFunctionTimes = customFunction.filter(
										(funcVariable: IFunctionVariableExtended) =>
											funcVariable.name === variable.name
									).length
									const isCustomVariable =
										variable.type === VariableType.customVariable
									return (
										<DetailsPopup
											data={variable.value}
											popperDirection="bottom-end"
											isHover={isCustomVariable}
											popperClassName="info-box-wrapper"
											popperContactClassName="info-box-data"
											targetClassName="detail-title--star"
											key={variable.name}
										>
											<div
												className={cx(
													'costing-function-editor--create-function--operator',
													'variable',
													{ deletable: isCustomVariable }
												)}
												onDragStart={e => handleDragStart(e, variable)}
												onDragEnd={handleDragEnd}
												onClick={() => handleClick(variable)}
												draggable
											>
												{variable.userReadableName}
												{!!isUsedInFunctionTimes && (
													<span className="costing-function-editor--create-function--variable-count">
														{isUsedInFunctionTimes}
													</span>
												)}
												{isCustomVariable && (
													<span
														className="costing-function-editor--create-function--variable-count delete custom"
														onClick={(e: React.MouseEvent<HTMLDivElement>) => {
															e.stopPropagation()
															dispatch(
																deleteCustomVariable(
																	variable,
																	customFunctionsList
																)
															)
														}}
													>
														<ClearIcon />
													</span>
												)}
											</div>
										</DetailsPopup>
									)
								})}
							</div>
						</div>
						<div>
							<div className="costing-function-editor--create-function--label">
								{getString('COSTING_FUNCTION_OPERATORS_LABEL')}
							</div>
							<div className="costing-function-editor--create-function--operators-wrapper">
								<div className="costing-function-editor--create-function--operators">
									{FUNCTION_OPERATORS.map(operator => (
										<div
											key={operator.name}
											className="costing-function-editor--create-function--operator"
											onDragStart={e => handleDragStart(e, operator)}
											onClick={() => handleClick(operator)}
											draggable
										>
											{operator.userReadableName}
										</div>
									))}
								</div>
								<div className="costing-function-editor--create-function--operators">
									{FUNCTION_NUMBERS.map(number => (
										<div
											key={number.name}
											className="costing-function-editor--create-function--operator"
											onDragStart={e => handleDragStart(e, number)}
											onClick={() => handleClick(number)}
											draggable
										>
											{number.userReadableName}
										</div>
									))}
								</div>
							</div>
						</div>
					</div>
					<div className="costing-function-editor--create-function--drop-zone--wrapper">
						<div
							onDragOver={handleDragOver}
							onDrop={handleDrop}
							className="costing-function-editor--create-function--drop-zone"
						>
							<div className="costing-function-editor--create-function--operators">
								<div className="costing-function-editor--create-function--drop-zone--function-name">
									{functionCategoryReadableName} =
								</div>
								{customFunction.map((variable: IFunctionVariableExtended) => (
									<div
										key={variable.id}
										className={cx(
											'costing-function-editor--create-function--operator',
											'deletable',
											{
												variable:
													variable.type !== VariableType.operator &&
													variable.type !== VariableType.number
											}
										)}
									>
										{variable.userReadableName}
										<span
											className="costing-function-editor--create-function--variable-count delete"
											onClick={() =>
												dispatch(deleteVariableFromFunction(variable.id))
											}
										>
											<ClearIcon />
										</span>
									</div>
								))}
							</div>
						</div>
						<ButtonWithLoader
							disabled={!customFunction.length}
							id="costing-function-editor--button"
							className="costing-function-editor--button"
							loading={isLoadingStep2}
							qaDataElementName="data-qa-save-function-btn"
							onClick={() =>
								dispatch(
									saveCustomFunction(
										customFunction,
										selectedFunctionCategory,
										selectedPrintingTechnologies,
										printingTechnologies,
										variablesList
									)
								)
							}
						>
							<SaveOutlinedIcon className="costing-function-editor--icon" />
							{getString('SAVE')}
						</ButtonWithLoader>
						<Button
							disabled={!customFunction.length}
							color="secondary"
							id="costing-function-editor--button"
							className="costing-function-editor--button secondary"
							onClick={() => dispatch(clearCustomFunction())}
						>
							{getString('CLEAR')}
						</Button>
					</div>
				</AccordionDetails>
			</Accordion>
			<SetNewParameterAlert />
		</>
	)
}

export default memo(Step2)
