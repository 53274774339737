import React, { FC } from 'react'
import cx from 'classnames'

import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import { ReactComponent as File } from 'assets/img/svg/icons/file.svg'
import { ReactComponent as CircleClose } from 'assets/img/svg/icons/circle_close.svg'
import { defaultNotSupportedUnitTypeFormats } from 'Services/Constants'

interface UploadListItemsProps {
  filesToUpload: any
  fileChipDeleteClicked: (file: any) => void
  disabled: boolean
}

const UploadListItems: FC<UploadListItemsProps> = ({
  filesToUpload,
  fileChipDeleteClicked,
  disabled
}) => {
  return filesToUpload?.map((file: any) => {
    const showGrey = disabled &&
      file?.name?.toLowerCase()?.includes(defaultNotSupportedUnitTypeFormats)

    return (
      <CastorSelectBox
        key={file.name}
        selected={true}
        withIcon={false}
        boxClassName={cx('upload-files', {
          'grey': showGrey
        })}
      >
        <div
          onClick={(e: any) => e.stopPropagation()}
        >
          <File className="icon-file" />
          <div className="text">{file.name}</div>
          <CircleClose
            onClick={(e: any) => {
              e.stopPropagation()
              fileChipDeleteClicked(file)
            }}
            className="icon-close"
          />
        </div>
      </CastorSelectBox>
    )
  })
}

export default UploadListItems
