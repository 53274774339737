import React, { Component } from 'react'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import {
  CUSTOMIZE_MATERIAL_COST_TITLE,
  CUSTOMIZE_MATERIAL_COST_EXPLANATION_ARRAY
} from '../../../../Services/Strings'
import MaterialCostInputs from './MaterialCostInputs'
import CustomizeMaterialCostTbl from './CustomizeMaterialCostTbl'

class CustomizeMaterialCost extends Component {
  render() {
    return (
      <div>
        <CastorFormHeader
          explanationHeader={CUSTOMIZE_MATERIAL_COST_TITLE}
          explanationArray={CUSTOMIZE_MATERIAL_COST_EXPLANATION_ARRAY}
          isInCard={true}
        />
        <MaterialCostInputs />
        <CustomizeMaterialCostTbl />
      </div>
    )
  }
}

export default CustomizeMaterialCost
