import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as CustomizeUserMaterialsActions from '../CustomizeUserMaterialsActions'
import { customizeUserMaterial } from '../../../../../Services/routeFuncs'
import {
	CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER,
	NAV_TITLE_CUSTOMIZE_USER
} from '../../../../../Services/Strings'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import NewUserMaterialForm from './NewUserMaterialForm'

class NewUserMaterial extends Component {
	componentDidMount() {
		const { setupNewUserMaterialForm } = this.props
		setupNewUserMaterialForm()
	}

	onSubmit = data => {
		const { onUserMaterialAdd, userId, materialCategories } = this.props
		onUserMaterialAdd(data, userId, materialCategories)
	}

	renderFormContent = () => {
		const {
			materialCategoriesToShow,
			selectedType,
			selectedCategory,
			selectedTemperatureUnit,
			selectedSurfaceFinishMethod,
			onMaterialTypeChange,
			materialCategories,
			userMaterialsLoading,
			materialTypes,
			userCurrencySign
		} = this.props
		return (
			<NewUserMaterialForm
				onSubmit={this.onSubmit}
				materialCategories={materialCategoriesToShow}
				allMaterialCategories={materialCategories}
				selectedType={selectedType}
				selectedCategory={selectedCategory}
				selectedTemperatureUnit={selectedTemperatureUnit}
				selectedSurfaceFinishMethod={selectedSurfaceFinishMethod}
				onMaterialTypeChange={onMaterialTypeChange}
				loading={userMaterialsLoading}
				materialTypes={materialTypes}
				userCurrencySign={'$'}
			/>
		)
	}

	render() {
		const { materialAdded, userId } = this.props
		if (materialAdded) {
			return <Redirect to={customizeUserMaterial(userId)} />
		}
		return (
			<NavBarAndMaterial title={NAV_TITLE_CUSTOMIZE_USER}>
				<CastorForm
					formTitle={CUSTOMIZE_FORM_USER_NEW_MATERIAL_HEADER}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('customizeNewMaterialFormState')

const mapStateToProps = state => {
	const {
		materialAdded,
		userId,
		materialCategoriesToShow,
		userMaterialsLoading
	} = state.CustomizeUserMaterialsReducer
	const { materialCategories, materialTypes, userCurrencySign } = state.user

	return {
		materialAdded,
		userId,
		materialCategories,
		materialTypes,
		materialCategoriesToShow,
		userMaterialsLoading,
		userCurrencySign,
		selectedType: selector(state, 'type'),
		selectedCategory: selector(state, 'category'),
		selectedTemperatureUnit: selector(state, 'temperatureUnit'),
		selectedSurfaceFinishMethod: selector(state, 'surfaceFinishMethod')
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewUserMaterial)
