import { useEffect, useState } from 'react'
import { useClearCacheCtx } from 'react-clear-cache'
import { browserName, isMobile } from 'react-device-detect'
import Helmet from 'react-helmet'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Router, Switch } from 'react-router-dom'

import * as Analytics from './Services/Analytics'
import { getSystemParameters } from './global actions'
import AdminHome from './Scenes/admin/AdminHome/AdminHome'
import Login from './Scenes/Authentication/Login.js'
import Register from './Scenes/Authentication/Register.js'
import ResetPassword from './Scenes/Authentication/ResetPassword/ResetPassword'
import TwoFactorAuth from './Scenes/Authentication/TwoFactorAuth'
import UsageAgreement from './Scenes/Authentication/UsageAgreement'
import UserConfirmation from './Scenes/Authentication/UserConfirmation'
import CastorAlert from './Scenes/Components/alerts/CastorAlert'
import CastorSimpleAlert from './Scenes/Components/alerts/CastorSimpleAlert'
import CastorBanner from './Scenes/Components/CastorBanner'
import CastorNotification from './Scenes/Components/CastorNotification/CastorNotification'
import ExternalUserLogin from './Scenes/Components/ExternalUserLogin'
import LoggedOutRoute from './Scenes/Components/LoggedOutRoute'
import PrivateRoute from './Scenes/Components/PrivateRoute'
import TakeATour from './Scenes/Components/TakeATour/TakeATour'
import UploadingProject from './Scenes/Home/NewUploadProject/UploadingProject'
import UserHome from './Scenes/Home/UserHome'
import ExternalRedirect from './Scenes/IntegrationProvider/IntegrationProvider'
import history from './Services/history'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CustomizeInHousePrintersAlert from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersAlert'
import { pageBackground } from 'Services/colors'
import { supportedBrowsers } from 'Services/Constants'
import {
	ADMIN_ROUTE,
	CASTOR_HOME,
	CASTOR_LIGHT_ROUTE,
	EXTERNAL,
	EXTERNAL_ROUTE_PREFIX,
	LOGIN_ROUTE,
	PROJECTS_ROUTE,
	REGISTER_ROUTE,
	RESET_PASSWORD_ROUTE,
	TWO_FACTOR_AUTH,
	UPLOAD_ROUTE,
	UPLOADING_ROUTE,
	USAGE_AGREEMENT,
	USER_ACCOUNT_CONFIRMATION,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { isUserLoggedIn } from 'Services/jwt'
import { setup } from 'Services/Network'
import {
	externalProjectConfigurationsRoute,
	externalProjectRoute,
	externalSettings,
	resetPasswordRoute
} from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './App.css'

const RouterTSX: any = Router
const RedirectTSX: any = Redirect
const SwitchTSX: any = Switch
const RouteTSX: any = Route
const CastorBannerTSX: any = CastorBanner
const PrivateRouteTSX: any = PrivateRoute
const LoggedOutRouteTSX: any = LoggedOutRoute
const HelmetTSX: any = Helmet

const DEVICE = 'DEVICE'
const BROWSER = 'BROWSER'

const { tabTitle, fontStyle } = getTheme()

const App = () => {
	const [showDeviceNotSupportedAlert, setShowDeviceNotSupportedAlert] =
		useState(false)
	const [showBrowserNotSupportedAlert, setShowBrowserNotSupportedAlert] =
		useState(false)
	const { isLatestVersion, emptyCacheStorage } = useClearCacheCtx()
	const dispatch = useDispatch()

	useEffect(() => {
		const path = window.location.pathname
		const excludeRoutes = [
			EXTERNAL_ROUTE_PREFIX,
			RESET_PASSWORD_ROUTE,
			USER_ACCOUNT_CONFIRMATION
		]
		document.title = tabTitle || ''
		Analytics.configure()
		dispatch(getSystemParameters())

		if (path && !excludeRoutes.some(route => path.includes(route))) {
			const castorLight =
				`/${window.location.href.split('/').pop()}` === CASTOR_LIGHT_ROUTE
			isUserLoggedIn(castorLight)
		}

		if (isMobile) {
			setShowDeviceNotSupportedAlert(true)
		}

		if (!supportedBrowsers.includes(browserName)) {
			setShowBrowserNotSupportedAlert(true)
		}

		if (!isLatestVersion) {
			emptyCacheStorage()
		}

		setup()
	}, [])

	const closeModal = () => {
		showDeviceNotSupportedAlert
			? setShowDeviceNotSupportedAlert(false)
			: setShowBrowserNotSupportedAlert(false)
	}

	const unsupportedReason = showDeviceNotSupportedAlert ? DEVICE : BROWSER

	return (
		<>
			<CastorAlert
				alertType={AlertType.WARNING}
				show={showDeviceNotSupportedAlert || showBrowserNotSupportedAlert}
				headerTitle={getString(`UNSUPPORTED_${unsupportedReason}_ALERT_TITLE`)}
				onCancel={closeModal}
				cancelOptionalText={getString(
					`UNSUPPORTED_${unsupportedReason}_ALERT_BUTTON`
				)}
				showConfirm={false}
			>
				{getString(`UNSUPPORTED_${unsupportedReason}_ALERT_MESSAGE`)}
			</CastorAlert>

			<RouterTSX
				history={history}
				onUpdate={Analytics.trackPageView(
					window.location.pathname + window.location.search
				)}
			>
				<div
					className="App"
					style={
						fontStyle || {
							fontFamily: "'Roboto', Helvetica, Arial, sans-serif",
							fontWeight: '300'
						}
					}
				>
					<RouteTSX
						path={`${EXTERNAL_ROUTE_PREFIX}${externalProjectRoute()}`}
						exact
						component={ExternalUserLogin}
					/>
					<RouteTSX
						path={`${EXTERNAL_ROUTE_PREFIX}${externalProjectConfigurationsRoute()}`}
						exact
						component={ExternalUserLogin}
					/>
					<RouteTSX
						path={`${EXTERNAL_ROUTE_PREFIX}${externalSettings()}`}
						exact
						component={ExternalUserLogin}
					/>
					<RouteTSX
						path={USER_ACCOUNT_CONFIRMATION}
						exact
						component={UserConfirmation}
					/>
					<RouteTSX path={EXTERNAL} component={ExternalRedirect} />
					<RouteTSX
						path={`${EXTERNAL}/providers/:id`}
						exact
						component={ExternalRedirect}
					/>
					{process.env.REACT_APP_ALLOW_REGISTER !== 'false' ? (
						<>
							<LoggedOutRouteTSX path={REGISTER_ROUTE} component={Register} />
							<LoggedOutRouteTSX path={LOGIN_ROUTE} component={Login} />
						</>
					) : (
						<div />
					)}
					<RouteTSX
						path={CASTOR_HOME}
						//@ts-ignore
						component={() => (window.location = 'https://www.3dcastor.com')}
					/>
					<PrivateRouteTSX path={USER_HOME_ROUTE} component={UserHome} />
					<RouteTSX
						path={resetPasswordRoute()}
						exact
						component={ResetPassword}
					/>
					<RouteTSX path={USAGE_AGREEMENT} component={UsageAgreement} />

					<PrivateRouteTSX
						path={UPLOADING_ROUTE}
						component={UploadingProject}
					/>

					<SwitchTSX>
						<RedirectTSX exact path="/" to={USER_HOME_ROUTE + UPLOAD_ROUTE} />
					</SwitchTSX>
					<PrivateRouteTSX path={ADMIN_ROUTE} component={AdminHome} />
					<SwitchTSX>
						<RedirectTSX
							exact
							path={ADMIN_ROUTE}
							to={ADMIN_ROUTE + PROJECTS_ROUTE}
						/>
					</SwitchTSX>
					<PrivateRouteTSX path={TWO_FACTOR_AUTH} component={TwoFactorAuth} />

					<HelmetTSX
						bodyAttributes={{ style: `background-color : ${pageBackground}` }}
					/>
					<CastorNotification />
					<CustomizeInHousePrintersAlert />
					<CastorSimpleAlert />
					<TakeATour />
					<CastorBannerTSX />
					{/* <Route path="*">
						<ErrorPage />
					</Route> */}
				</div>
			</RouterTSX>
		</>
	)
}

export default App
