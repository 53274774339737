import React, { memo } from 'react'

import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	withStyles
} from '@material-ui/core'
import cx from 'classnames'
import PropTypes from 'prop-types'

import Loader from '../../../../../Loader/Loader'
import tableStyle from '../../variables/styles/tableStyle'

function CustomTable({ ...props }) {
	const {
		classes,
		tableHead,
		tableData,
		tableHeaderColor,
		editTableStyle,
		loading,
		addMinHeight,
		qaDataElementName,
		className
	} = props

	const tableCellClass =
		classes.tableCell +
		cx({
			[' ' + classes[editTableStyle]]: editTableStyle
		})

	return (
		<div
			className={cx(classes.tableResponsive, className)}
			style={{ minHeight: addMinHeight ? '100vh' : '' }}
		>
			<Table className={classes.table} data-qa={qaDataElementName}>
				{tableHead !== undefined && !loading ? (
					<TableHead className={classes[tableHeaderColor + 'TableHeader']}>
						<TableRow>
							{tableHead.map((prop, key) => {
								const cellWidth = (100 / tableHead.length) * 10 // calculate minWidth for each column

								return (
									<TableCell
										// style={{minWidth: cellWidth}}
										className={tableCellClass + ' ' + classes.tableHeadCell}
										key={key}
									>
										{prop}
									</TableCell>
								)
							})}
						</TableRow>
					</TableHead>
				) : null}
				<TableBody>
					{loading ? (
						<Loader wrapperClassName="table-loader" load={loading} />
					) : (
						tableData.map((prop, key) => {
							return (
								<TableRow key={key}>
									{prop.map((prop, key) => {
										return (
											<TableCell className={tableCellClass} key={key}>
												{prop}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})
					)}
				</TableBody>
			</Table>
		</div>
	)
}

CustomTable.defaultProps = {
	tableHeaderColor: 'gray'
}

CustomTable.propTypes = {
	classes: PropTypes.object.isRequired,
	tableHeaderColor: PropTypes.oneOf([
		'warning',
		'primary',
		'danger',
		'success',
		'info',
		'rose',
		'gray'
	]),
	tableHead: PropTypes.arrayOf(
		PropTypes.oneOfType([PropTypes.string, PropTypes.object])
	),
	tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node))
}

export default memo(withStyles(tableStyle)(CustomTable))
