import {
  HANDLE_NOTIFICATION,
  NEW_ITEM_ADD_FAILED,
  NEW_ITEM_ADD_CALLED
} from '../../../global actions/types'
import { SHOW_NOTIFICATION } from '../../../Services/Strings'

export const setupAdminPage = (
  dispatch,
  networkCall,
  callArgs,
  successType,
  errorType,
  pageNumber
) => {
  networkCall(...callArgs)
    .then(response => {
      dispatch({
        type: successType,
        payload: { data: response.data, pageNumber }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({ type: errorType })
    })
}

export const selectItemToEdit = (
  dispatch,
  itemId,
  items,
  networkCall,
  callArgs,
  successType,
  errorType,
  itemType,
  findItemByIndex
) => {
  let selectedItem
  if (findItemByIndex) {
    selectedItem = items.find(item => item[findItemByIndex] == itemId)
  } else {
    selectedItem = items.find(item => item.id == itemId)
  }
  if (!selectedItem) {
    // happens when reaching edit page not from the table page (refreshing or via url)
    networkCall(...callArgs)
      .then(response => {
        dispatch({
          type: successType,
          payload: response.data[itemType]
        })
      })
      .catch(error => {
        console.log(error)
        dispatch({ type: errorType })
      })
  } else {
    dispatch({
      type: successType,
      payload: selectedItem
    })
  }
}

export const updateItem = (
  dispatch,
  updateItemNetworkCall,
  data,
  successType,
  itemType,
  successNotificationMessage,
  errorNotificationMessage
) => {
  updateItemNetworkCall(data)
    .then(response => {
      dispatch({
        type: successType,
        payload: itemType ? response.data[itemType] : response.data
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.SUCCESS,
          notificationMessage: successNotificationMessage
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage:
            error.validationMessage || errorNotificationMessage
        }
      })
    })
}

export const createNewItem = (
  dispatch,
  createNewItemNetworkCall,
  item,
  successType,
  itemType,
  successNotificationMessage,
  errorNotificationMessage
) => {
  dispatch({
    type: NEW_ITEM_ADD_CALLED
  })
  createNewItemNetworkCall(item)
    .then(response => {
      dispatch({
        type: successType,
        payload: response.data[itemType]
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.SUCCESS,
          notificationMessage: `${successNotificationMessage} id: ${response.data[itemType].id}`
        }
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({
        type: NEW_ITEM_ADD_FAILED
      })
      dispatch({
        type: HANDLE_NOTIFICATION,
        payload: {
          notificationType: SHOW_NOTIFICATION.ERROR,
          notificationMessage:
            error.validationMessage || errorNotificationMessage
        }
      })
    })
}

export const deleteItem = (
  dispatch,
  deleteItemNetworkCall,
  id,
  successType,
  errorType
) => {
  deleteItemNetworkCall(id)
    .then(response => {
      dispatch({
        type: successType,
        payload: id
      })
    })
    .catch(error => {
      console.log(error)
      dispatch({ type: errorType })
    })
}
