import React, { Component, ReactElement } from 'react'

import { Button, CustomInput } from '../thirdParty/CreativeTim/components'
import { ENTER_KEY_CODE } from '../../../Services/Constants'
import IconFactory from '../StarIcon/IconFactory'

const CustomInputTSX: any = CustomInput

interface Props {
  searchPhrase: string
  setSearchPhrase: Function
  placeholder?: string
}
interface State {
  searchPhrase: string
}

export default class CastorServerSearchBar extends Component<Props, State> {
  state: State
  props: Props
  constructor(props: Props) {
    super(props)
    this.state = {
      searchPhrase: props.searchPhrase
    }
    this.props = props
  }

  onSearchclick = () => {
    this.props.setSearchPhrase(this.state.searchPhrase)
  }

  onSearchChange = (e: any) => {
    this.setState({ searchPhrase: e.target.value })
    if (e.keyCode === ENTER_KEY_CODE) {
      this.onSearchclick()
    }
  }

  render() {
    return (
      <div>
        <CustomInputTSX
          formControlProps={{
            style: { margin: 'unset' }
          }}
          inputProps={{
            placeholder: this.props.placeholder || 'Search by name',
            inputProps: {
              'aria-label': 'Search'
            },
            style: { margin: 'unset' },
            defaultValue: this.state.searchPhrase,
            onKeyUp: (event: any) => this.onSearchChange(event)
          }}
        />
        <Button
          onClick={this.onSearchclick}
          color="white"
          aria-label="search-button"
          justIcon
          round
        >
          <IconFactory iconName="search" />
        </Button>
      </div>
    )
  }
}
