import React, { FC, memo } from 'react'

import CastorFormHeader from '../../../../Components/CastorForm/CastorFormHeader'
import CustomizePrinterPropsInputs from './CustomizePrinterPropsInputs'
import { getString } from '../../../../../Services/Strings/StringService'
import CustomizePrinterPropsTable from './CustomizePrinterPropsTable'
import WithFeatureToggleHOC from '../../../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../../../Services/models/Features'

import './CustomizePrinterProps.scss'

interface IProps {}

const CustomizePrinterProps: FC<IProps> = ({}) => {
	return (
		<div>
			<CastorFormHeader
				explanationHeader={getString('PRINTER_COSTS')}
				explanationArray={[getString('PRINTER_COSTS_SUBTITLE')]}
				isInCard={true}
			/>
			<div className="customize-printer-props--content">
				<CustomizePrinterPropsInputs />
			</div>
			<CustomizePrinterPropsTable />
		</div>
	)
}

export default WithFeatureToggleHOC(
	memo(CustomizePrinterProps),
	FeatureComponentId.USER_PRINTER_PROPS
)
