export interface IUserFilter {
	id?: string
	name: string
	on: boolean
	value?: any
	user?: number
}

export enum IUserFilterNames {
	foodSafe = 'foodSafe',
	bioCompatible = 'bioCompatible',
	corrosionResistant = 'corrosionResistant',
	accuracy = 'accuracy',
	rohs = 'rohs',
	esd = 'esd',
	leadTime = 'leadTime',
	elongation = 'elongation',
	printerTechnology = 'printerTechnology',
	youngsModulus = 'youngsModulus',
	thermalConductivity = 'thermalConductivity',
	surfaceFinish = 'surfaceFinish',
	flameRetardant = 'flameRetardant',
	inHousePrinters = 'inHousePrinters',
	cost = 'cost',
	euReach = 'euReach',
	serviceTemperature = 'serviceTemperature',
	strength = 'strength',
	sameMaterial = 'sameMaterial',
	fst = 'fst',
	skinContact = 'skinContact',
	ultraLowOutgassing = 'ultraLowOutgassing'
}

export interface IUserFilterToggles {
	printerTechnology: boolean
	inHousePrinters: boolean
	sameMaterial: boolean
	flameRetardant: boolean
	euReach: boolean
	rohs: boolean
	fst: boolean
	skinContact: boolean
	ultraLowOutgassing: boolean
	cost: boolean
	esd: boolean
	leadTime: boolean
	strength: boolean
	youngsModulus: boolean
	elongation: boolean
	serviceTemperature: boolean
	thermalConductivity: boolean
	accuracy: boolean
	surfaceFinish: boolean
	bioCompatible: boolean
	corrosionResistant: boolean
	foodSafe: boolean
}
