import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import cx from 'classnames'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import Flexbox from 'Scenes/Components/FlexBox'

import * as MaterialSelectorActions from './MaterialSelectorActions'
import { materialTypes } from '../../../Services/Constants'
import {
	SELECT_MATERIAL_CATEGORY_PLACEHOLDER,
	SELECT_MATERIAL_PLACEHOLDER,
	SELECT_MATERIAL_TYPE_PLACEHOLDER
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import CastorSelectBox from '../CastorSelectBox'
import TransparentButton from '../TransparentButton'

import './MaterialSelector.scss'

class MaterialSelector extends Component {
	constructor(props) {
		super(props)
		this.state = {
			hideMaterialNameSelector: props.hideMaterialNameSelector
		}
	}

	componentWillMount() {
		const {
			setup,
			materials,
			onChange,
			defaultMaterialForTypeArr,
			defaultMaterial,
			materialCategories,
			hideMaterialNameSelector
		} = this.props
		this.setState({ hideMaterialNameSelector })
		setup(
			materials,
			onChange,
			defaultMaterial,
			defaultMaterialForTypeArr,
			materialCategories
		)
	}

	componentDidUpdate(prevProps) {
		const {
			material,
			setup,
			materials,
			onChange,
			defaultMaterialForTypeArr,
			defaultMaterial,
			materialCategories
		} = this.props
		if (prevProps.material !== material) {
			onChange(material)
		}
		if (prevProps.materials.length !== materials.length) {
			setup(
				materials,
				onChange,
				defaultMaterial,
				defaultMaterialForTypeArr,
				materialCategories
			)
		}
	}

	materialChanged = event => {
		this.props.materialChanged(event.target.value)
	}

	materialTypeChanged = event => {
		this.props.materialTypeChanged(event.target.value)
	}

	materialCategoryChanged = event => {
		this.props.materialCategoryChanged(event.target.value)
	}

	renderSelectField = (
		className,
		value,
		onChange,
		disabledMenuItem,
		menuItems,
		rendredMenuItems,
		label,
		qaData,
		disableField
	) => {
		const {
			disabled,
			selectClassName = '',
			isButtonSelector,
			rootSelectClassName = ''
		} = this.props
		return (
			<div data-qa={qaData}>
				{isButtonSelector && <div className="label">{label}</div>}
				<Select
					title={value}
					disabled={disabled || disableField}
					className={className}
					value={value || ''}
					renderValue={() => (disableField ? getString('SELECT') : value || '')}
					onChange={onChange}
					classes={{
						select: selectClassName,
						root: rootSelectClassName
					}}
					inputProps={{
						name: 'simpleSelect',
						id: 'simple-select',
						'data-qa': `${qaData}${value}`
					}}
					MenuProps={{
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					<MenuItem disabled>{disabledMenuItem}</MenuItem>
					{rendredMenuItems
						? rendredMenuItems
						: menuItems.map(menuItem => {
								return (
									<MenuItem
										key={menuItem}
										style={{ textTransform: 'capitalize' }}
										value={menuItem}
									>
										{menuItem}
									</MenuItem>
								)
						  })}
				</Select>
			</div>
		)
	}

	renderButtonField = (value, menuItems, qaDataElementNameType) => {
		return (
			<div>
				<div className="label">{getString('ORIGINAL_MATERIAL_TYPE')}</div>
				<Flexbox>
					{menuItems.map(menuItem => {
						const selected = menuItem === value

						return (
							<CastorSelectBox
								key={menuItem}
								setSelectedType={() => {
									this.props.materialTypeChanged(menuItem)
								}}
								selected={selected}
								boxClassName="material-selector--box"
								qaDataElementName={`${qaDataElementNameType}${menuItem}`}
							>
								{menuItem}
							</CastorSelectBox>
						)
					})}
				</Flexbox>
			</div>
		)
	}

	renderCategorySelector = () => {
		const {
			showCategorySelector,
			materialCategory,
			materialCategoriesArr,
			isButtonSelector,
			qaDataElementNameCategory,
			disableMaterialCategory
		} = this.props
		const selectClassName = isButtonSelector
			? 'field-with-label outlined'
			: 'material-selector material-middle-selector'

		if (!showCategorySelector) {
			return <div />
		}

		return this.renderSelectField(
			`${selectClassName} material-category`,
			materialCategory,
			this.materialCategoryChanged,
			SELECT_MATERIAL_CATEGORY_PLACEHOLDER,
			materialCategoriesArr,
			undefined,
			getString('ORIGINAL_MATERIAL_CATEGORY'),
			qaDataElementNameCategory,
			disableMaterialCategory
		)
	}

	renderMaterialMenuItems = materialArr => {
		return materialArr.map(material => {
			return (
				<MenuItem key={material.id} value={material.name}>
					{material.name}
				</MenuItem>
			)
		})
	}

	renderNameFieldWithoutSelector = label => {
		const { material, disabled, isButtonSelector } = this.props
		return (
			<div>
				{isButtonSelector && <div className="label">{label}</div>}
				<div className="flex outlined specified">
					<div
						title={material.name}
						className="material-selector--no-select-field"
					>
						{material.name}
					</div>
					<TransparentButton
						disabled={disabled}
						onClick={() => this.setState({ hideMaterialNameSelector: false })}
					>
						{getString('SPECIFY_MATERIAL')}
					</TransparentButton>
				</div>
			</div>
		)
	}

	renderNameSelector = () => {
		const {
			material,
			materialArr,
			isButtonSelector,
			qaDataElementNameMaterial,
			disableOriginalMaterial
		} = this.props

		if (
			this.state.hideMaterialNameSelector &&
			material.type !== materialTypes.plastic
		) {
			return this.renderNameFieldWithoutSelector(
				getString('ORIGINAL_MATERIALS_BUTTON')
			)
		}
		const selectClassName = isButtonSelector
			? 'field-with-label outlined'
			: 'material-selector material-last-selector'

		return this.renderSelectField(
			selectClassName,
			material.name,
			this.materialChanged,
			SELECT_MATERIAL_PLACEHOLDER,
			materialArr,
			this.renderMaterialMenuItems(materialArr),
			getString('ORIGINAL_MATERIALS_BUTTON'),
			qaDataElementNameMaterial,
			disableOriginalMaterial
		)
	}

	render() {
		const {
			materialType,
			materialTypesArr,
			isButtonSelector,
			qaDataElementNameType,
			qaDataElementNameCategory,
			qaDataElementNameMaterial
		} = this.props
		return (
			<div
				className={cx('material-selctors-div', {
					'material-selctors-div__buttons': isButtonSelector
				})}
			>
				{isButtonSelector
					? this.renderButtonField(
							materialType,
							materialTypesArr,
							qaDataElementNameType
					  )
					: this.renderSelectField(
							'material-selector',
							materialType,
							this.materialTypeChanged,
							SELECT_MATERIAL_TYPE_PLACEHOLDER,
							materialTypesArr,
							isButtonSelector
					  )}
				<div className="flex">
					{this.renderCategorySelector()}
					{this.renderNameSelector()}
				</div>
			</div>
		)
	}
}

const mapStateToProps = ({ MaterialSelectorReducer, user }) => {
	const {
		material,
		materialArr,
		materialTypesArr,
		materialType,
		materialCategory,
		materialCategoriesArr,
		showCategorySelector,
		materialCategories: currentMaterialCategories
	} = MaterialSelectorReducer
	const { materials, materialCategories } = user

	return {
		material,
		materialArr,
		materials,
		materialTypesArr,
		materialType,
		materialCategory,
		materialCategoriesArr,
		showCategorySelector,
		materialCategories,
		currentMaterialCategories
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...MaterialSelectorActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialSelector)
