import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo, useEffect } from 'react'
// @ts-ignore
import Fade from 'react-reveal/Fade'
import { Element, scroller } from 'react-scroll'

import { pickBy, size } from 'lodash'

import SolutionConfigurePrioritizeButtons from './SolutionConfigurePrioritizeButtons'
import { ActionWithPayload } from 'global actions/ActionModels'
import CastorSlider from 'Scenes/Components/CastorSlider'
import InfoBox from 'Scenes/Components/InfoBox'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import usePrevious from 'Services/CustomHooks/usePrevious'
import { IPriority } from 'Services/models/IPriority'
import {
	PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE,
	PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE_HOVER
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../SolutionAnalysis.scss'
import './SolutionConfigure.scss'

const Elm: any = Element

interface IProps {
	className: string
	configurationId: number
	priorities: IPriority[]
	solutionPriorities: Map<string, number>
	isNewDesign?: boolean
	hidePropertyButton?: boolean
	headerText?: string
	showSolutionPriorities: any
	onPrioritiesFeatureClick: Function
	onPriorityRemove: (
		id: number,
		priority: IPriority,
		solutionPriorities: any
	) => ActionWithPayload<any>
	onPrioritizeFieldChange: (
		id: number,
		priorityName: string,
		priorityValue: number,
		solutionPriorities: Map<string, number>
	) => ActionWithPayload<any>
}

const onPriorityChange = (
	onPrioritizeFieldChange: (
		id: number,
		priorityName: string,
		priorityValue: number,
		solutionPriorities: Map<string, number>
	) => ActionWithPayload<any>,
	configurationId: number,
	priorityName: string,
	priorityValue: number,
	solutionPriorities: Map<string, number>
) => {
	onPrioritizeFieldChange(
		configurationId,
		priorityName,
		priorityValue,
		solutionPriorities
	)
}

const renderRemoveButton = (
	configurationId: number,
	priority: IPriority,
	solutionPriorities: any,
	onPriorityRemove: (
		id: number,
		priority: IPriority,
		solutionPriorities: any
	) => ActionWithPayload<any>
) => {
	if (priority.mandatory) {
		return <div />
	}
	return (
		<Button
			size="xs"
			justIcon
			color="transparent"
			onClick={() =>
				onPriorityRemove(configurationId, priority, solutionPriorities)
			}
			className="solution_configure_prioritize__romeve_button"
			title="remove"
		>
			<IconFactory
				iconName="clear"
				className="solution_configure_prioritize__remove-icon"
			/>
		</Button>
	)
}

const renderPriorities = (
	priority: IPriority,
	solutionPriorities: Map<string, number>,
	showSolutionPriorities: any,
	configurationId: number,
	onPriorityRemove: (
		id: number,
		priority: IPriority,
		solutionPriorities: any
	) => ActionWithPayload<any>,
	onPrioritizeFieldChange: (
		id: number,
		priorityName: string,
		priorityValue: number,
		solutionPriorities: Map<string, number>
	) => ActionWithPayload<any>,
	index: number
) => {
	if (showSolutionPriorities[priority.name]) {
		return (
			<div
				className="solution_configure_prioritize__field-wrapper"
				key={priority.id}
			>
				<Fade bottom force={index < 2}>
					<Flexbox
						flexDirection="column"
						alignItems="flex-start"
						width="100%"
						margin={index !== 0 ? '28px 0 0' : '0'}
					>
						<Flexbox width="100%" justifyContent="space-between">
							<p className="solution_configure_prioritize__field_title">
								{getString(priority.stringKey)}
							</p>
							{renderRemoveButton(
								configurationId,
								priority,
								solutionPriorities,
								onPriorityRemove
							)}
						</Flexbox>

						<CastorSlider
							defaultValue={solutionPriorities.get(priority.name)}
							onChange={(value: number) =>
								onPriorityChange(
									onPrioritizeFieldChange,
									configurationId,
									priority.name,
									value,
									solutionPriorities
								)
							}
							marks={{ 1: 1, 3: 3, 5: 5 }}
							step={1}
							min={1}
							max={5}
							range={{ min: 1, max: 5 }}
							value={solutionPriorities.get(priority.name)}
							dots={true}
							// activeDotStyle={{ backgroundColor: theme.colors.successColor }}
						/>
					</Flexbox>
				</Fade>
			</div>
		)
	}
}

const SolutionConfigurePrioritizeForm: FC<IProps> = ({
	className,
	configurationId,
	onPrioritizeFieldChange,
	priorities,
	solutionPriorities,
	showSolutionPriorities,
	onPrioritiesFeatureClick,
	onPriorityRemove,
	hidePropertyButton,
	headerText,
	isNewDesign
}) => {
	const prevShowSolutionPriorities = usePrevious(showSolutionPriorities) || {}
	useEffect(() => {
		const truePriorities = pickBy(showSolutionPriorities, value => value)
		const prevTrueShowSolutionPriorities = pickBy(
			prevShowSolutionPriorities,
			value => value
		)
		if (
			size(prevTrueShowSolutionPriorities) &&
			size(truePriorities) > size(prevTrueShowSolutionPriorities)
		) {
			scroller.scrollTo('scrollToBottom', {
				duration: 300,
				smooth: true,
				containerId: `priorityElementID_${configurationId}`
			})
		}
	}, [showSolutionPriorities])

	return (
		<div
			id={`part-analysis-material-properties-importance_${configurationId}`}
			className={className}
		>
			<Flexbox
				flexDirection="column"
				alignItems="center"
				width="100%"
				height="100%"
			>
				<Flexbox
					className={'solution-analysis-object-content-configure-top-header'}
					alignItems="baseline"
					justifyContent="space-between"
					width="75%"
					marginBottom="28px"
				>
					<div className="title-wrapper start">
						<div className="solution-analysis-object-content-configure-widget-title--with-info">
							<div className="solution-analysis-object-content-configure-widget-title">
								{headerText || PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE}
							</div>
							<InfoBox
								boxContactElement={
									PART_ANALYSIS_CONFIGURE_PRIORITIZE_TITLE_HOVER
								}
								boxDirection="bottom"
								boxClassName="solution-analysis-object-content-configure-widget--popper"
								boxContactClassName="solution-analysis-object-content-configure-widget--popper--data"
							/>
						</div>
						{!hidePropertyButton && isNewDesign && (
							<p>{getString('LOW_HIGH_PROPERTIES')}</p>
						)}
					</div>
					{!hidePropertyButton && (
						<SolutionConfigurePrioritizeButtons
							configurationId={configurationId}
							onPrioritiesFeatureClick={onPrioritiesFeatureClick}
							isNewDesign={isNewDesign}
						/>
					)}
				</Flexbox>
				<div
					className="solution-analysis-object-content-configure-widget--inputs"
					id={`priorityElementID_${configurationId}`}
				>
					<Flexbox flexDirection="column" alignItems="flex-start" width="75%">
						{priorities &&
							priorities.map((priority, index) =>
								renderPriorities(
									priority,
									solutionPriorities,
									showSolutionPriorities,
									configurationId,
									onPriorityRemove,
									onPrioritizeFieldChange,
									index
								)
							)}
					</Flexbox>
					<Elm name="scrollToBottom"></Elm>
				</div>
			</Flexbox>
		</div>
	)
}

export default memo(SolutionConfigurePrioritizeForm)
