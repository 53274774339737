import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { every, uniqBy } from 'lodash'

import {
	CONFIGURATION_CALCULATED,
	DO_REFRESH_CONFIGURATIONS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION
} from 'global actions/types'
import { checkReanalysisProjectsPoller } from 'global actions/UserActions'
import { store } from 'index'
import { ReanalyzingStatus } from 'Scenes/Components/thirdParty/CreativeTim/components/Sidebar/SideBarTypes'
import { SelectProject } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsInterface'
import { prepareDataToSelect } from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsService'
import {
	CHANGE_ALL_PROJECTS_DATA,
	CHANGE_PROJECTS_DATA,
	CLEAR_ALL_PROJECTS_DATA,
	GET_ALL_PROJECTS_DATA,
	UPDATE_PROJECTS_DATA,
	UPDATE_PROJECTS_DATA_ERROR,
	UPDATE_PROJECTS_DATA_SUCCESS
} from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsTypes'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { ProjectStatus } from 'Services/models/IProject'
import { calculateProjects, getProjects } from 'Services/Network'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const updateProjectsData = (projects: Array<string>) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		dispatch({ type: UPDATE_PROJECTS_DATA })
		const query = projects.map(project => {
			return { projectId: project }
		})
		const { features, maxAllowedUploadProjects } = store.getState().user

		try {
			await calculateProjects({
				query,
				toCheckDaytimeRange: false
			})

			try {
				store.dispatch(getProjectsData())
				const response = await getProjects()
				const projects = response?.data?.projects
				if (projects && projects[0]?.owner) {
					store.dispatch(checkReanalysisProjectsPoller(projects[0]?.owner))
					dispatch({
						type: GET_PROJECTS_SUCCESS,
						payload: { projects, features, maxAllowedUploadProjects }
					})
					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: getString(
								'RECALCULATE_PROJECTS_NOTIFICATION'
							)
						}
					})
				}
			} catch (error: any) {
				dispatch({ type: GET_PROJECTS_FAILED, payload: error.message })
			}

			dispatch({ type: UPDATE_PROJECTS_DATA_SUCCESS })
			dispatch({
				type: CONFIGURATION_CALCULATED
			})
			dispatch({
				type: DO_REFRESH_CONFIGURATIONS,
				payload: { doRefreshConfigurations: true }
			})
		} catch (err) {
			dispatch({ type: UPDATE_PROJECTS_DATA_ERROR })
			console.error(err)
		}
	}
}

export const toggleProjectsData = (
	selected: Array<string>,
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = selected.some(
				(selectedId: string) => selectedId === project.id
			)
			return project
		})

		dispatch({
			type: CHANGE_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}
export const toggleAllProjectsData = (
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const isSelected = every(preparedProjects, { selected: true })
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = !isSelected
			return project
		})

		dispatch({
			type: CHANGE_ALL_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}

export const toggleOnlyProjectsData = (
	id: string,
	preparedProjects: Array<SelectProject>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		let updated = preparedProjects?.map((project: SelectProject) => {
			project.selected = project.id === id
			return project
		})

		dispatch({
			type: CHANGE_PROJECTS_DATA,
			payload: {
				projectData: updated
			}
		})
	}
}

export const getProjectsData = () => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			let response = await getProjects()

			let projects = response?.data?.projects
				.filter((project: any) =>
					[
						ProjectStatus.published,
						ProjectStatus.complete,
						ProjectStatus.failed
					].includes(project.status)
				)
				.filter(
					(project: any) =>
						project.reanalyzingStatus === ReanalyzingStatus.complete
				)

			if (
				Feature.isFeatureOn(FeatureComponentId.PROJECT_BUNDLE_PAGE) &&
				projects
			) {
				projects = projects.map((project: any) => ({
					id: project.bundle ? project.bundle.id : project.id,
					name: project.bundle ? project.bundle.name : project.name
				}))
				projects = uniqBy(projects, 'id')
			}

			dispatch({
				type: GET_ALL_PROJECTS_DATA,
				payload: {
					projectData: prepareDataToSelect(projects)
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err) {
			console.error(err)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		}
	}
}

export const clearProjectsData = () => {
	return (dispatch: Dispatch<AnyAction>) => {
		dispatch({
			type: CLEAR_ALL_PROJECTS_DATA
		})
	}
}
