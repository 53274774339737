import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import { isNil } from 'lodash'

import {
	INVALID_FUNCTION_ERROR_CODE,
	PARAM_EXISTS_ERROR_CODE
} from './constants'
import {
	customVariableIsInUse,
	functionForTechnologyExists,
	getFunctionStringFromArray,
	getUserReadableFunctionCategoryName,
	getUserReadableFunctionString
} from './CostingFunctionEditorService'
import {
	CostingFunctionSteps,
	FunctionCategories,
	ICustomFunction,
	IFunctionCategory,
	IFunctionVariable,
	IFunctionVariableExtended,
	VariableType
} from './CustomizeCostingFunctionTypes'
import {
	ADD_VARIABLE_TO_FUNCTION,
	CLEAR_CUSTOM_FUNCTION,
	CUSTOM_FUNCTION_FAIL,
	CUSTOM_FUNCTION_SUCCESS,
	DELETE_CUSTOM_FUNCTION,
	DELETE_CUSTOM_VARIABLE,
	DELETE_VARIABLE_FROM_FUNCTION,
	ERROR_ADD_CUSTOM_VARIABLE,
	ERROR_FETCHING_VARIABLES,
	FUNCTION_TYPE_SELECTED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	ROLL_BACK_TO_FIRST_STEP,
	SETUP_COSTING_FUNCTION_EDITOR,
	START_ADD_CUSTOM_VARIABLE,
	START_FETCHING_VARIABLES,
	START_SAVING_CUSTOM_FUNCTION,
	SUCCESS_ADD_CUSTOM_VARIABLE,
	TECHNOLOGY_VARIABLES_FETCHED,
	TOGGLE_COSTING_FUNCTION_ACCORDION,
	TOGGLE_FUNCTION_PRINTING_TECHNOLOGY,
	TOGGLE_PARAMETER_MODAL
} from 'global actions/types'
import {
	ALERT_CALCULATION_FINISHED,
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import {
	IFunctionString,
	OrganizationFunctionString
} from 'Services/models/IFunctionString'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import {
	addFunctionString,
	addFunctionStringParam,
	deleteCustomFunctionString,
	deleteFunctionStringParam,
	getCustomFunctionStrings,
	getFunctionStringParams
} from 'Services/Network'
import { OK, SHOW_NOTIFICATION, YES } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const toggleCostingFunctionAccordion = (step: CostingFunctionSteps) => {
	return {
		type: TOGGLE_COSTING_FUNCTION_ACCORDION,
		payload: { step }
	}
}

export const functionTypeSelected = (
	type: string,
	isStep2ToggleAllowed: boolean,
	functionForCategoryExists: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		if (functionForCategoryExists) {
			return
		}
		if (isStep2ToggleAllowed) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('CHANGING_FUNCTION_TYPE_ALERT_DESCRIPTION').format(
						getString('FUNCTION_SELECT_LABEL')
					),
					headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
					alertType: AlertType.WARNING,
					showCancel: true,
					onConfirm: () => {
						dispatch({ type: ROLL_BACK_TO_FIRST_STEP })
						dispatch({
							type: FUNCTION_TYPE_SELECTED,
							payload: { type }
						})
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: YES
				}
			})
		} else {
			dispatch({
				type: FUNCTION_TYPE_SELECTED,
				payload: { type }
			})
		}
	}
}

export const toggleFunctionPrintingTechnology = (
	selectedPrintingTechnologies: string[],
	customFunctionsList: ICustomFunction[],
	selectedFunctionCategory: string,
	isStep2ToggleAllowed: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const filteredSelectedTechnologies = selectedPrintingTechnologies.filter(
			technology =>
				!functionForTechnologyExists(
					customFunctionsList,
					selectedFunctionCategory,
					technology
				)
		)
		if (isStep2ToggleAllowed) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('CHANGING_FUNCTION_TYPE_ALERT_DESCRIPTION').format(
						getString('PRINTING_TECHNOLOGY_SELECT_LABEL')
					),
					headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
					alertType: AlertType.WARNING,
					showCancel: true,
					onConfirm: () => {
						dispatch({ type: ROLL_BACK_TO_FIRST_STEP })
						dispatch({
							type: TOGGLE_FUNCTION_PRINTING_TECHNOLOGY,
							payload: {
								selectedPrintingTechnologies: filteredSelectedTechnologies
							}
						})
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: YES
				}
			})
		} else {
			dispatch({
				type: TOGGLE_FUNCTION_PRINTING_TECHNOLOGY,
				payload: { selectedPrintingTechnologies: filteredSelectedTechnologies }
			})
		}
	}
}

export const moveToCreateFunction = (
	selectedFunctionCategory: string,
	selectedPrintingTechnologies: string[],
	allPrintingTechnologies: IPrintingTechnology[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({ type: START_FETCHING_VARIABLES })
			const printingTechnologies =
				selectedPrintingTechnologies.length === allPrintingTechnologies.length
					? ''
					: selectedPrintingTechnologies
			const response = await getFunctionStringParams(
				selectedFunctionCategory,
				printingTechnologies
			)
			const functionStringParams: IFunctionStringParam[] =
				response?.data?.functionStringParams

			const variablesList = functionStringParams.reduce(
				(acc: IFunctionVariableExtended[], param) => {
					const functionType = param.organization
						? VariableType.customVariable
						: VariableType.variable
					const userReadableNames = getString(
						'FUNCTION_STRING_PARAMS_USER_READABLE_NAMES'
					)
					const userReadableName =
						userReadableNames[param.name] ||
						param.userReadableName ||
						param.name
					acc.push({
						...param,
						value: (!isNil(param.value)
							? param.value.toString()
							: null) as null,
						type: functionType,
						userReadableName
					})
					return acc
				},
				[]
			)

			dispatch({
				type: TECHNOLOGY_VARIABLES_FETCHED,
				payload: { variablesList }
			})
			dispatch({
				type: TOGGLE_COSTING_FUNCTION_ACCORDION,
				payload: { step: CostingFunctionSteps.CreateFunction }
			})
		} catch (err: any) {
			console.log(err)
			dispatch({ type: ERROR_FETCHING_VARIABLES })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message
				}
			})
		}
	}
}

export const addVariableToFunction = (variable: IFunctionVariable) => {
	return {
		type: ADD_VARIABLE_TO_FUNCTION,
		payload: { variable: { ...variable, id: `${variable.name}-${Date.now()}` } }
	}
}

export const clearCustomFunction = () => {
	return {
		type: CLEAR_CUSTOM_FUNCTION
	}
}

export const deleteVariableFromFunction = (variableId: number) => {
	return {
		type: DELETE_VARIABLE_FROM_FUNCTION,
		payload: { variableId }
	}
}

export const openParameterModal = () => {
	return {
		type: TOGGLE_PARAMETER_MODAL,
		payload: true
	}
}

export const closeParameterModal = () => {
	return {
		type: TOGGLE_PARAMETER_MODAL,
		payload: false
	}
}

export const addCustomVariable = (
	variableName: string,
	variableValue: number,
	setNameError: Dispatch<React.SetStateAction<string>>,
	closeModal: () => void
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({ type: START_ADD_CUSTOM_VARIABLE })

			const response = await addFunctionStringParam(variableName, variableValue)
			const addedVariable: IFunctionStringParam =
				response?.data.addedFunctionStringParam

			const variableToAdd = {
				...addedVariable,
				type: VariableType.customVariable
			}

			dispatch({
				type: SUCCESS_ADD_CUSTOM_VARIABLE,
				payload: { variableToAdd }
			})
			closeModal()
		} catch (err: any) {
			if (err.code === PARAM_EXISTS_ERROR_CODE) {
				setNameError(getString('COSTING_FUNCTION_SET_NEW_PARAMETER_NAME_ERROR'))
			}
			dispatch({ type: ERROR_ADD_CUSTOM_VARIABLE })
		}
	}
}

export const deleteCustomVariable = (
	variable: IFunctionVariableExtended,
	functionsList: ICustomFunction[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		const variableIsInUse = customVariableIsInUse(variable, functionsList)

		if (variableIsInUse) {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('USED_CONSTANT_ALERT_DESCRIPTION'),
					headerTitle: getString('USED_CONSTANT_ALERT_HEADER'),
					alertType: AlertType.WARNING,
					showCancel: false,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} else {
			try {
				const response = await deleteFunctionStringParam(variable.id)
				const deletedParam = response?.data.deletedParam

				dispatch({
					type: DELETE_CUSTOM_VARIABLE,
					payload: { param: deletedParam }
				})
			} catch (err: any) {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: err.message
					}
				})
			}
		}
	}
}

export const saveCustomFunction = (
	customFunction: IFunctionVariable[],
	selectedFunctionCategory: string,
	selectedPrintingTechnologies: string[],
	allPrintingTechnologies: IPrintingTechnology[],
	functionStringParams: IFunctionStringParam[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({ type: START_SAVING_CUSTOM_FUNCTION })
			const functionString = getFunctionStringFromArray(customFunction)

			const printingTechnologies =
				selectedPrintingTechnologies.length ===
					allPrintingTechnologies.length || !selectedPrintingTechnologies.length
					? ''
					: selectedPrintingTechnologies
			const response = await addFunctionString(
				functionString,
				selectedFunctionCategory,
				printingTechnologies
			)

			const addedFunctionString: ICustomFunction =
				response?.data?.addedFunctionString
			dispatch({
				type: CUSTOM_FUNCTION_SUCCESS,
				payload: {
					customFunctionToAdd: {
						...addedFunctionString,
						userReadableName: getUserReadableFunctionCategoryName(
							addedFunctionString?.category
						),
						functionString: getUserReadableFunctionString(
							addedFunctionString.functionString,
							functionStringParams
						),
						technologies:
							addedFunctionString.technologies &&
							addedFunctionString.technologies?.length > 0
								? addedFunctionString.technologies
								: allPrintingTechnologies.map(technology => technology.name)
					}
				}
			})
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: getString('CONSIDER_RECALCULATING_ALERT_BODY'),
					headerTitle: getString('CONSIDER_RECALCULATING_ALERT_HEADER'),
					alertType: AlertType.SUCCESS,
					showCancel: false,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (err: any) {
			console.log(err)
			dispatch({ type: CUSTOM_FUNCTION_FAIL })
			if (err.code === INVALID_FUNCTION_ERROR_CODE) {
				dispatch({
					type: ALERT_POPPED,
					payload: {
						text: getString('INVALID_FUNCTION_ALERT_BODY'),
						headerTitle: getString('INVALID_FUNCTION_ALERT_HEADER'),
						alertType: AlertType.WARNING,
						showCancel: false,
						onConfirm: () => {
							dispatch({
								type: ALERT_POPUP_CANCELED
							})
						},
						confirmText: OK
					}
				})
			} else {
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage: err.message
					}
				})
			}
		}
	}
}

export const setupCostingFunctionEditor = (
	editableFunctionStrings: IFunctionString[],
	allPrintingTechnologies: IPrintingTechnology[]
) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})

			const customFunctionsStrings = await getCustomFunctionStrings()
			const response = await getFunctionStringParams()

			const customFunctionStrings: OrganizationFunctionString[] =
				customFunctionsStrings?.data?.customFunctionStrings
			const functionStringParams: IFunctionStringParam[] =
				response?.data?.functionStringParams

			const customFunctionsList: ICustomFunction[] =
				customFunctionStrings.reduce(
					(acc: ICustomFunction[], functionStringObj) => {
						acc.push({
							...functionStringObj,
							functionString: getUserReadableFunctionString(
								functionStringObj.functionString,
								functionStringParams
							),
							userReadableName: getUserReadableFunctionCategoryName(
								functionStringObj.category
							),
							technologies:
								functionStringObj.technologies &&
								functionStringObj.technologies?.length > 0
									? functionStringObj.technologies
									: allPrintingTechnologies.map(technology => technology.name)
						})
						return acc
					},
					[]
				)

			const functionCategoriesList = editableFunctionStrings.reduce(
				(acc: IFunctionCategory[], functionString) => {
					if (!functionString.type) {
						const categoryName = getUserReadableFunctionCategoryName(
							functionString.category
						)
						acc.push({
							name: functionString.category as FunctionCategories,
							userReadableName: categoryName
						})
					}
					return acc
				},
				[]
			)

			dispatch({
				type: SETUP_COSTING_FUNCTION_EDITOR,
				payload: {
					customFunctionsList,
					functionCategoriesList
				}
			})
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (err: any) {
			console.log(err)
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message
				}
			})
		}
	}
}

export const deleteCustomFunctionAlertOpen = (id: number) => {
	return async (dispatch: any) => {
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('DELETE_FUNCTION_ALERT_DESCRIPTION'),
				headerTitle: getString('CONFIGURATION_CHANGES_WARNING'),
				alertType: AlertType.WARNING,
				showCancel: true,
				onConfirm: () => {
					dispatch({
						type: ALERT_CALCULATION_STARTED
					})
					dispatch(deleteCustomFunction(id))
				},
				confirmText: YES
			}
		})
	}
}

export const deleteCustomFunction = (id: number) => {
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const response = await deleteCustomFunctionString(id)
			const deletedFunctionString: OrganizationFunctionString =
				response?.data.deletedFunctionString
			dispatch({
				type: DELETE_CUSTOM_FUNCTION,
				payload: { id: deletedFunctionString.id }
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		} catch (err: any) {
			console.log(err)
			dispatch({ type: ALERT_CALCULATION_FINISHED })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: err.message
				}
			})
		}
	}
}
