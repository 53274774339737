import { ChangeEvent, FC } from 'react'

import { MenuItem, Select } from '@material-ui/core'

import { getString } from '../../../../../Services/Strings/StringService'
import Flexbox from 'Scenes/Components/FlexBox'

import './../CustomizeCO2Calculation.scss'

interface IProps {
	cycleList: any
	selectedCycle: any
	setSelectedCycle: Function
}

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

const CO2ManufacturingCycleSelector: FC<IProps> = ({
	cycleList,
	selectedCycle,
	setSelectedCycle
}) => {
	const renderMaterialType = (cycleList: any = []) => {
		return Object.keys(cycleList)
			.sort((a: any, b: any) =>
				cycleList[a].label?.localeCompare(cycleList[b].label)
			)
			.map((cycle: any) => {
				return (
					<MenuItemTsx
						className={'material-selector-class menu-item'}
						key={cycleList[cycle].id}
						value={cycleList[cycle].label}
					>
						{cycleList[cycle].label}
					</MenuItemTsx>
				)
			})
	}

	const onChangeMaterial = (value: any) => {
		setSelectedCycle(value)
	}

	const returnSelectTsx = () => {
		return (
			<Flexbox className="cycle-selector">
				<SelectTsx
					className="select"
					value={selectedCycle}
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeMaterial(e.target.value)
					}
					MenuProps={{
						PaperProps: {
							style: {
								transform: 'translate3d(0, 0, 0)'
							}
						}
					}}
				>
					<MenuItemTsx value="0" disabled>
						{getString('TECHNOLOGY_NAME')}
					</MenuItemTsx>
					{renderMaterialType(cycleList)}
				</SelectTsx>
			</Flexbox>
		)
	}

	return <div>{returnSelectTsx()}</div>
}

export default CO2ManufacturingCycleSelector
