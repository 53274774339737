import { FC, useState } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import { FormControlLabel } from '@material-ui/core'

import { getString } from '../../../../Services/Strings/StringService'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import CastorSwitch from '../../../Components/CastorSwitch'
import InfoBox from '../../../Components/InfoBox'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { CANCEL, OK } from 'Services/Strings'
import './CustomizeUserPostProcesses.scss'

const LabelFormControl: any = FormControlLabel

interface IProps {
	supportRemovalMachiningOn: false
	surfaceAreaMachiningOn: false
	disabled: false
	onSupportsRemovalToggleChange: (supportRemovalMachiningOn: boolean) => any
	onSurfaceAreaToggleChange: (surfaceAreaMachiningOn: boolean) => any
}

const CustomizeUserPostProcesses: FC<IProps> = ({
	supportRemovalMachiningOn,
	surfaceAreaMachiningOn,
	disabled,
	onSupportsRemovalToggleChange,
	onSurfaceAreaToggleChange
}) => {
	const isRemovalMachining = Feature.isFeatureOn(
		FeatureComponentId.REMOVAL_MACHINING
	)
	const isSurfaceAreaMachining = Feature.isFeatureOn(
		FeatureComponentId.SURFACE_AREA_MACHINING
	)

	const [showToggleAlert, setShowToggleAlert] = useState(false)

	return Feature.isFeatureOn(
		FeatureComponentId.CUSTOMIZE_USER_POST_PROCESSES
	) ? (
		<div className="solution-feature-post-processes" data-qa="data-qa-post-processes-costs">
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_MACHINING_TITLE')}
				explanationArray={getString('CUSTOMIZE_MACHINING_EXPLANATION_ARRAY')}
				isInCard={true}
			/>
			{isRemovalMachining && (
				<Flexbox className="solution-feature-post-processes--tog">
					<LabelFormControl
						control={
							<CastorSwitch
								disabled={
									!Feature.isFeatureActive(FeatureComponentId.REMOVAL_MACHINING)
								}
								onChange={(e: any) => {
									if (e.target.checked === true) {
										onSupportsRemovalToggleChange(e.target.checked)
									}
									if (e.target.checked === false) {
										setShowToggleAlert(true)
									}
								}}
								checked={supportRemovalMachiningOn}
							/>
						}
						label={getString('POST_PROCESSES_SUPPORT_REMOVAL_SETTINGS_LABEL')}
						data-qa="data-qa-support-removal-machining-toggle"
					/>
					<InfoBox
						boxContact={getString('POST_PROCESSES_MACHINING_INFO')}
						boxContactClassName="solution-feature-post-processes--info"
						boxDirection="right-end"
					/>
				</Flexbox>
			)}
			{
				<CastorAlert
					headerTitle={getString('SUPPORT_REMOVAL_ALERT_HEADER')}
					onCancel={() => {
						setShowToggleAlert(false)
					}}
					show={showToggleAlert}
					onConfirm={() => [
						onSupportsRemovalToggleChange(false),
						setShowToggleAlert(false)
					]}
					cancelOptionalText={CANCEL}
					confirmOptionalText={OK}
					alertType={AlertType.WARNING}
				>
					{getString('SUPPORT_REMOVAL_ALERT_INFORMATION')}
				</CastorAlert>
			}

			{isSurfaceAreaMachining && (
				<Flexbox className="solution-feature-post-processes--tog">
					<LabelFormControl
						control={
							<CastorSwitch
								disabled={disabled}
								onChange={(e: any) =>
									onSurfaceAreaToggleChange(e.target.checked)
								}
								checked={surfaceAreaMachiningOn}
							/>
						}
						label={getString('POST_PROCESSES_SURFACE_AREA_SETTINGS_LABEL')}
						data-qa="data-qa-surface-area-machining-toggle"
					/>
					<InfoBox
						boxContact={getString('POST_PROCESSES_MACHINING_SURFACE_AREA_INFO')}
						boxContactClassName="solution-feature-post-processes--info"
						boxDirection="right-end"
					/>
				</Flexbox>
			)}
		</div>
	) : (
		<div />
	)
}

export default CustomizeUserPostProcesses
