import * as strings from './Strings'

import loaderImage from '../../assets/img/eos-loader.gif'
import bgImage from 'assets/img/authBG.jpg'
import eosLogoImage from 'assets/img/eosLogo.png'

const tabOrder = {
	summary: 3,
	costAnalysis: 4,
	leadTime: 5,
	feaAnalysis: 6,
	geometryAnalysis: 2,
	material: 1,
	cluster: 7
}

const uploadProjectComponents = {
	projectDetails: 1,
	projectType: 0,
	currentMaterial: 2,
	manufacturingType: 3,
	estimatedPart: 4,
	typeOfAM: 5
}

export default {
	logo: eosLogoImage,
	agreementLogo: eosLogoImage,
	showBackButton: false,
	bgImage,
	loaderImage,
	showPoweredBy: true,
	showPDFInformation: false,
	showEffectiveIcon: true,
	skipLoginPage: true,
	actions: {},
	tabTitle: 'EOS',
	colors: {
		primaryColor: '#556679',
		primaryButtonColor: '#E60610',
		disabledButtonColor: '#c8c6c6',
		dropzoneBackground: 'rgb(60, 60, 60)',
		pageBackground: '#e0e0e0',
		loaderBackground: 'rgb(256, 256, 256)',
		passedColor: '#64c882',
		passedFontColor: '#ffffff',
		passedColorOnBackground: '#64c882',
		successColorOnBackground: '#20c0e6',
		failedColorOnBackground: '#f9d82d',
		borderlineColor: '#64c882',
		borderlineStrokeColor: '#f9d82d',
		failedColor: '#f9d82d',
		successColor: '#556679',
		simpleColor: '#999999',
		piePassedGradientStartColor: '#c2edd1',
		piePassedColor: '#64c882',
		pieBorderlineColor: '#c2edd1',
		pieBorderlineStrokeColor: '#f9d82d',
		pieFailedColor: '#f9d82d',
		notSuitableForPrint: '#20c0e6',
		notCostEffectiveColor: '#848484',
		threadColor: '#c9a900',
		pieMissingInformationColor: '#e7e9c8',
		costSavingColor: 'hsla(254, 83%, 72%, 1)',
		blueCardHeaderBackground: '#E60610',
		greenCardHeaderBackground: '#64c882',
		darkGreenCardHeaderBackground: '#57AD71',
		blueCardHeaderTextColor: '#ffffff',
		blueCardHeaderSubTextColor: '#ffffff8c',
		sideBarBackground: '#E9E9EB',
		sideBarBackgroundOpacity: '.93',
		sideBarBackgroundBoxShadow1: 'hsla(187, 100%, 42%, 0.28)',
		sideBarBackgroundBoxShadow2: 'hsla(187, 100%, 42%, 0.2)',
		sideBarItemText: '#111111',
		sideBarItemTextFocused: '#FFFFFF',
		sideBarSVGActiveText: '#ffffff',
		benefitsText: '#7653ed',
		benefitsBackground: '#9f86f2',
		benefitsBackgroundOpac: '#c8baf8',
		pieBenefitsInventory: '#dcd2fb',
		pieBenefitsShipments: '#c8baf8',
		chartLineColor: '#20c0e6',
		chartMethodColor: '#ec7d33',
		chartCrossColor: '#977CF3',
		chartAMColor: '#0ab0ef',
		hoverButtonBorderPrimary: '#E60610',
		hoverButtonBackgroundColor: 'transparent',
		hoverButtonTextColor: '#E60610'
	},
	sideBarImageStyle: {
		top: '35px',
		height: '49px',
		position: 'absolute',
		left: '60px'
	},
	sideBarLogoStyle: {
		padding: '12px 0px 12px 12px',
		margin: '0',
		display: 'block',
		position: 'relative',
		zIndex: '4',
		'&:after': {
			display: 'none'
		}
	},
	customComponents: {},
	strings,
	buttons: {
		fontSize: '17px',
		borderRadius: '8px',
		secondaryColor: 'transparent',
		infoColor: '#20c0e6',
		infoFontColor: '#ffffff',
		primaryBoxShadow: 'none',
		primaryBoxShadowHover: 'none',
		secondaryBoxShadow: 'none',
		secondaryBoxShadowHover: 'none',
		infoBoxShadow:
			'0 6px 10px -10px rgb(0, 0, 0, 0.3), 0 4px 10px 0px rgb(0, 0, 0, 0.12), 0 3px 4px -5px rgb(0, 0, 0, 0.2)',
		infoBoxShadowHover:
			'0 14px 26px -12px rgba(0, 188, 212, 0.42), 0 4px 23px 0px rgba(0, 0,' +
			' 0, 0.12), 0 8px 10px -5px rgba(0, 188, 212, 0.2)',
		primaryButtonHoverDecoration: 'underline',
		tableButtonColor: 'primary'
	},
	tabOrder: tabOrder,
	disableUploadMaterialTitle: true,
	tabOrderActive: {
		activeTab: tabOrder.material,
		unprintableTab: tabOrder.geometryAnalysis,
		defaultTab: tabOrder.costAnalysis,
		mechanicalTab: tabOrder.feaAnalysis,
		summaryTab: tabOrder.summary
	},
	lightUserAlert: {
		showHeaderText: false,
		headerWithLogo: true,
		withLogo: false,
		showTerms: true,
		showCancel: false,
		showUpload: false
	},
	defaultMaterial: {
		name: 'Nylon 101',
		type: 'plastic',
		id: 117,
		category: 'Rigid Plastics'
	},
	costAnalysisTextOffset: '140px',
	chartHeadFontSize: '14px',
	cardBoxClass: 'card-in-row',
	uploadBoxClass: 'multiple-params',
	cardBoxColor: 'grayRed',
	preSelectedType: '2',
	uploadProjectComponents,
	defaultObjectiveValue: 'basic'
}
