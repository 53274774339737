import { FC, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import * as CustomizeActions from '../CustomizeActions'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AddMaterialNamesMapping from './AddMaterialNamesMapping'
import MaterialNamesMappingList from './MaterialNamesMappingList'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Material } from 'Services/models/IMaterial'
import { IUserMaterialNamesMapping } from 'Services/models/IMaterialNamesMapping'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './CustomizeMaterialNamesMapping.scss'

const { defaultMaterial } = getTheme()

interface IProps {
	userMaterialNamesMapping: IUserMaterialNamesMapping[]
	defaultUploadProjectMaterialType: any
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	disableMaterialNameMappingSaveAll: boolean
	doMaterialNameMappingRefresh: boolean
	isLoadingMaterial?: boolean
	fetchUserMaterialNameMapping: Function
	onCreateUserMaterialNameMapping: Function
	onUpdateMaterialNameMapping: Function
	onRemoveUserMaterialNameMapping: Function
	onChangeSelectedMaterial: Function
}

interface IReduxStore {
	user: any
}

const CustomizeMaterialNamesMapping: FC<IProps> = ({
	userMaterialNamesMapping,
	disableMaterialNameMappingSaveAll,
	isLoadingMaterial,
	materialCategories,
	materialTypes,
	materials,
	doMaterialNameMappingRefresh,
	fetchUserMaterialNameMapping,
	onCreateUserMaterialNameMapping,
	onUpdateMaterialNameMapping,
	onRemoveUserMaterialNameMapping,
	onChangeSelectedMaterial
}) => {
	useEffect(() => {
		fetchUserMaterialNameMapping()
	}, [doMaterialNameMappingRefresh])

	const renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	const setMaterialNamesMappingContent = () => {
		return (
			<>
				<CastorFormHeader
					explanationHeader={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE'
					)}
					explanationArray={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{/* Default materials mappings list */}
				{Feature.isFeatureOn(FeatureComponentId.COMBINE_3D_2D) && (
					<MaterialNamesMappingList
						title={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_TITLE')}
						explanation={getString(
							'CUSTOMIZE_MATERIAL_NAMES_MAPPING_DEFAULT_EXPLANATION'
						)}
						disableMaterialNameMappingSaveAll={
							disableMaterialNameMappingSaveAll
						}
						userMaterialNamesMapping={userMaterialNamesMapping.filter(
							materialName => materialName.defaultFormatType
						)}
						isLoadingMaterial={isLoadingMaterial}
						materialCategories={materialCategories}
						materialTypes={materialTypes}
						materials={materials}
						isActive={true}
						allowRemove={false}
						updateMaterialNameMapping={onUpdateMaterialNameMapping}
						removeUserMaterialNameMapping={onRemoveUserMaterialNameMapping}
						changeSelectedMaterial={onChangeSelectedMaterial}
					/>
				)}
				<AddMaterialNamesMapping
					defaultMaterial={defaultMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					disabled={false}
					updateSelectedMaterial={onCreateUserMaterialNameMapping}
				/>
				<MaterialNamesMappingList
					title={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_BY_LIST_TITLE'
					)}
					explanation={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_MATERIAL_EXPLANATION'
					)}
					disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
					userMaterialNamesMapping={userMaterialNamesMapping.filter(
						materialName => !materialName.defaultFormatType
					)}
					isLoadingMaterial={isLoadingMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					updateMaterialNameMapping={onUpdateMaterialNameMapping}
					removeUserMaterialNameMapping={onRemoveUserMaterialNameMapping}
					changeSelectedMaterial={onChangeSelectedMaterial}
				/>
				<MaterialNamesMappingList
					title={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_TITLE'
					)}
					explanation={getString(
						'CUSTOMIZE_MATERIAL_NAMES_MAPPING_EDIT_MATERIALS_EXPLANATION'
					)}
					disableMaterialNameMappingSaveAll={disableMaterialNameMappingSaveAll}
					userMaterialNamesMapping={userMaterialNamesMapping.filter(
						materialName => !materialName.defaultFormatType
					)}
					isLoadingMaterial={isLoadingMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					isActive={true}
					updateMaterialNameMapping={onUpdateMaterialNameMapping}
					removeUserMaterialNameMapping={onRemoveUserMaterialNameMapping}
				/>
			</>
		)
	}

	return (
		<>
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={renderFormHeader()}
					formTitle={getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE')}
					content={setMaterialNamesMappingContent()}
				/>
			</NavBarAndMaterial>
		</>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		user: {
			userMaterialNamesMapping,
			isLoadingMaterial,
			disableMaterialNameMappingSaveAll,
			materialTypes,
			materials,
			materialCategories,
			doMaterialNameMappingRefresh
		}
	} = state
	return {
		disableMaterialNameMappingSaveAll,
		userMaterialNamesMapping,
		isLoadingMaterial,
		materialTypes,
		materials,
		materialCategories,
		doMaterialNameMappingRefresh
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeMaterialNamesMapping)
