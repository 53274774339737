import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { MenuItem, TextField } from '@material-ui/core'
import Language from '@material-ui/icons/Language'
import LocationCity from '@material-ui/icons/LocationCity'
import Public from '@material-ui/icons/Public'
import classNames from 'classnames'

import WithFeatureToggleHOC from '../../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../../Services/models/Features'
import { getString } from '../../../../Services/Strings/StringService'
import Loader from '../../../Loader/Loader'
import {
	fullCountry,
	fullState,
	makeAddress,
	updatedStates,
	US
} from './addressInfo/countryStateCity'
import { ICountry, IState } from './addressInfo/ICountryStateCity'
import {
	onAddressDropdownSelected,
	onGetAllAddresses
} from './CastorLocationDropdownActions'

import './index.scss'

interface IProps {
	wrapperClass?: string
	withIcons?: boolean
}

const LocationCityTSX: any = LocationCity
const PublicTSX: any = Public
const LanguageTSX: any = Language
const TextFieldTSX: any = TextField
const NULL_VAL = null
const EMPTY_VAL = ''

const getValue = (e: ChangeEvent<HTMLSelectElement>) => e.target.value
const listLength = (elem?: IState[]) => elem && elem.length > 0

const CastorLocationDropdown: FC<IProps> = ({
	wrapperClass,
	withIcons = false
}) => {
	const userInfo = useSelector(
		(state: RootStateOrAny) => state?.CastorLocationSearchReducer
	)
	const { allCountries, allStates, allCities, addressError, isLoadingAddress } =
		useSelector((state: RootStateOrAny) => state?.CastorLocationSearchReducer)
	const dispatch = useDispatch()

	const [country, setCountry] = useState<ICountry | null>(NULL_VAL)
	const [state, setState] = useState<IState | null>(NULL_VAL)
	const [cityString, setCityString] = useState<string>('')

	const statesToCountry = updatedStates(country?.iC, allStates)

	useEffect(() => {
		dispatch(onGetAllAddresses())
	}, [dispatch])

	useEffect(() => {
		const { resCountry, resState } = makeAddress(
			userInfo,
			allCountries,
			allStates
		)
		resCountry && setCountry(resCountry)
		resState && setState(resState)
		setCityString(userInfo?.city)
	}, [userInfo, allCountries, allStates, allCities])

	// prevent updates each time when user is typing (debounce on minimums)
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			dispatch(onAddressDropdownSelected(country, state, cityString))
		}, 500)

		return () => clearTimeout(delayDebounceFn)
	}, [dispatch, country, state, cityString])

	const onChangeDropdownValue = (
		selectedCountry: ICountry | null,
		selectedState: IState | null,
		selectedCityString: string
	) => {
		if (selectedCountry !== country) setCountry(selectedCountry)
		if (selectedState !== state) setState(selectedState)
		if (selectedCityString !== cityString) setCityString(selectedCityString)
	}

	const menuClasses = {
		MenuProps: {
			PopoverClasses: {
				root: 'dropdown'
			}
		}
	}

	const inputCountryIcon = withIcons
		? {
				startAdornment: <PublicTSX />
		  }
		: {}

	const inputStateIcon = withIcons
		? {
				startAdornment: <LanguageTSX />
		  }
		: {}

	const inputCityIcon = withIcons
		? {
				startAdornment: <LocationCityTSX />
		  }
		: {}

	return (
		<div className={classNames('location-block', wrapperClass)}>
			<Loader load={isLoadingAddress} message={''} wrapperClassName="loader" />
			<TextFieldTSX
				InputProps={inputCountryIcon}
				disabled={!listLength(allCountries)}
				InputLabelProps={{
					shrink: !!country?.iC
				}}
				select
				SelectProps={menuClasses}
				className="address-input"
				label={getString('COUNTRY')}
				value={country?.iC || EMPTY_VAL}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					const countryCode = getValue(e)
					const selectedCountry =
						fullCountry(countryCode, allCountries) || NULL_VAL
					onChangeDropdownValue(selectedCountry, NULL_VAL, EMPTY_VAL)
				}}
			>
				<MenuItem disabled>{getString('COUNTRY')}</MenuItem>
				{allCountries?.map(({ n: countryName, iC: countryCode }: ICountry) => (
					<MenuItem value={countryCode} key={countryCode}>
						{countryName}
					</MenuItem>
				))}
			</TextFieldTSX>
			<TextFieldTSX
				select
				InputProps={inputStateIcon}
				error={country?.iC === US && !state}
				InputLabelProps={{
					shrink: !!state?.iC
				}}
				SelectProps={menuClasses}
				className="address-input"
				label={getString('STATE')}
				value={state?.iC || EMPTY_VAL}
				disabled={!listLength(statesToCountry)}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					const stateCode = getValue(e)

					const selectedState =
						fullState(stateCode, allStates, country?.iC) || NULL_VAL
					onChangeDropdownValue(country, selectedState, EMPTY_VAL)
				}}
			>
				<MenuItem disabled>{getString('STATE')}</MenuItem>
				{statesToCountry?.map(({ n: stateName, iC: stateCode }: IState) => (
					<MenuItem value={stateCode} key={stateCode}>
						{stateName}
					</MenuItem>
				))}
			</TextFieldTSX>
			<TextFieldTSX
				InputProps={inputCityIcon}
				InputLabelProps={{
					shrink: !!cityString
				}}
				autoComplete="off"
				className="address-input"
				label={getString('CITY')}
				value={cityString}
				onChange={(e: ChangeEvent<HTMLSelectElement>) => {
					const cityName = getValue(e)
					setCityString(cityName)
				}}
			/>
			{addressError && <p className="error">{getString('ADDRESS_ERROR')}</p>}
		</div>
	)
}

export default memo(
	WithFeatureToggleHOC(
		CastorLocationDropdown,
		FeatureComponentId.OFFLINE_ADDRESS
	)
)
