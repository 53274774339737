import { getDefaultSelectedFunctionCategory } from './CostingFunctionEditorService'
import {
	CostingFunctionSteps,
	ICustomFunction,
	IFunctionCategory,
	IFunctionVariable,
	IFunctionVariableExtended
} from './CustomizeCostingFunctionTypes'
import {
	ADD_VARIABLE_TO_FUNCTION,
	CLEAR_CUSTOM_FUNCTION,
	CUSTOM_FUNCTION_FAIL,
	CUSTOM_FUNCTION_SUCCESS,
	DELETE_CUSTOM_FUNCTION,
	DELETE_CUSTOM_VARIABLE,
	DELETE_VARIABLE_FROM_FUNCTION,
	ERROR_ADD_CUSTOM_VARIABLE,
	ERROR_FETCHING_VARIABLES,
	FUNCTION_TYPE_SELECTED,
	ROLL_BACK_TO_FIRST_STEP,
	SETUP_COSTING_FUNCTION_EDITOR,
	START_ADD_CUSTOM_VARIABLE,
	START_FETCHING_VARIABLES,
	START_SAVING_CUSTOM_FUNCTION,
	SUCCESS_ADD_CUSTOM_VARIABLE,
	TECHNOLOGY_VARIABLES_FETCHED,
	TOGGLE_COSTING_FUNCTION_ACCORDION,
	TOGGLE_FUNCTION_PRINTING_TECHNOLOGY,
	TOGGLE_PARAMETER_MODAL
} from 'global actions/types'
import { IFunctionStringParam } from 'Services/models/IFunctionStringParam'

const INITIAL_STATE = {
	functionCategoriesList: [] as IFunctionCategory[],
	selectedFunctionCategory: '',
	selectedPrintingTechnologies: [],
	variablesList: [] as IFunctionVariableExtended[],
	operatorsList: [] as IFunctionVariable[],
	customFunction: [] as IFunctionVariableExtended[],
	customFunctionsList: [] as ICustomFunction[],
	addCustomVariableLoading: false,
	isLoadingStep1: false,
	isLoadingStep2: false,
	isStep2ToggleAllowed: false,
	stepIsOpen: {
		[CostingFunctionSteps.SelectFunctionAndTechnology]: false,
		[CostingFunctionSteps.CreateFunction]: false,
		[CostingFunctionSteps.ViewAndEdit]: false
	},
	parameterModalIsOpen: false
}

const CostingFunctionEditorReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case TOGGLE_COSTING_FUNCTION_ACCORDION: {
			const { step } = action.payload
			return {
				...state,
				stepIsOpen: {
					...state.stepIsOpen,
					[step]: !state.stepIsOpen[step as CostingFunctionSteps]
				}
			}
		}
		case FUNCTION_TYPE_SELECTED: {
			const { type } = action.payload
			return {
				...state,
				selectedFunctionCategory: type
			}
		}
		case TOGGLE_FUNCTION_PRINTING_TECHNOLOGY: {
			const { selectedPrintingTechnologies } = action.payload
			return {
				...state,
				selectedPrintingTechnologies
			}
		}
		case START_FETCHING_VARIABLES: {
			return {
				...state,
				isLoadingStep1: true
			}
		}
		case ERROR_FETCHING_VARIABLES: {
			return {
				...state,
				isLoadingStep1: false
			}
		}
		case TECHNOLOGY_VARIABLES_FETCHED: {
			const { variablesList } = action.payload
			return {
				...state,
				isLoadingStep1: false,
				isStep2ToggleAllowed: true,
				variablesList
			}
		}
		case ADD_VARIABLE_TO_FUNCTION: {
			const { variable } = action.payload
			return {
				...state,
				customFunction: [...state.customFunction, variable]
			}
		}
		case CLEAR_CUSTOM_FUNCTION: {
			return {
				...state,
				customFunction: []
			}
		}
		case DELETE_VARIABLE_FROM_FUNCTION: {
			const { variableId } = action.payload
			const filteredFunction = state.customFunction.filter(
				variable => variable.id !== variableId
			)
			return {
				...state,
				customFunction: filteredFunction
			}
		}
		case TOGGLE_PARAMETER_MODAL: {
			return {
				...state,
				parameterModalIsOpen: action.payload
			}
		}
		case START_ADD_CUSTOM_VARIABLE: {
			return {
				...state,
				addCustomVariableLoading: true
			}
		}
		case ERROR_ADD_CUSTOM_VARIABLE: {
			return {
				...state,
				addCustomVariableLoading: false
			}
		}
		case SUCCESS_ADD_CUSTOM_VARIABLE: {
			const { variableToAdd } = action.payload
			return {
				...state,
				addCustomVariableLoading: false,
				variablesList: [...state.variablesList, variableToAdd]
			}
		}
		case DELETE_CUSTOM_VARIABLE: {
			const { param } = action.payload as { param: IFunctionStringParam }

			const filteredCustomVariables = state.variablesList.filter(
				variable => variable.id !== param.id
			)
			const filteredFunction = state.customFunction.filter(
				variable => variable.name !== param.name
			)
			return {
				...state,
				variablesList: filteredCustomVariables,
				customFunction: filteredFunction
			}
		}
		case START_SAVING_CUSTOM_FUNCTION: {
			return {
				...state,
				isLoadingStep2: true
			}
		}
		case CUSTOM_FUNCTION_FAIL: {
			return {
				...state,
				isLoadingStep2: false
			}
		}
		case CUSTOM_FUNCTION_SUCCESS: {
			const { customFunctionToAdd } = action.payload
			return {
				...state,
				customFunctionsList: [
					...state.customFunctionsList,
					customFunctionToAdd
				],
				customFunction: [],
				selectedPrintingTechnologies: [],
				selectedFunctionCategory: getDefaultSelectedFunctionCategory(
					state.functionCategoriesList
				),
				isLoadingStep2: false,
				isStep2ToggleAllowed: false,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.CreateFunction]: false,
					[CostingFunctionSteps.ViewAndEdit]: true
				}
			}
		}
		case SETUP_COSTING_FUNCTION_EDITOR: {
			const { customFunctionsList, functionCategoriesList } = action.payload

			return {
				...state,
				customFunctionsList,
				functionCategoriesList,
				selectedFunctionCategory: getDefaultSelectedFunctionCategory(
					functionCategoriesList
				),
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.SelectFunctionAndTechnology]:
						functionCategoriesList.length > 0,
					[CostingFunctionSteps.ViewAndEdit]: customFunctionsList.length > 0
				}
			}
		}
		case DELETE_CUSTOM_FUNCTION: {
			const { id } = action.payload
			const filteredFunctions = state.customFunctionsList.filter(
				customFunction => customFunction.id !== id
			)
			return {
				...state,
				customFunctionsList: filteredFunctions,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.ViewAndEdit]: filteredFunctions.length > 0
				}
			}
		}
		case ROLL_BACK_TO_FIRST_STEP: {
			return {
				...state,
				variablesList: [],
				customFunction: [],
				isStep2ToggleAllowed: false,
				stepIsOpen: {
					...state.stepIsOpen,
					[CostingFunctionSteps.CreateFunction]: false
				}
			}
		}
		default:
			return state
	}
}

export default CostingFunctionEditorReducer
