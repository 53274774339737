import React, { memo, FC } from 'react'

import './DataTable.scss'

interface Props {
  text: string | number
  headerClassName: string
  data: any
}

const DataTableTextHeader: FC<Props> = ({
  text,
  headerClassName,
  data: { className = '' }
}) => (
  <div className={`data-table-header ${headerClassName} ${className}`}>
    {text}
  </div>
)

export default memo(DataTableTextHeader)
