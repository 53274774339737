import React, { memo, useEffect, useState } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import SelectMenu from './SelectMenu'
import { materialTypes } from '../../../Services/Constants'
import TransparentButton from '../TransparentButton'
import { getString } from '../../../Services/Strings/StringService'

import './MaterialsSolutionSelector.scss'

const MenuItemTsx: any = MenuItem
const SelectTsx: any = Select

interface Placeholders {
  selectMaterialPlaceHolder: string
  selectMaterialTypePlaceholder: string
  selectMaterialCategoryPlaceholder: string
}

interface Material {
  name: string
  type: string
  category: string
}

interface IProps extends Placeholders {
  materialValue: any
  materialTypeValue: string
  materialCategoryValue: string
  materialsList: Array<any>
  categoriesList: Array<any>
  typesList: Array<any>
  hideMaterialNameSelector: boolean
  flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  materialNameDisabled: boolean
  materialTypeDisabled: boolean
  materialCategoryDisabled: boolean
  onMaterialsChange: (event: any) => any
  onTypeChange: (event: any) => any
  onCategoryChange: (event: any) => any
  showName: boolean
}

const MaterialsSolutionSelector: React.FC<IProps & any> = ({
  materialValue,
  materialTypeValue,
  materialCategoryValue,
  hideMaterialNameSelector,
  materialsList,
  categoriesList,
  typesList,
  flexDirection,
  materialNameDisabled,
  materialTypeDisabled,
  materialCategoryDisabled,
  onMaterialsChange,
  onTypeChange,
  onCategoryChange,
  showName = false
}: IProps) => {
  const [hiddenMaterialNameSelector, setHiddenMaterialNameSelector] = useState(
    hideMaterialNameSelector
  )

  useEffect(() => {
    setHiddenMaterialNameSelector(hideMaterialNameSelector)
  }, [hideMaterialNameSelector])

  const materialChanged = (event: any) => {
    onMaterialsChange(event.target.value)
  }

  const materialTypeChanged = (event: any) => {
    onTypeChange(event.target.value)
  }

  const materialCategoryChanged = (event: any) => {
    onCategoryChange(event.target.value)
  }
  const createMenuItemTsxElement = (menuItem: any) => {
    return (
      <MenuItemTsx
        key={menuItem}
        style={{ textTransform: 'capitalize' }}
        value={menuItem}
      >
        {menuItem}
      </MenuItemTsx>
    )
  }

  const renderMaterialNameSelector = () => {
    if (
      !hiddenMaterialNameSelector ||
      materialValue?.type !== materialTypes.metal
    ) {
      return (
        <SelectMenu
          value={materialValue?.name || materialValue}
          disabled={materialNameDisabled || false}
          onChange={materialChanged}
        >
          {renderMaterialMenuItems()}
        </SelectMenu>
      )
    }

    return (
      <div className="material-selector--hide-picker">
        <div
          title={materialValue?.name || materialValue}
          className="material-selector--hide-picker--text"
        >
          {materialValue?.name || materialValue}
        </div>
        <TransparentButton
          className="material-selector--hide-picker--button"
          onClick={() => setHiddenMaterialNameSelector(false)}
        >
          {getString('SPECIFY_MATERIAL')}
        </TransparentButton>
      </div>
    )
  }

  const renderMaterialMenuItems = () => {
    return materialsList?.map((material: any) => {
      return (
        <MenuItemTsx key={material.id} value={material.name}>
          {material.name}
        </MenuItemTsx>
      )
    })
  }

  const selectItemsBuilder = (type: string) => {
    switch (type) {
      case 'types':
        return (
          <SelectMenu
            value={materialTypeValue}
            disabled={materialTypeDisabled || false}
            onChange={materialTypeChanged}
          >
            {typesList?.map((menuItem: any) =>
              createMenuItemTsxElement(menuItem)
            )}
          </SelectMenu>
        )
      case 'categories':
        return (
          <SelectMenu
            value={materialCategoryValue.toLowerCase()}
            disabled={materialCategoryDisabled || false}
            onChange={materialCategoryChanged}
          >
            {categoriesList?.map((menuItem: any) =>
              createMenuItemTsxElement(menuItem)
            )}
          </SelectMenu>
        )
      case 'materials':
        return renderMaterialNameSelector()

      default:
        throw null
    }
  }

  return (
    <Flexbox
      flexDirection={flexDirection || 'column'}
      justifyContent="space-between"
    >
      {typesList && (
        <div>
          {showName && (
            <div className="label">
              {getString('NEW_PART_CONFIGURATION_MATERIAL')}
            </div>
          )}
          {selectItemsBuilder('types')}
        </div>
      )}
      {categoriesList?.length > 1 && (
        <div>
          {showName && (
            <div className="label">
              {getString('NEW_PART_CONFIGURATION_MATERIAL_DETAIL')}
            </div>
          )}
          {selectItemsBuilder('categories')}
        </div>
      )}
      {materialsList && (
        <div>
          {showName && (
            <div className="label">{getString('SPECIFY_MATERIAL')}</div>
          )}
          {selectItemsBuilder('materials')}
        </div>
      )}
    </Flexbox>
  )
}
export default memo(MaterialsSolutionSelector)
