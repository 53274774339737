import { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import FilterInput from './filters'
import { changeAdvancedFilterCheckBox } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'
import { IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'

interface AdvancedFilterProps {
	id: string | number
	filter: IFilterData
	allowCustomize: boolean
	printingTechnologies: IPrintingTechnology[]
}

export const AdvancedFilter: FC<AdvancedFilterProps> = ({
	id,
	filter,
	allowCustomize,
	printingTechnologies
}) => {
	const dispatch = useDispatch()
	if (
		filter?.viewOnly ||
		(!Feature.isFeatureOn(FeatureComponentId.IS_INHOUSE_PRINTER_FILTER) &&
			filter.name === IUserFilterNames.inHousePrinters)
	) {
		return <></>
	}

	let disableInHousePrinter =
		!Feature.isFeatureActive(FeatureComponentId.IS_INHOUSE_PRINTER_FILTER) &&
		filter.name === IUserFilterNames.inHousePrinters
	const disabledCheckbox =
		(filter?.mandatory && !allowCustomize) || disableInHousePrinter

	return (
		<div className="filter-field" key={filter.name}>
			<DetailsPopup
				isHover={disableInHousePrinter}
				data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
				popperDirection="bottom"
				popperClassName="info-box-wrapper details-popup--contact-us"
				popperContactClassName="info-box-data"
				targetClassName="solution-feature-modal-checkbox-popup"
			>
				<div
					className={cx('solution-feature-modal-label', {
						disabled: disableInHousePrinter
					})}
					onClick={() =>
						!disabledCheckbox &&
						dispatch(
							changeAdvancedFilterCheckBox(id, !filter.checked, filter.name)
						)
					}
				>
					<CastorCheckbox
						className="solution-feature-modal-checkbox"
						checked={filter.mandatory || filter.checked}
						disabled={disabledCheckbox}
					/>
					<span>{getString(filter?.actualName)}</span>
				</div>
				<FilterInput
					id={id}
					filterData={filter}
					printingTechnologies={printingTechnologies}
				/>
			</DetailsPopup>
		</div>
	)
}

export default memo(AdvancedFilter)
