import { useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as UserActions from '../../../global actions/UserActions'
import * as CostAndLeadActions from '../Customize/CustomizeCostAndLeadSettings/CostAndLeadActions'
import * as CustomizeInHousePrintersActions from '../Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { onSettingsCloseButtonClick } from '../../../global actions/UserActions'
import {
	getCallbackURLFromLocalStorage,
	setCallbackURLInLocalStorage
} from '../../../Services/LocalStorageService'
import { Feature, FeatureComponentId } from '../../../Services/models/Features'
import { getString } from '../../../Services/Strings/StringService'
import CastorAlert from '../../Components/alerts/CastorAlert'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import { Danger } from '../../Components/thirdParty/CreativeTim/components'
import { Button } from '../../Components/thirdParty/CreativeTim/components'
import NavPills from '../../Components/thirdParty/CreativeTim/components/NavPills/NavPills'
import TransparentButton from '../../Components/TransparentButton'
import {
	fetchCustomizeData,
	onToggleDesktopPrinter
} from '../Customize/CustomizeActions'
import CostAndLeadTbl from '../Customize/CustomizeCostAndLeadSettings/CostAndLeadTbl'
import CustomizePrinterProps from '../Customize/CustomizeCostAndLeadSettings/CustomizePrinterProps'
import CustomizeFormSection from '../Customize/CustomizeFormSection'
import CustomizeInHousePrintersTable from '../Customize/CustomizeInHousePrinters/CustomizeInHousePrintersTable'
import InHouseMaterialOtherSelector from '../Customize/CustomizeInHousePrinters/InHouseMaterialOtherSelector'
import InHousePrintersOtherSelector from '../Customize/CustomizeInHousePrinters/InHousePrintersOtherSelector'
import InHousePrintersSelector from '../Customize/CustomizeInHousePrinters/InHousePrintersSelector'
import CustomizeMaterialCost from '../Customize/CustomizeMaterialCost/CustomizeMaterialCost'
import CustomizeSupplyChain from '../Customize/CustomizeSupplyChain/CustomizeSupplyChain'
import CustomizeSupplyChainRadioButton from '../Customize/CustomizeSupplyChain/CustomizeSupplyChainRadioButton'
import CustomizeUserPostProcesses from '../Customize/CustomizeUserPostProcesses/CustomizeUserPostProcesses'
import Flexbox from 'Scenes/Components/FlexBox'
import { gerUrlParams } from 'Services/Utils/urlTools'

import './UserSettings.scss'

const NavPillsTSX: any = NavPills
const NavBarAndMateriall: any = NavBarAndMaterial

interface IReduxProps {
	costAndLeadNames: any
	settingsData: any
	editInputs: any
	CostAndLeadReducer: any
	CustomizeInHousePrintersReducer: any
	printersFullData: any
	userCustomizationSettings: any
	user: any
	printersCompanies: any
	match: any
	showPrinterNotExistAlert: any
	selectedPrinter: any
	userId: any
	printerAmount: any
	userSettingsWithError: any
	showUploadButton: any
	desktopPrinter: boolean
	CustomizeReducer: any
	isShowSemiProfessionalPrintersToggle: boolean
	fetchCustomizeData: Function
	onToggleDesktopPrinter: Function
	setupPrinterPropsData: Function
	setupInHousePrinters: Function
	updateUserSettings: Function
	onSupportsRemovalChanged: Function
	onSurfaceAreaChanged: Function
	removePrinterNotExistAlert: Function
	onSupplyChainCostChanged: Function
	onSettingsCloseButtonClick: Function
}

const UserSettings: React.FC<IReduxProps> = ({
	costAndLeadNames,
	settingsData,
	editInputs,
	printersFullData,
	userCustomizationSettings,
	printersCompanies,
	match,
	userSettingsWithError,
	showUploadButton,
	showPrinterNotExistAlert,
	fetchCustomizeData,
	onToggleDesktopPrinter,
	setupPrinterPropsData,
	setupInHousePrinters,
	updateUserSettings,
	onSupportsRemovalChanged,
	onSurfaceAreaChanged,
	removePrinterNotExistAlert,
	onSupplyChainCostChanged,
	desktopPrinter,
	isShowSemiProfessionalPrintersToggle,
	onSettingsCloseButtonClick
}: IReduxProps) => {
	const [callbackURL, setCallBack] = useState<string>(
		getCallbackURLFromLocalStorage()
	)

	const history = useHistory()
	const [showCostEstimation, setShowCostEstimation] = useState<boolean>(false)

	const getCurrentTab = () => {
		return history?.location?.search.split('=')[1] || '0'
	}
	const [query, setQuery] = useState(getCurrentTab())

	useEffect(() => {
		// Update the current callbackURL.
		const { back: callbackURL } = gerUrlParams(['back'])

		if (callbackURL) {
			setCallBack(callbackURL)
			setCallbackURLInLocalStorage(callbackURL)
		}

		if (!settingsData || settingsData?.length === 0) {
			fetchCustomizeData(costAndLeadNames)
		} else {
			setupPrinterPropsData(printersFullData)
			setShowCostEstimation(true)
		}
	}, [])

	useEffect(() => {
		if (!printersCompanies) {
			setupInHousePrinters(printersCompanies, match)
		}
	})

	useEffect(() => {
		const params = new URLSearchParams()
		if (query) {
			params.append('tab', query)
		} else {
			params.delete('tab')
		}
		history.push({ search: params.toString() })
	}, [query])

	const onCloseClick = () => {
		if (callbackURL) {
			onSettingsCloseButtonClick(callbackURL, false)
		}
	}

	const elementIds = {
		costEstimation: `user-settings-tabs-title-cost-estimation`,
		inHousePrinters: `user-settings-tabs-title-in-house-printers`
	}

	const showAlert = () => {
		return Object.values(userSettingsWithError).indexOf(true) > -1
	}

	const renderCosts = () => {
		if (showCostEstimation) {
			return (
				<>
					<CostAndLeadTbl />
					<Flexbox
						flexDirection="row"
						justifyContent="space-between"
						className="applyAllButtons"
					>
						{renderUploadAlert(showAlert())}
						{renderApplyAllButton(showAlert())}
					</Flexbox>
					<CustomizeMaterialCost />
				</>
			)
		}
	}

	const renderUploadAlert = (show: boolean) => {
		if (!show) {
			return <div />
		}
		return (
			<Danger style={{ textAlign: 'left' }}>
				{getString('COST_AND_LEAD_APPLY_CHANGES_ALERT')}
			</Danger>
		)
	}

	const onUpdateClick = () => {
		updateUserSettings(settingsData, editInputs, costAndLeadNames)
	}

	const renderApplyAllButton = (show: boolean) => {
		if (!showUploadButton || show) {
			return <div />
		}
		return (
			<TransparentButton onClick={onUpdateClick}>
				{getString('COST_AND_LEAD_APPLY_CHANGES')}
			</TransparentButton>
		)
	}

	const renderPrinterNotExistAlert = () => {
		const printerName = getString('PRINTER')

		return (
			<CastorAlert
				show={showPrinterNotExistAlert}
				headerTitle={getString('IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_TITLE')}
				onConfirm={() => removePrinterNotExistAlert()}
				onCancel={() => removePrinterNotExistAlert()}
				confirmOptionalText={getString('OK')}
				showCancel={false}
			>
				{getString('IN_HOUSE_PRINTERS_PRINTER_NOT_EXIST_CONTENT').format(
					printerName
				)}
			</CastorAlert>
		)
	}
	const renderTabsArray = () => {
		const customizeSupplyChain = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_SUPPLY_CHAIN
		)
		const customizeSupplyChainRadioButton = Feature.isFeatureOn(
			FeatureComponentId.DEFAULT_SCENARIO
		)
		const addSemiProfessionalPrinters = Feature.isFeatureOn(
			FeatureComponentId.CUSTOMIZE_USER_SEMI_PRO_PRINTERS
		)
		const tabs = []
		const InHousePrintersSelectorProps = { zIndex: 99999 }
		tabs.push({
			tabButton: (
				<div
					id={elementIds.costEstimation}
					className="solution-tab--cost-estimation-title"
					onClick={() => setQuery('0')}
				>
					{getString('ANALYSIS_SETTINGS_COST_ESTIMATION_TAB')}
				</div>
			),
			tabContent: (
				<>
					<CastorFormHeader
						explanationHeader={getString('CUSTOMIZE_COST_AND_LEAD_TITLE')}
						explanationArray={getString(
							'CUSTOMIZE_COST_AND_LEAD_EXPLANATION_ARRAY'
						)}
						isInCard={true}
					/>

					<Flexbox flexDirection="column">
						{renderCosts()}
						<CustomizeUserPostProcesses
							supportRemovalMachiningOn={
								userCustomizationSettings?.supportRemovalMachiningOn
							}
							surfaceAreaMachiningOn={
								userCustomizationSettings?.surfaceAreaMachiningOn
							}
							onSurfaceAreaToggleChange={surfaceAreaMachiningOn =>
								onSurfaceAreaChanged(surfaceAreaMachiningOn)
							}
							onSupportsRemovalToggleChange={supportRemovalMachiningOn =>
								onSupportsRemovalChanged(supportRemovalMachiningOn)
							}
							disabled={false}
						/>
						{customizeSupplyChain && (
							<CustomizeSupplyChain />
						)}
						{customizeSupplyChainRadioButton && (
							<CustomizeSupplyChainRadioButton />
						)}
						<CustomizePrinterProps />
						{(addSemiProfessionalPrinters ||
							isShowSemiProfessionalPrintersToggle) && (
							<CustomizeFormSection
								componentId={2}
								explanationHeader={getString(
									'CUSTOMIZE_USER_SEMI_MATERIAL_TITLE'
								)}
								explanationArray={getString(
									'CUSTOMIZE_USER_SEMI_MATERIAL_EXPLANATION_ARRAY'
								)}
								switchChecked={desktopPrinter}
								onToggleChange={() => onToggleDesktopPrinter(desktopPrinter)}
								wrapperClassName="analysis-settings-content-tab--semi"
								label={`Add ${getString('CUSTOMIZE_USER_SEMI_MATERIAL_TITLE')}`}
								toggleClassName="analysis-settings-content-tab--semi--toggle"
							/>
						)}
					</Flexbox>
				</>
			)
		})
		tabs.push({
			tabButton: (
				<div
					id={elementIds.inHousePrinters}
					className="solution-tab--in-house-printers-title"
					onClick={() => setQuery('1')}
				>
					{getString('ANALYSIS_SETTINGS_IN_HOUSE_PRINTERS_TAB')}
				</div>
			),
			tabContent: (
				<div>
					<CastorFormHeader
						explanationHeader={getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE')}
						explanationArray={getString(
							'CUSTOMIZE_IN_HOUSE_PRINTERS_EXPLANATION_ARRAY'
						)}
						isInCard={true}
					/>
					<InHousePrintersSelector {...InHousePrintersSelectorProps} />
					<InHousePrintersOtherSelector />
					<InHouseMaterialOtherSelector {...InHousePrintersSelectorProps} />
					<CustomizeInHousePrintersTable />
				</div>
			)
		})
		return tabs
	}

	const tabs = renderTabsArray()

	return (
		<NavBarAndMateriall title={''}>
			<Flexbox
				flexDirection="column"
				alignSelf="center"
				alignItems="center"
				className="analysis-settings"
			>
				<div className="analysis-settings-header">
					{getString('ANALYSIS_SETTINGS_HEADER')}
				</div>

				<div className="analysis-settings-content">
					<NavPillsTSX
						initialSelectedTab={parseInt(getCurrentTab())}
						alignCenter={false}
						tabButtonsClassName="analysis-settings-content-tab-buttons"
						color="warning"
						tabButtonClassName="analysis-settings-content-tab-buttons-button"
						tabButtonSelectedClassName="analysis-settings-content-tab-buttons-button-selected"
						tabButtonLabelClassName="analysis-settings-content-tab-buttons-button-label"
						tabContentClassName="analysis-settings-content-tab-content"
						tabs={tabs}
					/>
					{renderPrinterNotExistAlert()}
				</div>
				{callbackURL !== '' && (
					<Flexbox
						justifyContent="flex-end"
						className="analysis-settings-footer"
					>
						<Button
							className="analysis-settings-footer-button"
							color="primary"
							onClick={onCloseClick}
						>
							{getString('NEW_PART_CONFIGURATION_CLOSE')}
						</Button>
					</Flexbox>
				)}
			</Flexbox>
		</NavBarAndMateriall>
	)
}

const mapStateToProps = ({
	CostAndLeadReducer,
	user: { printersFullData, userCustomizationSettings },
	CustomizeInHousePrintersReducer,
	CustomizeReducer: { desktopPrinter, isShowSemiProfessionalPrintersToggle }
}: IReduxProps) => {
	return {
		...CostAndLeadReducer,
		...CustomizeInHousePrintersReducer,
		printersFullData,
		userCustomizationSettings,
		desktopPrinter,
		isShowSemiProfessionalPrintersToggle
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) => {
	return bindActionCreators(
		{
			...CostAndLeadActions,
			...CustomizeInHousePrintersActions,
			...UserActions,
			fetchCustomizeData,
			onToggleDesktopPrinter,
			onSettingsCloseButtonClick
		},
		dispatch
	)
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings)
