import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Accordion } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import cx from 'classnames'

import { toggleCostingFunctionAccordion } from './CostingFunctionEditorActions'
import CustomFunctionRow from './CustomFunctionRow'
import {
	CostingFunctionSteps,
	ICustomFunction
} from './CustomizeCostingFunctionTypes'
import { IUser } from 'Services/models/IUser'
import { getString } from 'Services/Strings/StringService'

const Step3: FC = () => {
	const dispatch = useDispatch()
	const { stepIsOpen, customFunctionsList } = useSelector(
		(state: RootStateOrAny) => state.CostingFunctionEditorReducer
	)
	const { printingTechnologies }: IUser = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const step3 = CostingFunctionSteps.ViewAndEdit

	const tableHeaders =
		getString('COSTING_FUNCTION_FUNCTIONS_LIST_HEADERS') || []

	return (
		<Accordion
			disabled={!customFunctionsList.length}
			className="costing-function-editor--accordion"
			expanded={stepIsOpen[step3]}
			onChange={(e: React.ChangeEvent<{}>, expanded: boolean) => {
				dispatch(toggleCostingFunctionAccordion(step3))
			}}
		>
			<AccordionSummary
				aria-controls="panel1bh-content"
				id="panel1bh-header"
				className="costing-function-editor--step-heading--wrapper"
				classes={{ content: 'costing-function-editor--step-heading' }}
			>
				<div className="costing-function-editor--step-heading--text">
					<span className="costing-function-editor--step-heading--text--main">
						{getString('COSTING_FUNCTION_EDITOR_STEP_3_HEADER')}
					</span>
					<span>{getString('COSTING_FUNCTION_EDITOR_STEP_3_DESCRIPTION')}</span>
				</div>
				<ExpandMoreIcon
					className={cx('expand', {
						open: stepIsOpen[step3]
					})}
				/>
			</AccordionSummary>
			<AccordionDetails className="costing-function-editor--step-body">
				<div className="costing-function-editor--functions-list--wrapper">
					<div className="costing-function-editor--functions-list--headers">
						{tableHeaders.map(
							(header: { name: string; displayName: string }) => {
								return (
									<div
										key={header.name}
										className="costing-function-editor--functions-list--cell"
									>
										{header.displayName}
									</div>
								)
							}
						)}
					</div>
					<div>
						{customFunctionsList?.map((customFunction: ICustomFunction) => {
							return (
								<CustomFunctionRow
									customFunction={customFunction}
									printingTechnologies={printingTechnologies}
									key={customFunction.id}
								/>
							)
						})}
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	)
}

export default memo(Step3)
