import { FC } from 'react'
import cx from 'classnames'
import { useDispatch } from 'react-redux'

import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { generateKey } from 'Services/Utils/uniqKeyGenerator'
import { getString } from 'Services/Strings/StringService'
import { uploadUnitTypeSelected } from '../../UploadProjectActions'

interface UnitTypeSelectorProps {
	showUnitTypeSelector: any
	unitType: any
	inputClassName?: any
	error?: string | null
	errorUnit?: boolean
}

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

const UnitTypeSelector: FC<UnitTypeSelectorProps> = ({
	showUnitTypeSelector,
	unitType,
	inputClassName,
	error
}) => {
	const dispatch = useDispatch()

	if (!showUnitTypeSelector) {
		return <div />
	}

	return (
		<div
			onClick={(e: any) => e.stopPropagation()}
			className={cx('upload-unit-type', {
				'with-unit': showUnitTypeSelector
			})}
		>
			{showUnitTypeSelector && (
				<div className="unit-type">
					<div className="upload-project--unit-type-title">
						{getString('PROJECT_UNIT_TYPE')}
					</div>
					<SelectTSX
						className={cx(inputClassName)}
						value={unitType || 'disabled'}
						onChange={(e: any) =>
							dispatch(uploadUnitTypeSelected(e?.target?.value))
						}
						inputProps={{
							name: 'simpleSelect',
							id: 'simple-select'
						}}
						data-qa={`data-qa-file-unit-selector}`}
					>
						<MenuItemTSX disabled value="disabled">
							{getString('UNIT_SELECTION_PLACEHOLDER')}
						</MenuItemTSX>
						{getString('CAD_UNIT_TYPES')?.map((json: any, index: any) => {
							return (
								<MenuItemTSX
									key={generateKey(index)}
									value={json.key}
									data-qa={`data-qa-${json.key}`}
								>
									{json.name}
								</MenuItemTSX>
							)
						})}
					</SelectTSX>
				</div>
			)}
		</div>
	)
}

export default UnitTypeSelector
