import React, { FC, memo } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import {
	MATERIAL_COST_INPUTS_WEIGHT_UNIT,
	MATERIAL_COST_PRICE_IN_USD
} from '../../../Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './CustomizeForm.scss'

const TextFieldTSX: any = TextField
const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem

interface IProps {
	materialPrice: number
	selectedWeightUnit: string
	onMaterialPriceChange: Function
	onWeightSelectorChange: Function
	zIndex?: number
	materialPriceError: boolean
}

const MaterialPricePicker: FC<IProps> = ({
	onMaterialPriceChange,
	materialPrice,
	onWeightSelectorChange,
	selectedWeightUnit,
	zIndex = 1300,
	materialPriceError
}) => {
	return (
		<div className="price-flex">
			<div className="material-picker">
				<span className="material-picker--currency-sign">$</span>
				<TextFieldTSX
					className="material-picker--text"
					type="number"
					placeholder={MATERIAL_COST_PRICE_IN_USD}
					onChange={onMaterialPriceChange}
					value={materialPrice}
					error={materialPriceError}
					helperText={
						materialPriceError &&
						getString('MATERIAL_COST_PRICE_ZERO_VALIDATION')
					}
					FormHelperTextProps={{
						className:
							'customize-printer-props--content--inputs--fields--field--error-text'
					}}
				/>
			</div>
			<SelectTSX
				className="material-picker--select small"
				value={selectedWeightUnit}
				onChange={onWeightSelectorChange}
				MenuProps={{
					style: { zIndex }
				}}
			>
				{MATERIAL_COST_INPUTS_WEIGHT_UNIT.map((item: string) => {
					return (
						<MenuItemTSX key={item} value={item}>
							{item}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
		</div>
	)
}

export default memo(MaterialPricePicker)
