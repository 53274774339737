import { FC, Fragment, memo, useEffect, useState } from 'react'

import cx from 'classnames'
import isArray from 'lodash/isArray'

import IconFactory from '../StarIcon/IconFactory'
import DataTableBreakRowLine from './DataTableBreakRowLine'
import DataTableFieldFactory from './DataTableFieldFactory'
import { IDataTableField } from './IDataTableField'
import { getString } from 'Services/Strings/StringService'
import { generateKey } from 'Services/Utils/uniqKeyGenerator'

import './DataTable.scss'

interface IProps {
	tableHead?: (IDataTableField | IDataTableField[])[]
	tableDataRows: any[][]
	tableClassName?: string
	customElements?: IDataTableField[]
	showBreakLines?: boolean
	showBreakLineAtEndOfTable?: boolean
	showChildren?: boolean
	shouldShowDetailed?: boolean
	shouldShowNested?: boolean
	tableStyle?: Record<string, string>
}

const isTotalPartCostExist = (
	dataRows: any[][],
	shouldShowNested?: boolean
) => {
	let isTotalPartCost: boolean = false

	dataRows.forEach(singleRow => {
		if (
			singleRow.some(
				elem => elem.text === getString('TOTAL_PART_COST').format('$')
			)
		) {
			isTotalPartCost = true
		}
	})

	if (shouldShowNested) {
		isTotalPartCost = false
	}

	return isTotalPartCost
}

const DataTable: FC<IProps> = ({
	shouldShowNested = false,
	shouldShowDetailed = false,
	showChildren = false,
	tableHead,
	tableDataRows,
	tableClassName,
	customElements,
	showBreakLines = false,
	showBreakLineAtEndOfTable = false,
	tableStyle = {}
}) => {
	const [showRows, setShowRows] = useState<Record<string, boolean>>({})
	const [firstColumnClassName, setFirstColumnClassName] = useState(
		'data-table--first-column'
	)
	const [isThereParentRows, setIsThereParentRows] = useState(false)

	useEffect(() => {
		isThereParentRows &&
			setFirstColumnClassName('data-table--first-column--inside-row')
	}, [isThereParentRows])

	// we need to show all nested rows
	// if showChildren and !isTotalPartCost already shown
	useEffect(() => {
		if (showChildren) {
			let showAllRows = showRows
			let isTotalPartCost: boolean = isTotalPartCostExist(
				tableDataRows,
				shouldShowNested
			)

			if (!isTotalPartCost) {
				tableDataRows.forEach((singleRow, key) => {
					showAllRows[key] = true
					showAllRows[`child_${key + 1}`] = shouldShowDetailed
				})
				setShowRows({
					...showAllRows
				})
			}
		}
	}, [showChildren, tableDataRows, shouldShowDetailed])

	const columnsLength =
		tableDataRows && tableDataRows[0] && tableDataRows[0].length

	const renderFirstRowInMultipleRows = (
		rowIndex: number,
		field: any,
		key: string,
		parentRowIndex: number | string,
		isChildRow?: boolean
	) => {
		if (rowIndex > 0) {
			return <DataTableFieldFactory dataTableField={field} key={key} />
		}

		return (
			<div
				key={key}
				className={cx(
					'data-table--first-column--parent data-table--first-column',
					{
						child: isChildRow
					}
				)}
				onClick={() =>
					setShowRows({
						...showRows,
						[parentRowIndex]: !showRows[parentRowIndex]
					})
				}
			>
				<IconFactory
					className={`data-table--first-column--parent--chevron ${
						showRows[parentRowIndex] ? 'rotate' : ''
					}`}
					iconName="chevronRight"
				/>
				<DataTableFieldFactory
					// className={k === 0 ? 'data-table--first-column' : ''}
					dataTableField={field}
					key={key}
				/>
			</div>
		)
	}

	const renderRows = () => {
		return tableDataRows.map((tableDataRow, i) => (
			<Fragment key={generateKey(i)}>
				{tableDataRow.map((field, j) => {
					if (!isArray(field)) {
						return (
							<DataTableFieldFactory
								className={j === 0 ? firstColumnClassName : ''}
								dataTableField={field}
								key={`${i}_${j}`}
							/>
						)
					}
					!isThereParentRows && setIsThereParentRows(true)
					return field.map((field, k) => {
						if (j === 0) {
							return renderFirstRowInMultipleRows(k, field, `${i}_${j}_${k}`, i)
						}

						if (!showRows[i]) {
							return
						}

						if (isArray(field)) {
							return field.map((field, c) => {
								if (k === 0) {
									return renderFirstRowInMultipleRows(
										c,
										field,
										`${i}_${j}_${k}_${c}`,
										`child_${j}`,
										true
									)
								}

								if (!showRows[`child_${j}`]) {
									return
								}

								return (
									<DataTableFieldFactory
										className={
											c === 0
												? `${firstColumnClassName} data-table--inside-row nested-child`
												: 'data-table--inside-row'
										}
										dataTableField={field}
										key={`${i}_${j}_${k}_${c}`}
									/>
								)
							})
						}

						return (
							<DataTableFieldFactory
								className={
									k === 0
										? `${firstColumnClassName} data-table--inside-row`
										: 'data-table--inside-row'
								}
								dataTableField={field}
								key={`${i}_${j}_${k}`}
							/>
						)
					})
				})}
				{showBreakLines &&
					(i < tableDataRows.length - 1 || showBreakLineAtEndOfTable) &&
					tableDataRows[i].length > 0 && <DataTableBreakRowLine />}
			</Fragment>
		))
	}

	const renderTable = () => {
		return (
			<>
				{tableHead &&
					tableHead.map((head, index) => {
						if (Array.isArray(head)) {
							return (
								<div
									key={generateKey(index)}
									className={`data-table-header--multiple-lines ${
										index === 0 ? firstColumnClassName : ''
									}`}
								>
									{head.map((h, j) => (
										<DataTableFieldFactory
											dataTableField={h}
											key={`${index}_${j}`}
										/>
									))}
								</div>
							)
						}
						return (
							<DataTableFieldFactory
								className={index === 0 ? firstColumnClassName : ''}
								dataTableField={head}
								key={generateKey(index)}
							/>
						)
					})}
				{renderRows()}
				{customElements && (
					<>
						<DataTableBreakRowLine />
						{customElements.map((customElement, index) => (
							<DataTableFieldFactory
								className={index === 0 ? firstColumnClassName : ''}
								dataTableField={customElement}
								key={generateKey(index)}
							/>
						))}
					</>
				)}
			</>
		)
	}

	const renderAllTable = () => {
		return (
			<div
				className={`data-table ${tableClassName || ''}`}
				style={{
					gridTemplateColumns: `repeat(${columnsLength}, auto)`,
					...tableStyle
				}}
			>
				{renderTable()}
			</div>
		)
	}

	return renderAllTable()
}

export default memo(DataTable)
