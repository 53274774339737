import React, { FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { match } from 'react-router'
import { useLocation } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as ProjectAnalysisActions from '../ProjectAnalysis/ProjectAnalysisActions'
import CastorForm from '../../Components/CastorForm/CastorForm'
import DataTable from '../../Components/DataTable'
import NavBarAndMaterial from '../../Components/NavBarAndMaterial'
import {
	buildGridTemplateColumns,
	buildPartPropertiesHeaders,
	buildPartPropertyRows,
	onRecalculateClick
} from './PartPropertiesService'
import PartPropertyCalculateAlert from './PartPropertyCalculateAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import Flexbox from 'Scenes/Components/FlexBox'
import TransparentButton from 'Scenes/Components/TransparentButton'
import GeometryAnalysisReviewAndFixes from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/GeometryAnalysisReviewAndFixes'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

import './PartProperties.scss'

interface IReduxStore {
	ProjectAnalysisReducer: any
}

interface IProps {
	match: match<any>
	partId: number
	projectId: string
	partsProperties: any[]
	inapplicablePartsProperties: any[]
	partsPropertiesCalculating: { rowIndex: number; calculating: boolean }
	partsPropertiesReset: { rowIndex: number; reset: boolean }
	onGetPartsProperties: Function
	onPartsPropertiesChange: Function
	onPartsPropertiesReset: Function
	onRestAllPartProperties: Function
	updatePartProperty: Function
	onPartsPropertiesGoBack: Function
	partsPropertiesAll: Array<Part>
}

const PartProperties: FC<IProps> = ({
	match: {
		params: { projectId }
	},
	partId,
	partsProperties,
	inapplicablePartsProperties,
	partsPropertiesCalculating,
	partsPropertiesReset,
	onGetPartsProperties,
	onPartsPropertiesChange,
	onPartsPropertiesReset,
	onRestAllPartProperties,
	updatePartProperty,
	onPartsPropertiesGoBack,
	partsPropertiesAll
}) => {
	const { state } = useLocation() as { state?: Record<string, boolean> }
	const propertiesList = inapplicablePartsProperties?.length
		? [...partsProperties, ...inapplicablePartsProperties]
		: partsProperties
	const [selectedPart, setSelectedPart] = useState<any>({})
	const [show, setShow] = useState(false)
	const [showAlert, setPartPropertyCalculateAlert] = useState(false)
	const [partIndex, setPartIndex] = useState(-1)
	const [partProperties, setPartProperties] = useState([])

	const setPartToViewer = (part: any) => {
		setSelectedPart(part)
		setShow(true)
	}
	const onConfirmClick = (index: number, partPropertiesPerClick: any) => {
		const partPropertiesApplicable = partPropertiesPerClick.filter(
			(part: any) => !part.isInapplicable
		)
		setPartIndex(index)
		setPartProperties(partPropertiesApplicable)
		setPartPropertyCalculateAlert(true)
	}

	useEffect(() => {
		if (projectId && !partsProperties.length) {
			onGetPartsProperties(projectId)
		}
	}, [])

	let partPropertyRows: any[][]

	// need for catching errors in
	// service and pass to parent boundary
	try {
		partPropertyRows = buildPartPropertyRows(
			propertiesList,
			partId,
			projectId,
			partsPropertiesCalculating,
			partsPropertiesReset,
			updatePartProperty,
			onConfirmClick,
			onPartsPropertiesReset,
			partsPropertiesAll,
			setPartToViewer
		)
	} catch (e) {
		console.error(e)
	}

	const renderButtons = () => {
		const allowResetAllPartsProperties = Feature.isFeatureOn(
			FeatureComponentId.ALLOW_RESET_ALL_PARTS_PROPERTIES
		)
		const recalculateAllPartsProperties = Feature.isFeatureOn(
			FeatureComponentId.RECALCULATE_ALL_PARTS_PROPERTIES
		)
		return (
			<Flexbox className={'part-properties-buttons'}>
				{allowResetAllPartsProperties && (
					<TransparentButton
						color="secondary"
						disabled={
							partsPropertiesCalculating.calculating ||
							partsPropertiesReset.reset
						}
						loading={partsPropertiesReset.rowIndex === -1}
						onClick={() => onRestAllPartProperties(projectId, propertiesList)}
					>
						{getString('RESET_ALL')}
					</TransparentButton>
				)}
				{recalculateAllPartsProperties && (
					<ButtonWithLoader
						className="part-properties-buttons-cal-button"
						color="primary"
						disabled={
							partsPropertiesCalculating.calculating ||
							partsPropertiesReset.reset
						}
						loading={partsPropertiesCalculating.rowIndex === -1}
						onClick={() => onConfirmClick(-1, propertiesList)}
					>
						{getString('RECALCULATE_ALL')}
					</ButtonWithLoader>
				)}
			</Flexbox>
		)
	}

	const renderFormContent = () => {
		return (
			<Flexbox className={'part-properties'} flexDirection="column">
				<DataTable
					tableClassName="part-properties-table"
					tableHead={buildPartPropertiesHeaders(
						propertiesList[0]?.data
							.filter((property: any) => property.showInUI)
							.sort((a: any, b: any) => {
								return a.index > b.index ? 1 : -1
							})
							.map((partPropertiesHeader: any) => partPropertiesHeader)
					)}
					tableDataRows={partPropertyRows}
					tableStyle={{
						gridTemplateColumns: buildGridTemplateColumns(
							propertiesList[0]?.data || []
						),
						gridTemplateRows: `60px repeat(${
							partId ? 1 : propertiesList.length
						}, 80px 10px)`,
						justifyContent: 'space-around'
					}}
					showBreakLines
					showBreakLineAtEndOfTable
				/>
				{!partId && partsProperties?.length > 1 && renderButtons()}
			</Flexbox>
		)
	}

	return (
		<ErrorBoundary extra="PartProperties">
			<NavBarAndMaterial title={getString('PARTS_PROPERTIES')}>
				<CastorForm
					formTitle={getString('PARTS_PROPERTIES')}
					content={renderFormContent()}
					style={{ maxWidth: 'unset' }}
				/>
				<ButtonWithLoader
					className="part-properties-back-button"
					color="primary"
					disabled={
						partsPropertiesCalculating.calculating || partsPropertiesReset.reset
					}
					onClick={() => onPartsPropertiesGoBack(projectId, state?.prevUrl)}
				>
					{getString('PARTS_PROPERTIES_BACK_BUTTON')}
				</ButtonWithLoader>

				<PartPropertyCalculateAlert
					setPartPropertyCalculateAlert={setPartPropertyCalculateAlert}
					removeConfigurationLoading={false}
					projectId={projectId}
					partProperties={partProperties}
					partIndex={partIndex}
					onPartsPropertiesChange={onPartsPropertiesChange}
					onRecalculateClick={onRecalculateClick}
					showAlert={showAlert}
				/>
				<GeometryAnalysisReviewAndFixes
					show={show}
					partImageUrl={selectedPart?.meshUploadURL}
					configurationPrintIssues={[]}
					orientationVector={[]}
					configuration={{}}
					solution={undefined}
					printIssues={[]}
					partHealedStlURLExist={undefined}
					printIssueLoaders={{}}
					onWallThicknessClick={() => {}}
					onTolerancesClick={() => {}}
					part={selectedPart}
					onPartPrintIssueChanged={() => {}}
					onMeshHealingClick={() => {}}
					onViewerModelError={() => {}}
					onCancel={() => setShow(false)}
				/>
			</NavBarAndMaterial>
		</ErrorBoundary>
	)
}

const mapStateToProps = ({
	ProjectAnalysisReducer: {
		partId,
		projectId,
		partsProperties,
		partsPropertiesCalculating,
		partsPropertiesReset,
		inapplicablePartsProperties,
		partsPropertiesAll
	}
}: IReduxStore) => {
	return {
		partId,
		projectId,
		partsProperties,
		partsPropertiesCalculating,
		partsPropertiesReset,
		inapplicablePartsProperties,
		partsPropertiesAll
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...ProjectAnalysisActions }, dispatch)

export default WithFeatureToggleHOC(
	memo(connect(mapStateToProps, mapDispatchToProps)(PartProperties)),
	FeatureComponentId.DRAWING_ANALYSIS
)
