import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// material-ui components
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'

import buttonStyle from '../../../../../../assets/jss/material-dashboard-pro-react/components/buttonStyle'
import OverrideComponentHOC from '../../../../../../themes/OverrideComponentHOC'

const RegularButton = ({
  classes,
  color,
  round,
  children,
  fullWidth,
  disabled,
  simple,
  size,
  block,
  link,
  justIcon,
  className,
  muiClasses,
  ...rest
}) => {
  const btnClasses = cx({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  })
  return (
    <Button classes={muiClasses} className={btnClasses} {...rest}>
      {children}
    </Button>
  )
}

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'defaultNoBackground',
    'primaryNoBackground',
    'infoNoBackground',
    'successNoBackground',
    'warningNoBackground',
    'dangerNoBackground',
    'roseNoBackground',
    'twitter',
    'twitterNoBackground',
    'facebook',
    'facebookNoBackground',
    'google',
    'googleNoBackground',
    'linkedin',
    'linkedinNoBackground',
    'pinterest',
    'pinterestNoBackground',
    'youtube',
    'youtubeNoBackground',
    'tumblr',
    'tumblrNoBackground',
    'github',
    'githubNoBackground',
    'behance',
    'behanceNoBackground',
    'dribbble',
    'dribbbleNoBackground',
    'reddit',
    'redditNoBackground',
    'white',
    'simple',
    'transparent',
    'simpleGray',
    'primaryNewStyle'
  ]),
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  // make the button's min width to 160px
  wd: PropTypes.bool,
  // make the button smaller
  justIcon: PropTypes.bool,
  // button will float right
  right: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'lg', 'xs']),
  simple: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object
}

export default OverrideComponentHOC(withStyles(buttonStyle)(RegularButton))
