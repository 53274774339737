import {
	FETCH_QR,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	RESET_TOW_FACTOR_STATE,
	TOGGLE_ADMIN_LOGIN_HELP_POPUP,
	TOW_FACTOR_WRONG_CODE,
	TWO_FACTOR_AUTH_SETUP_FINISH,
	TWO_FACTOR_CODE_CHANGED,
	TWO_FACTOR_LOGIN_SUCCESSED,
	USER_SAVED_QR
} from 'global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from 'global actions/types/CastorAlertTypes'
import { History } from 'history'
import { projectPageRoles } from 'Scenes/Authentication/TwoFactorAuth/constants'
import {
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import {
	confirmUserCode,
	getTwoFactorCode,
	informTwoFactorLoginIssue,
	isAdminAuthenticated,
	sendTwoFactorCode
} from 'Services/Network'
import {
	OK,
	SHOW_NOTIFICATION,
	TWO_FACTOR_TROUBLE_POPUP_BODY_TEXT,
	TWO_FACTOR_TROUBLE_POPUP_HEADER,
	TWO_FACTOR_WRONG_CODE
} from 'Services/Strings'

export const setup = (
	history: History,
	setupEnd: boolean,
	roles: number[]
): any => {
	return async (dispatch: any) => {
		try {
			dispatch({ type: HANDLE_LOADER, payload: 1 })
			const res = await isAdminAuthenticated()
			const adminAuthenticated = res?.data.verify
			if (adminAuthenticated) {
				dispatch({ type: HANDLE_LOADER, payload: -1 })
				if (projectPageRoles.some(role => roles.includes(role))) {
					history.push('/admin/projects')
				} else {
					history.push('/admin/printers')
				}
			} else {
				const setupExist = res?.data.two_factor_enabled
				if (!setupExist) {
					await fetchTwoFactorCode(dispatch)
				}
				dispatch({
					type: TWO_FACTOR_AUTH_SETUP_FINISH,
					payload: { adminAuthenticated, setupExist, setupEnd }
				})
				dispatch({ type: HANDLE_LOADER, payload: -1 })
			}
		} catch (err) {
			console.log(err)
			dispatch({ type: HANDLE_LOADER, payload: -1 })
			history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
		}
	}
}
const fetchTwoFactorCode = async (dispatch: any): Promise<void> => {
	const res = await getTwoFactorCode()
	const { qr } = res?.data
	dispatch({ type: FETCH_QR, payload: { qr } })
}
export const sendCode = (code: number | null, initialSetup: boolean): any => {
	if (code == null) {
		console.error('code has to be number')
		return
	}
	return async (dispatch: any) => {
		try {
			if (initialSetup) {
				await confirmUserCode(code)
				dispatch({ type: TWO_FACTOR_LOGIN_SUCCESSED })
			} else {
				await sendTwoFactorCode(code)
				dispatch({ type: TWO_FACTOR_LOGIN_SUCCESSED })
			}
		} catch (error: any) {
			console.error(error)
			dispatch({ type: TOW_FACTOR_WRONG_CODE })
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: TWO_FACTOR_WRONG_CODE
				}
			})
		}
	}
}
export const clear = (): any => {
	return { type: RESET_TOW_FACTOR_STATE }
}
export const saveQr = (): any => {
	return { type: USER_SAVED_QR }
}
export const codeInputOnChange = (value?: number) => {
	return { type: TWO_FACTOR_CODE_CHANGED, payload: { value } }
}
export const showPopup = () => {
	return {
		type: TOGGLE_ADMIN_LOGIN_HELP_POPUP,
		payload: { showLoginHelpPopup: true }
	}
}
const hidePopup = (dispatch: any) => {
	dispatch({
		type: ALERT_POPUP_CANCELED
	})
}
export const informAdminLoginIssue = (): any => {
	return async (dispatch: any) => {
		try {
			const time = new Date().toString()
			await informTwoFactorLoginIssue(time)
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: TWO_FACTOR_TROUBLE_POPUP_BODY_TEXT,
					headerTitle: TWO_FACTOR_TROUBLE_POPUP_HEADER,
					showCancel: false,
					onConfirm: () => {
						hidePopup(dispatch)
					},
					confirmText: OK
				}
			})
		} catch (error: any) {
			console.log(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: error.validationMessage || error.message
				}
			})
		}
	}
}
