import React, { FC } from 'react'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { getString } from 'Services/Strings/StringService'

import './CustomizeCostingFunctionEditor.scss'

const CustomizeCostingFunctionEditor: FC = () => {
	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<h3 className="costing-function-editor--header">
				{getString('COSTING_FUNCTION_EDITOR_TITLE')}
			</h3>
			<div className="costing-function-editor--body">
				<Step1 />
				<Step2 />
				<Step3 />
			</div>
		</NavBarAndMaterial>
	)
}

export default CustomizeCostingFunctionEditor
