import {
	ADMIN_PRINTER_HOLE_SIZE_TIP,
	PRINTER_TABLE_TEXTS,
	ADMIN_PRINTER_TRAY_WEIGHT_TIP,
	ADMIN_PRINTER_MAINTENANCE_PERCENTAGE_TIP,
	ADMIN_PRINTER_MAINTENANCE_FIXED_TIP,
	ADMIN_PRINTER_BUILD_RATE_TIP,
	ADMIN_PRINTER_MACHINE_COST_TIP,
	ADMIN_PRINTER_HOURLY_TIP,
	ADMIN_PRINTER_LAYER_TIP,
	ADMIN_PRINTER_INFRASTRUCTURE_TIP,
	ADMIN_TYPICAL_KWH_TIP
} from '../../../../Services/Strings'

export const NUMBER = 'number'
export const LINK = 'link'

export const standardPrinterProperties = [
	{ name: 'name', label: PRINTER_TABLE_TEXTS.NAME },
	{ name: 'company', label: PRINTER_TABLE_TEXTS.COMPANY },
	{
		name: 'technology',
		label: PRINTER_TABLE_TEXTS.TECHNOLOGY,
		isSelectedItems: true
	},
	{
		name: 'description',
		label: PRINTER_TABLE_TEXTS.DESCRIPTION,
		multiline: true
	},
	{ name: 'trayX', label: PRINTER_TABLE_TEXTS.TRAY_X, type: NUMBER },
	{ name: 'trayY', label: PRINTER_TABLE_TEXTS.TRAY_Y, type: NUMBER },
	{ name: 'trayZ', label: PRINTER_TABLE_TEXTS.TRAY_Z, type: NUMBER },
	{
		name: 'buildRate',
		label: PRINTER_TABLE_TEXTS.BUILD_RATE,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_BUILD_RATE_TIP
	},
	{
		name: 'machineCost',
		label: PRINTER_TABLE_TEXTS.MACHINE_COST,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_MACHINE_COST_TIP
	},
	{ name: 'reviewsLink', label: PRINTER_TABLE_TEXTS.REVIEWS_LINK, type: LINK },
	{
		name: 'defaultLayerThickness',
		label: PRINTER_TABLE_TEXTS.DEFAULT_LAYER,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_LAYER_TIP
	},
	{
		name: 'hourlyCost',
		label: PRINTER_TABLE_TEXTS.HOURLY_COST,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_HOURLY_TIP
	},
	{
		name: 'minimalTolerance',
		label: PRINTER_TABLE_TEXTS.MINIMAL_TOLERANCE,
		type: NUMBER
	},
	{ name: 'accuracy', label: PRINTER_TABLE_TEXTS.ACCURACY, type: NUMBER },
	// { name: 'link', label: PRINTER_TABLE_TEXTS.LINK, type: LINK },
	{
		name: 'infrastructureCostAsPercentOfMachineCost',
		label: PRINTER_TABLE_TEXTS.INFRASTRUCTURE_COST,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_INFRASTRUCTURE_TIP
	},
	{
		name: 'holeThresholdMM',
		label: PRINTER_TABLE_TEXTS.HOLE_THRESHOLD,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_HOLE_SIZE_TIP
	},
	{
		name: 'trayWeightLimitation',
		label: PRINTER_TABLE_TEXTS.TRAY_WEIGHT,
		type: NUMBER,
		iIcon: ADMIN_PRINTER_TRAY_WEIGHT_TIP
	},
	{
		name: 'typicalKwh',
		label: PRINTER_TABLE_TEXTS.TYPICAL_KWH,
		type: NUMBER,
		iIcon: ADMIN_TYPICAL_KWH_TIP
	}
]

export const maintenanceAsPercent = {
	name: 'maintenanceAsPercentOfMachineCostPerYear',
	label: PRINTER_TABLE_TEXTS.MAINTENANCE_AS_PERCENT,
	type: NUMBER,
	iIcon: ADMIN_PRINTER_MAINTENANCE_PERCENTAGE_TIP
}

export const maintenanceAsFixed = {
	name: 'maintenanceFixedMachineCostPerYear',
	label: PRINTER_TABLE_TEXTS.MAINTENANCE_AS_FIXED,
	type: NUMBER,
	iIcon: ADMIN_PRINTER_MAINTENANCE_FIXED_TIP
}

export const printerCheckboxes = [
	{ name: 'isMainstream', label: PRINTER_TABLE_TEXTS.IS_MAINSTREAM },
	{ name: 'isDesktop', label: PRINTER_TABLE_TEXTS.IS_DESKTOP }
]

export const maintenanceMachineSelector = {
	name: PRINTER_TABLE_TEXTS.MAINTENANCE_MACHINE_COST,
	items: [
		{ key: PRINTER_TABLE_TEXTS.AS_PERCENT, value: 1 },
		{ key: PRINTER_TABLE_TEXTS.AS_FIXED, value: 2 }
	]
}

export const checkErrorValues = [
	{ name: 'name', checkIfEmpty: true },
	{ name: 'company', checkIfEmpty: true },
	{ name: 'description', checkIfEmpty: true },
	{
		name: 'buildRate',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 10000000,
		isRequired: true
	},
	{
		name: 'trayX',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 3000,
		isRequired: true
	},
	{
		name: 'trayY',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 3000,
		isRequired: true
	},
	{
		name: 'trayZ',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 3000,
		isRequired: true
	},
	{
		name: 'machineCost',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 10000000,
		isRequired: true
	},
	{
		name: 'hourlyCost',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 1,
		max: 3000,
		isRequired: true
	},
	{
		name: 'holeThresholdMM',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0.1,
		max: 20,
		isRequired: true
	},
	{
		name: 'trayWeightLimitation',
		checkIfEmpty: false,
		checkIfMinMax: true,
		min: 0.01,
		max: 100
	},
	{
		name: 'infrastructureCostAsPercentOfMachineCost',
		checkIfNull: true,
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 1,
		isRequired: true
	},
	{
		name: 'maintenanceAsPercentOfMachineCostPerYear',
		checkIfNull: true,
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0,
		max: 1,
		isRequired: true
	},
	{
		name: 'maintenanceFixedMachineCostPerYear',
		checkIfNull: true,
		checkIfEmpty: true
	},
	{ name: 'accuracy', checkIfNull: true, checkIfEmpty: true },
	{ name: 'reviewsLink', checkIfLink: true },
	{ name: 'technology', checkIfEmpty: true },
	{ name: 'minimalTolerance', checkIfEmpty: true },
	{
		name: 'defaultLayerThickness',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 10,
		max: 5000,
		isRequired: true
	},

	//{ name: 'link', checkIfLink: true },
	{
		name: 'typicalKwh',
		checkIfEmpty: true,
		checkIfMinMax: true,
		min: 0.1,
		max: 350,
		isRequired: true
	}
]

export const initialPrinterState = {
	name: '',
	company: '',
	description: '',
	buildRate: '',
	trayX: '',
	trayY: '',
	trayZ: '',
	machineCost: '',
	hourlyCost: '',
	holeThresholdMM: '',
	trayWeightLimitation: '',
	infrastructureCostAsPercentOfMachineCost: null,
	maintenanceAsPercentOfMachineCostPerYear: null,
	maintenanceFixedMachineCostPerYear: null,
	reviewsLink: '',
	defaultLayerThickness: '',
	accuracy: null,
	minimalTolerance: '',
	technology: '',
	isMainstream: false,
	typicalKwh: ''
}
