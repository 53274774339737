export interface ISolutionBenefit {
  id?: number
  type: benefitType
  name: string
  description: string
  partSolution: any
  score: number
}

export enum benefitType {
  buyToFly = 'buy-to-fly',
  complexityScore = 'complexity-score',
  costSaving = 'cost-saving',
  timeSaving = 'time-saving',
  weightReduction = 'weight-reduction'
}
