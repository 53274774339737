import React, { Component } from 'react'
import TagsInput from 'react-tagsinput'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'

import { ItemGrid } from '../thirdParty/CreativeTim/components'
import ButtonWithLoader from '../ButtonWithLoader'
import interactor from './CastorTagsInteractor'
import { Danger } from '../thirdParty/CreativeTim/components'
import { ITEM_ALLREADY_EXIST, ADD, SCHEME } from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'

import { tagsStyles } from './CastorTagsStyle.css'
import './CastorTags.scss'

class CastorTags
  extends Component {
  constructor (props)
  {
    super(props)
    const handleState = newState => {
      this.setState(newState)
    }
    this.interactor = new interactor(handleState)
    this.state = this.interactor.setInitialState(props.selectedItems)
  }

  onSelectFieldChange = event => {
    this.interactor.onSelectFieldChange(this.props.labels, event.target.value)
  }

  defaultRenderLayout = (tagComponents, inputComponent) => {
    return (
      <span>
        {inputComponent}
        {tagComponents}
      </span>
    )
  }

  renderSelectField = () => {
    if (!this.state.showSelectField) {
      return <div />
    }

    return (
      <Select
        value={this.state.selectedItem}
        onChange={this.onSelectFieldChange}
        inputProps={{
          name: 'simpleSelect',
          id: 'simple-select'
        }}
        MenuProps={{
          PaperProps: {
            style: {
              transform: 'translate3d(0, 0, 0)'
            }
          }
        }}
      >
        {this.props.selectedItems.map(item => {
          return <MenuItem value={item}>{item}</MenuItem>
        })}
      </Select>
    )
  }

  handleTags = (newItemsArr, tag, index) => {
    const { tags, handleTags } = this.props

    this.interactor.handleTags(newItemsArr, tag, index, tags, handleTags)
  }

  returnToDefault = () => {
    const { handleTags, filteredGlobalOffTheShelf } = this.props

    this.interactor.handleTags(
      filteredGlobalOffTheShelf, [], [], filteredGlobalOffTheShelf, handleTags,
      true)
  }

  onTextFieldChange = (onChange, e) => {
    this.interactor.onTextFieldChange()
    onChange(e)
  }

  showItemExistAlert = () => {
    if (this.state.itemAllreadyExist) {
      return <Danger>{ITEM_ALLREADY_EXIST}</Danger>
    }
    return <div />
  }

  renderInput = props => {
    const { onChange, value, addTag, ...other } = props
    const { loading } = this.props
    return (
      <div>
        <div style={tagsStyles.textFieldDiv}>
          <TextField
            onChange={this.onTextFieldChange.bind(this, onChange)}
            value={this.state.savedValue ? this.state.savedValue : value}
            {...other}
            style={{ width: '50%' }}
          />

          {this.renderSelectField()}

          <ButtonWithLoader
            size={30}
            loading={loading}
            onClick={() => this.refs.customizeTag.accept()}
            color="primary"
            style={{ padding: '0.5rem 1.5rem', width: 'unset' }}
          >
            {ADD}
          </ButtonWithLoader>
        </div>
        {this.showItemExistAlert()}
      </div>
    )
  }

  renderTag = props => {
    let {
      tag,
      key,
      disabled,
      onRemove,
      classNameRemove,
      getTagDisplayValue,
      className,
      ...other
    } = props
    className = className + ` ${tag.label} tag-text`
    return (
      <span title={tag.itemType} key={key} className={className} {...other}>
        {getTagDisplayValue(tag.text)}
        {!disabled && (
          <a
            className={classNameRemove}
            style={{ top: 1 }}
            onClick={e => onRemove(key)}
          />
        )}
      </span>
    )
  }

  handleChangeInput = tag => {
    this.interactor.handleChangeInput(tag, this.props.labels)
  }

  render () {
    const { tags } = this.props
    return (
      <div>
        <ItemGrid>
          <TagsInput
            removeKeys={[]}
            ref="customizeTag"
            value={tags}
            onChange={this.handleTags}
            inputProps={{
              className: 'react-tagsinput-input',
              placeholder: `${this.state.selectedItem} ${SCHEME}`
            }}
            renderInput={this.renderInput}
            renderTag={this.renderTag}
            inputValue={this.state.tag.text}
            onChangeInput={this.handleChangeInput}
            renderLayout={this.defaultRenderLayout}
          />
        </ItemGrid>
        <div className="tag-buttons">
          <ButtonWithLoader
            className="customize-solution-filter-secondary"
            color="secondary"
            onClick={this.returnToDefault}
            style={{ padding: '0.5rem 1.5rem', width: 'unset' }}
          >
            {getString('RETURN_TO_DEFAULT')}
          </ButtonWithLoader>
        </div>
      </div>
    )
  }
}

export default CastorTags
