import {
	CHECK_SYSTEM_PARAMETERS,
	HANDLE_LOADER,
	RESET_LOADER
} from '../global actions/types'

const INITIAL_STATE = {
	loaderCounter: 0,
	isOnPrem: false,
	isAllowRegister: false
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case HANDLE_LOADER:
			return {
				...state,
				loaderCounter: (state.loaderCounter += action.payload)
			}
		case RESET_LOADER:
			return {
				...state,
				loaderCounter: INITIAL_STATE.loaderCounter
			}
		case CHECK_SYSTEM_PARAMETERS:
			return {
				...state,
				isOnPrem: action.payload.isOnPrem,
				isAllowRegister: action.payload.isAllowRegister
			}
		default:
			return state
	}
}
