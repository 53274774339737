import { Action, ActionWithPayload } from '../../../global actions/ActionModels'
import {
	CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP,
	HANDLE_NOTIFICATION,
	PROJECT_PART_WEIGHT_REDUCTION_SENT,
	WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT
} from '../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../global actions/types/CastorAlertTypes'
import {
	BACK_PROGRESS_CLICKED,
	CANCEL_PROGRESS,
	COMPANY_SELECTOR_CHANGED,
	DONE_PROGRESS_CLICKED,
	HIDE_WEIGHT_REDUCTION_MODEL,
	MATERIAL_SELECTOR_CHANGED,
	NEXT_STEP_CLICKED,
	PRINTER_SELECTOR_CHANGED,
	PROGRESS_ENDED,
	RESET_PROGRESS,
	SHOW_WEIGHT_REDUCTION_MODEL,
	WEIGHT_REDUCTION_PROGRESS_SETUP
} from '../../../global actions/types/WeightReductionProgressTypes'
import { WeightReductionType } from '../../../Services/models/IPart'
import { FeaResult, ISolutionFea } from '../../../Services/models/ISolutionFea'
import {
	WeightReductionGen2Type,
	WeightReductionMode
} from '../../../Services/models/IWeightReductionGen2'
import {
	createFeaRequest,
	getCaeplexRedirectURL
} from '../../../Services/Network/PartAnalysisNetwork'
import { weightReductionGen2Request } from '../../../Services/Network/ProjectAnalysisNetwork'
import {
	CONFIGURATION_CALCULATION_ERROR,
	OK,
	SHOW_NOTIFICATION
} from '../../../Services/Strings'
import { getString } from '../../../Services/Strings/StringService'
import { SimpleConfigurationSelectorService } from '../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { AlertType } from '../alerts/AlertTypes'
import { PROGRESS_STEPS, RADIO_BUTTONS_VALUE } from './index'
import { IBestMatchData } from 'Services/models/IBestMatch'

let simpleConfigurationSelectorService: SimpleConfigurationSelectorService

export const setup = (
	configuration: any,
	partId: number,
	feaExistAndPassed: boolean | undefined,
	weightReductionPartsLeadingData?: IBestMatchData[],
	customInitialStep?: number
): Action<any> => {
	const configurationData = getBestMatchConfigurationData(
		configuration,
		partId,
		weightReductionPartsLeadingData,
		feaExistAndPassed
	)
	return {
		type: WEIGHT_REDUCTION_PROGRESS_SETUP,
		payload: { initialStep: customInitialStep, configurationData }
	}
}
export const onNextStepClick = (
	dispatch: any,
	customInitialStep: any,
	canPerformWeightReduction: boolean,
	onCancel: Function
): any => {
	if (!canPerformWeightReduction) {
		dispatch({ type: HIDE_WEIGHT_REDUCTION_MODEL })
		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: getString('WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_TEXT'),
				headerTitle: getString('WEIGHT_REDUCTION_PROGRESS_FDM_ALERT_HEADER'),
				alertType: AlertType.WARNING,
				onConfirm: () => {
					dispatch({ type: SHOW_WEIGHT_REDUCTION_MODEL })
					dispatch({ type: ALERT_POPUP_CANCELED })
					dispatch({
						type: NEXT_STEP_CLICKED
					})
				},
				onCancel: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
					dispatch({ type: RESET_PROGRESS })
					onCancel()
					onCancelClick()
				},
				showCancel: false,
				confirmText: OK
			}
		})
	} else {
		dispatch({
			type: NEXT_STEP_CLICKED
		})
	}
}
export const onConfirmButtonClicked = (
	fromPart: boolean,
	stepValue: number,
	configurationData: any,
	isSTLFile: boolean,
	hasBrepData: boolean,
	customInitialStep: number,
	onCancel: Function,
	radioStep2Value?: string,
	feaId?: string | null,
	partFeas?: Array<ISolutionFea>
) => {
	return (dispatch: any) => {
		if (stepValue === PROGRESS_STEPS.STEP_2) {
			switch (radioStep2Value) {
				case RADIO_BUTTONS_VALUE.FAST_TRACK_RADIO_BUTTON_VALUE:
					onFastTrackChoose(
						dispatch,
						configurationData?.id,
						configurationData?.part
					)
					onCancel()
					return
				case RADIO_BUTTONS_VALUE.OPTIMIZED_TRACK_RADIO_BUTTON_VALUE:
					onOptimizedTrackChoose(
						dispatch,
						onCancel,
						fromPart,
						configurationData?.id,
						configurationData?.part,
						isSTLFile,
						hasBrepData,
						configurationData?.weightReductionType,
						configurationData?.feaExistAndPassed,
						feaId || configurationData?.feaId,
						partFeas
					)
					return
				default:
					return
			}
		}
		const canPerformWeightReduction =
			configurationData && configurationData.canPerformWeightReduction
		onNextStepClick(
			dispatch,
			customInitialStep,
			canPerformWeightReduction,
			onCancel
		)
	}
}
export const onCancelClick = (): Action<any> => {
	return {
		type: CANCEL_PROGRESS
	}
}
export const updateProjectFlag = (): Action<any> => {
	return {
		type: WEIGHT_REDUCTION_PROCESS_STARTED_FROM_PROJECT
	}
}
export const onBackClick = (): Action<any> => {
	return {
		type: BACK_PROGRESS_CLICKED
	}
}

export const onCompanySelectorChange = (
	value: string,
	data: any
): ActionWithPayload<any> => {
	simpleConfigurationSelectorService = new SimpleConfigurationSelectorService(
		data
	)
	const printersList =
		simpleConfigurationSelectorService.getPrintersNamesList(value)

	return {
		type: COMPANY_SELECTOR_CHANGED,
		payload: { value, printersList }
	}
}
export const onPrinterSelectorChange = (
	value: string
): ActionWithPayload<any> => {
	const materialsList =
		simpleConfigurationSelectorService.getPrinterMaterialsList(value)

	return {
		type: PRINTER_SELECTOR_CHANGED,
		payload: { value, materialsList }
	}
}
export const onMaterialSelectorChange = (
	value: string
): ActionWithPayload<any> => {
	return {
		type: MATERIAL_SELECTOR_CHANGED,
		payload: { value }
	}
}
export const onFastTrackChoose = async (
	dispatch: any,
	configurationId: number,
	partId: number
) => {
	try {
		if (configurationId == null || partId === null) {
			throw new Error('configurationId or partId is missing')
		}
		dispatch({ type: DONE_PROGRESS_CLICKED })
		await weightReductionGen2Request(
			configurationId,
			WeightReductionGen2Type.FAST
		)
		dispatch({
			type: PROJECT_PART_WEIGHT_REDUCTION_SENT,
			payload: { partId }
		})
		dispatch({
			type: ALERT_POPPED,
			payload: {
				headerTitle: getString('WEIGHT_REDUCTION_PROGRESS_MODEL_HEADER'),
				confirmText: getString('OK'),
				text: getString('WEIGHT_REDUCTION_FEA_SUCCEEDED'),
				showCancel: false,
				onConfirm: () => {
					dispatch({ type: ALERT_POPUP_CANCELED })
				}
			}
		})
		dispatch({ type: RESET_PROGRESS })
		dispatch({ type: CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP })
	} catch (error: any) {
		console.error(error)
		dispatch({ type: PROGRESS_ENDED })
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: CONFIGURATION_CALCULATION_ERROR
			}
		})
	}
}
export const onOptimizedTrackChoose = async (
	dispatch: any,
	onCancel: Function,
	fromPart: boolean,
	id: number,
	partId: number,
	stlFile: boolean,
	hasBrepData: boolean,
	weightReductionType: WeightReductionType,
	feaExistAndPassed: boolean,
	feaId?: string | null,
	partFeas?: Array<ISolutionFea>
) => {
	try {
		if (id == null || partId === null) {
			throw new Error('id or partId is missing')
		}
		dispatch({ type: DONE_PROGRESS_CLICKED })
		feaId = getFeaId(id, feaId, partFeas)
		const weightReductionMode =
			feaExistAndPassed && feaId
				? WeightReductionMode.surfaceSelection
				: WeightReductionMode.full
		if (stlFile || !hasBrepData) {
			partNotFitEndingProgress(dispatch, fromPart, onCancel)
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('WEIGHT_REDUCTION_PROGRESS_MODEL_HEADER'),
					confirmText: getString('OK'),

					text: !hasBrepData
						? getString('WEIGHT_REDUCTION_NO_BREP_DATA_FEA_NOT_POSSIBLE_MSG')
						: getString('WEIGHT_REDUCTION_STL_FEA_NOT_POSSIBLE_MSG'),
					showCancel: false,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
			return
		}
		if (
			feaExistAndPassed &&
			weightReductionType !== WeightReductionType.SUITABLE_FOR_WR
		) {
			dispatch({ type: RESET_PROGRESS })

			await weightReductionGen2Request(id, WeightReductionGen2Type.COMPLEX)
			dispatch({
				type: PROJECT_PART_WEIGHT_REDUCTION_SENT,
				payload: { partId }
			})
			dispatch({ type: CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP })
			onCancel()
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: getString('WEIGHT_REDUCTION_PROGRESS_MODEL_HEADER'),
					confirmText: getString('OK'),

					text: getString('WEIGHT_REDUCTION_FEA_SUCCEEDED'),
					showCancel: false,
					onConfirm: () => {
						dispatch({ type: ALERT_POPUP_CANCELED })
					}
				}
			})
			return
		}
		const backToProjectPage = !fromPart
		const {
			data: { requestUrl, request }
		} = (await createFeaRequest(
			partId,
			id,
			backToProjectPage,
			feaId,
			weightReductionMode
		)) as any
		const response = await getCaeplexRedirectURL(requestUrl, request)
		const { redirectURL } = JSON.parse(response)

		dispatch({ type: PROGRESS_ENDED })
		window.location.replace(redirectURL)
	} catch (error: any) {
		console.error(error)
		dispatch({ type: PROGRESS_ENDED })
		dispatch({
			type: HANDLE_NOTIFICATION,
			payload: {
				notificationType: SHOW_NOTIFICATION.ERROR,
				notificationMessage: CONFIGURATION_CALCULATION_ERROR || error.message
			}
		})
	}
}
const partNotFitEndingProgress = (
	dispatch: any,
	fromPart: boolean,
	onCancel: Function
) => {
	dispatch({ type: CLOSE_PART_REDUCE_WEIGHT_PROGRESS_POPUP })
	if (fromPart) {
		onCancel()
	}
	dispatch({ type: RESET_PROGRESS })
}
const getBestMatchConfigurationData = (
	configuration: any,
	partId: number,
	weightReductionPartsLeadingData?: IBestMatchData[],
	feaExistAndPassed?: boolean
) => {
	if (!weightReductionPartsLeadingData || !partId) {
		return
	}
	let configurationData
	if (configuration && configuration?.id > 0) {
		const { id, part, result } = configuration
		configurationData = {
			id,
			part: part.id,
			result,
			feaExistAndPassed,
			weightReductionType: part.weightReductionType
		}
	}
	if (weightReductionPartsLeadingData?.length) {
		configurationData = weightReductionPartsLeadingData.find((obj: any) => obj.part === partId)
	}
	return configurationData
}

const getFeaId = (
	configurationId: number,
	feaId?: string | null,
	partFeas?: Array<ISolutionFea>
) => {
	if (feaId && feaId !== '') {
		const feaObject: any = partFeas?.find(obj => obj?.id === feaId)
		if (
			feaObject &&
			feaObject.result !== null &&
			feaObject.result !== FeaResult.failed
		) {
			return feaId
		} else {
			return null
		}
	}
	if (!partFeas || !partFeas.length) {
		return null
	}
	const feaObject: any = partFeas?.find(
		obj => obj?.configuration?.id === configurationId
	)
	if (
		feaObject &&
		feaObject.result !== null &&
		feaObject.result !== FeaResult.failed
	) {
		return feaObject.id
	}
	return null
}
