import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import AddIcon from '@material-ui/icons/Add'

import * as CustomizeUserMaterialsActions from './CustomizeUserMaterialsActions'
import { customizeNewUserMaterial } from '../../../../Services/routeFuncs'
import { getString } from '../../../../Services/Strings/StringService'
import { getThemeString } from '../../../../themes/getThemeString'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import CastorFormHeader from '../../../Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import CustomizeUserMaterialsTable from './CustomizeUserMaterialsTable'

import './CustomizeUserMaterials.css'

class CustomizeUserMaterials extends Component {
	componentDidMount() {
		const { setupUserMaterials, match } = this.props
		setupUserMaterials(match)
	}

	renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getThemeString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getThemeString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	renderNewButton = () => {
		const { userId } = this.props
		return (
			<Link className="new-material-link" to={customizeNewUserMaterial(userId)}>
				<Button>
					<AddIcon />
					{getString('USER_MATERIAL_ADD_NEW')}
				</Button>
			</Link>
		)
	}

	setUserMaterialsContent = () => {
		return (
			<div>
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_USER_MATERIAL_TITLE')}
					explanationArray={getThemeString(
						'CUSTOMIZE_USER_MATERIAL_EXPLANATION_ARRAY'
					)}
					isInCard={true}
				/>
				{this.renderNewButton()}
				<CustomizeUserMaterialsTable />
			</div>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
				<CastorForm
					formHeader={this.renderFormHeader()}
					formTitle={getString('CUSTOMIZE_FORM_USER_MATERIAL_HEADER')}
					content={this.setUserMaterialsContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const mapStateToProps = ({ CustomizeUserMaterialsReducer }) => {
	const { userId } = CustomizeUserMaterialsReducer
	return {
		userId
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomizeUserMaterials)
