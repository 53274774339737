import { toNumber } from 'lodash'

import {
	GET_PROJECTS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED,
	PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED,
	RESET_AUTH_STATE,
	USER_HOME_CRUMB_NAME_CHANGED,
	USER_HOME_CRUMB_NAMES_CHANGED,
	USER_HOME_CRUMB_RESET,
	USER_HOME_DRAW_TOGGLE,
	USER_HOME_PROJECT_REMOVED,
	USER_HOME_ROUTE_CHANGED
} from '../../global actions/types'
import userHomeRoutes from '../../routes/app'
import { PROJECTS_ROUTE } from '../../Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from '../../Services/models/Features'
import { getString } from '../../Services/Strings/StringService'
import { clustersToRoutes, projectsToRoutes } from './UserHomeService'

const INITIAL_STATE = {
	loading: false,
	projects: [],
	routes: userHomeRoutes,
	error: null,
	mobileOpen: false,
	miniActive: false,
	dateTime: Date.now(),
	setupCalled: false,
	crumbs: [],
	flatRoutes: [],
	projectsStatusPollerStarted: false,
	showSideBar: true
}

const userHomeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROJECTS:
			return { ...state, loading: true, setupCalled: true }

		case USER_HOME_ROUTE_CHANGED:
			return { ...state, crumbs: action.payload.crumbs }

		case USER_HOME_CRUMB_RESET: {
			return {
				...state,
				crumbs: INITIAL_STATE.crumbs
			}
		}

		case USER_HOME_CRUMB_NAME_CHANGED: {
			const { crumbNewName, changeNameWith } = action.payload
			const flatRoutes = state.flatRoutes.map(route => {
				if (route.changeNameWith === changeNameWith) {
					route.name = crumbNewName
					return route
				}
				return route
			})
			return { ...state, flatRoutes }
		}

		case USER_HOME_CRUMB_NAMES_CHANGED: {
			const { updateCrumbs } = action.payload
			const flatRoutes = state.flatRoutes.map(route => {
				if (route.changeNameWith) {
					route.name = updateCrumbs[route.changeNameWith]
					return route
				}
				return route
			})
			return { ...state, flatRoutes }
		}

		case GET_PROJECTS_SUCCESS:
			const { projects, features, maxAllowedUploadProjects, showSideBar } =
				action.payload

			const disableUploadNavbar =
				projects &&
				projects.length >= toNumber(maxAllowedUploadProjects) &&
				toNumber(maxAllowedUploadProjects) === 1
			const routes = createRoutes(projects, features, disableUploadNavbar)
			const flatRoutes = []
			routes.map(route => {
				flatRoutes.push({ ...route })
				if (route.views?.length) {
					route.views.map(routeView => {
						flatRoutes.push({ ...routeView })
						if (routeView.views?.length) {
							routeView.views.map(r => flatRoutes.push({ ...r }))
						}
					})
				}
			})

			//check if sidebar was provided,
			//if not use from state
			const sideBarIsOn = !!showSideBar
				? showSideBar !== 'false'
				: state.showSideBar

			return {
				...state,
				projects: action.payload,
				routes,
				flatRoutes,
				showSideBar: sideBarIsOn,
				loading: false
			}
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_STARTED:
			return { ...state, projectsStatusPollerStarted: true }
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_FINISHED:
			return { ...state, projectsStatusPollerStarted: false }
		case PROJECTS_REANALYSIS_RECALCULATE_POLLER_FAILED:
			return { ...state, projectsStatusPollerStarted: false }

		case GET_PROJECTS_FAILED:
			return { ...state, loading: false, error: action.payload }
		case USER_HOME_DRAW_TOGGLE:
			return { ...state, mobileOpen: !state.mobileOpen }
		case USER_HOME_PROJECT_REMOVED:
			const { projectId } = action.payload
			return {
				...state,
				routes: removeProjectRoute(projectId, state.routes),
				dateTime: Date.now()
			}
		case RESET_AUTH_STATE:
			return { ...INITIAL_STATE }

		default:
			return state
	}
}

const removeProjectRoute = (projectId, routes) => {
	var projectsRouteIndex = routes.findIndex(route =>
		route.path.endsWith(PROJECTS_ROUTE + '/:projectId')
	)
	routes[projectsRouteIndex].views = routes[projectsRouteIndex].views.filter(
		view => view.project?.id !== projectId && view.folderId !== projectId
	)

	routes[projectsRouteIndex].views = routes[projectsRouteIndex].views.map(
		view => {
			if (view.views) {
				view.views = view.views.filter(v => v.project?.id !== projectId)
			}
			return view
		}
	)

	return routes
}

const createRoutes = (projects, features, disableUploadNavbar) => {
	let routes = userHomeRoutes
	const userPanel = Feature.isFeatureOn(FeatureComponentId.USER_PANEL, features)
	const projectBundle = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE,
		features
	)
	const projectPartsInSideBar = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_PARTS_IN_SIDEBAR,
		features
	)
	const userPanelIndex = routes.findIndex(
		route => route.name === getString('MY_PANEL')
	)
	const foundIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_MY_PROJECTS')
	)
	const foundClustersIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_ANALYSIS_CLUSTER')
	)
	const foundUploadIndex = routes.findIndex(
		x => x.name === getString('NAV_TITLE_PROJECT_UPLOAD')
	)

	if (routes[foundIndex]) {
		routes[foundIndex].views = projectsToRoutes(
			projects,
			projectBundle,
			projectPartsInSideBar
		)
	}
	if (routes[foundClustersIndex]) {
		routes[foundClustersIndex].views = clustersToRoutes(projects, projectBundle)
	}
	if (userPanelIndex >= 0) {
		routes[userPanelIndex].sidebar = userPanel
	}

	if (disableUploadNavbar) {
		routes[foundUploadIndex].sidebar = userPanel
	}

	return routes
}

export default userHomeReducer
