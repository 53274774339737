import { cloneDeep, findIndex, isBoolean, some } from 'lodash'

import {
	CHANGE_ADVANCED_FILTERS_CHECKBOX,
	CHANGE_ADVANCED_FILTERS_TECHNOLOGY,
	CHANGE_ADVANCED_FILTERS_VALUE,
	CHANGE_ADVANCED_FILTERS_VALUES,
	REMOVE_ADVANCED_FILTERS,
	RESET_ADVANCED_FILTERS,
	SAVE_ADVANCED_FILTERS,
	SETUP_ADVANCED_FILTERS
} from './AdvancedSettingsTypes'
import { Action } from 'global actions/ActionModels'
import { getDisplayFilterItemsList } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import { getString } from 'Services/Strings/StringService'

export class AdvancedSettingsInitialState {
	readonly tempFilters: Array<any> = []
	readonly filters: Array<any> = []
	readonly filterItemsList: Array<any> = []
	readonly isError: boolean = false
}

const initialAdvancedState: AdvancedSettingsInitialState =
	new AdvancedSettingsInitialState()

const AdvancedSettingsReducer = (
	state = initialAdvancedState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case SETUP_ADVANCED_FILTERS: {
			const { preparedAdvancedSettings } = payload

			const filterItemsList = getDisplayFilterItemsList(
				preparedAdvancedSettings
			)

			return {
				...state,
				filters: preparedAdvancedSettings,
				tempFilters: preparedAdvancedSettings,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_VALUE: {
			const { filterName, filterValue, filterError } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].value = filterValue
			updatedFilters[changedIndex].error = filterError

			return {
				...state,
				tempFilters: updatedFilters,
				isError: some(updatedFilters, filter => !!filter.error)
			}
		}

		case CHANGE_ADVANCED_FILTERS_VALUES: {
			const { filterValues, materialFilterReset } = payload

			let updatedFilters = cloneDeep(state.tempFilters)

			if (materialFilterReset) {
				updatedFilters = updatedFilters.map(filter => {
					// reset values only for non-user change
					if (!filter.manualCheck) {
						filter.checked = filter.prevChecked
					}
					return filter
				})
			}

			filterValues &&
				Object.keys(filterValues).map(key => {
					const changedIndex = findIndex(
						updatedFilters,
						filter => filter.name === key
					)
					if (changedIndex != -1 && !!filterValues[key]) {
						updatedFilters[changedIndex].checked = filterValues[key]
						updatedFilters[changedIndex].value = filterValues[key]
					}
				})

			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_TECHNOLOGY: {
			const { filterName, filterValue } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].value = filterValue

			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case CHANGE_ADVANCED_FILTERS_CHECKBOX: {
			const { filterChecked, filterName } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			const oldIndex = findIndex(
				state.filters,
				filter => filter.name === filterName
			)

			updatedFilters[changedIndex].checked = filterChecked
			updatedFilters[changedIndex].disabled = !filterChecked
			// we need to keep info if the param was changed by click
			updatedFilters[changedIndex].manualCheck = true

			if (!filterChecked) {
				updatedFilters[changedIndex].value =
					state.filters[oldIndex].defaultValue

				if (updatedFilters[changedIndex].type !== 'boolean') {
					updatedFilters[changedIndex].value = null
				}

				updatedFilters[changedIndex].error = null
			}

			if (filterChecked && updatedFilters[changedIndex].value == null) {
				updatedFilters[changedIndex].error = getString('PROVIDE_THE_VALUE')
			}

			return {
				...state,
				tempFilters: updatedFilters,
				isError: some(updatedFilters, filter => !!filter.error)
			}
		}

		case REMOVE_ADVANCED_FILTERS: {
			const { filterName } = payload

			const updatedFilters = cloneDeep(state.tempFilters)
			const changedIndex = findIndex(
				updatedFilters,
				filter => filter.name === filterName
			)
			updatedFilters[changedIndex].checked = false
			updatedFilters[changedIndex].value =
				updatedFilters[changedIndex].defaultValue
			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				filterItemsList
			}
		}

		case SAVE_ADVANCED_FILTERS: {
			const updatedFilters = state.tempFilters.map(filter => {
				if (filter.checked && filter.value === null) {
					filter.value = filter.defaultValue
				}
				return filter
			})
			const filterItemsList = getDisplayFilterItemsList(updatedFilters)

			return {
				...state,
				filters: updatedFilters,
				tempFilters: updatedFilters,
				filterItemsList,
				isError: false
			}
		}

		case RESET_ADVANCED_FILTERS: {
			const filterItemsList = getDisplayFilterItemsList(state.filters)
			return {
				...state,
				tempFilters: state.filters,
				filterItemsList,
				isError: false
			}
		}

		default:
			return state
	}
}

export default AdvancedSettingsReducer
