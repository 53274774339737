import React, { ChangeEvent, FC, Fragment, memo, useState } from 'react'
import { events } from 'react-joyride'
import { useDispatch } from 'react-redux'

import SolutionAnalysisHeaderButtons from './SolutionAnalysisHeaderButtons'
import SolutionPdfModal from './SolutionExportPdf/SolutionPdfModal'
import SolutionHeaderName from './SolutionHeaderName'
import { ActionWithPayload } from 'global actions/ActionModels'
import Flexbox from 'Scenes/Components/FlexBox'
import { onToggleGrabCad } from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisActions'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { ISolutionBenefit } from 'Services/models/ISolutionBenefit'
import { IUserProvider } from 'Services/models/IUserProvider'

import '../SolutionAnalysis.scss'

interface IProps {
	benefits: Array<ISolutionBenefit>
	solutionColor: string
	leadingByUserChoice: boolean
	isLeading: boolean
	showSolutionDetails: boolean
	editMode?: boolean
	newSolution?: boolean
	configurationId: number
	showConfigure: boolean
	solutionName: string
	partWeightReducedStlURL: string
	enableSolutionButtons: boolean
	showBenefits: boolean
	isDesktopConfiguration: boolean
	showDownloadButton: boolean
	showWeightReductionButton: boolean
	freezeConfiguration: boolean
	disableConfiguration: boolean
	solution: any
	quantity: any
	userProviders: IUserProvider[]
	sendToLoader: boolean
	isSpecifiedQuantity: boolean
	onConfigureClick: (
		e: any,
		configurationId: number,
		configureBtnElementId: string
	) => void
	onCombineClick: (e: any) => void
	onSetLeadingClick: (e: any) => void
	onRemoveClick: (e: any) => void
	onGetAQClick: (e: any, elementId: string) => void
	on3dExportClick: (e: ChangeEvent<HTMLInputElement>, elementId: string) => void
	onPrintingOptionClick: (
		e: any,
		provider: IUserProvider,
		configurationId: number
	) => void
	onInputFocusOut: Function
	onInputFocusIn: (id: number) => ActionWithPayload<any>
	onSolutionNameChange: (
		id: number,
		solutionName: string
	) => ActionWithPayload<any>
	onWeightReductionClick: (id: number) => any
	canCombine?: boolean
	showFullIcon?: boolean
	isCluster?: boolean
	disableHeader?: boolean
	showEditName?: boolean
	configuration: IConfiguration
	partId: number
	showCrabCad?: boolean
}

const SolutionAnalysisHeader: FC<IProps> = ({
	benefits,
	solutionColor,
	showSolutionDetails,
	editMode,
	newSolution,
	onInputFocusOut,
	onInputFocusIn,
	configurationId,
	showConfigure,
	solutionName,
	enableSolutionButtons,
	onSolutionNameChange,
	leadingByUserChoice,
	isLeading,
	showBenefits,
	solution,
	quantity,
	isDesktopConfiguration,
	showDownloadButton,
	partWeightReducedStlURL,
	showWeightReductionButton,
	freezeConfiguration,
	disableConfiguration,
	canCombine,
	isCluster,
	userProviders,
	sendToLoader,
	isSpecifiedQuantity,
	onCombineClick,
	onConfigureClick,
	onSetLeadingClick,
	onRemoveClick,
	onGetAQClick,
	on3dExportClick,
	onPrintingOptionClick,
	onWeightReductionClick,
	showFullIcon = false,
	disableHeader = false,
	showEditName = false,
	configuration,
	partId,
	showCrabCad
}) => {
	const dispatch = useDispatch()
	const [showModal, setShowModal] = useState(false)
	const showHeaderButtons =
		Feature.isFeatureOn(FeatureComponentId.CONFIGURATION_HEADER) || false

	if (showEditName) {
		return (
			<div className="solution-edit-name">
				<SolutionHeaderName
					showFullIcon={showFullIcon}
					benefits={benefits}
					disableConfiguration={disableConfiguration}
					showSolutionDetails={showSolutionDetails}
					editMode={editMode}
					solutionColor={solutionColor}
					newSolution={newSolution}
					onInputFocusOut={onInputFocusOut}
					onInputFocusIn={onInputFocusIn}
					configurationId={configurationId}
					solutionName={solutionName}
					onSolutionNameChange={onSolutionNameChange}
					showBenefits={showHeaderButtons ? showBenefits : false}
					isDesktopConfiguration={isDesktopConfiguration}
				/>
			</div>
		)
	}

	if (disableHeader) {
		return <></>
	}

	return (
		<Fragment>
			<Flexbox
				className="solution-analysis-header"
				style={{ '--solution-color': solutionColor }}
			>
				<div
					className="solution-analysis-header-best-match"
					style={{ color: solutionColor }}
				>
					<SolutionHeaderName
						showFullIcon={showFullIcon}
						benefits={benefits}
						disableConfiguration={disableConfiguration}
						showSolutionDetails={showSolutionDetails}
						editMode={editMode}
						solutionColor={solutionColor}
						newSolution={newSolution}
						onInputFocusOut={onInputFocusOut}
						onInputFocusIn={onInputFocusIn}
						configurationId={configurationId}
						solutionName={solutionName}
						onSolutionNameChange={onSolutionNameChange}
						showBenefits={showHeaderButtons ? showBenefits : false}
						isDesktopConfiguration={isDesktopConfiguration}
					/>
				</div>
				{showHeaderButtons && (
					<SolutionAnalysisHeaderButtons
						setShowModal={setShowModal}
						isCluster={isCluster}
						canCombine={canCombine}
						showSolutionDetails={showSolutionDetails}
						configurationId={configurationId}
						showConfigure={showConfigure}
						enableSolutionButtons={enableSolutionButtons}
						freezeConfiguration={freezeConfiguration}
						leadingByUserChoice={leadingByUserChoice}
						isLeading={isLeading}
						solution={solution}
						quantity={quantity}
						showDownloadButton={showDownloadButton}
						partWeightReducedStlURL={partWeightReducedStlURL}
						showWeightReductionButton={showWeightReductionButton}
						userProviders={userProviders}
						sendToLoader={sendToLoader}
						onCombineClick={onCombineClick}
						onConfigureClick={onConfigureClick}
						onGetAQClick={onGetAQClick}
						on3dExportClick={on3dExportClick}
						onPrintingOptionClick={onPrintingOptionClick}
						onRemoveClick={onRemoveClick}
						onSetLeadingClick={onSetLeadingClick}
						onWeightReductionClick={() =>
							onWeightReductionClick(configurationId)
						}
						showCrabCad={showCrabCad}
						selectedGrabCad={!!configuration.grabCadPrintConfiguration}
						onToggleGrabCad={() =>
							dispatch(
								onToggleGrabCad(
									partId,
									configuration.grabCadPrintConfiguration,
									configuration.id
								)
							)
						}
					/>
				)}
			</Flexbox>
			{showModal && (
				<SolutionPdfModal
					showModal={showModal}
					configurationId={configurationId}
					setShowModal={setShowModal}
				/>
			)}
		</Fragment>
	)
}

export default memo(SolutionAnalysisHeader)
