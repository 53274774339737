import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import {
	onEditAllToggle,
	onEditCO2Data,
	onEditToggle,
	onUpdateCO2Data
} from 'Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Actions'
import CustomizeCO2Cost from 'Scenes/Home/Customize/CustomizeCO2Cost/CustomizeCO2Cost'
import { getString } from 'Services/Strings/StringService'

const Transportation: FC = () => {
	const co2EmissionProps = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2Reducer
	)
	const dispatch = useDispatch()

	return (
		<div className="material-production--wrapper product-use">
			<div className="material-production--header">
				{getString('TRANSPORTATION')}
			</div>
			<CustomizeCO2Cost
				{...co2EmissionProps}
				onEditToggle={(co2Key: string, isEdit: boolean) =>
					dispatch(onEditToggle(co2Key, isEdit))
				}
				onEditAllToggle={(isEditAll: boolean) =>
					dispatch(onEditAllToggle(isEditAll))
				}
				onEditCO2Data={(co2Key: string, keyLabel: string, value: any) =>
					dispatch(onEditCO2Data(co2Key, keyLabel, value))
				}
				onUpdateCO2Data={(reset: boolean, co2Key?: string) =>
					dispatch(onUpdateCO2Data(reset, co2Key))
				}
			/>
		</div>
	)
}

export default Transportation
