import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import InfoBox from 'Scenes/Components/InfoBox'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { getString } from 'Services/Strings/StringService'

interface TopMaterialInformationProps {
	showCostInRange?: boolean
	printerMaterial: any
}

const TopMaterialInformation: FC<TopMaterialInformationProps> = ({
	showCostInRange,
	printerMaterial
}) => {
	const showInfo = printerMaterial.Category || printerMaterial.description
	const { cluster } = useSelector(
		(state: RootStateOrAny) => state.MainPartAnalysisReducer
	)
	const showMaterialCategory =
		cluster?.formatType === METADATA || showCostInRange

	return (
		<div className="castor-detail with-frame">
			<div className="title-wrapper">
				{!showMaterialCategory && showInfo ? (
					<div>
						<InfoBox
							boxContactElement={
								<div>
									{printerMaterial.Category && (
										<p>
											<span>{getString('PRINTER_TITLE_CATEGORY')}</span>{' '}
											{printerMaterial.Category}
										</p>
									)}
									{printerMaterial.description && (
										<p>
											<span>{getString('PRINTER_TITLE_DESCRIPTION')}</span>{' '}
											{printerMaterial.description}
										</p>
									)}
								</div>
							}
							iconClassName="cost-details-icon"
						/>
					</div>
				) : (
					<div />
				)}
				<div className="castor-detail-title">
					{getString('RECOMMENDED_MATERIAL_LABEL')}
				</div>
			</div>
			<div className={`castor-detail-body detail-body`}>
				{!showMaterialCategory
					? printerMaterial.name
					: printerMaterial.subCategory}
			</div>
		</div>
	)
}

export default memo(TopMaterialInformation)
