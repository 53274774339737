import { FC, memo, useCallback, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { ImageList, ImageListItem } from '@material-ui/core'
import Subheader from '@material-ui/core/ListSubheader'

import { changePartsPage } from '../ProjectAnalysisActions'
import { ProjectAnalysisPartsView } from '../ProjectAnalysisInterfaces'
import LockPartsCars from './LockPartsCars'
import PartsHeader from './PartsHeader'
import PartsListView from './PartsListView/PartsListView'
import PartsSubHeader from './PartsSubHeader'
import ProjectPdfModal from './ProjectExportPDF/ProjectPdfModal'
import ProjectPartTile from './ProjectPartTile'
import { styles } from './style'
import WeightReducedPartsSubHeader from './WeightReducedPartsSubHeader'
import CastorPagination from 'Scenes/Components/CastorPagination/CastorPagination'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const SubheaderTSX: any = Subheader

const ProjectMultipleParts: FC = () => {
	const [showPDFModal, setShowPDFModal] = useState(false)
	const {
		allPartsInapplicable,
		weightReducedParts,
		isSinglePartProject,
		amountOfLockedParts,
		parts,
		project,
		showExportButton,
		is2dProject,
		totalPartsCount,
		searchPhrase,
		selectedFilterPart,
		showStandardCostFinishIcon,
		projectId,
		partsProperties,
		selectedFilterValues,
		partsView,
		leadingConfigurationData,
		partsLoading,
		paginationData,
		printabilityData,
		partsToPrintLoading,
		partsToPrintSummary,
		partsToPrint,
		refetchParts
	} = useSelector((state: RootStateOrAny) => state.ProjectAnalysisReducer)

	const { roles, contactUsEmail } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const dispatch = useDispatch()

	const singlePartProjectViewIsOn = Feature.isFeatureOn(
		FeatureComponentId.SINGLE_PART_PROJECT_VIEW
	)

	const onExportPDFClick = useCallback(() => {
		setShowPDFModal(true)
	}, [])

	const closePDFModal = useCallback(() => setShowPDFModal(false), [])

	const renderParts = (partsView: ProjectAnalysisPartsView) => {
		if (allPartsInapplicable) {
			return (
				<ImageListItem cols={3}>
					<p className="all-inapplicable">
						{getString('ALL_PARTS_INAPPLICABLE')}
					</p>
				</ImageListItem>
			)
		}
		if (partsView === ProjectAnalysisPartsView.list) {
			return (
				<ImageListItem cols={3} style={styles.partsRows} key="listView">
					<PartsListView
						parts={parts}
						partsProperties={partsProperties}
						leadingConfigurationData={leadingConfigurationData}
						lockedParts={!!project?.lockedParts}
						amountOfLockedParts={amountOfLockedParts}
						roles={roles}
						contactUsEmail={contactUsEmail}
						isBundle={false}
					/>
				</ImageListItem>
			)
		}
		return (
			<ImageList
				style={{
					...styles.partsHeader,
					flexDirection: 'row',
					zIndex: 99
				}}
				cols={3}
			>
				{!!weightReducedParts.length && (
					<ImageListItem key="Subheader3" cols={3} className="parts-subheader">
						<WeightReducedPartsSubHeader
							weightReducedParts={weightReducedParts}
						/>
					</ImageListItem>
				)}
				{!!weightReducedParts.length &&
					weightReducedParts?.map((part: Part) => (
						<ImageListItem
							key={part.id}
							id="grid-list-tile"
							cols={1}
							style={styles.partTile}
							classes={{ item: 'grid-list--tile' }}
						>
							<ProjectPartTile
								key={part.id}
								part={part}
								partsProperties={partsProperties}
								isBundle={false}
							/>
						</ImageListItem>
					))}
				{!!parts.length && !!weightReducedParts.length && (
					<ImageListItem key="Subheader2" cols={3} className="parts-subheader">
						<PartsSubHeader
							parts={parts}
							weightReducedParts={weightReducedParts}
						/>
					</ImageListItem>
				)}
				{!!project?.lockedParts && (
					<ImageListItem
						classes={{ item: 'grid-list--tile' }}
						key={'904343'}
						style={styles.partTile}
						cols={1}
					>
						<LockPartsCars
							amountOfLockedParts={amountOfLockedParts}
							roles={roles}
							contactUsEmail={contactUsEmail}
						/>
					</ImageListItem>
				)}
				{parts?.map((part: Part) => (
					<ImageListItem
						key={part.id}
						style={styles.partTile}
						id="grid-list-tile"
						cols={1}
						classes={{ item: 'grid-list--tile' }}
					>
						<ProjectPartTile
							part={part}
							partsProperties={partsProperties}
							isBundle={false}
						/>
					</ImageListItem>
				))}
			</ImageList>
		)
	}

	if (isSinglePartProject && singlePartProjectViewIsOn) {
		return <></>
	}

	return (
		<>
			<Loader
				load={partsLoading}
				wrapperClassName="loading-wrapper multiple-parts-loading"
			/>
			<ImageList
				style={{
					...styles.partsGrid,
					flexDirection:
						partsView === ProjectAnalysisPartsView.list && allPartsInapplicable
							? 'column'
							: 'row'
				}}
				cols={3}
			>
				<ImageListItem
					id="grid-list"
					key="Subheader"
					cols={3}
					style={styles.partsHeader}
				>
					<SubheaderTSX component="div">
						<PartsHeader
							selectedFilterValues={selectedFilterValues}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={showExportButton}
							project={project}
							parts={parts}
							weightReducedParts={weightReducedParts}
							is2dProject={is2dProject}
							allPartsCount={totalPartsCount}
							allPartsInapplicable={allPartsInapplicable}
							searchPhrase={searchPhrase}
							projectId={projectId}
							selectedFilterPart={selectedFilterPart}
							partsView={partsView}
							totalPartsCount={paginationData.totalPartsCount}
							page={paginationData.page}
							limit={paginationData.limit}
							onExportPDFClick={onExportPDFClick}
							refetchParts={refetchParts}
						/>
					</SubheaderTSX>
				</ImageListItem>
				{renderParts(partsView)}
				<ImageListItem
					id="grid-list"
					key="Pagination"
					cols={3}
					style={styles.partsPagination}
				>
					<CastorPagination
						showPagination={paginationData.totalPagesCount > 1}
						pageNumber={paginationData.page}
						isLastPage={!paginationData.enableNext}
						total={paginationData.totalPartsCount}
						limit={paginationData.limit}
						className={{ pagination: 'parts-pagination' }}
						showingFrom={paginationData.showingFrom}
						showingTo={paginationData.showingTo}
						onNextClick={() =>
							dispatch(changePartsPage(paginationData.page + 1))
						}
						onPrevClick={() =>
							dispatch(changePartsPage(paginationData.page - 1))
						}
						onPageClick={(page: number) => dispatch(changePartsPage(page))}
					/>
				</ImageListItem>
			</ImageList>
			{showPDFModal && (
				<ProjectPdfModal
					showModal={showPDFModal}
					closeModal={closePDFModal}
					isBundle={false}
					projectId={projectId}
					selectedFilterValues={selectedFilterValues}
					searchPhrase={searchPhrase}
					projectName={project.name}
					printabilityData={printabilityData}
					partsToPrintLoading={partsToPrintLoading}
					totalPartsCount={totalPartsCount}
					partsToPrintSummary={partsToPrintSummary}
					partsToPrint={partsToPrint}
					partsProperties={partsProperties}
					leadingConfigurationData={leadingConfigurationData}
					shouldRequestAllParts={paginationData.totalPagesCount > 1}
				/>
			)}
		</>
	)
}

export default memo(ProjectMultipleParts)
