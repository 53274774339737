import { FC, memo, useCallback, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { ImageList, ImageListItem } from '@material-ui/core'
import Subheader from '@material-ui/core/ListSubheader'

import { changePartsPage } from '../ProjectAnalysis/ProjectAnalysisActions'
import { ProjectAnalysisPartsView } from '../ProjectAnalysis/ProjectAnalysisInterfaces'
import PartsListView from '../ProjectAnalysis/ProjectMultipleParts/PartsListView/PartsListView'
import ProjectPdfModal from '../ProjectAnalysis/ProjectMultipleParts/ProjectExportPDF/ProjectPdfModal'
import CastorPagination from 'Scenes/Components/CastorPagination/CastorPagination'
import LockPartsCars from 'Scenes/Home/ProjectAnalysis/ProjectMultipleParts/LockPartsCars'
import PartsHeader from 'Scenes/Home/ProjectAnalysis/ProjectMultipleParts/PartsHeader'
import ProjectPartTile from 'Scenes/Home/ProjectAnalysis/ProjectMultipleParts/ProjectPartTile'
import { styles } from 'Scenes/Home/ProjectAnalysis/ProjectMultipleParts/style'
import Loader from 'Scenes/Loader/Loader'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'
import { getString } from 'Services/Strings/StringService'

const SubheaderTSX: any = Subheader

const ProjectBundleMultipleParts: FC = () => {
	const [showPDFModal, setShowPDFModal] = useState(false)
	const { projectId } = useParams() as { projectId: string }
	const {
		weightReducedParts,
		projects,
		project,
		parts,
		totalPartsCount,
		standalonePartsCount,
		partsProperties,
		allPartsInapplicable,
		selectedFilterPart,
		searchPhrase,
		selectedFilterValues,
		showStandardCostFinishIcon,
		partsView,
		leadingConfigurationData,
		partsLoading,
		paginationData,
		printabilityData,
		partsToPrintLoading,
		partsToPrintSummary,
		partsToPrint,
		refetchParts
	} = useSelector((state: RootStateOrAny) => state.ProjectBundleReducer)

	const { roles, contactUsEmail } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	const dispatch = useDispatch()

	const assemblyProjects = projects?.filter(
		(project: Project) => project.isAssembly
	)

	const onExportPDFClick = useCallback(() => {
		setShowPDFModal(true)
	}, [])

	const closePDFModal = useCallback(() => setShowPDFModal(false), [])

	const renderParts = (partsView: ProjectAnalysisPartsView) => {
		if (allPartsInapplicable) {
			return (
				<ImageListItem cols={3}>
					<p className="all-inapplicable">
						{getString('ALL_PARTS_INAPPLICABLE')}
					</p>
				</ImageListItem>
			)
		}
		if (partsView === ProjectAnalysisPartsView.list) {
			return (
				<ImageListItem cols={3} style={styles.partsRows} key="listView">
					<PartsListView
						parts={parts}
						partsProperties={partsProperties}
						leadingConfigurationData={leadingConfigurationData}
						lockedParts={!!project?.lockedParts}
						amountOfLockedParts={project?.amountOfLockedParts}
						roles={roles}
						contactUsEmail={contactUsEmail}
						isBundle
					/>
				</ImageListItem>
			)
		}
		return (
			<ImageList
				style={{
					...styles.partsHeader,
					flexDirection: 'row',
					zIndex: 99
				}}
				cols={3}
			>
				{project?.lockedParts && (
					<ImageListItem
						classes={{ item: 'grid-list--tile' }}
						key={'904343'}
						style={styles.partTile}
						cols={1}
					>
						<LockPartsCars
							amountOfLockedParts={project?.amountOfLockedParts}
							roles={roles}
							contactUsEmail={contactUsEmail}
						/>
					</ImageListItem>
				)}
				{parts?.map((part: Part) => (
					<ImageListItem
						key={part.id}
						style={styles.partTile}
						id="grid-list-tile"
						cols={1}
						classes={{ item: 'grid-list--tile' }}
					>
						<ProjectPartTile
							part={part}
							partsProperties={partsProperties}
							isBundle
						/>
					</ImageListItem>
				))}
			</ImageList>
		)
	}

	return (
		<>
			<Loader
				load={partsLoading}
				wrapperClassName="loading-wrapper multiple-parts-loading"
			/>
			<ImageList
				style={{
					...styles.partsGrid,
					flexDirection:
						partsView === ProjectAnalysisPartsView.list ? 'column' : 'row'
				}}
				cols={3}
			>
				<ImageListItem
					id="grid-list"
					key="Subheader"
					cols={3}
					style={styles.partsHeader}
				>
					<SubheaderTSX component="div">
						<PartsHeader
							assemblyProjects={assemblyProjects}
							bundleId={projectId}
							selectedFilterValues={selectedFilterValues}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={true}
							project={project}
							parts={parts}
							weightReducedParts={weightReducedParts}
							is2dProject={false}
							allPartsCount={totalPartsCount}
							standalonePartsCount={standalonePartsCount}
							allPartsInapplicable={allPartsInapplicable}
							searchPhrase={searchPhrase}
							projectId={projectId}
							selectedFilterPart={selectedFilterPart}
							partsView={partsView}
							totalPartsCount={paginationData.totalPartsCount}
							page={paginationData.page}
							limit={paginationData.limit}
							onExportPDFClick={onExportPDFClick}
							refetchParts={refetchParts}
						/>
					</SubheaderTSX>
				</ImageListItem>
				{renderParts(partsView)}
				<ImageListItem
					id="grid-list"
					key="Pagination"
					cols={3}
					style={styles.partsPagination}
				>
					<CastorPagination
						showPagination={paginationData.totalPagesCount > 1}
						pageNumber={paginationData.page}
						isLastPage={!paginationData.enableNext}
						total={paginationData.totalPartsCount}
						limit={paginationData.limit}
						className={{ pagination: 'parts-pagination' }}
						showingFrom={paginationData.showingFrom}
						showingTo={paginationData.showingTo}
						onNextClick={() =>
							dispatch(changePartsPage(paginationData.page + 1))
						}
						onPrevClick={() =>
							dispatch(changePartsPage(paginationData.page - 1))
						}
						onPageClick={(page: number) => dispatch(changePartsPage(page))}
					/>
				</ImageListItem>
			</ImageList>
			{showPDFModal && (
				<ProjectPdfModal
					showModal={showPDFModal}
					closeModal={closePDFModal}
					projectId={projectId}
					selectedFilterValues={selectedFilterValues}
					searchPhrase={searchPhrase}
					projectName={project.name}
					printabilityData={printabilityData}
					partsToPrintLoading={partsToPrintLoading}
					totalPartsCount={totalPartsCount}
					partsToPrintSummary={partsToPrintSummary}
					partsToPrint={partsToPrint}
					partsProperties={partsProperties}
					leadingConfigurationData={leadingConfigurationData}
					shouldRequestAllParts={paginationData.totalPagesCount > 1}
					isBundle
				/>
			)}
		</>
	)
}

export default memo(ProjectBundleMultipleParts)
