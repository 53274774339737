import { isBoolean, omit } from 'lodash'

import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUserRole } from 'Services/models/IUserRole'


export const checkForUserInfoChanges = (
	userSelected: Record<string, unknown>,
	newData: Record<string, unknown>
) => {
	let fieldsForUpdate = {}
	Object.keys(newData).forEach(fieldName => {
		const initialValue = isBoolean(newData[fieldName])
			? !!userSelected[fieldName]
			: userSelected[fieldName]
		const fieldsValuesEqual = newData[fieldName] !== initialValue
		const idField = fieldName === 'id'
		if (fieldsValuesEqual || idField) {
			const newValue = newData[fieldName]
			fieldsForUpdate = {
				[fieldName]: newValue,
				...fieldsForUpdate
			}
		}
	})
	return fieldsForUpdate
}

export const findRoleName = (usersRoles: IUserRole[], roleNumber: number) => {
	const role = usersRoles.find(
		(usersRole: IUserRole) => usersRole.id === roleNumber
	)
	return role?.name
}

export const validations = {
	minSelectedValues: (value: number[]) => value && !value?.length
}

export const prepareInfoForRequest = (
	newUpdatedInfo: Record<string, unknown>
) => {
	const roleEditingOn = Feature.isFeatureOn(FeatureComponentId.ROLE_EDITING)
	let preparedInfoForRequest
	if (!roleEditingOn && newUpdatedInfo?.roles) {
		const { roles, ...info } = newUpdatedInfo
		preparedInfoForRequest = { info }
	} else if (!newUpdatedInfo?.roles) {
		preparedInfoForRequest = { info: newUpdatedInfo }
	} else {
		const roles: Array<number> | string = newUpdatedInfo.roles as
			| Array<number>
			| string
		const preparedRoles = typeof roles !== 'string' ? roles.toString() : roles

		newUpdatedInfo = omit(newUpdatedInfo, 'roles')
		preparedInfoForRequest = { info: newUpdatedInfo, roles: preparedRoles }
	}
	return preparedInfoForRequest
}

export const makeRolesAsArray = (
	roles: string | number | Array<number> = ''
) => {
	return typeof roles === 'number'
		? [roles]
		: typeof roles === 'string'
		? roles.split(',').map((role: string) => +role)
		: roles
}