import { cloneDeep, get, set } from 'lodash'

import { getString } from 'Services/Strings/StringService'


const emailFields = [
	{
		key: 'smtpSettings.host',
		label: getString('ADMIN_SMTP_SERVER_NAME'),
		value: '',
		error: false
	},
	{
		key: 'smtpSettings.auth.user',
		label: getString('ADMIN_SMTP_USER_NAME'),
		value: '',
		error: false
	},
	{
		key: 'smtpSettings.auth.pass',
		label: getString('ADMIN_SMTP_PASSWORD'),
		value: '',
		placeholder: getString('PASSWORD'),
		error: false,
		showEmpty: true
	},
	{
		key: 'from',
		label: getString('ADMIN_SMTP_FROM_EMAIL'),
		value: '',
		error: false
	},
	{
		key: 'smtpSettings.port',
		label: getString('ADMIN_SMTP_TCP_PORT'),
		value: '',
		error: false,
		isNumber: true,
		validationMessage: getString('CO2_PER_KG_DATA_VALIDATION')
	},
	{
		key: 'smtpSettings.secure',
		label: getString('ADMIN_SMTP_SSL_CONNECTION'),
		value: '',
		error: false,
		disabled: true
	}
]

export const prepareEmailData = (emailSettings: Record<string, string>) => {
	return emailFields.map(field => {
		field.value = field.showEmpty
			? ''
			: get(emailSettings, field.key.split('.'))
		return field
	})
}

export const prepareEmailDataToSend = (
	data: Array<Record<string, string>>,
	emailSettings: Record<string, string>
) => {
	const settings = cloneDeep(emailSettings)
	data.forEach(field => {
		const oldValue = get(emailSettings, field.key.split('.'))
		set(settings, field.key.split('.'), field.value || oldValue)
	})

	return settings
}