import {
	FunctionCategories,
	IFunctionVariable
} from './CustomizeCostingFunctionTypes'

export const PARAM_EXISTS_ERROR_CODE = 743388
export const INVALID_FUNCTION_ERROR_CODE = 743389
export const DOT = '.'
export const OPENING_BRACKET = '('
export const CLOSING_BRACKET = ')'
export const INVALID_FUNCTION = 'Invalid function'
export const DRAGGING_CLASS_NAME = 'dragging'
export const FUNCTION_OPERATORS = [
	{
		type: 'operator',
		name: '+',
		userReadableName: '+',
		value: '+'
	},
	{
		type: 'operator',
		name: '-',
		userReadableName: '-',
		value: '-'
	},
	{
		type: 'operator',
		name: '*',
		userReadableName: '*',
		value: '*'
	},
	{
		type: 'operator',
		name: '/',
		userReadableName: '/',
		value: '/'
	},
	{
		type: 'operator',
		name: '(',
		userReadableName: '(',
		value: '('
	},
	{
		type: 'operator',
		name: ')',
		userReadableName: ')',
		value: ')'
	},
	{
		type: 'operator',
		name: '.',
		userReadableName: '.',
		value: '.'
	}
] as IFunctionVariable[]

export const FUNCTION_NUMBERS = [
	{
		type: 'number',
		name: '1',
		userReadableName: '1',
		value: '1'
	},
	{
		type: 'number',
		name: '2',
		userReadableName: '2',
		value: '2'
	},
	{
		type: 'number',
		name: '3',
		userReadableName: '3',
		value: '3'
	},
	{
		type: 'number',
		name: '4',
		userReadableName: '4',
		value: '4'
	},
	{
		type: 'number',
		name: '5',
		userReadableName: '5',
		value: '5'
	},
	{
		type: 'number',
		name: '6',
		userReadableName: '6',
		value: '6'
	},
	{
		type: 'number',
		name: '7',
		userReadableName: '7',
		value: '7'
	},
	{
		type: 'number',
		name: '8',
		userReadableName: '8',
		value: '8'
	},
	{
		type: 'number',
		name: '9',
		userReadableName: '9',
		value: '9'
	},
	{
		type: 'number',
		name: '0',
		userReadableName: '0',
		value: '0'
	}
] as IFunctionVariable[]

export const categoriesWithTechnologies = [FunctionCategories.productionCost]
