import {
  USER_PROFILE_ADDRESS_SELECTED,
  LOCATION_ADDRESS_CHANGED,
  USER_PROFILE_UPDATED,
  SETUP_PROFILE_DETAILS,
  DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE,
  ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE,
  USER_PROFILE_GET_ALL_ADDRESSES,
  USER_PROFILE_GET_ALL_ADDRESSES_ERROR,
  USER_PROFILE_GET_ALL_ADDRESSES_LOADING
} from '../../../global actions/types'
import { SUCCESS, ERROR } from '../../../Services/Strings'

const INITIAL_STATE = {
  address: '',
  formatted_address: '',
  city: '',
  state: '',
  country: '',
  zip_code: '',
  long: null,
  lat: null,
  validAddress: '',
  autoCompleteDisable: false,
  allCountries: [],
  allStates: [],
  addressError: false,
  isLoadingAddress: false,
  countryCode: ''
}

export default (oldState = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_PROFILE_ADDRESS_SELECTED: {
      return {
        ...oldState,
        ...action.payload,
        validAddress: SUCCESS
      }
    }
    case USER_PROFILE_GET_ALL_ADDRESSES: {
      const { allCountries, allStates } = action.payload
      return {
        ...oldState,
        allCountries: allCountries,
        allStates: allStates,
        addressError: false,
      }
    }
    case USER_PROFILE_GET_ALL_ADDRESSES_ERROR: {
      return {
        ...oldState,
        addressError: true,
      }
    }
    case USER_PROFILE_GET_ALL_ADDRESSES_LOADING: {
      return {
        ...oldState,
        isLoadingAddress: action.payload.isLoadingAddress,
      }
    }
    case LOCATION_ADDRESS_CHANGED: {
      const { address, autoCompleteDisable } = action.payload
      let validAddress = ERROR
      if (!address) {
        validAddress = ''
      }
      if (autoCompleteDisable) {
        validAddress = SUCCESS
      }

      return {
        ...INITIAL_STATE,
        address,
        validAddress,
        autoCompleteDisable
      }
    }

    case USER_PROFILE_UPDATED: {
      const { formatted_address } = action.payload
      return {
        ...oldState,
        address: formatted_address
      }
    }
    case DISABLE_LOCATION_ADDRESS_AUTO_COMPLETE: {
      return {
        ...oldState,
        autoCompleteDisable: true
      }
    }
    case ENABLE_LOCATION_ADDRESS_AUTO_COMPLETE: {
      return {
        ...oldState,
        autoCompleteDisable: false
      }
    }

    case SETUP_PROFILE_DETAILS: {
      const {
        formatted_address,
        city,
        state,
        country,
        zip_code,
        long,
        lat,
        validAddress
      } = action.payload
      return {
        ...oldState,
        address: formatted_address,
        city: city || oldState.city,
        state: state || oldState.state,
        country: country || oldState.country,
        zip_code: zip_code || oldState.zip_code,
        long: long || oldState.long,
        lat: lat || oldState.lat,
        validAddress: validAddress || oldState.validAddress
      }
    }

    default:
      return oldState
  }
}
