import React, { FC, memo, useEffect, useState, Component } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import AdminDataTable from '../AdminDataTable/AdminDataTable'

import Loader from '../../../Loader/Loader'

import { AdminPrintingTechnologyInitialState } from './AdminPrintingTechnologyReducer'
import * as AdminPrintingTechnologyActions from './AdminPrintingTechnologyActions'

import { adminPrintersTechnologyEditFormRoute } from '../../../../Services/routeFuncs'
import { getString } from '../../../../Services/Strings/StringService'

interface IProps extends AdminPrintingTechnologyInitialState {
  setupAdminPrintingTechnologyPage: Function
}

interface IStoreProps {
  AdminPrintingTechnologyReducer: AdminPrintingTechnologyInitialState
}

const AdminPrintingTechnology: FC<IProps> = ({
  loading,
  printersTechParametersData,
  showNoParametersAlert,
  showPagination,
  isLastPage,
  showAdminPrintersAlert,
  setupAdminPrintingTechnologyPage
}) => {
  useEffect(() => {
    setupAdminPrintingTechnologyPage()
  }, [])
  if (loading) {
    return <Loader load={loading} message="" />
  }
  const renderPrintersTableData = (techParametersData: Array<any>) => {
    if (!techParametersData) {
      return []
    }
    return (
      techParametersData &&
      techParametersData.map((printerTechData: any) => {
        return [
          printerTechData.name,
          printerTechData.canStackModelsVertically == true ? 'true' : 'false',
          printerTechData.machineWarmupTime,
          printerTechData.machineCooldownTime,
          printerTechData.supportMaterialAsPercentOfPartMass,
          printerTechData.infrastructureCost,
          printerTechData.machineUpTime,
          printerTechData.additionalOperationCost,
          printerTechData.trayDensity,
          printerTechData.isUseSupport == true ? 'true' : 'false',
        ]
      })
    )
  }

  return (
    <AdminDataTable
      loading={false}
      formTitle={getString('ADMIN_PRINTER_TECHNOLOGY_PAGE_HEADER')}
      formSubTitle={getString('ADMIN_PRINTER_TECHNOLOGY_SUB_TITLE')}
      tableHeadParams={getString('ADMIN_PRINTER_TECHNOLOGY_TABLE_HEADERS')}
      tableData={renderPrintersTableData(printersTechParametersData)}
      showNoDataAlert={showNoParametersAlert}
      showPagination={showPagination}
      pageNumber={0}
      isLastPage={isLastPage}
      showAdminErrorAlert={showAdminPrintersAlert}
      linkToEdit={adminPrintersTechnologyEditFormRoute}
      removeSearchBar={true}
      removeNewItem={true}
      hideDeleteButton={true}
      editLinkCustomRowIndex={0}
    />
  )
}

function mapStateToProps({ AdminPrintingTechnologyReducer }: IStoreProps) {
  return {
    ...AdminPrintingTechnologyReducer
  }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...AdminPrintingTechnologyActions }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(memo(AdminPrintingTechnology))
