import { forEach, isString, isBoolean } from 'lodash'
import { standardPrinterMaterials, deviationPrinterMaterials, allowedCategories } from './constants'

/*
*  This helper was created to expand Standard deviation Object and pass it to one level up
*  standardDeviation: {accuracy: 1} => 'accuracyStd: 1'
*  before we push changes to backend we move 'accuracyStd: 1' back to standardDeviation
*  P.S it was needed because of reduxForm
*/

const std = 'Std'

// Create Deviation for necessary fields from array of names standardPrinterMaterials
export const addedDeviationExtToProperty = (data: any) => {
  let changedData = data
  let standardDeviation = data?.standardDeviation

  forEach(data, (elem,  key: string) => {
    forEach(standardPrinterMaterials, (printerMaterial) => {
      if (key === printerMaterial.name && printerMaterial.hasStandardDeviation) {
        const newKey = key + std
        const newVal = { [newKey]: standardDeviation && standardDeviation[printerMaterial.name] || '' }
        changedData = {
          ...changedData,
          ...newVal
        }
      }
    })
  })

  return changedData
}

// Create Add created deviation to existing object
export const prepareDeviationToProperty = (data: any) => {
  let changedData = data

  //Get value from nameStd and add to standardDeviation
  forEach(data, (elem,  key: string) => {
    forEach(standardPrinterMaterials, (printerMaterial) => {
      const oldKey = printerMaterial.name + std
      if (key === oldKey && printerMaterial.hasStandardDeviation) {
        const newVal = {[printerMaterial.name]: elem || ''}
        changedData['standardDeviation'] = {
          ...changedData.standardDeviation,
          ...newVal
        }
      }
    })

    // make sure that these values are Numbers, not a string
    forEach(deviationPrinterMaterials, (printerMaterial) => {
      if (key === printerMaterial.name) {
        changedData[key] = forEach(changedData[key], (data: any, keyDev: any) => {
          if (data?.stdev && data?.val) {
            changedData[key][keyDev] = {
              stdev: isString(data?.stdev) ? +data.stdev.replace(/,/g, '.') : data?.stdev,
              val: isString(data?.val) ? +data.val.replace(/,/g, '.') : data?.val,
            }
          }
        })
      }
    })
  })
  return changedData
}


interface IPriner {
  company: string
  createdAt: null | string
  id: number
  inPrinterNamesList: boolean
  name: string | null
  updatedAt: null | string
}

export const printerToCompany = (allPrinters: any) => {
  return allPrinters.reduce((r: any, a: IPriner) => {
    r[a.company] = [...r[a.company] || [], a];
    return r;
  }, {});
}

// it's needed because of redux form, initial state doesn't load properly we have 3 state for machining
// none/false/true
export const checkMachining = (data: any) => {
  let changedData = data
  const selectedCategory = data?.category
  const existingMachining = isBoolean(data?.machining)
  const showMachining = allowedCategories.includes(selectedCategory)

  if (showMachining) {
    if(!existingMachining) {
      changedData['machining'] = true
    }
  }

  return changedData
}


export const getPrinterCompanyName = (allPrintersCompanies: any, printerName: string) => {
  let selectedPrinterName: string = ''

  Object.keys(allPrintersCompanies).forEach(key =>
    forEach(allPrintersCompanies[key], printer => {
      if (printer.name === printerName) {
        selectedPrinterName = key
      }
    })
  )

  return selectedPrinterName
}
