import parseHTML from 'html-react-parser'
import React, { memo, PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import { Button } from '../../Components/thirdParty/CreativeTim/components'
import { PREV_PATH } from 'Scenes/Home/NewUploadProject/constants'
import {
	getStringItemFromLocalStorage,
	removeItemFromLocalStorage
} from 'Services/LocalStorageService'
import { getTheme } from 'themes/getTheme'

import logo from '../../../assets/img/castorLogo.png'
import agreementText from '../../../assets/json/AgreementText.json'

import './UsageAgreement.scss'

const { agreementLogo, showBackButton = true } = getTheme()

interface IProps {
	history: any
}

class UsageAgreement extends PureComponent<IProps> {
	constructor(props: IProps) {
		super(props)
	}

	renderAgreementText = () => {
		return (
			agreementText &&
			agreementText?.text?.map((text: any) => {
				return (
					<>
						<p className="text"> {parseHTML(text)}</p>
						<p>&nbsp;</p>
					</>
				)
			})
		)
	}

	render() {
		const onBackClick = () => {
			const prevPath = getStringItemFromLocalStorage(PREV_PATH)
			if (prevPath) {
				removeItemFromLocalStorage(PREV_PATH)
				window.open(prevPath, '_self')
			} else {
				this.props.history.goBack()
			}
		}

		const renderGoBackButton = () => {
			if (!showBackButton) return
			return <Button onClick={onBackClick}>{agreementText?.backButton}</Button>
		}

		return (
			<div className="agreement-wrapper">
				<img className="logo" src={agreementLogo || logo} alt="logo" />
				{renderGoBackButton()}
				<div className="text-wrapper">
					<p className="header">{parseHTML(agreementText?.header)}</p>
					<p>&nbsp;</p>
					{this.renderAgreementText()}
				</div>
				{renderGoBackButton()}
			</div>
		)
	}
}

export default withRouter(memo(UsageAgreement))
