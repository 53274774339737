import React, { FC, memo, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { scroller } from 'react-scroll'

import cx from 'classnames'
import { Launch } from '@material-ui/icons'

import {
	solutionMapConstant,
	xAxisDropDownValues
} from '../..//PartAnalysisConstants'
import { calculateAlternativeSolutions } from '../../PartAnalysisActions'
import { usePartReducer, useUserReducer } from '../../PartAnalysisSelector'
import {
	calculateChartData,
	getScoreText,
	setNewTooltip
} from '../../PartAnalysisService'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as Winner } from 'assets/img/svg/icons/winner.svg'

const WinnerIcon: any = Winner
const LaunchIcon: any = Launch

interface ConfigurationSolutionTableProps {
	activeTabChange: Function
	selectedXAxisName: string
	selectedYAxisName: string
	configuration: any
	alternativeSolutions: any
}

const scrollToElement = (element: string) => {
	scroller.scrollTo(element, {
		duration: 300,
		delay: 100,
		smooth: true,
		containerId: `solution-map-table-body`
	})
}
const ConfigurationSolutionTable: FC<ConfigurationSolutionTableProps> = ({
	activeTabChange,
	selectedXAxisName,
	selectedYAxisName,
	configuration
}) => {
	const {
		alternativeSolutions,
		alternativeSolutionHover: hoverElement,
		...part
	} = usePartReducer()
	const { printingTechnologies, roles } = useUserReducer()
	const { XDifference, YDifference } = calculateChartData(
		alternativeSolutions,
		selectedXAxisName,
		selectedYAxisName
	)
	const getHoveredSolution =
		alternativeSolutions.find(
			(solution: any) => solution.position === hoverElement
		) || {}

	useEffect(() => {
		scrollToElement(`data-${hoverElement}`)
	}, [hoverElement])

	const dispatch = useDispatch()
	const showActualScore = Feature.isFeatureOn(
		FeatureComponentId.SHOW_ACTUAL_SCORE
	)

	const handleButtonClick = (solution: any) => {
		if (solution?.url === 0 || solution?.url > -1) {
			activeTabChange(null, solution.url)
		} else {
			dispatch(calculateAlternativeSolutions(solution, part, configuration))
		}
	}

	const solutionTabRow = (solution: any) => {
		const isEqualData =
			getHoveredSolution[selectedXAxisName] === solution[selectedXAxisName] &&
			getHoveredSolution[selectedYAxisName] === solution[selectedYAxisName]
		const closeDataByX =
			Math.abs(
				getHoveredSolution[selectedXAxisName] - solution[selectedXAxisName]
			) < XDifference
		const closeDataByY =
			Math.abs(
				getHoveredSolution[selectedYAxisName] - solution[selectedYAxisName]
			) < YDifference

		const isHovered =
			hoverElement === solution.position ||
			isEqualData ||
			(closeDataByX &&
				getHoveredSolution[selectedYAxisName] ===
					solution[selectedYAxisName]) ||
			(closeDataByY &&
				getHoveredSolution[selectedXAxisName] === solution[selectedXAxisName])

		const isBest = solution.position === 1
		const isPartOfGraphExponentialLine =
			solution.isPartOfGraphExponentialLine && solution.position !== 1

		const printerName = (
			<div className="position-block">
				<div
					className={cx('position', {
						'best-position': isBest
					})}
				>
					{solution.position}
				</div>
				<div className="name">{solution.printerName}</div>
			</div>
		)

		const actionButton = (
			<div
				className={cx('button-block', {
					'best-button': isBest
				})}
			>
				<ButtonWithLoader
					color={'transparent'}
					size={20}
					top={20}
					loading={solution.isLoading}
					disabled={solution.disabled}
					onClick={() => handleButtonClick(solution)}
					className="add-button"
				>
					{solution.name}
					{!isBest && solution.url > -1 && <LaunchIcon className="launch" />}
				</ButtonWithLoader>
				{(isBest && <WinnerIcon />) || isPartOfGraphExponentialLine}
			</div>
		)

		return (
			<div
				id={`data-${solution.position}`}
				key={solution.position}
				onMouseEnter={() =>
					setNewTooltip(
						solution,
						true,
						printingTechnologies,
						selectedXAxisName,
						roles
					)
				}
				onMouseLeave={() =>
					setNewTooltip(
						solution,
						false,
						printingTechnologies,
						selectedXAxisName,
						roles
					)
				}
				className={cx('row', {
					'best-position': isBest || isPartOfGraphExponentialLine,
					'is-hover': isHovered
				})}
			>
				<div>{printerName}</div>
				<div>{solution.materialName}</div>
				<div>
					{selectedXAxisName === solutionMapConstant.score
						? getScoreText(solution.score, !!showActualScore)
						: solution[selectedXAxisName]}
				</div>
				<div>{actionButton}</div>
			</div>
		)
	}

	const renderRowsData = () => {
		return alternativeSolutions.map((solution: any) => solutionTabRow(solution))
	}

	const selectedTitle = useMemo(
		() => xAxisDropDownValues.find(el => el.value === selectedXAxisName),
		[selectedXAxisName]
	)

	return (
		<div className={'solution-map-table'}>
			<div className="header">
				<div>{getString('RECOMMENDED_PRINTER_LABEL')}</div>
				<div>{getString('RECOMMENDED_MATERIAL_LABEL')}</div>
				<div>
					{selectedTitle?.name}
					<span>{selectedTitle?.units}</span>
				</div>
				<div></div>
			</div>
			<div id="solution-map-table-body" className="body">
				{renderRowsData()}
			</div>
		</div>
	)
}

export default memo(ConfigurationSolutionTable)
