import { useEffect, useState } from 'react'

import Numeral from 'numeral'

import { makeTotalCostForStandard } from '../../../../../SolutionAnalysisHeader/SolutionExportPdf/SolutionPdfService'
import { CostAnalysisEnum } from '../CostParametersEnum'
import { DataTableService } from 'Scenes/Components/DataTable/DataTableService'
import { DataTableFieldType } from 'Scenes/Components/DataTable/IDataTableField'
import { generateProductionParameters } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysisContent/SolutionAnalysisTabs/Tabs/CostComparisonTab/CostAnalysisTable/CostAnalysisTableHelper'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import {
	calculate2DRange,
	displayInRange
} from 'Services/global/calculateRange'
import { Part } from 'Services/models/IPart'
import { getString } from 'Services/Strings/StringService'

const dataTableService = new DataTableService()

const checkValueForStandardAndCustom = (
	value: any,
	isStandardCost?: boolean,
	isCostCustomized?: boolean
) => {
	if ((isStandardCost && +value === 0) || isCostCustomized) return null

	return value
}

const checkValueForCustom = (
	value: string | number,
	isCostCustomized?: boolean
) => {
	return isCostCustomized ? null : value
}

const getCostAnalysisTableHeaders = (
	traditionalMethod: string,
	printer: string
) => [
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.Header,
		text: getString('MATERIAL_COMPARISON_HEADER_COMPARISON')
	}),
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.Header,
		text: printer
	}),
	dataTableService.RenderFieldObject({
		type: DataTableFieldType.Header,
		text: traditionalMethod
	})
]

export function useCostAnalysisTableHeaders(
	traditionalMethod: string,
	printer: string
) {
	const [costAnalysisTableHeaders, setCostAnalysisTableHeaders] = useState(
		getCostAnalysisTableHeaders(traditionalMethod, printer)
	)

	useEffect(() => {
		setCostAnalysisTableHeaders(
			getCostAnalysisTableHeaders(traditionalMethod, printer)
		)
	}, [traditionalMethod])

	return costAnalysisTableHeaders
}

const singleRowElement = (
	text: string,
	iconName: string,
	cost: any,
	traditionCost: any,
	showEffectiveIcon: boolean,
	isEffective: boolean,
	isInside?: boolean,
	description?: string,
	extraClassName?: string
) => {
	return [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithIconButton,
			text: text,
			iconName: iconName,
			className: `cost-summary--table--text--full-detail data-table--first-column ${
				isInside ? 'data-table--first-column--inside' : ''
			}  ${extraClassName || ''}`,
			iconClassName: 'icon-part',
			hoverText: getString('TOTAL_PART_COST_DETAILS'),
			isHover: true,
			iconOnTheRight: true,
			description: description
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.TextWithIcon,
			text: cost,
			iconName: showEffectiveIcon && isEffective ? 'costSavingSmall' : '',
			className: `cost-comparison-tab--table--text-with-icon cost-summary--table--text--full-detail`,
			iconClassName: 'cost-comparison-tab--table--text-with-icon--icon',
			iconOnTheRight: true,
			extraData: { dataQaAttribute: 'data-qa-AM-total-part-cost' }
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: traditionCost,
			className: `cost-summary--table--text--full-detail`
		})
	]
}

const materialRowElement = (
	materialCost: number | string,
	totalPartMaterial: number | string,
	totalSupportMaterial: number | string,
	unusedMaterialWaste: number | string,
	materialCostIs: number | string,
	partWeightIs: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.totalPartMaterial] &&
		disabledParameters?.[CostAnalysisEnum.totalSupportMaterial] &&
		disabledParameters?.[CostAnalysisEnum.unusedMaterialWaste]
	)
		return []

	return [
		singleRowElement(
			`${getString('MATERIAL')} [$]`,
			'info',
			materialCost,
			'',
			false,
			false,
			false,
			getString('COST_ANALYSIS_MATERIAL').format(materialCostIs, partWeightIs)
		),
		disabledParameters?.[CostAnalysisEnum.totalPartMaterial]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_PART_MATERIAL')} [$]`,
					'info',
					totalPartMaterial,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.totalSupportMaterial]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_SUPPORT_AND_OTHERS')} [$]`,
					'info',
					totalSupportMaterial,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.unusedMaterialWaste]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_MATERIAL_WASTE')} [$]`,
					'info',
					unusedMaterialWaste,
					'',
					false,
					false,
					true
			  )
	]
}

const machineRowElement = (
	machineCost: number | string,
	totalSharedMachineTime: number | string,
	printTime: number | string,
	machineCostPerHour: number | string,
	partPrintTime: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.printTime] &&
		disabledParameters?.[CostAnalysisEnum.totalSharedMachineTime]
	)
		return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_MACHINE_ROW_TEXT')} [$]`,
			'info',
			machineCost,
			'',
			false,
			false,
			false,
			getString('COST_ANALYSIS_MACHINE').format(
				machineCostPerHour,
				partPrintTime
			)
		),
		disabledParameters?.[CostAnalysisEnum.printTime]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_PRINT_TIME')} [$]`,
					'info',
					printTime,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.totalSharedMachineTime]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_TOTAL_SHARED_MACHINE_TIME')} [$]`,
					'info',
					totalSharedMachineTime,
					'',
					false,
					false,
					true
			  ) || []
	]
}

const consumablesRowElement = (
	consumablesCost: number | string,
	operatingCost: number | string,
	perBuildConsumables: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	if (
		disabledParameters?.[CostAnalysisEnum.operatingCost] &&
		disabledParameters?.[CostAnalysisEnum.perBuildConsumables]
	)
		return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_CONSUMABLES_ROW_TEXT')} [$]`,
			'info',
			consumablesCost,
			'',
			false,
			false,
			false,
			getString('COST_ANALYSIS_CONSUMABLES')
		),
		disabledParameters?.[CostAnalysisEnum.perBuildConsumables]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_BUILD_CONSUMABLES')} [$]`,
					'info',
					perBuildConsumables,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.operatingCost]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_OPERATING_COST')} [$]`,
					'info',
					operatingCost,
					'',
					false,
					false,
					true
			  )
	]
}

const laborRowElement = (
	labor: number | string,
	laborHoursBuildExchange: number | string,
	laborHoursDuringPureBuild: number | string,
	laborTechnicianFTE: number | string,
	mandatoryPostProcessCost: number | string,
	optionalPostProcessCost: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	const disableLabor =
		disabledParameters?.[CostAnalysisEnum.laborHoursBuild] &&
		disabledParameters?.[CostAnalysisEnum.laborHoursDuringBuild] &&
		disabledParameters?.[CostAnalysisEnum.postProcessCost] &&
		disabledParameters?.[CostAnalysisEnum.optionalPostProcess]

	if (disableLabor) return []

	return [
		singleRowElement(
			`${getString('COST_ANALYSIS_COST_TABLE_PROD_LABOR_ROW_TEXT')} [$]`,
			'info',
			labor,
			'',
			false,
			false,
			false,
			getString('COST_ANALYSIS_LABOR').format(laborTechnicianFTE)
		),
		disabledParameters?.[CostAnalysisEnum.laborHoursBuild]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_BUILD_EXCHANGE')} [$]`,
					'info',
					laborHoursBuildExchange,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.laborHoursDuringBuild]
			? []
			: singleRowElement(
					`${getString('COST_TABLE_LABOR_DURING_BUILD')} [$]`,
					'info',
					laborHoursDuringPureBuild,
					'',
					false,
					false,
					true
			  ),
		disabledParameters?.[CostAnalysisEnum.postProcessCost]
			? []
			: singleRowElement(
					`${getString(
						'COST_ANALYSIS_COST_TABLE_PROD_MANDATORY_PP_ROW_TEXT'
					)} [$]`,
					'info',
					mandatoryPostProcessCost,
					'',
					false,
					false,
					true,
					''
			  ),
		disabledParameters?.[CostAnalysisEnum.optionalPostProcess]
			? []
			: !!+optionalPostProcessCost
			? singleRowElement(
					`${getString(
						'COST_ANALYSIS_COST_TABLE_PROD_OPTIONAL_PP_ROW_TEXT'
					)} [$]`,
					'info',
					optionalPostProcessCost,
					'',
					false,
					false,
					true,
					getString('COST_ANALYSIS_POST_PROCESS_OPTIONAL')
			  )
			: []
	]
}

const costOrderFeesElement = (
	totalOrderFeesCost: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	if (disabledParameters?.[CostAnalysisEnum.totalOrderFees]) return []

	return [
		singleRowElement(
			`${getString('COST_TABLE_ORDER_MANAGEMENT_SERVICES')} [$]`,
			'info',
			totalOrderFeesCost,
			'',
			false,
			false,
			true,
			'',
			'extra-space'
		)
	]
}

const buildPrepRowElement = (
	buildPrep: number | string,
	disabledParameters: Record<CostAnalysisEnum, boolean> | null
) => {
	if (disabledParameters?.[CostAnalysisEnum.buildPrep]) return []

	return [
		singleRowElement(
			`${getString('COST_TABLE_BUILD_PREP_COST')} [$]`,
			'info',
			buildPrep,
			'',
			false,
			false,
			true,
			'',
			'extra-space'
		)
	]
}

export const generateMaterialRow = (solution: any, configuration: any) => {
	return [
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: getString('MATERIAL'),
			className:
				'cost-summary--table--text--full-detail data-table--first-column'
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: solution.printerMaterial.name,
			className: `cost-summary--table--text--full-detail`
		}),
		dataTableService.RenderFieldObject({
			type: DataTableFieldType.Text,
			text: configuration.material.name,
			className: `cost-summary--table--text--full-detail`
		})
	]
}

export const generateTotalCostRow = (
	showEffectiveIcon: any,
	totalPartEffective: any,
	solution: any,
	configuration: any,
	supplyChainOn: boolean,
	userCustomizationSettings: any,
	part: Part,
	chainBenefits: any,
	isStandardCost?: boolean,
	isShowValuesInRanges?: boolean,
	drawingCostPercentage?: number
) => {
	const isMetadata = part?.formatType === METADATA
	if (!supplyChainOn) {
		return []
	}

	let configurationTotalCost: string | number = Numeral(
		configuration.costResults.actualResult
	).format('0,0[.]00')

	const { maxCostDetails, minCostDetails, totalCost } = solution?.costDetails

	let solutionTotalCost = Numeral(totalCost).format('0,0[.]00')

	// for Standard cost
	if (isStandardCost) {
		configurationTotalCost = makeTotalCostForStandard(
			part,
			configuration,
			chainBenefits
		)
	}

	if (isMetadata) {
		configurationTotalCost = calculate2DRange(
			configuration.costResults.actualResult,
			isMetadata ? drawingCostPercentage : undefined
		)
	}

	if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
		solutionTotalCost = displayInRange(
			minCostDetails?.totalCost,
			maxCostDetails?.totalCost,
			solutionTotalCost,
			'0,0[.]00',
			part?.isDrawing || isMetadata ? drawingCostPercentage : undefined
		)
	}

	return singleRowElement(
		getString('TOTAL_PART_COST').format('$'),
		'info',
		solutionTotalCost,
		configurationTotalCost,
		showEffectiveIcon,
		totalPartEffective,
		undefined,
		getString('TOTAL_PART_COST_DETAILS_CHECK_BOX_ON')
	)
}

export const generateUpfrontRows = (
	supplyChainOn: boolean,
	perPartCosts: any,
	showEffectiveIcon: any,
	upfrontEffective: any,
	perPartCostsTraditional: any,
	costsBreakDown: any,
	toolingEffective: any,
	supplyChainTraditionalDetails: any,
	designEffective: any,
	verificationEffective: any,
	isStandardCost?: boolean,
	isShowValuesInRanges?: boolean,
	minPartCosts?: any,
	maxPartCosts?: any,
	minCostsBreakDown?: any,
	maxCostsBreakDown?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean
) => {
	if (!supplyChainOn) {
		return []
	}

	const { costsBreakDown: supplyCostsBreakDown = {} } =
		supplyChainTraditionalDetails
	const { upfrontBreakDown = {} } = supplyCostsBreakDown
	const shouldShowEffectiveIcon = productionCostCalculatedWithCustomFunction
		? false
		: showEffectiveIcon
	let totalUpfrontCostsPerUnit
	let tooling
	let design
	let verification

	if (!productionCostCalculatedWithCustomFunction) {
		totalUpfrontCostsPerUnit = Numeral(
			perPartCosts.totalUpfrontCostsPerUnit
		).format('0,0[.]00')
		tooling = Numeral(costsBreakDown.upfrontBreakDown.tooling).format(
			'0,0[.]00'
		)
		design = Numeral(costsBreakDown.upfrontBreakDown.design).format('0,0[.]00')
		verification = Numeral(costsBreakDown.upfrontBreakDown.verification).format(
			'0,0[.]00'
		)

		if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
			totalUpfrontCostsPerUnit = displayInRange(
				minPartCosts?.totalUpfrontCostsPerUnit,
				maxPartCosts?.totalUpfrontCostsPerUnit,
				totalUpfrontCostsPerUnit,
				'0,0[.]00'
			)

			tooling = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.tooling,
				maxCostsBreakDown?.upfrontBreakDown?.tooling,
				tooling,
				'0,0[.]00'
			)

			design = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.design,
				maxCostsBreakDown?.upfrontBreakDown?.design,
				design,
				'0,0[.]00'
			)

			verification = displayInRange(
				minCostsBreakDown?.upfrontBreakDown?.verification,
				maxCostsBreakDown?.upfrontBreakDown?.verification,
				verification,
				'0,0[.]00'
			)
		}
	}

	return [
		singleRowElement(
			`${getString('UPFRONT')} [$]`,
			'info',
			totalUpfrontCostsPerUnit,
			checkValueForStandardAndCustom(
				Numeral(perPartCostsTraditional.totalUpfrontCostsPerUnit).format(
					'0,0[.]00'
				),
				isStandardCost,
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			upfrontEffective,
			undefined,
			getString('COST_ANALYSIS_UPFRONT')
		),
		singleRowElement(
			`${getString('TOOLING')} [$]`,
			'info',
			tooling,
			checkValueForStandardAndCustom(
				Numeral(upfrontBreakDown.tooling).format('0,0[.]00') || 0,
				isStandardCost,
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			toolingEffective,
			true,
			getString('COST_ANALYSIS_TOOLING')
		),
		singleRowElement(
			`${getString('DESIGN')} [$]`,
			'info',
			design,
			checkValueForStandardAndCustom(
				Numeral(upfrontBreakDown.design).format('0,0[.]00'),
				isStandardCost,
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			designEffective,
			true,
			getString('COST_ANALYSIS_DESIGN')
		),
		singleRowElement(
			`${getString('VERIFICATION')} [$]`,
			'info',
			verification,
			checkValueForStandardAndCustom(
				Numeral(upfrontBreakDown.verification).format('0,0[.]00'),
				isStandardCost,
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			verificationEffective,
			true,
			getString('COST_ANALYSIS_VERIFICATION')
		)
	]
}

export const generateInventoryRows = (
	supplyChainOn: boolean,
	perPartCosts: any,
	showEffectiveIcon: any,
	inventoryEffective: any,
	perPartCostsTraditional: any,
	costsBreakDown: any,
	holdingEffective: any,
	supplyChainTraditionalDetails: any,
	orderingEffective: any,
	obsolescenceEffective: any,
	annualEffective: any,
	otherEffective: any,
	isShowValuesInRanges?: boolean,
	minPartCosts?: any,
	maxPartCosts?: any,
	minCostsBreakDown?: any,
	maxCostsBreakDown?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean
) => {
	if (!supplyChainOn) {
		return []
	}
	const { costsBreakDown: supplyCostsBreakDown = {} } =
		supplyChainTraditionalDetails
	const { inventoryBreakDown = {} } = supplyCostsBreakDown
	const shouldShowEffectiveIcon = productionCostCalculatedWithCustomFunction
		? false
		: showEffectiveIcon

	let totalInventoryCostsPerUnit
	let holding
	let ordering
	let obsolescence
	let annualMaintenance
	let other

	if (!productionCostCalculatedWithCustomFunction) {
		totalInventoryCostsPerUnit = Numeral(
			perPartCosts?.totalInventoryCostsPerUnit
		).format('0,0[.]00')

		holding = Numeral(costsBreakDown.inventoryBreakDown.holding).format(
			'0,0[.]00'
		)
		ordering = Numeral(costsBreakDown.inventoryBreakDown.ordering).format(
			'0,0[.]00'
		)
		obsolescence = Numeral(
			costsBreakDown.inventoryBreakDown.obsolescence
		).format('0,0[.]00')
		annualMaintenance = Numeral(
			costsBreakDown.inventoryBreakDown.annualMaintenance
		).format('0,0[.]00')
		other = Numeral(costsBreakDown.inventoryBreakDown.other).format('0,0[.]00')

		if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
			totalInventoryCostsPerUnit = displayInRange(
				minPartCosts?.totalInventoryCostsPerUnit,
				maxPartCosts?.totalInventoryCostsPerUnit,
				totalInventoryCostsPerUnit,
				'0,0[.]00'
			)
			holding = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.holding,
				maxCostsBreakDown?.inventoryBreakDown?.holding,
				holding,
				'0,0[.]00'
			)
			ordering = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.ordering,
				maxCostsBreakDown?.inventoryBreakDown?.ordering,
				ordering,
				'0,0[.]00'
			)
			obsolescence = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.obsolescence,
				maxCostsBreakDown?.inventoryBreakDown?.obsolescence,
				obsolescence,
				'0,0[.]00'
			)
			annualMaintenance = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.annualMaintenance,
				maxCostsBreakDown?.inventoryBreakDown?.annualMaintenance,
				annualMaintenance,
				'0,0[.]00'
			)

			other = displayInRange(
				minCostsBreakDown?.inventoryBreakDown?.other,
				maxCostsBreakDown?.inventoryBreakDown?.other,
				other,
				'0,0[.]00'
			)
		}
	}

	return [
		singleRowElement(
			`${getString('INVENTORY')} [$]`,
			'info',
			totalInventoryCostsPerUnit,
			checkValueForCustom(
				Numeral(perPartCostsTraditional?.totalInventoryCostsPerUnit).format(
					'0,0[.]00'
				),
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			inventoryEffective,
			undefined,
			getString('COST_ANALYSIS_INVENTORY')
		),
		singleRowElement(
			`${getString('HOLDING')} [$]`,
			'info',
			holding,
			checkValueForCustom(
				Numeral(inventoryBreakDown.holding).format('0,0[.]00') || 0,
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			holdingEffective,
			true,
			getString('COST_ANALYSIS_HOLDING')
		),
		singleRowElement(
			`${getString('ORDERING')} [$]`,
			'info',
			ordering,
			checkValueForCustom(
				Numeral(inventoryBreakDown.ordering).format('0,0[.]00'),
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			orderingEffective,
			true,
			getString('COST_ANALYSIS_ORDERING')
		),
		singleRowElement(
			`${getString('OBSOLESCENCE')} [$]`,
			'info',
			obsolescence,
			checkValueForCustom(
				Numeral(inventoryBreakDown.obsolescence).format('0,0[.]00'),
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			obsolescenceEffective,
			true,
			getString('COST_ANALYSIS_OBSOLESCE')
		),
		singleRowElement(
			`${getString('ANNUAL_MAINTENANCE')} [$]`,
			'info',
			annualMaintenance,
			checkValueForCustom(
				Numeral(inventoryBreakDown.annualMaintenance).format('0,0[.]00'),
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			annualEffective,
			true,
			getString('COST_ANALYSIS_ANNUAL_MAINTENANCE')
		),
		singleRowElement(
			`${getString('OTHER')} [$]`,
			'',
			other,
			checkValueForCustom(
				Numeral(inventoryBreakDown.other).format('0,0[.]00'),
				traditionalCostCalculatedCustom
			),
			shouldShowEffectiveIcon,
			otherEffective,
			true
		)
	]
}

export const getProductionRows = (
	perPartCosts: any,
	showEffectiveIcon: any,
	productionEffective: any,
	perPartCostsTraditional: any,
	solution: any,
	configuration: any,
	isShowValuesInRanges: boolean = false,
	minPartCosts?: any,
	maxPartCosts?: any,
	productionCostCalculatedWithCustomFunction?: boolean,
	traditionalCostCalculatedCustom?: boolean,
	fullTrayAssumption?: boolean
) => {
	const disabledParameters =
		solution?.costCalculatedWithOverrideFunction || null

	const {
		materialCost,
		machineCost,
		consumablesCost,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		labor,
		totalPartMaterial,
		totalSupportMaterial,
		unusedMaterialWaste,
		printTime,
		operatingCost,
		perBuildConsumables,
		totalOrderFeesCost,
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		totalProductionCostsPerUnit,
		materialCostIs,
		partWeightIs,
		machineCostPerHour,
		partPrintTime,
		laborTechnicianFTE,
		totalSharedMachineTime,
		buildPrep
	} = generateProductionParameters(
		solution,
		configuration,
		isShowValuesInRanges,
		perPartCosts,
		minPartCosts,
		maxPartCosts,
		fullTrayAssumption
	)

	if (
		productionCostCalculatedWithCustomFunction ||
		traditionalCostCalculatedCustom
	) {
		return singleRowElement(
			`${getString('PRODUCTION')} [$]`,
			'info',
			productionCostCalculatedWithCustomFunction
				? ''
				: totalProductionCostsPerUnit,
			traditionalCostCalculatedCustom
				? ''
				: Numeral(perPartCostsTraditional.totalProductionCostsPerUnit).format(
						'0,0[.]00'
				  ),
			false,
			false,
			true,
			getString('COST_ANALYSIS_PRODUCTION')
		)
	}

	return [
		singleRowElement(
			`${getString('PRODUCTION')} [$]`,
			'info',
			totalProductionCostsPerUnit,
			Numeral(perPartCostsTraditional.totalProductionCostsPerUnit).format(
				'0,0[.]00'
			),
			showEffectiveIcon,
			productionEffective,
			false,
			getString('COST_ANALYSIS_PRODUCTION')
		),
		materialRowElement(
			materialCost,
			totalPartMaterial,
			totalSupportMaterial,
			unusedMaterialWaste,
			materialCostIs,
			partWeightIs,
			disabledParameters
		),
		...buildPrepRowElement(buildPrep, disabledParameters),
		machineRowElement(
			machineCost,
			totalSharedMachineTime,
			printTime,
			machineCostPerHour,
			partPrintTime,
			disabledParameters
		),
		consumablesRowElement(
			consumablesCost,
			operatingCost,
			perBuildConsumables,
			disabledParameters
		),
		laborRowElement(
			labor,
			laborHoursBuildExchange,
			laborHoursDuringPureBuild,
			laborTechnicianFTE,
			mandatoryPostProcessCost,
			optionalPostProcessCost,
			disabledParameters
		),
		...costOrderFeesElement(totalOrderFeesCost, disabledParameters)
	]
}
