import { FC, memo } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'
import TextField from '@material-ui/core/TextField'
import Flexbox from 'Scenes/Components/FlexBox'
import { forEach } from "lodash";

import { formFields, checkErrorValues } from './constants'
import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import TransparentButton from '../../../Components/TransparentButton'
import { REQUIRED } from '../../../../Services/Strings'
import CastorSwitch from '../../../Components/CastorSwitch'
import { getString } from '../../../../Services/Strings/StringService'

import '../adminHome.scss'

const ReduxFormField: any = Field
const TextFieldTSX: any = TextField

interface IProps {
  onSubmitClick: any
}
interface IFormData {}

const validate = (values: any) => {
  const errors: any = {}
  forEach(checkErrorValues, (item) => {
    const selectedValue = values[item.name]

    if (item?.checkIfNull && values[item.name] == null) {
      errors[item.name] = REQUIRED
    }
      if(!item?.checkIfNull && !selectedValue){
      values[item.name] = 0
    }
    
    if (selectedValue && item.checkIfMinMax) {
      const checkOnlyMin = item?.min && selectedValue < item?.min
      const checkAndEqualMin = !item?.min && selectedValue < item?.min

      if (selectedValue > item?.max || (checkAndEqualMin || checkOnlyMin)) {
        const label = item?.checkIfNull ?
        getString('NUMBER_VALIDATION_REQUIRED') :
        getString('NUMBER_VALIDATION_NOT_REQUIRED')
    
        errors[item.name] = label.format(
          item?.min,
          item?.max
        )
      }
    }
  })
  return errors
}

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  initialized,
  ...custom
}: {
  input: any
  label: string
  meta: { touched: any; error: any }
  initialized: boolean
}) => (
  <TextFieldTSX
    label={label}
    hintText={label}
    floatingLabelText={label}
    error={initialized && error}
    helperText={error}
    className="form-field-text-custom"
    {...input}
    {...custom}
  />
)

const renderToggleField = ({ input, label, change, disabled }: any) => (
  <div className="form-field-toggle">
    <div>{label}</div>
    <CastorSwitch
      disabled={disabled}
      checked={input.value}
      onChange={input.onChange}
    />
  </div>
)

const renderReduxFormField = (
  name: string,
  label: string,
  type: string,
  isToggle: boolean,
  initialized: boolean
) => {
  return (
    <ReduxFormField
      name={name}
      component={isToggle ? renderToggleField : renderTextField}
      label={label}
      type={type}
      initialized={initialized}
    />
  )
}

const AdminPrinterTechFrom: FC<InjectedFormProps<IFormData, IProps> & IProps> =
  ({
    handleSubmit,
    onSubmitClick,
    reset,
    pristine,
    submitting,
    valid,
    initialized
  }) => {
    return (
      <Flexbox className="admin-project-form" flexDirection="column">
        {formFields.map(({ name, label, type, isToggle }) =>
          renderReduxFormField(name, label, type, isToggle, initialized)
        )}
        <Flexbox
          alignItems="center"
          alignSelf="flex-end"
          justifyContent="space-between"
          width="200px"
        >
          <TransparentButton onClick={() => reset()}>
            {getString('RESET')}
          </TransparentButton>
          <ButtonWithLoader
            onClick={handleSubmit(onSubmitClick)}
            loading={false}
            disabled={pristine || submitting || !valid}
          >
            {getString('SUBMIT')}
          </ButtonWithLoader>
        </Flexbox>
      </Flexbox>
    )
  }

export default memo(
  reduxForm<IFormData, IProps>({
    form: 'AdminPrinterTechParameters',
    validate,
    enableReinitialize: true
  })(AdminPrinterTechFrom)
)
