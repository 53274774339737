import React, { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import Flexbox from 'Scenes/Components/FlexBox'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import {
	clearProjectsData,
	getProjectsData,
	toggleAllProjectsData,
	toggleOnlyProjectsData,
	toggleProjectsData,
	updateProjectsData
} from 'Scenes/Home/Customize/CustomizeRecalculateProjects/CustomizeRecalculateProjectsAction'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CheckboxTSX: any = Checkbox
const InputLabelTsx: any = InputLabel
const ButtonTSX: any = Button
const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const FormControlTsx: any = FormControl

const CustomizeRecalculateProjects: FC = () => {
	const dispatch = useDispatch()
	const { preparedProjects, selectedProjects, error, isLoading } = useSelector(
		(state: RootStateOrAny) => state?.CustomizeRecalculateProjectsReducer
	)

	useEffect(() => {
		dispatch(getProjectsData())

		return () => {
			dispatch(clearProjectsData())
		}
	}, [])

	const renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}

	return (
		<NavBarAndMaterial title={getString('NAV_TITLE_CUSTOMIZE_USER')}>
			<CastorForm
				formHeader={renderFormHeader()}
				formTitle={getString('SELECT_PROJECTS_HEADER')}
				alertType={AlertType.WARNING}
				content={
					<div className="multiple-projects">
						<p>
							{getString('CUSTOMIZE_RECALCULATE_PROJECTS_EXPLANATION_ARRAY')}
						</p>
						<FormControlTsx className="multiple-projects__form">
							<InputLabelTsx id="multiple-projects-label">
								{getString('SELECT_PROJECT_HEADER')}
							</InputLabelTsx>
							<SelectTsx
								labelId="multiple-projects-label"
								id="multiple-projects"
								className="multiple-projects-select"
								multiple
								MenuProps={{
									autoWidth: false,
									variant: 'menu',
									getContentAnchorEl: null,
									classes: { paper: 'multiple-projects-select-paper' }
								}}
								value={selectedProjects}
								onChange={(e: any) =>
									dispatch(toggleProjectsData(e.target.value, preparedProjects))
								}
								renderValue={(selected: any) => {
									const names = selected.map(
										(id: string) =>
											preparedProjects.find((project: any) => id === project.id)
												?.name
									)
									return (
										<div className="multiple-projects__selected-value">
											{names.length > 1
												? getString('PROJECTS_SELECTED').format(names.length)
												: getString('PROJECT_SELECTED').format(names.length)}
										</div>
									)
								}}
							>
								<div className={'multiple-projects-select-paper-button'}>
									<ButtonTSX
										onClick={(e: React.MouseEvent<HTMLDivElement>) => {
											e.preventDefault()
											e.stopPropagation()
											dispatch(toggleAllProjectsData(preparedProjects))
										}}
									>
										<span>{getString('SELECT_ALL_PROJECT_HEADER')}</span>
									</ButtonTSX>
								</div>

								{preparedProjects?.map(({ id, name, selected }: any) => (
									<MenuItemTsx key={id} value={id} className="menu-item">
										<Flexbox
											alignItems="center"
											justifyContent="flex-start"
											width="100%"
										>
											<CheckboxTSX color="primary" checked={selected} />
											<div>{name}</div>
										</Flexbox>
										<div
											className="multiple-projects__select-only"
											onClick={(e: React.MouseEvent<HTMLDivElement>) => {
												e.stopPropagation()
												dispatch(toggleOnlyProjectsData(id, preparedProjects))
											}}
										>
											{getString('SELECT_ONLY_TECHNOLOGY')}
										</div>
									</MenuItemTsx>
								))}
							</SelectTsx>
						</FormControlTsx>

						<Flexbox
							className="multiple-projects-select-only"
							alignItems="center"
							justifyContent="flex-end"
							width="100%"
						>
							<ButtonWithLoader
								loading={isLoading}
								onClick={() => dispatch(updateProjectsData(selectedProjects))}
								disabled={
									!Feature.isFeatureOn(
										FeatureComponentId.RECALCULATE_PROJECTS
									) || !selectedProjects.length
								}
							>
								{getString('RECALCULATE')}
							</ButtonWithLoader>

							<p className="error">{error}</p>
						</Flexbox>
					</div>
				}
			/>
		</NavBarAndMaterial>
	)
}

export default memo(CustomizeRecalculateProjects)
