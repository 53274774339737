import { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import AdvancedExplanation from 'Scenes/Components/AdvancedSettings/common/AdvancedExplanation'
import AdvancedFilter from 'Scenes/Components/AdvancedSettings/common/AdvancedFilter'
import { IN_HOUSE_PRINTERS } from 'Services/Constants'

import './index.scss'

interface AdvancedSettingsProps {
	id: string | number
	explanationArray?: Array<string>
	showTitle?: boolean
	title?: string
	extraExplanation?: string
	allowCustomize: boolean
	hideInHouseFilter?: boolean
}

const AdvancedSettings: FC<AdvancedSettingsProps> = ({
	id,
	explanationArray,
	showTitle,
	title,
	extraExplanation,
	allowCustomize,
	hideInHouseFilter = false
}) => {
	const [{ printingTechnologies }, { tempFilters }] = useSelector(
		(state: RootStateOrAny) => [
			state.user,
			state.AdvancedSettingsReducer?.advancedStates[id] ||
				new AdvancedSettingsInitialState()
		]
	)

	return (
		<div className="advanced-filter">
			<AdvancedExplanation
				explanationArray={explanationArray}
				showTitle={showTitle}
				title={title}
				extraExplanation={extraExplanation}
			/>
			<div className="advanced-filter__bordered">
				<div className="advanced-filter__wrapper">
					{tempFilters.map((filter: any) => {
						if (hideInHouseFilter && filter.name === IN_HOUSE_PRINTERS)
							return null
						return (
							<AdvancedFilter
								id={id}
								printingTechnologies={printingTechnologies}
								key={filter.name}
								filter={filter}
								allowCustomize={allowCustomize}
							/>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default memo(AdvancedSettings)
