import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import cx from 'classnames'

import { getString } from 'Services/Strings/StringService'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Loader from 'Scenes/Loader/Loader'
import StatusMessage from './StatusMessage'

const PaperTSX: any = Paper

interface LinearProgressBarProps {
	small?: boolean
	bottomAnalyse?: boolean
	hideFiles?: boolean
	showAnalyze?: boolean
}

const LinearProgressBar: FC<LinearProgressBarProps> = ({
	small,
	bottomAnalyse,
	hideFiles,
	showAnalyze = true
}) => {
	const {
		progress,
		filesUploaded,
		totalFiles,
		uploadSuccess,
		progressBar
	} = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})

	const stillInProgress = progress < 100 && !hideFiles

	return (
		<div className={cx('linear-progress-bar', { 'small-view': small })}>
			{!hideFiles && (
				<PaperTSX className="linear-progress-bar__box">
					<h2>
						{getString('UPLOAD')}
						{stillInProgress && (
							<span>
								<IconFactory iconName="warn" />
								{getString('UPLOAD_PARTS_KEEP_PAGE_OPEN')}
							</span>
						)}
					</h2>
					<div className="linear-progress-bar__box-progress">
						<div className="linear-progress-bar__loader">
							{stillInProgress ? (
								<Loader load={true} size={100} message="" showFlex={false} />
							) : (
								<IconFactory iconName="checkmark" />
							)}
						</div>
						<div className="linear-progress-bar__info">
							<div className="linear-progress-bar--progress-indicator">
								<div>{progress}%</div>
							</div>
							<LinearProgress
								classes={{
									root: 'progress-line',
									bar: 'progress-bar'
								}}
								className="progress"
								variant="determinate"
								value={+progress}
							/>
							<div className="linear-progress-bar--files-indicator">
								{getString('UPLOADING_MESSAGE').format(
									filesUploaded.length,
									totalFiles.length
								)}
							</div>
						</div>
					</div>
				</PaperTSX>
			)}
			{showAnalyze && (
				<PaperTSX className="linear-progress-bar__box">
					<h2>
						{getString('ANALYZE')}
						<span>
							{!stillInProgress && getString('UPLOAD_PARTS_YOU_CAN_LEAVE')}
						</span>
					</h2>
					<div className="linear-progress-bar__box-progress">
						<div className="linear-progress-bar__loader">
							{!stillInProgress && uploadSuccess ? (
								<Loader load={true} size={100} message="" showFlex={false} />
							) : stillInProgress ? (
								''
							) : (
								<IconFactory iconName="checkmark" />
							)}
						</div>
						<div className="linear-progress-bar__info">
							<div className="linear-progress-bar--progress-indicator"></div>
							<div className="linear-progress-bar__info flex-column">
								<LinearProgress
									classes={{
										root: 'progress-line',
										bar: cx('progress-bar', { empty: progressBar === 0 })
									}}
									className="progress"
									variant="determinate"
									value={+progressBar}
								/>
								{!stillInProgress && (
									<p>
										<StatusMessage />
									</p>
								)}
							</div>
							<div className="linear-progress-bar--files-indicator"></div>
						</div>
					</div>
				</PaperTSX>
			)}
			{bottomAnalyse && !stillInProgress && (
				<span>{getString('UPLOAD_PARTS_YOU_CAN_LEAVE')}</span>
			)}
			{bottomAnalyse && stillInProgress && (
				<span>{getString('UPLOAD_PARTS_KEEP_PAGE_OPEN')}</span>
			)}
		</div>
	)
}

export default memo(LinearProgressBar)
