import { ChangeEvent, FC, useEffect, useState } from 'react'
import { Select, MenuItem } from '@material-ui/core'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'
import '../CustomizeCO2Calculation.scss'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

interface IProps {
	printersFullData: any
	selectedMaterials: any
	selectedPrinterName: any
	selectedPrinterCompany: any
	setSelectedPrinterId: Function
	setSelectedPrinterCompany: Function
	setSelectedPrinterName: Function
	setSelectedMaterials: Function
}

const EmissionsPrinterMaterialSelector: FC<IProps> = ({
	printersFullData,
	selectedMaterials,
	selectedPrinterName,
	selectedPrinterCompany,
	setSelectedPrinterCompany,
	setSelectedPrinterName,
	setSelectedMaterials,
	setSelectedPrinterId
}) => {
	const onChangePrinterCompany = (value: any) => {
		setSelectedPrinterCompany(value)
		setSelectedPrinterName('0')
		setSelectedMaterials('0')
	}

	const onChangePrinterName = (value: any) => {
		setSelectedPrinterName(value)
		setSelectedMaterials('0')
	}

	const onChangePrinterMaterial = (value: any) => {
		const companyIndex = printersFullData
			.map((object: { companyName: any }) => object.companyName)
			.indexOf(selectedPrinterCompany)

		const nameIndex = printersFullData[companyIndex].printers
			.map((object: { name: any }) => object.name)
			.indexOf(selectedPrinterName)
		const materialsList = printersFullData[companyIndex].printers[nameIndex]
		const materialIndex = materialsList.materials

			.map((object: { name: any }) => object.name)
			.indexOf(value)
		setSelectedPrinterId(materialsList.materials[materialIndex].id)
		setSelectedMaterials(value)
	}

	const renderPrinterName = (selectedPrinterCompany: any) => {
		const index = printersFullData
			.map((object: { companyName: any }) => object.companyName)
			.indexOf(selectedPrinterCompany)

		if (index !== -1) {
			return Object.keys(printersFullData[index].printers)
				.sort((a: string, b: string) =>
					printersFullData[index].printers[a].name?.localeCompare(
						printersFullData[index].printers[b].name
					)
				)
				.map(data => {
					return (
						<MenuItemTsx
							key={data}
							value={printersFullData[index].printers[data].name}
						>
							{printersFullData[index].printers[data].name}
						</MenuItemTsx>
					)
				})
		}
		return <></>
	}

	const renderPrinterMaterials = (
		selectedPrinterCompany: any,
		selectedPrinterName: any
	) => {
		if (selectedPrinterCompany !== '0' && selectedPrinterName !== '0') {
			const companyIndex = printersFullData
				.map((object: { companyName: any }) => object.companyName)
				.indexOf(selectedPrinterCompany)

			const nameIndex = printersFullData[companyIndex].printers
				.map((object: { name: any }) => object.name)
				.indexOf(selectedPrinterName)

			const materialsList = printersFullData[companyIndex].printers[nameIndex]
			return Object.keys(materialsList.materials)
				.sort((a: any, b: any) =>
					materialsList.materials[a].name?.localeCompare(
						materialsList.materials[b].name
					)
				)
				.map(data => {
					return (
						<MenuItemTsx key={data} value={materialsList.materials[data].name}>
							{materialsList.materials[data].name}
						</MenuItemTsx>
					)
				})
		}
		return <></>
	}

	const returnSelectTsx = () => {
		return (
			<Flexbox className="cycle-selector">
				<SelectTsx
					className="select"
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangePrinterCompany(e.target.value)
					}
					value={selectedPrinterCompany}
				>
					<MenuItemTsx value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')}
					</MenuItemTsx>
					{Object.keys(printersFullData)
						.sort((a: any, b: any) =>
							printersFullData[a].companyName?.localeCompare(
								printersFullData[b].companyName
							)
						)
						.map(company => {
							return (
								<MenuItemTsx
									key={company}
									value={printersFullData[company].companyName}
								>
									{printersFullData[company].companyName}
								</MenuItemTsx>
							)
						})}
				</SelectTsx>

				<SelectTsx
					className="select"
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangePrinterName(e.target.value)
					}
					value={selectedPrinterName}
				>
					<MenuItemTsx value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME')}
					</MenuItemTsx>
					{renderPrinterName(selectedPrinterCompany)}
				</SelectTsx>

				<SelectTsx
					className="select"
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangePrinterMaterial(e.target.value)
					}
					value={
						selectedMaterials?.length
							? selectedMaterials
							: [getString('ADMIN_MATERIALS_PAGE_HEADER')]
					}
				>
					<MenuItemTsx value="0" disabled>
						{getString('ADMIN_MATERIALS_PAGE_HEADER')}
					</MenuItemTsx>
					{renderPrinterMaterials(selectedPrinterCompany, selectedPrinterName)}
				</SelectTsx>
			</Flexbox>
		)
	}

	return <div>{returnSelectTsx()}</div>
}

export default EmissionsPrinterMaterialSelector
