import { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withStyles } from '@material-ui/core'
import Close from '@material-ui/icons/Close'

import ItemGrid from '../../../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import extendedTablesStyle from '../../../../assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx'
import {
  Table,
  Button,
  Muted
} from '../../../Components/thirdParty/CreativeTim/components/index.js'
import * as CustomizeUserMaterialsActions from './CustomizeUserMaterialsActions'
import {
  MATERIAL_COST_DELETE_ITEM,
  USER_MATERIAL_NAME,
  USER_MATERIAL_TYPE,
  IN_HOUSE_PRINTERS_ACTION,
  USER_MATERIAL_NO_MATERIALS_FOUND
} from '../../../../Services/Strings.js'

class CustomizeUserMaterialsTable extends Component {
  onDeleteClick = itemToDelete => {
    const { onDeleteClick, userId } = this.props
    onDeleteClick(itemToDelete, userId)
  }

  onDeleteConfirmed = () => {
    const { removeUserMaterial, userId, itemToDelete } = this.props
    removeUserMaterial(itemToDelete, userId)
  }

  renderDeleteButton = itemToDelete => {
    const { classes } = this.props
    return (
      <Button
        title={MATERIAL_COST_DELETE_ITEM}
        onClick={this.onDeleteClick.bind(this, itemToDelete)}
        color="dangerNoBackground"
        style={{ margin: 'unset', padding: 'unset' }}
        customClass={classes.actionButton}
      >
        <Close className={classes.icon} />
      </Button>
    )
  }

  renderInHousePrinterTblData = () => {
    const { userMaterials } = this.props
    if (!userMaterials.length) {
      return [['', <Muted>{USER_MATERIAL_NO_MATERIALS_FOUND}</Muted>, '']]
    }

    return userMaterials.map(userMaterial => [
      userMaterial.name,
      userMaterial.type,
      this.renderDeleteButton(userMaterial)
    ])
  }

  render() {
    return (
      <ItemGrid>
        <Table
          editTableStyle={'materialCostTableCell'}
          tableHead={[
            USER_MATERIAL_NAME,
            USER_MATERIAL_TYPE,
            IN_HOUSE_PRINTERS_ACTION
          ]}
          tableData={this.renderInHousePrinterTblData()}
        />
      </ItemGrid>
    )
  }
}

const mapStateToProps = ({ CustomizeUserMaterialsReducer, user }) => {
  const userMaterials = CustomizeUserMaterialsReducer.userId
      ? CustomizeUserMaterialsReducer.userMaterials
      : user.userMaterials,
    { userId, itemToDelete, showDeleteAlert } = CustomizeUserMaterialsReducer
  return { userMaterials, userId, itemToDelete, showDeleteAlert }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ ...CustomizeUserMaterialsActions }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(extendedTablesStyle)(CustomizeUserMaterialsTable))
