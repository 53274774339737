import {
	MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR,
	MATERIAL_CHANGED_IN_MATERIAL_SELECTOR,
	MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR,
	SETUP_MATERIAL_PICKER
} from '../../../global actions/types'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()
const materialPlaceholder = { name: '', id: -1, type: '', category: '' }

const INITIAL_STATE = {
	materials: [],
	defaultMaterialForTypeArr: [],
	material: defaultMaterial,
	materialType: defaultMaterial.type,
	materialCategory: defaultMaterial.category,
	materialArr: [],
	materialTypesArr: [],
	materialCategoriesArr: [],
	showCategorySelector: true,
	showMaterialsFilters: false,
	materialCategories: []
}

const materialSelectorReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SETUP_MATERIAL_PICKER: {
			const { defaultMaterialForTypeArr, initialMaterial, materialCategories } =
					action.payload,
				materials = action.payload.materials || [],
				materialCategoriesArr = generateMaterialCategories(
					materials,
					initialMaterial.type
				)

			const material =
				materials.find(
					material =>
						material.name === initialMaterial.name &&
						material.category === initialMaterial.category
				) || initialMaterial

			return {
				...state,
				defaultMaterialForTypeArr,
				materials,
				materialArr: filteredMaterialsByCategory(materials, material.category),
				materialCategories,
				materialCategoriesArr,
				materialTypesArr: generateMaterialTypes(materials),
				showCategorySelector: materialCategoriesArr.length > 1,
				material,
				materialType: material.type,
				materialCategory: material.category?.toLowerCase()
			}
		}
		case MATERIAL_TYPE_CHANGED_IN_MATERIAL_SELECTOR: {
			const { materials, defaultMaterialForTypeArr, materialCategories } = state
			const selectedTypeDefaultMaterial = defaultMaterialForTypeArr.find(
				material => material.type == action.payload
			)
			const setMaterial = materialCategories.find(
				material =>
					material.defaultMaterialSelection?.category?.toLowerCase() ===
					selectedTypeDefaultMaterial?.category?.toLowerCase()
			)?.defaultMaterialSelection
			const materialCategory = setMaterial?.category?.toLowerCase()
			const materialCategoriesArr = generateMaterialCategories(
				materials,
				action.payload
			)

			return {
				...state,
				materialArr: filteredMaterialsByCategory(materials, materialCategory),
				materialCategory,
				materialType: setMaterial?.type,
				materialCategoriesArr,
				showCategorySelector: materialCategoriesArr.length > 1,
				material: setMaterial || materialPlaceholder
			}
		}
		case MATERIAL_CATEGORY_CHANGED_IN_MATERIAL_SELECTOR: {
			const { materials, materialCategories } = state
			const setMaterial = materialCategories.find(
				material =>
					material.defaultMaterialSelection?.category?.toLowerCase() ==
					action.payload
			)?.defaultMaterialSelection
			const materialArr = filteredMaterialsByCategory(materials, action.payload)
			return {
				...state,
				materialCategory: action.payload?.toLowerCase(),
				materialArr: filteredMaterialsByCategory(materials, action.payload),
				material: setMaterial || materialArr[0] || materialPlaceholder
			}
		}
		case MATERIAL_CHANGED_IN_MATERIAL_SELECTOR:
			return {
				...state,
				material: state.materials.find(
					material => action.payload === material.name
				)
			}
		default:
			return state
	}
}

const generateMaterialCategories = (materials, type) => {
	return materials
		.map(material => {
			if (material.type?.toLowerCase() === type?.toLowerCase())
				return material.category
		})
		.sort()
		.filter((material, pos, self) => {
			if (self.indexOf(material) == pos) return material
		})
}
const filteredMaterialsByCategory = (materials, category) => {
	return materials
		.filter(
			(material, pos, self) =>
				self.indexOf(material) == pos &&
				material.category?.toLowerCase() === category?.toLowerCase()
		)
		.sort()
}
const generateMaterialTypes = materials => {
	return materials
		.map(material => material.type)
		.sort()
		.filter((material, pos, self) => {
			if (self.indexOf(material) == pos) return material
		})
}

export default materialSelectorReducer
