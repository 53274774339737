import React, { FC, memo, useRef } from 'react'
import { AnyAction, bindActionCreators } from 'redux'
import { connect, DispatchProp } from 'react-redux'
import ReactJoyride, {
  LIFECYCLE,
  STATUS,
  ACTIONS,
  CallBackProps,
  status
} from 'react-joyride'

import Beacon from './Beacon'
import Tooltip from './Tooltip'
import { TakeATourInitialState } from './TakeATourReducer'
import * as TakeATourActions from './TakeATourActions'
import WithFeatureToggleHOC from '../../../Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from '../../../Services/models/Features'

const Joyride: any = ReactJoyride

interface IReduxStore {
  TakeATourReducer: TakeATourInitialState
  user: any
}

interface IProps {
  pagesVisited: string[]
  email: string
  currentPageName: string
  updateTourAction: (
    updateTourActionAttributes: TakeATourActions.UpdateTourActionAttributes
  ) => any
  onStepIndexUpdated: (
    ...onStepIndexUpdatedParams: Parameters<
      typeof TakeATourActions.onStepIndexUpdated
    >
  ) => any
  onNextStepClick: (back?: boolean) => any
  onTourStop: () => any
}

const TakeATour: FC<IProps & TakeATourInitialState> = ({
  run,
  steps,
  stepIndex,
  pauseTour,
  pagesVisited,
  email,
  currentPageName,
  disableScrolling,
  customSteps,
  callback,
  updateTourAction,
  onNextStepClick
}) => {
  const beaconRef = useRef(null)
  const handleCallback = (data: CallBackProps) => {
    const { status, type, action, index } = data
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED]
    if (finishedStatuses.includes(status)) {
      updateTourAction({
        email,
        pagesVisited,
        pageName: currentPageName,
        isTourFinished: true
      })
    }
    if (typeof stepIndex === 'number' && type.includes(LIFECYCLE.ERROR)) {
      if (action === ACTIONS.NEXT || action === ACTIONS.PREV) {
        onNextStepClick(action === ACTIONS.PREV)
      }
    }
    if (type === LIFECYCLE.BEACON) {
      const beaconElement: any = beaconRef.current
      beaconElement && beaconElement.click()
    }
    callback(data)
  }

  return (
    <Joyride
      callback={handleCallback}
      run={run}
      continuous={true}
      showProgress={true}
      disableScrolling={disableScrolling}
      steps={customSteps || steps || []}
      stepIndex={stepIndex}
      pauseTour={pauseTour}
      disableCloseOnEsc={true}
      tooltipComponent={Tooltip}
      beaconComponent={(props: any) => <Beacon ref={beaconRef} {...props} />}
      styles={{
        options: {
          zIndex: pauseTour ? -1 : 1150
        }
      }}
    />
  )
}

const mapStateToProps = ({
  TakeATourReducer,
  user: {
    pagesVisited,
    currentPageName,
    userDetails: { email }
  }
}: IReduxStore) => {
  return { ...TakeATourReducer, pagesVisited, email, currentPageName }
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
  bindActionCreators({ ...TakeATourActions }, dispatch)

export default WithFeatureToggleHOC(
  memo(connect(mapStateToProps, mapDispatchToProps)(TakeATour)),
  FeatureComponentId.TAKE_A_TOUR
)
