import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link } from 'react-router-dom'
import { AnyAction, bindActionCreators } from 'redux'

import * as AdminUsersActions from '../AdminUsersActions'
import CastorForm from '../../../../Components/CastorForm/CastorForm'
import DataTable from '../../../../Components/DataTable'
import NavBarAndMaterial from '../../../../Components/NavBarAndMaterial'
import { Button } from '../../../../Components/thirdParty/CreativeTim/components'
import PageLoader from 'Scenes/Loader/PageLoader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { adminUserSubscriptionEditInfoRoute } from 'Services/routeFuncs'
import {
	NO_USER_MATERIALS_MSG,
	NO_USER_PRINTERS_MSG,
	USER_INFORMATION_HEADERS
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './AdminUserInfo.scss'

const NavBarAndMaterialTSX: any = NavBarAndMaterial
const LinkTSX: any = Link
const ButtonTSX: any = Button

interface IProps extends RouteComponentProps<MatchParams> {
	projectSelected: any
	userSelected: any
	loading: boolean
	userSubscriptionInfoData: any[][]
	userPersonalInfoData: any[][]
	userProjectAndPartInfoData: any[][]
	userPrintersData: any[][]
	userMaterialsData: any[][]
	userPrintersHeaders: any[]
	userMaterialsHeaders: any[]
	fetchUserInfo: (id: string) => any
	onUserInfoEditClick: (user: any) => any
	loaderCounter: number
}

interface IReduxStore {
	AdminUsersReducer: any
	GlobalReducer: any
}

interface MatchParams {
	userId: string
}

const AdminUserInfo: FC<IProps> = ({
	userSelected,
	loading,
	onUserInfoEditClick,
	fetchUserInfo,
	userSubscriptionInfoData,
	userPersonalInfoData,
	userProjectAndPartInfoData,
	userPrintersData,
	userMaterialsData,
	userPrintersHeaders,
	userMaterialsHeaders,
	match: { params },
	loaderCounter
}) => {
	useEffect(() => {
		if (!userSelected && !loading) {
			const { userId } = params
			fetchUserInfo(userId)
		}
	}, [userSelected, loading])

	const renderSubscriptionInfoTable = (
		onUserInfoEditClick: Function,
		userSelected: any
	) => {
		if (!userSelected) {
			return <div></div>
		}

		const editUser = Feature.isFeatureOn(FeatureComponentId.EDIT_USER)

		return (
			<div>
				{editUser && (
					<LinkTSX
						to={adminUserSubscriptionEditInfoRoute(userSelected.id)}
						onClick={() => onUserInfoEditClick(userSelected)}
					>
						<ButtonTSX size="sm" color="primary" data-qa="data-qa-edit-button">
							{getString('EDIT')}
						</ButtonTSX>
					</LinkTSX>
				)}
				<DataTable
					tableClassName="admin--users--user-info"
					tableDataRows={userSubscriptionInfoData}
					showBreakLines={true}
				/>
			</div>
		)
	}
	const renderPersonalInfo = () => {
		return (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userPersonalInfoData}
				showBreakLines={true}
			/>
		)
	}
	const renderPrintersInfo = () => {
		return userPrintersData && userPrintersData.length ? (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userPrintersData}
				tableHead={userPrintersHeaders}
				showBreakLines={true}
			/>
		) : (
			<div>{NO_USER_PRINTERS_MSG}</div>
		)
	}
	const renderMaterialsInfo = () => {
		return userMaterialsData && userMaterialsData.length ? (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userMaterialsData}
				showBreakLines={true}
				tableHead={userMaterialsHeaders}
			/>
		) : (
			<div>{NO_USER_MATERIALS_MSG}</div>
		)
	}
	const renderProjectAndPartsInfoTable = () => {
		return (
			<DataTable
				tableClassName="admin--users--user-info"
				tableDataRows={userProjectAndPartInfoData}
				showBreakLines={true}
			/>
		)
	}

	if (loaderCounter) {
		return <PageLoader withDrawer />
	}

	return (
		<NavBarAndMaterialTSX title={'Users'}>
			<CastorForm
				formTitle={
					USER_INFORMATION_HEADERS.SUBSCRIPTION_INFORMATION_INFO_HEADER
				}
				formSubTitle=""
				content={renderSubscriptionInfoTable(onUserInfoEditClick, userSelected)}
				style={{ maxWidth: 'unset' }}
			/>
			<CastorForm
				formTitle={USER_INFORMATION_HEADERS.PROJECT_AND_PARTS_INFO_HEADER}
				formSubTitle=""
				content={renderProjectAndPartsInfoTable()}
				style={{ maxWidth: 'unset' }}
			/>

			<CastorForm
				formTitle={USER_INFORMATION_HEADERS.PERSONAL_INFO_HEADER}
				formSubTitle=""
				content={renderPersonalInfo()}
				style={{ maxWidth: 'unset' }}
			/>
			<CastorForm
				formTitle={USER_INFORMATION_HEADERS.PRINTERS_INFO_HEADER}
				formSubTitle=""
				content={renderPrintersInfo()}
				style={{ maxWidth: 'unset' }}
			/>
			<CastorForm
				formTitle={USER_INFORMATION_HEADERS.MATERIALS_INFO_HEADER}
				formSubTitle=""
				content={renderMaterialsInfo()}
				style={{ maxWidth: 'unset' }}
			/>
		</NavBarAndMaterialTSX>
	)
}

const mapStateToProps = ({ AdminUsersReducer, GlobalReducer }: IReduxStore) => {
	return {
		...AdminUsersReducer,
		loaderCounter: GlobalReducer.loaderCounter
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...AdminUsersActions }, dispatch)

export default memo(connect(mapStateToProps, mapDispatchToProps)(AdminUserInfo))
