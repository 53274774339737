import React, { FC, memo, useCallback, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import { toNumber, uniqBy } from 'lodash'

import LightUserUploadProjectAlert from '../../LightUserUploadProjectAlert/LightUserUploadProjectAlert'
import {
	addedFiles,
	additionalNotesChanged,
	draggingToDropzoneChangedUpload,
	removeFile,
	uploadProjectClicked
} from '../../UploadProjectActions'
import FileRow from './FileRow'
import { useFilesValidation } from './hooks'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import WarnMessage from 'Scenes/Components/CastorWarn'
import FieldWithLabel from 'Scenes/Components/FieldWithLabel'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { KEY_3d } from 'Scenes/Home/NewUploadProject/constants'
import { UPLOAD } from 'Scenes/Home/NewUploadProject/constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'

import './index.scss'

const QuickUploadFiles: FC = () => {
	const {
		loading,
		filesToUpload,
		draggingFiles,
		ERP,
		CAD,
		unitType,
		toleranceIncluded,
		customToleranceValue,
		application,
		filterFieldsValue,
		filtersConfirmed,
		showUnitTypeSelector,
		selectedProjectScenario,
		BOMFile,
		amValue,
		trialUserPopupErrorMessage,
		filesWithMaterialAndQuantity,
		additionalNotes,
		error,
		isStandardCostBoxChecked
	} = useSelector((state: RootStateOrAny) => state.uploadProject)
	const [showTrialUserPopup, setShowTrialUserPopup] = useState(false)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const { filters } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[UPLOAD] ||
			new AdvancedSettingsInitialState()
	)
	const uploadRef = useRef()
	const dispatch = useDispatch()
	const history = useHistory()
	const { errorFiles, validateFiles, revalidateFiles } = useFilesValidation(
		filesWithMaterialAndQuantity
	)

	const quickUploadHeaders: string[] = getString(
		'QUICK_UPLOAD_FILES_CARD_HEADERS_ARRAY'
	)
	const lightUserMultipleUploads = Feature.isFeatureOn(
		FeatureComponentId.LIGHT_USER_MULTIPLE_UPLOADS
	)
	const personalizedLightUser =
		lightUserMultipleUploads && user?.userDetails.name
	const disableDropFiles =
		filesToUpload.length >= toNumber(user.maxAllowedLightUploadParts)
	const noAddedFiles = filesToUpload.length === 0
	const disableUpload = !filesWithMaterialAndQuantity.length

	const handleDrop = (
		files: File[],
		selectedType: string,
		rejectedFiles: File[]
	) => {
		if (
			disableDropFiles ||
			files.length > toNumber(user.maxAllowedLightUploadParts)
		) {
			return
		}

		dispatch(
			addedFiles(
				files,
				user?.acceptedFileTypes,
				user?.notSupportedUnitTypeFormats,
				selectedType,
				user?.partsFileSizeLimit,
				rejectedFiles
			)
		)
	}
	const dragEvent = useCallback(
		(show: boolean) => {
			dispatch(draggingToDropzoneChangedUpload(show))
		},
		[dispatch]
	)
	const fileChipDeleteClicked = useCallback(
		(file: File) => {
			dispatch(removeFile(file, user?.notSupportedUnitTypeFormats))
		},
		[dispatch, user?.notSupportedUnitTypeFormats]
	)

	const submitFormClicked = useCallback(
		(
			email: string = '',
			name: string = '',
			company: string = '',
			zipCode: string = ''
		) => {
			const projectCost = null
			const projectManufacturing = undefined
			const uniqMaterial: any = uniqBy(filesWithMaterialAndQuantity, 'id')
			const materialChosenSelected =
				uniqMaterial.length === 1 ? uniqMaterial[0] : null
			const quantity = materialChosenSelected?.quantity || null
			const drawingMaterial = false

			dispatch(
				uploadProjectClicked(
					filesToUpload[0]?.name,
					quantity,
					ERP,
					CAD,
					filesToUpload,
					unitType,
					BOMFile,
					materialChosenSelected,
					toleranceIncluded,
					toleranceIncluded
						? customToleranceValue
						: ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
					user,
					application,
					filters,
					showUnitTypeSelector,
					drawingMaterial,
					KEY_3d,
					selectedProjectScenario,
					history,
					false,
					{ email, name, company, zipCode },
					projectCost,
					projectManufacturing,
					amValue,
					filesWithMaterialAndQuantity,
					additionalNotes,
					personalizedLightUser,
					isStandardCostBoxChecked
				)
			)
		},
		[
			BOMFile,
			CAD,
			ERP,
			additionalNotes,
			amValue,
			application,
			customToleranceValue,
			dispatch,
			filesToUpload,
			filesWithMaterialAndQuantity,
			filterFieldsValue,
			filtersConfirmed,
			history,
			personalizedLightUser,
			selectedProjectScenario,
			showUnitTypeSelector,
			toleranceIncluded,
			unitType,
			user
		]
	)

	const handleStartUploading = useCallback(() => {
		const successValidation = validateFiles()
		if (!successValidation) return
		if (personalizedLightUser) {
			submitFormClicked(user?.userDetails.email, user?.userDetails.name)
		} else {
			setShowTrialUserPopup(true)
		}
	}, [
		personalizedLightUser,
		submitFormClicked,
		user?.userDetails.email,
		user?.userDetails.name,
		validateFiles
	])

	return (
		<div className="new-upload-project__block quick-upload">
			<CardHeaderBox
				cardClass="new-upload-project__quick-upload--card"
				contentClass="new-upload-project__card new-upload-project__quick-upload--card--content"
				title={getString('QUICK_UPLOAD_FILES')}
				content={
					<div
						className={cx(
							'new-upload-project__quick-upload--card--content--wrapper',
							{ 'without-header': noAddedFiles }
						)}
					>
						<div
							className={cx(
								'new-upload-project__quick-upload--card--content--header',
								{ hidden: noAddedFiles }
							)}
						>
							{quickUploadHeaders?.map((header, index) => (
								<div key={header + index}>{header}</div>
							))}
						</div>
						<div className="new-upload-project__quick-upload--card--content--body">
							{filesToUpload?.map((file: File, index: number) => {
								return (
									<FileRow
										key={file.name + index}
										uploadedFile={file}
										fileChipDeleteClicked={fileChipDeleteClicked}
										errorFiles={errorFiles}
										onBlur={revalidateFiles}
									/>
								)
							})}
							<Dropzone
								accept={user?.acceptedFileTypes.join(',')}
								ref={uploadRef}
								id="upload-project-dropzone"
								className={cx('upload-project-dropzone', {
									'drag-on': draggingFiles,
									empty: noAddedFiles
								})}
								onDrop={(files: File[], rejectedFiles: File[]) =>
									handleDrop(files, KEY_3d, rejectedFiles)
								}
								multiple
								onDragEnter={() => dragEvent(true)}
								onDragLeave={() => dragEvent(false)}
								data-qa="data-qa-dropzone-upload-part"
							>
								<DownloadBom className="upload-project-dropzone__icon" />
								<p className="upload-project-dropzone--title">
									{getString('NEW_UPLOAD_DROP_HERE')}
								</p>
								<p className="upload-project-dropzone--explanation">
									{getString('NEW_UPLOAD_ENTER_CAD')}
								</p>
							</Dropzone>
						</div>
						<div className="new-upload-project__quick-upload--card--content--footer">
							<FieldWithLabel
								fieldId="new-upload-project__quick-upload--notes"
								labelName={getString('ADDITIONAL_NOTES')}
								fieldPlaceholder={getString('NEW_UPLOAD_ENTER_TEXT')}
								fieldValue={additionalNotes}
								fieldOnChange={value => dispatch(additionalNotesChanged(value))}
								fieldClassName="new-upload-project__quick-upload--notes--field"
								wrapperClassName="new-upload-project__quick-upload--notes"
								autoFocus={false}
							/>
							<WarnMessage
								warnClassName="new-upload-project__quick-upload--warn-message"
								show={!!error}
								message={error}
							/>
							<ButtonWithLoader
								disabled={disableUpload}
								id="new-upload-project__quick-upload--start-upload"
								loading={loading}
								primary={true}
								onClick={handleStartUploading}
								className="new-upload-project__quick-upload--start-upload"
							>
								{getString('UPLOAD_FILES_BUTTON')}&nbsp;
								{filesToUpload?.length > 1 && (
									<span>
										({filesToUpload?.length} {getString('NEW_UPLOAD_FILES')})
									</span>
								)}
							</ButtonWithLoader>
						</div>
					</div>
				}
			/>
			<div className="new-upload-project__quick-upload--footer">
				{getString('QUICK_UPLOAD_FILES_FOOTER_EXPLANATION')}
			</div>
			<LightUserUploadProjectAlert
				show={showTrialUserPopup}
				onConfirm={(
					email: string,
					name: string,
					company: string,
					zipCode: string
				) => {
					submitFormClicked(email, name, company, zipCode)
				}}
				onCancel={() => {
					setShowTrialUserPopup(false)
				}}
				errorMessage={trialUserPopupErrorMessage}
			/>
		</div>
	)
}

export default memo(QuickUploadFiles)
