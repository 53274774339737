import { FolderOpen, FolderSpecial } from '@material-ui/icons'
import { isBoolean, isEmpty, isNull, isString } from 'lodash'

import ProjectPage from './ProjectPage'
import { UNDEFINED_STRING } from 'Services/Constants'
import { UPLOAD_ROUTE } from 'Services/Constants/RoutesConstants'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { Project, ProjectClusterStatus } from 'Services/models/IProject'
import { partRoute, projectRoute } from 'Services/routeFuncs'

interface PartRoute {
	id: number
	partNumber: string
}

interface Route {
	collapse?: boolean
	component?: any
	exact?: boolean
	folderId?: string
	icon?: any
	id?: string
	isInHome?: boolean
	name?: string
	navbarName?: string
	path?: string
	reanalyzingStatus?: string
	sidebar?: boolean
	state?: string
	views?: any

	// we can add any other field
	[name: string]: any
}

export const preparePartView = (project: Project) =>
	project.parts.map((part: PartRoute) => {
		return {
			path: partRoute(project.id, part.id),
			name: part.partNumber,
			navbarName: part.partNumber,
			icon: null,
			isInHome: true,
			exact: true,
			project
		}
	})

export const clustersToRoutes = (
	projects: Array<Project>,
	projectBundle: boolean
) => {
	const routes: Array<Route> = []
	projects.forEach((project: Project) => {
		if (project.clusterStatus === ProjectClusterStatus.complete) {
			let projectPath = projectRoute(project.id)
			let projectName = project.name
			let folderId = project.id

			if (project.bundle && projectBundle) {
				projectPath = projectRoute(project?.bundle?.id)
				projectName = project?.bundle?.name
				folderId = project?.bundle?.id
			}

			routes.push({
				id: project.id,
				folderId: folderId,
				folderPath: projectPath,
				folderName: projectName,
				path: `/clusterAnalysis/${project.id}`,
				name: project.name,
				clusterRoute: true,
				navbarName: project.name,
				icon: null,
				isInHome: true,
				project,
				hidden: true,
				sidebar: false
			})
		}
	})

	return routes
}

export const makeObject = (data: any, defaultData: any) =>
	data ? (typeof data === 'string' ? JSON.parse(data) : data) : defaultData

export const projectsToRoutes = (
	projects: Array<Project>,
	projectBundle: boolean,
	projectPartsInSideBar: boolean
) => {
	let routes: Array<Route> = []

	projects.forEach((project: Project) => {
		project.parts = makeObject(project.parts, [])
		project.bundle = makeObject(project.bundle, null)

		if (project.bundle && project?.bundle?.id) {
			const routeIndex = routes.findIndex(
				(route: Route) => route.folderId === project?.bundle?.id
			)
			// if we have bundle, we need to show only bundle +
			// hide the projects that related to bundle to have route
			if (projectBundle) {
				routes = makeRouteForProjectBundle(
					routeIndex,
					routes,
					project,
					projectPartsInSideBar
				)
			} else {
				// old logic for showing bundle collapse
				routes = makeCollapseRouteForProjectBundle(
					routeIndex,
					routes,
					project,
					projectPartsInSideBar
				)
			}
		} else {
			// regular project
			routes = makeRouteForProject(routes, project, projectPartsInSideBar)
		}
	})

	return routes
}

export const makeRouteForProject = (
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean
) => {
	let changedRoutes = routes
	let projectRoutes = {
		id: project.id,
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		icon: null,
		isInHome: true,
		project,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// check if we have more than 1 part then create partsView
		const partsViews =
			project.parts.length > 1 ? preparePartView(project) : undefined

		// create collapse menu for project parts
		changedRoutes.push({
			...projectRoutes,
			collapse: !!partsViews,
			state: `openProjectPart_${project.id}`,
			component: ProjectPage,
			icon: null,
			sidebar: true,
			views: partsViews
		})
	} else {
		//without parts in sidebar
		changedRoutes.push(projectRoutes)
	}

	return changedRoutes
}

export const makeRouteForProjectBundle = (
	routeIndex: number,
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean
) => {
	let changedRoutes = routes
	let bundleProject = {
		id: project?.bundle?.id,
		folderId: project?.bundle?.id,
		path: projectRoute(project?.bundle?.id),
		name: project?.bundle?.name,
		navbarName: project?.bundle?.name,
		isInHome: true,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// create collapse menu for project parts
		const bundleViews = project.parts.length > 0 ? preparePartView(project) : []

		if (routeIndex < 0) {
			// add first project route and view
			changedRoutes.push({
				...bundleProject,
				collapse: true,
				state: `openProjectPart_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				views: bundleViews
			})
		} else {
			// add to current project route views
			changedRoutes[routeIndex].views.push(...bundleViews)
		}
	} else {
		if (routeIndex < 0) {
			changedRoutes.push(bundleProject)
		}
	}

	// prepare hidden route
	// we need it to highlight area
	changedRoutes.push({
		id: project.id,
		folderId: project?.bundle?.id,
		folderPath: projectRoute(project?.bundle?.id),
		folderName: project?.bundle?.name,
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		icon: null,
		isInHome: true,
		project,
		hidden: true,
		sidebar: false
	})

	return changedRoutes
}

export const makeCollapseRouteForProjectBundle = (
	routeIndex: number,
	routes: Array<Route>,
	project: Project,
	projectPartsInSideBar: boolean
) => {
	let changedRoutes = routes
	let folderProject = {
		id: project?.bundle?.id,
		folderId: project?.bundle?.id,
		name: project?.bundle?.name,
		navbarName: project?.bundle?.name,
		isInHome: true,
		reanalyzingStatus: project.reanalyzingStatus,
		exact: true
	}

	let viewProject = {
		path: projectRoute(project.id),
		name: project.name,
		navbarName: project.name,
		reanalyzingStatus: project.reanalyzingStatus,
		icon: null,
		isInHome: true,
		exact: true,
		project
	}

	//show parts in sidebar
	if (projectPartsInSideBar) {
		// create collapse menu for project parts
		const partsViews = project.parts.length > 0 ? preparePartView(project) : []

		if (routeIndex < 0) {
			changedRoutes.push({
				collapse: true,
				name: project?.bundle?.name,
				state: `openProjectBundle_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				isInHome: true,
				folderId: project?.bundle?.id,
				exact: true,
				views: partsViews
			})
		} else {
			// add to current project route views
			changedRoutes[routeIndex].views.push(...partsViews)
		}
	} else {
		// old logic without parts in sidebar
		if (routeIndex < 0) {
			changedRoutes.push({
				...folderProject,
				collapse: true,
				state: `openProjectBundle_${project?.bundle?.id}`,
				component: ProjectPage,
				icon: null,
				sidebar: true,
				views: [viewProject]
			})
		} else {
			changedRoutes[routeIndex].views.push(viewProject)
		}
	}

	return changedRoutes
}

// keep showSideBar in localstorage if
// it's not provided form Url
export const getShowSideBarValue = (sideBar: string | boolean | null) => {
	let showSideBar = sideBar

	//always show sideBar if we go to Upload page param wasn't sent
	if (isNull(sideBar) && window.location.href?.includes(UPLOAD_ROUTE)) {
		showSideBar = true
	}

	//verify if new param is empty
	const emptyParam =
		isNull(showSideBar) || (isString(showSideBar) && isEmpty(showSideBar))

	//take data from localstorage
	const showSideBarFromLocalStorage =
		getStringItemFromLocalStorage('showSideBar')

	//verify if param is empty
	//and param in localstorage is not empty
	if (
		emptyParam &&
		showSideBarFromLocalStorage !== UNDEFINED_STRING &&
		!isEmpty(showSideBarFromLocalStorage)
	) {
		//take param from LS
		showSideBar = showSideBarFromLocalStorage
	} else {
		//update param
		setStringItemToLocalStorage('showSideBar', showSideBar?.toString() || '')
	}

	return showSideBar
}
