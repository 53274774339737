import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeCO2CarbonIntensity from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Manufacturing/CustomizeCO2CarbonIntensity'
import CustomizeCO2TypicalKwh from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Manufacturing/CustomizeCO2TypicalKwh'
import {
	onCo2PerKwChange,
	onDeleteCycleKwh,
	onDeletePrinterTypicalKwh,
	onPrinterTypicalkwhChange,
	onSetNewPrinterTypicalkwh,
	onSetUserCustomzationSettings,
	onTmTypicalkwhChange,
	onUpdateTypicalkwhTm
} from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { getString } from 'Services/Strings/StringService'

const Production: FC = () => {
	const { printersFullData, co2PerKW } = useSelector(
		(state: RootStateOrAny) => state.user
	)
	const {
		co2PerKwValue,
		typicalKwhList,
		typicalKwhPrinter,
		typicalKwhTm,
		cycleList,
		printersTypicalkwhList
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)
	const dispatch = useDispatch()

	return (
		<div className="material-production--wrapper">
			<div className="material-production--header">
				{getString('PRODUCTION')}
			</div>
			<CustomizeCO2CarbonIntensity
				setUserCustomzationSettings={(value: number, parameter: string) =>
					dispatch(onSetUserCustomzationSettings(value, parameter))
				}
				onCo2PerKwChange={(value: number) => dispatch(onCo2PerKwChange(value))}
				co2PerKwValue={co2PerKwValue}
				co2PerKW={co2PerKW}
			/>
			<CustomizeCO2TypicalKwh
				printersFullData={printersFullData}
				cycleList={cycleList}
				typicalKwhList={typicalKwhList}
				setNewPrinterTypicalkwh={(newPrinter: any) =>
					dispatch(onSetNewPrinterTypicalkwh(newPrinter))
				}
				typicalKwhPrinter={typicalKwhPrinter}
				typicalKwhTm={typicalKwhTm}
				onPrinterTypicalkwhChange={(value: number) =>
					dispatch(onPrinterTypicalkwhChange(value))
				}
				onTmTypicalkwhChange={(value: number) =>
					dispatch(onTmTypicalkwhChange(value))
				}
				printersTypicalkwhList={printersTypicalkwhList}
				onDeletePrinterTypicalKwh={(printerToDelete: any) =>
					dispatch(onDeletePrinterTypicalKwh(printerToDelete))
				}
				onUpdateTypicalkwhTm={(cycle: any) =>
					dispatch(onUpdateTypicalkwhTm(cycle))
				}
				onDeleteCycleKwh={(cycleToDelete: any) =>
					dispatch(onDeleteCycleKwh(cycleToDelete))
				}
			/>
		</div>
	)
}

export default Production
