import {
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS,
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR,
  GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED,
  ADMIN_MATERIAL_PRINTER_PAIR_DELETED,
  ADMIN_MATERIAL_PRINTER_PAIR_UPDATED,
  ADMIN_MATERIAL_PRINTER_PAIR_ADDED,
  ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED,
  ADMIN_MATERIAL_PRINTER_PAIR_INITIAL_VALUES_HAD_SET,
  NEW_ITEM_ADD_CALLED,
  NEW_ITEM_ADD_FAILED,
  GET_ADMIN_MATERIALS
} from '../../../../global actions/types'

const INITIAL_STATE = {
  materialPrinterPairs: [],
  totalMaterialPrinterPairs: 0,
  materialPrinterPairsTableData: [],
  showNoMaterialPrinterPairsAlert: false,
  pageNumber: 1,
  isLastPage: false,
  NumOfMaterialPrinterPairsSeen: 0,
  showPagination: false,
  limitMaterialPrinterPairs: 50,
  showAdminMaterialPrinterPairsAlert: false,
  selectedEditMaterialPrinterPair: null,
  materialPrinterPairAdded: false,
  searchPhrase: '',
  selectedNewMaterialPrinterPair: {},
  addingNewItem: false,
  loading: false
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_MATERIALS:
      return { ...state, loading: true }
    case GET_ADMIN_MATERIAL_PRINTER_PAIRS_SUCCESS:
      const { data, pageNumber } = action.payload,
        { materialPrinterPairs, totalMaterialPrinterPairs } = data,
        NumOfMaterialPrinterPairsSeen =
          (pageNumber - 1) * state.limitMaterialPrinterPairs +
          materialPrinterPairs.length,
        isLastPage = NumOfMaterialPrinterPairsSeen >= totalMaterialPrinterPairs
      return {
        ...state,
        materialPrinterPairs,
        totalMaterialPrinterPairs,
        materialPrinterPairsTableData: materialPrinterPairs.map(
          materialPrinterPair => Object.values(materialPrinterPair)
        ),
        showNoMaterialPrinterPairsAlert: !materialPrinterPairs.length,
        pageNumber,
        NumOfMaterialPrinterPairsSeen,
        isLastPage,
        showPagination: !(isLastPage && pageNumber === 1),
        showAdminMaterialPrinterPairsAlert: false,
        selectedNewMaterialPrinterPair: {},
        materialPrinterPairAdded: false,
        addingNewItem: false,
        loading: false,
      }
    case ADMIN_MATERIAL_PRINTER_PAIR_UPDATED:
      const updatedMaterialPrinterPair = action.payload
      return {
        ...state,
        materialPrinterPairs: state.materialPrinterPairs.map(
          materialPrinterPair =>
            materialPrinterPair.id === updatedMaterialPrinterPair.id
              ? updatedMaterialPrinterPair
              : materialPrinterPair
        )
      }
    case GET_ADMIN_MATERIAL_PRINTER_PAIRS_GOT_ERROR:
      return {
        ...state,
        showAdminMaterialPrinterPairsAlert: true,
        loading: false,
      }
    case GET_ADMIN_MATERIAL_PRINTER_PAIRS_EDIT_MATERIAL_PRINTER_PAIR_SELECTED:
      return {
        ...state,
        selectedEditMaterialPrinterPair: action.payload,
        materialPrinterPairAdded: false
      }
    case ADMIN_MATERIAL_PRINTER_PAIR_DELETED:
      const id = action.payload
      return {
        ...state,
        loading: false,
        materialPrinterPairs: state.materialPrinterPairs.filter(
          materialPrinterPair => materialPrinterPair.id !== id
        )
      }
    case ADMIN_MATERIAL_PRINTER_PAIR_ADDED:
      const newMaterialPrinterPair = action.payload
      return {
        ...state,
        materialPrinterPairs: [
          ...state.materialPrinterPairs,
          newMaterialPrinterPair
        ],
        materialPrinterPairAdded: true,
        addingNewItem: false
      }
    case ADMIN_MATERIAL_PRINTER_PAIR_SEARCH_PHRASE_CHANGED:
      const searchPhrase = action.payload
      return {
        ...state,
        searchPhrase
      }
    case ADMIN_MATERIAL_PRINTER_PAIR_INITIAL_VALUES_HAD_SET:
      return {
        ...state,
        selectedNewMaterialPrinterPair: action.payload
      }
    case NEW_ITEM_ADD_CALLED:
      return {
        ...state,
        addingNewItem: true
      }
    case NEW_ITEM_ADD_FAILED:
      return {
        ...state,
        addingNewItem: false
      }
    default:
      return state
  }
}
