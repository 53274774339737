import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import _ from 'lodash'

import * as AdminMaterialsActions from './AdminMaterialsActions'
import { ADMIN_MATERIALS_EDIT } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminMaterialForm from './AdminMaterialForm'

class EditAdminMaterial extends Component {
	componentDidMount() {
		const {
			materials,
			selectMaterialToEdit,
			match,
			setupAdminMaterialsPage,
			pageNumber,
			limitMaterials,
			searchPhrase
		} = this.props
		const { id } = match.params
		if (!materials.length) {
			setupAdminMaterialsPage(searchPhrase, pageNumber, limitMaterials)
		} else {
			selectMaterialToEdit(id, materials)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			materials,
			selectMaterialToEdit,
			match,
			selectedEditMaterial,
			setupEditMaterialForm,
			materialCategories
		} = this.props
		const { id } = match.params
		if (
			materials.length &&
			(!selectedEditMaterial || prevProps.match.params.id !== id)
		) {
			selectMaterialToEdit(id, materials)
		}
		if (!_.isEqual(selectedEditMaterial, prevProps.selectedEditMaterial)) {
			setupEditMaterialForm(materialCategories)
		}
	}

	onSubmit = data => {
		this.props.updateMaterial(data)
	}

	renderFormContent = () => {
		const {
			selectedEditMaterial,
			printingTechnologies,
			materialCategoriesToShow,
			materialCategories,
			onMaterialTypeChange,
			selectedMaterialType,
			selectedMaterialCategory,
			selectedSurfaceFinishMethod,
			defaultFilters
		} = this.props
		return (
			<AdminMaterialForm
				printingTechnologies={printingTechnologies}
				onSubmit={this.onSubmit}
				initialValues={selectedEditMaterial}
				materialCategories={materialCategoriesToShow}
				allMaterialCategories={materialCategories}
				onMaterialTypeChange={onMaterialTypeChange}
				selectedMaterialType={selectedMaterialType}
				selectedMaterialCategory={selectedMaterialCategory}
				selectedSurfaceFinishMethod={selectedSurfaceFinishMethod}
				userCurrencySign="$"
				defaultFilters={defaultFilters}
			/>
		)
	}

	render() {
		return (
			<NavBarAndMaterial title={ADMIN_MATERIALS_EDIT}>
				<CastorForm
					formTitle={ADMIN_MATERIALS_EDIT}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminMaterialFormState')

const mapStateToProps = state => {
	const { AdminMaterialsReducer, user } = state
	const {
		materials,
		selectedEditMaterial,
		pageNumber,
		limitMaterials,
		searchPhrase,
		materialCategoriesToShow
	} = AdminMaterialsReducer
	const { printingTechnologies, materialCategories } = user

	return {
		materials,
		selectedEditMaterial,
		pageNumber,
		limitMaterials,
		printingTechnologies,
		searchPhrase,
		materialCategories,
		materialCategoriesToShow,
		defaultFilters: selector(state, 'defaultFilters'),
		selectedMaterialType: selector(state, 'type'),
		selectedMaterialCategory: selector(state, 'category'),
		selectedSurfaceFinishMethod: selector(state, 'surfaceFinishMethod')
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminMaterialsActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAdminMaterial)
