import React, { FC, memo } from 'react'

import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminSingletons from './AdminSingletons/index'
import AdminMailManager from 'Scenes/admin/AdminHome/AdminSettings/AdminMailManager'

const NavBarAndMaterialTSX: any = NavBarAndMaterial

const AdminSettings: FC = () => {
	return (
		<NavBarAndMaterialTSX title={'Settings'}>
			<AdminSingletons />
			<AdminMailManager />
		</NavBarAndMaterialTSX>
	)
}

export default memo(AdminSettings)
