import React, { FC, memo, useEffect } from 'react'
import {
	connect,
	DispatchProp,
	RootStateOrAny,
	useDispatch,
	useSelector
} from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'
import { change, getFormSyncErrors, getFormValues, reset } from 'redux-form'

import * as SolutionAnalysisActions from '../SolutionAnalysisActions'
import { SolutionAnalysisInitialState } from '../SolutionAnalysisReducer'
import SolutionConfigureButtons from './SolutionConfigureButtons'
import SolutionConfigureContentSelection from './SolutionConfigureContentSelection'
import { IProps, IReduxProps, IReduxStore } from './SolutionConfigureInterface'
import SolutionConfigureMaterials from './SolutionConfigureMaterials'
import {
	changeAdvancedFilters,
	setupAdvancedFilters
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import {
	keepConfigurationUniqueFilters,
	prepareFiltersToSend
} from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import Devider from 'Scenes/Components/Devider/Devider'
import Flexbox from 'Scenes/Components/FlexBox'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IConfiguration } from 'Services/models/IConfiguration'
import { IMaterial } from 'Services/models/IMaterial'
import { IPrinter } from 'Services/models/IPrintersTypes'
import { IUserFilterNames } from 'Services/models/IUserFilter'

import 'assets/css/slider.scss'

import '../SolutionAnalysis.scss'

const DeviderTSX: any = Devider

const SolutionConfigure: FC<IProps & IReduxProps> = ({
	configuration,
	priorities,
	partId,
	projectId,
	loadingCalculation,
	enableSolutionButtons,
	solutionName,
	configurationId,
	solutionPriorities,
	showSolutionPriorities,
	batchSize,
	chosenMaterial,
	materials,
	initialMaterial,
	chosenMaterialType,
	chosenMaterialCategory,
	materialTypesList,
	categoriesList,
	materialsList,
	showMaterialsFilters,
	allOptionalPostProcessesData,
	solution,
	originalPostProcessValues,
	PostProcessOnCounter,
	tempSolutionPostProcessToggles,
	optionalPostProcessAvailability,
	optionalPostProcessesBreakDown,
	toleranceIncluded,
	clusterId,
	userPrinters,
	project,
	part,
	printingOrientationCalc,
	printingOrientationVector,
	configurationChanged,
	trayOrientationCustom,
	trayOrientationLoader,
	userCurrencySign,
	simpleConfiguration,
	printersCompaniesList,
	simpleConfigurationMaterialsList,
	simpleConfigurationPrinters,
	simpleConfigurationSelectorCompanyValue,
	simpleConfigurationSelectorPrinterValue,
	simpleConfigurationSelectorMaterialValue,
	simpleInhouseConfiguration,
	printersFullData,
	disableAdvancedButtons,
	printCostQuantity,
	tourConfigurationId,
	freezeConfiguration,
	configurationPrintIssues,
	initialToleranceValue,
	materialCategories,
	disableConfiguration,
	partSolution,
	disableCalculateButton,
	printingTechnologies,
	customConfiguration,
	printerMaterialID,
	change,
	onConfigureBackClick,
	onPrioritizeFieldChange,
	onCalculateClick,
	onFilterFeatureClick,
	onPrioritiesFeatureClick,
	onPriorityRemove,
	onBatchSizeChange,
	onResetClick,
	onChangeMaterial,
	onChangeMaterialCategory,
	onChangeMaterialType,
	originalFilterButtonClicked,
	onPostProcessesClick,
	onChangeOrientationClick,
	onInhouseTogleClick,
	onCompanyChangeSimpleConfiguration,
	onPrinterChangeSimpleConfiguration,
	onMaterialChangeSimpleConfiguration,
	onSimpleInhouseConfigurationChange,
	onTolerancesClick,
	onInHousePrintersAlertOpen,
	isResetDisableButton,
	manufacturingMethod
}) => {
	const dispatch = useDispatch()
	const showDefaultMaterialFilters = Feature.isFeatureOn(
		FeatureComponentId.DEFAULT_MATERIAL_FILTERS
	)
	const { filters, isError } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[configuration.id] ||
			new AdvancedSettingsInitialState()
	)
	const isSimpleDisableButton =
		simpleConfiguration && !simpleConfigurationSelectorMaterialValue
	configurationId = configuration.id

	useEffect(() => {
		dispatch(
			setupAdvancedFilters(configuration.id, false, configuration.filters)
		)
	}, [configuration.filters])

	useEffect(() => {
		if (showDefaultMaterialFilters && configurationChanged) {
			dispatch(
				changeAdvancedFilters(
					configuration.id,
					chosenMaterial?.defaultFilters,
					true
				)
			)
		}
	}, [chosenMaterial, configurationChanged])

	const isSolutionPrintable = (configuration: IConfiguration): boolean => {
		const result = configuration.result
		return !(
			result === CADAnalysisResult.notPrintable ||
			configuration.solution === null
		)
	}

	const resetInHouse = () => {
		onSimpleInhouseConfigurationChange(
			configurationId,
			printersFullData,
			configuration,
			false
		)
	}

	const resetConfiguration = () => {
		if (simpleConfiguration) {
			resetInHouse()
		}
		dispatch(
			setupAdvancedFilters(configuration.id, false, configuration.filters)
		)
		onResetClick(
			configurationId,
			configuration,
			priorities,
			initialMaterial,
			materials,
			originalPostProcessValues,
			solution,
			project,
			materialCategories
		)
	}

	const calculateConfiguration = () => {
		const inHouseOn = filters.find(
			(filter: any) => filter.name === IUserFilterNames.inHousePrinters
		)?.checked
		if (!userPrinters.length && inHouseOn) {
			onInHousePrintersAlertOpen()
			return
		}
		const material = simpleConfiguration ? initialMaterial : chosenMaterial
		const preparedFilters = prepareFiltersToSend(filters)
		const solutionFilters = keepConfigurationUniqueFilters(
			preparedFilters,
			filters,
			configuration.filters
		)
		if (printerMaterialID) {
			solutionFilters.printerMaterialID = printerMaterialID
		}

		onCalculateClick(
			configurationId,
			solution,
			partId,
			projectId,
			Object.fromEntries(solutionPriorities),
			solutionFilters,
			null,
			solutionName,
			configuration.solution && configuration.solution.id,
			material,
			batchSize,
			tempSolutionPostProcessToggles,
			allOptionalPostProcessesData,
			optionalPostProcessesBreakDown,
			optionalPostProcessAvailability,
			toleranceIncluded,
			clusterId,
			false,
			part,
			userCurrencySign,
			printCostQuantity,
			printingOrientationVector,
			printingOrientationCalc,
			false,
			simpleConfiguration,
			simpleConfigurationSelectorPrinterValue,
			simpleConfigurationSelectorMaterialValue,
			simpleInhouseConfiguration,
			undefined,
			tourConfigurationId
		)
	}

	return (
		<Flexbox
			flexDirection="column"
			justifyContent="space-between"
			alignItems="flex-end"
			className="solution-analysis-object-content-configure"
		>
			<Flexbox
				alignItems="flex-start"
				justifyContent="space-between"
				width="100%"
				className="solution-analysis-object-content-configure--top"
			>
				<SolutionConfigureMaterials
					configurationChanged={configurationChanged}
					onPostProcessesClick={onPostProcessesClick}
					onChangeOrientationClick={onChangeOrientationClick}
					onBatchSizeChange={onBatchSizeChange}
					configurationId={configurationId}
					numberOfPostProcessesOn={PostProcessOnCounter}
					batchSize={batchSize}
					initialMaterial={chosenMaterial}
					onChangeMaterial={onChangeMaterial}
					onChangeMaterialCategory={onChangeMaterialCategory}
					onChangeMaterialType={onChangeMaterialType}
					chosenMaterialType={chosenMaterialType}
					chosenMaterialCategory={chosenMaterialCategory}
					chosenMaterial={chosenMaterial}
					materialTypesList={materialTypesList}
					categoriesList={categoriesList}
					materialsList={materialsList}
					showMaterialsFilters={showMaterialsFilters}
					originalFilterButtonClicked={originalFilterButtonClicked}
					partSolution={partSolution}
					trayOrientationCustom={trayOrientationCustom}
					trayOrientationLoader={trayOrientationLoader}
					updatedPostProcess={tempSolutionPostProcessToggles}
					printable={isSolutionPrintable(configuration)}
					simpleConfiguration={simpleConfiguration}
					disableAdvancedButtons={disableAdvancedButtons}
					cluster={clusterId}
					part={part}
					configuration={configuration}
					configurationPrintIssues={configurationPrintIssues}
					initialToleranceValue={initialToleranceValue}
					lowestToleranceValue={partSolution?.lowestToleranceValue}
					disableConfiguration={disableConfiguration}
					onTolerancesClick={() => onTolerancesClick(configurationId)}
					manufacturingMethod={manufacturingMethod}
					isSpecifiedQuantity={configuration.isSpecifiedQuantity}
				/>
				<SolutionConfigureContentSelection
					configuration={configuration}
					priorities={priorities}
					printingTechnologies={printingTechnologies}
					solutionPriorities={solutionPriorities}
					showSolutionPriorities={showSolutionPriorities}
					userPrinters={userPrinters}
					simpleConfiguration={simpleConfiguration}
					printersCompaniesList={printersCompaniesList}
					simpleConfigurationMaterialsList={simpleConfigurationMaterialsList}
					simpleConfigurationPrinters={simpleConfigurationPrinters}
					onPrioritizeFieldChange={onPrioritizeFieldChange}
					onFilterFeatureClick={onFilterFeatureClick}
					onPrioritiesFeatureClick={onPrioritiesFeatureClick}
					onPriorityRemove={onPriorityRemove}
					onInhouseTogleClick={onInhouseTogleClick}
					onCompanyChangeSimpleConfiguration={(value: string) => {
						onCompanyChangeSimpleConfiguration(configurationId, value, change)
					}}
					onPrinterChangeSimpleConfiguration={(value: IPrinter) => {
						onPrinterChangeSimpleConfiguration(configurationId, value, change)
					}}
					onMaterialChangeSimpleConfiguration={(value: IMaterial) => {
						onMaterialChangeSimpleConfiguration(configurationId, value, change)
					}}
					onSimpleInhouseConfigurationChange={(value: boolean) => {
						onSimpleInhouseConfigurationChange(
							configurationId,
							printersFullData,
							configuration,
							value
						)
					}}
					simpleConfigurationSelectorCompanyValue={
						simpleConfigurationSelectorCompanyValue
					}
					simpleConfigurationSelectorPrinterValue={
						simpleConfigurationSelectorPrinterValue
					}
					simpleConfigurationSelectorMaterialValue={
						simpleConfigurationSelectorMaterialValue
					}
					simpleInhouseConfiguration={simpleInhouseConfiguration}
					customConfiguration={customConfiguration}
				/>
			</Flexbox>
			<Flexbox className="solution-analysis-object-content-configure-widget--footer">
				<DeviderTSX
					size="100%"
					className="solution-analysis-object-content-configure-widget-devider"
				/>
				<SolutionConfigureButtons
					freezeConfiguration={freezeConfiguration}
					configurationId={configurationId}
					onConfigureBackClick={() => {
						onConfigureBackClick(configurationId)
						resetConfiguration()
					}}
					onResetClick={() => resetConfiguration()}
					onCalculateClick={() => calculateConfiguration()}
					loadingCalculation={loadingCalculation}
					enableSolutionButtons={enableSolutionButtons}
					disableConfiguration={disableConfiguration}
					disableCalculateButton={
						disableCalculateButton || isSimpleDisableButton || isError
					}
					disableResetAllButton={isResetDisableButton}
				/>
			</Flexbox>
		</Flexbox>
	)
}

const mapStateToProps = (state: RootStateOrAny, ownProps: IProps) => {
	const {
		user: {
			filters,
			priorities,
			materials,
			allOptionalPostProcessesData,
			optionalPostProcessAvailability,
			optionalPostProcessesBreakDown,
			printers,
			userCurrencySign,
			printersFullData,
			materialCategories,
			printingTechnologies
		},
		SolutionAnalysisReducer,
		MaterialSelectorReducer: { material },
		MainPartAnalysisReducer: {
			partId,
			projectId,
			project,
			clusterId,
			solutions,
			currentStepTargetId,
			tourConfigurationId,
			partPrintIssues,
			initialToleranceValue
		}
	}: IReduxStore = state
	const toleranceIncluded = project && project.toleranceIncluded
	return {
		...(SolutionAnalysisReducer.states[ownProps.configuration.id] ||
			new SolutionAnalysisInitialState()),
		allOptionalPostProcessesData,
		filters,
		priorities,
		partId,
		projectId,
		partSolution: solutions.find(
			s =>
				s.id ===
				(ownProps.configuration.solution && ownProps.configuration.solution.id)
		),
		configurationPrintIssues: partPrintIssues.filter(
			partPrintIssue =>
				partPrintIssue.configuration === ownProps.configuration.id ||
				!partPrintIssue.configuration
		),
		material,
		materials,
		optionalPostProcessAvailability,
		optionalPostProcessesBreakDown,
		toleranceIncluded,
		clusterId,
		userPrinters: printers,
		project,
		userCurrencySign,
		printersFullData,
		currentStepTargetId,
		tourConfigurationId,
		initialToleranceValue,
		materialCategories,
		printingTechnologies
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{ ...SolutionAnalysisActions, reset, change, onInHousePrintersAlertOpen },
		dispatch
	)

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(memo(SolutionConfigure))
