export const itemBtn = {
  backgroundColor: 'transparent',
  color: 'unset'
}

export const btnDiv = {
  display: 'flex',
  alignItems: 'center'
}

export const divider = {
  width: '90%',
  margin: '0 auto'
}

export const userMenuTarget = {
  backgroundColor: 'transparent',
  display: 'flex',
  justifyContent: 'space-between',
  color: 'unset',
  // padding: '10px 15px',
  lineHeight: '1.5em',
  paddingLeft: '30px',
  // paddingRight: '10px',
  borderRadius: '3px',
  fontSize: '1.2em'
  // paddingTop: '1.7em'
}

export const margin = '10px 15px 0'
