import React, { FC, memo, useEffect, useState } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { Fade } from 'react-reveal'
import { reset } from 'redux-form'

import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'

import * as SolutionAnalysisActions from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import Loader from '../../../../Loader/Loader'
import {
	formatComponent,
	onCalculateClick,
	onConfigureBackClick,
	onFilterModalCancel,
	onFilterModalConfirm,
	onResetClick,
	onSimpleInhouseConfigurationChange,
	setupSolution
} from '../../MainPartAnalysis/SolutionAnalysis/SolutionAnalysisActions'
import FilterFeaturesModal from '../../MainPartAnalysis/SolutionAnalysis/SolutionFilterFeature'
import { addNewConfigurationId } from '../PartAnalysisConstants'
import {
	usePartReducer,
	useSolutionReducer,
	useUserReducer
} from '../PartAnalysisSelector'
import ConfigurationCreateNew from './ConfigurationCreateNew'
import ConfigurationCreatePrinterMaterial from './ConfigurationCreatePrinterMaterial'
import { ActionWithPayload } from 'global actions/ActionModels'
import { setupAdvancedFilters } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { prepareFiltersToSend } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsService'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { materialTypeChanged } from 'Scenes/Components/MaterialSelector/MaterialSelectorActions'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { hideInhouseAlertWithMaterial } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/MainPartAnalysisActions'
import { defaultMetal, materialTypes } from 'Services/Constants'
import { FormatType } from 'Services/models/IPart'
import { IPriority } from 'Services/models/IPriority'
import { IUserFilterNames } from 'Services/models/IUserFilter'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

interface ConfigurationNewSolutionAlertProps {
	setShowNewConfigurationAlert: Function
	showNewConfigurationAlert: boolean
	configuration: any
}

let ConfigurationNewAlert: FC<ConfigurationNewSolutionAlertProps> = ({
	setShowNewConfigurationAlert,
	showNewConfigurationAlert,
	configuration
}) => {
	const dispatch = useDispatch()
	const configurationId = configuration.id
	const {
		disableCalculateButton,
		loadingCalculation,
		solutionPriorities,
		simpleConfigurationSelectorPrinterValue,
		simpleConfigurationSelectorMaterialValue,
		simpleInhouseConfiguration,
		tempSolutionPostProcessToggles,
		solution,
		printCostQuantity,
		printingOrientationVector,
		printingOrientationCalc,
		simpleConfiguration,
		initialMaterial,
		originalPostProcessValues,
		printerMaterialID,
		chosenMaterial,
		keepMaterialForInHouseClick,
		showFilterFeaturesModal
	} = useSolutionReducer(configurationId)
	const {
		initialBatchSize,
		partId,
		project,
		clusterId,
		tourConfigurationId,
		part,
		cluster,
		partMaterial,
		solutions,
		isWeightReductionPart,
		partFeas,
		partPrintIssues,
		disablePart,
		feaId
	} = usePartReducer()
	const {
		priorities,
		printersFullData,
		allOptionalPostProcessesData,
		userCurrencySign,
		optionalPostProcessAvailability,
		optionalPostProcessesBreakDown,
		userCustomizationSettings,
		materialCategories,
		materials,
		printers
	} = useUserReducer()
	const { filters } = useSelector((state: RootStateOrAny) => {
		return (
			state.AdvancedSettingsReducer?.advancedStates[configurationId] ||
			new AdvancedSettingsInitialState()
		)
	})
	const [quantity, setQuantity] = useState<number>(initialBatchSize)
	const [configurationName, setConfigurationName] = useState<string>('')
	const [loading, setLoading] = useState(loadingCalculation)

	const toleranceIncluded = project && project.toleranceIncluded
	const defaultSelectedMaterial =
		configuration.material === materialTypes.metal
			? defaultMetal
			: defaultMaterial
	const selectedMaterial = !isEmpty(chosenMaterial)
		? chosenMaterial
		: partMaterial || defaultSelectedMaterial
	const willCreateNewConfiguration = configurationId === addNewConfigurationId

	useEffect(() => {
		if (!loadingCalculation && loadingCalculation !== loading) {
			setShowNewConfigurationAlert(false)
		}
		setLoading(loadingCalculation)
	}, [loadingCalculation])

	useEffect(() => {
		dispatch(
			setupSolution(
				configuration,
				part,
				cluster,
				filters,
				priorities,
				initialBatchSize,
				partMaterial,
				materials,
				solutions.find((s: any) => s.id === configuration?.solution?.id),
				allOptionalPostProcessesData,
				optionalPostProcessAvailability,
				optionalPostProcessesBreakDown,
				toleranceIncluded,
				userCurrencySign,
				printersFullData,
				isWeightReductionPart,
				partFeas.find((pF: any) => pF.configurationId === configuration?.id),
				partPrintIssues,
				materialCategories,
				disablePart,
				userCustomizationSettings,
				feaId
			)
		)

		return () => resetNewConfiguration(keepMaterialForInHouseClick)
	}, [showNewConfigurationAlert])

	const onResetClicked = (
		onResetClick: (
			...onResetClickParams: Parameters<
				typeof SolutionAnalysisActions.onResetClick
			>
		) => ActionWithPayload<any>,
		reset: any,
		configuration: any,
		priorities: IPriority[],
		initialMaterial: any,
		materials: any[],
		originalPostProcessValues: any,
		solution: any,
		project: any,
		materialCategories: any[]
	) => {
		dispatch(
			onResetClick(
				configurationId,
				configuration,
				priorities,
				initialMaterial,
				materials,
				originalPostProcessValues,
				solution,
				project,
				materialCategories
			)
		)
	}

	const resetNewConfiguration = (keepMaterial: boolean = false) => {
		// we need it only for reset from unmount
		if (keepMaterial) return

		setQuantity(initialBatchSize)
		setConfigurationName('')
		dispatch(materialTypeChanged(defaultSelectedMaterial.type))
		dispatch(setupAdvancedFilters(configurationId, true))

		if (simpleConfiguration) {
			dispatch(
				onSimpleInhouseConfigurationChange(
					configurationId,
					printersFullData,
					configuration,
					false
				)
			)
		}

		onResetClicked(
			onResetClick,
			reset,
			configuration,
			priorities,
			initialMaterial,
			materials,
			originalPostProcessValues,
			solution,
			project,
			materialCategories
		)
	}

	const confirmNewConfiguration = () => {
		let printerMaterialFilters: any = prepareFiltersToSend(filters)
		let material = selectedMaterial
		let solutionName = configurationName

		if (simpleConfiguration) {
			printerMaterialFilters = {
				...printerMaterialFilters,
				inHousePrinters: simpleInhouseConfiguration,
				printerMaterialID: printerMaterialID
			}

			material = initialMaterial

			solutionName = isEmpty(configurationName)
				? simpleConfigurationSelectorPrinterValue.name
				: configurationName
		}
		dispatch(hideInhouseAlertWithMaterial(configurationId, false))
		dispatch(
			onCalculateClick(
				configurationId,
				solution,
				partId,
				project.id,
				Object.fromEntries(solutionPriorities),
				printerMaterialFilters,
				null,
				solutionName,
				configuration.solution && configuration.solution.id,
				material,
				quantity,
				tempSolutionPostProcessToggles,
				allOptionalPostProcessesData,
				optionalPostProcessesBreakDown,
				optionalPostProcessAvailability,
				toleranceIncluded,
				clusterId,
				false,
				part || cluster,
				userCurrencySign,
				printCostQuantity,
				printingOrientationVector,
				printingOrientationCalc,
				false,
				simpleConfiguration,
				simpleConfigurationSelectorPrinterValue,
				simpleConfigurationSelectorMaterialValue,
				simpleInhouseConfiguration,
				undefined,
				tourConfigurationId
			)
		)
	}

	const handleAlertConfirm = () => {
		const inHouseOn = filters.find(
			(filter: any) => filter.name === IUserFilterNames.inHousePrinters
		)?.checked
		if (willCreateNewConfiguration && inHouseOn && !printers?.length) {
			dispatch(onInHousePrintersAlertOpen())
			return
		}
		confirmNewConfiguration()
	}

	return (
		<>
			<CastorAlert
				onConfirmReset={() => resetNewConfiguration()}
				showReset={true}
				headerTitle={
					configurationId === addNewConfigurationId
						? getString('NEW_CONFIGURATION')
						: getString('ADD_PRINTER_MATERIAL_CONFIGURATION_BUTTON')
				}
				alertClass={cx('add-new-solution-alert', {
					loading: loadingCalculation
				})}
				fullScreen={true}
				confirmOptionalText={getString('CALCULATE_CONFIGURATION')}
				onConfirm={handleAlertConfirm}
				onCancel={() => {
					setShowNewConfigurationAlert(false)
					dispatch(formatComponent(configurationId))
					dispatch(onConfigureBackClick(configurationId))
				}}
				show={showNewConfigurationAlert}
				disabled={disableCalculateButton}
				loadingCalculation={loadingCalculation}
			>
				<Loader
					wrapperClassName="new-configuration-loading"
					message={getString('CALCULATING')}
					load={loadingCalculation}
				/>
				<Fade>
					{willCreateNewConfiguration ? (
						<ConfigurationCreateNew
							defaultSelectedMaterial={selectedMaterial}
							setQuantity={setQuantity}
							quantity={quantity}
							configurationName={configurationName}
							setConfigurationName={setConfigurationName}
							configuration={configuration}
							isPart2d={(part || cluster)?.formatType === FormatType.pdf}
							isSpecifiedQuantity={part?.isSpecifiedQuantity}
						/>
					) : (
						<ConfigurationCreatePrinterMaterial
							setQuantity={setQuantity}
							quantity={quantity}
							configurationName={configurationName}
							setConfigurationName={setConfigurationName}
							configuration={configuration}
							isPart2d={(part || cluster)?.formatType === FormatType.pdf}
							isSpecifiedQuantity={part?.isSpecifiedQuantity}
						/>
					)}
				</Fade>
			</CastorAlert>
			<FilterFeaturesModal
				showFilterFeaturesModal={showFilterFeaturesModal}
				onFilterModalCancel={(configuration: any) =>
					dispatch(onFilterModalCancel(configuration))
				}
				onFilterModalConfirm={(id: any, toggles: any) =>
					dispatch(onFilterModalConfirm(id, toggles))
				}
				configuration={configuration}
			/>
		</>
	)
}

export default memo(ConfigurationNewAlert)
