import {
	MPIO_REQUEST,
	MPIO_REQUEST_FAIL,
	MPIO_REQUEST_SUCCESS
} from '../../../../global actions/types'
import {
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { requestMultiplePartsIntoOne } from '../../../../Services/Network'
import {
	COMBINE_AROUND_THIS_PART_SEARCH_EMPTY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
	COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
	CONFIGURATION_CALCULATION_ERROR,
	CONSOLIDATION_DELETE_RESULTS,
	CONSOLIDATION_KEEP_RESULTS,
	CONSOLIDATION_WILL_BE_OVERWRITTEN,
	NO,
	OK,
	REQUEST_FAILED,
	SEARCH_AGAIN_FOR_RESULT,
	YES
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'

export const sendMultiplePartsIntoOneRequest = (projectId, bundleId) => {
	return async dispatch => {
		try {
			dispatch({ type: MPIO_REQUEST })
			await requestMultiplePartsIntoOne(projectId, bundleId)
			dispatch({ type: MPIO_REQUEST_SUCCESS })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: COMBINE_MULTIPLE_INTO_ONE_REQ_BODY,
					headerTitle: COMBINE_MULTIPLE_INTO_ONE_REQ_TITLE,
					showCancel: false,
					alertType: AlertType.SUCCESS,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		} catch (error) {
			console.error(error.message)
			dispatch({ type: MPIO_REQUEST_FAIL })
			dispatch({
				type: ALERT_POPPED,
				payload: {
					text: CONFIGURATION_CALCULATION_ERROR,
					headerTitle: REQUEST_FAILED,
					showCancel: false,
					alertType: AlertType.WARNING,
					onConfirm: () => {
						dispatch({
							type: ALERT_POPUP_CANCELED
						})
					},
					confirmText: OK
				}
			})
		}
	}
}

export const confirmConsolidationAnalysis = (
	projectId,
	clustersCount,
	bundleId
) => {
	return async dispatch => {
		let text = (
			<div>
				{CONSOLIDATION_WILL_BE_OVERWRITTEN}
				<br />
				<br />
				<b>{YES}</b> {CONSOLIDATION_DELETE_RESULTS}
				<br />
				<b>{NO}</b> {CONSOLIDATION_KEEP_RESULTS}
			</div>
		)

		if (!clustersCount) {
			text = <div>{COMBINE_AROUND_THIS_PART_SEARCH_EMPTY}</div>
		}

		dispatch({
			type: ALERT_POPPED,
			payload: {
				text: text,
				headerTitle: SEARCH_AGAIN_FOR_RESULT,
				alertType: AlertType.WARNING,
				onConfirm: () => {
					dispatch({
						type: ALERT_POPUP_CANCELED
					})
					dispatch(sendMultiplePartsIntoOneRequest(projectId, bundleId))
				},
				confirmText: YES,
				cancelText: NO
			}
		})
	}
}
