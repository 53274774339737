import React, { FC, memo } from 'react'

import cx from 'classnames'

import ProgressBarStep from './ProgressBarStep'
import { IOnboardingWizardStep } from 'Services/models/IOnboardingWizardStep'
import { getString } from 'Services/Strings/StringService'

import './OnboardingProgressBar.scss'

type IProps = {
	steps: IOnboardingWizardStep[]
	currentStep: IOnboardingWizardStep
}

const OnboardingProgressBar: FC<IProps> = ({ steps, currentStep }) => {
	return (
		<div className="progress-bar--wrapper">
			{steps.map((step: IOnboardingWizardStep, index: number) => {
				const parentStep = steps.find(
					step => step.id === currentStep.parentStepId
				)
				const isFirstStep = index === 0
				const isStepCompleted =
					currentStep?.order > step.order ||
					Boolean(parentStep && parentStep.order > step.order)
				const isStepCurrent =
					currentStep?.id === step.id || currentStep?.parentStepId === step.id
				return (
					<>
						{!isFirstStep && (
							<div
								className={cx('progress-bar--connector', {
									disabled: !isStepCompleted && !isStepCurrent
								})}
							/>
						)}
						<ProgressBarStep
							title={getString(step.titleStringKey)}
							isStepCurrent={isStepCurrent}
							isStepCompleted={isStepCompleted}
							stepNumber={index + 1}
						/>
					</>
				)
			})}
		</div>
	)
}

export default memo(OnboardingProgressBar)
