import React, { FC, memo } from 'react'

import './DataTable.scss'

interface IProps {
  className?: string
}

const DataTableBreakRowLine: FC<IProps> = ({ className }) => (
  <div className={`data-table--break-row-line ${className || ''}`}></div>
)

export default memo(DataTableBreakRowLine)
