import React, { FC, memo, useMemo, useState } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import cx from 'classnames'

import { quantityOptions } from './constants'
import { useFileMaterialSelectorState } from './hooks'
import CastorSelectBox from 'Scenes/Components/CastorSelectBox'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

const SelectTSX: any = Select
const MenuItemTSX: any = MenuItem
const TextFieldTSX: any = TextField

type IProps = {
	fileName: string
}

const FileMaterialAndQuantitySelector: FC<IProps> = ({ fileName }) => {
	const [inputValue, setInputValue] = useState('')
	const {
		state,
		onMaterialTypeChange,
		onMaterialCategoryChange,
		onMaterialChange,
		onQuantityChange,
		onQuantityBlur,
		materialsService
	} = useFileMaterialSelectorState(fileName)

	const { materialTypesList, categoriesList, materialsList } =
		materialsService.getDataList()

	const disabledMenuItem = getString('CHOOSE')
	const isSelectedFromOptions = useMemo(
		() => !!quantityOptions.find(item => item === state.quantity),
		[state.quantity]
	)

	const onQuantityInputChange = (
		event: React.ChangeEvent<{}>,
		newInputValue: string
	) => {
		const regex = new RegExp(/^[1-9]+[0-9]*$/)
		if (newInputValue && !regex.test(newInputValue)) {
			return
		}
		setInputValue(newInputValue)
	}

	return (
		<>
			<Flexbox>
				{materialTypesList?.map(type => {
					const selected = type === state.materialType

					return (
						<CastorSelectBox
							key={type}
							setSelectedType={() => {
								onMaterialTypeChange(type)
							}}
							selected={selected}
							boxClassName="file-material-selector--box"
							qaDataElementName={`data-qa-material-type-${type}`}
						>
							{type}
						</CastorSelectBox>
					)
				})}
			</Flexbox>
			<SelectTSX
				displayEmpty
				disabled={!state.materialType}
				className={'file-row--select-field'}
				value={state.materialCategory}
				renderValue={(selected: string) => selected || disabledMenuItem}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					onMaterialCategoryChange(event.target.value)
				}
				classes={{
					select: 'file-row--select'
				}}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				MenuProps={{
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
			>
				<MenuItemTSX disabled>{disabledMenuItem}</MenuItemTSX>
				{categoriesList.map((category: string) => {
					return (
						<MenuItemTSX
							key={category}
							style={{ textTransform: 'capitalize' }}
							value={category}
						>
							{category}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
			<SelectTSX
				displayEmpty
				disabled={!state.materialCategory}
				className={'file-row--select-field'}
				value={state.material}
				renderValue={(selected: string) => selected || disabledMenuItem}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
					onMaterialChange(event.target.value)
				}
				classes={{
					select: 'file-row--select'
				}}
				inputProps={{
					name: 'simpleSelect',
					id: 'simple-select'
				}}
				MenuProps={{
					PaperProps: {
						style: {
							transform: 'translate3d(0, 0, 0)'
						}
					}
				}}
			>
				<MenuItemTSX disabled>{disabledMenuItem}</MenuItemTSX>
				{materialsList.map((material: Record<string, string>) => {
					return (
						<MenuItemTSX
							key={material.name}
							style={{ textTransform: 'capitalize' }}
							value={material.name}
						>
							{material.name}
						</MenuItemTSX>
					)
				})}
			</SelectTSX>
			<Autocomplete
				value={state.quantity}
				onChange={onQuantityChange}
				inputValue={inputValue}
				onInputChange={onQuantityInputChange}
				classes={{
					root: cx({ 'file-row--root': isSelectedFromOptions }),
					inputRoot: cx('file-row--select-field file-row--input-field', {
						'with-tilde': isSelectedFromOptions
					}),
					input: 'file-row--input-placeholder',
					inputFocused: 'focused',
					clearIndicator: 'file-row--clear-indicator',
					option: 'file-row--option',
					noOptions: 'file-row--no-options'
				}}
				onBlur={() => onQuantityBlur(inputValue)}
				options={quantityOptions}
				renderInput={(params: any) => (
					<TextFieldTSX {...params} placeholder={disabledMenuItem} />
				)}
				data-qa="data-qa-file-quantity-selector"
			/>
		</>
	)
}

export default memo(FileMaterialAndQuantitySelector)
