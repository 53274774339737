import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import _ from 'lodash'

import * as AdminPrintersActions from './AdminPrintersActions'
import { adminPrintersRoute } from '../../../../Services/routeFuncs'
import { ADMIN_PRINTERS_NEW } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminPrinterForm from './AdminPrinterForm'

class NewAdminPrinter extends Component {
	onSubmit = data => {
		const {
			createNewPrinter,
			selectedNewPrinter,
			createNewPrinterAndRemovePrinterName
		} = this.props
		if (_.isEmpty(selectedNewPrinter)) {
			createNewPrinter(data)
		} else {
			createNewPrinterAndRemovePrinterName(data, selectedNewPrinter.id)
		}
	}

	renderFormContent = () => {
		const {
			printingTechnologies,
			selectedNewPrinter,
			addingNewItem,
			onMaintenanceMachineCostFieldsSelectorNewPrinterChange,
			maintenanceMachineSelectorsValueNewPrinter,
			selectedPrinterTechnology
		} = this.props
		return (
			<AdminPrinterForm
				selectedPrinterTechnology={selectedPrinterTechnology}
				onMaintenanceMachineCostFieldsSelectorChange={
					onMaintenanceMachineCostFieldsSelectorNewPrinterChange
				}
				maintenanceMachineCostFieldsSelectorValue={
					maintenanceMachineSelectorsValueNewPrinter
				}
				initialValues={selectedNewPrinter}
				printingTechnologies={printingTechnologies}
				addingNewItem={addingNewItem}
				onSubmit={this.onSubmit}
			/>
		)
	}

	render() {
		const { printerAdded, pageNumber } = this.props
		if (printerAdded) {
			return <Redirect to={adminPrintersRoute(pageNumber)} />
		}
		return (
			<NavBarAndMaterial title={ADMIN_PRINTERS_NEW}>
				<CastorForm
					formTitle={ADMIN_PRINTERS_NEW}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminPrinterFormState')

const mapStateToProps = state => {
	const {
		printerAdded,
		pageNumber,
		selectedNewPrinter,
		addingNewItem,
		maintenanceMachineSelectorsValueNewPrinter
	} = state.AdminPrintersReducer
	const { printingTechnologies } = state.user
	return {
		printerAdded,
		pageNumber,
		printingTechnologies,
		selectedNewPrinter,
		addingNewItem,
		maintenanceMachineSelectorsValueNewPrinter,
		selectedPrinterTechnology: selector(state, 'technology')
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrintersActions }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAdminPrinter)
