import { Link } from 'react-router-dom'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import Button from '../../Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import CastorFormHeader from '../../Components/CastorForm/CastorFormHeader'
import { CUSTOMIZE } from 'Services/Strings'
import OverrideComponentHOC from '../../../themes/OverrideComponentHOC'
import CastorSwitch from '../../Components/CastorSwitch'

import { styles } from '../styles/formStyles.css'

const CustomizeFormSection = props => {
	const {
		explanationHeader,
		explanationArray,
		linkTo,
		switchChecked,
		onToggleChange,
		wrapperClassName,
		toggleClassName,
		qaDataElementName,
		label = '',
		buttonName = ''
	} = props
	const renderLink = () => {
		if (linkTo) {
			return (
				<Link to={linkTo}>
					<Button color="primary" data-qa={`${qaDataElementName}-btn`}>
						{buttonName || CUSTOMIZE}
					</Button>
				</Link>
			)
		}
	}

	const renderToggle = () => {
		if (onToggleChange) {
			return (
				<FormControlLabel
					control={<CastorSwitch checked={switchChecked} />}
					onChange={onToggleChange}
					label={label}
					className={toggleClassName}
					data-qa={`${qaDataElementName}-toggle`}
				/>
			)
		}
	}
	return (
		<div
			style={styles.itemsDiv}
			className={wrapperClassName}
			data-qa={qaDataElementName}
		>
			<div style={styles.customizeFormElement}>
				<CastorFormHeader
					explanationHeader={explanationHeader}
					explanationArray={explanationArray}
					isInCard={true}
				/>
			</div>
			{renderToggle()}
			{renderLink()}
		</div>
	)
}

export default OverrideComponentHOC(CustomizeFormSection)
