import React, { memo, FC } from 'react'
import { Select, withStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuItem from '@material-ui/core/MenuItem'
import './CastorSelector.scss'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const KeyboardArrowDownIconTsx: any = KeyboardArrowDownIcon

interface item {
  key: string
  value: any
}
interface IProps {
  value: String
  disabled: boolean
  onChange: Function
  children: any
  classes: any
  items: Array<item>
  className?: Array<item>
}
const styles = {
  select: {
    width: '207px',
    height: '37px',
    fontSize: ' 14px',
    borderRadius: '2px',
    border: '1px solid #dedede',
    padding: '0 15px',
    lineHeight: '2.8em',
    margin: '4px 0'
  },
  icon: {
    right: '14px'
  }
}
const createMenuItemTsxElement = (menuItem: any) => {
  return (
    <MenuItemTsx
      key={menuItem.key}
      style={{ textTransform: 'capitalize' }}
      value={menuItem.value}
    >
      {menuItem.key}
    </MenuItemTsx>
  )
}

const CastorSelector: FC<IProps> = ({
  value,
  disabled,
  onChange,
  children,
  classes,
  items,
  className = ''
}) => (
  <SelectTsx
    title={value}
    disableUnderline={true}
    disabled={disabled}
    className={`material-selector ${className}`}
    IconComponent={() => (
      <KeyboardArrowDownIconTsx />
      )
    }
    value={value || ''}
    onChange={onChange}
    classes={classes}
    variant={'filled'}
    inputProps={{
      name: 'simpleSelect',
      id: 'simple-select'
    }}
    MenuProps={{
      PaperProps: {
        style: {
          transform: 'translate3d(0, 0, 0)'
        }
      }
    }}
  >
    {items && items.map((item: any) => createMenuItemTsxElement(item))}
  </SelectTsx>
)

export default memo(withStyles(styles)(CastorSelector))
