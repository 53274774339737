import React, { FC, memo } from 'react'

import classnames from 'classnames'

import CastorPartResultIndex from 'Scenes/Components/CastorPartResultIndex/CastorPartResultIndex'
import {
	PART_RESULTS_LED_WITH_TITLE_INDEXES,
	partResults
} from 'Services/Constants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { INDEX } from 'Services/Strings'

interface PieFooterProps {
	is2dProject: any
	isAssembly: boolean
	partResultHovered: any
	chartIsEmpty: boolean
}

const PieFooter: FC<PieFooterProps> = ({
	is2dProject,
	isAssembly,
	partResultHovered,
	chartIsEmpty
}) => {
	let partResultIndexes = PART_RESULTS_LED_WITH_TITLE_INDEXES
	const pieChart3DMissingInfoOn = Feature.isFeatureOn(
		FeatureComponentId.PIE_CHART_3D_MISSING_INFORMATION
	)

	if (!is2dProject && !pieChart3DMissingInfoOn) {
		partResultIndexes = PART_RESULTS_LED_WITH_TITLE_INDEXES.filter(
			part => part.result !== partResults.missingInformation
		)
	}

	if (chartIsEmpty) {
		return null
	}

	return (
		<div
			className={classnames('project-results--label--index', {
				'limited-height': isAssembly
			})}
		>
			<h5>{INDEX}</h5>
			<CastorPartResultIndex
				className={classnames('led-elements', {
					'increased-gap': isAssembly
				})}
				indexes={partResultIndexes}
				onlyColors={true}
				currentTitle={partResultHovered}
				circleSize={20}
			/>
		</div>
	)
}

export default memo(PieFooter)
