import { lowerCase } from 'lodash'

import { isLoggedInFail } from '../../../../global actions'
import {
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	MATERIAL_ADDED_TO_USER,
	MATERIAL_REMOVED_FROM_USER,
	NEW_MATERIAL_FORM_LOADED,
	USER_MATERIALS_DELETE_CANCELED,
	USER_MATERIALS_DELETE_CLICKED,
	USER_MATERIALS_MATERIAL_ADD_FAILED,
	USER_MATERIALS_MATERIAL_ADDED,
	USER_MATERIALS_MATERIAL_ADDED_TO_USER,
	USER_MATERIALS_MATERIAL_REMOVED,
	USER_MATERIALS_MATERIAL_SUBMITED,
	USER_MATERIALS_MATERIAL_TYPE_CHANGED,
	USER_MATERIALS_RECIEVED,
	USER_MATERIALS_USER_ID_SELECTED,
	USER_MATERIALS_USER_MATERIALS_FORMATED
} from '../../../../global actions/types'
import {
	ALERT_CALCULATION_STARTED,
	ALERT_POPPED,
	ALERT_POPUP_CANCELED
} from '../../../../global actions/types/CastorAlertTypes'
import { store } from '../../../../index'
import {
	addMaterialToUser,
	getAllUserMaterials,
	removeMaterialFromUser
} from '../../../../Services/Network'
import {
	OK,
	SHOW_NOTIFICATION,
	USER_MATERIAL_ADD_FAILED,
	USER_MATERIAL_ADDED,
	USER_MATERIAL_DELETE_ALERT_BODY,
	USER_MATERIAL_DELETE_ALERT_TITLE,
	USER_MATERIAL_DELETE_FAILED
} from '../../../../Services/Strings'
import { AlertType } from '../../../Components/alerts/AlertTypes'

export const setupUserMaterials = match => {
		if (match.params['userId']) {
			return getUserMaterials(parseInt(match.params['userId']))
		} else {
			return formatUserMaterials()
		}
	},
	getUserMaterials = userId => {
		return async dispatch => {
			dispatch({
				type: HANDLE_LOADER,
				payload: 1
			})
			dispatch({
				type: USER_MATERIALS_USER_ID_SELECTED,
				payload: userId
			})
			try {
				const response = await getAllUserMaterials(userId)
				const userMaterials = response.data.userMaterials
				dispatch({
					type: USER_MATERIALS_RECIEVED,
					payload: userMaterials
				})
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
			} catch (error) {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			}
		}
	},
	onUserMaterialAdd = (material, userId, materialCategories) => {
		return async dispatch => {
			dispatch({
				type: USER_MATERIALS_MATERIAL_SUBMITED
			})
			try {
				material.category = lowerCase(material.category)
				material.wallThickness = materialCategories.find(
					category => lowerCase(category.name) === material.category
				).wallThickness
				const response = await addMaterialToUser(material, userId)
				try {
					if (userId) {
						dispatch({
							type: USER_MATERIALS_MATERIAL_ADDED_TO_USER,
							payload: response.data.userMaterials
						})
					} else {
						const { userMaterials } = store.getState().user
						const addedMaterial = response.data.userMaterials.filter(
							({ id }) =>
								!userMaterials.some(({ id: existId }) => existId === id)
						)

						dispatch({
							type: MATERIAL_ADDED_TO_USER,
							payload: {
								userMaterials: response.data.userMaterials,
								material: addedMaterial
							}
						})
					}

					dispatch({
						type: USER_MATERIALS_MATERIAL_ADDED
					})

					dispatch({
						type: HANDLE_NOTIFICATION,
						payload: {
							notificationType: SHOW_NOTIFICATION.SUCCESS,
							notificationMessage: USER_MATERIAL_ADDED
						}
					})
				} catch (error) {
					console.log(error)
					isLoggedInFail(dispatch, error)
				}
			} catch (error) {
				console.log(error)
				dispatch({
					type: HANDLE_NOTIFICATION,
					payload: {
						notificationType: SHOW_NOTIFICATION.ERROR,
						notificationMessage:
							error.validationMessage || USER_MATERIAL_ADD_FAILED
					}
				})
				dispatch({
					type: USER_MATERIALS_MATERIAL_ADD_FAILED
				})
			}
		}
	},
	onDeleteClick = (itemToDelete, userId) => {
		return dispatch => {
			dispatch({
				type: ALERT_POPPED,
				payload: {
					headerTitle: USER_MATERIAL_DELETE_ALERT_TITLE,
					confirmText: OK,
					text: USER_MATERIAL_DELETE_ALERT_BODY,
					onConfirm: () => removeUserMaterial(itemToDelete, userId, dispatch),
					alertType: AlertType.WARNING
				}
			})
			dispatch({
				type: USER_MATERIALS_DELETE_CLICKED,
				payload: itemToDelete
			})
		}
	},
	onDeleteCancel = () => {
		return {
			type: USER_MATERIALS_DELETE_CANCELED
		}
	},
	removeUserMaterial = async (material, userId, dispatch) => {
		dispatch({
			type: USER_MATERIALS_DELETE_CANCELED
		})
		dispatch({
			type: ALERT_CALCULATION_STARTED
		})
		try {
			const response = await removeMaterialFromUser(material, userId)
			try {
				dispatch({
					type: ALERT_POPUP_CANCELED
				})
				if (userId) {
					dispatch({
						type: USER_MATERIALS_MATERIAL_REMOVED,
						payload: response.data.materials
					})
				} else {
					const { userMaterials } = store.getState().user
					const removedMaterial = userMaterials.filter(
						({ id: existId }) =>
							!response.data.materials.some(({ id }) => existId === id)
					)

					dispatch({
						type: MATERIAL_REMOVED_FROM_USER,
						payload: {
							userMaterials: response.data.materials,
							material: removedMaterial
						}
					})
				}
			} catch (error) {
				console.log(error)
				isLoggedInFail(dispatch, error)
			}
		} catch (error) {
			console.error(error)
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: USER_MATERIAL_DELETE_FAILED
				}
			})
			dispatch({
				type: ALERT_POPUP_CANCELED
			})
		}
	},
	formatUserMaterials = () => {
		return {
			type: USER_MATERIALS_USER_MATERIALS_FORMATED
		}
	},
	setupNewUserMaterialForm = () => {
		return {
			type: NEW_MATERIAL_FORM_LOADED
		}
	},
	onMaterialTypeChange = (selectedType, materialCategories) => {
		return {
			type: USER_MATERIALS_MATERIAL_TYPE_CHANGED,
			payload: { selectedType, materialCategories }
		}
	}
