import React, { FC, memo } from 'react'
import Chart from 'react-apexcharts'

import { isEqual } from 'lodash'

import ApexCharts from 'apexcharts'
import { getString } from 'Services/Strings/StringService'


const ChartGraph: any = Chart
const ApexChartsGraph: any = ApexCharts

interface ChartMap {
	chartRef: any,
	componentChartData: any,
	series: Array<any>,
	showLowMedium: boolean
	selectedXAxis: string
}

const ChartMap: FC<ChartMap> = ({
	chartRef,
	componentChartData,
	series,
	showLowMedium = false
}) => {
	return (
		<div id="chartID" className="chart">
			<div className="apexcharts-tooltip-1"></div>
			<ChartGraph
				ref={chartRef}
				id="chartDataApex"
				type="line"
				options={componentChartData}
				series={series}
				height="100%"
			/>
			{showLowMedium && (
				<div className="low-medium-good">
					<span className="low">{getString('LOW')}</span>
					<span className="medium">{getString('MEDIUM')}</span>
					<span className="good">{getString('GOOD')}</span>
				</div>
			)}
		</div>
	)
}

export default memo(ChartMap, (props: any, nextProps: any) => {
	return isEqual(props.selectedXAxis, nextProps.selectedXAxis)
})