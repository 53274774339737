import React, { FC } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import CustomizeCO2EmissionsPrinterMaterial from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/CustomizeCO2EmissionsPrinterMaterial'
import CustomizeCO2EmissionsRawMaterial from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/CustomizeCO2EmissionsRawMaterial'
import EmissionsPrinterMaterialTable from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/EmissionsPrinterMaterialTable'
import EmissionsRawMaterialTable from 'Scenes/Home/Customize/CustomizeCO2Calculation/CO2Emissions/EmissionsRawMaterialTable'
import {
	onCo2PerKgMaterialChange,
	onCo2PerKgPrinterMaterialChange,
	onDeleteMaterial,
	onDeletePrinterMaterial,
	onSetNewMaterial,
	onSetNewPrinterMaterial
} from 'Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2CalculationAction'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

const { defaultMaterial } = getTheme()

const MaterialProduction: FC = () => {
	const { printersFullData, materials, materialTypes, materialCategories } =
		useSelector((state: RootStateOrAny) => state.user)
	const {
		printersList,
		materialList,
		co2PerKgMaterial,
		co2PerKgPrinterMaterial
	} = useSelector(
		(state: RootStateOrAny) => state.CustomizeCO2CalculationReducer
	)

	const dispatch = useDispatch()

	return (
		<div className="material-production--wrapper">
			<div className="material-production--header">
				{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}
			</div>
			<CustomizeCO2EmissionsRawMaterial
				defaultMaterial={defaultMaterial}
				materialCategories={materialCategories}
				materialTypes={materialTypes}
				materials={materials}
				onCo2PerKgMaterialChange={(value: number) =>
					dispatch(onCo2PerKgMaterialChange(value))
				}
				setNewMaterial={(newMaterial: any) =>
					dispatch(onSetNewMaterial(newMaterial))
				}
				co2PerKg={co2PerKgMaterial}
			></CustomizeCO2EmissionsRawMaterial>
			<EmissionsRawMaterialTable
				materialList={materialList}
				deleteMaterial={(materialToDelete: any) =>
					dispatch(onDeleteMaterial(materialToDelete))
				}
			></EmissionsRawMaterialTable>
			<CustomizeCO2EmissionsPrinterMaterial
				onCo2PerKgPrinterMaterialChange={(value: number) =>
					dispatch(onCo2PerKgPrinterMaterialChange(value))
				}
				setNewPrinterMaterial={(newPrinterMaterial: any) =>
					dispatch(onSetNewPrinterMaterial(newPrinterMaterial))
				}
				co2PerKg={co2PerKgPrinterMaterial}
				printersFullData={printersFullData}
			></CustomizeCO2EmissionsPrinterMaterial>
			<EmissionsPrinterMaterialTable
				printersList={printersList}
				deleteMaterial={(materialToDelete: any) =>
					dispatch(onDeletePrinterMaterial(materialToDelete))
				}
			></EmissionsPrinterMaterialTable>
		</div>
	)
}

export default MaterialProduction
