import { ChangeEvent, FC, useState } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'

import ButtonWithLoader from '../../../Components/ButtonWithLoader'
import CastorMaterialSelector from '../../../Components/CastorMaterialSelector/CastorMaterialSelector'
import { Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'
import { isEnterKeyPressed } from 'Services/getKeyCodesService'
import { getSelectedMaterial } from './CastorMaterialNamesMappingService'

import './CustomizeMaterialNamesMapping.scss'

interface IProps {
  defaultMaterial: any
  materialCategories: any
  materialTypes: string[]
  materials: Material[]
  disabled: boolean
  updateSelectedMaterial: Function
}

const DividerTSX: any = Divider

const AddMaterialNamesMapping: FC<IProps> = ({
  defaultMaterial,
  materialCategories,
  materialTypes,
  materials,
  disabled,
  updateSelectedMaterial
}) => {
  const [expression, setExpression] = useState('')
  const [selectedMaterial, setSelectedMaterial] = useState<
    Material | undefined
  >(defaultMaterial)
  const [disableAddButton, setDisableAddButton] = useState(true)

  const saveInputFromUser = (e: any) => {
    setExpression(e)
    setDisableAddButton(false)
  }

  const onChangeSelector = (property: string, value: any) => {
    const _selectedMaterial = getSelectedMaterial(
      materials,
      materialCategories,
      property,
      value
    )
    setSelectedMaterial(_selectedMaterial)
  }

  const onAddClick = () => {
    if (!disableAddButton) {
      updateSelectedMaterial(
        {
          expression,
          material: selectedMaterial,
          active: true
        },
        onChangeSelector
      )
    }
    setExpression('')
    setDisableAddButton(true)
  }

  return (
    <div data-qa="data-qa-add-custom-material">
      <DividerTSX className="custom-material-names--divider" />
      <div className="custom-material-names--title">
        {getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_MATERIAL_TITLE')}
      </div>
      <div className="custom-material-names--explanation">
        {getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_ADD_BY_LIST_EXPLANATION')}
      </div>
      <div>
        <Flexbox alignItems="center">
          <TextField
            type="input"
            className="custom-material-names text"
            data-qa="data-qa-custom-material-name-input"
            value={expression}
            variant="standard"
            placeholder={getString('ENTER_EXPRESSION')}
            onChange={(e: ChangeEvent<HTMLInputElement>) => [
              saveInputFromUser(e.target.value)
            ]}
            onKeyUp={(e: any) => {
              if (isEnterKeyPressed(e)) {
                onAddClick()
              }
            }}
          />
          <CastorMaterialSelector
            onChangeMaterial={onChangeSelector}
            materialCategories={materialCategories}
            materials={materials}
            disabled={disabled}
            materialTypes={materialTypes}
            selectedMaterial={selectedMaterial}
          />
          <ButtonWithLoader
            size={20}
            style={{
              padding: '5px 20px',
              width: 'unset',
              maxWidth: '70px',
              minWidth: '70px'
            }}
            top={20}
            className="custom-material-names--AddButton"
            onClick={onAddClick}
            disabled={disableAddButton}
            qaDataElementName="data-qa-add-custom-material-btn"
          >
            {getString('PART_ANALYSIS_CONFIGURE_FEATURE')}
          </ButtonWithLoader>
        </Flexbox>
      </div>
    </div>
  )
}

export default AddMaterialNamesMapping
