import React, { memo, FC } from 'react'
import CostAnalysisTable from '.'
import CostAnalysisTableButtons from './CostAnalysisTableButtons/CostAnalysisTableButtons'
import CostAnalysisTableSwitch from './CostAnalysisTableSwitch'
import { Feature, FeatureComponentId } from "../../../../../../../../../../Services/models/Features";
import { Part } from '../../../../../../../../../../Services/models/IPart'

interface IProps {
  traditionalMethod: string
  show: boolean
  showAnalysisTable: boolean
  solution: any
  configuration: any
  onAnalysisTableSwitchChange: Function
  chainBenefits: any
  costLoading: boolean
  part: Part
}

const CostAnalysisTableAndSwitch: FC<IProps> = ({
  traditionalMethod,
  solution,
  configuration,
  showAnalysisTable,
  show,
  onAnalysisTableSwitchChange,
  chainBenefits,
  costLoading,
  part
}) => {
  if (!show) {
    return <div></div>
  }
  const costTabOn = Feature.isFeatureOn(
    FeatureComponentId.COST_TAB_INFORMATION
  )

  return (
    <div
      className={`cost-comparison-tab--table ${
        showAnalysisTable ? 'show' : ''
      }`}
    >
      {!costTabOn && <CostAnalysisTableButtons
        openSwitch={showAnalysisTable}
        onChange={onAnalysisTableSwitchChange}
        show={show}
      />}

      <CostAnalysisTable
        traditionalMethod={traditionalMethod}
        solution={solution}
        configuration={configuration}
        chainBenefits={chainBenefits}
        part={part}
      />

      {costTabOn && <CostAnalysisTableSwitch
        openSwitch={showAnalysisTable}
        onChange={onAnalysisTableSwitchChange}
        show={show}
        costLoading={costLoading}
      />}
    </div>
  )
}

export default memo(CostAnalysisTableAndSwitch)
