import { FC, memo } from 'react'

import cx from 'classnames'
import Delete from '@material-ui/icons/DeleteForever'

import CastorExcelExport from 'Scenes/Components/CastorExcelExport/CastorExcelExport'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import StandardCost from 'Scenes/Components/StandardCost'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import PartPropertiesButton from 'Scenes/Home/PartProperties/PartPropertiesButton'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import {
	EXPORT_AS_PDF,
	PROJECT_ANALYSIS_REMOVE_PROJECT
} from 'Services/Strings.js'
import { getString } from 'Services/Strings/StringService'

import './ProjectAnalysisActivities.scss'

const DeleteTSX: any = Delete

interface Props {
	showStandardCostFinishIcon: boolean
	deleteButtonClassName?: string
	deleteButtonText?: string
	deleteButtonIconClassName?: string
	showExportButton: boolean
	isAssembly?: boolean
	onStandardCostClick: Function
	onRemoveProjectClick: Function
	onExportClick: Function
	onExportPDFClick?: () => void
	is2dProject?: boolean
	disableProperties?: boolean
	isInapplicable?: boolean
	allPartsInapplicable?: boolean
	onPartPropertiesClick: Function
	exportPDFDisabled?: boolean
}

const renderStandardCostButton = (
	onStandardCostClick: Function,
	showStandardCostFinishIcon: boolean,
	isAssembly: boolean
) => {
	return (
		<StandardCost
			isPrimary={!isAssembly}
			isTransparent={isAssembly}
			onClick={onStandardCostClick}
			showStandardCostFinishIcon={showStandardCostFinishIcon}
		/>
	)
}

const renderPartsPropertiesButton = (
	onPartPropertiesClick: Function,
	isAssembly: boolean
) => {
	return (
		<PartPropertiesButton
			isPrimary={!isAssembly}
			isTransparent={isAssembly}
			onClick={onPartPropertiesClick}
		/>
	)
}

const renderDeleteButton = (
	onRemoveProjectClick: Function,
	deleteButtonClassName: string,
	deleteButtonText: string,
	deleteButtonIconClassName: string
) => {
	if (!Feature.isFeatureOn(FeatureComponentId.DELETE_PROJECT)) {
		return <></>
	}
	const disableDeleteProject = !Feature.isFeatureActive(
		FeatureComponentId.DELETE_PROJECT
	)
	return (
		<DetailsPopup
			managerClassName="order-4"
			isHover={disableDeleteProject}
			data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
			popperDirection="bottom-end"
			popperClassName="info-box-wrapper details-popup--contact-us"
			popperContactClassName="info-box-data"
		>
			<Button
				disabled={disableDeleteProject}
				onClick={onRemoveProjectClick}
				className={`parts-header--remove-button ${deleteButtonClassName}`}
				color="transparent"
				title={PROJECT_ANALYSIS_REMOVE_PROJECT}
				justIcon={!deleteButtonText}
				round={!deleteButtonText}
			>
				<DeleteTSX
					className={`parts-header--remove-button--icon ${deleteButtonIconClassName}`}
				/>
				<div className="parts-header--remove-button--text">
					{deleteButtonText}
				</div>
			</Button>
		</DetailsPopup>
	)
}

const renderExportExcelButton = (
	showExportButton: boolean,
	onExportClick: Function,
	isAssembly?: boolean
) => {
	if (!showExportButton) {
		return <div />
	}
	return <CastorExcelExport onClick={onExportClick} isAssembly={isAssembly} />
}

const renderExportPDFButton = (
	onExportPDFClick?: Function,
	exportPDFDisabled?: boolean
) => {
	const exportToPdfOn = Feature.isFeatureOn(
		FeatureComponentId.EXPORT_PROJECT_TO_PDF
	)
	const quickCADUploadIsOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)
	if (!onExportPDFClick || !exportToPdfOn) {
		return <></>
	}
	return (
		<Button
			color="primary"
			onClick={onExportPDFClick}
			className={cx('export-button transparent', {
				'in-quick-cad': quickCADUploadIsOn
			})}
			disabled={!!exportPDFDisabled}
		>
			<IconFactory iconName="exportPDF" className={'export-csv'} />
			<div>{EXPORT_AS_PDF}</div>
		</Button>
	)
}

const ProjectAnalysisActivities: FC<Props> = ({
	showStandardCostFinishIcon,
	showExportButton,
	deleteButtonClassName = '',
	deleteButtonText = '',
	deleteButtonIconClassName = '',
	isAssembly = false,
	onStandardCostClick,
	onRemoveProjectClick,
	onExportClick,
	onExportPDFClick,
	onPartPropertiesClick,
	is2dProject,
	isInapplicable,
	allPartsInapplicable,
	exportPDFDisabled
}) => {
	return (
		<div
			className={cx({
				'parts-header--assembly': isAssembly,
				'parts-header--not-assembly': !isAssembly
			})}
		>
			{renderPartsPropertiesButton(onPartPropertiesClick, isAssembly)}
			{!allPartsInapplicable &&
				!isInapplicable &&
				renderStandardCostButton(
					onStandardCostClick,
					showStandardCostFinishIcon,
					isAssembly
				)}
			{renderDeleteButton(
				onRemoveProjectClick,
				deleteButtonClassName,
				deleteButtonText,
				deleteButtonIconClassName
			)}
			{!allPartsInapplicable &&
				!is2dProject &&
				renderExportExcelButton(showExportButton, onExportClick, isAssembly)}
			{isAssembly && renderExportPDFButton(onExportPDFClick, exportPDFDisabled)}
		</div>
	)
}

export default memo(ProjectAnalysisActivities)
