import React, { useState } from 'react'
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux'

import { MaterialSelectorService } from 'Scenes/Components/MaterialSelector/MaterialSelectorService'
import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'
import { onFileMaterialAndQuantityChange } from '../../UploadProjectActions'
import { initialState } from './constants'

export const useFileMaterialSelectorState = (name: string) => {
	const { filesWithMaterialAndQuantity } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const state = filesWithMaterialAndQuantity.find(
		(file: IFileWithMaterialAndQuantity) => file.name === name
	)
	const user = useSelector((state: RootStateOrAny) => state.user)

	const dispatch = useDispatch()

	const materialsService = new MaterialSelectorService(
		user.materials,
		state.materialType,
		state.materialCategory,
		state.material,
		user.materialCategories
	)

	const materialAndQuantityChanged = (
		changedFile: IFileWithMaterialAndQuantity
	) => {
		dispatch(onFileMaterialAndQuantityChange(changedFile))
	}

	const onMaterialTypeChange = (value: string) => {
		const { chosenMaterialType, chosenMaterialCategory, chosenMaterial } =
			materialsService.materialTypeChanged(value)

		materialAndQuantityChanged({
			...initialState,
			name,
			quantity: state.quantity,
			materialType: chosenMaterialType,
			materialCategory: chosenMaterialCategory,
			material: chosenMaterial.name,
			id: chosenMaterial.id
		})
	}

	const onMaterialCategoryChange = (value: string) => {
		const { chosenMaterialCategory, chosenMaterial } =
			materialsService.materialCategoryChanged(value)

		materialAndQuantityChanged({
			...state,
			materialType: state.materialType,
			materialCategory: chosenMaterialCategory,
			material: chosenMaterial.name,
			id: chosenMaterial.id
		})
	}

	const onMaterialChange = (value: string) => {
		const { chosenMaterial } = materialsService.materialChanged(value)

		materialAndQuantityChanged({
			...state,
			material: chosenMaterial.name,
			id: chosenMaterial.id
		})
	}

	const onQuantityChange = (
		event: React.ChangeEvent<{}>,
		newValue: string | null
	) => {
		materialAndQuantityChanged({
			...state,
			quantity: newValue
		})
	}

	const onQuantityBlur = (inputValue: string) => {
		materialAndQuantityChanged({
			...state,
			quantity: inputValue
		})
	}

	return {
		state,
		onMaterialTypeChange,
		onMaterialCategoryChange,
		onMaterialChange,
		onQuantityChange,
		onQuantityBlur,
		materialsService
	}
}

export const useFilesValidation = (files: IFileWithMaterialAndQuantity[]) => {
	const [errorFiles, setErrorFiles] = useState<string[]>([])

	const validateFiles = () => {
		const errors: string[] = []
		files.forEach(file => {
			if (!file.id || !file.quantity) {
				errors.push(file.name)
			}
		})
		if (errors.length) {
			setErrorFiles(errors)
			return false
		}
		setErrorFiles([])
		return true
	}

	const revalidateFiles = () => {
		if (!errorFiles.length) return
		validateFiles()
	}

	return { errorFiles, validateFiles, revalidateFiles }
}
