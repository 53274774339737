import headerCardStyle from 'assets/jss/material-dashboard-pro-react/components/headerCardStyle.jsx'
import React, { memo } from 'react'

import cx from 'classnames'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
// material-ui components
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'

import './Cards.scss'

function HeaderCard({ ...props }) {
	const {
		classes,
		id,
		headerColor,
		plainCard,
		cardTitle,
		cardSubtitle,
		content,
		footer,
		footerAlign,
		action,
		contentRootClassName,
		alertType
	} = props
	const plainCardClasses = cx({
		[' ' + classes.cardPlain]: plainCard
	})
	const cardPlainHeaderClasses = cx({
		[' ' + classes.cardPlainHeader]: plainCard
	})
	const cardFooterClasses =
		classes.cardActions +
		'  ' +
		cx({
			[classes[footerAlign]]: footerAlign !== undefined
		})
	return (
		<Card className={classes.card + plainCardClasses} id={id}>
			<CardHeader
				classes={{
					root:
						classes.cardHeader +
						' ' +
						classes[headerColor + 'CardHeader'] +
						cardPlainHeaderClasses,
					title: classes.cardTitle,
					subheader: classes.cardSubtitle
				}}
				title={
					<div className="title">
						{alertType && (
							<IconFactory
								iconName={alertType}
								className="form-header--type-icon"
							/>
						)}
						{cardTitle}
					</div>
				}
				subheader={cardSubtitle}
				action={action}
				data-qa={`data-qa-card-header-${cardTitle}`}
			/>
			<CardContent className={`${contentRootClassName} ${classes.cardContent}`}>
				{content}
			</CardContent>

			{footer !== undefined ? (
				<CardActions className={cardFooterClasses}>{footer}</CardActions>
			) : null}
		</Card>
	)
}

HeaderCard.defaultProps = {
	headerColor: 'blue'
}

HeaderCard.propTypes = {
	plainCard: PropTypes.bool,
	classes: PropTypes.object.isRequired,
	headerColor: PropTypes.oneOf([
		'orange',
		'green',
		'red',
		'blue',
		'purple',
		'rose',
		'grayRed'
	]),
	cardTitle: PropTypes.node,
	cardSubtitle: PropTypes.node,
	content: PropTypes.node,
	footer: PropTypes.node,
	footerAlign: PropTypes.oneOf(['left', 'center', 'right'])
}

export default memo(withStyles(headerCardStyle)(HeaderCard))
