import React, { FC } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'
import { some } from 'lodash'

import { changePartsView } from '../../ProjectAnalysisActions'
import { ProjectAnalysisPartsView } from '../../ProjectAnalysisInterfaces'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { FeatureComponentId } from 'Services/models/Features'
import { Part } from 'Services/models/IPart'
import { Project } from 'Services/models/IProject'

import { ReactComponent as ListViewIcon } from '../../../../../assets/img/svg/list-view-icon.svg'
import { ReactComponent as ThumbnailViewIcon } from '../../../../../assets/img/svg/thumbnail-view-icon.svg'

import './SwitchView.scss'

type IProps = {
	partsView: ProjectAnalysisPartsView
	parts: Part[]
}

const SwitchView: FC<IProps> = ({ partsView, parts }) => {
	const dispatch = useDispatch()
	const isMetadata =
		parts && some(parts, ({ formatType }) => formatType === METADATA)

	const switchToThumbnailView = () => {
		dispatch(changePartsView(ProjectAnalysisPartsView.thumbnail))
	}

	const switchToListView = () => {
		dispatch(changePartsView(ProjectAnalysisPartsView.list))
	}

	if (isMetadata) {
		return <></>
	}

	return (
		<div className="switch-view--wrapper" data-qa="data-qa-switch-view-button">
			<div
				className={cx('switch-view--button', {
					selected: partsView === ProjectAnalysisPartsView.thumbnail
				})}
				data-qa="data-qa-switch-thumbnail-button"
				onClick={switchToThumbnailView}
			>
				<ThumbnailViewIcon />
			</div>
			<div
				className={cx('switch-view--button', {
					selected: partsView === ProjectAnalysisPartsView.list
				})}
				data-qa="data-qa-switch-list-view-button"
				onClick={switchToListView}
			>
				<ListViewIcon />
			</div>
		</div>
	)
}

export default WithFeatureToggleHOC(
	SwitchView,
	FeatureComponentId.SWITCH_PARTS_VIEW_BUTTON
)
