import React, { FC, memo } from 'react'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import { default as classNames } from 'classnames'

import { ActionWithPayload } from '../../../../../../../global actions/ActionModels'
import { IPostProcess } from '../../../../../../../Services/models/IPostProcess'
import { PART_ANALYSIS_ADD_POST_PROCESS_HEADER } from '../../../../../../../Services/Strings'
import { getString } from '../../../../../../../Services/Strings/StringService'
import CastorCheckbox from '../../../../../../Components/CastorCheckbox'
import CastorSwitch from '../../../../../../Components/CastorSwitch'
import InfoBox from '../../../../../../Components/InfoBox'
import Flexbox from 'Scenes/Components/FlexBox'
import { CustomFunctionStringCategory } from 'Services/models/IFunctionString'
import { ISolution } from 'Services/models/ISolution'

import './SolutionConfigureFeatures.scss'

const LabelFormControl: any = FormControlLabel
const CastorCheckboxTSX: any = CastorCheckbox

interface Props {
	allOptionalPostProcessesData: IPostProcess[]
	showPostProcesses: any
	//   showSolutionFilters: any
	configurationId: number
	renderCheckbox?: boolean
	onPostProcessesToggleChange: (
		id: number,
		postProcessToggled: IPostProcess,
		infoMessage: string,
		toggleState: boolean
	) => ActionWithPayload<any>
	solution: ISolution
}

const ControlComponent = (props: any) => {
	if (props.renderCheckbox)
		return (
			<div className="check-boxs-with-info">
				<CastorCheckboxTSX {...props} />
			</div>
		)
	return <CastorSwitch {...props} />
}

const SolutionConfigureFeaturePostProcesses: FC<Props> = ({
	allOptionalPostProcessesData,
	configurationId,
	onPostProcessesToggleChange,
	showPostProcesses,
	renderCheckbox = false,
	solution
}) => {
	const productionCostIsCustomized =
		solution.costCalculatedWithCustomFunction &&
		solution.costCalculatedWithCustomFunction[
			CustomFunctionStringCategory.productionCost
		]
	return (
		<div
			className={classNames('solution-feature-modal one-column', {
				'with-checkbox': renderCheckbox
			})}
		>
			{!renderCheckbox && (
				<div
					className={classNames('solution-feature-modal-title', {
						'with-tooltip': productionCostIsCustomized
					})}
				>
					{PART_ANALYSIS_ADD_POST_PROCESS_HEADER}
					{productionCostIsCustomized && (
						<InfoBox
							boxContact={getString('CUSTOM_FUNCTION_POST_PROCESSES_HEADER')}
							boxContactClassName="solution-feature-modal-post-processes-info"
							boxDirection="right-start"
						/>
					)}
				</div>
			)}
			{allOptionalPostProcessesData.map((process: IPostProcess, index) => {
				if (process.editDisabled) {
					return <div key={process.id} />
				}
				return (
					<Flexbox
						alignItems="center"
						key={process.id}
						className={classNames({
							disabled: showPostProcesses[process.id].disabled
						})}
					>
						<LabelFormControl
							control={
								<ControlComponent
									info={getString(process.info)}
									renderCheckbox={renderCheckbox}
									checked={showPostProcesses[process.id].toggled}
									disabled={
										showPostProcesses[process.id].disabled ||
										productionCostIsCustomized
									}
									onChange={() =>
										onPostProcessesToggleChange(
											configurationId,
											process,
											process.toastInfo,
											showPostProcesses[process.id].toggled
										)
									}
								/>
							}
							label={getString(process.labelName)}
							key={process.id}
							classes={{ root: 'switch-label-root' }}
						/>
						{
							<InfoBox
								boxContact={
									!showPostProcesses[process.id].disabled
										? getString(process.info)
										: showPostProcesses[process.id].toggled
										? getString(process.infoOn)
										: getString(process.disabledInfo)
								}
								boxContactClassName="solution-feature-modal-post-processes-info"
								boxDirection="left-start"
							/>
						}
					</Flexbox>
				)
			})}
		</div>
	)
}

export default memo(SolutionConfigureFeaturePostProcesses)
