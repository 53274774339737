export const pageStyle = (isOldChrome: boolean) => {
  return `
		html, body, div, span, applet, object, iframe,
		h1, h2, h3, h4, h5, h6, p, blockquote, pre,
		a, abbr, acronym, address, big, cite, code,
		del, dfn, em, img, ins, kbd, q, s, samp,
		small, strike, strong, sub, sup, tt, var,
		b, u, i, center,
		dl, dt, dd, ol, ul, li,
		fieldset, form, label, legend,
		table, caption, tbody, tfoot, thead, tr, th, td,
		article, aside, canvas, details, embed,
		figure, figcaption, footer, header, hgroup,
		menu, nav, output, ruby, section, summary,
		time, mark, audio, video {
		  margin: 0;
		  padding: 0;
		  border: 0;
		  font-size: 100%;
		  font: inherit;
		  vertical-align: baseline;
		}
		
		/* HTML5 display-role reset for older browsers */
		article, aside, details, figcaption, figure,
		footer, header, hgroup, menu, nav, section {
		  display: block;
		}
		html {
		    zoom: 90%;
		}
		body {
		  line-height: 1;
		}
		
		ol, ul {
		  list-style: none;
		}
		
		blockquote, q {
		  quotes: none;
		}
		
		blockquote:before, blockquote:after,
		q:before, q:after {
		  content: '';
		  content: none;
		}
		
		table {
		  border-collapse: collapse;
		  border-spacing: 0;
		}
		
		table tr td {
		  font-family: Roboto, Helvetica, Arial, sans-serif;
		}
		
		@media print {
		  table {
		     page-break-after: auto;
		  }
		  tr {
		    page-break-inside: avoid;
		    page-break-after: auto;
		  }
		  div {
		    page-break-inside: avoid;
		  }
		  td {
		    page-break-inside: avoid;
		    page-break-after: auto;
		  }
		  thead {
		    display: table-header-group;
		  }
		  tfoot {
		    display: table-footer-group;
		  }
		
		  body {
		    -webkit-print-color-adjust: exact;
		  }
		
		  @page {
		    size: A4 portrait;
		    margin: 5mm 10mm;
		  }
		
		  div.page-footer {
		    position: fixed;
		        ${isOldChrome ? ` 
			         bottom: 5mm;
			         width: 100%;
		        `: `
			         top: 460mm;
			         width: 315mm;
		         `}
		         
		    height: 50px;
		    font-size: 15px;
		    color: #000000;
		    page-break-after: always;
		  }
		
		  div.page-header {
		    position: fixed;
		        ${isOldChrome ? ` 
			         top: 0mm;
			         width: 100%;
		        `: `
			         top: 0;
			         width: 315mm;
		         `}
		    height: 50px;
		    font-size: 15px;
		    color: #000000;
		  }
		
		  .page-number:before {
		    counter-increment: page;
		    content: counter(page);
		  }
		
		  .header-space {
		    height: 220px;
		  }
		
		  .footer-space {
		    height: 60px;
		  }
		}
`
}
