import { initialPrinterState } from './constants'
import {
  GET_ADMIN_PRINTERS_SUCCESS,
  GET_ADMIN_PRINTERS_GOT_ERROR,
  GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED,
  ADMIN_PRINTER_DELETED,
  ADMIN_PRINTER_UPDATED,
  ADMIN_PRINTER_ADDED,
  ADMIN_PRINTER_SEARCH_PHRASE_CHANGED,
  ADMIN_PRINTER_INITIAL_VALUES_HAD_SET,
  NEW_ITEM_ADD_CALLED,
  NEW_ITEM_ADD_FAILED,
  MAINTENANCE_FIELDS_SELECTOR_CHANGED,
  MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
  GET_ADMIN_PRINTERS
} from '../../../../global actions/types'

const INITIAL_STATE = {
  printers: [],
  totalPrinters: 0,
  printersTableData: [],
  showNoPrintersAlert: false,
  pageNumber: 1,
  isLastPage: false,
  NumOfPrintersSeen: 0,
  showPagination: false,
  limitPrinters: 50,
  showAdminPrintersAlert: false,
  selectedEditPrinter: null,
  printerAdded: false,
  searchPhrase: '',
  selectedNewPrinter: initialPrinterState,
  addingNewItem: false,
  maintenanceMachineSelectorsValues: {},
  maintenanceMachineSelectorsValueNewPrinter: 1,
  maintenanceMachineCostFieldsSelectorValue: null,
  loading: false
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_PRINTERS:
      return { ...state, loading: true }
    case GET_ADMIN_PRINTERS_SUCCESS:
      const { data, pageNumber } = action.payload,
        { printers, totalPrinters } = data,
        NumOfPrintersSeen =
          (pageNumber - 1) * state.limitPrinters + printers.length,
        isLastPage = NumOfPrintersSeen >= totalPrinters
      const temp = createSelectorValuesObject(printers)
      return {
        ...state,
        printers,
        totalPrinters,
        printersTableData: printers.map(printer => Object.values(printer)),
        maintenanceMachineSelectorsValues: temp,
        showNoPrintersAlert: !printers.length,
        pageNumber,
        NumOfPrintersSeen,
        isLastPage,
        showPagination: !(isLastPage && pageNumber === 1),
        showAdminPrintersAlert: false,
        selectedNewPrinter: initialPrinterState,
        printerAdded: false,
        addingNewItem: false,
        loading: false,
      }
    case ADMIN_PRINTER_UPDATED:
      const updatedPrinter = action.payload
      return {
        ...state,
        printers: state.printers.map(printer =>
          printer.id === updatedPrinter.id ? updatedPrinter : printer
        )
      }
    case GET_ADMIN_PRINTERS_GOT_ERROR:
      return {
        ...state,
        showAdminPrintersAlert: true,
        loading: false,
      }
    case GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED:
      return {
        ...state,
        selectedEditPrinter: action.payload,
        printerAdded: false
      }
    case ADMIN_PRINTER_DELETED:
      const id = action.payload
      return {
        ...state,
        printers: state.printers.filter(printer => printer.id !== id)
      }
    case ADMIN_PRINTER_ADDED:
      const newPrinter = action.payload
      return {
        ...state,
        printers: [...state.printers, newPrinter],
        printerAdded: true,
        addingNewItem: false
      }
    case ADMIN_PRINTER_SEARCH_PHRASE_CHANGED:
      const searchPhrase = action.payload
      return {
        ...state,
        searchPhrase
      }
    case ADMIN_PRINTER_INITIAL_VALUES_HAD_SET:
      return {
        ...state,
        selectedNewPrinter: action.payload
      }
    case NEW_ITEM_ADD_CALLED:
      return {
        ...state,
        addingNewItem: true
      }
    case NEW_ITEM_ADD_FAILED:
      return {
        ...state,
        addingNewItem: false
      }
    case MAINTENANCE_FIELDS_SELECTOR_CHANGED: {
      const { value, printerId } = action.payload
      return {
        ...state,
        maintenanceMachineSelectorsValues: {
          ...state.maintenanceMachineSelectorsValues,
          [printerId]: { value }
        }
      }
    }
    case MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED:
      const { value } = action.payload
      return {
        ...state,
        maintenanceMachineSelectorsValueNewPrinter: value
      }
    default:
      return state
  }
}

const createSelectorValuesObject = printers => {
  return printers.reduce((acc, printer) => {
    if (printer.maintenanceAsPercentOfMachineCostPerYear) {
      return { ...acc, ...{ [printer.id]: { value: 1 } } }
    } else {
      return { ...acc, ...{ [printer.id]: { value: 2 } } }
    }
  }, {})
}
