import { IFeature } from 'Services/models/IFeature'

export enum ReanalyzingStatus {
	complete = 'complete',
	pending = 'pending',
	inProgress = 'inProgress'
}

//TODO: fix any in future
export interface IProps {
	alternativeComponent: any
	classes: any
	color: string
	dateTime: number
	drawToggledUser: Function
	error: null | boolean
	features: IFeature[]
	handleDrawerToggle: Function
	href: string
	isRouteLegalFunc: Function
	isTrial: boolean
	loaderCounter: number
	loading: boolean
	location: Location
	loggedIn: boolean
	logo: string
	match: Math
	miniActive: boolean
	mobileOpen: boolean
	open: boolean
	parentProps: any
	projects: {
		projects: any
		features: IFeature[]
		maxAllowedUploadProjects: string
	}
	roles: number[]
	routes: Route[]
	setupCalled: boolean
	setupUserHome: Function
	showTrialBanner: boolean
	stateUnknown: boolean
	staticContext?: any
	type: string
	image: any
	logoText?: string
	bgColor: string
	rtlActive?: boolean
	personalizedLightUser?: boolean
	clickableLogo?: boolean
	onboardingCompleted?: boolean
}

export interface IState {
	miniActive: boolean
	openAvatar: boolean
	openComponents: boolean
	openForms: boolean
	openMaps: boolean
	openPages: boolean
	openProjects: boolean
	openTables: boolean

	[x: string]: boolean
}

export interface Route {
	changeNameWith?: string
	name: string
	component?: ChildNode
	exact?: boolean
	isInHome?: boolean
	path: string
	sidebar?: boolean
	collapse?: boolean
	state?: any
	redirect?: string
	icon?: any
	mini?: string
	views?: Route[]
	reanalyzingStatus?: string
	hidden?: boolean
	folderId?: string
	id?: string
}