export const enum CostParameters {
	puAnnualKgCO2 = 'puAnnualKgCO2',
	puYearsCO2 = 'puYearsCO2',
	disposeFactor = 'disposeFactor'
}

export const enum CostAnalysisEnum {
	totalMaterial = 'TOTAL_MATERIAL',
	totalPartMaterial = 'TOTAL_PART_MATERIAL',
	totalSupportMaterial = 'TOTAL_SUPPORT_MATERIAL',
	unusedMaterialWaste = 'UNUSED_MATERIAL_WASTE',
	machineUsage = 'MACHINE_USAGE',
	printTime = 'PRINT_TIME',
	totalSharedMachineTime = 'TOTAL_SHARED_MACHINE_TIME',
	buildConsumable = 'BUILD_CONSUMABLE_COST',
	operatingCost = 'OPERATING_COST',
	perBuildConsumables = 'PER_BUILD_CONSUMABLES',
	labor = 'LABOR',
	laborHoursBuild = 'LABOR_HOURS_BUILD_EXCHANGE',
	laborHoursDuringBuild = 'LABOR_HOURS_DURING_PURE_BUILD',
	postProcessCost = 'POST_PROCESS_COST',
	optionalPostProcess = 'OPTIONAL_POST_PROCESS_REDUCE_FUNC',
	totalOrderFees = 'TOTAL_ORDER_FEES_COST',
	buildPrep = 'TOTAL_BUILD_PREP_COST'
}
