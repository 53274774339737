import { Divider } from '@material-ui/core'
import React, { FC, memo } from 'react'
import { currencyFormatter } from 'Services/Utils/numberService'
import { pieBenefitsManufacturing } from '../../../Services/colors'
import { IResultsSelectionOption } from '../../../Services/models/ResultsSelectionOptions'
import { getString } from '../../../Services/Strings/StringService'
import Loader from '../../Loader/Loader'
import CalculationAccordingSelect from '../CalculationAccordingSelect'
import CastorPartResultIndex from '../CastorPartResultIndex/CastorPartResultIndex'
import PieChart, { IChartSeriesData } from '../PieChart'

import './PrintingSavingStats.scss'

interface IProps {
  onSelectChange: Function
  selectValue: IResultsSelectionOption
  printingSavingChartData: IChartSeriesData[]
  load: boolean
  printingSavingIndexes: any[]
  userCurrencySign: string
  printingSavingTotalCostBenefit: number
}

const DividerTSX: any = Divider

const PrintingSavingStats: FC<IProps> = ({
  onSelectChange,
  selectValue,
  printingSavingChartData,
  load,
  printingSavingIndexes,
  userCurrencySign,
  printingSavingTotalCostBenefit
}) => {
  return (
    <div className="printing-saving">
      <Loader load={load} message={''} />
      <CalculationAccordingSelect
        explanationText={getString('RESULTS_SELECTION_BENEFITS_EXPLANATION')}
        onSelectChange={onSelectChange}
        selectValue={selectValue.type}
      />
      <div className="printing-saving--content">
        {printingSavingChartData && (
          <div className="printing-saving--content--pie" data-qa="data-qa-printing-saving-stats">
            <PieChart
              chartData={printingSavingChartData}
              maxWidth={270}
              yPosition={220}
              active
              padAngle={0}
              labelStyle={{ fill: pieBenefitsManufacturing, fontSize: '40px' }}
            />
            <div className="printing-saving--content--data-saving">
              <div className="printing-saving--content--title" data-qa="data-qa-total-printing-savings">
                {`${getString(
                  'MY_PANEL_TOTAL_SAVINGS'
                )} ${userCurrencySign}${currencyFormatter(
                  printingSavingTotalCostBenefit,
                  '0,0'
                )}`}
                <DividerTSX className="printing-saving--content--divider" />
              </div>
              <CastorPartResultIndex indexes={printingSavingIndexes} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(PrintingSavingStats)
