import { ChangeEvent, FC } from 'react'
import { Select, MenuItem } from '@material-ui/core'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'
import '../CustomizeCO2Calculation.scss'

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem

interface IProps {
	printersFullData: any
	selectedPrinterName: any
	selectedPrinterCompany: any
	setSelectedPrinterId: Function
	setSelectedPrinterCompany: Function
	setSelectedPrinterName: Function
}

const CO2ManufacturingPrinterSelector: FC<IProps> = ({
	printersFullData,
	selectedPrinterName,
	selectedPrinterCompany,
	setSelectedPrinterCompany,
	setSelectedPrinterName,
	setSelectedPrinterId
}) => {
	const onChangePrinterCompany = (value: any) => {
		setSelectedPrinterCompany(value)
		setSelectedPrinterName('0')
	}

	const onChangePrinterName = (value: any) => {
		setSelectedPrinterName(value)

		const companyIndex = printersFullData
			.map((object: { companyName: any }) => object.companyName)
			.indexOf(selectedPrinterCompany)

		const nameIndex = printersFullData[companyIndex].printers
			.map((object: { name: any }) => object.name)
			.indexOf(value)
		const materialsList = printersFullData[companyIndex].printers[nameIndex]
		setSelectedPrinterId(materialsList.printerId)
	}

	const renderPrinterName = (selectedPrinterCompany: any) => {
		const index = printersFullData
			.map((object: { companyName: any }) => object.companyName)
			.indexOf(selectedPrinterCompany)
		if (index !== -1) {
			return Object.keys(printersFullData[index].printers)
				.sort((a: string, b: string) =>
					printersFullData[index].printers[a].name?.localeCompare(
						printersFullData[index].printers[b].name
					)
				)

				.map(data => {
					return (
						<MenuItemTsx
							key={data}
							value={printersFullData[index].printers[data].name}
						>
							{printersFullData[index].printers[data].name}
						</MenuItemTsx>
					)
				})
		}
		return <></>
	}

	const returnSelectTsx = () => {
		return (
			<Flexbox className="printers-selector">
				<SelectTsx
					className="select"
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangePrinterCompany(e.target.value)
					}
					value={selectedPrinterCompany}
				>
					<MenuItemTsx value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_COMPANY_SELECTOR_NAME')}
					</MenuItemTsx>
					{Object.keys(printersFullData)
						.sort((a: any, b: any) =>
							printersFullData[a].companyName?.localeCompare(
								printersFullData[b].companyName
							)
						)

						.map(company => {
							return (
								<MenuItemTsx
									key={company}
									value={printersFullData[company].companyName}
								>
									{printersFullData[company].companyName}
								</MenuItemTsx>
							)
						})}
				</SelectTsx>
				<SelectTsx
					className="select"
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangePrinterName(e.target.value)
					}
					value={selectedPrinterName}
				>
					<MenuItemTsx value="0" disabled>
						{getString('IN_HOUSE_PRINTERS_NAME_SELECTOR_NAME')}
					</MenuItemTsx>
					{renderPrinterName(selectedPrinterCompany)}
				</SelectTsx>
			</Flexbox>
		)
	}

	return <div>{returnSelectTsx()}</div>
}

export default CO2ManufacturingPrinterSelector
