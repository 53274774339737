import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { map } from 'lodash'

import * as AdminPrinterMaterialsActions from './AdminPrinterMaterialsActions'
import {
	adminPrinterMaterialsEditFormRoute,
	adminPrinterMaterialsNewFormRoute,
	adminPrinterMaterialsRoute
} from '../../../../Services/routeFuncs'
import {
	ADMIN_PRINTER_MATERIALS_PAGE_HEADER,
	ADMIN_PRINTER_MATERIALS_SUB_TITLE,
	MATERIAL_TABLE_TEXTS,
	PRINTER_MATERIAL_PARAMS
} from '../../../../Services/Strings'
import {
	createDeviationText,
	createSingleDeviation
} from '../../../Components/DataTable/DataTableTextWithXYZ'
import AdminDataTable from '../AdminDataTable/AdminDataTable'

import '../adminHome.scss'

const printerMaterialParams = PRINTER_MATERIAL_PARAMS

class AdminPrinterMaterials extends Component {
	componentWillMount() {
		this.setupAdminPrinterMaterialsPage()
	}

	componentDidUpdate = prevProps => {
		this.setupAdminPrinterMaterialsPage(prevProps)
	}

	componentWillUnmount() {
		const { onAdminPrinterMaterialsUnmounted } = this.props
		onAdminPrinterMaterialsUnmounted()
	}

	setupAdminPrinterMaterialsPage = prevProps => {
		const {
				match,
				pageNumber,
				setupAdminPrinterMaterialsPage,
				printerMaterials,
				limitPrinterMaterials,
				searchPhrase
			} = this.props,
			urlPage = parseInt(match.params['page']) || 1
		if (
			urlPage !== pageNumber ||
			(prevProps && searchPhrase !== prevProps.searchPhrase) ||
			!prevProps
		) {
			setupAdminPrinterMaterialsPage(
				searchPhrase,
				urlPage,
				limitPrinterMaterials
			)
		}
	}

	renderPropertiesField = printerMaterial => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{MATERIAL_TABLE_TEXTS.TYPE}: {printerMaterial.type}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.CATEGORY}: {printerMaterial.Category}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.DENCITY}: {printerMaterial.density}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.TECHNOLOGY}: {printerMaterial.printerTechnology}
				</div>
			</div>
		)
	}

	getXYZ = data => {
		const deviationText = data ? createDeviationText(data) : {}
		return map(deviationText, deviation =>
			createSingleDeviation(deviation.name, deviation.value)
		)
	}

	renderStrengthField = printerMaterial => {
		return (
			<div className="admin-multiple-line-field">
				<div>
					{MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}:{' '}
					{this.getXYZ(printerMaterial.yieldStrengthMPaExt)}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}:{' '}
					{this.getXYZ(printerMaterial.youngsModulusExt)}
				</div>
				<div>
					{MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH}:{' '}
					{this.getXYZ(printerMaterial.ultimateTensileStrengthExt)}
				</div>
			</div>
		)
	}

	renderDescriptionField = printerMaterial => {
		return (
			<div
				className="admin-multiple-line-elypsis"
				title={printerMaterial.description}
			>
				{printerMaterial.description}
			</div>
		)
	}

	renderPrinterMaterialsTableData = () => {
		const { printerMaterials } = this.props
		return printerMaterials.map(printerMaterial => {
			return [
				printerMaterial.name,
				printerMaterial.printers,
				this.renderPropertiesField(printerMaterial),
				this.renderStrengthField(printerMaterial),
				this.renderDescriptionField(printerMaterial),
				printerMaterial.costPerCubicCM,
				printerMaterial.id
			]
		})
	}

	deleteRow = id => {
		this.props.deletePrinterMaterial(id)
	}

	setSearchPhrase = searchPhrase => {
		this.props.setSearchPhrase(searchPhrase)
	}

	render() {
		const {
			showNoPrinterMaterialsAlert,
			showPagination,
			pageNumber,
			isLastPage,
			showAdminPrinterMaterialsAlert,
			totalPrinterMaterials,
			limitPrinterMaterials,
			searchPhrase,
			loading
		} = this.props
		return (
			<AdminDataTable
				loading={loading}
				formTitle={ADMIN_PRINTER_MATERIALS_PAGE_HEADER}
				formSubTitle={ADMIN_PRINTER_MATERIALS_SUB_TITLE}
				tableHeadParams={printerMaterialParams}
				tableData={this.renderPrinterMaterialsTableData()}
				linkToRouteFunction={adminPrinterMaterialsRoute}
				showNoDataAlert={showNoPrinterMaterialsAlert}
				showPagination={showPagination}
				pageNumber={pageNumber}
				isLastPage={isLastPage}
				showAdminErrorAlert={showAdminPrinterMaterialsAlert}
				totalResults={totalPrinterMaterials}
				limitResults={limitPrinterMaterials}
				linkToEdit={adminPrinterMaterialsEditFormRoute}
				linkToNew={adminPrinterMaterialsNewFormRoute}
				deleteRow={this.deleteRow}
				setSearchPhrase={this.setSearchPhrase}
				searchPhrase={searchPhrase}
			/>
		)
	}
}

const mapStateToProps = ({ AdminPrinterMaterialsReducer }) => {
	const {
		printerMaterials,
		totalPrinterMaterials,
		printerMaterialsTableHead,
		printerMaterialsTableData,
		showNoPrinterMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterMaterials,
		showAdminPrinterMaterialsAlert,
		searchPhrase,
		loading
	} = AdminPrinterMaterialsReducer
	return {
		printerMaterials,
		totalPrinterMaterials,
		printerMaterialsTableHead,
		printerMaterialsTableData,
		showNoPrinterMaterialsAlert,
		pageNumber,
		isLastPage,
		showPagination,
		limitPrinterMaterials,
		showAdminPrinterMaterialsAlert,
		searchPhrase,
		loading
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AdminPrinterMaterials)
