import React, { FC, memo, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Fade } from '@material-ui/core'
import cx from 'classnames'

import Button from 'Scenes/Components/thirdParty/CreativeTim/components/CustomButtons/Button'
import { EXTERNAL, OAUTH2 } from 'Services/Constants/RoutesConstants'
import { uploadProjectRoute } from 'Services/routeFuncs'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as ErrorImage } from 'assets/img/svg/error-image.svg'

import './index.scss'

const disableErrorRoutes = [
	'/',
	'/home/castorLight',
	'/authorize',
	'/api/integration-consumer/provider-auth',
	OAUTH2,
	EXTERNAL
]

const ErrorPage: FC = () => {
	const [showErrorPage, setShowErrorPage] = useState(false)
	const location = useLocation()
	const history = useHistory()
	const isDisableErrorPage = disableErrorRoutes.includes(location.pathname)

	// need to wait a bit to show an animation
	useEffect(() => {
		const showErrorPage = setTimeout(() => {
			setShowErrorPage(true)
		}, 600)

		return () => {
			clearTimeout(showErrorPage)
			setShowErrorPage(false)
		}
	}, [])

	if (isDisableErrorPage) return <></>

	return (
		<Fade timeout={600} in={showErrorPage}>
			<div className={cx('page-not-exist', { show: showErrorPage })}>
				<ErrorImage />
				<div className="text">{getString('PAGE_IS_NOT_EXIST_OR_ACCESS')}</div>
				<br />
				<Button
					color="primary"
					onClick={() => history.push(uploadProjectRoute())}
					className="page-not-exist__button"
					type="button"
				>
					{getString('LIGHT_USER_WARNING_BUTTON_TEXT')}
				</Button>
			</div>
		</Fade>
	)
}

export default memo(ErrorPage)
