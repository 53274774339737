import {
  setupAdminPage,
  selectItemToEdit,
  updateItem,
  deleteItem,
  createNewItem
} from '../adminActionsService'
import {
  getTechParametersAdmin,
  updatePrinterTechParametersAdmin
} from '../../../../Services/Network'
import {
  GET_ADMIN_3D_TECH_PARAMETERS,
  GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
  GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS,
  GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
  ADMIN_3D_TECH_PARAMETERS_UPDATED
} from '../../../../global actions/types'
import { getString } from '../../../../Services/Strings/StringService'

export const setupAdminPrintingTechnologyPage = (
  searchPhrase: any,
  pageNumber: any,
  limitPrinters: any
) => {
  return (dispatch: any) => {
    setupAdminPage(
      dispatch,
      getTechParametersAdmin,
      [searchPhrase, pageNumber, limitPrinters],
      GET_ADMIN_3D_TECH_PARAMETERS_SUCCESS,
      GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
      pageNumber
    )
    dispatch({ type: GET_ADMIN_3D_TECH_PARAMETERS })
  }
}

export const selectPrinterTechnologyToEdit = (
  printerTechName: number,
  printersTechnologies: Array<any>
) => {
  return (dispatch: any) => {
    selectItemToEdit(
      dispatch,
      printerTechName,
      printersTechnologies,
      getTechParametersAdmin,
      [printerTechName],
      GET_ADMIN_3D_TECH_PARAMETERS_EDIT_SELECTED,
      GET_ADMIN_3D_TECH_PARAMETERS_GOT_ERROR,
      'printer',
      'name'
    )
  }
}

export const updatePrinterTechnologyParameters = (data: any) => {
  return (dispatch: any) => {
    updateItem(
      dispatch,
      updatePrinterTechParametersAdmin,
      data,
      ADMIN_3D_TECH_PARAMETERS_UPDATED,
      'printerTechParametersData',
      getString('ADMIN_PRINTER_TECHNOLOGY_UPDATE_SUCCESS'),
      getString('ADMIN_PRINTER_TECHNOLOGY_UPDATE_ERROR')
    )
  }
}
