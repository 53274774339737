import { FC } from 'react'
import cx from 'classnames'
import Flexbox from 'Scenes/Components/FlexBox'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { Feature, FeatureComponentId } from 'Services/models/Features'
import { materialRadioChanged, removeFiles } from '../../UploadProjectActions'
import { getString } from 'Services/Strings/StringService'
import CastorRadio from 'Scenes/Components/CastorRadio'
import {
	drawingFiles,
	BOMRadioValue,
	chosenMaterialRadioValue
} from 'Services/Constants'
import { TAB_3d_INDEX } from '../../constants'
import { FormatType } from 'Services/models/IPart'
import UploadBOM from './UploadBOM'
import UploadMaterial from './UploadMaterial'

const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel

interface UploadMaterialsProps {
	is2dUpload: boolean | null
	uploadTabIndex: any
	selectedType?: string | null
	isDrawingFeatureOn?: boolean
}

const UploadMaterialsParameters: FC<UploadMaterialsProps> = ({
	is2dUpload,
	isDrawingFeatureOn,
	uploadTabIndex,
	selectedType
}) => {
	const { radioButtonSelected } = useSelector(
		(state: RootStateOrAny) => state.uploadProject
	)
	const user = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()
	const isCombine3D2D = Feature.isFeatureOn(FeatureComponentId.COMBINE_3D_2D)
	const showBomFileOption = Feature.isFeatureOn(
		FeatureComponentId.UPLOAD_BOM_FILE
	)

	const removeFilesByFormatType = (radioButtonId: any) => {
		if (uploadTabIndex === TAB_3d_INDEX) {
			switch (radioButtonId) {
				case BOMRadioValue:
					dispatch(removeFiles(FormatType.threeD))
					break
				case chosenMaterialRadioValue:
					dispatch(removeFiles(FormatType.threeD))
					break
				case drawingFiles:
					dispatch(removeFiles(FormatType.threeDByPDF))
					break
			}
		}
	}

	const materialRadioButtonChanged = (event: any) => {
		const isMachining = user?.userCustomizationSettings?.machining
		dispatch(materialRadioChanged(event.target.value, isMachining))
		removeFilesByFormatType(event.target.value)
	}

	const isSingleMaterial = Feature.isFeatureOn(
		FeatureComponentId.MATERIAL_FROM_FILES
	)
	const isSingleMaterialDisabled = !Feature.isFeatureActive(
		FeatureComponentId.MATERIAL_FROM_FILES
	)

	return (
		<Flexbox
			className="upload-form"
			alignContent="flex-start"
			flexDirection="row"
		>
			<div className="">
				<RadioGroupTSX
					id="upload-project-materials"
					className={cx('new-upload-project__materials', {
						disabled: !selectedType
					})}
					row={true}
					aria-label="select materials"
					name="select materials"
					value={radioButtonSelected}
					onChange={(e: any) => materialRadioButtonChanged(e)}
					color="rose"
				>
					{is2dUpload ? (
						<FormControlLabelTSX
							value={drawingFiles}
							control={
								<CastorRadio
									inputProps={{ 'data-qa': `data-qa-radio-2d-drawing` }}
									className="upload-form-radio"
								/>
							}
							label={
								<div className="upload-form-radio-bom">
									<p
										className={cx({
											disabledText: radioButtonSelected !== drawingFiles,
											bomUploadInput: radioButtonSelected === drawingFiles
										})}
									>
										{getString('USE_MATERIALS_FROM_DRAWING')}
									</p>
								</div>
							}
							className="upload-form-radioGroup"
						/>
					) : showBomFileOption ? (
						<FormControlLabelTSX
							value={BOMRadioValue}
							control={
								<CastorRadio
									className="upload-form-radio"
									inputProps={{ 'data-qa': `data-qa-radio-3d-bom` }}
								/>
							}
							label={
								<UploadBOM disabled={radioButtonSelected !== BOMRadioValue} />
							}
							className={'upload-form-radioGroup'}
						/>
					) : (
						<div />
					)}
					<FormControlLabelTSX
						value={chosenMaterialRadioValue}
						control={
							<CastorRadio
								className="upload-form-radio"
								inputProps={{ 'data-qa': `data-qa-radio-material` }}
							/>
						}
						label={
							<UploadMaterial
								disabled={radioButtonSelected !== chosenMaterialRadioValue}
							/>
						}
						className="upload-form-radioGroup"
					/>
					{isDrawingFeatureOn &&
					!is2dUpload &&
					isCombine3D2D &&
					isSingleMaterial ? (
						<FormControlLabelTSX
							disabled={isSingleMaterialDisabled}
							value={drawingFiles}
							control={
								<CastorRadio
									className="upload-form-radio"
									inputProps={{ 'data-qa': `data-qa-radio-3d-drawing` }}
								/>
							}
							label={
								<div className="upload-form-radio-bom">
									<p
										className={cx({
											disabledText: radioButtonSelected !== drawingFiles,
											bomUploadInput: radioButtonSelected === drawingFiles
										})}
									>
										{getString('USE_MATERIALS_FROM_DRAWING')}
									</p>
								</div>
							}
							className="upload-form-radioGroup"
						/>
					) : (
						<div />
					)}
				</RadioGroupTSX>
			</div>
		</Flexbox>
	)
}

export default UploadMaterialsParameters
