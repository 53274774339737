import { CloudUpload, Folder, ViewQuilt } from '@material-ui/icons'

import AllWeightReductionsPartsView from '../Scenes/Components/WeightReduction/AllWeightReductionsPartsView'
import CustomizeCO2EmissionsSelector from '../Scenes/Home/Customize/CustomizeCO2Calculation/CustomizeCO2EmissionsSelector'
import CustomizeCostAndLeadSettings from '../Scenes/Home/Customize/CustomizeCostAndLeadSettings/CustomizeCostAndLeadSettings'
import CustomizeForm from '../Scenes/Home/Customize/CustomizeForm'
import CustomizeInHousePrinters from '../Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrinters'
import CustomizePartFiltering from '../Scenes/Home/Customize/CustomizePartFiltering/CustomizePartFiltering'
import CustomizeRecalculateProjects from '../Scenes/Home/Customize/CustomizeRecalculateProjects/index'
import CustomizeSolutionFilters from '../Scenes/Home/Customize/CustomizeSolutionFilters/index'
import CustomizeUserMaterials from '../Scenes/Home/Customize/CustomizeUserMaterials/CustomizeUserMaterials'
import NewUserMaterial from '../Scenes/Home/Customize/CustomizeUserMaterials/NewUserMaterial/NewUserMaterial'
import NewPartConfiguration from '../Scenes/Home/NewPartAnalysis/NewPartConfiguration/NewPartConfiguration'
import PartAnalysisView from '../Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisView'
import UploadProjectForm from '../Scenes/Home/NewUploadProject/index'
import RequestPrintOfferForm from '../Scenes/Home/PartAnalysis/RequestPrintOffer/RequestPrintOfferForm'
import AllClustersView from '../Scenes/Home/PrintableClusters/AllClustersView'
import ClusterOverview from '../Scenes/Home/PrintableClusters/PrintableClustersOverview'
import Profile from '../Scenes/Home/Profile/Profile'
import ProjectPage from '../Scenes/Home/ProjectPage'
import UploadScript from '../Scenes/Home/uploadScript/UploadScript'
import UserPanel from '../Scenes/Home/UserPanel'
import UserSettings from '../Scenes/Home/UserSettings/UserSettings'
import {
	CLUSTER_ANALYSIS_ROUTE,
	CLUSTER_OVERVIEW_ROUTE,
	CUSTOMIZE_CO2_CALCULATIONS_ROUTE,
	CUSTOMIZE_COST_AND_LEAD_ROUTE,
	CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE,
	CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE,
	CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE,
	CUSTOMIZE_MATERIAL_ROUTE,
	CUSTOMIZE_NEW_MATERIAL_ROUTE,
	CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE,
	CUSTOMIZE_ROUTE,
	CUSTOMIZE_SOLUTION_FILTER_ROUTE,
	CUSTOMIZE_TAGS_ROUTE,
	ONBOARDING_WIZARD_ROUTE,
	PROJECT_NOT_UPLOADED,
	PROJECTS_ROUTE,
	SEND_TO_SERVICE_BUREAU_ROUTE,
	UPLOAD_ROUTE,
	UPLOAD_SCRIPT_ROUTE,
	USER_HOME_ROUTE,
	USER_PANEL_ROUTE,
	USER_PROFILE_ROUTE,
	USER_SETTINGS
} from '../Services/Constants/RoutesConstants'
import {
	allClustersView,
	allWeightReductionView,
	partConfigurationRoute,
	partConfigurationsAfterFeaRoute,
	partConfigurationsRoute,
	partNewConfigurationsRoute,
	partPropertiesRoute,
	projectAfterFeaRoute,
	projectRoute
} from '../Services/routeFuncs'
import {
	NAV_TITLE_ANALYSIS_CLUSTER,
	NAV_TITLE_HOME,
	NAV_TITLE_MY_PROJECTS,
	NAV_TITLE_PROJECT_UPLOAD,
	NAV_TITLE_PROJECTS,
	ONBOARDING_WIZARD_PAGE_NAME
} from '../Services/Strings'
import { getString } from '../Services/Strings/StringService'
import CustomizeCostingFunctionEditor from 'Scenes/Home/Customize/CustomizeCostingFunctionEditor/CustomizeCostingFunctionEditor'
import CustomizeMaterialNamesMapping from 'Scenes/Home/Customize/CustomizeMaterialNamesMapping/CustomizeMaterialNamesMapping'
import OnboardingWizard from 'Scenes/Home/OnboardingWizard'
import PartProperties from 'Scenes/Home/PartProperties/PartProperties'
import ProjectNotUploaded from 'Scenes/Home/ProjectNotUploaded/ProjectNotUploaded'

const homePath = suffix => {
	return USER_HOME_ROUTE + suffix
}

const userHomeRoutes = [
	{
		path: '/',
		name: NAV_TITLE_HOME,
		component: UploadProjectForm
	},
	{
		path: homePath(PROJECTS_ROUTE),
		name: NAV_TITLE_PROJECTS
	},
	{
		path: homePath(UPLOAD_ROUTE),
		name: NAV_TITLE_PROJECT_UPLOAD,
		icon: CloudUpload,
		component: UploadProjectForm,
		isInHome: true,
		sidebar: true,
		removeFromCrumbs: true
	},
	{
		path: homePath(UPLOAD_SCRIPT_ROUTE),
		name: NAV_TITLE_PROJECT_UPLOAD,
		component: UploadScript,
		isInHome: true,
		sidebar: false,
		removeFromCrumbs: true
	},
	{
		path: homePath(USER_PANEL_ROUTE),
		name: getString('MY_PANEL'),
		icon: ViewQuilt,
		component: UserPanel,
		isInHome: true,
		sidebar: true,
		new: true
	},
	{
		path: homePath(USER_PANEL_ROUTE + '/:userId'),
		name: getString('MY_PANEL'),
		component: UserPanel,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_ROUTE),
		name: getString('NAV_TITLE_CUSTOMIZE_USER'),
		component: CustomizeForm,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(USER_SETTINGS),
		name: getString('NAV_TITLE_CUSTOMIZE_USER'),
		component: UserSettings,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_TAGS_ROUTE),
		name: getString('CUSTOMIZE_OFF_THE_SHELF_TITLE'),
		component: CustomizePartFiltering,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_COST_AND_LEAD_ROUTE),
		name: getString('CUSTOMIZE_COST_AND_LEAD_TITLE'),
		component: CustomizeCostAndLeadSettings,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_CO2_CALCULATIONS_ROUTE),
		name: getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE'),
		component: CustomizeCO2EmissionsSelector,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_SOLUTION_FILTER_ROUTE),
		name: 'Customize Solution Filtering',
		component: CustomizeSolutionFilters,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_MATERIAL_NAMES_MAPPING_ROUTE),
		name: getString('CUSTOMIZE_MATERIAL_NAMES_MAPPING_TITLE'),
		component: CustomizeMaterialNamesMapping,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_COSTING_FUNCTION_EDITOR_ROUTE),
		name: getString('COSTING_FUNCTION_EDITOR_TITLE'),
		component: CustomizeCostingFunctionEditor,
		isInHome: true
	},
	{
		path: homePath(CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE),
		name: getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE'),
		component: CustomizeInHousePrinters,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_IN_HOUSE_PRINTERS_ROUTE + '/:userId'),
		name: getString('CUSTOMIZE_IN_HOUSE_PRINTERS_TITLE'),
		component: CustomizeInHousePrinters,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_RECALCULATE_PROJECTS_ROUTE),
		name: getString('CUSTOMIZE_RECALCULATE_PROJECTS_TITLE'),
		component: CustomizeRecalculateProjects,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_MATERIAL_ROUTE),
		name: getString('CUSTOMIZE_USER_MATERIAL_TITLE'),
		component: CustomizeUserMaterials,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_MATERIAL_ROUTE + '/:userId'),
		name: getString('CUSTOMIZE_USER_MATERIAL_TITLE'),
		component: CustomizeUserMaterials,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_NEW_MATERIAL_ROUTE),
		name: 'Customize new user material',
		component: NewUserMaterial,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(CUSTOMIZE_NEW_MATERIAL_ROUTE + '/:userId'),
		name: 'Customize new user material',
		component: NewUserMaterial,
		isInHome: true,
		exact: true
	},
	{
		path: homePath(USER_PROFILE_ROUTE),
		name: 'User Profile',
		component: Profile,
		isInHome: true
	},
	{
		collapse: true,
		path: projectRoute(),
		name: NAV_TITLE_MY_PROJECTS,
		state: 'openProjects',
		component: ProjectPage,
		icon: Folder,
		isInHome: true,
		views: [],
		sidebar: true,
		exact: true,
		changeNameWith: 'projectName'
	},
	{
		path: projectAfterFeaRoute(),
		name: 'Project analysis after fea',
		component: ProjectPage,
		isInHome: true,
		sidebar: false,
		exact: true,
		removeFromCrumbs: true
	},
	{
		path: allClustersView(),
		name: 'All Clusters',
		component: AllClustersView,
		isInHome: true,
		exact: true
	},
	{
		path: allWeightReductionView(),
		name: 'All Weight Reduction',
		component: AllWeightReductionsPartsView,
		isInHome: true,
		exact: true
	},
	// {
	//   path: `${projectRoute()}${PART_ROUTE}`,
	//   name: 'Part Analysis'
	// },
	{
		path: partConfigurationRoute(),
		component: PartAnalysisView,
		isInHome: true,
		sidebar: false,
		changeNameWith: 'configurationName',
		exact: false
	},
	{
		path: partConfigurationsRoute(),
		component: PartAnalysisView,
		isInHome: true,
		sidebar: false,
		changeNameWith: 'partName',
		exact: true
	},
	{
		path: partNewConfigurationsRoute(),
		component: NewPartConfiguration,
		isInHome: true,
		sidebar: false,
		removeFromCrumbs: true,
		exact: true
	},
	{
		path: partPropertiesRoute(),
		component: PartProperties,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: partConfigurationsAfterFeaRoute(),
		name: 'Part analysis after fea',
		component: PartAnalysisView,
		isInHome: true,
		sidebar: false,
		exact: true,
		removeFromCrumbs: true
	},
	{
		path: homePath(CLUSTER_OVERVIEW_ROUTE + '/:projectId'),
		name: 'Printable cluster overview',
		component: ClusterOverview,
		isInHome: true,
		sidebar: false
	},
	{
		path: homePath(CLUSTER_ANALYSIS_ROUTE + '/:projectId' + '/:clusterId'),
		name: NAV_TITLE_ANALYSIS_CLUSTER,
		component: PartAnalysisView,
		isInHome: true,
		sidebar: false,
		views: []
	},
	{
		path:
			SEND_TO_SERVICE_BUREAU_ROUTE +
			'/:projectId/:partId/:quantity/:printerName/:materialName/:configurationId',
		name: 'Get Quote',
		component: RequestPrintOfferForm,
		isInHome: true,
		sidebar: false
	},
	{
		path: homePath(PROJECT_NOT_UPLOADED),
		name: 'Project Not Uploaded',
		component: ProjectNotUploaded,
		isInHome: true,
		sidebar: false,
		exact: true
	},
	{
		path: homePath(ONBOARDING_WIZARD_ROUTE),
		name: ONBOARDING_WIZARD_PAGE_NAME,
		component: OnboardingWizard,
		isInHome: true,
		sidebar: false,
		exact: true
	}
]

export default userHomeRoutes
