export interface IDataTableField {
	text: string | number
	type: DataTableFieldType
	data?: any
	order?: number
	truncated?: boolean
	nestedDataTableFields?: IDataTableField[]
}

export interface IDataTableFields {
	type: DataTableFieldType
	text?: string | number
	dataOrDeviation?: null | number | object
	iconName?: string
	onClick?: Function
	className?: string
	iconClassName?: string
	extraData?: Record<string, any>
	iconOnTheRight?: boolean
	title?: string
	intro?: string
	hoverText?: string
	order?: number
	truncated?: boolean
	nestedDataTableFields?: IDataTableField[]
	isHover?: boolean | undefined
	description?: string
	descriptionClassName?: string
	score?: number | null
	withAdditiveMind?: boolean
}

export enum DataTableFieldType {
	Text,
	TextWithStandardDeviation,
	TextWithXYZDeviation,
	TextWithIcon,
	Button,
	Buttons,
	ButtonWithIcon,
	ButtonWithIconAndPopup,
	ButtonWithLoader,
	ButtonWithPopup,
	TransparentButtonWithLoader,
	Header,
	HeaderWithDescription,
	OpacityText,
	WallThicknessButton,
	LongText,
	RadioButtonWithColor,
	HeaderIcon,
	IconInfo,
	Detailed,
	DataTableInputText,
	DataTableInputNumber,
	DataTableDropdown,
	TextWithIconButton,
	TextWithIconLink,
	TextWithIconWarn,
	DataTableTextWithDescription
}
