import React, { ChangeEvent, FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import cx from 'classnames'

import { changeAdvancedFilter } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsActions'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import TextField from 'Scenes/Components/TextField/TextField'

interface TextFieldFilterProps {
	id: string | number
	filterData: IFilterData
}

const TextFieldFilter: FC<TextFieldFilterProps> = ({ id, filterData }) => {
	const dispatch = useDispatch()
	return (
		<TextField
			input={{
				onChange: (e: ChangeEvent<HTMLInputElement>) => {
					dispatch(
						changeAdvancedFilter(id, e.currentTarget.value, filterData.name)
					)
				},
				min: filterData.minValue,
				max: filterData.maxValue,
				value: filterData.value
			}}
			disableDot={true}
			disableFloat={true}
			type="number"
			error={filterData?.error}
			inputClassName={cx('filter-fields--widget--text-field-box', {
				disabled: filterData.disabled
			})}
			wrapperClassName="filter-fields--widget--text-field"
			disabled={filterData.disabled}
			key={filterData.name}
			placeholder={filterData.defaultValue}
			minValue={filterData.minValue}
			showRemove={!filterData.disabled && !filterData.mandatory}
			label={filterData.units ? filterData.units : ''}
			labelClassName="advanced-label"
		/>
	)
}

export default memo(TextFieldFilter)
