import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'

import * as CustomizeActions from '../../CustomizeActions'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	onYearlyAmountChange: Function
	onPartLifeTimeChange: Function
	setUserCustomzationSettings: Function
	puAnnualKgCO2: number
	puYearsCO2: number
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2ProductUse: FC<IProps> = ({
	onYearlyAmountChange,
	onPartLifeTimeChange,
	puAnnualKgCO2Value,
	puYearsCO2Value,
	setUserCustomzationSettings,
	puAnnualKgCO2,
	puYearsCO2
}) => {
	const [isKgError, setIsKgError] = useState(false)
	const [isErrorYears, setIsErrorYears] = useState(false)
	const { partLifeLoading, yearlyAmountLoading } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	useEffect(() => {
		if (!yearlyAmountLoading) {
			onYearlyAmountChange('')
		}
		if (!partLifeLoading) {
			onPartLifeTimeChange('')
		}
	}, [partLifeLoading, yearlyAmountLoading])

	const savedKgAmountChecked = (value: string) => {
		if (Number(value) < 0 || Number(value) > 1000) {
			setIsKgError(true)
		} else {
			setIsKgError(false)
		}
		onYearlyAmountChange(value)
	}

	const savedKgYearsChecked = (value: string) => {
		if (Number(value) < 0 || Number(value) > 20) {
			setIsErrorYears(true)
		} else {
			setIsErrorYears(false)
		}
		onPartLifeTimeChange(value)
	}

	const onSaveProductUse = () => {
		if (puAnnualKgCO2Value) {
			setUserCustomzationSettings(
				puAnnualKgCO2Value,
				ParameterTypes.yearlyAmount
			)
		}
		if (puYearsCO2Value) {
			setUserCustomzationSettings(puYearsCO2Value, ParameterTypes.partLife)
		}
	}

	const renderSavedKg = () => {
		return (
			<>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_CALCULATION_PRODUCT_USE_SUBTITLE')}
				</div>
				<Flexbox>
					<div className="custom-calculation-co2--explanation">
						{getString('CO2_CALCULATION_PRODUCT_USE_INPUT_TEXT')}
					</div>
					<TextFieldTSX
						className="custom-calculation-co2--product-use-picker"
						type="number"
						placeholder={puAnnualKgCO2}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							savedKgAmountChecked(e.target.value)
						}
						value={puAnnualKgCO2Value}
					/>
				</Flexbox>
				{isKgError && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION')}
					</div>
				)}
				<Flexbox>
					<div className="custom-calculation-co2--explanation">
						{getString('CO2_CALCULATION_PART_LIFE')}
					</div>
					<TextFieldTSX
						className="custom-calculation-co2--product-use-picker"
						type="number"
						placeholder={puYearsCO2}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							savedKgYearsChecked(e.target.value)
						}
						value={puYearsCO2Value}
					/>
					<div className="custom-calculation-co2--explanation">
						{getString('YEARS').toLowerCase()}
					</div>
				</Flexbox>
				{isErrorYears && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_PRODUCT_USE_DATA_VALIDATION_YEARS')}
					</div>
				)}

				<ButtonWithLoader
					size={20}
					style={{
						padding: '5px 20px',
						width: 'unset',
						maxWidth: '70px',
						minWidth: '70px',
						left: '745px',
						height: '33px'
					}}
					top={20}
					loading={partLifeLoading || yearlyAmountLoading}
					onClick={onSaveProductUse}
					disabled={
						isErrorYears ||
						isKgError ||
						(!puYearsCO2Value && !puAnnualKgCO2Value)
					}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</>
		)
	}

	return <>{renderSavedKg()}</>
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2ProductUse)
)
