import React, { FC, memo } from 'react'
import { TextField } from '@material-ui/core'

import './TexFieldInsideString.scss'

const TextFieldTSX: any = TextField

interface IProps {
  type?: string
  className?: string
  inputClassName?: string
  value?: number | string
  onChange?: Function
  onKeyUp?: Function
  min?: number
  max?: number
  error?: boolean
}

const TexFieldInsideString: FC<IProps> = ({
  className = '',
  inputClassName = '',
  value,
  type,
  onChange,
  onKeyUp,
  min,
  max,
  error
}) => {
  return (
    <TextFieldTSX
      className={`text-in-string ${className}`}
      value={value}
      type={type}
      inputProps={{
        className: `text-in-string--input ${inputClassName} ${
          error ? 'input-error' : ''
        }`,
        onChange,
        onKeyUp,
        min,
        max
      }}
    />
  )
}

export default memo(TexFieldInsideString)
