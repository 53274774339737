import React, { FC, useState, useEffect } from 'react'
import { ReactComponent as ComplexityFit } from '../../../assets/img/svg/complex-full.svg'
import { ReactComponent as ComplexitySmall } from '../../../assets/img/svg/complex.svg'
import { IOtherProps } from '../../../Services/models/IOtherProps'

interface IProps extends IOtherProps {
  small?: boolean
  className?: string
  smallClassName?: string
  expandIconOnHover?: boolean
}

export const IconComplexityScore: FC<IProps> = ({
  small,
  className = '',
  smallClassName = '',
  expandIconOnHover,
  ...props
}) => {
  const [isBigIcon, setIsBigIcon] = useState(false)
  useEffect(() => {
    setIsBigIcon(!small)
  }, [small])

  if (!isBigIcon) {
    return (
      <ComplexitySmall
        className={smallClassName}
        onMouseOver={() => setIsBigIcon(expandIconOnHover || false)}
        {...props}
      />
    )
  }
  return (
    <ComplexityFit
      className={className}
      onMouseLeave={() => setIsBigIcon(!expandIconOnHover || false)}
    />
  )
}
