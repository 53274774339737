import loginPageStyle from '../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'
import Email from '@material-ui/icons/Email'
import LockOutline from '@material-ui/icons/LockOutlined'
import cx from 'classnames'
import PropTypes from 'prop-types'

import PackageJson from '../../../package.json'
import {
	emailChanged,
	forgotPasswordClicked,
	forgotPasswordOnClose,
	hideResetPasswordRequestErrorAlert,
	loginStateChanged,
	loginUser,
	passwordChanged,
	resetAuthState,
	resetPasswordRequestConfirm,
	sendResetEmailConfirmed
} from '../../global actions'
import { ENTER_KEY_CODE } from '../../Services/Constants'
import { REGISTER_ROUTE } from '../../Services/Constants/RoutesConstants'
import { uploadProjectRoute } from '../../Services/routeFuncs'
import {
	FORGOT_PASSWORD,
	FORGOT_PASSWORD_ON_PREM_TEXT,
	FORGOT_PASSWORD_POPUP_BODY,
	FORGOT_PASSWORD_POPUP_TITLE,
	LOGIN_EMAIL_PLACEHOLDER,
	LOGIN_PASSWORD_PLACEHOLDER,
	LOGIN_REGISTER_BUT,
	LOGIN_TITLE,
	OK,
	RESET_PASSWORD_EMAIL_POPUP_HEADER,
	RESET_PASSWORD_REQUEST_ERROR,
	RESET_PASSWORD_REQUEST_SUCCESS,
	SUBMIT,
	USER_PROFILE_EMAIL_ALERT
} from '../../Services/Strings.js'
import { getTheme } from '../../themes/getTheme'
import { getThemeString } from '../../themes/getThemeString'
import CastorAlert from '../Components/alerts/CastorAlert'
import ButtonWithLoader from '../Components/ButtonWithLoader'
import PoweredBy from '../Components/PoweredBy'
import LoginCard from '../Components/thirdParty/CreativeTim/components/Cards/LoginCard.jsx'
import CustomInput from '../Components/thirdParty/CreativeTim/components/CustomInput/CustomInput.jsx'
// core components
import GridContainer from '../Components/thirdParty/CreativeTim/components/Grid/GridContainer.jsx'
import ItemGrid from '../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import PageLoader from '../Loader/PageLoader'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'

import './Login.scss'

const theme = getTheme()

class Login extends Component {
	constructor(props) {
		super(props)
		// we use this to make the card to appear after the page has been rendered
		this.state = {
			cardAnimaton: 'cardHidden',
			showLogin: false
		}
	}

	componentDidMount() {
		this.props.resetAuthState()
		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		setTimeout(
			function () {
				this.setState({ cardAnimaton: '' })
			}.bind(this),
			700
		)

		if (
			!this.props.location.pathname.includes('login') &&
			!this.props.loggedIn &&
			theme.skipLoginPage
		) {
			this.props.history.push('/home/castorLight')
			window.location.reload()
		} else {
			this.setState({ showLogin: true })
		}
	}

	onEmailChange(event) {
		const { emailChanged } = this.props
		emailChanged(event.target.value)
	}

	onKeyUp(event) {
		if (event.keyCode === ENTER_KEY_CODE) {
			this.onSubmitClicked()
		}
	}

	onPasswordChange(event) {
		const { passwordChanged } = this.props
		passwordChanged(event.target.value)
	}

	onSubmitClicked() {
		const { email, password, loginUser, language } = this.props
		loginUser({ email, password }, language)
	}

	onForgotPasswordClicked() {
		const { forgotPasswordClicked, showForgotPasswordPopUp } = this.props
		const click = !showForgotPasswordPopUp
		forgotPasswordClicked(click)
	}

	onForgotPasswordConfirm(data) {
		const { sendResetEmailConfirmed } = this.props
		sendResetEmailConfirmed(data.email)
	}

	updateStateAndRoute() {
		// this.props.loginStateChanged(true)
		const { history } = this.props
		history.push(uploadProjectRoute())
	}

	componentDidUpdate(prevProps) {
		if (this.props.loggedIn && !prevProps.loggedIn) {
			return this.updateStateAndRoute()
		}
	}

	renderError() {
		const { error } = this.props
		if (!error) {
			return <div />
		}
		return <p style={styles.errorTextStyle}>{error}</p>
	}

	renderResetPasswordFailedAlert = () => {
		const { resetRequestError, resetPasswordRequestConfirm } = this.props
		return (
			<CastorAlert
				alertType={AlertType.WARNING}
				headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
				show={!!resetRequestError}
				onConfirm={resetPasswordRequestConfirm}
				onCancel={resetPasswordRequestConfirm}
				showCancel={false}
				confirmOptionalText={OK}
			>
				{resetRequestError || RESET_PASSWORD_REQUEST_ERROR}
			</CastorAlert>
		)
	}

	renderResetPasswordSuccessAlert = () => {
		const {
			resetRequestError,
			resetEmailRequestSuccess,
			resetPasswordRequestConfirm
		} = this.props
		return (
			<CastorAlert
				show={resetEmailRequestSuccess && !resetRequestError}
				headerTitle={RESET_PASSWORD_EMAIL_POPUP_HEADER}
				onConfirm={resetPasswordRequestConfirm}
				onCancel={resetPasswordRequestConfirm}
				showCancel={false}
				confirmOptionalText={OK}
				alertType={AlertType.SUCCESS}
			>
				{RESET_PASSWORD_REQUEST_SUCCESS}
			</CastorAlert>
		)
	}

	renderResetPasswordAlert() {
		const {
			showForgotPasswordPopUp,
			resetRequestError,
			forgotPasswordOnClose,
			isOnPrem
		} = this.props
		if (isOnPrem || !navigator.onLine) {
			return (
				<CastorAlert
					show={showForgotPasswordPopUp}
					inputLabel={FORGOT_PASSWORD_POPUP_BODY}
					alertClass="req-email"
					headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
					validationMsg={USER_PROFILE_EMAIL_ALERT}
					onConfirm={() => forgotPasswordOnClose()}
					onCancel={() => this.onForgotPasswordClicked()}
					showCancel={false}
					confirmOptionalText={OK}
				>
					{FORGOT_PASSWORD_ON_PREM_TEXT}
				</CastorAlert>
			)
		}
		return (
			<CastorAlert
				show={showForgotPasswordPopUp && !resetRequestError}
				type="input"
				inputLabel={FORGOT_PASSWORD_POPUP_BODY}
				inputPlaceholder="Email"
				alertClass="req-email"
				inputType="email"
				headerTitle={FORGOT_PASSWORD_POPUP_TITLE}
				validationMsg={USER_PROFILE_EMAIL_ALERT}
				onConfirm={data => this.onForgotPasswordConfirm(data)}
				onCancel={() => this.onForgotPasswordClicked()}
				confirmOptionalText={OK}
				resetFormOnCancel
			/>
		)
	}

	showRegisterArea() {
		const { isAllowRegister } = this.props
		const blockRegisterLink = process.env.REACT_APP_REGISTER_LINK === 'false'
		if (isAllowRegister && !blockRegisterLink) {
			return (
				<>
					<Divider style={styles.divider} />

					<div className="register-text">
						<p style={styles.subtitle}>
							{getThemeString('LOGIN_REGISTER_HEADER')}
						</p>
						<Link to={REGISTER_ROUTE}>{LOGIN_REGISTER_BUT}</Link>
					</div>
				</>
			)
		}
		return <div />
	}

	render() {
		const { classes, loaderCounter } = this.props
		const { showLogin } = this.state

		if (!showLogin) {
			return <></>
		}

		return (
			<div className={cx(classes.wrapper, 'login-screen')} ref="wrapper">
				{loaderCounter ? (
					<PageLoader />
				) : (
					<>
						{this.renderResetPasswordAlert()}
						{this.renderResetPasswordSuccessAlert()}
						{this.renderResetPasswordFailedAlert()}
						<div className={classes.fullPage}>
							<div className={classes.content}>
								<div className={classes.container}>
									<GridContainer justify="center">
										<ItemGrid xs={12} sm={6} md={4}>
											<form>
												<LoginCard
													customCardClass={classes[this.state.cardAnimaton]}
													headerColor="rose"
													cardTitle={LOGIN_TITLE}
													cardSubtitle={
														<img
															alt={'logo-img'}
															style={styles.logoImage}
															src={theme.whiteBackgroundLogo || theme.logo}
														/>
													}
													footerAlign="center"
													content={
														<div flexDirection="column">
															<CustomInput
																labelText={LOGIN_EMAIL_PLACEHOLDER}
																id="email"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	endAdornment: (
																		<InputAdornment
																			position="end"
																			className={classes.inputAdornment}
																		>
																			<Email
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	value: this.props.email,
																	onChange: this.onEmailChange.bind(this),
																	onKeyUp: this.onKeyUp.bind(this)
																}}
															/>
															<CustomInput
																labelText={LOGIN_PASSWORD_PLACEHOLDER}
																id="password"
																formControlProps={{
																	fullWidth: true
																}}
																inputProps={{
																	endAdornment: (
																		<InputAdornment
																			position="end"
																			className={classes.inputAdornment}
																		>
																			<LockOutline
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	value: this.props.password,
																	onChange: this.onPasswordChange.bind(this),
																	onKeyUp: this.onKeyUp.bind(this),
																	type: 'password'
																}}
															/>
															<ButtonWithLoader
																loading={this.props.loading}
																primary={true}
																style={styles.submitButtonStyle}
																onClick={this.onSubmitClicked.bind(this)}
															>
																{SUBMIT}
															</ButtonWithLoader>
															<div
																onClick={this.onForgotPasswordClicked.bind(
																	this
																)}
															>
																<a className="register-text-link">
																	{FORGOT_PASSWORD}
																</a>
															</div>
															{this.renderError()}
															{this.showRegisterArea()}
															<PoweredBy className="login-powered-by" />
														</div>
													}
												/>
											</form>
										</ItemGrid>
									</GridContainer>
								</div>
							</div>
							<div
								className={classes.fullPageBackground}
								style={{ backgroundImage: 'url(' + theme.bgImage + ')' }}
							/>
						</div>
						<span className="login-version-text">V{PackageJson.version}</span>
					</>
				)}
			</div>
		)
	}
}

const styles = {
	textField: {
		width: 250
	},
	submitButtonStyle: {
		margin: 15,
		alignSelf: 'center'
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red',
		marginTop: 10
	},
	paperStyle: {
		margin: 200,
		textAlign: 'center',
		display: 'inline-block'
	},
	formWrapper: {
		margin: 15
	},
	logoImage: {
		marginTop: 20,
		width: 180
	},
	lineStyle: {
		borderWidth: 0.5,
		borderColor: 'black',
		margin: 10
	},
	subtitle: {
		alignSelf: 'center',
		color: 'gray'
	},
	divider: {
		width: '90%',
		margin: '0 auto',
		color: 'black',
		marginTop: 19,
		marginBottom: 18
	},
	successButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#5ab867',
		boxShadow:
			'0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		'&:hover': {
			backgroundColor: '#5ab867',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	},
	failedButton: {
		fontSize: 16,
		color: '#ffffff',
		width: 120,
		height: 40,
		backgroundColor: '#f4413f',
		boxShadow:
			'0 2px 2px 0 rgba(76, 175, 80, 0.14), 0 3px 1px -2px rgba(76, 175, 80, 0.2), 0 1px 5px 0 rgba(76, 175, 80, 0.12)',
		'&:hover': {
			backgroundColor: '#f4413f',
			boxShadow:
				'0 14px 26px -12px rgba(76, 175, 80, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(76, 175, 80, 0.2)'
		}
	}
}
Login.propTypes = {
	classes: PropTypes.object.isRequired
}
const mapStateToProps = ({
	auth,
	GlobalReducer: { loaderCounter, isOnPrem, isAllowRegister }
}) => {
	return {
		...auth,
		isOnPrem,
		isAllowRegister,
		loaderCounter
	}
}
export default withRouter(
	connect(mapStateToProps, {
		emailChanged,
		passwordChanged,
		loginUser,
		loginStateChanged,
		resetAuthState,
		forgotPasswordClicked,
		sendResetEmailConfirmed,
		hideResetPasswordRequestErrorAlert,
		resetPasswordRequestConfirm,
		forgotPasswordOnClose
	})(withStyles(loginPageStyle)(Login))
)
