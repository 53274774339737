import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'

import { FormControlLabel, RadioGroup } from '@material-ui/core'
import cx from 'classnames'
import { isEqual } from 'lodash'
import Numeral from 'numeral'

import PartCardFooter from '../../../../ProjectAnalysis/PartCard/PartCardFooter'
import OrientationAnalysisResults from './OrientationAnalysisResults'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import Card from 'Scenes/Components/Card/Card'
import { CastorPartBenefits } from 'Scenes/Components/CastorPartBenefits/CastorPartBenefits'
import CastorRadio from 'Scenes/Components/CastorRadio'
import Flexbox from 'Scenes/Components/FlexBox'
import InfoBox from 'Scenes/Components/InfoBox'
import { displayInRange } from 'Services/global/calculateRange'
import WithFeatureToggleHOC from 'Services/HOC/WithFeatureToggleHOC'
import { CADAnalysisResult } from 'Services/models/CADAnalysisResult'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { OrientationData } from 'Services/models/IOrinetationData'
import { Part } from 'Services/models/IPart'
import {
	CUSTOMIZE_FORM_COST_AND_LEAD_HEADER,
	LAYERS,
	SOLUTION_ORIENTATION_HEADER,
	SUPPORT
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import './SolutionOrientation.scss'

const RadioGroupTSX: any = RadioGroup
const FormControlLabelTSX: any = FormControlLabel
const MIN_ORIENTATION_COUNT = 3
const MIN_ORIENTATION_INDEX = 2

interface IProps {
	showChangeOrientationModal: boolean
	loadingCalculation: boolean
	onCancel: Function
	onConfirm: Function
	orientationsData: OrientationData[]
	configuration: any
	orientationModalExplanationText: string
	orientationInfoButtonClicked: Function
	part: Part
	solution: any
	chosenOrientationVector: number[]
}

const renderBenefits = (
	{ benefits, analysisResult }: OrientationData,
	indexOrientation: number
) => {
	const displayBenefit = benefits || []
	const orientationBoxDirection =
		indexOrientation > MIN_ORIENTATION_INDEX ? 'auto-end' : 'left-start'

	return (
		<div className="grid-list-benefits">
			<CastorPartBenefits
				showHoverData={false}
				benefits={displayBenefit || []}
				small
				iconClassName="solution-orientation--orientations--card--content--icon"
				className="grid-list-benefits"
			/>
			<InfoBox
				boxContactElement={
					<OrientationAnalysisResults analysisResultsRows={analysisResult} />
				}
				boxClassName="analysis-result-hover-box"
				boxDirection={orientationBoxDirection}
				boxContactClassName="analysis-result-hover-box--contact"
				inPortal
			/>
		</div>
	)
}

const SolutionOrientation: FC<IProps> = ({
	showChangeOrientationModal,
	onCancel,
	onConfirm,
	orientationsData,
	loadingCalculation,
	orientationInfoButtonClicked,
	chosenOrientationVector,
	solution
}) => {
	const isShowValuesInRanges = Feature.isFeatureOn(
		FeatureComponentId.SHOW_VALUES_IN_RANGES
	)
	const showSmallItems = orientationsData.length <= MIN_ORIENTATION_COUNT
	const [radioValue, setRadioValue] = useState<string>()
	const [currentChosenOrientationVector, setCurrentChosenOrientationVector] =
		useState<number[]>()

	useEffect(() => {
		if (chosenOrientationVector?.length > 0 && orientationsData) {
			setCurrentChosenOrientationVector(chosenOrientationVector)
			const select_orientation = orientationsData.find(
				(orientation: OrientationData) =>
					isEqual(orientation.trayNormalVector, currentChosenOrientationVector)
			)
			if (select_orientation) {
				setRadioValue(select_orientation.name)
			}
		}
	}, [orientationsData, chosenOrientationVector])

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		const chosenOrientation: OrientationData | undefined =
			(orientationsData &&
				orientationsData.length &&
				orientationsData.find(
					(orientation: OrientationData) => orientation.name === e.target.value
				)) ||
			undefined
		if (!chosenOrientation?.failOnSize) {
			setRadioValue(e.target.value)
			setCurrentChosenOrientationVector(chosenOrientation?.trayNormalVector)
		}
	}

	const onAlertCancel = () => {
		setCurrentChosenOrientationVector(chosenOrientationVector)
		onCancel()
	}

	const onAlertConfirm = () => {
		onConfirm(false, currentChosenOrientationVector)
	}

	const renderPartPerBuildContent = (orientation: OrientationData) => {
		const fullTrayAssumption =
			solution?.costDetails?.threeDPrintingCostsBreakDown?.fullTrayAssumption
		if (fullTrayAssumption != null && !fullTrayAssumption) {
			return ''
		}
		return getString('COST_ESTIMATIONS_ASSUMES').format(
			orientation.numberOfPartsPerTray
		)
	}

	const renderOrientationLabel = (
		orientation: OrientationData,
		selected: boolean,
		orientationInfoButtonClicked: Function,
		indexOrientation: number
	) => {
		const {
			totalCost: basicTotalCost,
			maxCostDetails,
			minCostDetails
		} = orientation?.costDetails

		let totalCost = Numeral(basicTotalCost).format('0,0')

		//Check ranges is ON
		if (isShowValuesInRanges && minCostDetails && maxCostDetails) {
			totalCost = displayInRange(
				minCostDetails?.totalCost,
				maxCostDetails?.totalCost,
				totalCost
			)
		}

		return (
			<Card
				imageUrl={orientation.imageURL}
				addSizeWrapper={false}
				title={`${CUSTOMIZE_FORM_COST_AND_LEAD_HEADER}: $${totalCost}`}
				extraTitles={
					solution?.printerTechnology?.isUseSupport
						? [`${SUPPORT}: ${orientation.supportMassPercentage}%`]
						: []
				}
				contentText={`${Numeral(Math.round(orientation.numberOfLayers)).format(
					'0,0'
				)} ${LAYERS}${renderPartPerBuildContent(orientation)}`}
				className={`solution-orientation--orientations--card ${
					selected ? 'card--selected' : ''
				}`}
				contentTitleClassName="solution-orientation--orientations--card--content--title"
				imageClassName="solution-orientation--orientations--card--image"
				contentClassName="solution-orientation--orientations--card--content"
				sizeWrapperClassName="solution-orientation--orientations--card--image--wrapper"
				footer={renderCardFooter(
					orientation,
					orientationInfoButtonClicked,
					indexOrientation
				)}
			/>
		)
	}
	const renderCardFooter = (
		orientation: OrientationData,
		orientationInfoButtonClicked: Function,
		indexOrientation: number
	) => {
		if (!orientation.analysisResult || !orientation.analysisResult.length) {
			return (
				<PartCardFooter
					result={orientation.result}
					benefits={orientation.benefits || []}
					orientationInfoButtonClicked={orientationInfoButtonClicked}
					benefitIconClassName="solution-orientation--orientations--card--content--icon"
					labelTextClassName="solution-orientation--orientations--card--content--label-text"
					showHoverData={false}
					isMissingInfo={
						orientation.status === CADAnalysisResult.missingInformation
					}
				/>
			)
		}

		return (
			<PartCardFooter
				result={orientation.result}
				orientationInfoButtonClicked={orientationInfoButtonClicked}
				labelTextClassName="solution-orientation--orientations--card--content--label-text"
				customBenefitSection={renderBenefits(orientation, indexOrientation)}
				showHoverData={false}
			/>
		)
	}

	const renderRadioSelector = () => (
		<RadioGroupTSX
			name="orientation"
			value={radioValue}
			onChange={handleChange}
			class="solution-orientation--orientations"
		>
			{orientationsData ? (
				orientationsData.map((orientation: OrientationData, idx: number) => {
					const disabledOrientation =
						orientation.status === CADAnalysisResult.failed ||
						orientation.result === CADAnalysisResult.notPrintable

					const disableLightUserOrientation = !Feature.isFeatureActive(
						FeatureComponentId.TRAY_ORIENTATION
					)
					return (
						<FormControlLabelTSX
							key={orientation.name}
							disabled={disabledOrientation || disableLightUserOrientation}
							value={orientation.name}
							control={<CastorRadio className="solution-orientation--radio" />}
							className={cx('solution-orientation--wrapper', {
								'small-item': showSmallItems
							})}
							classes={{
								label: `solution-orientation--label--wrapper`
							}}
							label={renderOrientationLabel(
								orientation,
								radioValue === orientation.name,
								orientationInfoButtonClicked,
								idx
							)}
						/>
					)
				})
			) : (
				<div />
			)}
		</RadioGroupTSX>
	)

	const renderOrientationContent = () => {
		return (
			<Flexbox alignItems="flex-start">
				<Flexbox
					flexDirection="column"
					alignItems="flex-start"
					className="solution-orientation--explanation"
				>
					<div>{SOLUTION_ORIENTATION_HEADER[0]}</div>
					<br />
					<div>{SOLUTION_ORIENTATION_HEADER[1]}</div>
					<br />
					<div>{SOLUTION_ORIENTATION_HEADER[2]}</div>
					<br />
				</Flexbox>

				{renderRadioSelector()}
			</Flexbox>
		)
	}
	const disableLightUserOrientation = !Feature.isFeatureActive(
		FeatureComponentId.TRAY_ORIENTATION
	)
	return (
		<CastorAlert
			disabled={disableLightUserOrientation}
			headerTitle={getString('PRINTING_ORIENTATION_ALERT_TITLE')}
			onCancel={onAlertCancel}
			show={showChangeOrientationModal}
			onConfirm={onAlertConfirm}
			alertClass="solution-orientation--alert"
			alertBodyClass="solution-orientation--alert--body"
			loadingCalculation={loadingCalculation}
			fullScreen
			onButtonHoverText={
				disableLightUserOrientation
					? getString('LIGHT_USER_BUTTONS_INFORMATION')
					: ''
			}
			buttonHoverClassName={
				disableLightUserOrientation ? 'details-popup--contact-us' : ''
			}
		>
			{orientationsData?.length ? renderOrientationContent() : <div />}
		</CastorAlert>
	)
}

export default WithFeatureToggleHOC(
	memo(SolutionOrientation),
	FeatureComponentId.TRAY_ORIENTATION
)
