import React, { FC, memo, useEffect } from 'react'
import { connect, DispatchProp } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { Divider } from '@material-ui/core'

import * as CustomizeCO2Actions from '../CustomizeCO2Cost/CustomizeCO2Actions'
import * as CustomizeCO2CalculationAction from './CustomizeCO2CalculationAction'
import CustomizeCO2Cost from '../CustomizeCO2Cost/CustomizeCO2Cost'
import CustomizeCO2EmissionsPrinterMaterial from './CO2Emissions/CustomizeCO2EmissionsPrinterMaterial'
import CustomizeCO2EmissionsRawMaterial from './CO2Emissions/CustomizeCO2EmissionsRawMaterial'
import EmissionsPrinterMaterialTable from './CO2Emissions/EmissionsPrinterMaterialTable'
import EmissionsRawMaterialTable from './CO2Emissions/EmissionsRawMaterialTable'
import CustomizeCO2EndOfLife from './CO2EndofLife/CustomizeCO2EndOfLife'
import CustomizeCO2CarbonIntensity from './CO2Manufacturing/CustomizeCO2CarbonIntensity'
import CustomizeCO2TypicalKwh from './CO2Manufacturing/CustomizeCO2TypicalKwh'
import CustomizeCO2ProductUse from './CO2ProductUse/CustomizeCO2ProductUse'
import CastorForm from 'Scenes/Components/CastorForm/CastorForm'
import CastorFormHeader from 'Scenes/Components/CastorForm/CastorFormHeader'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import './CustomizeCO2Calculation.scss'

const { defaultMaterial } = getTheme()

const DividerTSX: any = Divider
interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
	CustomizeInHousePrintersReducer: any
	CustomizeCO2Reducer: any
}
interface IProps {
	onEditToggle: Function
	onEditAllToggle: Function
	onEditCO2Data: Function
	onUpdateCO2Data: Function
	onSetUserCustomzationSettings: Function
	onCo2PerKgMaterialChange: Function
	onCo2PerKwChange: Function
	onYearlyAmountChange: Function
	onDisposeFactorChange: Function
	onCo2PerKgPrinterMaterialChange: Function
	onSetNewMaterial: Function
	onDeleteMaterial: Function
	onDeletePrinterTypicalKwh: Function
	onSetNewPrinterMaterial: Function
	onDeletePrinterMaterial: Function
	onSetNewPrinterTypicalkwh: Function
	fetchUserCo2Data: Function
	onPrinterTypicalkwhChange: Function
	onTmTypicalkwhChange: Function
	onUpdateTypicalkwhTm: Function
	onPartLifeTimeChange: Function
	onDeleteCycleKwh: Function
	materialTypes: any
	materials: any
	materialCategories: any
	co2PerKgMaterial: any
	co2PerKgPrinterMaterial: any
	printersTypicalkwhList: any
	materialList: any
	printersList: any
	cycleList: any
	typicalKwhList: any
	printersFullData: any
	typicalKwhPrinter: any
	typicalKwhTm: any
	puAnnualKgCO2: number
	puYearsCO2: number
	disposeFactor: number
	co2PerKW: number
	disposeFactorValue: string
	puAnnualKgCO2Value: string
	puYearsCO2Value: string
	co2PerKwValue: string
	co2EmissionProps: any
}

const CustomizeCO2EmissionsSelector: FC<IProps> = ({
	materialCategories,
	materialTypes,
	materials,
	onCo2PerKgMaterialChange,
	onSetNewMaterial,
	co2PerKgMaterial,
	co2PerKgPrinterMaterial,
	materialList,
	printersList,
	onCo2PerKwChange,
	onSetNewPrinterMaterial,
	onPartLifeTimeChange,
	co2PerKW,
	co2PerKwValue,
	cycleList,
	typicalKwhList,
	puAnnualKgCO2Value,
	puYearsCO2Value,
	disposeFactorValue,
	puAnnualKgCO2,
	puYearsCO2,
	disposeFactor,
	printersFullData,
	onSetUserCustomzationSettings,
	onYearlyAmountChange,
	onDisposeFactorChange,
	onDeleteMaterial,
	onCo2PerKgPrinterMaterialChange,
	onDeletePrinterMaterial,
	onSetNewPrinterTypicalkwh,
	fetchUserCo2Data,
	typicalKwhPrinter,
	typicalKwhTm,
	onPrinterTypicalkwhChange,
	onTmTypicalkwhChange,
	printersTypicalkwhList,
	onDeletePrinterTypicalKwh,
	onUpdateTypicalkwhTm,
	onDeleteCycleKwh,
	co2EmissionProps,
	onEditToggle,
	onEditAllToggle,
	onEditCO2Data,
	onUpdateCO2Data
}) => {
	useEffect(() => {
		fetchUserCo2Data()
	}, [])
	const renderSelectors = () => {
		return (
			<div>
				<div className="custom-calculation-co2--title">
					{getString('CUSTOMIZE_CO2_MATERIAL_PRODUCTION')}
				</div>
				<CustomizeCO2EmissionsRawMaterial
					defaultMaterial={defaultMaterial}
					materialCategories={materialCategories}
					materialTypes={materialTypes}
					materials={materials}
					onCo2PerKgMaterialChange={onCo2PerKgMaterialChange}
					setNewMaterial={onSetNewMaterial}
					co2PerKg={co2PerKgMaterial}
				></CustomizeCO2EmissionsRawMaterial>
				<EmissionsRawMaterialTable
					materialList={materialList}
					deleteMaterial={onDeleteMaterial}
				></EmissionsRawMaterialTable>
				<CustomizeCO2EmissionsPrinterMaterial
					onCo2PerKgPrinterMaterialChange={onCo2PerKgPrinterMaterialChange}
					setNewPrinterMaterial={onSetNewPrinterMaterial}
					co2PerKg={co2PerKgPrinterMaterial}
					printersFullData={printersFullData}
				></CustomizeCO2EmissionsPrinterMaterial>
				<EmissionsPrinterMaterialTable
					printersList={printersList}
					deleteMaterial={onDeletePrinterMaterial}
				></EmissionsPrinterMaterialTable>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('PRODUCTION')}
				</div>
				<CustomizeCO2CarbonIntensity
					setUserCustomzationSettings={onSetUserCustomzationSettings}
					onCo2PerKwChange={onCo2PerKwChange}
					co2PerKwValue={co2PerKwValue}
					co2PerKW={co2PerKW}
				></CustomizeCO2CarbonIntensity>
				<CustomizeCO2TypicalKwh
					printersFullData={printersFullData}
					cycleList={cycleList}
					typicalKwhList={typicalKwhList}
					setNewPrinterTypicalkwh={onSetNewPrinterTypicalkwh}
					typicalKwhPrinter={typicalKwhPrinter}
					typicalKwhTm={typicalKwhTm}
					onPrinterTypicalkwhChange={onPrinterTypicalkwhChange}
					onTmTypicalkwhChange={onTmTypicalkwhChange}
					printersTypicalkwhList={printersTypicalkwhList}
					onDeletePrinterTypicalKwh={onDeletePrinterTypicalKwh}
					onUpdateTypicalkwhTm={onUpdateTypicalkwhTm}
					onDeleteCycleKwh={onDeleteCycleKwh}
				></CustomizeCO2TypicalKwh>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('CO2_PARAMETERS_PRODUCT_USE_HEADER_TEXT')}
				</div>
				<CustomizeCO2ProductUse
					onYearlyAmountChange={onYearlyAmountChange}
					onPartLifeTimeChange={onPartLifeTimeChange}
					puAnnualKgCO2Value={puAnnualKgCO2Value}
					puYearsCO2Value={puYearsCO2Value}
					setUserCustomzationSettings={onSetUserCustomzationSettings}
					puAnnualKgCO2={puAnnualKgCO2}
					puYearsCO2={puYearsCO2}
				></CustomizeCO2ProductUse>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('CO2_PARAMETERS_END_OF_LIFE_HEADER_TEXT')}
				</div>
				<CustomizeCO2EndOfLife
					setUserCustomzationSettings={onSetUserCustomzationSettings}
					disposeFactor={disposeFactor}
					disposeFactorValue={disposeFactorValue}
					disposeFactorChange={onDisposeFactorChange}
				></CustomizeCO2EndOfLife>
				<DividerTSX className="custom-calculation-co2--divider" />
				<div className="custom-calculation-co2--title">
					{getString('TRANSPORTATION')}
				</div>

				<CustomizeCO2Cost
					{...co2EmissionProps}
					onEditToggle={onEditToggle}
					onEditAllToggle={onEditAllToggle}
					onEditCO2Data={onEditCO2Data}
					onUpdateCO2Data={onUpdateCO2Data}
				/>
			</div>
		)
	}

	const renderContent = () => {
		return (
			<>
				<CastorFormHeader
					explanationHeader={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					explanationArray={getString('CUSTOMIZE_CO2_CALCULATIONS_ARRAY')}
					isInCard={true}
				/>
				{renderSelectors()}
			</>
		)
	}
	const renderFormHeader = () => {
		return (
			<CastorFormHeader
				explanationHeader={getString('CUSTOMIZE_EXPLINATION_1_HEADER')}
				explanationArray={getString('CUSTOMIZE_EXPLANATION_ARRAY')}
			/>
		)
	}
	return (
		<>
			<NavBarAndMaterial title={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}>
				<CastorForm
					formHeader={renderFormHeader()}
					formTitle={getString('CUSTOMIZE_CO2_CALCULATIONS_TITLE')}
					content={renderContent()}
				/>
			</NavBarAndMaterial>
		</>
	)
}

const mapStateToProps = (state: IReduxStore) => {
	const {
		CustomizeCO2Reducer,
		CustomizeInHousePrintersReducer,
		CustomizeCO2CalculationReducer,
		user: {
			materialTypes,
			materialCategories,
			materials,
			printersFullData,
			printerMaterialUniqueNames,
			co2PerKW,
			puAnnualKgCO2,
			puYearsCO2,
			disposeFactor
		}
	} = state
	return {
		co2EmissionProps: CustomizeCO2Reducer,
		...CustomizeCO2CalculationReducer,
		...CustomizeInHousePrintersReducer,
		materialCategories,
		materialTypes,
		materials,
		printersFullData,
		printerMaterialUniqueNames,
		co2PerKW,
		puAnnualKgCO2,
		puYearsCO2,
		disposeFactor
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators(
		{
			...CustomizeCO2CalculationAction,
			...CustomizeCO2Actions
		},
		dispatch
	)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EmissionsSelector)
)
