import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

import headerLinksStyle from '../../../../Components/thirdParty/CreativeTim/variables/styles/headerLinksStyle'
import { CUSTOMIZE_USER_MATERIAL_TITLE } from '../../../../../Services/Strings'
import {
  customizeUser,
  customizeUserMaterial
} from '../../../../../Services/routeFuncs'
import CustomizeInhouseMenuItem from './CustomizeInhouseMenuItem'
import CustomizeDesktopPrinterMenuItem from './CustomizeDesktopPrinterMenuItem'

import '../AdminUsers.scss'

const CustomizeMenuItems = props => {
  const {
    classes,
    onClick,
    userId,
    onDesktopPrinterToggle,
    isDesktopPrinter
  } = props

  return (
    <MenuList role="menu">
      <CustomizeInhouseMenuItem
        componentId={2}
        onClick={onClick}
        component={Link}
        to={customizeUser(userId)}
        menuItemClassName={classes.dropdownItem}
        buttonClassName={`${classes.button} menu-button-item`}
      />
      <MenuItem
        onClick={onClick}
        component={Link}
        to={customizeUserMaterial(userId)}
        className={classes.dropdownItem}
      >
        <Button
          className={`${classes.button} menu-button-item`}
          variant="text"
          size="small"
        >
          {CUSTOMIZE_USER_MATERIAL_TITLE}
        </Button>
      </MenuItem>
      <CustomizeDesktopPrinterMenuItem
        componentId={2}
        onChange={onDesktopPrinterToggle}
        isDesktopPrinter={isDesktopPrinter}
      />
    </MenuList>
  )
}

export default withStyles(headerLinksStyle)(CustomizeMenuItems)
