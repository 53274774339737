import { ClearCacheProvider } from 'react-clear-cache'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { applyMiddleware, compose, createStore } from 'redux'
import ReduxThunk from 'redux-thunk'

import {
	createGenerateClassName,
	StylesProvider
} from '@material-ui/core/styles'

import App from './App'
import reducers from './global reducers'
import throttledMiddleware from './middleware/throttled'
import registerServiceWorker from './registerServiceWorker'

import './index.css'


const composeEnhancers =
	(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
			trace: true,
			traceLimit: 25
		})) ||
	compose

const generateClassName = createGenerateClassName({
	seed: 'castor'
})

export const store = createStore(
	reducers,
	{},
	composeEnhancers(applyMiddleware(throttledMiddleware, ReduxThunk))
)

ReactDOM.render(
	<StylesProvider generateClassName={generateClassName}>
		<Provider store={store}>
			<ClearCacheProvider>
				<App />
			</ClearCacheProvider>
		</Provider>
	</StylesProvider>,
	document.getElementById('root')
)

registerServiceWorker()