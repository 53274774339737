import registerPageStyle from '../../assets/jss/material-dashboard-pro-react/views/registerPageStyle'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Business from '@material-ui/icons/Business'
import Check from '@material-ui/icons/Check'
import Email from '@material-ui/icons/Email'
import Face from '@material-ui/icons/Face'
import HomeIcon from '@material-ui/icons/Home'
import InfoOutline from '@material-ui/icons/InfoOutlined'
import LockOutline from '@material-ui/icons/LockOutlined'

import {
	checkboxClicked,
	companyChanged,
	emailChanged,
	hidePasswordStrengthAlert,
	hideVerifyUserAlert,
	loginStateChanged,
	nameChanged,
	passwordChanged,
	reenterPasswordChanged,
	registerUser,
	resetAuthState,
	showPasswordStrengthAlert
} from '../../global actions'
import { ENTER_KEY_CODE } from '../../Services/Constants'
import {
	LOGIN_ROUTE,
	USAGE_AGREEMENT
} from '../../Services/Constants/RoutesConstants'
import { uploadProjectRoute } from '../../Services/routeFuncs'
import {
	REGISTER_COMPANY_PLACEHOLDER,
	REGISTER_EMAIL_PLACEHOLDER,
	REGISTER_LOGIN_BUT,
	REGISTER_LOGIN_TEXT,
	REGISTER_NAME_PLACEHOLDER,
	REGISTER_PASS_PLACEHOLDER,
	REGISTER_REENTER_PASS_PLACEHOLDER,
	REGISTER_TITLE,
	RESET_VERIFY_EMAIL_REQUEST_SUCCESS,
	RESET_VERIFY_USER_POPUP_HEADER,
	SUBMIT,
	TURMS_CHECKBOX_LINK_TEXT,
	TURMS_CHECKBOX_TEXT,
	USER_ADDRESS
} from '../../Services/Strings'
import { getString } from '../../Services/Strings/StringService'
import { getTheme } from '../../themes/getTheme'
import { getThemeString } from '../../themes/getThemeString'
import CastorAlert from '../Components/alerts/CastorAlert'
import ButtonWithLoader from '../Components/ButtonWithLoader'
import CastorLocationDropdown from '../Components/CastorLocationSearchInput/CastorLocationDropdown/CastorLocationDropdown'
import CastorLocationSearchInput from '../Components/CastorLocationSearchInput/CastorLocationSearchInput'
import PoweredBy from '../Components/PoweredBy'
import RegularCard from '../Components/thirdParty/CreativeTim/components/Cards/RegularCard.jsx'
import CustomInput from '../Components/thirdParty/CreativeTim/components/CustomInput/CustomInput.jsx'
import GridContainer from '../Components/thirdParty/CreativeTim/components/Grid/GridContainer.jsx'
import ItemGrid from '../Components/thirdParty/CreativeTim/components/Grid/ItemGrid.jsx'
import RegisterExplanation from './RegisterExplanation'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import Flexbox from 'Scenes/Components/FlexBox'

import './Login.scss'

const theme = getTheme()

class Register extends Component {
	constructor(props) {
		super(props)
		this.state = {
			checked: []
		}
	}

	componentDidMount() {
		this.props.resetAuthState()
	}

	onKeyUp(event) {
		if (event.keyCode === ENTER_KEY_CODE) {
			this.onSubmitClicked()
		}
	}

	onEmailChange(event) {
		this.props.emailChanged(event.target.value)
	}

	onPasswordChange(event) {
		this.props.passwordChanged(event.target.value)
	}

	onReenterPasswordChange(event) {
		this.props.reenterPasswordChanged(
			this.props.strengthScore,
			this.props.password,
			event.target.value
		)
	}

	onNameChange(event) {
		this.props.nameChanged(event.target.value)
	}

	onCompanyChange(event) {
		this.props.companyChanged(event.target.value)
	}

	onUsageAgreementClick() {
		const { isOnPrem } = this.props

		if (isOnPrem || !navigator.onLine) {
			this.props.history.push(USAGE_AGREEMENT)
		} else {
			var win = window.open(getThemeString('USAGE_AGREEMENT_LINK'), '_blank')
			win.focus()
		}
	}

	onSubmitClicked() {
		const {
			email,
			password,
			reenterPassword,
			name,
			company,
			agreedToTerms,
			strengthScore,
			formatted_address,
			city,
			state,
			country,
			long,
			lat,
			zip_code,
			validAddress,
			language,
			countryCode
		} = this.props

		this.props.registerUser({
			email,
			password,
			reenterPassword,
			name,
			company,
			agreedToTerms,
			strengthScore,
			formatted_address,
			city,
			state,
			country,
			long,
			lat,
			zip_code,
			validAddress,
			language,
			countryCode
		})
	}

	updateStateAndRoute() {
		this.props.loginStateChanged(true)
		this.props.history.push(uploadProjectRoute())
	}

	checkboxClicked(event) {
		this.props.checkboxClicked(event.target.checked)
	}

	componentDidUpdate(prevProps) {
		if (this.props.loggedIn && !prevProps.loggedIn) {
			return this.updateStateAndRoute()
		}
	}

	renderAlert() {
		return (
			<CastorAlert
				alertType={AlertType.WARNING}
				show={this.props.showingSimpleAlertText}
				headerTitle={this.props.showingSimpleAlertTitle}
				onConfirm={this.props.hidePasswordStrengthAlert.bind(this)}
				onCancel={this.props.hidePasswordStrengthAlert.bind(this)}
				showCancel={false}
				confirmOptionalText={getString('CLOSE')}
			>
				{this.props.showingSimpleAlertText}
			</CastorAlert>
		)
	}

	renderVerifyUserAlert = () => {
		const { showingRenderVerifyUser } = this.props
		return (
			<CastorAlert
				alertType={AlertType.SUCCESS}
				show={showingRenderVerifyUser}
				headerTitle={RESET_VERIFY_USER_POPUP_HEADER}
				onConfirm={this.props.hideVerifyUserAlert.bind(this)}
				onCancel={this.props.hideVerifyUserAlert.bind(this)}
				showCancel={false}
				confirmOptionalText={getString('CLOSE')}
			>
				{RESET_VERIFY_EMAIL_REQUEST_SUCCESS}
			</CastorAlert>
		)
	}

	render() {
		const { classes, isOnPrem } = this.props

		return (
			<div className={classes.wrapper} ref="wrapper">
				{this.renderAlert()}
				{this.renderVerifyUserAlert()}

				<div className={classes.fullPage}>
					<div className={classes.container}>
						<GridContainer justify="center">
							<ItemGrid xs={12} sm={12} md={10}>
								<RegularCard
									cardTitle={REGISTER_TITLE}
									titleAlign="center"
									customCardTitleClasses={classes.cardTitle}
									customCardClasses={classes.cardClasses}
									content={
										<div>
											<Flexbox
												flexDirection="row"
												style={styles.horizontalFlex}
											>
												<p style={styles.subtitle}>
													{REGISTER_LOGIN_TEXT}&nbsp;
												</p>
												<Link to={LOGIN_ROUTE} style={styles.linkStyle}>
													{REGISTER_LOGIN_BUT}
												</Link>
											</Flexbox>
											<GridContainer justify="center">
												<ItemGrid xs={12} sm={12} md={5}>
													<div className={classes.center}>
														<img
															alt={'img-register'}
															style={styles.logoImage}
															src={theme.whiteBackgroundLogo || theme.logo}
														/>
													</div>
													<RegisterExplanation />
												</ItemGrid>
												<ItemGrid xs={12} sm={8} md={5}>
													<br />
													<form
														className={classes.form}
														style={{ display: 'flex', flexDirection: 'column' }}
													>
														<CustomInput
															success={this.props.emailValid}
															error={this.props.emailValid === false}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<Email
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_EMAIL_PLACEHOLDER,
																value: this.props.email,
																onChange: this.onEmailChange.bind(this),
																autoComplete: 'email',
																onKeyUp: this.onKeyUp.bind(this)
															}}
														/>
														<CustomInput
															success={!!this.props.name}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<Face
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_NAME_PLACEHOLDER,
																value: this.props.name,
																onChange: this.onNameChange.bind(this),
																autoComplete: 'name',
																onKeyUp: this.onKeyUp.bind(this)
															}}
														/>
														<CustomInput
															success={!!this.props.company}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<Business
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_COMPANY_PLACEHOLDER,
																value: this.props.company,
																onChange: this.onCompanyChange.bind(this),
																autoComplete: 'company',
																onKeyUp: this.onKeyUp.bind(this)
															}}
														/>
														{isOnPrem ? (
															<CastorLocationDropdown
																wrapperClass={'register'}
																withIcons
															/>
														) : (
															<CastorLocationSearchInput
																placeHolder={USER_ADDRESS}
																managerStyle={{
																	width: '100%',
																	margin: '0 12px'
																}}
																inputStyle={{ margin: 'unset' }}
																inputProps={{
																	startAdornment: (
																		<InputAdornment
																			position="start"
																			className={classes.inputAdornment}
																		>
																			<HomeIcon
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	autoComplete: 'shipping'
																}}
															/>
														)}

														<Flexbox flexDirection="row">
															<CustomInput
																success={this.props.strengthScore > 1}
																error={
																	this.props.password.length > 0 &&
																	this.props.strengthScore <= 1
																}
																formControlProps={{
																	fullWidth: true,
																	className: classes.customFormControlClasses
																}}
																inputProps={{
																	startAdornment: (
																		<InputAdornment
																			position="start"
																			className={classes.inputAdornment}
																		>
																			<LockOutline
																				className={classes.inputAdornmentIcon}
																			/>
																		</InputAdornment>
																	),
																	placeholder: REGISTER_PASS_PLACEHOLDER,
																	value: this.props.password,
																	onChange: this.onPasswordChange.bind(this),
																	type: 'password',
																	autoComplete: 'new-password',
																	onKeyUp: this.onKeyUp.bind(this)
																}}
															/>

															<IconButton
																onClick={this.props.showPasswordStrengthAlert.bind(
																	this
																)}
																style={
																	this.props.password.length > 0 &&
																	this.props.strengthScore <= 1
																		? styles.infoBut
																		: styles.infoButHidden
																}
															>
																<InfoOutline />
															</IconButton>
														</Flexbox>
														<CustomInput
															success={this.props.reenterPasswordValid}
															error={this.props.reenterPasswordInValid}
															formControlProps={{
																fullWidth: true,
																className: classes.customFormControlClasses
															}}
															inputProps={{
																startAdornment: (
																	<InputAdornment
																		position="start"
																		className={classes.inputAdornment}
																	>
																		<LockOutline
																			className={classes.inputAdornmentIcon}
																		/>
																	</InputAdornment>
																),
																placeholder: REGISTER_REENTER_PASS_PLACEHOLDER,
																value: this.props.reenterPassword,
																onChange:
																	this.onReenterPasswordChange.bind(this),
																type: 'password',
																autoComplete: 'new-password',
																onKeyUp: this.onKeyUp.bind(this)
															}}
														/>
														<Flexbox
															flexDirection="row"
															justifyContent="flex-start"
															cy-data="terms_of_use"
															alignItems="center"
															classes={{
																root: classes.checkboxLabelControl,
																label: classes.checkboxLabel
															}}
															style={{ alignSelf: 'center' }}
														>
															<Checkbox
																cy-data="terms_of_use__checkbox"
																onClick={this.checkboxClicked.bind(this)}
																checked={this.props.agreedToTerms}
																checkedIcon={
																	<Check className={classes.checkedIcon} />
																}
																icon={
																	<Check className={classes.uncheckedIcon} />
																}
																classes={{
																	checked: classes.checked
																}}
															/>
															<Flexbox
																flexDirection="row"
																justifyContent="flex-start"
															>
																<span style={styles.checkboxLabel}>
																	{TURMS_CHECKBOX_TEXT}&nbsp;
																</span>
																<div
																	style={styles.checkboxLabelLink}
																	onClick={() => this.onUsageAgreementClick()}
																>
																	{TURMS_CHECKBOX_LINK_TEXT}
																</div>
															</Flexbox>
														</Flexbox>
														<div className={classes.center}>
															<ButtonWithLoader
																rounded
																loading={this.props.loading}
																primary={true}
																style={styles.submitButtonStyle}
																onClick={this.onSubmitClicked.bind(this)}
															>
																{SUBMIT}
															</ButtonWithLoader>
															<br />
															<p style={styles.errorTextStyle}>
																{this.props.error}
															</p>
														</div>
													</form>
												</ItemGrid>
											</GridContainer>
											<PoweredBy className="register-powered-by" />
										</div>
									}
								/>
							</ItemGrid>
						</GridContainer>
					</div>

					<div
						className={classes.fullPageBackground}
						style={{ backgroundImage: 'url(' + theme.bgImage + ')' }}
					/>
				</div>
			</div>
		)
	}
}

const styles = {
	submitButtonStyle: {
		margin: 15,
		minWidth: 115,
		height: 45
	},
	errorTextStyle: {
		fontSize: 15,
		alignSelf: 'center',
		color: 'red'
	},
	paperStyle: {
		margin: 250,
		textAlign: 'center',
		display: 'inline-block'
	},
	formWrapper: {
		margin: 15
	},
	logoImage: {
		margin: 40,
		width: 200,
		alighSelf: 'center'
	},
	lineStyle: {
		borderWidth: 0.5,
		borderColor: 'black',
		margin: 10
	},
	subtitle: {
		fontSize: 22,
		alignSelf: 'center',
		color: 'gray',
		margin: 6
	},
	linkStyle: {
		fontSize: 22,
		alignSelf: 'center',
		color: '#9c27b0'
	},
	textField: {
		width: 250
	},
	checkbox: {
		width: '30'
	},
	checkboxLabel: {
		fontSize: 14,
		color: 'gray',
		cursor: 'default'
	},
	checkboxLabelLink: {
		fontSize: 14,
		color: '#9c27b0',
		cursor: 'pointer'
	},
	horizontalFlex: {
		flex: 1,
		alignItems: 'center',
		align: 'center',
		justifyContent: 'center'
	},
	infoBut: { width: 40, marginLeft: 3, marginRight: -80, marginTop: 6 },
	infoButHidden: {
		width: 40,
		marginLeft: 3,
		marginRight: -80,
		visibility: 'hidden'
	}
}

const mapStateToProps = ({
	auth,
	user,
	CastorLocationSearchReducer,
	GlobalReducer
}) => {
	const {
		email,
		password,
		reenterPassword,
		name,
		company,
		error,
		loading,
		loggedIn,
		agreedToTerms,
		strengthScore,
		emailValid,
		showingSimpleAlertText,
		showingRenderVerifyUser,
		showingSimpleAlertTitle,
		reenterPasswordInValid,
		reenterPasswordValid,
		language
	} = auth

	return {
		email,
		password,
		reenterPassword,
		name,
		company,
		error,
		loading,
		loggedIn,
		agreedToTerms,
		strengthScore,
		emailValid,
		showingSimpleAlertText,
		showingRenderVerifyUser,
		showingSimpleAlertTitle,
		reenterPasswordInValid,
		reenterPasswordValid,
		language,
		...GlobalReducer,
		...CastorLocationSearchReducer
	}
}
export default connect(mapStateToProps, {
	emailChanged,
	passwordChanged,
	reenterPasswordChanged,
	nameChanged,
	companyChanged,
	registerUser,
	loginStateChanged,
	resetAuthState,
	checkboxClicked,
	hidePasswordStrengthAlert,
	hideVerifyUserAlert,
	showPasswordStrengthAlert
})(withStyles(registerPageStyle)(Register))
