import Flexbox from 'Scenes/Components/FlexBox'
import { FC, memo } from 'react'

import { styles } from '../ProjectMultipleParts/style'
import ProjectResultsContent from './ProjectResultsContent'
import HeaderCard from 'Scenes/Components/thirdParty/CreativeTim/components/Cards/HeaderCard'
import GridContainer from 'Scenes/Components/thirdParty/CreativeTim/components/Grid/GridContainer'
import { Part } from 'Services/models/IPart'
import { PartProperties } from 'Services/models/IPartPropertiest'
import { Project } from 'Services/models/IProject'
import { PROJECT_ANALYSIS_SUB_TITLE, RESULTS } from 'Services/Strings'
import { getTheme } from 'themes/getTheme'

const { cardBoxColor } = getTheme()

const GridContainerTSX: any = GridContainer

interface ProjectsResultProps {
	project: Project
	isSinglePartProject: boolean
	is2dProject: boolean
	onlyPart: Part
	amountOfLockedParts: string
	showStandardCostFinishIcon: boolean
	showExportButton: boolean
	onlyPartResultTitle: string
	partsProperties: PartProperties[]
	selectedFilterPart: string
}

const ProjectsResult: FC<ProjectsResultProps> = ({
	project,
	isSinglePartProject,
	is2dProject,
	onlyPart,
	amountOfLockedParts,
	showStandardCostFinishIcon,
	showExportButton,
	onlyPartResultTitle,
	partsProperties,
	selectedFilterPart
}) => {
	if (!project) return <div />

	return (
		<GridContainerTSX wrap="wrap">
			<Flexbox flexDirection="row" style={styles.horizontalFlex}>
				<HeaderCard
					id={`general-part-statistics${
						isSinglePartProject ? '-single-part' : ''
					}`}
					cardTitle={RESULTS}
					headerColor={cardBoxColor || 'green'}
					cardSubtitle={PROJECT_ANALYSIS_SUB_TITLE}
					iconColor="red"
					content={
						<ProjectResultsContent
							project={project}
							isSinglePartProject={isSinglePartProject}
							is2dProject={is2dProject}
							onlyPart={onlyPart}
							amountOfLockedParts={amountOfLockedParts}
							showStandardCostFinishIcon={showStandardCostFinishIcon}
							showExportButton={showExportButton}
							onlyPartResultTitle={onlyPartResultTitle}
							partsProperties={partsProperties}
							selectedFilterPart={selectedFilterPart}
						/>
					}
				/>
			</Flexbox>
		</GridContainerTSX>
	)
}

export default memo(ProjectsResult)
