import { FC, memo } from 'react'
import { AnyAction, bindActionCreators } from 'redux'
import { connect, DispatchProp } from 'react-redux'
import { Close } from '@material-ui/icons'

import { getString } from 'Services/Strings/StringService'
import {
	Button,
	ItemGrid,
	Table
} from 'Scenes/Components/thirdParty/CreativeTim/components'
import { CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM } from 'Services/Strings'
import * as CustomizeCO2CalculationAction from '.././CustomizeCO2CalculationAction'

import '../CustomizeCO2Calculation.scss'

const ItemGridTSX: any = ItemGrid
const CloseTSX: any = Close

interface IProps {
	printersTypicalkwhList: any
	deletePrinterTypicalKwh: Function
}
interface IReduxStore {
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2TypicalKwhTable: FC<IProps> = ({
	printersTypicalkwhList,
	deletePrinterTypicalKwh
}) => {
	const onDeleteMaterial = (itemToDelete: any) => {
		deletePrinterTypicalKwh(itemToDelete)
	}

	const renderDeleteButton = (itemToDelete: Record<string, any>) => {
		return (
			<Button
				title={CUSTOMIZE_PRINTER_SETTINGS_DELETE_ITEM}
				onClick={() => onDeleteMaterial(itemToDelete)}
				color="dangerNoBackground"
				style={{ margin: 'unset', padding: 'unset' }}
			>
				<CloseTSX className="custom-calculation-co2--table--icon" />
			</Button>
		)
	}

	const renderCo2MaterialData = () => {
		return printersTypicalkwhList.filter((printer: any) => printer.typicalKwh).map(
			(printerObject: {
				printer: { name: string; id: any }
				typicalKwh: number
			}) => [
				printerObject.printer.name,
				printerObject.typicalKwh,
				renderDeleteButton({printer: printerObject.printer.id, printerSettings: {typicalKwh: null}})
			]
		)
	}

	return (
		<div className="customize__table">
			<ItemGridTSX>
				<Table
					editTableStyle={'co2CalculationTable'}
					tableHead={[
						getString('PRINTER_NAME'),
						getString('TYPICAL_POWER_CONSUMPTION'),
						getString('ACTIONS')
					]}
					tableData={renderCo2MaterialData()}
				/>
			</ItemGridTSX>
		</div>
	)
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeCO2CalculationAction }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2TypicalKwhTable)
)
