import React, { ChangeEvent, FC, memo } from 'react'
import { useLocation } from 'react-router-dom'

import cx from 'classnames'

import SolutionAnalysisHeaderGetAQ from './SolutionAnalysisHeaderGetAQ'
import AdditiveMinds from 'Scenes/Components/AdditiveMinds'
import { AdditiveMindsTypes } from 'Scenes/Components/AdditiveMinds/AdditiveMindsInterface'
import DetailsPopup from 'Scenes/Components/DetailsPopup'
import Flexbox from 'Scenes/Components/FlexBox'
import GrabCadPrint from 'Scenes/Components/GrabCadPrint'
import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { LocationPartsParams } from 'Services/models/IPart'
import { IUserProvider } from 'Services/models/IUserProvider'
import {
	COMBINE_AROUND_THIS_PART,
	PART_ANALYSIS_CONFIGURE,
	WEIGHT_REDUCTION_CONFIGURATION_BUTTON
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../MainPartAnalysis.scss'
import '../SolutionAnalysis.scss'

const SolutionAnalysisHeaderGetAQTSX: any = SolutionAnalysisHeaderGetAQ

interface Props {
	setShowModal: Function
	showSolutionDetails: boolean
	configurationId: number
	showConfigure: boolean
	isNewDesign?: boolean
	hideConfigureButton?: boolean
	enableSolutionButtons: boolean
	freezeConfiguration: boolean
	leadingByUserChoice: boolean
	isLeading: boolean
	showDownloadButton: boolean
	showWeightReductionButton: boolean
	combineButtonText?: string
	partWeightReducedStlURL: string
	solution: any
	quantity: any
	canCombine?: boolean
	disableRemove?: boolean
	isCluster?: boolean
	userProviders: IUserProvider[]
	sendToLoader: boolean
	onCombineClick?: Function
	onConfigureClick: Function
	onRemoveClick: Function
	onSetLeadingClick: Function
	onGetAQClick: Function
	on3dExportClick: Function
	onPrintingOptionClick: Function
	onWeightReductionClick: Function
	onToggleGrabCad: Function
	selectedGrabCad?: boolean
	showCrabCad?: boolean
}

const renderDownloadButton = (
	showDownloadButton: boolean,
	enableSolutionButtons: boolean,
	partWeightReducedStlURL: string
) => {
	if (!showDownloadButton) {
		return <div></div>
	}
	return (
		<Button
			color="transparent"
			onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
				e.stopPropagation()
				window.open(partWeightReducedStlURL, '_self')
			}}
			className={`part-analysis-button-text-transform solution-analysis-header-text ${
				enableSolutionButtons ? 'enabled' : ''
			}`}
			disabled={!enableSolutionButtons}
		>
			<IconFactory
				iconName="download"
				className="solution-analysis-header-text-icon"
			/>
			<div className="solution-analysis-header-text-text">
				{getString('DOWNLOAD')}
			</div>
		</Button>
	)
}
const renderWeightReductionButton = (
	enableSolutionButtons: boolean,
	isWeightReductionPart: boolean,
	showWeightReductionButton: boolean,
	configurationId: number,
	onWeightReductionClick: Function
) => {
	if (
		isWeightReductionPart ||
		!showWeightReductionButton ||
		Feature.isFeatureOn(FeatureComponentId.WEIGHT_REDUCTION_OLD)
	) {
		return <div />
	}
	return (
		<Button
			id={`weight-reduction-solution-configuration-button-${configurationId}`}
			color="transparent"
			onClick={(e: any) => {
				e.stopPropagation()
				onWeightReductionClick()
			}}
			className={`part-analysis-button-text-transform solution-analysis-header-text ${
				enableSolutionButtons ? 'enabled' : ''
			}`}
			disabled={!enableSolutionButtons}
		>
			<IconFactory
				iconName="weightTransparent"
				className="solution-analysis-header-text-icon"
			/>
			<div className="solution-analysis-header-text-text">
				{WEIGHT_REDUCTION_CONFIGURATION_BUTTON}
			</div>
		</Button>
	)
}
const SolutionAnalysisHeaderButtons: FC<Props> = ({
	showSolutionDetails,
	configurationId,
	showConfigure,
	enableSolutionButtons,
	leadingByUserChoice,
	isLeading,
	solution,
	quantity,
	showDownloadButton,
	partWeightReducedStlURL,
	showWeightReductionButton,
	freezeConfiguration,
	canCombine,
	isCluster,
	userProviders,
	sendToLoader,
	onCombineClick,
	onConfigureClick,
	onRemoveClick,
	onSetLeadingClick,
	onGetAQClick,
	on3dExportClick,
	onPrintingOptionClick,
	onWeightReductionClick,
	setShowModal,
	disableRemove,
	combineButtonText,
	hideConfigureButton = false,
	isNewDesign = false,
	onToggleGrabCad,
	selectedGrabCad,
	showCrabCad
}) => {
	const configureBtnElementId = `part-analysis-configure-button_${configurationId}`
	const showConfigureButton = Feature.isFeatureOn(
		FeatureComponentId.UPDATE_CONFIGURATION
	)
	const disableConfigureButton = !Feature.isFeatureActive(
		FeatureComponentId.UPDATE_CONFIGURATION
	)
	const location = useLocation<LocationPartsParams>()
	const isWeightReductionConfiguration = location?.state?.isWeightReduction

	const showLeadingButton =
		Feature.isFeatureOn(FeatureComponentId.SHOW_LEADING_CONFIGURATION) &&
		!isWeightReductionConfiguration

	const disableConfigure =
		freezeConfiguration || !enableSolutionButtons || disableConfigureButton

	const disableLeadingButton = isLeading && !leadingByUserChoice

	return (
		<Flexbox className="solution-analysis-header-buttons-wrapper">
			{canCombine &&
				Feature.isFeatureOn(FeatureComponentId.COMBINE_AROUND_PART) && (
					<Button
						color="transparent"
						onClick={onCombineClick}
						className={`part-analysis-button-text-transform solution-analysis-header-text ${
							enableSolutionButtons ? 'enabled' : ''
						}`}
						disabled={!enableSolutionButtons}
					>
						{combineButtonText ? (
							<>
								<IconFactory
									iconName="combine"
									className="solution-analysis-header-text-icon"
								/>
								<div className="solution-analysis-header-text-text">
									{combineButtonText}
								</div>
							</>
						) : (
							<DetailsPopup
								style={{ width: '250px' }}
								isHover={enableSolutionButtons}
								data={COMBINE_AROUND_THIS_PART}
								popperDirection="top-end"
								popperClassName="details-popup-data-benefits-message"
								popperContactClassName="details-popup-data-benefits"
							>
								<IconFactory
									iconName="combine"
									className="solution-analysis-header-text-icon"
								/>
							</DetailsPopup>
						)}
					</Button>
				)}
			{!isCluster && !disableRemove && (
				<Button
					color="transparent"
					onClick={onRemoveClick}
					className={`part-analysis-button-text-transform solution-analysis-header-text ${
						enableSolutionButtons ? 'enabled' : ''
					}`}
					disabled={!enableSolutionButtons}
				>
					<IconFactory
						iconName="remove"
						className="solution-analysis-header-text-icon"
					/>
				</Button>
			)}
			{showLeadingButton && (
				<DetailsPopup
					isHover={disableLeadingButton}
					data={getString('LEADING_CONFIGURATION_BUTTON_HINT')}
					popperDirection="bottom-end"
					popperClassName="info-box-wrapper"
					popperContactClassName="info-box-data"
					managerClassName="order-7"
				>
					<Button
						data-qa="data-qa-project-leading"
						color="transparent"
						onClick={onSetLeadingClick}
						className={cx(
							'part-analysis-button-text-transform solution-analysis-header-text starred order-7',
							{
								enabled: enableSolutionButtons,
								disabled: !enableSolutionButtons || disableLeadingButton,
								saved: leadingByUserChoice
							}
						)}
						disabled={!enableSolutionButtons || disableLeadingButton}
					>
						<IconFactory
							iconName={leadingByUserChoice ? 'saved' : 'save'}
							className="solution-analysis-header-text-icon starred"
						/>
						<div className="solution-analysis-header-text-text">
							{getString('LEADING')}
						</div>
					</Button>
				</DetailsPopup>
			)}

			{renderWeightReductionButton(
				enableSolutionButtons,
				showDownloadButton,
				showWeightReductionButton,
				configurationId,
				onWeightReductionClick
			)}

			{renderDownloadButton(
				showDownloadButton,
				enableSolutionButtons,
				partWeightReducedStlURL
			)}

			<SolutionAnalysisHeaderGetAQTSX
				setShowModal={setShowModal}
				enableSolutionButtons={enableSolutionButtons}
				solution={solution}
				quantity={quantity}
				userProviders={userProviders}
				sendToLoader={sendToLoader}
				onGetAQClick={onGetAQClick}
				on3dExportClick={on3dExportClick}
				onPrintingOptionClick={onPrintingOptionClick}
				configurationId={configurationId}
				isNewDesign={isNewDesign}
			/>
			{!hideConfigureButton &&
				!isCluster &&
				showConfigureButton &&
				!isWeightReductionConfiguration && (
					<DetailsPopup
						isHover={disableConfigure}
						data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
						popperDirection="bottom"
						popperClassName="info-box-wrapper details-popup--contact-us"
						popperContactClassName="info-box-data"
					>
						<Button
							data-qa="data-qa-project-configure"
							id={configureBtnElementId}
							color="transparent"
							onClick={(e: ChangeEvent<HTMLInputElement>) =>
								onConfigureClick(e, configurationId, configureBtnElementId)
							}
							className={cx(
								'part-analysis-button-text-transform solution-analysis-header-text filled',
								{
									enabled: !disableConfigure && showSolutionDetails,
									active: !disableConfigure && showConfigure,
									disabled: disableConfigure
								}
							)}
							disabled={disableConfigure}
						>
							<IconFactory
								iconName="configure"
								className="solution-analysis-header-text-icon"
							/>
							<div className="solution-analysis-header-text-text">
								{PART_ANALYSIS_CONFIGURE}
							</div>
						</Button>
					</DetailsPopup>
				)}
			{showCrabCad && (
				<GrabCadPrint
					tooltipPosition="bottom-end"
					blockClass="part-analysis-button-text-transform solution-analysis-header-text filled grab-configuration"
					selectedGrabCad={selectedGrabCad}
					onToggleGrabCad={onToggleGrabCad}
				/>
			)}
			<AdditiveMinds className="order-8" type={AdditiveMindsTypes.button} />
		</Flexbox>
	)
}

export default memo(SolutionAnalysisHeaderButtons)
