import React, { memo } from 'react'
import { Field, reduxForm } from 'redux-form'

import { MenuItem } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import {
	capitalize,
	cloneDeep,
	forEach,
	isEmpty,
	isNil,
	isObject,
	isString,
	lowerCase,
	map
} from 'lodash'

import { materialTypes } from '../../../../Services/Constants'
import {
	MATERIAL_TABLE_INFO,
	MATERIAL_TABLE_TEXTS,
	PRINTER_COMPANY_NOT_EXIST,
	REQUIRED,
	SUBMIT,
	TABLE_DEVIATION_DATA_ERROR,
	TABLE_VALUE_DATA_ERROR,
	UNDO_CHANGES
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import { Button } from '../../../Components/thirdParty/CreativeTim/components'
import { renderCheckboxWithoutLabel } from '../AdminFields/AdminCheckbox'
import {
	detectNonNumberValue,
	renderNumberField,
	replaceNonNumber
} from '../AdminFields/AdminNumberField'
import { renderSelectFieldTouched } from '../AdminFields/AdminSelectField'
import { renderTextField } from '../AdminFields/AdminTextField'
import {
	additionalPostProcesses,
	allowedCategories,
	checkErrorValues,
	deviationPrinterMaterials,
	MAX_PERCENTAGE_STDEV,
	MIN_VALUE,
	NUMBER,
	specialMaterial,
	standardPrinterMaterials
} from './constants'

import '../adminHome.scss'

const printerMaterialTypes = ['plastic', 'metal']

const diffInPercentage = (val, stdev) => {
	return ((stdev * 100) / val).toFixed() < MAX_PERCENTAGE_STDEV
}
const checkOnEmpty = stdev => {
	return (isString(stdev) && isEmpty(stdev)) || isNil(stdev)
}

const validate = values => {
	const errors = {}
	if (isEmpty(values)) {
		return errors
	}

	forEach(checkErrorValues, item => {
		const selectedValue = values[item.name]

		if (item.checkIfEmpty && !selectedValue) {
			errors[item.name] = REQUIRED
		}

		// if we don't have val and stdev or values is not object (for old values)
		if (
			(!selectedValue || !isObject(selectedValue)) &&
			item.checkNestedElements
		) {
			errors[item.name] = TABLE_VALUE_DATA_ERROR
		}

		if (selectedValue && isObject(selectedValue) && item.checkNestedElements) {
			const { X = {}, Y = {}, Z = {} } = selectedValue
			const { val: valX, stdev: stdevX } = X
			const { val: valY, stdev: stdevY } = Y
			const { val: valZ, stdev: stdevZ } = Z

			const stdevXAllow = diffInPercentage(+valX, +stdevX)
			const stdevYAllow = diffInPercentage(+valY, +stdevY)
			const stdevZAllow = diffInPercentage(+valZ, +stdevZ)

			const checkValuesArr = [
				+valX,
				+valY,
				+valZ,
				!checkOnEmpty(stdevX),
				!checkOnEmpty(stdevY),
				!checkOnEmpty(stdevZ)
			]

			// if some is empty or 0
			if (checkValuesArr.some(std => !std)) {
				errors[item.name] = TABLE_VALUE_DATA_ERROR
			} else if (
				(stdevX && !stdevXAllow) ||
				(stdevY && !stdevYAllow) ||
				(stdevZ && !stdevZAllow)
			) {
				errors[item.name] = TABLE_DEVIATION_DATA_ERROR
			}
		}
		if (selectedValue && item.checkIfMinMax) {
			if (selectedValue > item?.max || selectedValue < item?.min) {
				errors[item.name] = getString('NUMBER_VALIDATION_REQUIRED').format(
					item?.min,
					item?.max
				)
			}
		}
	})

	return errors
}

const renderPrintingTechSelectedItems = (
	printingTechnologies,
	change,
	selectedPrinterTechnology,
	initialized
) => {
	return (
		<Field
			disabled
			name="printerTechnology"
			component={renderSelectFieldTouched}
			label="Printer Technology"
			custom={{
				value: selectedPrinterTechnology || '',
				onChange: e => change('printerTechnology', e.target.value)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TECHNOLOGY}</MenuItem>
			{printingTechnologies.map(printingTechnology => {
				return (
					<MenuItem
						key={printingTechnology.name}
						value={printingTechnology.name}
					>
						{printingTechnology.userReadableName}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderCategoryItems = (
	allCategories,
	change,
	selectedCategory,
	selectedType,
	initialized
) => {
	const filteredCategories =
		selectedType && selectedType !== 'weak'
			? allCategories.filter(category => category?.type === selectedType)
			: allCategories
	return (
		<Field
			name="Category"
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.CATEGORY}
			custom={{
				value: selectedCategory || '',
				onChange: e => change('Category', e.target.value)
			}}
			initialized={initialized}
		>
			{filteredCategories?.map(category => {
				return (
					<MenuItem key={category.name} value={category.name}>
						{category.name}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderPrintersSelectedItems = (
	allPrintersCompanies,
	change,
	selectedPrinterName,
	selectedPrinterCompany,
	printingTechnologies,
	initialized
) => {
	const selectedCompanyKey = selectedPrinterCompany || ''
	const selectedPrinters = allPrintersCompanies[selectedCompanyKey]
	const isPrinterCompanyExist = selectedCompanyKey && selectedPrinterName
	const isNotExistOrEmpty =
		!isPrinterCompanyExist && !isEmpty(selectedPrinterName)

	return (
		<div className={'values-with-deviation'}>
			<Field
				errorMessage={isNotExistOrEmpty ? PRINTER_COMPANY_NOT_EXIST : ''}
				name="printerCompany"
				component={renderSelectFieldTouched}
				label={MATERIAL_TABLE_TEXTS.PRINTER_COMPANY}
				custom={{
					value: selectedCompanyKey,
					onChange: e => {
						change('printerCompany', e.target.value)
						change('printers', null)
						change('printerTechnology', null)
						change('type', null)
					}
				}}
				initialized={initialized}
			>
				{Object.keys(allPrintersCompanies)
					?.sort((a, b) => a.localeCompare(b))
					.map(companiesName => {
						return (
							<MenuItem key={companiesName} value={companiesName}>
								{companiesName}
							</MenuItem>
						)
					})}
			</Field>
			<Field
				disabled={!selectedCompanyKey}
				name="printers"
				placeholder={selectedPrinterName}
				component={renderSelectFieldTouched}
				label={MATERIAL_TABLE_TEXTS.PRINTER_NAME}
				custom={{
					value: selectedPrinterName || '',
					onChange: e => {
						const selectedPrinter =
							selectedPrinters?.find(
								printer => printer.name === e.target.value
							) || {}
						const selectedTechnology =
							printingTechnologies?.find(
								technology => technology.name === selectedPrinter.technology
							) || {}

						change('printers', selectedPrinter.name)
						change('printerTechnology', selectedPrinter.technology || null)
						change('type', selectedTechnology.type || null)
					}
				}}
				initialized={initialized}
			>
				{isNotExistOrEmpty && (
					<MenuItem value={selectedPrinterName} disabled={true}>
						{selectedPrinterName}
					</MenuItem>
				)}
				{selectedPrinters?.map(printer => {
					return (
						<MenuItem key={printer.name} value={printer.name}>
							{printer.name}
						</MenuItem>
					)
				})}
			</Field>
		</div>
	)
}

const materialTypeChange = (e, change) => {
	change('type', e.target.value)
	change('Category', '')
}

const renderTypeSelectedItems = (selectedType, change, initialized) => {
	return (
		<Field
			disabled
			name="type"
			component={renderSelectFieldTouched}
			label={MATERIAL_TABLE_TEXTS.TYPE}
			custom={{
				value: selectedType || '',
				onChange: e => materialTypeChange(e, change)
			}}
			initialized={initialized}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.TYPE}</MenuItem>
			{printerMaterialTypes.map(printerMaterialType => {
				return (
					<MenuItem
						key={printerMaterialType}
						value={lowerCase(printerMaterialType)}
					>
						{capitalize(printerMaterialType)}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const xyzItem = (item, labelVal, onChange) => {
	return (
		<div className={'block-with-deviation__item'} key={labelVal + labelVal}>
			<div className="value-label">{labelVal}:</div>
			<TextField
				value={item?.val}
				onChange={e => {
					const value = replaceNonNumber(e.target.value)
					onChange(value, labelVal)
				}}
				onKeyDown={e => detectNonNumberValue(e) && e.preventDefault()}
				InputProps={{ inputProps: { min: MIN_VALUE } }}
			/>
			±
			<TextField
				value={item?.stdev}
				onChange={e => {
					const value = replaceNonNumber(e.target.value)
					onChange(value, labelVal, true)
				}}
				onKeyDown={e => detectNonNumberValue(e) && e.preventDefault()}
				InputProps={{ inputProps: { min: MIN_VALUE } }}
			/>
		</div>
	)
}

const renderXYZField = ({ input, label, meta: { error }, initialized }) => {
	let modifyInput = cloneDeep(input.value)

	const updateDeviationValue = (newValue, labelValue, isStd) => {
		const changedValue = {
			[labelValue]: {
				stdev: isStd ? newValue : modifyInput[labelValue]?.stdev,
				val: !isStd ? newValue : modifyInput[labelValue]?.val
			}
		}
		modifyInput = {
			...modifyInput,
			...changedValue
		}

		input.onChange(modifyInput)
	}

	return (
		<div className="block-with-deviation">
			<div className="label">
				<TextField
					name="name"
					error={initialized && !!error}
					label={label}
					helperText={initialized && error}
					disabled={true}
					FormHelperTextProps={{
						classes: { root: 'block-with-deviation--error' }
					}}
				/>
			</div>
			<div className="block-with-deviation__flex">
				{xyzItem(modifyInput.X, 'X', updateDeviationValue)}
				{xyzItem(modifyInput.Y, 'Y', updateDeviationValue)}
				{xyzItem(modifyInput.Z, 'Z', updateDeviationValue)}
			</div>
		</div>
	)
}

const renderValuesWithDeviation = (standard, initialized) => {
	return (
		<div className={'values-with-deviation'}>
			<Field
				className="admin-form-field"
				name={standard.name}
				component={renderNumberField}
				label={standard.label}
				initialized={initialized}
			/>
			±
			<Field
				className="admin-form-field"
				name={standard.name + 'Std'}
				component={renderNumberField}
				label={'Deviation'}
				initialized={initialized}
			/>
		</div>
	)
}

const getExistingValue = (
	selectedId,
	checkBoxId,
	optionalPostProcessAvailability,
	selectedPrinterTechnology
) => {
	const getPostProcess = optionalPostProcessAvailability
		? optionalPostProcessAvailability[checkBoxId]
		: {}
	const getPostProcessByTechnology = getPostProcess
		? getPostProcess[selectedPrinterTechnology]
		: {}
	const isOn = getPostProcessByTechnology
		? getPostProcessByTechnology?.materials_ids?.find(id => id === selectedId)
		: false
	return !!isOn
}

const renderPostProcesses = (
	optionalPostProcessAvailability,
	selectedPrinterTechnology,
	showMachining,
	selectedId
) => {
	return (
		<div className="checkboxes-block">
			<p>{additionalPostProcesses.label}</p>
			<div>
				{additionalPostProcesses?.checkBoxes.map(checkBox => {
					const isOn = getExistingValue(
						selectedId,
						checkBox.id,
						optionalPostProcessAvailability,
						selectedPrinterTechnology
					)
					if (checkBox?.withSomeMetal && !showMachining) {
						return <div />
					}
					return (
						<FormControlLabel
							control={
								<Field
									name={checkBox?.name}
									component={renderCheckboxWithoutLabel}
									defaultChecked={isOn || checkBox?.defaultValue}
								/>
							}
							label={checkBox?.label}
						/>
					)
				})}
			</div>
		</div>
	)
}

const renderSpecialMaterial = () => {
	return (
		<div className="checkboxes-block">
			<p>{specialMaterial.label}</p>
			<div className="special-materials">
				{specialMaterial?.checkBoxes.map(checkBox => {
					return (
						<FormControlLabel
							control={
								<Field
									name={checkBox?.name}
									component={renderCheckboxWithoutLabel}
									defaultChecked={checkBox?.defaultChecked}
								/>
							}
							label={checkBox?.label}
						/>
					)
				})}
			</div>
		</div>
	)
}

const AdminPrinterMaterialForm = props => {
	const {
		handleSubmit,
		pristine,
		reset,
		submitting,
		onSubmit,
		printingTechnologies,
		valid,
		change,
		addingNewItem,
		selectedType,
		selectedPrinterTechnology,
		userCurrencySign,
		initialized,
		allPrintersCompanies,
		selectedPrinterName,
		selectedPrinterCompany,
		selectedCategory,
		allCategories,
		optionalPostProcessAvailability,
		selectedId
	} = props

	const showMachining = allowedCategories.includes(selectedCategory)

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="admin-form">
			{map(standardPrinterMaterials, standard => {
				if (standard?.isTypeSelectedItems) {
					return (
						<div key={standard.name}>
							{renderTypeSelectedItems(selectedType, change, initialized)}
						</div>
					)
				}

				if (standard?.isTechSelectedItems) {
					return (
						<div key={standard.name}>
							{renderPrintingTechSelectedItems(
								printingTechnologies,
								change,
								selectedPrinterTechnology,
								initialized
							)}
						</div>
					)
				}

				if (standard?.isCategorySelectedItems) {
					return (
						<div key={standard.name}>
							{renderCategoryItems(
								allCategories,
								change,
								selectedCategory,
								selectedType,
								initialized
							)}
						</div>
					)
				}

				if (standard?.isPrintersSelectedItems) {
					return (
						<div key={standard.name}>
							{renderPrintersSelectedItems(
								allPrintersCompanies,
								change,
								selectedPrinterName,
								selectedPrinterCompany,
								printingTechnologies,
								initialized
							)}
						</div>
					)
				}

				if (standard?.hasStandardDeviation) {
					return (
						<div key={standard.name}>
							{renderValuesWithDeviation(standard, initialized)}
						</div>
					)
				}

				if (standard?.isMetalOnly && selectedType === materialTypes.metal) {
					return (
						<div key={standard.name}>
							<Field
								className="admin-form-field"
								name={standard.name}
								component={renderTextField}
								label={standard.label}
								initialized={initialized}
								{...standard}
							/>
						</div>
					)
				}

				return (
					<div key={standard.name}>
						<Field
							className="admin-form-field"
							name={standard?.name}
							component={
								standard?.type === NUMBER ? renderNumberField : renderTextField
							}
							label={
								standard?.withSign
									? standard?.label.format(userCurrencySign)
									: standard?.label
							}
							initialized={initialized}
							multiline={standard?.multiline}
							{...standard}
						/>
					</div>
				)
			})}

			<div className="deviation">
				{deviationPrinterMaterials.map(({ name, label }) => {
					return (
						<div key={label}>
							<Field
								className="admin-form-field"
								name={name}
								component={renderXYZField}
								label={label}
								initialized={initialized}
							/>
						</div>
					)
				})}
			</div>

			{renderSpecialMaterial()}
			{renderPostProcesses(
				optionalPostProcessAvailability,
				selectedPrinterTechnology,
				showMachining,
				selectedId
			)}

			<div className="flex-block start">
				<FormControlLabel
					disabled
					control={
						<Field
							name="heatTreatment"
							component={renderCheckboxWithoutLabel}
							defaultChecked
							disabled
						/>
					}
					label={MATERIAL_TABLE_TEXTS.HEAT_TREATED}
				/>
				<div className="flex-block">
					<Field
						className="admin-form-field"
						name="coupleId"
						component={renderTextField}
						label={MATERIAL_TABLE_TEXTS.COUPLE_ID}
						initialized={initialized}
						disabled
						iIcon={MATERIAL_TABLE_INFO.COUPLE_ID}
					/>
				</div>
			</div>
			<div>
				<Button
					size="sm"
					color="primary"
					type="submit"
					disabled={!valid || addingNewItem}
				>
					{SUBMIT}
				</Button>
				<Button
					size="sm"
					color="primary"
					type="button"
					disabled={pristine || submitting}
					onClick={reset}
				>
					{UNDO_CHANGES}
				</Button>
			</div>
		</form>
	)
}

export default memo(
	reduxForm({
		form: 'adminPrinterMaterialFormState',
		validate,
		enableReinitialize: true
	})(AdminPrinterMaterialForm)
)
