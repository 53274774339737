import Numeral from 'numeral'

import { displayInRange } from 'Services/global/calculateRange'
import { IConfiguration } from 'Services/models/IConfiguration'
import { ISolution } from 'Services/models/ISolution'

export const generateProductionParameters = (
	solution: ISolution,
	configuration: IConfiguration,
	isShowValuesInRanges: boolean = false,
	perPartCosts: Record<string, any>,
	minPartCosts: Record<string, any>,
	maxPartCosts: Record<string, any>,
	fullTrayAssumption?: boolean
) => {
	const { costDetails } = solution || {}
	const { quantity } = configuration || {}
	const { minCostDetails, maxCostDetails } = costDetails
	const threeDPrintingCostsBreakDown = costDetails?.threeDPrintingCostsBreakDown
	const isFullTrayAssumptionOn =
		threeDPrintingCostsBreakDown?.fullTrayAssumption
	const minThreeDPrintingCostsBreakDown =
		minCostDetails?.threeDPrintingCostsBreakDown
	const maxThreeDPrintingCostsBreakDown =
		maxCostDetails?.threeDPrintingCostsBreakDown

	const partsQuantity = quantity

	const {
		unusedMaterialWaste,
		totalSupportMaterial,
		totalPartMaterial,
		materialCost,
		materialCostIs,
		partWeightIs
	} = getMaterialCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges,
		configuration
	)

	const {
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		labor,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		laborTechnicianFTE
	} = getLaborCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		fullTrayAssumption,
		isShowValuesInRanges
	)

	const {
		machineCost,
		printTime,
		totalSharedMachineTime,
		buildPrepTime,
		machineCostPerHour,
		partPrintTime
	} = getMachineCost(
		threeDPrintingCostsBreakDown,
		minThreeDPrintingCostsBreakDown,
		maxThreeDPrintingCostsBreakDown,
		partsQuantity,
		isShowValuesInRanges
	)

	const { consumablesCost, operatingCost, perBuildConsumables } =
		getConsumableCost(
			threeDPrintingCostsBreakDown,
			minThreeDPrintingCostsBreakDown,
			maxThreeDPrintingCostsBreakDown,
			partsQuantity,
			isShowValuesInRanges
		)

	const { totalOrderFeesCost, totalProductionCostsPerUnit, buildPrep } =
		getFeesAndCost(
			threeDPrintingCostsBreakDown,
			minThreeDPrintingCostsBreakDown,
			maxThreeDPrintingCostsBreakDown,
			partsQuantity,
			isShowValuesInRanges,
			perPartCosts,
			minPartCosts,
			maxPartCosts
		)

	return {
		//material cost
		materialCost,
		totalPartMaterial,
		totalSupportMaterial,
		unusedMaterialWaste,
		materialCostIs,
		partWeightIs,

		//machine cost
		machineCost,
		buildPrepTime,
		printTime,
		machineCostPerHour,
		partPrintTime,
		isFullTrayAssumptionOn,
		totalSharedMachineTime,

		//consumables cost
		consumablesCost,
		operatingCost,
		perBuildConsumables,
		mandatoryPostProcessCost,
		optionalPostProcessCost,

		//labor cost
		labor,
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		laborTechnicianFTE,

		// order fees cost
		totalOrderFeesCost,

		//others
		totalProductionCostsPerUnit,
		buildPrep
	}
}

const getMaterialCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partsQuantity: number,
	isShowValuesInRanges: boolean = false,
	configuration: IConfiguration
) => {
	const unusedMaterialCost =
		threeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		threeDPrintingCostsBreakDown.unusedMaterialWaste
	const minUnusedMaterial =
		minThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		minThreeDPrintingCostsBreakDown.unusedMaterialWaste
	const maxUnusedMaterial =
		maxThreeDPrintingCostsBreakDown.totalUnusedMaterialWasteCost ??
		maxThreeDPrintingCostsBreakDown.unusedMaterialWaste
	const totalSupportMaterialCost =
		threeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		threeDPrintingCostsBreakDown.totalSupportMaterial
	const minTotalSupportMaterialCost =
		minThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		minThreeDPrintingCostsBreakDown.totalSupportMaterial
	const maxTotalSupportMaterialCost =
		maxThreeDPrintingCostsBreakDown.totalSupportMaterialCost ??
		maxThreeDPrintingCostsBreakDown.totalSupportMaterial

	let materialCost: string = Numeral(
		threeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity
	).format('0,0[.]00')
	let totalPartMaterial = Numeral(
		threeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity
	).format('0,0[.]00')
	let totalSupportMaterial = Numeral(
		totalSupportMaterialCost / partsQuantity
	).format('0,0[.]00')
	let unusedMaterialWaste = Numeral(unusedMaterialCost / partsQuantity).format(
		'0,0[.]00'
	)

	const materialCostPerCubicCM =
		threeDPrintingCostsBreakDown?.materialCostPerCubicCM ||
		configuration?.solution?.printerMaterial?.costPerCubicCM

	const materialCostIs = Numeral(
		(materialCostPerCubicCM /
			configuration?.solution?.printerMaterial?.density) *
			1000
	).format('0,0[.]00')

	const partWeightNumber = Numeral(
		configuration?.solution?.printerMaterial?.density *
			configuration?.part.volume
	).format('0,0[.]')

	const partWeightIs = parseFloat(partWeightNumber.replace(',', '.')).toFixed(2)

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		materialCost = displayInRange(
			minThreeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalMaterialCost / partsQuantity,
			materialCost,
			'0,0[.]00'
		)

		totalPartMaterial = displayInRange(
			minThreeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalPartMaterialCost / partsQuantity,
			totalPartMaterial,
			'0,0[.]00'
		)

		totalSupportMaterial = displayInRange(
			minTotalSupportMaterialCost / partsQuantity,
			maxTotalSupportMaterialCost / partsQuantity,
			totalSupportMaterial,
			'0,0[.]00'
		)
		unusedMaterialWaste = displayInRange(
			minUnusedMaterial / partsQuantity,
			maxUnusedMaterial / partsQuantity,
			unusedMaterialWaste,
			'0,0[.]00'
		)
	}

	return {
		unusedMaterialWaste,
		totalSupportMaterial,
		totalPartMaterial,
		materialCost,
		materialCostIs,
		partWeightIs
	}
}

const getMachineCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partsQuantity: number,
	isShowValuesInRanges: boolean = false
) => {
	let totalMachineTimeValue: number =
		((threeDPrintingCostsBreakDown.totalMachineTimeRequired -
			threeDPrintingCostsBreakDown.printTime) *
			threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
		partsQuantity

	let machineCost: string = Numeral(
		threeDPrintingCostsBreakDown.machineUsage / partsQuantity
	).format('0,0[.]00')
	let printTime: string = Numeral(
		(threeDPrintingCostsBreakDown.printTime *
			threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			partsQuantity
	).format('0,0[.]00')

	let totalSharedMachineTime = Numeral(totalMachineTimeValue).format('0,0[.]00')

	let buildPrepTime: string = Numeral(
		(threeDPrintingCostsBreakDown.buildPrepTime *
			threeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			partsQuantity
	).format('0,0[.]00')
	let machineCostPerHour = Numeral(
		threeDPrintingCostsBreakDown.machineCostPerHourOccupied
	).format('0,0[.]00')
	let partPrintTime = Numeral(
		threeDPrintingCostsBreakDown.printTime / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		machineCost = displayInRange(
			minThreeDPrintingCostsBreakDown.machineUsage / partsQuantity,
			maxThreeDPrintingCostsBreakDown.machineUsage / partsQuantity,
			machineCost,
			'0,0[.]00'
		)
		buildPrepTime = displayInRange(
			(minThreeDPrintingCostsBreakDown.buildPrepTime *
				minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity,
			(maxThreeDPrintingCostsBreakDown.buildPrepTime *
				maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity,
			buildPrepTime,
			'0,0[.]00'
		)
		printTime = displayInRange(
			(minThreeDPrintingCostsBreakDown.printTime *
				minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity,
			(maxThreeDPrintingCostsBreakDown.printTime *
				maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
				partsQuantity,
			printTime,
			'0,0[.]00'
		)
		machineCostPerHour = displayInRange(
			minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied,
			maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied,
			machineCostPerHour,
			'0,0[.]00'
		)
		partPrintTime = displayInRange(
			minThreeDPrintingCostsBreakDown.printTime / partsQuantity,
			maxThreeDPrintingCostsBreakDown.printTime / partsQuantity,
			partPrintTime,
			'0,0[.]00'
		)

		let minTotalMachineTimeValue =
			((minThreeDPrintingCostsBreakDown.totalMachineTimeRequired -
				minThreeDPrintingCostsBreakDown.printTime) *
				minThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			partsQuantity
		let maxTotalMachineTimeValue =
			((maxThreeDPrintingCostsBreakDown.totalMachineTimeRequired -
				maxThreeDPrintingCostsBreakDown.printTime) *
				maxThreeDPrintingCostsBreakDown.machineCostPerHourOccupied) /
			partsQuantity

		totalSharedMachineTime = displayInRange(
			minTotalMachineTimeValue,
			maxTotalMachineTimeValue,
			totalSharedMachineTime,
			'0,0[.]00'
		)
	}

	return {
		machineCost,
		printTime,
		totalSharedMachineTime,
		buildPrepTime,
		machineCostPerHour,
		partPrintTime
	}
}

const getConsumableCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partsQuantity: number,
	isShowValuesInRanges: boolean = false
) => {
	let consumablesCost: string = Numeral(
		threeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity
	).format('0,0[.]00')
	let operatingCost: string = Numeral(
		threeDPrintingCostsBreakDown.operatingCost / partsQuantity
	).format('0,0[.]00')
	let perBuildConsumables: string = Numeral(
		threeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		consumablesCost = displayInRange(
			minThreeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.buildConsumablesCost / partsQuantity,
			consumablesCost,
			'0,0[.]00'
		)
		operatingCost = displayInRange(
			minThreeDPrintingCostsBreakDown.operatingCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.operatingCost / partsQuantity,
			operatingCost,
			'0,0[.]00'
		)
		perBuildConsumables = displayInRange(
			minThreeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity,
			maxThreeDPrintingCostsBreakDown.perBuildConsumables / partsQuantity,
			perBuildConsumables,
			'0,0[.]00'
		)
	}

	return {
		consumablesCost,
		operatingCost,
		perBuildConsumables
	}
}

const getFeesAndCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partsQuantity: number,
	isShowValuesInRanges: boolean = false,
	perPartCosts: Record<string, any>,
	minPartCosts: Record<string, any>,
	maxPartCosts: Record<string, any>
) => {
	let totalOrderFeesCost: string = Numeral(
		threeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity
	).format('0,0[.]00')

	let buildPrep: string = Numeral(
		threeDPrintingCostsBreakDown.buildPrepCost / partsQuantity
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		totalOrderFeesCost = displayInRange(
			minThreeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.totalOrderFeesCost / partsQuantity,
			totalOrderFeesCost,
			'0,0[.]00'
		)

		buildPrep = displayInRange(
			minThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
			maxThreeDPrintingCostsBreakDown.buildPrep / partsQuantity,
			buildPrep,
			'0,0[.]00'
		)
	}

	let totalProductionCostsPerUnit = Numeral(
		perPartCosts.totalProductionCostsPerUnit
	).format('0,0[.]00')

	if (isShowValuesInRanges && minPartCosts && maxPartCosts) {
		totalProductionCostsPerUnit = displayInRange(
			minPartCosts?.totalProductionCostsPerUnit,
			maxPartCosts?.totalProductionCostsPerUnit,
			totalProductionCostsPerUnit,
			'0,0[.]00'
		)
	}

	return {
		totalOrderFeesCost,
		totalProductionCostsPerUnit,
		buildPrep
	}
}

const getLaborCost = (
	threeDPrintingCostsBreakDown: Record<string, any>,
	minThreeDPrintingCostsBreakDown: Record<string, any>,
	maxThreeDPrintingCostsBreakDown: Record<string, any>,
	partsQuantity: number,
	fullTrayAssumption: boolean = false,
	isShowValuesInRanges: boolean = false
) => {
	let mandatoryPostProcessCostValue =
		threeDPrintingCostsBreakDown.postProcessCost || 0

	let optionalPostProcessCostValue =
		threeDPrintingCostsBreakDown.optionalPostProcessesCost || 0

	const laborHoursBuildExchangeValue = fullTrayAssumption
		? threeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  threeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: threeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const minLaborHoursBuildExchangeValue = fullTrayAssumption
		? minThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  minThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: minThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const maxLaborHoursBuildExchangeValue = fullTrayAssumption
		? maxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost +
		  maxThreeDPrintingCostsBreakDown.totalSharedLaborTimeCost
		: maxThreeDPrintingCostsBreakDown.laborHoursBuildExchangeCost

	const laborHoursDuringPureBuildValue = fullTrayAssumption
		? threeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: threeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	const minLaborHoursDuringPureBuildValue = fullTrayAssumption
		? minThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: minThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	const maxLaborHoursDuringPureBuildValue = fullTrayAssumption
		? maxThreeDPrintingCostsBreakDown.laborHoursDuringPureBuildCost
		: maxThreeDPrintingCostsBreakDown.laborHoursDuringBuildCost

	let mandatoryPostProcessCost: string = Numeral(
		mandatoryPostProcessCostValue / partsQuantity
	).format('0,0[.]00')
	let optionalPostProcessCost: string = Numeral(
		optionalPostProcessCostValue
	).format('0,0[.]00')

	let laborHoursBuildExchange: string = Numeral(
		laborHoursBuildExchangeValue / partsQuantity
	).format('0,0[.]00')

	let laborHoursDuringPureBuild: string = Numeral(
		laborHoursDuringPureBuildValue / partsQuantity
	).format('0,0[.]00')

	let labor: string = Numeral(
		(threeDPrintingCostsBreakDown.laborCost +
			optionalPostProcessCostValue +
			mandatoryPostProcessCostValue) /
			partsQuantity
	).format('0,0[.]00')

	let laborTechnicianFTE = Numeral(
		threeDPrintingCostsBreakDown.engineerHourCost
	).format('0,0[.]00')

	if (
		isShowValuesInRanges &&
		minThreeDPrintingCostsBreakDown &&
		maxThreeDPrintingCostsBreakDown
	) {
		optionalPostProcessCost = displayInRange(
			minThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
			maxThreeDPrintingCostsBreakDown.optionalPostProcessesCost,
			optionalPostProcessCost,
			'0,0[.]00'
		)
		mandatoryPostProcessCost = displayInRange(
			minThreeDPrintingCostsBreakDown.postProcessCost / partsQuantity,
			maxThreeDPrintingCostsBreakDown.postProcessCost / partsQuantity,
			mandatoryPostProcessCost,
			'0,0[.]00'
		)

		const minLaborData =
			minThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
			(minLaborHoursDuringPureBuildValue +
				minLaborHoursBuildExchangeValue +
				minThreeDPrintingCostsBreakDown.postProcessCost) /
				partsQuantity

		const maxLaborData =
			maxThreeDPrintingCostsBreakDown.optionalPostProcessesCost +
			(maxLaborHoursDuringPureBuildValue +
				maxLaborHoursBuildExchangeValue +
				maxThreeDPrintingCostsBreakDown.postProcessCost) /
				partsQuantity

		laborHoursBuildExchange = displayInRange(
			minLaborHoursBuildExchangeValue / partsQuantity,
			maxLaborHoursBuildExchangeValue / partsQuantity,
			laborHoursBuildExchange,
			'0,0[.]00'
		)

		laborHoursDuringPureBuild = displayInRange(
			minLaborHoursDuringPureBuildValue / partsQuantity,
			maxLaborHoursDuringPureBuildValue / partsQuantity,
			laborHoursDuringPureBuild,
			'0,0[.]00'
		)

		labor = displayInRange(minLaborData, maxLaborData, labor, '0,0[.]00')
	}

	return {
		laborHoursBuildExchange,
		laborHoursDuringPureBuild,
		labor,
		mandatoryPostProcessCost,
		optionalPostProcessCost,
		laborTechnicianFTE
	}
}
