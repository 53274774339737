import { extname } from 'path'
import { parse } from 'url'

export const getExtensionFromFileName = (fileName: string): string => {
  const regex = /(?:\.([^.]+))?$/
  return (regex.exec(fileName) || '')[1] || ''
}

export const getExtensionFromSigndURL = (fileUrl: string): string => {
  const parsed = parse(fileUrl || '')
  const ext = extname(parsed?.pathname || '')
  return ext
}
