import { ChangeEvent, FC } from 'react'

import { MenuItem, Select } from '@material-ui/core'
import cx from 'classnames'
import { isEmpty } from 'lodash'

import Flexbox from 'Scenes/Components/FlexBox'
import { Material } from 'Services/models/IMaterial'
import { getString } from 'Services/Strings/StringService'

import './CastorMaterialSelector.scss'

interface IProps {
	materialCategories: any
	materialTypes: string[]
	materials: Material[]
	selectedMaterial?: any
	disabled: boolean
	onChangeMaterial: Function
	onClickRow?: Function
}

const SelectTsx: any = Select
const MenuItemTsx: any = MenuItem
const REQUIRED = getString('REQUIRED')

const CastorMaterialSelector: FC<IProps> = ({
	materialCategories,
	materialTypes,
	materials,
	selectedMaterial,
	disabled,
	onChangeMaterial
}) => {
	const renderMaterialType = (dataOptions: any = []) => {
		return dataOptions.map((data: any) => {
			return (
				<MenuItemTsx
					className={'material-selector-class menu-item'}
					key={data.id}
					value={data}
				>
					{data}
				</MenuItemTsx>
			)
		})
	}

	const renderMaterialCategory = (
		dataOptions: any = [],
		materials: any = []
	) => {
		return dataOptions
			.sort((a: any, b: any) => a.name?.localeCompare(b.name))
			.filter(
				(option: any) =>
					isEmpty(selectedMaterial) || selectedMaterial?.type === option.type
			)
			.filter(
				(category: any) =>
					materials.filter(
						(material: any) =>
							material['category'].toLowerCase() === category.name.toLowerCase()
					).length > 0
			)
			.map((data: any) => {
				return (
					<MenuItemTsx key={data.id} value={data.name}>
						{data.name}
					</MenuItemTsx>
				)
			})
	}

	const renderMaterialName = (dataOptions: any = []) => {
		return dataOptions
			.filter(
				(option: any) =>
					isEmpty(selectedMaterial) ||
					selectedMaterial?.category?.toLowerCase() ===
						option.category.toLowerCase()
			)
			.map((data: any) => {
				return (
					<MenuItemTsx key={data.id} value={data.name}>
						{data.name}
					</MenuItemTsx>
				)
			})
	}

	const returnSelectTsx = () => {
		return (
			<Flexbox>
				<SelectTsx
					className={cx('material-selector-class', {
						disabledColor: disabled && !selectedMaterial
					})}
					disabled={disabled}
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						onChangeMaterial('type', e.target.value)
					}
					value={selectedMaterial?.type || REQUIRED}
					renderValue={() => selectedMaterial?.type || REQUIRED}
					data-qa="data-qa-material-type-select"
				>
					<MenuItemTsx value="0" disabled>
						{getString('SELECT_MATERIAL_TYPE_PLACEHOLDER')}
					</MenuItemTsx>
					{materialTypes?.length ? renderMaterialType(materialTypes) : <div />}
				</SelectTsx>
				<SelectTsx
					className={cx('material-selector-class', {
						disabledColor: disabled && !selectedMaterial
					})}
					disabled={disabled}
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) => [
						onChangeMaterial('category', e.target.value)
					]}
					value={selectedMaterial?.category || REQUIRED}
					renderValue={() => selectedMaterial?.category || REQUIRED}
					data-qa="data-qa-material-category-select"
				>
					<MenuItemTsx value="0" disabled>
						{getString('SELECT_MATERIAL_CATEGORY_PLACEHOLDER')}
					</MenuItemTsx>
					{materialCategories?.length ? (
						renderMaterialCategory(materialCategories, materials)
					) : (
						<div />
					)}
				</SelectTsx>
				<SelectTsx
					className={cx('material-selector-class', {
						disabledColor: disabled && !selectedMaterial
					})}
					disabled={disabled}
					disableUnderline={false}
					onChange={(e: ChangeEvent<HTMLInputElement>) => [
						onChangeMaterial('name', e.target.value)
					]}
					value={selectedMaterial?.name || REQUIRED}
					renderValue={() => selectedMaterial?.name || REQUIRED}
					data-qa="data-qa-material-select"
				>
					<MenuItemTsx value="0" disabled>
						{getString('SELECT_MATERIAL_PLACEHOLDER')}
					</MenuItemTsx>
					{materials?.length ? renderMaterialName(materials) : <div />}
				</SelectTsx>
			</Flexbox>
		)
	}

	return <div>{returnSelectTsx()}</div>
}

export default CastorMaterialSelector
