import * as EmailValidator from 'email-validator'
import zxcvbn from 'zxcvbn'

import {
	checkUserSubscriptionAlert,
	getTechnologies
} from '../../global actions'
import {
	AUTHORIZATION_TOKEN_UPDATED,
	CHECKBOX_CLICKED,
	COMPANY_CHANGED,
	CONFIRM_RESTART_PASSWORD_EMAIL_FAILED,
	EMAIL_CHANGED,
	FORGOT_PASSWORD_CHANGED,
	FORGOT_PASSWORD_POPUP_CLOSE,
	FORGOT_PASSWORD_POPUP_OPEND,
	FORGOT_REENTER_PASSWORD_CHANGED,
	GOT_LOGIN_DATA,
	GOT_MATERIAL_TECHNOLOGIES_DATA,
	HANDLE_LOADER,
	HIDE_ALERT,
	HIDE_FORGOT_ALERT,
	HIDE_PASSWORD_REQUEST_ALERT,
	HIDE_RESET_PASSWORD_ERROR_ALERT,
	HIDE_VERIFY_USER_ALERT,
	LOGIN_STATE_CHANGED,
	LOGIN_USER,
	LOGIN_USER_FAIL,
	LOGIN_USER_SUCCESS,
	NAME_CHANGED,
	PASSWORD_CHANGED,
	REENTER_PASSWORD_CHANGED,
	REFRESH_TOKEN_UPDATED,
	REGISTER_USER,
	REGISTER_USER_FAIL,
	REGISTER_USER_SUCCESS,
	RESET_AUTH_STATE,
	RESET_PASSWORD_FAIL,
	RESET_TOKEN_SAVED,
	SEND_RESET_REQUEST_CONFIRMED,
	SEND_RESET_REQUEST_FAIL,
	SHOW_EMAIL_SENDING_SUCCESS_POPUP,
	SHOW_FORGOT_PASSWORD_STRENGTH_ALERT,
	SHOW_PASSWORD_STRENGTH_ALERT,
	SHOW_RESET_PASSWORD_SUCCESS_POP_UP
} from '../../global actions/types'
import { store } from '../../index'
import {
	USER_SUBSCRIPTION_UN_VALID,
	USER_TRIAL_SUBSCRIPTION_UN_VALID
} from '../../Services/Constants'
import {
	ADDRESS_NOT_VALID,
	COMPANY_NOT_ENTERED,
	COUNTRY_NOT_VALID,
	EMAIL_INVALID,
	EMAIL_NOT_ENTERED,
	MUST_AGREE_TO_TURMS,
	NAME_NOT_ENTERED,
	PASSWORD_INVALID,
	PASSWORD_NOT_ENTERED,
	PASSWORD_NOT_MATCHING,
	STATE_NOT_VALID
} from '../../Services/LocalError'
import {
	confirmUser,
	getUserProfileData,
	login,
	register,
	resetPassword,
	sendRestartPasswordEmail,
	sendVerifyEmail,
	simpleLogin
} from '../../Services/Network'
import { ERROR } from '../../Services/Strings'
import {
	getString,
	handleUserStrings
} from '../../Services/Strings/StringService'
import { UserFilterService } from '../../Services/UserFilterService'
import { AuthActionsServiceBuilder } from '../../themes/Builders/AuthActionsServiceBuilder'
import { US } from '../Components/CastorLocationSearchInput/CastorLocationDropdown/addressInfo/countryStateCity'
import { UserRole } from '../Home/UserRole.enum'
import { USER_HOME_ROUTE } from 'Services/Constants/RoutesConstants'
import history from 'Services/history'
import { providerTokenLogin } from 'Services/Network/integration-provider'
import { uploadProjectRoute } from 'Services/routeFuncs'
import { getUserLanguage } from 'Services/Utils/startupTools'

const authActionsService = new AuthActionsServiceBuilder()
const userFilterService = new UserFilterService()

export const emailChanged = text => {
	return {
		type: EMAIL_CHANGED,
		payload: text
	}
}

export const nameChanged = text => {
	return {
		type: NAME_CHANGED,
		payload: text
	}
}
export const companyChanged = text => {
	return {
		type: COMPANY_CHANGED,
		payload: text
	}
}

export const passwordChanged = text => {
	return {
		type: PASSWORD_CHANGED,
		payload: text
	}
}
export const checkboxClicked = checked => {
	return {
		type: CHECKBOX_CLICKED,
		payload: checked
	}
}
export const resetAuthState = () => {
	return {
		type: RESET_AUTH_STATE
	}
}
export const confirmUserAccount = token => {
	return async dispatch => {
		const response = await confirmUser(token)
		dispatch({
			type: AUTHORIZATION_TOKEN_UPDATED,
			payload: {
				token: response?.data?.access_token,
				token_type: response?.data?.token_type
			}
		})
	}
}

export const loginProviderUser = (
	token,
	providerGuid,
	language,
	third_party_id,
	destinationURL
) => {
	return async dispatch => {
		dispatch({ type: HANDLE_LOADER, payload: 1 })
		try {
			dispatch({
				type: LOGIN_USER
			})
			const {
				data: {
					access_token,
					token_type,
					email,
					userId,
					refreshToken,
					refreshTokenExpireIn
				}
			} = await providerTokenLogin(token, providerGuid, third_party_id)
			dispatch({
				type: AUTHORIZATION_TOKEN_UPDATED,
				payload: {
					token: access_token,
					token_type: token_type
				}
			})
			dispatch({
				type: REFRESH_TOKEN_UPDATED,
				payload: {
					refreshToken: refreshToken,
					token_type: token_type,
					refreshTokenExpireIn: refreshTokenExpireIn
				}
			})
			if (!language) {
				window.location.reload()
			}
			await handleUserStrings(email)
			const userProfileData = await getUserProfileData(userId)
			loginUserSuccess(dispatch, userProfileData, email)
			history.push(
				destinationURL
					? `${USER_HOME_ROUTE}${destinationURL}`
					: uploadProjectRoute()
			)
			dispatch({ type: HANDLE_LOADER, payload: -1 })
		} catch (error) {
			// in case we want to show subscription messages:
			// if (error.serverErrorCode === USER_TRIAL_SUBSCRIPTION_UN_VALID) {
			//   authActionsService.onSubscriptionLoginError(dispatch, email, true)
			// }
			// if (error.serverErrorCode === USER_SUBSCRIPTION_UN_VALID) {
			//   authActionsService.onSubscriptionLoginError(dispatch, email)
			// }
			dispatch({ type: HANDLE_LOADER, payload: -1 })
			loginUserFailed(dispatch, error)
		}
	}
}

export const loginUser = ({ email, password }, language) => {
	return async dispatch => {
		if (!email || email.length === 0) {
			loginUserFailedValidation(dispatch, EMAIL_NOT_ENTERED)
			return
		}
		if (!EmailValidator.validate(email)) {
			loginUserFailedValidation(dispatch, EMAIL_INVALID)
			return
		}
		if (!password || password.length === 0) {
			loginUserFailedValidation(dispatch, PASSWORD_NOT_ENTERED)
			return
		}
		try {
			dispatch({
				type: LOGIN_USER
			})
			const {
				data: {
					access_token,
					token_type,
					userId,
					refreshToken,
					refreshTokenExpireIn
				}
			} = await login(email, password)
			await handleUserStrings(email)
			dispatch({
				type: AUTHORIZATION_TOKEN_UPDATED,
				payload: {
					token: access_token,
					token_type: token_type
				}
			})
			dispatch({
				type: REFRESH_TOKEN_UPDATED,
				payload: {
					refreshToken: refreshToken,
					token_type: token_type,
					refreshTokenExpireIn: refreshTokenExpireIn
				}
			})
			if (!language) {
				window.location.reload()
			}
			const userProfileData = await getUserProfileData(userId)
			loginUserSuccess(dispatch, userProfileData, email)
		} catch (error) {
			if (error.serverErrorCode === USER_TRIAL_SUBSCRIPTION_UN_VALID) {
				authActionsService.onSubscriptionLoginError(dispatch, email, true)
			}
			if (error.serverErrorCode === USER_SUBSCRIPTION_UN_VALID) {
				authActionsService.onSubscriptionLoginError(dispatch, email)
			}
			loginUserFailed(dispatch, error)
		}
	}
}

export const registerUser = ({
	email,
	password,
	reenterPassword,
	name,
	company,
	agreedToTerms,
	strengthScore,
	formatted_address,
	city,
	state,
	country,
	long,
	lat,
	zip_code,
	validAddress,
	language,
	countryCode
}) => {
	return async dispatch => {
		let { isOnPrem } = store.getState().GlobalReducer
		const isNotValidCountry = isOnPrem && !country
		const isNotValidState = isOnPrem && countryCode === US && !state

		if (!email || email.length === 0) {
			registerUserFailedValidation(dispatch, EMAIL_NOT_ENTERED)
			return
		}
		if (!EmailValidator.validate(email)) {
			registerUserFailedValidation(dispatch, EMAIL_INVALID)
			return
		}
		if (!password || password.length === 0) {
			registerUserFailedValidation(dispatch, PASSWORD_NOT_ENTERED)
			return
		}
		if (strengthScore <= 1) {
			registerUserFailedValidation(dispatch, PASSWORD_INVALID)
			return
		}
		if (password !== reenterPassword) {
			registerUserFailedValidation(dispatch, PASSWORD_NOT_MATCHING)
			return
		}
		if (!name || name.length < 1) {
			registerUserFailedValidation(dispatch, NAME_NOT_ENTERED)
			return
		}

		if (!company || name.company < 1) {
			registerUserFailedValidation(dispatch, COMPANY_NOT_ENTERED)
			return
		}
		if (!validAddress || validAddress === ERROR) {
			registerUserFailedValidation(dispatch, ADDRESS_NOT_VALID)
			return
		}

		if (isNotValidCountry) {
			registerUserFailedValidation(dispatch, COUNTRY_NOT_VALID)
			return
		}

		if (isNotValidState) {
			registerUserFailedValidation(dispatch, STATE_NOT_VALID)
			return
		}

		if (!agreedToTerms) {
			registerUserFailedValidation(dispatch, MUST_AGREE_TO_TURMS)
			return
		}
		try {
			dispatch({
				type: REGISTER_USER
			})
			const userLanguage = language || getUserLanguage()

			const response = await register({
				email,
				password,
				name,
				company,
				formatted_address,
				city,
				state,
				country,
				long,
				lat,
				zip_code,
				locale: userLanguage
			})

			if (!response) {
				registerUserFailed(dispatch, getString('SOMETHING_WENT_WRONG'))
				return
			}

			if (!userLanguage) {
				window.location.reload()
			}

			const isUserVerification = response?.data?.isUserVerification

			if (isUserVerification) {
				try {
					const verifyResponse = await sendVerifyEmail(email, name)
					if (verifyResponse?.status === 200) {
						dispatch({
							type: REGISTER_USER_SUCCESS
						})
					} else {
						dispatch({
							type: REGISTER_USER_FAIL,
							payload:
								verifyResponse?.message || getString('SOMETHING_WENT_WRONG')
						})
					}
				} catch (error) {
					registerUserFailed(dispatch, error)
				}
			} else {
				const tokenData = response?.data?.tokenData
				dispatch({
					type: AUTHORIZATION_TOKEN_UPDATED,
					payload: {
						token: tokenData?.access_token,
						token_type: tokenData?.token_type
					}
				})

				dispatch({
					type: REFRESH_TOKEN_UPDATED,
					payload: {
						refreshToken: tokenData?.refreshToken,
						token_type: tokenData?.token_type,
						refreshTokenExpireIn: tokenData?.refreshTokenExpireIn
					}
				})

				await handleUserStrings(email)
				loginUserSuccess(dispatch, response, email)
			}
		} catch (error) {
			console.log(error)
			registerUserFailed(dispatch, error)
		}
	}
}

const loginUserFailed = (dispatch, error) =>
	authUserFailed(LOGIN_USER_FAIL, dispatch, error)

const registerUserFailed = (dispatch, error) =>
	authUserFailed(REGISTER_USER_FAIL, dispatch, error)

const authUserFailed = (type, dispatch, error) => {
	let message = 'Authentication failed'
	if (error?.response?.data) {
		let body = error.response.data
		message = body.message
	} else {
		if (error?.message) {
			message = error.message?.message || error.message
		} else {
			let response = error?.response
			if (!response || response?.status === 404) {
				message = 'Could not connect to server'
			}
		}
	}
	dispatch({ type, payload: message })
}

const loginUserFailedValidation = (dispatch, error) => {
	dispatch({ type: LOGIN_USER_FAIL, payload: error.message })
}
const onConfirmEmailRestartPasswordFail = (dispatch, error) => {
	dispatch({
		type: CONFIRM_RESTART_PASSWORD_EMAIL_FAILED,
		payload: error.message
	})
}

const registerUserFailedValidation = (dispatch, error) => {
	dispatch({ type: REGISTER_USER_FAIL, payload: error.message })
}

export const loginUserSuccess = (dispatch, response, email) => {
	const {
		filters,
		userFilters: userFilter,
		printerMaterials,
		printingTechnology,
		printers,
		userMaterials,
		userDetails,
		userSubscriptionDetails,
		roles
	} = response?.data?.generalData

	const isLightUser = roles.includes(UserRole.LIGHT)
	const printerTechnologiesIds = printerMaterials.map(item => [
		item.printerTechnology,
		item.name,
		item.id
	])
	const printingTechnologies = printingTechnology
	const printerTechnologies = getTechnologies(
		printerMaterials,
		printingTechnologies
	)
	const { partsCreditExpired, trial, subscriptionExpired } =
		userSubscriptionDetails

	const isAdmin =
		roles.length > 0 ? roles.includes(UserRole.SUPER_ADMIN) : undefined

	dispatch(
		checkUserSubscriptionAlert(
			isAdmin,
			isLightUser,
			subscriptionExpired,
			partsCreditExpired,
			trial,
			userDetails.email
		)
	)

	dispatch({
		type: GOT_MATERIAL_TECHNOLOGIES_DATA,
		payload: {
			printerTechnologies,
			printerTechnologiesIds,
			printingTechnologies,
			printers,
			userMaterials
		}
	})

	dispatch({
		type: LOGIN_USER_SUCCESS,
		payload: response.data.user
	})

	dispatch({
		type: LOGIN_STATE_CHANGED,
		payload: true
	})

	const { userFilters } = userFilterService.getUserFilters(filters, userFilter)

	dispatch({
		type: GOT_LOGIN_DATA,
		payload: {
			isLightUser,
			userFilters,
			generalData: response.data.generalData
		}
	})
	//TODO: get token from the response and save it in auth reducer
}

export const reenterPasswordChanged = (strengthScore, password, text) => {
	return {
		type: REENTER_PASSWORD_CHANGED,
		payload: {
			text,
			reenterPasswordValid:
				strengthScore > 1 && text.length > 0 && text === password,
			reenterPasswordInValid:
				strengthScore > 1 && text.length > 0 && text !== password
		}
	}
}
const calculateStrengthForForgotPassword = password => {
	if (!password || password.length === 0) {
		return null
	} else {
		return zxcvbn(password).score
	}
}
export const forgotPasswordChanged = text => {
	const strengthScore = calculateStrengthForForgotPassword(text)
	return {
		type: FORGOT_PASSWORD_CHANGED,
		payload: {
			newPassword: text,
			strengthScore
		}
	}
}
export const saveResetToken = token => {
	return {
		type: RESET_TOKEN_SAVED,
		payload: token
	}
}
export const forgotReenterPasswordChanged = (strengthScore, password, text) => {
	return {
		type: FORGOT_REENTER_PASSWORD_CHANGED,
		payload: {
			text,
			forgotReenterPasswordValid:
				strengthScore > 1 && text.length > 0 && text === password,
			forgotReenterPasswordInValid:
				strengthScore > 1 && text.length > 0 && text !== password
		}
	}
}
export const forgotPasswordClicked = clicked => {
	return {
		type: FORGOT_PASSWORD_POPUP_OPEND,
		payload: clicked
	}
}
export const forgotPasswordOnClose = () => {
	return {
		type: FORGOT_PASSWORD_POPUP_CLOSE
	}
}
const emailValidation = (email, dispatch) => {
	if (!email || email.length === 0) {
		onConfirmEmailRestartPasswordFail(dispatch, EMAIL_NOT_ENTERED)
		return
	}
	if (!EmailValidator.validate(email)) {
		onConfirmEmailRestartPasswordFail(dispatch, EMAIL_INVALID)
		return
	}
}
export const sendResetEmailConfirmed = email => {
	return async dispatch => {
		try {
			emailValidation(email, dispatch)
			const res = await sendRestartPasswordEmail(email)
			if (!res?.data?.userExist) {
				resetPasswordEmailRequestFailed(dispatch, {
					message: 'Email does not exist in the system'
				})
				return
			}
			resetPasswordEmailRequestSuccess(dispatch)
		} catch (error) {
			resetPasswordEmailRequestFailed(dispatch, error)
		}
	}
}
export const sendResetPasswordToServer = ({
	forgotPassword,
	forgotReenterPassword,
	strengthScore,
	resetToken
}) => {
	return async dispatch => {
		try {
			if (!forgotPassword || forgotPassword.length === 0) {
				registerUserFailedValidation(dispatch, PASSWORD_NOT_ENTERED)
				return
			}

			if (strengthScore <= 1) {
				registerUserFailedValidation(dispatch, PASSWORD_INVALID)
				return
			}

			if (forgotPassword !== forgotReenterPassword) {
				registerUserFailedValidation(dispatch, PASSWORD_NOT_MATCHING)
				return
			}

			await resetPassword(forgotPassword, resetToken)
			resetPasswordSucceeded(dispatch)
		} catch (error) {
			resetPasswordFailed(dispatch, error)
		}
	}
}
export const hidePasswordStrengthAlert = () => {
	return {
		type: HIDE_ALERT
	}
}
export const hideVerifyUserAlert = () => {
	return {
		type: HIDE_VERIFY_USER_ALERT
	}
}
export const hideResetPasswordRequestErrorAlert = () => {
	return {
		type: HIDE_PASSWORD_REQUEST_ALERT
	}
}

export const showPasswordStrengthAlert = () => {
	return {
		type: SHOW_PASSWORD_STRENGTH_ALERT
	}
}
export const hideForgotPasswordStrengthAlert = () => {
	return {
		type: HIDE_FORGOT_ALERT
	}
}
export const hideResetPasswordErrorAlert = () => {
	return {
		type: HIDE_RESET_PASSWORD_ERROR_ALERT
	}
}

export const showForgotPasswordStrengthAlert = () => {
	return {
		type: SHOW_FORGOT_PASSWORD_STRENGTH_ALERT
	}
}

const resetPasswordEmailRequestFailed = (dispatch, error) => {
	authUserFailed(SEND_RESET_REQUEST_FAIL, dispatch, error)
}
const resetPasswordEmailRequestSuccess = dispatch => {
	dispatch({
		type: SHOW_EMAIL_SENDING_SUCCESS_POPUP
	})
}
export const resetPasswordRequestConfirm = dispatch => {
	return {
		type: SEND_RESET_REQUEST_CONFIRMED
	}
}
const resetPasswordFailed = (dispatch, error) => {
	authUserFailed(RESET_PASSWORD_FAIL, dispatch, error)
}
const resetPasswordSucceeded = dispatch => {
	dispatch({ type: SHOW_RESET_PASSWORD_SUCCESS_POP_UP })
}

export const simpleLoginUser = async (dispatch, email) => {
	const user = await simpleLogin(email)
	if (!user) {
		return
	}
	const tokenData = user?.data
	dispatch({
		type: AUTHORIZATION_TOKEN_UPDATED,
		payload: {
			token: tokenData?.access_token,
			token_type: tokenData?.token_type
		}
	})
	dispatch({
		type: REFRESH_TOKEN_UPDATED,
		payload: {
			refreshToken: tokenData?.refreshToken,
			token_type: tokenData?.token_type,
			refreshTokenExpireIn: tokenData?.refreshTokenExpireIn
		}
	})
}
