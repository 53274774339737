import React, { FC, memo } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import cx from 'classnames'

import CastorCheckbox from 'Scenes/Components/CastorCheckbox'
import { onChangeCategory } from 'Scenes/Home/Customize/CustomizeAnalysisCost/CustomizeAnalysisCostActions'
import { ICostAnalysisParam } from 'Services/models/ICostAnalysisParameter'
import { getString } from 'Services/Strings/StringService'

import './index.scss'

const CustomizeAnalysisCostCheckbox: FC<ICostAnalysisParam> = ({
	category,
	editable,
	isOn,
	parentCategory,
	name,
	cost,
	explanation
}) => {
	const dispatch = useDispatch()
	const { isError } = useSelector(
		(state: RootStateOrAny) => state.CustomizeAnalysisCostReducer
	)

	return (
		<div
			key={category}
			className={cx('cost-analysis-item', {
				disabled: !editable,
				['sub-item']: !!parentCategory,
				['parent-item']: !parentCategory,
				['error-item']: isError
			})}
			onClick={() => editable && dispatch(onChangeCategory(category, !isOn))}
		>
			<CastorCheckbox
				className="cost-analysis-item__checkbox"
				checked={isOn}
				disabled={!editable}
			/>
			<div className="name">{getString(name)}</div>
			<div className="cost">${cost}</div>
			<div className="explanation">{getString(explanation)}</div>
		</div>
	)
}
export default memo(CustomizeAnalysisCostCheckbox)
