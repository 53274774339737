import {
  GET_ADMIN_PRINTER_NAMES_SUCCESS,
  GET_ADMIN_PRINTER_NAMES_GOT_ERROR,
  GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED,
  ADMIN_PRINTER_NAME_DELETED,
  ADMIN_PRINTER_NAME_UPDATED,
  ADMIN_PRINTER_NAME_ADDED,
  ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED,
  ADMIN_PRINTER_NAMES_SELECTED_CLEARED,
  GET_ADMIN_PRINTER_NAMES
} from '../../../../global actions/types'

const INITIAL_STATE = {
  printerNames: [],
  totalPrinterNames: 0,
  printerNamesTableData: [],
  showNoPrinterNamesAlert: false,
  pageNumber: 1,
  isLastPage: false,
  NumOfPrinterNamesSeen: 0,
  showPagination: false,
  limitPrinterNames: 50,
  showAdminPrinterNamesAlert: false,
  selectedEditPrinterName: null,
  printerNameAdded: false,
  searchPhrase: '',
  loading: false
}
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ADMIN_PRINTER_NAMES:
      return { ...state, loading: true }
    case GET_ADMIN_PRINTER_NAMES_SUCCESS:
      const { data, pageNumber } = action.payload,
        { printerNames, totalPrinterNames } = data,
        NumOfPrinterNamesSeen =
          (pageNumber - 1) * state.limitPrinterNames + printerNames.length,
        isLastPage = NumOfPrinterNamesSeen >= totalPrinterNames
      return {
        ...state,
        printerNames,
        totalPrinterNames,
        printerNamesTableData: printerNames.map(printerName =>
          Object.values(printerName)
        ),
        showNoPrinterNamesAlert: !printerNames.length,
        pageNumber,
        NumOfPrinterNamesSeen,
        isLastPage,
        showPagination: !(isLastPage && pageNumber === 1),
        showAdminPrinterNamesAlert: false,
        loading: false
      }
    case ADMIN_PRINTER_NAME_UPDATED:
      const updatedPrinterName = action.payload
      return {
        ...state,
        printerNames: state.printerNames.map(printerName =>
          printerName.id === updatedPrinterName.id
            ? updatedPrinterName
            : printerName
        )
      }
    case GET_ADMIN_PRINTER_NAMES_GOT_ERROR:
      return {
        ...state,
        showAdminPrinterNamesAlert: true,
        loading: false
      }
    case GET_ADMIN_PRINTER_NAMES_EDIT_PRINTER_NAME_SELECTED:
      return {
        ...state,
        selectedEditPrinterName: action.payload,
        printerNameAdded: false
      }
    case ADMIN_PRINTER_NAME_DELETED:
      const id = action.payload
      return {
        ...state,
        printerNames: state.printerNames.filter(
          printerName => printerName.id !== id
        )
      }
    case ADMIN_PRINTER_NAME_ADDED:
      const newPrinterName = action.payload
      return {
        ...state,
        printerNames: [...state.printerNames, newPrinterName],
        printerNameAdded: true
      }
    case ADMIN_PRINTER_NAME_SEARCH_PHRASE_CHANGED:
      const searchPhrase = action.payload
      return {
        ...state,
        searchPhrase
      }
    case ADMIN_PRINTER_NAMES_SELECTED_CLEARED:
      return {
        ...state,
        selectedEditPrinterName: null
      }
    default:
      return state
  }
}
