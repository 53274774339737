import { Field, reduxForm } from 'redux-form'

import { FormHelperText, MenuItem } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import SelectField from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { capitalize } from 'lodash'

import {
	materialTypes,
	temperatureUnits
} from '../../../../../Services/Constants'
import {
	MATERIAL_TABLE_TEXTS,
	REQUIRED,
	USER_MATERIAL_TEMPERATURE_UNIT
} from '../../../../../Services/Strings'
import { getString } from '../../../../../Services/Strings/StringService'
import ButtonWithLoader from '../../../../Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'

import '../CustomizeUserMaterials.css'

const density = { min: 0.1, max: 10 }
const surfaceFinishMethods = { rz: 'rz', ra: 'ra' }

const validate = values => {
	const errors = {}
	if (!values.name || !values.name.trim()) {
		errors.name = REQUIRED
	}
	if (!values.category) {
		errors.category = REQUIRED
	}
	if (!values.density) {
		errors.density = REQUIRED
	}
	if (
		values.density &&
		(+values.density < density.min || +values.density > density.max)
	) {
		errors.density = getString('NUMBER_VALIDATION_REQUIRED').format(
			density.min,
			density.max
		)
	}
	if (!values.type) {
		errors.type = REQUIRED
	}
	if (!values.ultimateTensileStrength) {
		errors.ultimateTensileStrength = REQUIRED
	}
	if (!values.percentElongationAtBreak) {
		errors.percentElongationAtBreak = REQUIRED
	}
	if (!values.youngsModulus) {
		errors.youngsModulus = REQUIRED
	}
	if (values.youngsModulus && +values.youngsModulus === 0) {
		errors.youngsModulus = getString('CO2_PER_KG_DATA_VALIDATION')
	}
	return errors
}

const renderSelectField = ({
	label,
	meta: { touched, error },
	children,
	custom,
	className
}) => {
	return (
		<FormControl className={`${className} customize-form-field`}>
			<InputLabel error={touched && error}>{label}</InputLabel>
			<SelectField
				floatingLabelText={label}
				error={touched && error}
				children={children}
				{...custom}
			/>
			{error ? (
				<FormHelperText error={touched && error}>{error}</FormHelperText>
			) : (
				<div />
			)}
		</FormControl>
	)
}

const materialTypeChange = (
	e,
	change,
	onMaterialTypeChange,
	allMaterialCategories
) => {
	change('type', e.target.value)
	onMaterialTypeChange(e.target.value, allMaterialCategories)
	change('category', '')
}

const renderTypeSelectedItems = (
	selectedType,
	change,
	onMaterialTypeChange,
	allMaterialCategories,
	materialTypes
) => {
	return (
		<Field
			name="type"
			component={renderSelectField}
			label={MATERIAL_TABLE_TEXTS.TYPE}
			custom={{
				value: selectedType || '',
				onChange: e =>
					materialTypeChange(
						e,
						change,
						onMaterialTypeChange,
						allMaterialCategories
					)
			}}
		>
			<MenuItem className="customize-form-dropdown-item" disabled>
				{MATERIAL_TABLE_TEXTS.TYPE}
			</MenuItem>
			{Object.values(materialTypes).map(materialType => {
				return (
					<MenuItem
						className="customize-form-dropdown-item"
						key={materialType}
						value={materialType}
					>
						{materialType}
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderCategorySelectedItems = (
	materialCategories,
	selectedCategory,
	change
) => {
	if (!materialCategories.length) {
		return <div />
	}

	return (
		<Field
			name="category"
			component={renderSelectField}
			label={MATERIAL_TABLE_TEXTS.CATEGORY}
			custom={{
				value: capitalize(selectedCategory) || '',
				onChange: e => change('category', e.target.value)
			}}
		>
			<MenuItem disabled>{MATERIAL_TABLE_TEXTS.CATEGORY}</MenuItem>
			{materialCategories.map(materialCategory => (
				<MenuItem
					key={materialCategory.id}
					value={capitalize(materialCategory.name)}
				>
					{materialCategory.name}
				</MenuItem>
			))}
		</Field>
	)
}

const renderTemperatureUnitsSelectedItems = (
	selectedTemperatureUnit,
	change
) => {
	return (
		<Field
			name="temperatureUnit"
			component={renderSelectField}
			label={getString('USER_MATERIAL_UNITS')}
			custom={{
				value: selectedTemperatureUnit || '',
				onChange: e => change('temperatureUnit', e.target.value)
			}}
		>
			<MenuItem disabled>{USER_MATERIAL_TEMPERATURE_UNIT}</MenuItem>
			{Object.values(temperatureUnits).map(temperatureUnit => {
				return (
					<MenuItem key={temperatureUnit} value={temperatureUnit}>
						{temperatureUnit}°
					</MenuItem>
				)
			})}
		</Field>
	)
}

const renderTextField = ({
	input,
	label,
	InputProps,
	meta: { touched, error },
	...custom
}) => (
	<TextField
		label={label}
		hintText={label}
		floatingLabelText={label}
		error={touched && error}
		helperText={error}
		{...input}
		{...custom}
		InputProps={InputProps}
	/>
)

const NewUserMaterialForm = ({
	handleSubmit,
	onSubmit,
	valid,
	materialCategories,
	allMaterialCategories,
	selectedType,
	selectedCategory,
	selectedTemperatureUnit,
	change,
	onMaterialTypeChange,
	loading,
	userCurrencySign
}) => {
	if (materialCategories.length === 1) {
		change('category', capitalize(materialCategories[0].name))
	}
	return (
		<form className="customize-form">
			<div>
				<Field
					className="customize-form-field"
					name="name"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.NAME}
				/>
			</div>

			<div>
				{renderTypeSelectedItems(
					selectedType,
					change,
					onMaterialTypeChange,
					allMaterialCategories,
					materialTypes
				)}
			</div>

			<div>
				{renderCategorySelectedItems(
					materialCategories,
					selectedCategory,
					change
				)}
			</div>

			<div>
				<Field
					className="customize-form-field"
					name="density"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.DENCITY}
					type="number"
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			<div>
				<Field
					className="customize-form-field"
					name="ultimateTensileStrength"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.TENSILE_STRENGTH}
					type="number"
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			<div>
				<Field
					className="customize-form-field"
					name="percentElongationAtBreak"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.ELONGATION}
					type="number"
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			<div>
				<Field
					className="customize-form-field"
					name="youngsModulus"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YOUNGS_MODULUS}
					type="number"
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			<div>
				<Field
					className="customize-form-field"
					name="yieldStrengthMPa"
					component={renderTextField}
					label={MATERIAL_TABLE_TEXTS.YIELD_STRENGTH}
					type="number"
					InputProps={{ inputProps: { min: 0, step: 0.01 } }}
				/>
			</div>

			{selectedType === materialTypes.plastic ? (
				<div className="form-multiple-fields">
					<div className="form-field-in-multiple first">
						<Field
							className="customize-form-field"
							name="maximumServiceTemperature"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.SERVICE_TEMPERATURE}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.01 } }}
						/>
					</div>
					<div className="form-field-in-multiple last">
						{renderTemperatureUnitsSelectedItems(
							selectedTemperatureUnit,
							change
						)}
					</div>
				</div>
			) : (
				<div />
			)}

			{selectedType === materialTypes.metal ? (
				<>
					<div>
						<Field
							className="customize-form-field"
							name="thermalConductivity"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.THERMAL_CONDUCTIVITY}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.01 } }}
						/>
					</div>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.CNC"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CNC.format(userCurrencySign)}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.Cast"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CAST.format(userCurrencySign)}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
				</>
			) : (
				<div />
			)}

			{selectedType === materialTypes.plastic ? (
				<>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.Mold"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_MOLD.format(userCurrencySign)}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
					<div>
						<Field
							className="customize-form-field"
							name="traditionalCost.CNC"
							component={renderTextField}
							label={MATERIAL_TABLE_TEXTS.COST_CNC.format(userCurrencySign)}
							type="number"
							InputProps={{ inputProps: { min: 0, step: 0.0001 } }}
						/>
					</div>
				</>
			) : (
				<div />
			)}
			<Flexbox
				alignItems="center"
				alignSelf="flex-end"
				justifyContent="space-between"
			>
				<ButtonWithLoader
					loading={loading}
					disabled={!valid}
					onClick={handleSubmit(onSubmit)}
					className="customize-form-submit"
				>
					{getString('SUBMIT')}
				</ButtonWithLoader>
			</Flexbox>
		</form>
	)
}

export default reduxForm({
	form: 'customizeNewMaterialFormState',
	validate,
	enableReinitialize: true
})(NewUserMaterialForm)
