import React, { FC, memo } from 'react'
import { RootStateOrAny, useSelector } from 'react-redux'

import { ProjectStatusPartsType } from 'Scenes/Home/NewUploadProject/UploadingProject.enum'
import { getUploadingStatus } from 'Scenes/Home/NewUploadProject/UploadProjectActionService'
import { getString } from 'Services/Strings/StringService'

const StatusMessage: FC = () => {
	const {
		inProgressStatus,
		statusPartsType,
		numberOfPartsAnalyzed,
		numberOfTotalPartsAnalyzed,
		advancedFeaturesStatus
	} = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})

	const noPartsInAnalyze =
		!numberOfPartsAnalyzed && !numberOfTotalPartsAnalyzed

	const currentStatus = getUploadingStatus(
		inProgressStatus,
		advancedFeaturesStatus
	)

	if (statusPartsType === ProjectStatusPartsType.SINGLE_PART) {
		return <span className="loading-text">{currentStatus}</span>
	}

	if (
		!noPartsInAnalyze &&
		statusPartsType === ProjectStatusPartsType.MULTIPLE_PART
	) {
		return (
			<span className="loading-text">
				{getString('INITIAL_ANALYSIS_OUT_OF_PARTS').format(
					numberOfPartsAnalyzed,
					numberOfTotalPartsAnalyzed
				)}
			</span>
		)
	}

	return (
		<span className="loading-text">
			{getString('PROJECT_PENDING_SUBTITLE')}
		</span>
	)
}

export default memo(StatusMessage)
