import { getString } from '../../../../Services/Strings/StringService'

export const TEXT = 'text'
export const NUMBER = 'number'

export const formFields = [
	{
		name: 'name',
		label: getString('PRINTER_TECHNOLOGY_NAME'),
		type: TEXT,
		isToggle: false
	},
	{
		name: 'description',
		label: getString('PRINTER_TECHNOLOGY_DESCRIPTION'),
		type: TEXT,
		isToggle: false
	},
	{
		name: 'canRecycle',
		label: getString('PRINTER_TECHNOLOGY_RECYCLE'),
		type: NUMBER,
		isToggle: true
	},
	{
		name: 'trayDensity',
		label: getString('PRINTER_TECHNOLOGY_TRAY_DENSITY'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'canStackModelsVertically',
		label: getString('PRINTER_TECHNOLOGY_STACK_MODELS'),
		type: NUMBER,
		isToggle: true
	},
	{
		name: 'machineWarmupTime',
		label: getString('PRINTER_TECHNOLOGY_WARMUP_TIME'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineCooldownTime',
		label: getString('PRINTER_TECHNOLOGY_COOLDOWN_TIME'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineSetup',
		label: getString('PRINTER_TECHNOLOGY_SETUP_TIME'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineRemoval',
		label: getString('PRINTER_TECHNOLOGY_REMOVAL_TIME'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'supportMaterialAsPercentOfPartMass',
		label: getString('PRINTER_TECHNOLOGY_PERCENT_PART'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'recyclingFraction',
		label: getString('PRINTER_TECHNOLOGY_RECYCLING_FRACTION'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'supportRemovalTimePerSqrCM',
		label: getString('PRINTER_TECHNOLOGY_SUPPORT_REMOVAL'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machiningIncluded',
		label: getString('PRINTER_TECHNOLOGY_MACHINE_INCLUDED'),
		type: NUMBER,
		isToggle: true
	},
	{
		name: 'costOfCapital',
		label: getString('PRINTER_TECHNOLOGY_COST_CAPITAL'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineEconomicLife',
		label: getString('PRINTER_TECHNOLOGY_MACHINE_ECONOMIC'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'infrastructureCost',
		label: getString('PRINTER_TECHNOLOGY_INFRASTRUCTURE'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineUpTime',
		label: getString('PRINTER_TECHNOLOGY_MACHINE_UP'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'machineMaintenanceTime',
		label: getString('PRINTER_TECHNOLOGY_MAINTENANCE'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'additionalOperationCost',
		label: getString('PRINTER_TECHNOLOGY_ADDITIONAL'),
		type: NUMBER,
		isToggle: false
	},
	{
		name: 'isUseSupport',
		label: getString('PRINTER_TECHNOLOGY_USE_SUPPORT'),
		type: NUMBER,
		isToggle: true
	}
]

export const checkErrorValues = [
	{ name: 'name', checkIfNull: true },
	{ name: 'canRecycle', checkIfNull: true },
	{
		name: 'trayDensity',
		checkIfNull: false,
		checkIfMinMax: true,
		min: 0,
		max: 1
	},
	{ name: 'machineCooldownTime', checkIfNull: true },
	{ name: 'machineSetup', checkIfNull: true },
	{ name: 'machineRemoval', checkIfNull: true },
	{ name: 'supportMaterialAsPercentOfPartMass', checkIfNull: true },
	{ name: 'recyclingFraction', checkIfNull: true },
	{ name: 'supportRemovalTimePerSqrCM', checkIfNull: true },
	{ name: 'costOfCapital', checkIfNull: true },
	{ name: 'machineEconomicLife', checkIfNull: true },
	{ name: 'infrastructureCost', checkIfNull: true },
	{ name: 'machineUpTime', checkIfNull: true },
	{ name: 'additionalOperationCost', checkIfNull: true },
	{ name: 'isUseSupport', checkIfNull: true }
]
