import { cloneDeep } from 'lodash'

import {
	ADMIN_GET_SMTP_MAIL,
	ADMIN_GET_SMTP_MAIL_ERROR,
	ADMIN_GET_SMTP_MAIL_LOADING,
	ADMIN_SMTP_MAIL_DATA_CHANGE,
	ADMIN_UPDATE_SMTP_MAIL,
	ADMIN_VERIFY_PROGRESS_SMTP_MAIL,
	ADMIN_VERIFY_SMTP_MAIL
} from './AdminSettingsTypes'
import { Action } from 'global actions/ActionModels'
import {
	ADMIN_SETINGS_PRIORITIES_TOGGLED,
	ADMIN_SETINGS_SETUPED,
	ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED
} from 'global actions/types'
import { prepareEmailData } from 'Scenes/admin/AdminHome/AdminSettings/AdminSettingsService'

export class AdminSettingsInitialState {
	readonly isRelativeToOriginalMaterial: boolean = false
	readonly singletonButtonLoading: boolean = false
	readonly mailConfigurations: any = {}
	readonly mailData: any = []
	readonly mailError: boolean = false
	readonly mailLoading: boolean = false
	readonly mailVerifiedLoading: boolean = false
	readonly mailVerified: boolean | null = null
}

const initialState: AdminSettingsInitialState = new AdminSettingsInitialState()

const adminSettingsReducer = (
	state = initialState,
	{ type, payload }: Action<any>
) => {
	switch (type) {
		case ADMIN_SETINGS_SETUPED:
			const { isRelativeToOriginalMaterial } = payload
			return { ...state, isRelativeToOriginalMaterial }
		case ADMIN_SETINGS_PRIORITIES_TOGGLED: {
			const { isRelativeToOriginalMaterial } = payload
			return { ...state, isRelativeToOriginalMaterial }
		}
		case ADMIN_SETTINGS_SINGLETONS_LOADER_TOGGLED:
			return { ...state, singletonButtonLoading: !state.singletonButtonLoading }
		case ADMIN_GET_SMTP_MAIL_LOADING: {
			return { ...state, mailLoading: payload }
		}
		case ADMIN_SMTP_MAIL_DATA_CHANGE: {
			const { name, value } = payload
			const updatedValue = cloneDeep(state.mailData)
			const findIdx = updatedValue.findIndex((el: any) => el.key === name)
			updatedValue[findIdx].value = value
			updatedValue[findIdx].error = !value

			return { ...state, mailData: updatedValue }
		}
		case ADMIN_GET_SMTP_MAIL_ERROR: {
			return { ...state, mailError: payload }
		}
		case ADMIN_UPDATE_SMTP_MAIL:
		case ADMIN_GET_SMTP_MAIL: {
			const { mailConfigurations } = payload
			const mailData = prepareEmailData(mailConfigurations)
			return { ...state, mailConfigurations, mailData }
		}
		case ADMIN_VERIFY_PROGRESS_SMTP_MAIL: {
			return { ...state, mailVerifiedLoading: payload }
		}
		case ADMIN_VERIFY_SMTP_MAIL: {
			return { ...state, mailVerified: payload }
		}
		default:
			return state
	}
}

export default adminSettingsReducer