import React, { FC, memo } from 'react'

import MultiCheckboxFilter from './MultiCheckboxFilter'
import TextFieldFilter from './TextFieldFilter'
import { IFilterData } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsInterface'
import { IPrintingTechnology } from 'Services/models/IPrintingTechnology'

enum FilterType {
	Boolean = 'boolean',
	Array = 'array'
}

interface FilterInputProps {
	id: string | number
	filterData: IFilterData
	printingTechnologies: IPrintingTechnology[]
}

const FilterInput: FC<FilterInputProps> = ({
	id,
	filterData,
	printingTechnologies
}) => {
	switch (filterData.type.trim()) {
		case FilterType.Boolean:
			return <></>
		case FilterType.Array:
			return (
				<div className="filter-fields--widget--dropdown-field">
					<MultiCheckboxFilter
						id={id}
						filter={filterData}
						printingTechnologies={printingTechnologies}
					/>
				</div>
			)

		default:
			return <TextFieldFilter id={id} filterData={filterData} />
	}
}
export default memo(FilterInput)
