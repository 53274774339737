import React, { FC, memo } from 'react'

import CastorWallThicknessDemand from '../CastorWallThicknessDemand/CastorWallThicknessDemand'
import DataTableButton from './DataTableButton'
import DataTableButtonWithIcon from './DataTableButtonWithIcon'
import DataTableButtonWithLoader from './DataTableButtonWithLoader'
import DataTableButtonWithPopup from './DataTableButtonWithPopup'
import DataTableButtonWithPopupAndIcon from './DataTableButtonWithPopupAndIcon'
import DataTableDetailedField from './DataTableDetailedField'
import DataTableDropdown from './DataTableDropdown'
import DataTableIconHeader from './DataTableIconHeader'
import DataTableIconInfo from './DataTableIconInfo'
import DataTableInputNumber from './DataTableInputNumber'
import DataTableInputText from './DataTableInputText'
import DataTableLongTextField from './DataTableLongTextField'
import DataTableOpacityTextField from './DataTableOpacityTextField'
import DataTableRadioButtonWithColor from './DataTableRadioButtonWithColor'
import DataTableTextField from './DataTableTextField'
import DataTableTextHeader from './DataTableTextHeader'
import DataTableTextHeaderWithDescription from './DataTableTextHeaderWithDescription'
import DataTableTextWithDescription from './DataTableTextWithDescription'
import DataTableTextWithIcon from './DataTableTextWithIcon'
import DataTableTextWithIconButton from './DataTableTextWithIconButton'
import DataTableTextWithIconLink from './DataTableTextWithIconLink'
import DataTableTextWithSD from './DataTableTextWithSD'
import DataTableTextWithXYZ from './DataTableTextWithXYZ'
import DataTableTransparentButtonWithLoader from './DataTableTransparentButtonWithLoader'
import { DataTableFieldType, IDataTableField } from './IDataTableField'

const WallThicknessDemand: any = CastorWallThicknessDemand

interface IProps {
	dataTableField: IDataTableField
	className?: string
}

const DataTableFieldFactory: FC<IProps> = ({
	dataTableField: { text, data, type, truncated, nestedDataTableFields },
	className = ''
}) => {
	const renderComponent = () => {
		switch (type) {
			case DataTableFieldType.RadioButtonWithColor:
				return (
					<DataTableRadioButtonWithColor
						data={data}
						wrapperClassName={className}
					/>
				)
			case DataTableFieldType.Text:
				return (
					<DataTableTextField text={text} data={data} className={className} />
				)
			case DataTableFieldType.Header:
				return (
					<DataTableTextHeader
						text={text}
						headerClassName={className}
						data={data}
					/>
				)
			case DataTableFieldType.HeaderWithDescription:
				return (
					<DataTableTextHeaderWithDescription
						text={text}
						data={data}
						headerClassName={className}
					/>
				)
			case DataTableFieldType.Detailed:
				return <DataTableDetailedField text={text} data={data} />
			case DataTableFieldType.HeaderIcon:
				return <DataTableIconHeader headerClassName={className} data={data} />
			case DataTableFieldType.IconInfo:
				return <DataTableIconInfo headerClassName={className} data={data} />
			case DataTableFieldType.OpacityText:
				return <DataTableOpacityTextField text={text} className={className} />
			case DataTableFieldType.TextWithStandardDeviation:
				return (
					<DataTableTextWithSD text={text} data={data} className={className} />
				)
			case DataTableFieldType.TextWithXYZDeviation:
				return <DataTableTextWithXYZ data={data} className={className} />
			case DataTableFieldType.TextWithIcon:
				return (
					<DataTableTextWithIcon
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.TextWithIconButton:
				return (
					<DataTableTextWithIconButton
						text={text}
						data={data}
						className={className}
					/>
				)

			case DataTableFieldType.TextWithIconLink:
				return (
					<DataTableTextWithIconLink
						text={text}
						data={data}
						className={className}
					/>
				)

			case DataTableFieldType.TextWithIconWarn:
				return (
					<DataTableTextWithIcon
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.Button:
				return <DataTableButton text={text} data={data} className={className} />
			case DataTableFieldType.Buttons:
				return (
					<div className={data.className}>
						{nestedDataTableFields?.map(field => (
							<DataTableFieldFactory
								dataTableField={field}
								key={nestedDataTableFields.findIndex(f => f === field)}
							/>
						))}
					</div>
				)
			case DataTableFieldType.ButtonWithIcon:
				return (
					<DataTableButtonWithIcon
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.ButtonWithLoader:
				return (
					<DataTableButtonWithLoader
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.ButtonWithPopup:
				return (
					<DataTableButtonWithPopup
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.ButtonWithIconAndPopup:
				return (
					<DataTableButtonWithPopupAndIcon
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.TransparentButtonWithLoader:
				return (
					<DataTableTransparentButtonWithLoader
						text={text}
						data={data}
						className={className}
					/>
				)
			case DataTableFieldType.LongText:
				return (
					<DataTableLongTextField
						text={text}
						data={data}
						className={className}
						isTruncated={truncated}
					/>
				)
			case DataTableFieldType.WallThicknessButton:
				return (
					<WallThicknessDemand
						text={text}
						color="transparent"
						wrapperClassName="data-table-button-wrapper"
					/>
				)
			case DataTableFieldType.DataTableInputText:
				return (
					<DataTableInputText text={text} data={data} className={className} />
				)
			case DataTableFieldType.DataTableInputNumber:
				return (
					<DataTableInputNumber text={text} data={data} className={className} />
				)
			case DataTableFieldType.DataTableDropdown:
				return (
					<DataTableDropdown text={text} data={data} className={className} />
				)
			case DataTableFieldType.DataTableTextWithDescription:
				return (
					<DataTableTextWithDescription
						text={text}
						data={data}
						className={className}
					/>
				)
			default:
				break
		}
	}

	return <>{renderComponent()}</>
}

export default memo(DataTableFieldFactory)
