import appStyle from '../../assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx'
import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom'

import { withStyles } from '@material-ui/core'
import cx from 'classnames'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'

import {
	drawToggledUser,
	onRouteChange,
	setupUserHome
} from '../../global actions/index'
import { UPLOAD_ROUTE } from '../../Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from '../../Services/models/Features'
import { homePathRoute, onboardingWizardRoute } from '../../Services/routeFuncs'
import { getTheme } from '../../themes/getTheme'
import { CastorBannerType } from '../Components/CastorBanner/CastorBannerType.enum'
import MyMuiTheme from '../Components/MyMuiTheme'
import {
	Header,
	Sidebar
} from '../Components/thirdParty/CreativeTim/components'
import PageLoader from '../Loader/PageLoader'
import { UserRole } from './UserRole.enum.ts'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import { pageBackground } from 'Services/colors'
import { onboardingPermittedRoutes } from 'Services/Constants.js'

import './UserHome.scss'

const { logo, cardBoxColor } = getTheme()
const partUrl = ['part', 'project']

class UserHome extends Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	}

	handleDrawerToggle = () => {
		this.props.drawToggledUser()
	}

	componentDidMount() {
		const { setupUserHome, features, roles } = this.props
		if (features.length > 10 && !roles.includes(UserRole.LIGHT)) {
			setupUserHome(features)
		}
	}

	filterRoutes = (flatRoutes, props) => {
		return (
			flatRoutes
				.filter(({ path, removeFromCrumbs }) => {
					return props.match.path.includes(path) && !removeFromCrumbs
				})
				// Swap out any dynamic routes with their param values.
				// E.g. "/pizza/:pizzaId" will become "/pizza/1"
				.map(({ path, ...rest }) => {
					path = Object.keys(props.match.params).length
						? Object.keys(props.match.params).reduce(
								(path, param) =>
									path.replace(`:${param}`, props.match.params[param]),
								path
						  )
						: path

					const getRoute = flatRoutes?.find(route => route.path === path)
					return {
						...rest,
						path,
						name: getRoute?.name || rest?.name || ''
					}
				})
		)
	}

	componentDidUpdate() {
		const {
			setupUserHome,
			features,
			projects,
			setupCalled,
			onboardingCompleted,
			history
		} = this.props
		const onboardingWizardIsOn = Feature.isFeatureOn(
			FeatureComponentId.ONBOARDING_WIZARD
		)
		if (!projects.length && features.length > 10 && !setupCalled) {
			setupUserHome(features)
		}
		if (
			onboardingWizardIsOn &&
			!onboardingCompleted &&
			!onboardingPermittedRoutes.some(route =>
				window.location.pathname.includes(route)
			)
		) {
			history.push(onboardingWizardRoute())
		}
	}

	renderMainPage() {
		const {
			classes,
			crumbs,
			onRouteChange,
			flatRoutes,
			history,
			roles,
			userName,
			showSideBar,
			onboardingCompleted,
			...rest
		} = this.props
		const lightUserMultipleUploads = Feature.isFeatureOn(
			FeatureComponentId.LIGHT_USER_MULTIPLE_UPLOADS
		)
		const personalizedLightUser =
			roles.includes(UserRole.LIGHT) && userName && lightUserMultipleUploads
		const isPartRoute = partUrl.find(element => {
			return history.location.pathname.includes(element)
		})

		// show sidebar
		const sideBarAndMenu =
			showSideBar &&
			((!!isPartRoute &&
				Feature.isFeatureOn(FeatureComponentId.PART_PAGE_SIDE_BAR_AND_MENU)) ||
				(Feature.isFeatureOn(FeatureComponentId.SIDE_BAR_AND_MENU) &&
					!this.props.parentProps.location.state?.showCastorLightWarning))

		const mainPanel =
			classes.mainPanel +
			' ' +
			cx({
				[classes.mainPanelSidebarMini]: this.props.miniActive,
				[classes.mainPanelWithPerfectScrollbar]:
					navigator.platform.indexOf('Win') > -1,
				withoutMenu: !sideBarAndMenu,
				disableBackground: true,
				'main-panel-home': true
			})

		// avoid call function inside the render
		const componentWithCrumbs = (props, component) => {
			const routeCrumbs = this.filterRoutes(flatRoutes, props)
			if (!isEqual(routeCrumbs, crumbs)) {
				onRouteChange(routeCrumbs)
			}

			return component
		}

		let mainPage = ''
		if (!this.props.loading) {
			const showContactUs = Feature.isFeatureOn(
				FeatureComponentId.SHOW_CONTACT_US_BANNER
			)

			const showTrial =
				this.props.showTrialBanner &&
				((this.props.type === CastorBannerType.CONTACT_US && showContactUs) ||
					this.props.type !== CastorBannerType.CONTACT_US)

			const uploadProjectPageRedirection = Feature.isFeatureOn(
				FeatureComponentId.UPLOAD_PROJECT_PAGE_REDIRECTION
			)

			mainPage = (
				<MyMuiTheme>
					{sideBarAndMenu && (
						<ErrorBoundary errorClass="sidebar" extra="UserSidebar">
							<Sidebar
								isTrial={this.props.showTrialBanner}
								routes={this.props.routes}
								href={homePathRoute(UPLOAD_ROUTE)}
								logo={logo}
								handleDrawerToggle={this.handleDrawerToggle.bind(this)}
								color={cardBoxColor || 'green'}
								{...rest}
								location={this.props.parentProps.location}
								open={this.props.mobileOpen}
								personalizedLightUser={personalizedLightUser}
								clickableLogo={uploadProjectPageRedirection}
								onboardingCompleted={onboardingCompleted}
							/>
						</ErrorBoundary>
					)}
					<div
						className={mainPanel}
						style={{
							marginTop: showTrial ? 64 : 0
						}}
					>
						{sideBarAndMenu && (
							<Header
								width="50%"
								handleDrawerToggle={this.handleDrawerToggle.bind(this)}
								{...rest}
								location={this.props.parentProps.location}
							/>
						)}
						<div
							style={{ backgroundColor: pageBackground }}
							className={cx(styles.container, 'main-content')}
						>
							<div style={this.props.loaderCounter > 0 ? { opacity: 0 } : {}}>
								{this.props.routes.map((route, index) => {
									const { component: Component, isInHome, path, exact } = route
									if (isInHome) {
										return (
											<Route
												key={index}
												path={path}
												render={props =>
													componentWithCrumbs(props, <Component {...props} />)
												}
												exact={exact}
											/>
										)
									}
									return <Fragment key={index} />
								})}
							</div>
						</div>
					</div>
				</MyMuiTheme>
			)
		}
		return mainPage
	}

	render() {
		const darkBackground = Feature.isFeatureOn(
			FeatureComponentId.DARK_BACKGROUND
		)
		return (
			<div>
				<PageLoader />
				{darkBackground && <div className="dark-background" />}
				{this.renderMainPage()}
			</div>
		)
	}
}

const styles = {
	mainPanel: {
		paddingLeft: '300px',
		marginLeft: 'auto',
		marginRight: 'auto',
		'@media (min-width: 768px)': {
			paddingLeft: '15px'
		}
	}
}

UserHome.propTypes = {
	classes: PropTypes.object.isRequired
}
const mapStateToProps = ({
	userHome,
	classes,
	location,
	GlobalReducer: { loaderCounter },
	CastorBannerReducer: { show, type },
	user: { features, roles, userDetails, onboardingCompleted }
}) => {
	return {
		classes,
		location,
		loaderCounter,
		showTrialBanner: show,
		type,
		features,
		roles,
		userName: userDetails.name,
		onboardingCompleted,
		...userHome
	}
}
export default withRouter(
	connect(mapStateToProps, { setupUserHome, drawToggledUser, onRouteChange })(
		withStyles(appStyle)(UserHome)
	)
)
