import { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { formValueSelector } from 'redux-form'

import * as AdminPrinterMaterialsActions from './AdminPrinterMaterialsActions'
import { adminPrinterMaterialsRoute } from '../../../../Services/routeFuncs'
import { ADMIN_PRINTER_MATERIALS_NEW } from '../../../../Services/Strings'
import CastorForm from '../../../Components/CastorForm/CastorForm'
import NavBarAndMaterial from '../../../Components/NavBarAndMaterial'
import AdminPrinterMaterialForm from './AdminPrinterMaterialForm'

class NewAdminPrinterMaterial extends Component {
	componentDidMount() {
		this.setupAdminPrinters()
	}

	setupAdminPrinters = () => {
		this.props.setupAdminPrinters()
	}

	onSubmit = data => {
		this.props.createNewPrinterMaterial(data)
	}

	renderFormContent = () => {
		const {
			printingTechnologies,
			addingNewItem,
			selectedType,
			selectedPrinterTechnology,
			allPrintersCompanies,
			selectedPrinterName,
			selectedPrinterCompany,
			allCategories,
			selectedCategory,
			selectedNewPrinterMaterial,
			selectedId
		} = this.props
		return (
			<AdminPrinterMaterialForm
				selectedId={selectedId}
				initialValues={selectedNewPrinterMaterial}
				allPrintersCompanies={allPrintersCompanies}
				allCategories={allCategories}
				printingTechnologies={printingTechnologies}
				selectedPrinterName={selectedPrinterName}
				selectedCategory={selectedCategory}
				selectedPrinterCompany={selectedPrinterCompany}
				onSubmit={this.onSubmit}
				addingNewItem={addingNewItem}
				selectedType={selectedType}
				selectedPrinterTechnology={selectedPrinterTechnology}
				userCurrencySign={'$'}
			/>
		)
	}

	render() {
		const { printerMaterialAdded, pageNumber } = this.props
		if (printerMaterialAdded) {
			return <Redirect to={adminPrinterMaterialsRoute(pageNumber)} />
		}
		return (
			<NavBarAndMaterial title={ADMIN_PRINTER_MATERIALS_NEW}>
				<CastorForm
					formTitle={ADMIN_PRINTER_MATERIALS_NEW}
					content={this.renderFormContent()}
				/>
			</NavBarAndMaterial>
		)
	}
}

const selector = formValueSelector('adminPrinterMaterialFormState')

const mapStateToProps = state => {
	const {
		AdminPrinterMaterialsReducer: {
			printerMaterialAdded,
			pageNumber,
			addingNewItem,
			allPrintersCompanies,
			allCategories,
			selectedNewPrinterMaterial
		},
		user: { printingTechnologies }
	} = state
	return {
		printerMaterialAdded,
		pageNumber,
		printingTechnologies,
		addingNewItem,
		allPrintersCompanies,
		allCategories,
		selectedNewPrinterMaterial,
		selectedType: selector(state, 'type'),
		selectedPrinterTechnology: selector(state, 'printerTechnology'),
		selectedPrinterName: selector(state, 'printers'),
		selectedPrinterCompany: selector(state, 'printerCompany'),
		selectedCategory: selector(state, 'Category'),
		selectedId: selector(state, 'id')
	}
}

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ ...AdminPrinterMaterialsActions }, dispatch)
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NewAdminPrinterMaterial)
