import {
  USER_MATERIALS_USER_ID_SELECTED,
  USER_MATERIALS_RECIEVED,
  USER_MATERIALS_USER_MATERIALS_FORMATED,
  USER_MATERIALS_MATERIAL_REMOVED,
  USER_MATERIALS_MATERIAL_SUBMITED,
  USER_MATERIALS_MATERIAL_ADDED,
  USER_MATERIALS_MATERIAL_ADDED_TO_USER,
  USER_MATERIALS_MATERIAL_ADD_FAILED,
  NEW_MATERIAL_FORM_LOADED,
  USER_MATERIALS_DELETE_CLICKED,
  USER_MATERIALS_DELETE_CANCELED,
  USER_MATERIALS_MATERIAL_TYPE_CHANGED
} from '../../../../global actions/types'

const INITIAL_STATE = {
  userMaterials: [],
  userMaterialsLoading: false,
  userId: 0,
  materialAdded: false,
  showDeleteAlert: false,
  itemToDelete: null,
  materialCategoriesToShow: []
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_MATERIALS_MATERIAL_SUBMITED:
      return {
        ...state,
        userMaterialsLoading: true
      }
    case USER_MATERIALS_MATERIAL_ADDED_TO_USER:
      return {
        ...state,
        userMaterials: action.payload
      }
    case USER_MATERIALS_MATERIAL_ADDED:
      return {
        ...state,
        userMaterialsLoading: false,
        materialAdded: true,
        materialCategoriesToShow: []
      }
    case USER_MATERIALS_MATERIAL_ADD_FAILED:
      return {
        ...state,
        userMaterialsLoading: false
      }
    case USER_MATERIALS_MATERIAL_REMOVED:
      return {
        ...state,
        userMaterials: action.payload
      }
    case USER_MATERIALS_RECIEVED:
      return {
        ...state,
        userMaterials: action.payload,
        materialAdded: false
      }
    case USER_MATERIALS_USER_ID_SELECTED:
      return {
        ...state,
        userId: action.payload
      }
    case USER_MATERIALS_USER_MATERIALS_FORMATED:
      return {
        ...state,
        userMaterials: [],
        userId: 0,
        materialAdded: false
      }
    case NEW_MATERIAL_FORM_LOADED:
      return {
        ...state,
        materialAdded: false
      }
    case USER_MATERIALS_DELETE_CLICKED:
      return {
        ...state,
        showDeleteAlert: true,
        itemToDelete: action.payload
      }
    case USER_MATERIALS_DELETE_CANCELED:
      return {
        ...state,
        showDeleteAlert: false,
        itemToDelete: null
      }
    case USER_MATERIALS_MATERIAL_TYPE_CHANGED:
      const { selectedType, materialCategories } = action.payload
      return {
        ...state,
        materialCategoriesToShow: materialCategories.filter(
          materialCategory => materialCategory.type === selectedType
        )
      }
    default:
      return state
  }
}
