import { Dispatch } from 'react'
import { AnyAction } from 'redux'

import {
	CUSTOMIZE_MATERIAL_COST_ADDED,
	CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED,
	CUSTOMIZE_MATERIAL_COST_DELETED,
	CUSTOMIZE_MATERIAL_NAME_CHANGED,
	CUSTOMIZE_MATERIAL_PRICE_CHANGED,
	CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED,
	CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED,
	CUSTOMIZE_PRINTER_MATERIAL_SETUP,
	CUSTOMIZE_PRINTER_TECH_CHANGED,
	EDITABLE_MATERIAL_COSTS_CHANGE,
	HANDLE_NOTIFICATION,
	MATERIAL_COST_ALERT_UPDATED,
	SETUP_MATERIAL_COSTS
} from '../../../../global actions/types'
import {
	deleteCustomizationMaterialPriceList,
	updateCustomizationMaterialPriceList
} from '../../../../Services/Network'
import {
	ISimpleConfigurationCompany,
	SimpleConfigurationSelectorService
} from '../../../Components/SimpleConfigurationSelector/SimpleConfigurationSelectorService'
import { getMaterialCostInputErrorMessage } from '../CustomizeCostAndLeadSettings/CostAndLeadService'
import { ValidationStatus } from '../CustomizeCostAndLeadSettings/CustomizePrinterProps/CustomizePrinterCostsTypes'
import { IMaterialCost } from './CustomizeMaterialCostTypes'
import { DEFAULT_VALUE } from 'Services/Constants'
import { Printer } from 'Services/models/IPrinter'
import { PrinterMaterial } from 'Services/models/IPrinterMaterial'
import {
	CONFIGURATION_CALCULATION_ERROR,
	COST_AND_LEAD_BUTTON_ACTIONS,
	SHOW_NOTIFICATION
} from 'Services/Strings'

let simpleConfigurationSelectorService =
	new SimpleConfigurationSelectorService()

export const onPrinterTechSelectorChange = (value: string) => {
	return {
		type: CUSTOMIZE_PRINTER_TECH_CHANGED,
		payload: value
	}
}

export const onMaterialNameSelectorChange = (value: string) => {
	return {
		type: CUSTOMIZE_MATERIAL_NAME_CHANGED,
		payload: value
	}
}

export const onMaterialPriceChange = (value: string) => {
	return {
		type: CUSTOMIZE_MATERIAL_PRICE_CHANGED,
		payload: value
	}
}

export const onMaterialCostAdd = (
	materialPrice: string,
	selectedMaterialName: string,
	selectedMaterial: PrinterMaterial | string,
	editMode: boolean
) => {
	if (materialPrice && selectedMaterialName && selectedMaterial) {
		return async (dispatch: Dispatch<AnyAction>) => {
			if (!editMode) {
				dispatch({
					type: CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED,
					payload: true
				})
			}

			const materialIds = [
				typeof selectedMaterial === 'string'
					? selectedMaterial
					: selectedMaterial?.id
			]

			try {
				const materialPriceResponse =
					await updateCustomizationMaterialPriceList(materialIds, materialPrice)
				const materialPriceData = materialPriceResponse.data
				dispatch({
					type: CUSTOMIZE_MATERIAL_COST_ADDED,
					payload: {
						materialCosts: materialPriceData.priceList
					}
				})
				dispatch({
					type: CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED,
					payload: false
				})
				dispatch({
					type: MATERIAL_COST_ALERT_UPDATED,
					payload: false
				})
			} catch (error) {
				dispatch({
					type: CUSTOMIZE_MATERIAL_COST_BUTTON_LOADER_UPDATED,
					payload: false
				})
				dispatch({
					type: MATERIAL_COST_ALERT_UPDATED,
					payload: false
				})
				console.log(error)
			}
		}
	} else {
		if (!editMode) {
			return {
				type: MATERIAL_COST_ALERT_UPDATED,
				payload: true
			}
		} else {
			return {
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: CONFIGURATION_CALCULATION_ERROR
				}
			}
		}
	}
}

export const deleteMaterialCostItem = (itemToDelete: IMaterialCost) => {
	const materialId = itemToDelete.materialId
	return async (dispatch: Dispatch<AnyAction>) => {
		try {
			const materialPriceResponse = await deleteCustomizationMaterialPriceList([
				materialId
			])
			const materialPriceData = materialPriceResponse.data
			dispatch({
				type: CUSTOMIZE_MATERIAL_COST_DELETED,
				payload: { materialCosts: materialPriceData.priceList }
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const setupPrinterMaterialPropsData = (
	printersFullData: ISimpleConfigurationCompany[]
) => {
	simpleConfigurationSelectorService = new SimpleConfigurationSelectorService(
		printersFullData,
		false,
		true
	)
	const printerCompanies =
		simpleConfigurationSelectorService.getCompaniesNamesList()

	return {
		type: CUSTOMIZE_PRINTER_MATERIAL_SETUP,
		payload: { printerCompanies }
	}
}

export const onPrinterMaterialCompanyChange = (printerCompany: string) => {
	const printerNames =
		simpleConfigurationSelectorService.getPrintersNamesList(printerCompany)

	return {
		type: CUSTOMIZE_PRINTER_MATERIAL_COMPANY_CHANGED,
		payload: { printerNames, printerCompany }
	}
}

export const onPrinterMaterialNameChange = (selectedPrinter: Printer) => {
	return {
		type: CUSTOMIZE_PRINTER_MATERIAL_PRINTER_SELECTED,
		payload: { selectedPrinter }
	}
}

export const setupMaterialCostsTable = (
	materialCostsData: Record<string, any>,
	inHousePrintersData: Record<string, any>
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const editableMaterialCostsList: IMaterialCost[] = []

		inHousePrintersData?.forEach((inHousePrinter: Record<string, any>) => {
			inHousePrinter.materials.reduce(
				(acc: IMaterialCost[], inHousePrinterMaterial: PrinterMaterial) => {
					const customizedCostsForInHousePrinterMaterial =
						materialCostsData?.find(
							(materialCost: Record<string, any>) =>
								materialCost.printer_material.id === inHousePrinterMaterial.id
						)

					const materialCost = {
						materialPrice:
							customizedCostsForInHousePrinterMaterial?.price || '',
						printerName: inHousePrinter.name,
						printerCompany: inHousePrinter.company,
						materialName: inHousePrinterMaterial.name,
						weightUnit: 'KG',
						materialId: inHousePrinterMaterial.id as string,
						edit: false,
						valueLabel: !customizedCostsForInHousePrinterMaterial?.price
							? DEFAULT_VALUE
							: '',
						validationStatus: '',
						validationMessage: ''
					}
					acc.push(materialCost)
					return acc
				},
				editableMaterialCostsList
			)
		})

		const deletableMaterialCostsList = materialCostsData.reduce(
			(acc: IMaterialCost[], item: Record<string, any>) => {
				if (
					!inHousePrintersData?.some((inHousePrinter: Record<string, any>) =>
						inHousePrinter.materials.some(
							(material: PrinterMaterial) =>
								material.id === item.printer_material.id
						)
					)
				) {
					const materialCost = {
						materialPrice: item.price,
						printerName: item.printer_material.printers.name,
						printerCompany: item.printer_material.printers.company,
						materialName: item.printer_material.name,
						weightUnit: 'KG',
						materialId: item.printer_material.id
					}
					acc.push(materialCost)
				}
				return acc
			},
			[] as IMaterialCost[]
		)

		dispatch({
			type: SETUP_MATERIAL_COSTS,
			payload: { editableMaterialCostsList, deletableMaterialCostsList }
		})
	}
}

export const onMaterialCostChange = (
	materialId: string,
	value: string,
	editableMaterialCostsList: IMaterialCost[]
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const updatedEditableMaterialCostsList = [...editableMaterialCostsList]
		const materialIndex = editableMaterialCostsList.findIndex(
			materialCost => materialCost.materialId === materialId
		)
		if (materialIndex >= 0) {
			updatedEditableMaterialCostsList[materialIndex].materialPrice = value
			updatedEditableMaterialCostsList[materialIndex].validationMessage =
				getMaterialCostInputErrorMessage(value)
			updatedEditableMaterialCostsList[materialIndex].validationStatus =
				updatedEditableMaterialCostsList[materialIndex].validationMessage
					? ValidationStatus.error
					: value
					? ValidationStatus.success
					: ''
		}

		dispatch({
			type: EDITABLE_MATERIAL_COSTS_CHANGE,
			payload: { editableMaterialCostsList: updatedEditableMaterialCostsList }
		})
	}
}

export const onMaterialCostEditClick = (
	item: IMaterialCost,
	editableMaterialCostsList: IMaterialCost[],
	editMode: boolean
) => {
	return (dispatch: Dispatch<AnyAction>) => {
		const updatedEditableMaterialCostsList = [...editableMaterialCostsList]
		const materialIndex = editableMaterialCostsList.findIndex(
			materialCost => materialCost.materialId === item.materialId
		)
		updatedEditableMaterialCostsList[materialIndex].edit = editMode

		if (!editMode) {
			updatedEditableMaterialCostsList[materialIndex].materialPrice = ''
			updatedEditableMaterialCostsList[materialIndex].validationStatus = ''
			updatedEditableMaterialCostsList[materialIndex].validationMessage = ''
		}
		dispatch({
			type: EDITABLE_MATERIAL_COSTS_CHANGE,
			payload: { editableMaterialCostsList: updatedEditableMaterialCostsList }
		})
	}
}

export const onMaterialCostActionClicked = (
	clickType: keyof typeof COST_AND_LEAD_BUTTON_ACTIONS,
	item: IMaterialCost,
	editableMaterialCostsList: IMaterialCost[]
) => {
	switch (clickType) {
		case COST_AND_LEAD_BUTTON_ACTIONS.DEFAULT:
			return deleteMaterialCostItem(item)

		case COST_AND_LEAD_BUTTON_ACTIONS.EDIT:
			return onMaterialCostEditClick(item, editableMaterialCostsList, true)

		case COST_AND_LEAD_BUTTON_ACTIONS.CANCEL:
			return onMaterialCostEditClick(item, editableMaterialCostsList, false)

		case COST_AND_LEAD_BUTTON_ACTIONS.APPLY:
			return onMaterialCostAdd(
				item.materialPrice,
				item.materialName,
				item.materialId,
				true
			)
		default:
			break
	}
}
