import {
  WALL_THICKNESS_CLICKED,
  WALL_THICKNESS_ALERT_CANCELED,
  WALL_THICKNESS_INPUT_CHANGED,
  WALL_THICKNESS_UPDATED,
  HANDLE_NOTIFICATION,
  WALL_THICKNESS_POLLER_FINISHED,
  PART_ANALYSIS_3D_MODAL_CLOSED,
  WALL_THICKNESS_CONFIRMED,
  PART_ANALYSIS_WALL_THICKNESS_UPDATED
} from '../../../global actions/types'
import {
  SHOW_NOTIFICATION,
  WALL_THICKNESS_NOTIFICATION_SUCCEDED,
  POLLER_REACHED_TIMEOUT,
  WALL_THICKNESS_NOTIFICATION_TOO_LONG,
  WALL_THICKNESS_NOTIFICATION_FAILED
} from '../../../Services/Strings'
import { CastorWallThicknessDemandService } from './CastorWallThicknessDemandService'
import { getPartSolutions } from '../../../Services/Network/PartAnalysisNetwork'
import { getCustomizationSettings } from '../../../Services/Network'
import {
  SOLUTIONS_FETCHED_FINISHED,
  SOLUTIONS_FETCHED_FORMATED,
  SOLUTION_SETUPED
} from '../../../global actions/types/partAnalysisTypes'

let service = new CastorWallThicknessDemandService()

export const onWallThicknessDemandClick = () => {
    return dispatch => {
      dispatch({
        type: WALL_THICKNESS_CLICKED
      })
      dispatch({
        type: PART_ANALYSIS_3D_MODAL_CLOSED
      })
    }
  },
  onAlertCancel = () => {
    return {
      type: WALL_THICKNESS_ALERT_CANCELED
    }
  },
  onInputChange = e => {
    return {
      type: WALL_THICKNESS_INPUT_CHANGED,
      payload: e.target.value
    }
  },
  onAlertConfirm = (customWallThickness, partId) => {
    return async dispatch => {
      try {
        dispatch({
          type: WALL_THICKNESS_CONFIRMED
        })
        dispatch({
          type: SOLUTIONS_FETCHED_FORMATED
        })
        service = new CastorWallThicknessDemandService(partId)
        await service.updateWallThickness(customWallThickness)

        dispatch({
          type: WALL_THICKNESS_UPDATED
        })

        await service.handlePoller()

        const response = await getPartSolutions(partId, true)
        const settingsResponse = await getCustomizationSettings()

        const { configurations } = response.data
        
        const {
          defaultSettings = {}
        } = settingsResponse?.data
        
        const { drawingCostPercentage } = defaultSettings
        
        dispatch({
          type: SOLUTIONS_FETCHED_FINISHED,
          payload: {
            ...response.data,
            drawingCostPercentage,
            partId
          }
        })

        configurations.forEach(configuration => {
          dispatch({
            type: SOLUTION_SETUPED,
            payload: { id: configuration.id }
          })
        })

        dispatch({
          type: PART_ANALYSIS_WALL_THICKNESS_UPDATED,
          payload: response.data.part
        })
        dispatch({
          type: WALL_THICKNESS_POLLER_FINISHED
        })
        dispatch({
          type: HANDLE_NOTIFICATION,
          payload: {
            notificationType: SHOW_NOTIFICATION.SUCCESS,
            notificationMessage: WALL_THICKNESS_NOTIFICATION_SUCCEDED
          }
        })
      } catch (error) {
        console.error(error)
        dispatch({
          type: WALL_THICKNESS_POLLER_FINISHED
        })
        if (error === POLLER_REACHED_TIMEOUT) {
          dispatch({
            type: HANDLE_NOTIFICATION,
            payload: {
              notificationType: SHOW_NOTIFICATION.INFO,
              notificationMessage: WALL_THICKNESS_NOTIFICATION_TOO_LONG
            }
          })
        } else {
          dispatch({
            type: HANDLE_NOTIFICATION,
            payload: {
              notificationType: SHOW_NOTIFICATION.ERROR,
              notificationMessage: WALL_THICKNESS_NOTIFICATION_FAILED
            }
          })
        }
      }
    }
  },
  clearPoller = () => {
    service.clearPoller()
    return {
      type: WALL_THICKNESS_POLLER_FINISHED
    }
  }
