import { IFileWithMaterialAndQuantity } from 'Services/models/IUploadProject'

export const quantityOptions = ['10', '100', '1000']

export const initialState: IFileWithMaterialAndQuantity = {
	name: '',
	materialType: '',
	materialCategory: '',
	material: '',
	id: 0,
	quantity: null
}