import React from 'react'

import { Part, PartStatus } from '../../../Services/models/IPart'
import { AlertType } from '../../Components/alerts/AlertTypes'
import CastorAlert from '../../Components/alerts/CastorAlert'
import { CastorPartBenefits } from '../../Components/CastorPartBenefits/CastorPartBenefits'
import IconFactory from '../../Components/StarIcon/IconFactory'
import {
	CONSOLIDATION_DELETE_PART,
	CONSOLIDATION_KEEP_PART,
	CONSOLIDATION_WILL_BE_REMOVED,
	DELETE_ITEM,
	NO,
	YES
} from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const stopEvent = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
	e.stopPropagation()
	e.preventDefault()
}

export const renderBenefits = ({ feaResult, benefits, status }: any) => {
	if (status === PartStatus.awaitingWallThickness) {
		return <div />
	}
	return (
		<div className="grid-list-benefits">
			<IconFactory
				iconName="fea"
				type={feaResult}
				className="grid-list-benefits--icon"
			/>
			<CastorPartBenefits
				benefits={benefits}
				small
				className="grid-list-benefits"
			/>
		</div>
	)
}

export const renderConsolidationDeletionAlert = ({
	show,
	onCancel,
	onConfirm
}: {
	show: boolean
	onCancel: () => void
	onConfirm: () => void
}) => {
	return (
		<div
			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
				stopEvent(e)
			}
		>
			<CastorAlert
				alertType={AlertType.WARNING}
				headerTitle={DELETE_ITEM}
				show={show}
				onConfirm={onConfirm}
				onCancel={onCancel}
				confirmOptionalText={YES}
				cancelOptionalText={NO}
				inPortal
			>
				{CONSOLIDATION_WILL_BE_REMOVED}
				<br />
				<br />
				<b>{YES}</b> {CONSOLIDATION_DELETE_PART}
				<br />
				<b>{NO}</b> {CONSOLIDATION_KEEP_PART}
			</CastorAlert>
		</div>
	)
}

export const getWeightReductionRate = (part: Part) => {
	if (!part.reducedVolumePercentage) {
		return null
	}
	const reducedVolumePercentage = part.reducedVolumePercentage.toFixed(1)
	return [getString('WEIGHT_REDUCTION_RATE').format(reducedVolumePercentage)]
}
