import Flexbox from 'Scenes/Components/FlexBox'
import { FC, memo } from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import DateFnsUtils from '@date-io/date-fns'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { validations } from '../AdminUsersService'
import EditRoles from './EditRoles'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CastorSwitch from 'Scenes/Components/CastorSwitch'
import TextField from 'Scenes/Components/TextField/TextField'
import TransparentButton from 'Scenes/Components/TransparentButton'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { SUBMIT, USER_INFORMATION_FROM_EDIT_LABELS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

import '../../AdminProjects/AdminProjectForm/AdminProjectForm.scss'
import './AdminUserForm.scss'

const ReduxFormField: any = Field
const FormControlLabelTSX: any = FormControlLabel

interface IProps {
	onSubmitClick: any
	onRemoveUser: any
	userUpdateLoading: boolean
}

interface IFormData {
	expireDate: string
	partsCredit: string
	trial: boolean | number
	verified: boolean | number
}

const renderTimePicker = ({ input, label }: any) => {
	return (
		<div className="date-picker-wrapper">
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					PopoverProps={{
						className: 'dropdown'
					}}
					disableToolbar
					variant="inline"
					label={label}
					value={input.value}
					autoOk={true}
					format="MMMM d, yyyy"
					onChange={(e: any) => input.onChange(e)}
				/>
			</MuiPickersUtilsProvider>
		</div>
	)
}

const renderTextField = ({
	input,
	label,
	type,
	placeholder,
	meta: { touched, error, warning },
	inputProps
}: any) => (
	<TextField
		wrapperClassName="admin-user-form--field"
		label={label}
		error={error}
		type={type}
		disableMinus
		disablePlus
		disableZero
		input={input}
		touched={touched}
		placeholder={placeholder}
		inputProps={inputProps}
	/>
)

const renderToggleField = ({ input, change, disabled }: any) => (
	<CastorSwitch
		disabled={disabled}
		onChange={(e: any) => input.onChange(e)}
		checked={input.value}
	/>
)

const renderSelectField = ({ input, label, meta: { error } }: any) => {
	return (
		<EditRoles
			roles={input.value || []}
			onChange={input.onChange}
			label={label}
			error={error}
		/>
	)
}

const AdminUserForm: FC<InjectedFormProps<IFormData, IProps> & IProps> = ({
	handleSubmit,
	onSubmitClick,
	onRemoveUser,
	reset,
	pristine,
	submitting,
	valid,
	userUpdateLoading
}) => {
	const roleEditingOn = Feature.isFeatureOn(FeatureComponentId.ROLE_EDITING)

	return (
		<Flexbox className="admin-user-form" flexDirection="column">
			<ReduxFormField
				name="partsCredit"
				component={renderTextField}
				label={USER_INFORMATION_FROM_EDIT_LABELS.PARTS_CREDIT}
				type="number"
				placeholder={'0'}
				inputProps={{ min: 1 }}
			/>

			<ReduxFormField
				name="expireDate"
				component={renderTimePicker}
				label={USER_INFORMATION_FROM_EDIT_LABELS.EXPIRE_DATE}
				type="date"
			/>

			<FormControlLabelTSX
				key={'3'}
				control={
					<ReduxFormField name={'trial'} component={renderToggleField} />
				}
				label={USER_INFORMATION_FROM_EDIT_LABELS.TRIAL}
			/>

			<FormControlLabelTSX
				control={
					<ReduxFormField name={'verified'} component={renderToggleField} />
				}
				label={USER_INFORMATION_FROM_EDIT_LABELS.VERIFIED}
			/>
			{roleEditingOn && (
				<ReduxFormField
					name="roles"
					component={renderSelectField}
					label={USER_INFORMATION_FROM_EDIT_LABELS.PERMISSIONS}
					validate={validations.minSelectedValues}
				/>
			)}
			<Flexbox
				flexDirection="row"
				//alignItems="center"
				justifyContent="space-between"
			>
				<ButtonWithLoader
					onClick={handleSubmit(onRemoveUser)}
					loading={userUpdateLoading}
					disabled={submitting || !valid}
					className="submit-button"
					qaDataElementName="data-qa-delete-user"
				>
					{getString('DELETE_USER')}
					{/* <IconFactory iconName="remove-square" className="submit-button--icon" /> */}
				</ButtonWithLoader>
				<Flexbox
					alignItems="center"
					alignSelf="flex-end"
					justifyContent="space-between"
					width="200px"
				>
					<TransparentButton className="reset-button" onClick={() => reset()}>
						{getString('RESET')}
					</TransparentButton>
					<ButtonWithLoader
						onClick={handleSubmit(onSubmitClick)}
						loading={userUpdateLoading}
						disabled={submitting || !valid}
						qaDataElementName="data-qa-submit-user-edit"
						// style={{ width: '23%' }}
					>
						{SUBMIT}
					</ButtonWithLoader>
				</Flexbox>
			</Flexbox>
		</Flexbox>
	)
}

export default memo(
	reduxForm<any, IProps>({
		form: 'adminUser'
		// validate
	})(AdminUserForm)
)
