import {
	ADMIN_PRINTER_ADDED,
	ADMIN_PRINTER_DELETED,
	ADMIN_PRINTER_DELETED_GOT_ERROR,
	ADMIN_PRINTER_SEARCH_PHRASE_CHANGED,
	ADMIN_PRINTER_UPDATED,
	GET_ADMIN_PRINTERS,
	GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED,
	GET_ADMIN_PRINTERS_GOT_ERROR,
	GET_ADMIN_PRINTERS_SUCCESS,
	HANDLE_NOTIFICATION,
	MAINTENANCE_FIELDS_SELECTOR_CHANGED,
	MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
	NEW_ITEM_ADD_CALLED,
	NEW_ITEM_ADD_FAILED
} from '../../../../global actions/types'
import {
	createNewPrinterAdmin,
	createNewPrinterAdminAndRemovePrinterName,
	deletePrinterAdmin,
	getPrinterAdmin,
	getPrintersAdmin,
	updateParametersAdmin,
	updatePrinterAdmin
} from '../../../../Services/Network'
import {
	ADMIN_PRINTERS_ADDED_SUCCESS,
	ADMIN_PRINTERS_NAME_ALREADY_EXIST_ERROR,
	ADMIN_PRINTERS_UPDATE_ERROR,
	ADMIN_PRINTERS_UPDATE_SUCCESS,
	SHOW_NOTIFICATION
} from '../../../../Services/Strings'
import {
	createNewItem,
	deleteItem,
	selectItemToEdit,
	setupAdminPage,
	updateItem
} from '../adminActionsService'

export const setupAdminPrintersPage = (
	searchPhrase,
	pageNumber,
	limitPrinters
) => {
	return dispatch => {
		setupAdminPage(
			dispatch,
			getPrintersAdmin,
			[searchPhrase, pageNumber, limitPrinters],
			GET_ADMIN_PRINTERS_SUCCESS,
			GET_ADMIN_PRINTERS_GOT_ERROR,
			pageNumber
		)
		dispatch({ type: GET_ADMIN_PRINTERS })
	}
}

export const setSearchPhrase = searchPhrase => {
	return {
		type: ADMIN_PRINTER_SEARCH_PHRASE_CHANGED,
		payload: searchPhrase
	}
}

export const selectPrinterToEdit = (printerId, printers) => {
	return dispatch => {
		selectItemToEdit(
			dispatch,
			printerId,
			printers,
			getPrinterAdmin,
			[printerId],
			GET_ADMIN_PRINTERS_EDIT_PRINTER_SELECTED,
			GET_ADMIN_PRINTERS_GOT_ERROR,
			'printer'
		)
	}
}

export const updatePrinter = data => {
	return dispatch => {
		updateItem(
			dispatch,
			updatePrinterAdmin,
			data,
			ADMIN_PRINTER_UPDATED,
			'printerUpdated',
			ADMIN_PRINTERS_UPDATE_SUCCESS,
			ADMIN_PRINTERS_UPDATE_ERROR
		)
	}
}

export const onAdminPrintersUnmounted = () => {
	return {
		type: ADMIN_PRINTER_SEARCH_PHRASE_CHANGED,
		payload: ''
	}
}

export const updateParameters = data => {
	return dispatch => {
		updateItem(
			dispatch,
			updateParametersAdmin,
			data,
			ADMIN_PRINTER_UPDATED,
			'printerUpdated',
			ADMIN_PRINTERS_UPDATE_SUCCESS,
			ADMIN_PRINTERS_UPDATE_ERROR
		)
	}
}
export const deletePrinter = id => {
	return dispatch => {
		deleteItem(
			dispatch,
			deletePrinterAdmin,
			id,
			ADMIN_PRINTER_DELETED,
			ADMIN_PRINTER_DELETED_GOT_ERROR
		)
	}
}

export const createNewPrinter = printer => {
	return dispatch => {
		createNewItem(
			dispatch,
			createNewPrinterAdmin,
			printer,
			ADMIN_PRINTER_ADDED,
			'printerAdded',
			ADMIN_PRINTERS_ADDED_SUCCESS,
			ADMIN_PRINTERS_UPDATE_ERROR
		)
	}
}

export const createNewPrinterAndRemovePrinterName = (printer, removeId) => {
	return async dispatch => {
		try {
			dispatch({
				type: NEW_ITEM_ADD_CALLED
			})
			const response = await createNewPrinterAdminAndRemovePrinterName(
				printer,
				removeId
			)
			dispatch({
				type: ADMIN_PRINTER_ADDED,
				payload: response.data.printerAdded
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: `${ADMIN_PRINTERS_ADDED_SUCCESS} id: ${response.data.printerAdded.id}`
				}
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: NEW_ITEM_ADD_FAILED
			})

			const errorMessage =
				error?.internalCode === 129345
					? ADMIN_PRINTERS_NAME_ALREADY_EXIST_ERROR
					: error?.validationMessage || ADMIN_PRINTERS_UPDATE_ERROR

			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage: errorMessage
				}
			})
		}
	}
}

export const onMaintenanceMachineCostFieldsSelectorChange = (
	event,
	printerId
) => {
	return {
		type: MAINTENANCE_FIELDS_SELECTOR_CHANGED,
		payload: { value: event.target.value, printerId }
	}
}

export const onMaintenanceMachineCostFieldsSelectorNewPrinterChange = event => {
	return {
		type: MAINTENANCE_FIELDS_SELECTOR_NEW_PRINTER_CHANGED,
		payload: { value: event.target.value }
	}
}
