import { FC, useMemo } from 'react'

import cx from 'classnames'

import { getPartsListViewHeaders } from './PartsListViewService'
import InfoBox from 'Scenes/Components/InfoBox'

type IProps = {
	isQuickCADUpload?: boolean
	toPrint?: boolean
}

const PartsListHeaders: FC<IProps> = ({ isQuickCADUpload, toPrint }) => {
	const partListViewHeaders = useMemo(
		() => getPartsListViewHeaders(isQuickCADUpload),
		[isQuickCADUpload]
	)

	return (
		<div
			className={cx('parts-list--header', {
				'in-quick-cad-upload': isQuickCADUpload
			})}
		>
			{partListViewHeaders?.map(
				(header: { name: string; displayName: string; hoverText?: string }) => {
					return (
						<div key={header.name} className="parts-list--header--item">
							{header.displayName}
							{!toPrint && isQuickCADUpload && header.hoverText && (
								<InfoBox
									boxContactElement={header.hoverText}
									iconClassName="parts-list--header--info-icon"
									inPortal
								/>
							)}
						</div>
					)
				}
			)}
		</div>
	)
}

export default PartsListHeaders
