import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo } from 'react'

import IconFactory from 'Scenes/Components/StarIcon/IconFactory'
import { UNPRINTABLE } from 'Scenes/Home/ProjectPending/ProjectPendingConstants'
import { getString } from 'Services/Strings/StringService'


const ConfigurationFailedProcessing: FC = () => {
	return (
		<div className="part-analysis-tab--failed-processing">
			<Flexbox alignItems="center" justifyContent="center" width="100%">
				<p>
					<b>
						<IconFactory
							iconName={UNPRINTABLE}
							className="status-viewer-page--icon"
						/>
						{getString('FAILED_PROCESSING_PART_ALERT_TITLE')}
					</b>
				</p>
			</Flexbox>
		</div>
	)
}

export default memo(ConfigurationFailedProcessing)