import Flexbox from 'Scenes/Components/FlexBox'
import React, { FC, memo } from 'react'
import { NavLink } from 'react-router-dom'
import { Element } from 'react-scroll'

import cx from 'classnames'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import LinkItemStatus from './LinkItemStatus'

const ElementTSX: any = Element
const ListItemTSX: any = ListItem
const ListItemTextTSX: any = ListItemText

interface NavLinkItemProps {
	keyProp: string
	collapseItemText: string
	collapseItem: string
	path: string
	navLinkClasses: string
	collapseItemMini: string
	mini?: string
	name: string
	icon: string
	popperDirection?: any
	reanalyzingStatus?: string
}

const NavLinkItem: FC<NavLinkItemProps> = ({
	keyProp,
	collapseItem,
	path,
	navLinkClasses,
	collapseItemMini,
	mini,
	name,
	collapseItemText,
	icon,
	reanalyzingStatus,
	popperDirection
}) => {
	const itemId = path?.split('/')[3]
	const Icon: JSX.Element | any = icon

	return (
		<ElementTSX
			key={`scrollToMenu_${itemId + keyProp}`}
			id={`scrollToMenu_${itemId}`}
			name={`scrollToMenu_${itemId}`}
		>
			<ListItemTSX
				id={itemId}
				className={cx(collapseItem, {
					'sidebar-with-icon': !!icon
				})}
			>
				<NavLink
					to={path}
					className={navLinkClasses}
					data-qa={`data-qa-side-bar-${name}`}
				>
					{icon ? <Icon/>: <></>}
					{mini && <span className={collapseItemMini}>{mini}</span>}
					<Flexbox alignItems="center" justifyContent="space-between">
						<ListItemTextTSX
							title={name}
							primary={name}
							disableTypography={true}
							className={`${collapseItemText} sidebar--text`}
							data-qa={`data-qa-side-bar-${name}`}
						/>
						{reanalyzingStatus && (
							<LinkItemStatus
								reanalyzingStatus={reanalyzingStatus}
								popperDirection={popperDirection}
							/>
						)}
					</Flexbox>
				</NavLink>
			</ListItemTSX>
		</ElementTSX>
	)
}

export default memo(NavLinkItem)
