import { FC, memo, useEffect } from 'react'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { toNumber } from 'lodash'

import ProgressBar from './ProgressBar/index'
import AdBlock from 'Scenes/Components/AdBlock'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import NavBarAndMaterial from 'Scenes/Components/NavBarAndMaterial'
import { useUserReducer } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisSelector'
import {
	clearPoller,
	getFastProjectPoller,
	sendAdminUploadFilesInfo,
	uploadFailedFiles
} from 'Scenes/Home/NewUploadProject/UploadProjectActions'
import {
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { projectRoute, uploadProjectRoute } from 'Services/routeFuncs'
import {
	COMPLETE_UPLOAD_FAILED_MSG,
	COMPLETE_UPLOAD_FAILED_TITLE,
	COMPLETE_UPLOAD_SUCCESS_MSG,
	COMPLETE_UPLOAD_SUCCESS_TITLE,
	NAV_TITLE_PROJECT_UPLOADING,
	OK,
	START_OVER,
	YES
} from 'Services/Strings'

interface UploadingProjectProps {}

const UploadingProject: FC<UploadingProjectProps> = ({}) => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { isLightUser } = useUserReducer()
	const user = useSelector((state: RootStateOrAny) => state.user)
	const {
		pollerStarted,
		uploadSuccess,
		quickProject,
		projectId,
		filesFailed,
		uploadURLs,
		bomFile,
		antivirusMessages,
		antivirusMessagingEndpoint,
		quickProjectPublished,
		quickProjectFailed,
		filesToUpload,
		progress,
		bundleId
	} = useSelector((state: RootStateOrAny) => {
		return state?.uploadProject
	})

	const showStartOver = filesFailed.length > 0 && filesToUpload.length === 0

	const showProgressAlert = Feature.isFeatureOn(
		FeatureComponentId.SHOW_PROGRESS_ALERT
	)
	const showContactUs = Feature.isFeatureOn(
		FeatureComponentId.SHOW_CONTACT_US_BANNER
	)
	const isProjectBundleOn = Feature.isFeatureOn(
		FeatureComponentId.PROJECT_BUNDLE_PAGE
	)
	const isQuickUploadOn = Feature.isFeatureOn(
		FeatureComponentId.QUICK_CAD_UPLOAD
	)

	useEffect(() => {
		if (!showStartOver && uploadSuccess && quickProject && !pollerStarted) {
			dispatch(getFastProjectPoller(projectId))
		}
	}, [uploadSuccess, quickProject, pollerStarted, projectId, showStartOver])

	useEffect(() => {
		if (bundleId && pollerStarted) {
			dispatch(clearPoller())
			dispatch(getFastProjectPoller(projectId, bundleId))
		}
	}, [bundleId, dispatch, projectId])

	useEffect(() => {
		return () => {
			dispatch(clearPoller())
		}
	}, [])

	const handleCompleteSuccess = (disableBackgroundClick: boolean) => {
		if (disableBackgroundClick) {
			return
		}
		history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
	}

	const handleStartOver = () => {
		dispatch(sendAdminUploadFilesInfo(projectId))
		history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
	}

	const handleCompleteFailed = () => {
		dispatch(
			uploadFailedFiles(
				filesFailed,
				uploadURLs,
				bomFile,
				antivirusMessages,
				antivirusMessagingEndpoint,
				false,
				user?.defaultFileName
			)
		)
	}

	useEffect(() => {
		if (projectId && !showStartOver) {
			if (!isLightUser && toNumber(progress) === 100 && showProgressAlert) {
				setTimeout(() => {
					history.push(projectRoute(projectId))
					return
				}, 1500)
			} else if (quickProjectPublished) {
				const id =
					isProjectBundleOn && isQuickUploadOn && bundleId
						? bundleId
						: projectId
				window.location.replace(projectRoute(id))
			} else if (quickProjectFailed) {
				history.push(uploadProjectRoute())
			}
		}
	}, [
		quickProjectPublished,
		quickProjectFailed,
		progress,
		projectId,
		showStartOver,
		filesToUpload
	])

	return (
		<NavBarAndMaterial
			title={NAV_TITLE_PROJECT_UPLOADING}
			wrapperStyle={showContactUs ? 'with-contact-banner' : ''}
		>
			<ProgressBar
				showProgressAlert={showProgressAlert}
				showAnalyze={isLightUser}
			/>
			<CastorAlert
				show={uploadSuccess && !quickProject}
				alertType={AlertType.SUCCESS}
				headerTitle={COMPLETE_UPLOAD_SUCCESS_TITLE}
				onConfirm={handleCompleteSuccess}
				onCancel={handleCompleteSuccess}
				confirmOptionalText={OK}
				showCancel={false}
			>
				{COMPLETE_UPLOAD_SUCCESS_MSG}
			</CastorAlert>
			<CastorAlert
				show={showStartOver}
				alertType={AlertType.WARNING}
				headerTitle={COMPLETE_UPLOAD_FAILED_TITLE}
				onConfirm={handleCompleteFailed}
				onCancel={handleStartOver}
				confirmOptionalText={YES}
				cancelOptionalText={START_OVER}
			>
				{COMPLETE_UPLOAD_FAILED_MSG}
			</CastorAlert>
			<AdBlock />
		</NavBarAndMaterial>
	)
}

export default memo(UploadingProject)
