import { AxiosPromise } from 'axios'

import { IOptionalMetalFormParameters } from '../models/CostComparisonModels'
import { IChainBenefits } from '../models/IChainBenefits'
import { IClusterPart } from '../models/ICluster'
import { Part, PartStatus, WeightReductionType } from '../models/IPart'
import { SolutionConfigurationTypes } from '../models/ISolutionConfiguration'
import { ISolutionFea } from '../models/ISolutionFea'
import { WeightReductionMode } from '../models/IWeightReductionGen2'
import { PartPrintIssue } from '../models/PartPrintIssue'
import { del, get, post, put } from '../Network'
import { CalculationType } from 'Scenes/Home/NewPartAnalysis/MainPartAnalysis/SolutionAnalysis/SolutionAnalysis'
import { IUserProvider } from 'Services/models/IUserProvider'

export const getConfigurationSolutions = (configurationId: number) =>
	get(`/configuration/solutions/${configurationId}`, { timeout: 10000000 })

export const getPartSolutions = (
	partId: number,
	calculateSolutions: boolean = false
): AxiosPromise<any> | undefined => {
	return get(
		`/partSolutions/${partId}?getCostGraph=${false}&calculateSolutions=${calculateSolutions}`
	)
}

export const getPartClusterSolutions = (
	clusterId: number
): AxiosPromise<any> | undefined => {
	return get(`/clusterSolutions?clusterId=${clusterId}`)
}

export const updateLockLeadTimeGraph = (id: number): any => {
	const params = {
		unlockLeadTimeGraph: true
	}

	return put(`/configurations/unlockLeadTimeGraph/${id}`, params)
}

export const updateToolingParam = (id: number, isTooling: boolean): any => {
	const params = {
		isTooling
	}

	return put(`/configurations/changeToolingParam/${id}`, params)
}

export const createOrUpdateSolution = (
	solutionId: number,
	partId: number | null,
	projectId: string | null,
	priorities: any,
	filters: any,
	configurationId: number,
	isSpecifiedQuantity: boolean,
	solutionName: string,
	material: any,
	batchSize: number,
	postProcessesOptional: any,
	clusterId: number | null,
	printingOrientationVector?: Array<number>,
	orientationChange?: boolean,
	isSimpleConfiguration?: boolean,
	chosenPrinter?: any,
	chosenMaterial?: any,
	isSimpleInhouseConfiguration?: boolean,
	supportVolume?: number,
	manufacturingMethod?: string,
	yearsOfDemand?: number,
	defaultConfiguration?: any,
	settingScenario?: string,
	fullTrayAssumption?: boolean,
	calculationType?: CalculationType
): AxiosPromise<any> | undefined => {
	const params = {
		solutionId,
		partId,
		projectId,
		priorities,
		filters,
		configurationId,
		solutionName,
		material,
		batchSize,
		postProcessesOptional,
		clusterId,
		trayOrientationVector: printingOrientationVector,
		trayOrientationChange: orientationChange,
		newSupportVolume: supportVolume,
		manufacturingMethod: manufacturingMethod,
		yearsOfDemand,
		defaultConfiguration: defaultConfiguration,
		settingScenario,
		fullTrayAssumption,
		isSpecifiedQuantity,
		type: isSimpleConfiguration
			? SolutionConfigurationTypes.simple
			: SolutionConfigurationTypes.regular,
		calculationType
	}

	return post(`/solution`, params)
}

export const getConfiguration = (configurationId: number) => {
	return get(
		`/solutionViewModel/${configurationId}?ignorePartStatus=${PartStatus.awaitingWallThickness}`
	)
}

export const getWallThickness = (configurationId: number) => {
	return get(`/wallThicknessViewModel/${configurationId}`)
}

export const updateSolutionName = (
	configurationId: number,
	solutionName: string,
	isSimpleConfiguration: boolean
) => {
	const configurationType = isSimpleConfiguration
		? SolutionConfigurationTypes.simple
		: SolutionConfigurationTypes.regular
	const params = { configurationId, solutionName, configurationType }
	return put(`/solutionName`, params)
}

export const updateSolutionQuantity = (
	configurationId: number,
	quantity: number,
	postProcessesOptional: any,
	chainBenefits: IChainBenefits,
	expectedYearsOfDemand: number,
	chosenOrientation: any,
	changeQuantity: boolean = false,
	productLifeCycle?: string,
	fullTrayAssumption?: boolean
) => {
	const params = {
		configurationId,
		quantity,
		postProcessesOptional,
		chainBenefits,
		expectedYearsOfDemand,
		chosenOrientation,
		changeQuantity,
		productLifeCycle,
		fullTrayAssumption
	}
	return put(`/solutionQuantity`, params)
}

export const updateConfigurationTraditionalMethod = (
	configurationId: number,
	manufacturingMethod: string
) => {
	const params = { configurationId, manufacturingMethod }
	return put(`/traditionalMethod`, params)
}

export const removeSolution = (
	configurationId: number,
	isSimpleConfiguration: boolean = false
) => {
	return del(
		`/solution?configurationId=${configurationId}&isSimpleConfiguration=${isSimpleConfiguration}`
	)
}

export const combineAroundPart = (
	partId: string,
	projectId: string,
	sameMaterial: boolean
) => {
	let params = {
		partId: partId,
		projectId: projectId,
		sameMaterial: sameMaterial
	}

	return post('/requestMultiplePartsIntoOne', params)
}

export const updateSolutionStarred = (
	configurationId: number,
	starred: boolean,
	isSimpleConfiguration: boolean
) => {
	const params = { configurationId, starred, isSimpleConfiguration }
	return put(`/starredSolution`, params)
}

export const updateLeadingConfiguration = (
	configurationId: number,
	leadingByUserChoice: boolean
) => {
	const params = { configurationId, leadingByUserChoice }
	return post(`/leadingConfiguration`, params)
}

export const updateCNCProps = (
	partId: number,
	cncParams: IOptionalMetalFormParameters
) => {
	const params = { partId, cncParams }
	return post(`/cncProps`, params)
}
export const updateUserCustomCNCProps = (
	cncParams: IOptionalMetalFormParameters
) => {
	const params = { customCNCParams: cncParams }
	return post(`/user/userCustomCNCProps`, params)
}
export const createMeshHealing = (partId: number) =>
	post(`/meshHealing/${partId}`)

export const meshHealingSendToPrint = (configurationId: number) =>
	put(`/user/meshHealingSendToPrint`, { configurationId })

export const userSendToPrint = (configurationId: number) =>
	put(`/user/sendToPrint`, { configurationId })

export const exportConfigurationData = (
	configurationId: number,
	part: Part,
	userProviders: IUserProvider[],
	exportType: string
) => {
	const params = {
		exportType: exportType,
		configurationId: configurationId,
		userProviders: userProviders,
		partId: part.id
	}
	return post(`/configuration/exportData`, params)
}

export const partDownload = (partId: number) =>
	put(`/user/partDownload`, { partId })

export const updateNotCostEffectivePartResult = (partId: number) =>
	put(`/part/costEffective/${partId}`)

export const createFeaRequest = (
	partId: number,
	configurationId: number,
	backToProjectPage: boolean,
	feaId?: string | null,
	weightReductionMode?: WeightReductionMode
): AxiosPromise<{ requestUrl: string; request: any }> | undefined =>
	post('/fea/analysisRequest', {
		partId,
		configurationId,
		feaId,
		backToProjectPage,
		weightReductionMode
	})

export const saveFeaAnalysisData = (
	feaId: string,
	userInputs: number[],
	feaAnalysisResultsId: string,
	feaAnalysisCancelled: boolean = false
):
	| AxiosPromise<{ solutionFea: ISolutionFea; feaValues: number[] }>
	| undefined =>
	put(`/fea/analysisData/${feaId}`, {
		userInputs: JSON.stringify(userInputs),
		feaAnalysisResultsId,
		feaAnalysisCancelled
	})

export const partsPropertiesChange = (
	projectId: string,
	partsProperties: any[],
	bundleId?: string
) => {
	return put(`/partsDrawingProperties/${projectId}`, {
		partsProperties,
		bundleId
	})
}

export const partsPropertiesReset = (
	projectId: string,
	partsIds: any[],
	bundleId?: string
) => {
	return post(`/partsDrawingProperties`, {
		partsIds,
		projectId,
		bundleId
	})
}

export const getPartsProperties = (projectId: string) => {
	return get(`/partsDrawingProperties/${projectId}`)
}

export const getFeaAnalysisResults = (problemHash: string, feaId?: string) =>
	get(`/fea/analysisResults/${problemHash}/${feaId}`)

export const getSolutionFea = (feaId: string) =>
	get(`/fea/solutionFea/${feaId}`)

export const getCaeplexRedirectURL = (
	requestUrl: string,
	request: Record<string, any>
): Promise<any> =>
	fetch(requestUrl, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify(request)
	}).then(response => response.text())

export const changePartPrintIssueActivity = (
	partIssueId: number,
	active: boolean,
	partId: number
):
	| AxiosPromise<{
			configurations: any[]
			part: Part | null
			cluster: IClusterPart | null
			solutions: any[]
			partPrintIssues: PartPrintIssue[]
	  }>
	| undefined => {
	return put(`/partPrintIssueActivity/${partIssueId}`, {
		active,
		partId
	})
}

export const updatePartWeightReductionType = (
	partId: number,
	weightReductionType: WeightReductionType
) => {
	return put(`/updatePartWeightReductionType/${partId}`, {
		weightReductionType
	})
}

export const getPart = (partId: number) => get(`/part/${partId}`)
