import React, { ChangeEvent, FC, memo } from 'react'

import TextField from '@material-ui/core/TextField'
import cx from 'classnames'
import FlexBox from '../FlexBox'
import InfoBox from '../InfoBox'
import NumberField from '../NumberField'
import { ZERO_NUMBER_FIELD_VALUE } from 'Services/Constants'

import './index.scss'

const TextFieldTSX: any = TextField

interface FieldWithLabelProps {
	isNumberField?: boolean
	labelName: string
	fieldPlaceholder?: string
	fieldId?: any
	fieldValue: any
	fieldClassName?: string
	fieldOnChange: (value: number | string) => void
	qaDataElementName?: any
	currency?: string
	disabled?: boolean
	autoFocus?: boolean
	wrapperClassName?: string
	step?: number
	isCommaDisabled?: boolean
	isDotDisabled?: boolean
	error?: boolean
	helperText?: string
	isHoverText?: boolean
	hoverText?: string
}

const FieldWithLabel: FC<FieldWithLabelProps> = ({
	isNumberField,
	labelName,
	fieldId,
	fieldValue,
	fieldOnChange,
	fieldPlaceholder,
	fieldClassName,
	qaDataElementName,
	currency,
	disabled,
	autoFocus = true,
	wrapperClassName,
	step,
	isCommaDisabled,
	isDotDisabled,
	error,
	helperText,
	isHoverText,
	hoverText
}) => {
	return (
		<div
			id={fieldId || ''}
			className={cx('field-with-label__block', wrapperClassName)}
		>
			<FlexBox>
				<div className="label">{labelName}</div>
				{isHoverText && (
					<InfoBox
						boxDirection="right-start"
						boxContact={hoverText}
						iconClassName="info-box__info_icon"
						boxClassName="info-box"
					/>
				)}
			</FlexBox>
			{isNumberField ? (
				<NumberField
					disabled={disabled}
					allowZero={false}
					allowEmpty={true}
					placeholder=""
					variant="outlined"
					value={fieldValue}
					onChangeValue={(value: number | string) => fieldOnChange(value)}
					inputClass={cx('field-with-label outlined', fieldClassName)}
					minValue={ZERO_NUMBER_FIELD_VALUE}
					showArrows={true}
					changeOnScroll={false}
					qaDataElementName={qaDataElementName}
					isCommaDisabled={isCommaDisabled}
					isDotDisabled={isDotDisabled}
					step={step}
					error={error}
					helperText={helperText}
				/>
			) : (
				<TextFieldTSX
					disabled={disabled}
					inputProps={{ 'data-qa': qaDataElementName }}
					placeholder={fieldPlaceholder || ''}
					className={cx('field-with-label outlined', fieldClassName)}
					value={fieldValue}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						fieldOnChange(e.target.value)
					}
					autoFocus={autoFocus}
					error={error}
					helperText={helperText}
				/>
			)}
			{currency ? <span className="currency">{currency}</span> : ''}
		</div>
	)
}

export default memo(FieldWithLabel)
