import React, { Component } from 'react'
import Flexbox from 'Scenes/Components/FlexBox'
import cx from 'classnames'

import Muted from './thirdParty/CreativeTim/components/Typography/Muted.jsx'
import IconFactory from './StarIcon/IconFactory.js'

class LedWithTitle extends Component {
	renderText() {
		const { title, jsx, bolded } = this.props
		if (title) {
			return (
				<Muted style={styles.text} className={cx({ bolded: bolded })}>
					{title}
				</Muted>
			)
		} else if (jsx) {
			return jsx
		}
	}

	renderIcon = () => {
		const { result, color, gradientStartColor, onlyColors, circleSize } =
			this.props
		if (!result || onlyColors) {
			return (
				<div
					style={{
						width: circleSize
							? `${circleSize}px`
							: onlyColors
							? '18px'
							: '16px',
						height: circleSize
							? `${circleSize}px`
							: onlyColors
							? '18px'
							: '15px',
						background: `linear-gradient(${
							gradientStartColor || color
						}, ${color})`,
						borderRadius: onlyColors ? '50%' : '20px'
					}}
				/>
			)
		}
		return <IconFactory small iconName={result} width="16px" height="13px" />
	}

	render() {
		const { addStyle } = this.props
		return (
			<Flexbox
				flexDirection="row"
				style={{ ...addStyle, ...styles.horizontalFlex }}
			>
				{this.renderIcon()}
				{this.renderText()}
			</Flexbox>
		)
	}
}

const styles = {
	xAxisLabel: { textAlign: 'center' },
	text: {
		width: '100%',
		paddingLeft: '5px',
		textAlign: 'left',
		marginTop: '-3px'
	},
	circle: { display: 'flex' }
}

export default LedWithTitle
