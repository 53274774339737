import {
	CLUSTER_MATERIAL_CHANGED_IN_TECH_ANALYSIS,
	CLUSTER_MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS,
	CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS,
	CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL,
	CLUSTER_RESULT_RECALCULATED_FAILED,
	CLUSTER_RESULT_RECALCULATED_SUCCESS,
	HIDE_ALERT,
	IN_HOUSE_SWITCH_CHANGED,
	SETUP_TECH_ANALYISIS_CLUSTER_COMPONENT,
	SHOW_PRICE_ESTIMATE_ALERT,
	SHOW_PRINTER_ALERT,
	SHOW_PRIORITY_ALERT,
	SHOW_TECHNOLOGY_ALERT
} from '../../../../../global actions/types'
import { defaultMetrics } from '../../../../../Services/Constants'
import {
	COST_ESTIMATE_INFO,
	COST_ESTIMATE_TITLE_INFO,
	PRINTING_TECH,
	PRIORITY_ALERT_TEXT,
	PRIORITY_ALERT_TITLE
} from '../../../../../Services/Strings'
import { getTheme } from '../../../../../themes/getTheme'

const { defaultMaterial } = getTheme()

let updatedClusterDelegate
let stateBeforeServerCall
let materials
const INITIAL_STATE = {
	loading: false,
	error: null,
	showingSimpleAlertText: null,
	chosenMaterial: defaultMaterial,
	materialType: defaultMaterial.type,
	metrics: defaultMetrics,
	displayedStength: 0,
	newSolution: null,
	inHouse: false,
	projectQuantity: 1,
	amountWarningText: null
}

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SETUP_TECH_ANALYISIS_CLUSTER_COMPONENT:
			materials = action.payload.materials
			updatedClusterDelegate = action.payload.updateClusterFunc
			const metrics = action.payload.metrics
				? action.payload.metrics
				: defaultMetrics
			const inHouse = action.payload.showInHouseSolutions

			const chosenMaterialId = action.payload.cluster.material
				? action.payload.cluster.material
				: defaultMaterial.id
			const chosenMaterial = materialFromMaterialId(chosenMaterialId)
			return {
				...state,
				chosenMaterial,
				materialType: chosenMaterial.type,
				metrics,
				inHouse
			}
		case SHOW_PRICE_ESTIMATE_ALERT:
			return {
				...state,
				showingSimpleAlertText: COST_ESTIMATE_INFO,
				showingSimpleAlertTitle: COST_ESTIMATE_TITLE_INFO
			}
		case SHOW_PRIORITY_ALERT:
			return {
				...state,
				showingSimpleAlertText: PRIORITY_ALERT_TEXT,
				showingSimpleAlertTitle: PRIORITY_ALERT_TITLE
			}
		case SHOW_PRINTER_ALERT:
			return {
				...state,
				showingSimpleAlertText: action.payload.description,
				showingSimpleAlertTitle: action.payload.name
			}
		case SHOW_TECHNOLOGY_ALERT:
			const techInfo = PRINTING_TECH.find(
				obj => action.payload.toLowerCase() === obj.name.toLowerCase()
			)
			if (!techInfo) {
				return {
					...state,
					showingSimpleAlertText: null,
					showingSimpleAlertTitle: null
				}
			}
			const title = `${techInfo.name} - ${techInfo.fullName}`
			const description = techInfo.description
			return {
				...state,
				showingSimpleAlertText: description,
				showingSimpleAlertTitle: title
			}
		case HIDE_ALERT:
			return {
				...state,
				showingSimpleAlertText: null,
				showingSimpleAlertTitle: null
			}
		case CLUSTER_MATERIAL_TYPE_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return { ...state, materialType: action.payload }
		case CLUSTER_MATERIAL_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return { ...state, materialType: action.payload }
		case CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS:
			stateBeforeServerCall = state
			return { ...state, metrics: action.payload }
		case CLUSTER_METRICS_CHANGED_IN_TECH_ANALYSIS_FINAL:
			stateBeforeServerCall = state
			return { ...state, metrics: action.payload, loading: true, error: null }
		case CLUSTER_RESULT_RECALCULATED_SUCCESS: {
			const cluster = action.payload.cluster
			const metrics = action.payload.solution.metrics
			const material = materialFromMaterialId(cluster.material)
			const showInHouseSolutions = action.payload.showInHouseSolutions

			return {
				...state,
				chosenMaterial: material,
				materialType: material.type,
				metrics,
				inHouse: action.payload.showInHouseSolutions,
				loading: false,
				error: null,
				newSolution: action.payload.solution
			}
		}
		case CLUSTER_RESULT_RECALCULATED_FAILED:
			const lastState = stateBeforeServerCall ? stateBeforeServerCall : state
			return { ...lastState, error: action.payload, loading: false }
		case IN_HOUSE_SWITCH_CHANGED:
			return { ...state, inHouse: action.payload, loading: true, error: null }
		default:
			return state
	}
}

const materialFromMaterialId = id => {
	return materials.find(material => material.id === id)
}
