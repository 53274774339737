import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react'
import { connect, DispatchProp, RootStateOrAny, useSelector } from 'react-redux'
import { AnyAction, bindActionCreators } from 'redux'

import { TextField } from '@material-ui/core'

import * as CustomizeActions from '../../CustomizeActions'
import { ParameterTypes } from '../ParameterTypes'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import Flexbox from 'Scenes/Components/FlexBox'
import { getString } from 'Services/Strings/StringService'

const TextFieldTSX: any = TextField

interface IProps {
	disposeFactorValue: string
	disposeFactor: number
	setUserCustomzationSettings: Function
	disposeFactorChange: Function
}

interface IReduxStore {
	user: any
	CustomizeCO2CalculationReducer: any
}

const CustomizeCO2EndOfLife: FC<IProps> = ({
	disposeFactorValue,
	setUserCustomzationSettings,
	disposeFactor,
	disposeFactorChange
}) => {
	const [isEndOfLifeError, setEndOfLifeError] = useState(false)
	const { disposeFactorLoading } = useSelector(
		(state: RootStateOrAny) => state.user
	)

	useEffect(()=> {
		if (!disposeFactorLoading) {
			disposeFactorChange('')
		}
	}, [disposeFactorLoading])

	const disposeFactorCheck = (value: string) => {
		if (Number(value) < 0 || Number(value) > 25) {
			setEndOfLifeError(true)
		} else {
			setEndOfLifeError(false)
		}
		disposeFactorChange(value)
	}

	const onSaveProductUse = () => {
		if (disposeFactorValue !== '') {
			setUserCustomzationSettings(
				disposeFactorValue,
				ParameterTypes.disposeFactor
			)
		}
	}

	const renderEndOfLife = () => {
		return (
			<>
				<div className="custom-calculation-co2--explanation">
					{getString('CO2_CALCULATION_END_OF_LIFE_SUBTITLE')}
				</div>
				<Flexbox>
					<div className="custom-calculation-co2--explanation">
						{getString('CO2_CALCULATION_END_OF_LIFE_INPUT_TEXT')}
					</div>
					<TextFieldTSX
						className="custom-calculation-co2--end-of-life-picker"
						type="number"
						placeholder={disposeFactor}
						onChange={(e: ChangeEvent<HTMLInputElement>) =>
							disposeFactorCheck(e.target.value)
						}
						value={disposeFactorValue}
					/>
				</Flexbox>
				{isEndOfLifeError && (
					<div className="custom-calculation-co2--error">
						{getString('CO2_CALCULATION_END_OF_LIFE_DATA_VALIDATION')}
					</div>
				)}
				<ButtonWithLoader
					size={20}
					style={{
						padding: '5px 20px',
						width: 'unset',
						maxWidth: '70px',
						minWidth: '70px',
						left: '745px',
						height: '33px'
					}}
					top={20}
					loading={disposeFactorLoading}
					onClick={onSaveProductUse}
					disabled={isEndOfLifeError}
				>
					{getString('SET')}
				</ButtonWithLoader>
			</>
		)
	}

	return <>{renderEndOfLife()}</>
}

const mapStateToProps = (CustomizeCO2CalculationReducer: IReduxStore) => {
	return {
		...CustomizeCO2CalculationReducer
	}
}

const mapDispatchToProps = (dispatch: DispatchProp<AnyAction>) =>
	bindActionCreators({ ...CustomizeActions }, dispatch)

export default memo(
	connect(mapStateToProps, mapDispatchToProps)(CustomizeCO2EndOfLife)
)
