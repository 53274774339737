import {
	CHANGE_USER_MATERIAL_NAME_MAPPING,
	COST_AND_LEAD_TBL_DATA_ARRANGED,
	CREATE_USER_MATERIAL_NAMES_MAPPING,
	FATCH_USER_MATERIAL_NAMES_MAPPING,
	FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
	HANDLE_LOADER,
	HANDLE_NOTIFICATION,
	IN_HOUSE_PRINTERS_DATA_RECEIVED,
	PART_FILTERING_FORM_DATA_ARRANGED,
	REMOVE_USER_MATERIAL_NAMES_MAPPING,
	START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
	UPDATE_USER_MATERIAL_NAMES_MAPPING,
	UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
	USER_DESKTOP_MATERIAL_TOGGLED
} from '../../../global actions/types'
import {
	createUserMaterialNameMapping,
	getAllPrintersCompanies,
	getCustomizationMaterialPriceList,
	getCustomizationPrinterSettingsList,
	getCustomizationSettings,
	getProjectScenario,
	getProjectScenarioParameters,
	getUserMaterialNamesMapping,
	removeUserMaterialNameMapping,
	toggleUserDesktopPrinters,
	updateUserMaterialNamesMapping
} from '../../../Services/Network'
import {
	checkSemiProfessionalPrintersToggle,
	getGlobalFilteringArray,
	getMaterialCosts,
	getPartFilteringArray,
	setupValidationStateKeys
} from './CustomizeLogic'
import { defaultSettingScenario } from 'Services/Constants'
import { SHOW_NOTIFICATION } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'

export const fetchCustomizeData = (
	costAndLeadNames,
	userSettings,
	drawingCost2dNames,
	printersFullData
) => {
	return dispatch => {
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		Promise.allSettled([
			getCustomizationSettings(),
			getCustomizationMaterialPriceList(),
			getCustomizationPrinterSettingsList(),
			getAllPrintersCompanies(),
			getProjectScenario(),
			getProjectScenarioParameters()
		])
			.then(response => {
				handleCustomizeDataResponse(
					costAndLeadNames,
					response,
					dispatch,
					userSettings,
					drawingCost2dNames,
					printersFullData
				)
			})
			.catch(error => {
				dispatch({
					type: HANDLE_LOADER,
					payload: -1
				})
				console.error(error)
			})
	}
}

export const onToggleDesktopPrinter = desktopPrinter => {
	return async dispatch => {
		try {
			await toggleUserDesktopPrinters(!desktopPrinter)
			dispatch({
				type: USER_DESKTOP_MATERIAL_TOGGLED
			})
		} catch (error) {
			console.error(error)
		}
	}
}

export const fetchUserMaterialNameMapping = () => {
	return async dispatch => {
		const userMaterialNamesMapping = (await getUserMaterialNamesMapping())?.data
			?.userMaterialNamesMapping
		dispatch({
			type: FATCH_USER_MATERIAL_NAMES_MAPPING,
			payload: { userMaterialNamesMapping }
		})
	}
}

export const onCreateUserMaterialNameMapping = (
	userMaterialNameMapping,
	onChangeSelector
) => {
	return async dispatch => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await createUserMaterialNameMapping(userMaterialNameMapping)
			).data.userMaterialNamesMapping

			const newUserMaterialNameMapping = userMaterialNamesMapping.filter(
				userMaterialNameMappingObject =>
					userMaterialNameMappingObject.expression ===
					userMaterialNameMapping.expression
			)

			dispatch({
				type: CREATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMapping: newUserMaterialNameMapping[0]
				}
			})
			onChangeSelector('type', 'plastic')
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error) {
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.WARN,
					notificationMessage: error.internalMessage || error.validationMessage
				}
			})
		}
	}
}

export const onUpdateMaterialNameMapping = _userMaterialNamesMapping => {
	return async dispatch => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (
				await updateUserMaterialNamesMapping(_userMaterialNamesMapping)
			).data.userMaterialNamesMapping

			const userMaterialNameMappingUpdate = userMaterialNamesMapping.find(
				userMaterialNameMappingObject =>
					userMaterialNameMappingObject.expression ===
					_userMaterialNamesMapping[0].expression
			)

			dispatch({
				type: UPDATE_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					userMaterialNamesMapping,
					userMaterialNameMappingUpdate
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_UPDATED_SUCCESS'
					)
				}
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_UPDATED_FAILED')
				}
			})
		}
	}
}

export const onChangeSelectedMaterial = userMaterialNameMapping => {
	return async dispatch => {
		try {
			dispatch({
				type: CHANGE_USER_MATERIAL_NAME_MAPPING,
				payload: {
					userMaterialNameMapping
				}
			})
		} catch (error) {
			console.log(error)
		}
	}
}

export const onRemoveUserMaterialNameMapping = id => {
	return async dispatch => {
		dispatch({
			type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
			payload: {
				isLoading: true
			}
		})
		try {
			const userMaterialNamesMapping = (await removeUserMaterialNameMapping(id))
				.data.userMaterialNamesMapping

			dispatch({
				type: REMOVE_USER_MATERIAL_NAMES_MAPPING,
				payload: { userMaterialNamesMapping, userMaterialNameMappingId: id }
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.SUCCESS,
					notificationMessage: getString(
						'USER_MATERIAL_NAME_MAPPING_REMOVED_SUCCESS'
					)
				}
			})
		} catch (error) {
			console.log(error)
			dispatch({
				type: START_UPDATING_USER_MATERIAL_NAMES_MAPPING,
				payload: {
					isLoading: false
				}
			})
			dispatch({
				type: HANDLE_NOTIFICATION,
				payload: {
					notificationType: SHOW_NOTIFICATION.ERROR,
					notificationMessage:
						error.validationMessage ||
						getString('USER_MATERIAL_NAME_MAPPING_REMOVED_FAILED')
				}
			})
		}
	}
}

const handleCustomizeDataResponse = (
	costAndLeadNames,
	response,
	dispatch,
	userSettings,
	drawingCost2dNames,
	printersFullData
) => {
	const settingsResponse = response[0].value
	const materialPriceResponse = response[1].value
	const printerSettingsResponse = response[2].value
	const printersCompanies = response[3].value?.data?.printerCompanies || []
	const printers = Object.values(printersCompanies).flat()
	const settingsData = settingsResponse?.data
	const originalUserSettingsValues = settingsData.userSettings
	const partFilteringArr = getPartFilteringArray(settingsData)
	const globalOffTheShelfSubstrings =
		settingsData.userSettings.globalOffTheShelfSubstrings
	const filteredGlobalOffTheShelf = getGlobalFilteringArray(
		globalOffTheShelfSubstrings
	)
	const materialPriceData = materialPriceResponse?.data
	const userPrinterSettingsData =
		printerSettingsResponse?.data?.userPrinterSettingsList || []
	const validationStateKeys = {}
	const defaultProjectScenario =
		response[4].value?.data?.defaultProjectScenario ||
		defaultSettingScenario.lowVolume
	const baseDefaultProjectScenarios =
		response[4].value?.data?.baseDefaultProjectScenarios
	const defaultProjectScenarioParameters =
		response[5].value?.data?.defaultProjectScenarioParams
	const isShowSemiProfessionalPrintersToggle =
		checkSemiProfessionalPrintersToggle(printersFullData)

	setupValidationStateKeys(
		settingsData,
		costAndLeadNames,
		validationStateKeys,
		drawingCost2dNames
	)

	dispatch({
		type: COST_AND_LEAD_TBL_DATA_ARRANGED,
		payload: {
			settingsData,
			materialCosts: materialPriceData.priceList,
			originalUserSettingsValues,
			validationStateKeys,
			userPrinterSettingsData,
			userSettings,
			isShowSemiProfessionalPrintersToggle
		}
	})
	dispatch({
		type: PART_FILTERING_FORM_DATA_ARRANGED,
		payload: {
			partFilteringArr,
			filteredGlobalOffTheShelf
		}
	})
	dispatch({
		type: IN_HOUSE_PRINTERS_DATA_RECEIVED,
		payload: { printersCompanies, printers }
	})
	dispatch({
		type: FULL_TRAY_ASSUMPTION_DATA_ARRANGED,
		payload: { settingsData }
	})
	dispatch({
		type: UPLOAD_PROJECT_SCENARIO_DATA_ARRANGED,
		payload: {
			defaultProjectScenario,
			defaultProjectScenarioParameters,
			baseDefaultProjectScenarios
		}
	})
	dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})
}
