import { Store } from 'redux'

import { isEmpty } from 'lodash'

import { getJsonItemFromLocalStorage } from './LocalStorageService'
import { getUserProfileData, isLoggedIn, refreshJWT } from './Network'
import { handleUserStrings } from './Strings/StringService'
import { isLoggedInSuccess } from 'global actions'
import {
	AUTHORIZATION_TOKEN_UPDATED,
	HANDLE_LOADER,
	LOGIN_PENDING,
	LOGIN_STATE_CHANGED,
	REFRESH_TOKEN_UPDATED
} from 'global actions/types'
import { store } from 'index'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import {
	UPLOAD_ROUTE,
	USER_HOME_ROUTE
} from 'Services/Constants/RoutesConstants'
import history from 'Services/history'

export const isUserLoggedIn = async (castorLight?: boolean): Promise<any> => {
	store.dispatch({
		type: LOGIN_PENDING,
		payload: true
	})
	store.dispatch({
		type: HANDLE_LOADER,
		payload: 1
	})

	let token = store.getState().auth?.authorizationToken
	let userId = store.getState().user?.userId

	if (token) {
		const userProfileData = await getUserProfileData(userId)
		isLoggedInSuccess(store.dispatch, userProfileData)
		await handleUserStrings(
			userProfileData?.data?.generalData?.userDetails?.email
		)

		store.dispatch({
			type: LOGIN_STATE_CHANGED,
			payload: true
		})

		store.dispatch({
			type: HANDLE_LOADER,
			payload: -1
		})

		return {
			userId,
			isLoggedIn: true
		}
	}

	const refreshTokenData = getJsonItemFromLocalStorage('crt')
	if (isEmpty(refreshTokenData)) {
		// Get the default fast track user if there is no logged in user.
		if (castorLight) {
			return await loginFastTrackUser(store)
		}

		store.dispatch({
			type: LOGIN_STATE_CHANGED,
			payload: false
		})

		store.dispatch({
			type: HANDLE_LOADER,
			payload: -1
		})

		return {
			userId: null,
			isLoggedIn: false
		}
	}

	if (
		Date.now() >
		(refreshTokenData?.expireIn && new Date(refreshTokenData.expireIn * 1000))
	) {
		// Get the default fast track user if there is no logged in user.
		if (castorLight) {
			return await loginFastTrackUser(store)
		}

		store.dispatch({
			type: LOGIN_STATE_CHANGED,
			payload: false
		})

		store.dispatch({
			type: HANDLE_LOADER,
			payload: -1
		})

		return {
			userId: null,
			isLoggedIn: false
		}
	}

	const tokenData = await refreshJWT()
	if (tokenData) {
		const userProfileData = await getUserProfileData(tokenData?.userId)
		const isLightUser = userProfileData?.data?.generalData?.roles?.includes(
			UserRole.LIGHT
		)
		isLoggedInSuccess(store.dispatch, userProfileData)
		await handleUserStrings(
			userProfileData?.data?.generalData?.userDetails?.email
		)

		if (castorLight && isLightUser) {
			history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
		}
		if (castorLight && !isLightUser) {
			history.push(USER_HOME_ROUTE + UPLOAD_ROUTE, {
				showCastorLightWarning: true
			})
		}

		store.dispatch({
			type: LOGIN_STATE_CHANGED,
			payload: true
		})

		store.dispatch({
			type: HANDLE_LOADER,
			payload: -1
		})

		return {
			userId: tokenData?.userId,
			isLoggedIn: true
		}
	}

	// Get the default fast track user if there is no logged in user.
	if (castorLight) {
		return await loginFastTrackUser(store)
	}

	store.dispatch({
		type: LOGIN_STATE_CHANGED,
		payload: false
	})

	store.dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})

	return {
		userId: null,
		isLoggedIn: false
	}
}

const loginFastTrackUser = async (store: Store) => {
	const response = await isLoggedIn(true)
	const tokenData = response?.data
	const loggedIn = tokenData?.isLoggedIn
	if (!loggedIn) {
		store.dispatch({
			type: HANDLE_LOADER,
			payload: -1
		})
		return {
			userId: null,
			isLoggedIn: false
		}
	}

	store.dispatch({
		type: AUTHORIZATION_TOKEN_UPDATED,
		payload: {
			token: tokenData.access_token,
			token_type: tokenData.token_type
		}
	})

	store.dispatch({
		type: REFRESH_TOKEN_UPDATED,
		payload: {
			refreshToken: tokenData.refreshToken,
			token_type: tokenData.token_type,
			refreshTokenExpireIn: tokenData.refreshTokenExpireIn
		}
	})
	history.push(USER_HOME_ROUTE + UPLOAD_ROUTE)
	const userProfileData = await getUserProfileData(tokenData?.userId)
	isLoggedInSuccess(store.dispatch, userProfileData)
	await handleUserStrings(
		userProfileData?.data?.generalData?.userDetails?.email
	)

	store.dispatch({
		type: HANDLE_LOADER,
		payload: -1
	})

	return {
		userId: tokenData?.userId,
		isLoggedIn: true
	}
}
