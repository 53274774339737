import { ChangeEvent, FC, memo } from 'react'
import React from 'react'

import Slider from '@material-ui/core/Slider'
import Close from '@material-ui/icons/Close'

import {
	axisArray,
	defaultSliderValue,
	maxSliderValue,
	minSliderValue,
	stepSliderValue
} from 'Scenes/Components/ClipPlane/constants'
import { Button } from 'Scenes/Components/thirdParty/CreativeTim/components'
import { getString } from 'Services/Strings/StringService'

import { ReactComponent as ContentCut } from 'assets/img/svg/content_cut.svg'

import './index.scss'

interface Axis {
	name: string
	color: string
	value: string
}

interface ClipPlaneProps {
	axis: string
	setAxis: Function
	disabledSlider: boolean,
	setDisabledSlider: Function,
	showSlider: boolean
	setShowSlider: Function
	sliderValue: number | string
	setSliderValue: Function
}

const ClipPlane: FC<ClipPlaneProps> = ({
	axis,
	setAxis,
	disabledSlider,
	setDisabledSlider,
	showSlider,
	setShowSlider,
	setSliderValue,
	sliderValue
}) => {
	const handleSliderChange = (
		event: ChangeEvent<{}>,
		newValue: number | number[]
	) => {
		setSliderValue(newValue)
	}

	return (
		<div className="slider-block">
			{showSlider ? (
				<div className="slider-block__axis">
					<div className="slider-block__axis-buttons">
						<div>
							{axisArray.map((singleAxis: Axis) => (
								<Button
									key={singleAxis.value}
									className={`${
										axis === singleAxis.value ? 'active' : ''
									} axis-button`}
									onClick={() => {
										setAxis(singleAxis.value)
										setSliderValue(defaultSliderValue)
										if (singleAxis.value === '0 0 0 0') {
											setDisabledSlider(true)
										} else {
											setDisabledSlider(false)
										}
									}}
								>
									{singleAxis.name}
								</Button>
							))}
						</div>
						<div>
							<Close
								onClick={() => {
									setSliderValue(defaultSliderValue)
									setShowSlider(false)
								}}
							/>
						</div>
					</div>
					<div className="slider-block__slider">
						<Slider
							value={typeof sliderValue === 'number' ? sliderValue : 0}
							onChange={handleSliderChange}
							min={minSliderValue}
							step={stepSliderValue}
							max={maxSliderValue}
							aria-labelledby="input-slider"
							className="clip-slider"
							classes={{
								rail: 'clip-rail',
								track: 'clip-track',
								thumb: 'clip-thumb'
							}}
							disabled={disabledSlider}
						/>
					</div>
				</div>
			) : (
				<div className="slider-block__button">
					<Button
						className="clipping-button"
						onClick={() => setShowSlider(true)}
					>
						<ContentCut />
						{getString('CLIPPING_PLANE')}
					</Button>
				</div>
			)}
		</div>
	)
}

export default memo(ClipPlane)
