import { isNull } from 'lodash'

import {
	GET_PROJECTS,
	GET_PROJECTS_FAILED,
	GET_PROJECTS_SUCCESS,
	HANDLE_LOADER,
	USER_HOME_DRAW_TOGGLE,
	USER_HOME_ROUTE_CHANGED
} from '../../global actions/types'
import { store } from '../../index'
import { UNDEFINED_STRING } from '../../Services/Constants'
import { UPLOAD_ROUTE } from '../../Services/Constants/RoutesConstants'
import {
	getStringItemFromLocalStorage,
	setStringItemToLocalStorage
} from '../../Services/LocalStorageService'
import { getProjects } from '../../Services/Network'
import { getShowSideBarValue } from './UserHomeService'
import { checkReanalysisProjectsPoller } from 'global actions/UserActions'
import { gerUrlParams } from 'Services/Utils/urlTools'

export const setupUserHome = features => {
	return async dispatch => {
		const { maxAllowedUploadProjects } = store.getState().user
		dispatch({
			type: HANDLE_LOADER,
			payload: 1
		})
		dispatch({ type: GET_PROJECTS })

		try {
			const { showSideBar = true } = gerUrlParams(['showSideBar'])
			const sideBarIsOn = getShowSideBarValue(showSideBar)

			const response = await getProjects()
			const projects = response?.data?.projects
			if (projects && projects[0]?.owner) {
				dispatch({
					type: GET_PROJECTS_SUCCESS,
					payload: {
						projects,
						features,
						maxAllowedUploadProjects,
						showSideBar: sideBarIsOn
					}
				})
				dispatch(checkReanalysisProjectsPoller(projects[0]?.owner))
			} else {
				dispatch({
					type: GET_PROJECTS_SUCCESS,
					payload: {
						projects,
						features,
						maxAllowedUploadProjects,
						showSideBar: sideBarIsOn
					}
				})
			}
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
		} catch (error) {
			dispatch({
				type: HANDLE_LOADER,
				payload: -1
			})
			console.log(error)
			dispatch({ type: GET_PROJECTS_FAILED, payload: error.message })
		}
	}
}

export const drawToggledUser = () => {
	return { type: USER_HOME_DRAW_TOGGLE }
}

export const onRouteChange = crumbs => {
	return { type: USER_HOME_ROUTE_CHANGED, payload: { crumbs } }
}
