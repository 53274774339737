import React from 'react'
import Flexbox from 'Scenes/Components/FlexBox'

import HeaderCard from '../thirdParty/CreativeTim/components/Cards/HeaderCard'
import { getTheme } from 'themes/getTheme'

import { styles } from './styles.css'

const { cardBoxColor } = getTheme()

const CastorForm = props => {
	const {
		formHeader,
		formTitle,
		formSubTitle,
		content,
		style,
		superViewStyle,
		alertType
	} = props
	return (
		<Flexbox
			style={{ ...styles.superview, ...superViewStyle }}
			flexDirection="column"
		>
			{formHeader}
			<div style={{ ...styles.cardDiv, ...style }}>
				<HeaderCard
					cardTitle={formTitle}
					cardSubtitle={formSubTitle}
					headerColor={cardBoxColor || 'green'}
					content={content}
					alertType={alertType}
				/>
			</div>
		</Flexbox>
	)
}

export default CastorForm
