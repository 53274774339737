import { sortBy } from 'lodash'
import {
  USER_PROFILE_ADDRESS_SELECTED,
  USER_PROFILE_ADDRESS_CHANGED,
  USER_PROFILE_GET_ALL_ADDRESSES,
  USER_PROFILE_GET_ALL_ADDRESSES_ERROR,
  USER_PROFILE_GET_ALL_ADDRESSES_LOADING
} from '../../../../global actions/types'
import { SUCCESS, ERROR } from '../../../../Services/Strings'
import { IState, ICountry } from './addressInfo/ICountryStateCity'
import { US } from './addressInfo/countryStateCity'

const makeAddress = (
  country: ICountry | null,
  state: IState| null,
  cityString?: string
) => {
  let addrArr = []
  if (cityString) addrArr.push(cityString)
  if (state) addrArr.push(state?.n)
  if (country) addrArr.push(country?.n)

  return addrArr.join(', ')
}

export const onAddressDropdownSelected = (
  country: ICountry | null,
  state: IState| null,
  cityString?: string
) => {
  return async (dispatch: any) => {
    const fullAddress = makeAddress(country, state, cityString)
    const isNotValidAddress = !country || (country.iC === US && !state)

    const parsedAddress = {
      formatted_address: fullAddress,
      city: cityString,
      state: state?.n || '',
      country: country?.n || '',
      zip_code: '',
      long: state?.long || country?.long || '',
      lat:  state?.lat || country?.lat || '',
      countryCode: country?.iC || ''
    }

    dispatch({
      type: USER_PROFILE_ADDRESS_CHANGED,
      payload: isNotValidAddress? ERROR: SUCCESS
    })

    dispatch({
      type: USER_PROFILE_ADDRESS_SELECTED,
      payload: {
        address: fullAddress,
        ...parsedAddress
      }
    })
  }
}

/**
 * Function get Json file from URL and return it in the callback
 * @param {string} url
 */
const fetchJson = (url: string) => {
  return fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    }
  ).then((response) => {
    return response.json();
  })
}

export const onGetAllAddresses = () => {
  return async (dispatch: any) => {
    dispatch({
      type: USER_PROFILE_GET_ALL_ADDRESSES_LOADING,
      payload: {
        isLoadingAddress: true
      }
    })

    try {
      const getCountries = await fetchJson(`${process.env.PUBLIC_URL}/json/country.json`)
      const getStates = await fetchJson(`${process.env.PUBLIC_URL}/json/state.json`)

      const sortedCountries = sortBy(getCountries, ['n'])
      const sortedStates = sortBy(getStates, ['n'])

      dispatch({
        type: USER_PROFILE_GET_ALL_ADDRESSES,
        payload: {
          allCountries: sortedCountries,
          allStates: sortedStates,
        }
      })
    } catch (e) {
      dispatch({
        type: USER_PROFILE_GET_ALL_ADDRESSES_ERROR,
      })
    }

    dispatch({
      type: USER_PROFILE_GET_ALL_ADDRESSES_LOADING,
      payload: {
        isLoadingAddress: false
      }
    })
  }
}
