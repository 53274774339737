import { isEmpty, isNil, map, some } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import { ProjectAnalysisPartsView } from '../ProjectAnalysis/ProjectAnalysisInterfaces'
import {
	CHANGE_FILTERS_KEY,
	CHANGE_INITIAL_PROJECT_SETUP,
	CHANGE_PARTS_PAGE,
	CHANGE_PARTS_VIEW,
	CHANGE_SEARCH_PHRASE,
	DO_REFRESH_CONFIGURATIONS,
	GET_PROJECT_ANALYSIS_NO_PERMISSION,
	GET_PROJECT_PARTS_STARTED,
	GET_PROJECT_PARTS_SUCCESS,
	GET_PROJECT_PARTS_TO_PRINT_FROM_STATE,
	GET_PROJECT_PARTS_TO_PRINT_STARTED,
	GET_PROJECT_PARTS_TO_PRINT_SUCCESS,
	GRAB_CAD_PRINT_UPDATED,
	MPIO_REQUEST,
	MPIO_REQUEST_SUCCESS,
	PART_ANALYSIS_WALL_THICKNESS_UPDATED,
	PART_FINANCIAL_UPDATED,
	PROJECT_ANALYSIS_PART_REMOVED,
	PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR,
	PROJECT_ANALYSIS_REMOVE_ALERT,
	PROJECT_ANALYSIS_REMOVE_PART_CANCELED,
	PROJECT_ANALYSIS_REMOVE_PART_CLICKED,
	PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED,
	PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED,
	PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED,
	PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED,
	PROJECT_PARTS_PROPERTIES_CALCULATING,
	PROJECT_PARTS_PROPERTIES_FETCHED,
	PROJECT_PARTS_PROPERTIES_RESET,
	PROJECT_PARTS_PROPERTIES_UPDATE_PART,
	PROJECT_WEIGHT_REDUCTION_CHANGED,
	PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED,
	PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED,
	PROJECT_WEIGHT_REDUCTION_UPDATED,
	STAR_PART_SUCCESS
} from 'global actions/types'
import { filters } from 'Scenes/Components/FilterPartsGrid/filterPartsEnum'
import {
	changeFilterValue,
	prepareFiltersForLocalStorage,
	prepareFiltersForReducer
} from 'Scenes/Components/FilterPartsGrid/FilterPartsService'
import { isPartWeightReduced } from 'Scenes/Components/WeightReduction/WeightReductionService'
import { getClustersWithImages } from 'Scenes/Home/PrintableClusters/PrintableClustersOverviewReducer'
import {
	addReasonsToUnprintableParts,
	createPaginationData,
	createPartsToPrintSummary,
	getAllBenefitsState,
	getClusterRequestText,
	getClustersStatuses,
	getClusterState,
	getOnlyPartState,
	getPartsStandardCosts,
	getPartValue,
	getWeightReductionState,
	getWightReductionStatuses
} from 'Scenes/Home/ProjectAnalysis/ProjectAnalysisService'
import {
	GET_PROJECTS_BUNGLE,
	RESET_PROJECTS_BUNGLE
} from 'Scenes/Home/ProjectBundle/ProjectBundleTypes'
import { partsPageLimit } from 'Services/Constants'
import {
	getJsonItemFromLocalStorage,
	getStringItemFromLocalStorage,
	setJsonItemToLocalStorage,
	setStringItemToLocalStorage
} from 'Services/LocalStorageService'
import { IBestMatchData } from 'Services/models/IBestMatch'
import { Part } from 'Services/models/IPart'
import { PartProperty } from 'Services/models/IPartPropertiest'
import {
	Project,
	ProjectClusterStatus,
	ProjectStatus
} from 'Services/models/IProject'

const CHANGED = 'changed'

const INITIAL_STATE = {
	bundleId: null,
	project: {},
	projects: [],
	parts: [] as Part[],
	allClusters: [] as any[],
	partsProperties: [],
	partsPropertiesAll: [] as Part[],
	weightReducedParts: [],
	allPartsInapplicable: false,
	selectedFilterPart: null,
	searchPhrase: '',
	isSinglePartProject: false,
	is2dProject: false,
	onlyPart: {} as Part,
	onlyPartResultTitle: '',
	numberOfPrintablePart: 0,
	numberOfPrintableParts: 0,
	numberOfAllBenefits: 0,
	benefitsState: [],
	selectedFilterValues: [filters.PRINTABLE],
	showMultiplePartsIntoOneRequest: true,
	showMultiplePartsIntoOneResults: false,
	projectWeightReductionStatus: '',
	clusterRequested: false,
	clusterRequestText: '',
	projectClusterStatus: '',
	weightReductionRequested: false,
	weightReductionLoading: false,
	weightReductionRequestText: '',
	weightReductionItemsWithPictures: [],
	weightReducedSuggestedParts: [],
	projectWeightReductionThreshold: 0,
	projectWeightReductionMinimumThicknessThreshold: 0,
	updateWeightReductionButtonLoading: false,
	clusterItemsWithPictures: [],
	partsStandardCosts: [],
	initialStandardCosts: null,
	showStandardCostAlert: false,
	updateStandardCostLoading: false,
	disableStandardCostSubmit: true,
	showStandardCostNumberError: false,
	showNameDuplicationError: false,
	showStandardCostError: false,
	showStandardCostFinishIcon: false,
	requestedRemovePartId: null,
	requestedRemovePartName: '',
	showingSimpleAlertText: null,
	showingSimpleAlertTitle: null,
	showRemovePartAlert: false,
	loadingRemovePart: false,
	doRefreshConfigurations: false,
	loading: false,
	userHasNoPermissions: false,
	partsStandardCostsBeforeChanges: [],
	partsView: ProjectAnalysisPartsView.list,
	leadingConfigurationData: [] as IBestMatchData[],
	paginationData: {
		page: 1,
		limit: partsPageLimit,
		totalPartsCount: 0,
		totalPagesCount: 1,
		enableNext: false,
		enablePrev: false,
		showingFrom: 0,
		showingTo: 0
	},
	partsLoading: false,
	partsToPrintLoading: false,
	partsToPrint: [],
	partsToPrintSummary: [],
	totalPartsCount: 0,
	standalonePartsCount: 0,
	printabilityData: {
		borderline: 0,
		failed: 0,
		missingInformation: 0,
		notCostEffective: 0,
		notPrintable: 0,
		printable: 0
	},
	weightReductionPartsLeadingData: [] as IBestMatchData[],
	initialSetup: true,
	refetchParts: false
}

const ProjectBundleReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case RESET_PROJECTS_BUNGLE: {
			return {
				...INITIAL_STATE
			}
		}

		case GET_PROJECTS_BUNGLE: {
			const { bundleId, projects, partsResults, features, clusters } =
				action.payload

			const projectWeightReductionThreshold =
				projects[0]?.weightReductionThreshold
			const projectWeightReductionMinimumThicknessThreshold =
				projects[0]?.weightReductionMinimumThicknessThreshold

			const clustersCount = clusters.length

			const filteredClusterWithPictures = getClustersWithImages(clusters)
			const clusterStatus = getClustersStatuses(projects, clustersCount)
			const weightReductionStatus = getWightReductionStatuses(projects)

			const getFilterFromLocal = getJsonItemFromLocalStorage(
				`filter-${bundleId}`
			)
			const partsViewFromLocal = getStringItemFromLocalStorage(
				`view-${bundleId}`
			)

			const selectedFilterValuesFromLocal =
				(!isEmpty(getFilterFromLocal) && getFilterFromLocal.split(',')) || null

			const preparedFiltersFromLocal = prepareFiltersForReducer(
				selectedFilterValuesFromLocal
			)

			// if there is no selected filter we need to pass by default and save it
			// if some part is Printable => by default Printable, if not => All
			const selectedFilterValues = preparedFiltersFromLocal
				? preparedFiltersFromLocal
				: partsResults?.isSomePrintable
				? state.selectedFilterValues
				: [filters.ALL]

			const selectedPartsView = partsViewFromLocal
				? partsViewFromLocal
				: state.partsView

			const selectedFilterValuesToLS =
				prepareFiltersForLocalStorage(selectedFilterValues)

			const projectStatus = some(
				projects,
				(project: Project) => project.status === ProjectStatus.published
			)
				? ProjectStatus.published
				: ProjectStatus.awaitingAnalysis

			const metadataParts = projects.reduce(
				(accumulator: number, currentValue: Project) => {
					return accumulator + currentValue?.metadataParts
				},
				0
			)

			// do not set filters to ls until all parts are analyzed
			if (projectStatus === ProjectStatus.published) {
				setJsonItemToLocalStorage(
					`filter-${state.bundleId}`,
					`${selectedFilterValuesToLS}`
				)
				setStringItemToLocalStorage(`view-${state.bundleId}`, selectedPartsView)
			}

			const project = {
				id: bundleId,
				isAssembly: false,
				name: projects[0]?.bundle?.name,
				assemblyImage: null,
				status: projectStatus,
				clusters: clustersCount,
				metadataParts: metadataParts,
				clusterStatus: clusterStatus,
				reductions: projects.reduce(
					(accumulator: number, currentValue: Project) => {
						return accumulator + currentValue.reductions
					},
					0
				),
				recalculateClusters: some(
					projects,
					(project: Project) => project.recalculateClusters
				),
				offerMultiplePartsIntoOne: some(
					projects,
					(project: Project) => project.offerMultiplePartsIntoOne
				),
				partsWeightReductionNumber: 1,
				weightReductionStatus: weightReductionStatus,
				amountOfLockedParts: projects.reduce(
					(total: number, { lockedParts }: any) => total + (lockedParts || 0),
					0
				),
				lockedParts: some(
					projects,
					(project: Project) => project.lockedParts > 0
				)
			}

			const partsStandardCosts = getPartsStandardCosts(
				partsResults?.partsWithStandardCost,
				partsResults?.totalPartsCount
			)

			return {
				...state,
				bundleId,
				selectedFilterValues,
				project,
				projects,
				allPartsInapplicable: partsResults?.allPartsInapplicable,
				allClusters: clusters,
				searchPhrase: '',
				projectWeightReductionThreshold,
				showStandardCostFinishIcon: false,
				projectWeightReductionMinimumThicknessThreshold,
				totalPartsCount: partsResults?.totalPartsCount,
				standalonePartsCount: partsResults?.standalonePartsCount,
				partsStandardCosts,
				initialStandardCosts: partsStandardCosts,
				numberOfPrintableParts: partsResults?.printablePartsCount,
				partsView: selectedPartsView,
				...filteredClusterWithPictures,
				printabilityData: partsResults?.printabilityData,
				...getAllBenefitsState(
					partsResults?.printablePartsCount,
					partsResults?.weightReductionParts.length,
					partsResults?.benefitsData,
					project,
					features,
					partsResults?.is2dProject
				),
				...getClusterState(project),
				...getWeightReductionState(
					partsResults?.weightReductionParts,
					project,
					features
				),
				weightReductionPartsLeadingData:
					partsResults?.weightReductionPartsLeadingData
			}
		}

		case MPIO_REQUEST_SUCCESS:
			return {
				...state,
				clusters: [],
				clusterItemsWithPictures: []
			}

		case MPIO_REQUEST:
			return {
				...state,
				clusterRequested: true,
				clusterRequestText: getClusterRequestText(
					ProjectClusterStatus.awaitingAnalysis
				),
				projectClusterStatus: ProjectClusterStatus.awaitingAnalysis
			}

		case CHANGE_FILTERS_KEY: {
			const { selectedFilter, isBundle } = action.payload
			const { selectedFilterValues } = state
			if (!isBundle) return state
			let updatedFilterValues = changeFilterValue(
				selectedFilter,
				selectedFilterValues
			)
			const updatedValuesToLS =
				prepareFiltersForLocalStorage(updatedFilterValues)

			setJsonItemToLocalStorage(
				`filter-${state.bundleId}`,
				`${updatedValuesToLS}`
			)

			return {
				...state,
				selectedFilterValues: updatedFilterValues,
				paginationData: {
					...state.paginationData,
					page: 1
				}
			}
		}

		case CHANGE_SEARCH_PHRASE:
			const searchPhrase = action.payload
			return { ...state, searchPhrase }

		case PROJECT_WEIGHT_REDUCTION_UPDATE_CLICK_TOGGLED:
			return {
				...state,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading
			}

		case PROJECT_WEIGHT_REDUCTION_UPDATED: {
			const { partsResults, features } = action.payload
			const {
				is2dProject,
				allPartsInapplicable,
				onlyPart,
				weightReductionParts,
				printablePartsCount,
				totalPartsCount,
				benefitsData
			} = partsResults

			const project = {
				...state.project,
				weightReductionThreshold: state.projectWeightReductionThreshold
			}

			return {
				...state,
				project,
				is2dProject,
				updateWeightReductionButtonLoading:
					!state.updateWeightReductionButtonLoading,
				onlyPart,
				allPartsInapplicable,
				totalPartsCount,
				...getWeightReductionState(
					weightReductionParts,
					state.project,
					features
				),
				...getAllBenefitsState(
					printablePartsCount,
					weightReductionParts.length,
					benefitsData,
					state.project,
					features,
					is2dProject
				)
			}
		}
		case PROJECT_WEIGHT_REDUCTION_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionThreshold: value
			}
		}
		case PROJECT_ANALYSIS_STANDARD_COST_CLICK_TOGGLED:
			return {
				...state,
				showStandardCostAlert: !state.showStandardCostAlert,
				partsStandardCosts: state.initialStandardCosts
			}

		case PART_ANALYSIS_WALL_THICKNESS_UPDATED:
		case PART_FINANCIAL_UPDATED:
		case STAR_PART_SUCCESS: {
			const starredPart = action.payload
			const parts = state.parts.map((part: Part) =>
				part.id === starredPart.id ? starredPart : part
			)
			let onlyPart: Part = state.onlyPart as Part
			if (starredPart.id === onlyPart?.id) {
				onlyPart = starredPart
			}

			return { ...state, parts, onlyPart }
		}

		case PROJECT_ANALYSIS_REMOVE_PART_CLICKED: {
			const { partId, partName } = action.payload
			return {
				...state,
				requestedRemovePartId: partId,
				requestedRemovePartName: partName,
				showRemovePartAlert: true
			}
		}

		case PROJECT_ANALYSIS_REMOVE_ALERT:
			return {
				...state,
				showingSimpleAlertTitle: null,
				showingSimpleAlertText: null
			}

		case PROJECT_ANALYSIS_REMOVE_PART_CANCELED:
		case PROJECT_ANALYSIS_PART_REMOVED_GOT_ERROR:
			return {
				...state,
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false
			}

		case PROJECT_ANALYSIS_REMOVE_PART_CONFIRMED:
			return {
				...state,
				loadingRemovePart: true
			}

		case PROJECT_ANALYSIS_PART_REMOVED: {
			const { partId, features, clusters, partsResults, isBundle } =
				action.payload
			if (!isBundle) return state
			const {
				is2dProject,
				printablePartsCount,
				isSinglePartProject,
				onlyPart,
				partsWithStandardCost,
				totalPartsCount,
				weightReductionParts,
				benefitsData,
				printabilityData,
				standalonePartsCount
			} = partsResults
			const part: Part[] = state.parts.filter(
				(part: Part) => part.id === partId
			)

			const parts = state.parts.filter((part: Part) => part.id !== partId)
			const allClusters: any = state.allClusters
			const clustersWhenDeletedPart = allClusters.filter((cluster: any) => {
				return !some(cluster.compositionSetParts, cl => {
					return cl.partId === part[0].externalId
				})
			})

			const partsStandardCosts = getPartsStandardCosts(
				partsWithStandardCost,
				totalPartsCount
			)

			return {
				...state,
				parts,
				is2dProject,
				totalPartsCount,
				standalonePartsCount,
				printabilityData,
				...getOnlyPartState(isSinglePartProject, onlyPart, state),
				...getWeightReductionState(
					weightReductionParts,
					state.project,
					features
				),
				project: {
					...state.project,
					recalculateClusters: true
				},
				isSinglePartProject,
				loadingRemovePart: false,
				requestedRemovePartId: null,
				requestedRemovePartName: '',
				showRemovePartAlert: false,
				allClusters: clustersWhenDeletedPart,
				...getClustersWithImages(clustersWhenDeletedPart),
				...getAllBenefitsState(
					printablePartsCount,
					weightReductionParts.length,
					benefitsData,
					{ ...state.project, clusters },
					features,
					is2dProject
				),
				partsStandardCosts,
				initialStandardCosts: partsStandardCosts
			}
		}

		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATED:
			const { data } = action.payload
			const prevPartsStandardCosts = state.partsStandardCosts
			let disableStandardCostSubmit = false
			let showStandardCostError = false
			let showStandardCostNumberError = false
			let showNameDuplicationError = false
			let emptyData = true

			const partsStandardCosts = data.map((row: any, key: any) => {
				// remove the space at the beginning and at the end
				let partName = getPartValue(row[0].value) as string

				let partStandardCost: any = getPartValue(row[1].value)
				const isSinglePartProject = state.isSinglePartProject

				if (partName && partStandardCost) {
					emptyData = false
				}

				// const partId = row[0].id
				const part = isSinglePartProject
					? state.onlyPart.partNumber.toLowerCase() ===
							partName.toLowerCase() && state.onlyPart
					: state.partsPropertiesAll.find(
							part => part.partNumber.toLowerCase() === partName.toLowerCase()
					  )

				// check if part is already exist
				prevPartsStandardCosts &&
					prevPartsStandardCosts.forEach((part: any[], i) => {
						if (key !== i) {
							const existPartName = getPartValue(part[0].value)

							if (partName && partName === existPartName) {
								showNameDuplicationError = true
							}
						}
					})

				const partNameValid = !partName || !!part
				const standardCostValid =
					(!isNaN(partStandardCost) && partStandardCost > 0) ||
					partStandardCost === ''

				if (!partNameValid) {
					showStandardCostError = true
				}

				if (!standardCostValid) {
					showStandardCostNumberError = true
				}

				if (!partNameValid || !standardCostValid || showNameDuplicationError) {
					disableStandardCostSubmit = true
				}

				if (partStandardCost === '' && partName) {
					disableStandardCostSubmit = false
				}

				if (isEmpty(partName) && !!+partStandardCost) {
					showStandardCostError = true
					disableStandardCostSubmit = true
				}

				return [
					{ value: partName, valid: partNameValid },
					{ value: partStandardCost, valid: standardCostValid }
				]
			})
			return {
				...state,
				partsStandardCosts,
				showStandardCostError,
				showStandardCostNumberError,
				showNameDuplicationError,
				disableStandardCostSubmit: disableStandardCostSubmit,
				partsStandardCostsBeforeChanges:
					state.partsStandardCostsBeforeChanges?.length === 0
						? state.partsStandardCosts
						: state.partsStandardCostsBeforeChanges
			}

		case PROJECT_ANALYSIS_STANDARD_COST_LOADER_TOGGLED:
			return {
				...state,
				updateStandardCostLoading: !state.updateStandardCostLoading
			}

		case PROJECT_ANALYSIS_STANDARD_COST_DATA_UPDATE_FINISHED: {
			const { partsResults, features } = action.payload
			const {
				is2dProject,
				numberOfFailedParts,
				printablePartsCount,
				isSinglePartProject,
				onlyPart,
				weightReductionParts,
				totalPartsCount,
				benefitsData,
				printabilityData,
				partsWithStandardCost
			} = partsResults

			return {
				...state,
				is2dProject,
				printabilityData,
				numberOfFailedParts,
				totalPartsCount,
				isSinglePartProject,
				...getOnlyPartState(isSinglePartProject, onlyPart, state),
				...getAllBenefitsState(
					printablePartsCount,
					weightReductionParts.length,
					benefitsData,
					state.project,
					features,
					is2dProject
				),
				updateStandardCostLoading: false,
				showStandardCostAlert: false,
				showStandardCostFinishIcon: true,
				partsStandardCostsBeforeChanges: [],
				initialStandardCosts: getPartsStandardCosts(
					partsWithStandardCost,
					totalPartsCount
				)
			}
		}

		case DO_REFRESH_CONFIGURATIONS: {
			const { doRefreshConfigurations, afterStandardCostUpdate, refetchParts } =
				action.payload

			return {
				...state,
				doRefreshConfigurations: afterStandardCostUpdate
					? state.doRefreshConfigurations
					: doRefreshConfigurations,
				refetchParts
			}
		}

		case PROJECT_WEIGHT_REDUCTION_MIN_THICKNESS_CHANGED: {
			const { value } = action.payload
			return {
				...state,
				projectWeightReductionMinimumThicknessThreshold: value
			}
		}

		case PROJECT_PARTS_PROPERTIES_FETCHED: {
			const {
				partsProperties: properties,
				rowIndex,
				inapplicablePartsProperties,
				parts,
				projectId
			} = action.payload
			if (projectId && projectId !== state.bundleId) {
				return state
			}
			const { partsProperties } = state

			let updatedPartProperties = cloneDeep(properties)

			if (rowIndex !== -1 && !isNil(rowIndex)) {
				updatedPartProperties = cloneDeep(partsProperties)
				updatedPartProperties[rowIndex] = properties[rowIndex]
				updatedPartProperties[rowIndex][CHANGED] = false
			} else {
				updatedPartProperties = updatedPartProperties.map(
					(property: PartProperty | any) => {
						property[CHANGED] = false
						return property
					}
				)
			}

			return {
				...state,
				partsProperties: updatedPartProperties,
				inapplicablePartsProperties,
				partsPropertiesAll: parts
			}
		}

		case PROJECT_PARTS_PROPERTIES_CALCULATING: {
			const { calculating, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesCalculating: {
					calculating,
					rowIndex
				}
			}
		}
		case PROJECT_PARTS_PROPERTIES_RESET: {
			const { reset, rowIndex } = action.payload
			return {
				...state,
				partsPropertiesReset: {
					reset,
					rowIndex
				}
			}
		}

		case PROJECT_PARTS_PROPERTIES_UPDATE_PART: {
			const { partId } = action.payload
			return {
				...state,
				partId
			}
		}
		case GRAB_CAD_PRINT_UPDATED: {
			const { partId, toggleGrabCad } = action.payload
			return {
				...state,
				parts: map(state.parts, (part: Part) => {
					if (part.id === partId) {
						return {
							...part,
							grabCadPrint: !toggleGrabCad
						}
					}

					return part
				})
			}
		}
		case GET_PROJECT_ANALYSIS_NO_PERMISSION: {
			return {
				...state,
				userHasNoPermissions: true
			}
		}

		case CHANGE_PARTS_VIEW: {
			const { view } = action.payload

			setStringItemToLocalStorage(`view-${state.bundleId}`, view)

			return {
				...state,
				partsView: view
			}
		}

		case GET_PROJECT_PARTS_SUCCESS: {
			const { parts, totalPartsCount, leadingConfigurationData, user } =
				action.payload

			// pass isUsedByCluster if part.externalId is existed in compositionSetPart
			for (let cluster of state.allClusters) {
				for (let compositionSetPart of cluster.compositionSetParts) {
					// check and changed All parts
					for (let part of parts) {
						if (compositionSetPart.partId === part.externalId) {
							part.isUsedByCluster = true
						}
					}
				}
			}

			const filteredParts = parts.filter(
				(part: Part) => !isPartWeightReduced(part)
			)

			return {
				...state,
				parts: addReasonsToUnprintableParts(
					filteredParts,
					leadingConfigurationData,
					user
				),
				weightReducedParts: filteredParts?.filter((part: Part) =>
					isPartWeightReduced(part)
				),
				paginationData: createPaginationData(
					state.paginationData.page,
					state.paginationData.limit,
					totalPartsCount,
					filteredParts.length
				),
				partsLoading: false,
				leadingConfigurationData,
				refetchParts: false
			}
		}

		case GET_PROJECT_PARTS_STARTED: {
			return {
				...state,
				partsLoading: true
			}
		}

		case CHANGE_PARTS_PAGE: {
			const { page } = action.payload

			return {
				...state,
				paginationData: {
					...state.paginationData,
					page
				}
			}
		}

		case GET_PROJECT_PARTS_TO_PRINT_STARTED: {
			return {
				...state,
				partsToPrintLoading: true
			}
		}

		case GET_PROJECT_PARTS_TO_PRINT_SUCCESS: {
			const { parts, leadingConfigurationData } = action.payload
			return {
				...state,
				partsToPrintLoading: false,
				partsToPrint: parts,
				partsToPrintSummary: createPartsToPrintSummary(parts),
				leadingConfigurationData
			}
		}

		case GET_PROJECT_PARTS_TO_PRINT_FROM_STATE: {
			return {
				...state,
				partsToPrint: state.parts,
				partsToPrintSummary: createPartsToPrintSummary(state.parts)
			}
		}

		case CHANGE_INITIAL_PROJECT_SETUP: {
			return {
				...state,
				initialSetup: action.payload
			}
		}

		default:
			return state
	}
}

export default ProjectBundleReducer
