import { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import Dropzone from 'react-dropzone'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import cx from 'classnames'
import { isEmpty, toNumber } from 'lodash'

import LightUserUploadProjectAlert from '../../LightUserUploadProjectAlert/LightUserUploadProjectAlert'
import {
	addedFiles,
	draggingToDropzoneChangedUpload,
	onDropDefaultFile,
	removeError,
	removeFile,
	uploadProjectClicked
} from '../../UploadProjectActions'
import UnitTypeSelector from './UnitTypeSelector'
import UploadAgreement from './UploadAgreement'
import UploadImage from './UploadImage'
import UploadListItems from './UploadListItems'
import { AdvancedSettingsInitialState } from 'Scenes/Components/AdvancedSettings/AdvancedSettingsReducer'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import ButtonWithLoader from 'Scenes/Components/ButtonWithLoader'
import CardHeaderBox from 'Scenes/Components/CardHeaderBox'
import WarnMessage from 'Scenes/Components/CastorWarn'
import Flexbox from 'Scenes/Components/FlexBox'
import { ToleranceClass } from 'Scenes/Components/toleranceClassMenu/toleranceClassMenu'
import { onInHousePrintersAlertOpen } from 'Scenes/Home/Customize/CustomizeInHousePrinters/CustomizeInHousePrintersActions'
import { METADATA } from 'Scenes/Home/NewUploadProject/constants'
import { UPLOAD } from 'Scenes/Home/NewUploadProject/constants'
import {
	uploadInfoMessage,
	useUploadErrorMessage
} from 'Scenes/Home/NewUploadProject/UploadBlocks/UploadFiles/UploadFilesHook'
import { getAcceptedFileFormats } from 'Scenes/Home/NewUploadProject/UploadProjectActionService'
import { UserRole } from 'Scenes/Home/UserRole.enum'
import {
	BOMRadioValue,
	chosenMaterialRadioValue,
	drawingFiles,
	STEP,
	STP
} from 'Services/Constants'
import { getExtensionFromFileName } from 'Services/getExtensionFromFileName'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { IUserFilterNames } from 'Services/models/IUserFilter'
import { PART, PARTS } from 'Services/Strings'
import { getString } from 'Services/Strings/StringService'
import { getTheme } from 'themes/getTheme'

import { ReactComponent as DownloadBom } from 'assets/img/svg/icons/download_bom.svg'

import './index.scss'

const { uploadBoxClass } = getTheme()

interface IProps {
	is2dUpload: boolean
	selectedType: any
	isDrawingFeatureOn?: boolean
}

const UploadFiles: FC<IProps> = ({
	is2dUpload,
	isDrawingFeatureOn,
	selectedType
}) => {
	const isMetaData = selectedType === METADATA
	const user = useSelector((state: RootStateOrAny) => state.user)

	const isLightUser = useMemo(
		() => user.roles.includes(UserRole.LIGHT),
		[user.roles]
	)
	const fileLimit = isLightUser
		? toNumber(user.maxAllowedLightUploadParts)
		: toNumber(user.maxAllowedUploadParts)
	const [showTrialUserPopup, setShowTrialUserPopup] = useState<boolean>(false)
	const [isFileAmountLimit, setIsFileAmountLimit] = useState<boolean>(false)
	//prevent showing errors for light user at first
	const [initialDisableErrors, setInitialDisableErrors] =
		useState<boolean>(isLightUser)
	const showAgreement = Feature.isFeatureOn(FeatureComponentId.SHOW_AGREEMENT)
	const addProjectName = Feature.isFeatureOn(
		FeatureComponentId.ADD_PROJECT_NAME
	)
	const [showUploadProjectPopup, setShowUploadProjectPopup] = useState(false)
	const [isStepFile, setIsStepFiles] = useState(false)
	const [isDefaultFile, setIsDefaultFile] = useState(false)
	const [agreement, setAgreement] = useState(!showAgreement)
	const {
		error,
		loading,
		filesToUpload,
		userRemainingPart,
		userDateExpired,
		draggingFiles,
		radioButtonSelected,
		projectName,
		quantity,
		ERP,
		CAD,
		unitType,
		toleranceIncluded,
		customToleranceValue,
		application,
		showUnitTypeSelector,
		selectedProjectScenario,
		BOMFile,
		errorUnit,
		projectStandardCostError,
		manufactureMethodError,
		projectStandardCost,
		manufactureMethod,
		amValue,
		trialUserPopupShown,
		trialUserPopupErrorMessage,
		enableUploadWithErrorMessage,
		filesWithMaterialAndQuantity,
		isStandardCostBoxChecked
	} = useSelector((state: RootStateOrAny) => state.uploadProject)
	const { filters } = useSelector(
		(state: RootStateOrAny) =>
			state.AdvancedSettingsReducer?.advancedStates[UPLOAD] ||
			new AdvancedSettingsInitialState()
	)
	const materialChosen = useSelector(
		(state: RootStateOrAny) => state.MaterialSelectorReducer?.material
	)
	const lightUserMultipleUploads = Feature.isFeatureOn(
		FeatureComponentId.LIGHT_USER_MULTIPLE_UPLOADS
	)
	const isCurrentStandardCostOn = Feature.isFeatureOn(
		FeatureComponentId.CURRENT_STANDARD_COST
	)
	const showStepfilesWarning = Feature.isFeatureOn(
		FeatureComponentId.STEP_FILE_WINDOW_UPLOAD_PAGE
	)
	const personalizedLightUser =
		lightUserMultipleUploads && user?.userDetails.name
	const { printers } = useSelector((state: RootStateOrAny) => state.user)
	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {
		if (trialUserPopupShown != null) {
			setShowTrialUserPopup(trialUserPopupShown)
		}
	}, [trialUserPopupShown])

	//ref to call dropzone
	const uploadRef: any = useRef()

	const disableGeneralDropFiles = filesToUpload.length >= fileLimit

	const dragEvent = (show: boolean) => {
		dispatch(draggingToDropzoneChangedUpload(show))
	}

	const handleDrop = (
		files: File[],
		is2dUpload: boolean,
		rejectedFiles: File[]
	) => {
		if (disableGeneralDropFiles || files.length > fileLimit) {
			dispatch(removeError())
			setInitialDisableErrors(false)
			setIsFileAmountLimit(true)
			return
		}
		setIsFileAmountLimit(false)
		dispatch(
			addedFiles(
				files,
				user?.acceptedFileTypes,
				user?.notSupportedUnitTypeFormats,
				selectedType,
				user?.partsFileSizeLimit,
				rejectedFiles
			)
		)
	}
	useEffect(() => {
		setIsStepFiles(false)
		filesToUpload.map((file: any) => {
			if (
				[STEP, STP].includes(getExtensionFromFileName(file.name).toUpperCase())
			) {
				setIsStepFiles(true)
			}
		})
	}, [filesToUpload])
	const handleDefaultFileDrop = () => {
		dispatch(
			onDropDefaultFile(
				user?.acceptedFileTypes,
				user?.notSupportedUnitTypeFormats,
				selectedType,
				user?.defaultFileName
			)
		)
		setIsDefaultFile(true)
	}
	const submitFormClicked = (
		email: string = '',
		name: string = '',
		company = '',
		zipCode= ''
	) => {
		const BOMFileSelected =
			radioButtonSelected === BOMRadioValue ? BOMFile : null
		const projectCost = projectStandardCost > 0 ? projectStandardCost : null
		const projectManufacturing = isEmpty(manufactureMethod)
			? null
			: manufactureMethod
		const materialChosenSelected =
			radioButtonSelected === chosenMaterialRadioValue ? materialChosen : null
		const drawingMaterial = radioButtonSelected === drawingFiles
		const skipFileUpload = isDefaultFile
		const isStandardCostDisabled = isCurrentStandardCostOn
			? isStandardCostBoxChecked
			: !projectCost

		dispatch(
			uploadProjectClicked(
				projectName || filesToUpload[0]?.name,
				Number(quantity),
				ERP,
				CAD,
				filesToUpload,
				unitType,
				BOMFileSelected,
				materialChosenSelected,
				toleranceIncluded,
				toleranceIncluded
					? customToleranceValue
					: ToleranceClass.TOLERANCE_CLASS_IRRELEVANT,
				user,
				application,
				filters,
				showUnitTypeSelector,
				drawingMaterial,
				selectedType,
				selectedProjectScenario,
				history,
				skipFileUpload,
				{ email, name, company, zipCode },
				projectCost,
				projectManufacturing,
				amValue,
				filesWithMaterialAndQuantity,
				undefined,
				personalizedLightUser,
				isStandardCostDisabled
			)
		)
	}

	const fileChipDeleteClicked = (file: File) => {
		dispatch(removeFile(file, user?.notSupportedUnitTypeFormats))
		if (isDefaultFile) {
			setIsDefaultFile(false)
		}
	}

	const triggerUpload = (e: React.MouseEvent<HTMLDivElement | SVGSVGElement>) =>
		!disableGeneralDropFiles &&
		!disableUploadContent &&
		uploadRef?.current?.onClick(e)

	const isRemainingParts = userRemainingPart === 0
	const isAdmin = useMemo(
		() => user.roles.includes(UserRole.SUPER_ADMIN),
		[user.roles]
	)

	let disableUpload =
		!selectedType ||
		!filesToUpload.length ||
		(selectedType !== METADATA && !radioButtonSelected) ||
		errorUnit ||
		(showUnitTypeSelector && !unitType) ||
		isRemainingParts ||
		(!projectName && addProjectName) ||
		userDateExpired

	const handleStartUploading = () => {
		const showPopUp =
			isStepFile &&
			!showUploadProjectPopup &&
			showStepfilesWarning &&
			manufactureMethod != '' &&
			projectStandardCost > 0
		if (showPopUp) {
			setShowUploadProjectPopup(true)
			return
		}

		// Show Errors if we click on upload
		if (initialDisableErrors) {
			setInitialDisableErrors(false)
			return
		}

		if (disableUpload) return

		if (isLightUser && !personalizedLightUser) {
			setShowTrialUserPopup(true)
		} else {
			const inHouseOn = filters.find(
				(filter: any) => filter.name === IUserFilterNames.inHousePrinters
			)?.checked
			if (inHouseOn && !printers?.length) {
				dispatch(onInHousePrintersAlertOpen())
				return
			}
			submitFormClicked()
		}
	}

	const materialNotSelected = useMemo(
		() =>
			radioButtonSelected === BOMRadioValue ? false : !radioButtonSelected,
		[radioButtonSelected]
	)

	const BOMFileNotSelected = useMemo(
		() => radioButtonSelected === BOMRadioValue && !BOMFile,
		[BOMFile, radioButtonSelected]
	)

	const { errorMessage, disableByErrorMessage } = useUploadErrorMessage(
		selectedType,
		materialNotSelected,
		BOMFileNotSelected,
		errorUnit,
		toNumber(quantity),
		manufactureMethodError,
		projectStandardCostError,
		projectName,
		isRemainingParts,
		error,
		userDateExpired,
		isAdmin,
		isFileAmountLimit ? fileLimit : undefined,
		enableUploadWithErrorMessage,
		agreement,
		isStandardCostBoxChecked
	)

	// if we have no errors or file type error,
	// we need to allow light user see it at first
	useEffect(() => {
		const noErrors = !disableUpload && !disableByErrorMessage && agreement

		if ((noErrors || error) && initialDisableErrors) {
			setInitialDisableErrors(false)
		}
	}, [
		disableUpload,
		disableByErrorMessage,
		agreement,
		error,
		initialDisableErrors
	])

	const infoMessage = uploadInfoMessage(
		is2dUpload,
		isDrawingFeatureOn,
		radioButtonSelected,
		selectedType
	)

	if (isAdmin && (isRemainingParts || userDateExpired)) {
		disableUpload = false
	}

	const showTestPartDisable = Feature.isFeatureActive(
		FeatureComponentId.USE_CASTOR_PART
	)
	const disableUploadContent =
		!selectedType || (selectedType !== METADATA && !radioButtonSelected)

	const acceptFilesType = useMemo(
		() =>
			getAcceptedFileFormats(
				is2dUpload,
				isMetaData,
				radioButtonSelected,
				user.acceptedFileTypes
			),
		[is2dUpload, isMetaData, radioButtonSelected]
	)

	return (
		<div className={cx('new-upload-project__block box-files', uploadBoxClass)}>
			<CardHeaderBox
				contentClass="new-upload-project__card files"
				title={getString('NEW_UPLOAD_SECTION_FILES')}
				content={
					<Flexbox
						alignItems="center"
						flexDirection="column"
						className="new-upload-project__files"
					>
						<Flexbox
							alignItems="center"
							flexDirection="column"
							className={cx('files-upload-content', {
								disabled: disableUploadContent
							})}
						>
							<DownloadBom
								className={cx('files-upload-content__icon', {
									disabled: disableGeneralDropFiles
								})}
								onClick={(e: React.MouseEvent<SVGSVGElement>) =>
									triggerUpload(e)
								}
							/>
							<Flexbox
								alignItems="center"
								className="files-upload-content__title"
							>
								<UploadImage
									radioButtonSelected={radioButtonSelected}
									is2dUpload={is2dUpload}
									selectedType={selectedType}
								/>
								<h1
									onClick={(e: React.MouseEvent<HTMLHeadingElement>) =>
										triggerUpload(e)
									}
								>
									{getString('NEW_UPLOAD_DROP_HERE')}
								</h1>
							</Flexbox>
							<p className="files-upload-content__text">{infoMessage}</p>
							<WarnMessage
								warnClassName="red absolute bottom"
								show={!initialDisableErrors && !!errorMessage}
								message={errorMessage}
							/>
						</Flexbox>
						<Flexbox
							alignItems="center"
							flexDirection="column"
							className={cx('files-upload-content__dropzone', {
								disabled: disableUploadContent,
								'with-error': !selectedType || error
							})}
						>
							<Dropzone
								disabled={disableGeneralDropFiles || isDefaultFile}
								ref={uploadRef}
								accept={acceptFilesType}
								id="upload-project-dropzone"
								className={cx('upload-project-dropzone', {
									'drag-on': draggingFiles,
									'drag-off': !draggingFiles
								})}
								onDrop={(files: File[], rejectedFiles: File[]) =>
									handleDrop(files, is2dUpload, rejectedFiles)
								}
								multiple
								onDragEnter={() => dragEvent(true)}
								onDragLeave={() => dragEvent(false)}
								data-qa="data-qa-dropzone-upload-part"
							>
								<div
									// prevents downloading file on drop
									// when dropzone is disabled
									onDrop={event => event.preventDefault()}
								>
									<UnitTypeSelector
										errorUnit={errorUnit || (showUnitTypeSelector && !unitType)}
										error={error}
										inputClassName="field-with-label outlined without-label"
										unitType={unitType}
										showUnitTypeSelector={showUnitTypeSelector}
									/>
									<div
										className={cx('upload-list', {
											'with-unit': showUnitTypeSelector,
											'with-error': !!error
										})}
									>
										<UploadListItems
											disabled={showUnitTypeSelector && !unitType}
											filesToUpload={filesToUpload}
											fileChipDeleteClicked={fileChipDeleteClicked}
										/>
									</div>
								</div>
							</Dropzone>
							<Flexbox
								className={
									showTestPartDisable ? 'upload-button-area-light-user' : ''
								}
								alignItems="center"
							>
								{showTestPartDisable ? (
									<ButtonWithLoader
										color="secondary"
										className="use-our-part-button"
										qaDataElementName="use-our-part-uploading"
										disabled={filesToUpload.length >= 1}
										id="upload-project-use-our-part-button"
										onClick={handleDefaultFileDrop}
									>
										{getString('USER_MISSING_FILE')}&nbsp;
									</ButtonWithLoader>
								) : (
									<></>
								)}
								<div className="upload-button">
									<ButtonWithLoader
										qaDataElementName="data-qa-start-uploading"
										disabled={
											!initialDisableErrors &&
											(disableUpload || disableByErrorMessage || !agreement)
										}
										id="upload-project-upload-button"
										loading={loading}
										primary={true}
										onClick={handleStartUploading}
									>
										{getString('UPLOAD_FILES_BUTTON')}&nbsp;
										{filesToUpload?.length > 1 && (
											<span>
												({filesToUpload?.length} {getString('NEW_UPLOAD_FILES')}
												)
											</span>
										)}
									</ButtonWithLoader>
								</div>
							</Flexbox>
						</Flexbox>
						<div className="new-upload-project__agreement">
							<p>{getString('FILES_WILL_NOT_SHARED')}</p>
							<UploadAgreement
								agreement={agreement}
								setAgreement={setAgreement}
							/>
						</div>
					</Flexbox>
				}
			/>
			<LightUserUploadProjectAlert
				show={showTrialUserPopup}
				onConfirm={(email: string, name: string, company: string, zipCode: string) => {
					submitFormClicked(email, name, company, zipCode)
				}}
				onCancel={() => {
					setShowTrialUserPopup(false)
				}}
				errorMessage={trialUserPopupErrorMessage}
			/>
			<CastorAlert
				headerTitle={getString('CONFIGURATION_CHANGES_WARNING')}
				show={showUploadProjectPopup}
				onConfirm={() => {
					setShowUploadProjectPopup(false)
					if (
						(disableUpload || disableByErrorMessage || !agreement) &&
						initialDisableErrors
					) {
						// if we have some error we need to show them
						setInitialDisableErrors(false)
					} else {
						// if no errors start uploading
						handleStartUploading()
					}
				}}
				onCancel={() => {
					setShowUploadProjectPopup(false)
				}}
				alertType={AlertType.WARNING}
				cancelOptionalText={getString('CANCEL')}
			>
				{getString('UPLOAD_PROJECT_WARNING_STEP_FILE').format(
					`${userRemainingPart} ${(userRemainingPart === 1
						? PART
						: PARTS
					).toLowerCase()}`
				)}
			</CastorAlert>
		</div>
	)
}
export default memo(UploadFiles)
