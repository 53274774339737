import find from 'lodash/find'
import isNumber from 'lodash/isNumber'
import isObject from 'lodash/isObject'
import mapValues from 'lodash/mapValues'
import Numeral from 'numeral'

const getMinValue = (totalValue: number = 0, range: number) =>
	Numeral(totalValue - range)?.format('0,0')
const getMaxValue = (totalValue: number = 0, range: number) =>
	Numeral(totalValue + range)?.format('0,0')
const getRange = (totalValue: number = 0, percentage: number) =>
	(totalValue * percentage) / 100
export const getPercentage = (totalValue: number, percentage: number = 100) => {
	return (totalValue / 100) * percentage
}

export const calculate2DRange = (
	totalValue: any,
	drawingCostPercentage?: number
) => {
	const percentage = drawingCostPercentage || 1
	const range = getRange(totalValue, percentage)
	const maxValue = getMaxValue(totalValue, range)
	const minValue = getMinValue(totalValue, range)

	if (maxValue === minValue) return Number(minValue)
	return `${minValue} - ${maxValue}`
}

export const calculate2DRangeArray = (
	baseValue: number,
	rangeValue: number,
	drawingCostPercentage?: number
) => {
	const percentage = drawingCostPercentage || 1
	const range = getRange(rangeValue, percentage)
	let maxValue = getMaxValue(rangeValue, range)
	const minValue = getMinValue(rangeValue, range)

	if (maxValue === minValue) {
		return baseValue + parseFloat(minValue)
	}
	return `${baseValue + parseFloat(minValue)} - ${
		baseValue + parseFloat(maxValue)
	}`
}

export const calculate2DSupplyChainTMRange = (
	totalValue: any,
	baseValue: number,
	drawingCostPercentage?: number
) => {
	const percentage = drawingCostPercentage || 1
	const range = getRange(totalValue, percentage)
	const maxValue = getMaxValue(baseValue + totalValue, range)
	const minValue = getMinValue(baseValue + totalValue, range)

	if (maxValue === minValue) return minValue
	return `${minValue} - ${maxValue}`
}

export const displayInRange = (
	minData?: number,
	maxData?: number,
	defaultData?: string | number,
	format?: string | null,
	drawingCostPercentage?: number | null
) => {
	let minNumber: any = minData || 0
	let maxNumber: any = maxData || 0
	const minFormattedData = format ? minNumber : Math.ceil(minNumber)
	const maxFormattedData = format ? maxNumber : Math.ceil(maxNumber)

	// if !MAX AND !MIN we need to return empty
	if (minNumber <= 0 && maxNumber <= 0) {
		return defaultData
	}

	if (!drawingCostPercentage) {
		// if !MAX OR !Min and !drawing, return or max or min
		if (!maxNumber || !minNumber) {
			return minNumber
				? Numeral(minFormattedData).format(format || '0,0') || minNumber
				: Numeral(maxFormattedData).format(format || '0,0') || minNumber
		}

		// if MAX equal to Min and no drawing, return one of them
		if (
			minNumber === maxNumber ||
			Numeral(minFormattedData).format(format || '0,0') ===
				Numeral(maxFormattedData).format(format || '0,0')
		) {
			if (minFormattedData < 0.0001) return 0
			return Numeral(minFormattedData).format(format || '0,0')
		}
	} else {
		// if MAX and Min and drawing, calculate ranges from min and max values
		if (maxNumber && minNumber) {
			const rangeMin = getRange(minData, drawingCostPercentage)
			const rangeMax = getRange(maxData, drawingCostPercentage)

			minNumber = getMinValue(minData, rangeMin)
			maxNumber = getMaxValue(maxData, rangeMax)
			return `${minNumber} - ${maxNumber}`
		}

		// if !MAX OR !Min and drawing, calculate ranges from min or max values
		if (!maxNumber || !minNumber) {
			const existNumber = maxNumber || minNumber
			const existRange = getRange(existNumber, drawingCostPercentage)

			return calculate2DRange(existNumber, existRange)
		}
	}

	const minNumberWithFormat = Numeral(Math.abs(minNumber)).format(
		format || '0,0'
	)
	const maxNumberWithFormat = Numeral(Math.abs(maxNumber)).format(
		format || '0,0'
	)

	//need to avoid negative values if they are exist
	if (minNumber < 0) {
		return `${maxNumberWithFormat}`
	}
	if (maxNumber < 0) {
		return `${maxNumberWithFormat}`
	}
	if (minNumber < 0 && maxNumber < 0) {
		return `±1`
	}

	return `${minNumberWithFormat} - ${maxNumberWithFormat}`
}

export const postProcessesRange = (
	minValue?: number | any,
	maxValue?: number | any,
	defaultData?: string | number,
	format?: string | null,
	drawingCostPercentage?: number | null
) => {
	if (isNumber(minValue) && isNumber(maxValue)) {
		return displayInRange(
			minValue,
			maxValue,
			defaultData,
			format,
			drawingCostPercentage
		)
	}

	if (isObject(minValue) && isObject(maxValue)) {
		return mapValues(minValue, (value, key): any => {
			const max =
				(maxValue &&
					find(maxValue, (elem: any, keyMax: any) => keyMax === key)) ||
				value

			// first level of post processes
			if (isNumber(value)) {
				return displayInRange(
					value,
					max,
					defaultData,
					format,
					drawingCostPercentage
				)
			}

			// second level of post processes
			return mapValues(value, (valueObj, keyObj): any => {
				const minValueObj = isNumber(valueObj) ? valueObj : 0
				const maxValueObj =
					find(max, (elem: any, keyMaxObj: any) => keyMaxObj === keyObj) ||
					minValueObj

				return displayInRange(
					minValueObj,
					maxValueObj,
					valueObj,
					format,
					drawingCostPercentage
				)
			})
		})
	}

	return minValue
}

export const postProcesses2DRange = (
	optionalPostProcessesCost?: number | any,
	drawingCostPercentage?: number | any
) => {
	if (isNumber(optionalPostProcessesCost)) {
		return calculate2DRange(optionalPostProcessesCost, drawingCostPercentage)
	}

	if (isObject(optionalPostProcessesCost)) {
		return mapValues(optionalPostProcessesCost, (value, key): any => {
			const numberValue = value || 0

			if (isNumber(value)) {
				return calculate2DRange(numberValue, drawingCostPercentage)
			}

			// second level of post processes
			return mapValues(value, (valueObj, keyObj): any => {
				const minValueObj = valueObj || 0

				return calculate2DRange(minValueObj, drawingCostPercentage)
			})
		})
	}

	return optionalPostProcessesCost
}

export const calculateStandardCostDiff = (
	totalCost: number,
	onlySupplyChainCost: number,
	drawingValue: number | null,
	isPlus?: boolean
) => {
	const diff = Math.round(totalCost) - Math.round(onlySupplyChainCost)

	const percentageValue = getPercentage(diff, drawingValue || 100)

	if (drawingValue) {
		return isPlus
			? Math.ceil(diff + percentageValue)
			: Math.ceil(diff - percentageValue)
	}

	return Math.ceil(diff)
}

// make '0' -> 0, '5 - 7' -> '5 - 7', 5 -> 5
export const toNumberStringValue = (value: number | string) =>
	isNaN(+value) ? value : +value
