import React, { FC, memo } from 'react'
import { useDispatch } from 'react-redux'

import CastorAlert from 'Scenes/Components/alerts/CastorAlert'
import { NO, YES } from 'Services/Strings'
import { AlertType } from 'Scenes/Components/alerts/AlertTypes'
import { getString } from 'Services/Strings/StringService'

interface PartPropertyCalculateAlertProps {
	setPartPropertyCalculateAlert: Function

	removeConfigurationLoading: boolean
	onRecalculateClick: Function
	projectId: number
	partProperties: any[]
	partIndex: number
	onPartsPropertiesChange: Function
	showAlert: boolean
}

const PartPropertyCalculateAlert: FC<PartPropertyCalculateAlertProps> = ({
	setPartPropertyCalculateAlert,
	removeConfigurationLoading,
	onRecalculateClick,
	projectId,
	partProperties,
	partIndex,
	onPartsPropertiesChange,
	showAlert
}) => {
	const dispatch = useDispatch()
	const onRemoveAlert = () => setPartPropertyCalculateAlert(false)

	return (
		<CastorAlert
			headerTitle={getString('CONFIGURATION_CHANGES_WARNING')}
			onCancel={() => {
				onRemoveAlert()
			}}
			show={showAlert}
			onConfirm={() => {
					onRecalculateClick(
						projectId,
						partProperties,
						partIndex,
						onPartsPropertiesChange,
						setPartPropertyCalculateAlert
					)
			}}
			cancelOptionalText={NO}
			confirmOptionalText={YES}
			alertType={AlertType.WARNING}
			loadingCalculation={removeConfigurationLoading}
		>
			{getString('WARNING_PART_PROPERTY_RECALCULATE')}
		</CastorAlert>
	)
}

export default memo(PartPropertyCalculateAlert)
