import React, { FC, ReactElement, ReactNode, useState } from 'react'
import { Manager, Popper, Target } from 'react-popper'

import { Portal } from '@material-ui/core'
import { ClickAwayListener } from '@material-ui/core'

import PopupMessage from './DetailsPopupMessage'

import './DetailsPopup.scss'

const ManagerTSX: any = Manager
const PopperTSX: any = Popper
const TargetTSX: any = Target
const ClickAwayListenerTSX: any = ClickAwayListener

interface Props {
	isOpen?: boolean
	data: ReactElement | HTMLElement | any
	handleClose?: Function
	popperDirection:
		| 'auto'
		| 'top'
		| 'right'
		| 'bottom'
		| 'left'
		| 'auto-start'
		| 'auto-end'
		| 'top-start'
		| 'right-start'
		| 'bottom-start'
		| 'left-start'
		| 'top-end'
		| 'right-end'
		| 'bottom-end'
		| 'left-end'
	children?: ReactNode | ReactElement | Array<ReactNode> | Array<ReactElement>
	popperClassName?: string
	popperContactClassName?: string
	targetClassName?: string
	managerClassName?: string
	mousePositionX?: number
	mousePositionY?: number
	positionX?: number
	positionY?: number
	style?: object
	isHover?: boolean
	disableClick?: boolean
	shouldPreventOverflow?: boolean
	inPortal?: boolean
}

const popover = document.createElement('div')
popover.setAttribute('id', 'popover')
document.body.appendChild(popover)

const DetailsPopup: FC<Props> = ({
	positionX,
	positionY,
	mousePositionY,
	mousePositionX,
	popperContactClassName,
	popperClassName,
	popperDirection = 'auto-start',
	data,
	style,
	shouldPreventOverflow,
	isHover,
	targetClassName = '',
	managerClassName = '',
	inPortal,
	children
}) => {
	const [show, setShow] = useState(false)
	const openWindow = true

	const getReference = (positionX: number, positionY: number) => {
		return {
			getBoundingClientRect: () => ({
				width: 0,
				height: 0,
				top: positionY,
				bottom: positionY + 120,
				left: positionX - 20,
				right: positionX - 20
			})
		}
	}

	const renderPopper = () => {
		let reference: any = null

		if (mousePositionX && mousePositionY) {
			reference = getReference(mousePositionX, mousePositionY)
		}

		if (positionX && positionY) {
			reference = getReference(positionX, positionY)
		}

		return (
			<PopperTSX
				className={`details-popup-main-popper ${(show && 'show') || ''} ${
					popperClassName || ''
				}`}
				style={style}
				target={reference}
				placement={popperDirection}
				modifiers={{
					preventOverflow: {
						enabled: !!shouldPreventOverflow,
						escapeWithReference: true,
						boundariesElement: 'scrollParent'
					},
					hide: { enabled: false }
				}}
				onClick={onPopperClick}
			>
				<PopupMessage data={data} className={popperContactClassName} />
			</PopperTSX>
		)
	}

	const onPopperClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation()
	}

	const onTargetClick = (e: React.MouseEvent<HTMLElement>) => {
		if (!isHover) {
			e.stopPropagation()
			setShow(show => !show)
		}
	}

	const handleClose = () => {
		if (!isHover && show) {
			setShow(false)
		}
	}

	const onMouseChange = (value: boolean) => {
		if (isHover && show !== value) {
			setShow(value && data)
		}
	}

	if (!isHover) {
		return <>{children}</>
	}

	return (
		<ClickAwayListenerTSX onClickAway={handleClose}>
			<ManagerTSX
				className={managerClassName}
				onMouseEnter={() => {
					onMouseChange(openWindow)
				}}
				onMouseLeave={() => {
					onMouseChange(!openWindow)
				}}
			>
				<TargetTSX
					onClick={onTargetClick}
					className={isHover ? `details-popup-target ${targetClassName}` : ''}
				>
					{children}
				</TargetTSX>
				{inPortal && show ? (
					<Portal container={popover}>{renderPopper()}</Portal>
				) : (
					renderPopper()
				)}
			</ManagerTSX>
		</ClickAwayListenerTSX>
	)
}

export default DetailsPopup
