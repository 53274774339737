import Flexbox from 'Scenes/Components/FlexBox'
import React, { Component } from 'react'

import DetailsPopup from '../../../../Scenes/Components/DetailsPopup'
import WithFeatureToggleHOC from '../../../../Services/HOC/WithFeatureToggleHOC'
import {
	Feature,
	FeatureComponentId
} from '../../../../Services/models/Features'
import { ProjectClusterStatus } from '../../../../Services/models/IProject'
import {
	PART_CONSOLIDATION_OPPORTUNITIES,
	PART_CONSOLIDATION_OPPORTUNITIES_HELPER,
	SEARCH_AGAIN,
	SEARCH_AGAIN_FOR_RESULT,
	WITH_ANY_MATERIAL,
	WITH_SAME_MATERIAL
} from '../../../../Services/Strings'
import { getString } from '../../../../Services/Strings/StringService'
import FeatureSection from '../../../Components/FeatureSection'
import InfoBox from '../../../Components/InfoBox'
import IconFactory from '../../../Components/StarIcon/IconFactory'
import Button from '../../../Components/thirdParty/CreativeTim/components/CustomButtons/Button.jsx'
import PrintableClustersOverview from '../../PrintableClusters/PrintableClustersOverview'

import '../ProjectAnalysis.scss'

class RequestMultiplePartsIntoOneCard extends Component {
	componentWillMount() {}

	renderCountExplanation = () => {
		const { materialCountInfo } = this.props

		return (
			<div>
				<div className="text-dark">
					{materialCountInfo?.sameMaterialCount} {WITH_SAME_MATERIAL}
				</div>
				<div>
					{materialCountInfo?.anyMaterialCount} {WITH_ANY_MATERIAL}
				</div>
			</div>
		)
	}

	renderSubHeaderSection = () => {
		const { requested, requestText, projectClusterStatus, materialCountInfo } =
			this.props

		const displayPopup =
			materialCountInfo?.totalMaterialCount &&
			projectClusterStatus === ProjectClusterStatus.complete

		if (!requested) {
			return <div />
		}

		return (
			<Flexbox alignItems="center">
				{projectClusterStatus !== ProjectClusterStatus.failed && (
					<IconFactory
						iconName={projectClusterStatus}
						className="cluster-section--subheader--icon"
					/>
				)}
				<div className="cluster-section--subheader--text">
					{requestText ||
						getString('COMBINE_MULTIPLE_INTO_ONE_CARD_COMPLETED_TXT').format(
							materialCountInfo?.totalMaterialCount || 0
						)}
				</div>
				{!!displayPopup && (
					<InfoBox
						inPortal={true}
						boxContact={this.renderCountExplanation()}
						iconClassName="admin-form-field__info_icon"
						boxDirection="right-start"
					/>
				)}
			</Flexbox>
		)
	}

	renderSectionContent = () => {
		const {
			requested,
			setupClustersOverview,
			clusterItemsWithPictures,
			requestText,
			showResults,
			project
		} = this.props
		if (showResults) {
			return (
				<PrintableClustersOverview
					clusterItemsWithPictures={clusterItemsWithPictures}
					setupClustersOverview={setupClustersOverview}
					projectId={project.id}
					projectIdentifier={project.id}
				/>
			)
		}

		if (requested) {
			return <div />
		}

		return requestText
	}

	renderSectionAction = () => {
		const {
			project,
			projectClusterStatus,
			materialCountInfo,
			confirmConsolidationAnalysis
		} = this.props

		const isAwaitingAnalysis =
			projectClusterStatus === ProjectClusterStatus.awaitingAnalysis
		const noClustersFound =
			projectClusterStatus === ProjectClusterStatus.noClustersFound
		const isNotRecalculate = !project?.recalculateClusters && project?.clusters
		const isFeatureOn = Feature.isFeatureOn(FeatureComponentId.UNIFICATION)

		if (
			isAwaitingAnalysis ||
			noClustersFound ||
			isNotRecalculate ||
			!projectClusterStatus ||
			!isFeatureOn
		) {
			return
		}

		const disableButton = !Feature.isFeatureActive(
			FeatureComponentId.UNIFICATION
		)

		return (
			<>
				<div className="search-again-info">
					<IconFactory
						iconName="warning"
						className="filter-fields--widget--remove--icon"
					/>
					{SEARCH_AGAIN_FOR_RESULT}
				</div>
				<DetailsPopup
					isHover={false}
					data={getString('LIGHT_USER_BUTTONS_INFORMATION')}
					popperDirection="bottom"
					popperClassName="info-box-wrapper details-popup--contact-us"
					popperContactClassName="info-box-data"
				>
					<Button
						disabled={disableButton}
						className="cluster-section--button"
						color="primary"
						onClick={() =>
							confirmConsolidationAnalysis(
								project.id,
								materialCountInfo?.totalMaterialCount
							)
						}
					>
						{SEARCH_AGAIN}
					</Button>
				</DetailsPopup>
			</>
		)
	}

	sectionHeader = () => {
		return (
			<div className="feature-section--header__with-icon">
				<span>{PART_CONSOLIDATION_OPPORTUNITIES}</span>
				<div>
					<InfoBox
						boxContact={PART_CONSOLIDATION_OPPORTUNITIES_HELPER}
						iconClassName="admin-form-field__info_icon"
						boxDirection="right-start"
					/>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div id={FeatureComponentId.UNIFICATION}>
				<FeatureSection
					sectionHeader={this.sectionHeader()}
					sectionSubHeader={this.renderSubHeaderSection()}
					sectionAction={this.renderSectionAction()}
					sectionContent={this.renderSectionContent()}
				/>
			</div>
		)
	}
}

export default WithFeatureToggleHOC(
	RequestMultiplePartsIntoOneCard,
	FeatureComponentId.UNIFICATION
)
