import React, { FC, memo, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import { isNumber } from 'lodash'

import { addSimpleConfigurationClick } from '../../../MainPartAnalysis/MainPartAnalysisActions'
import { NewPrinterMaterialButton } from '../../common/Buttons'
import DropDownAxis from '../../common/DropDownAxis'
import {
	solutionMapConstant,
	xAxisDropDownValues,
	yAxisDropDownValues
} from '../../PartAnalysisConstants'
import { useChangeAxis } from '../../PartAnalysisHooks'
import {
	usePartReducer,
	useSolutionReducer,
	useUserReducer
} from '../../PartAnalysisSelector'
import { generateChartData } from '../../PartAnalysisService'
import ConfigurationPrioritizeForm from './ConfigurationPrioritizeForm'
import ConfigurationSolutionTable from './ConfigurationSolutionTable'
import ErrorBoundary from 'Scenes/Components/ErrorBoundary/ErrorBoundary'
import ChartMap from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/ConfigurationContent/ConfigurationTabDetails/ChartMap'
import { onChangeHoverAlternativeSolution } from 'Scenes/Home/NewPartAnalysis/PartAnalysisTab/PartAnalysisActions'
import Loader from 'Scenes/Loader/Loader'
import { Feature, FeatureComponentId } from 'Services/models/Features'
import { getString } from 'Services/Strings/StringService'

interface ConfigurationSolutionMapProps {
	disablePart: boolean
	activeTabChange: Function
	configuration: any
}

let ConfigurationSolutionMap: FC<ConfigurationSolutionMapProps> = ({
	disablePart,
	activeTabChange,
	configuration
}) => {
	let chartRef = React.useRef(null)
	const {
		alternativeSolutionHover: hoverElement,
		alternativeSolutions,
		alternativeSolutionsLoading
	} = usePartReducer()
	const { loadingCalculation } = useSolutionReducer(configuration.id)
	const [selectedXAxis, setSelectedXAxis, selectedXAxisExplanation] =
		useChangeAxis(solutionMapConstant.score)
	const { printingTechnologies, roles } = useUserReducer()
	const configurationMaterial = configuration.material
	const dispatch = useDispatch()
	const onAddSimpleConfigurationClick = () => {
		dispatch(addSimpleConfigurationClick())
	}

	const showActualScore = !!Feature.isFeatureOn(
		FeatureComponentId.SHOW_ACTUAL_SCORE
	)

	const updateHoverElement = (element: number | null) => {
		dispatch(
			onChangeHoverAlternativeSolution(isNumber(element) ? element + 1 : null)
		)
	}

	const chartMapSolutions = useMemo(
		() =>
			generateChartData(
				alternativeSolutions,
				selectedXAxis,
				solutionMapConstant.cost,
				updateHoverElement,
				printingTechnologies,
				showActualScore,
				configurationMaterial,
				hoverElement,
				roles
			),
		[alternativeSolutions, selectedXAxis, updateHoverElement, hoverElement]
	)

	const showLowMedium = useMemo(
		() => selectedXAxis === solutionMapConstant.score && !showActualScore,
		[selectedXAxis, showActualScore]
	)

	if (alternativeSolutionsLoading || loadingCalculation) {
		return (
			<div className={'details__block map'}>
				<Loader load={true} message="" wrapperClassName="loader" />
			</div>
		)
	}
	return (
		<ErrorBoundary extra="ConfigurationSolutionMap">
			<div className="details__block map" onClick={e => e.stopPropagation()}>
				<div className="details__map">
					<div className="details__map-analysis">
						<div className="yx-axis">
							<div className="y-axis">
								<span>
									{getString('Y_AXIS')} {getString('AXIS')}
								</span>
								<DropDownAxis
									disableArrow
									value={solutionMapConstant.cost}
									dropDownArray={yAxisDropDownValues}
									onChangeValue={() => {}}
									dropDownClass="top-input"
								/>
							</div>
							<div className="x-axis">
								<span>
									{getString('X_AXIS')} {getString('AXIS')}
								</span>
								<DropDownAxis
									value={selectedXAxis}
									dropDownArray={xAxisDropDownValues}
									onChangeValue={setSelectedXAxis}
									explanation={selectedXAxisExplanation}
								/>
							</div>
						</div>
						<ChartMap
							selectedXAxis={selectedXAxis}
							showLowMedium={showLowMedium}
							chartRef={chartRef}
							componentChartData={chartMapSolutions?.componentChartData}
							series={chartMapSolutions?.series}
						/>
					</div>
					<ConfigurationPrioritizeForm configuration={configuration} />
				</div>
				<div className="details__table details__actions">
					{alternativeSolutions && alternativeSolutions.length > 0 && (
						<ConfigurationSolutionTable
							alternativeSolutions={alternativeSolutions}
							selectedXAxisName={selectedXAxis}
							selectedYAxisName={solutionMapConstant.cost}
							activeTabChange={activeTabChange}
							configuration={configuration}
						/>
					)}
					<NewPrinterMaterialButton
						isEmpty={!alternativeSolutions?.length}
						onAddSimpleConfigurationClick={onAddSimpleConfigurationClick}
						disablePart={disablePart}
					/>
				</div>
			</div>
		</ErrorBoundary>
	)
}

export default memo(ConfigurationSolutionMap)
